import _ from 'lodash'
import moment from 'moment'

export const hasOnboardingVirtualClassroom = virtualClassroomsBySequence =>
  virtualClassroomsBySequence.some(({ isOnboarding }) => isOnboarding)

export const hasNoOnboardingVirtualClassroom = virtualClassroomsBySequence =>
  virtualClassroomsBySequence.some(({ isOnboarding }) => !isOnboarding)

export const getNextVirtualClassroomIndex = virtualClassroomsBySequence =>
  _.sortBy(virtualClassroomsBySequence, 'sequenceNumber').findIndex(sequence =>
    sequence.virtualClassrooms.some(({ endDate }) => moment(endDate).isSameOrAfter()),
  )

export const hasAtLeastOneWebexLink = sequenceList =>
  sequenceList.some(sequence => sequence.virtualClassrooms.some(virtualClassroom => virtualClassroom.type === 'webex'))

export const SLOT_STATUS = {
  DONE: 'done',
  LIVE: 'live',
  OPENED: 'opened',
}

export const getSlotStatus = ({ startDate, endDate, hasAuthenticatedParticipantRegistered }) => {
  const currentDate = moment()

  if (currentDate.isSameOrAfter(endDate)) {
    return SLOT_STATUS.DONE
  }

  if (currentDate.isBetween(moment(startDate).subtract(15, 'minutes'), endDate)) {
    // Si le participant n'est pas encore enregistré on ne considère pas le créneau comme "live".
    // Retourner un statut "opened" permettra à l'utilisateur de pouvoir s'inscrire sur le créneau.
    if (!hasAuthenticatedParticipantRegistered) {
      return SLOT_STATUS.OPENED
    }

    return SLOT_STATUS.LIVE
  }

  return SLOT_STATUS.OPENED
}
