import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import gql from 'graphql-tag'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'
import { colors } from 'styles-resources'
import InfoBloc from '../InfoBloc'
import svgBanner from './assets/banner.svg'

const Content = styled.div`
  position: relative;
  text-align: center;
  background-image: url(${svgBanner});
  background-size: cover;
  background-repeat: round;
  text-align: center;

  height: 80px;
  margin-bottom: 30px;
`

const NbCertifiedCourse = styled.h2`
  padding-top: 10px;
  color: ${colors.unowOrange};
`

const NbCertifiedSession = styled.p`
  text-align: center;
`

const BecomeTheFirst = styled.p`
  margin-top: 10px;
  font-size: 14px;
  color: ${colors.midGray};
  text-align: center;
`

const Certified = ({
  gender,
  session: {
    nbCertifiedParticipants: nbCertifiedSession,
    course: { nbCertifiedParticipants: nbCertifiedCourse },
  },
  className,
}) => (
  <InfoBloc title={<FormattedMessage id="courses.course.infos.certified.title" />} className={className}>
    <Content>
      <NbCertifiedCourse>{nbCertifiedCourse}</NbCertifiedCourse>
    </Content>
    <NbCertifiedSession>
      <FormattedMessage id="courses.course.infos.certified.in_session" values={{ number: nbCertifiedSession }} />
    </NbCertifiedSession>
    {nbCertifiedSession === 0 && (
      <BecomeTheFirst>
        <FormattedMessage id="courses.course.infos.certified.become_the_first" values={{ gender }} />
      </BecomeTheFirst>
    )}
  </InfoBloc>
)

Certified.fragments = {
  session: gql`
    fragment _ on Session {
      nbCertifiedParticipants
      course {
        nbCertifiedParticipants
      }
    }
  `,
}

Certified.defaultProps = {
  className: undefined,
}

Certified.propTypes = {
  session: propType(Certified.fragments.session).isRequired,
  gender: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default Certified
