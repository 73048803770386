import React from 'react'
import PropTypes from 'prop-types'
import { mq } from '@unowmooc/themes'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { ButtonSkin } from '@unowmooc/buttons'
import { Link } from 'react-router-dom'
import { FormattedHTMLMessage } from 'react-intl'
import * as ConversationUI from './UI'

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${mq.sm(css`
    flex-direction: column-reverse;
  `)};
`

const HeaderCourseDescription = styled.div`
  color: ${({ theme: { colors } }) => colors.midGray};
  font-size: 14px;
  line-height: 30px;
  margin-bottom: 16px;
`

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;

  ${mq.sm(css`
    flex-direction: column;
  `)};
`

const ButtonSkinStyled = styled(ButtonSkin)`
  margin-left: 40px;

  ${mq.sm(css`
    margin-left: 0;
  `)};
`

const OverviewParticipant = ({ fullName, avatarUrl, sessionTitle, subject, sentAt, detailUrl }) => (
  <ConversationUI.BoxShadow>
    <Header>
      <ConversationUI.Sender fullName={fullName} avatarUrl={avatarUrl} sentAt={sentAt} />
      <HeaderCourseDescription>{sessionTitle}</HeaderCourseDescription>
    </Header>
    <FlexWrapper>
      <ConversationUI.Subject text={subject} />
      <ButtonSkinStyled tag={Link} to={detailUrl} size="small">
        <FormattedHTMLMessage id="conversation.see" />
      </ButtonSkinStyled>
    </FlexWrapper>
  </ConversationUI.BoxShadow>
)

OverviewParticipant.defaultProps = {
  avatarUrl: undefined,
  sentAt: undefined,
}

OverviewParticipant.propTypes = {
  fullName: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  sessionTitle: PropTypes.string.isRequired,
  detailUrl: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string,
  sentAt: PropTypes.string,
}

export default OverviewParticipant
