import { useEffect, useState } from 'react'
import { eventListenerOpts } from 'utils/supportsPassive'

function useScrollPosition() {
  const [[scrollPosition, lastScrollPosition], setScrollPosition] = useState([0, 0])

  useEffect(() => {
    const updatePosition = () => {
      // scrollY ne fonctionne pas sous IE11 contrairement à pageYOffset
      setScrollPosition(([currentScrollPosition]) => [window.scrollY || window.pageYOffset, currentScrollPosition])
    }

    window.addEventListener('scroll', updatePosition, eventListenerOpts)
    updatePosition()

    return () => window.removeEventListener('scroll', updatePosition, eventListenerOpts)
  }, [])

  return [scrollPosition, lastScrollPosition]
}

export default useScrollPosition
