import React, { useState } from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Alert, Button } from '@unowmooc/react-ui-kit'
import { numericFormat } from 'utils/date'
import { withUser } from 'store/UserProvider'
import PageQuery from 'components/PageQuery'
import { Form as FinalForm } from 'react-final-form'
import FormHeader from 'components/FormContainer/Form/Header'
import { useMutation } from '@apollo/react-hooks'
import moment from 'moment'
import createScrollOnErrorDecorator from 'components/FormContainer/Form/decorators/scrollOnError'
import I18nProvider from '@unowmooc/i18n'
import { Link } from 'react-router-dom'
import EditVirtualClassroomSequence from './Form/EditVirtualClassroomSequence'

const CREATE_VIRTUAL_CLASSROOMS = gql`
  mutation createVirtualClassrooms($virtualClassrooms: [VirtualClassroomAnimatorInput]!, $sessionId: Int!) {
    createAnimatorVirtualclassrooms(virtualClassrooms: $virtualClassrooms, sessionId: $sessionId) {
      id
      virtualClassrooms {
        id
      }
    }
  }
`

const EditPage = ({
  intl,
  match: {
    params: { idSession },
  },
}) => {
  const [createVirtualClassrooms] = useMutation(CREATE_VIRTUAL_CLASSROOMS)
  const [errorMessage, setErrorMessage] = useState()
  const [sessionInfos, setSessionInfos] = useState()
  const [requestLoading, setRequestLoading] = useState(false)

  const scrollOnError = createScrollOnErrorDecorator({ offsetTop: -80 })

  const handleSubmitForm = values => {
    setRequestLoading(true)

    const virtualclassroomsVariable = []

    Object.keys(values.vc).forEach(sequenceKey => {
      const vcitem = values.vc[sequenceKey]
      virtualclassroomsVariable.push({
        sequenceNumber: vcitem.sequence,
        startDate: moment(vcitem.start).format(),
        endDate: moment(vcitem.end).format(),
      })
    })

    const mutationVariables = {
      sessionId: idSession,
      virtualClassrooms: virtualclassroomsVariable,
    }

    createVirtualClassrooms({ variables: mutationVariables }).catch(function (infos) {
      setErrorMessage(infos.message)
    })
  }

  const validateCvOnSameTime = values => {
    const errors = {
      vc: {},
    }

    /*
      Pour que l'erreur fonctionne il faut renvoyer un object comme celui-ci
      { vc: { "number1": { start: "Conflit avec champ" }}};
     */

    Object.keys(values.vc ?? []).forEach(sequenceKey => {
      if (
        typeof values.vc[sequenceKey] !== 'undefined' &&
        typeof values.vc[sequenceKey].start !== 'undefined' &&
        typeof values.vc[sequenceKey].start !== 'undefined'
      ) {
        Object.keys(values.vc).forEach(otherSequenceKey => {
          if (
            sequenceKey !== otherSequenceKey &&
            typeof values.vc[otherSequenceKey] !== 'undefined' &&
            typeof values.vc[otherSequenceKey].start !== 'undefined' &&
            typeof values.vc[otherSequenceKey].end !== 'undefined'
          ) {
            if (
              parseInt(sequenceKey.substring(6), 10) > parseInt(otherSequenceKey.substring(6), 10) &&
              moment(values.vc[sequenceKey].start).isBefore(
                values.vc[otherSequenceKey].start,
                values.vc[otherSequenceKey].end,
                undefined,
                '[)',
              )
            ) {
              if (typeof errors.vc[sequenceKey] === 'undefined') {
                errors.vc[sequenceKey] = {}
              }

              errors.vc[sequenceKey].start = `${I18nProvider.formatMessage({
                id: 'vc_input.edit.errors.should_be_after',
              })} #${otherSequenceKey.substring(6)}`
            }

            /*
             https://momentjscom.readthedocs.io/en/latest/moment/05-query/06-is-between/
             Version 2.13.0 introduces inclusivity. A [ indicates inclusion of a value. A ( indicates exclusion. If the inclusivity parameter is used, both indicators must be passed.
            */
            if (
              moment(values.vc[sequenceKey].start).isBetween(
                values.vc[otherSequenceKey].start,
                values.vc[otherSequenceKey].end,
                undefined,
                '[)',
              )
            ) {
              if (typeof errors.vc[sequenceKey] === 'undefined') {
                errors.vc[sequenceKey] = {}
              }

              errors.vc[sequenceKey].start = `${I18nProvider.formatMessage({
                id: 'vc_input.edit.errors.start_same',
              })} #${otherSequenceKey.substring(6)}`
            }

            if (
              moment(values.vc[sequenceKey].end).isBetween(
                values.vc[otherSequenceKey].start,
                values.vc[otherSequenceKey].end,
              )
            ) {
              if (typeof errors.vc[sequenceKey] === 'undefined') {
                errors.vc[sequenceKey] = {}
              }

              errors.vc[sequenceKey].end = `${I18nProvider.formatMessage({
                id: 'vc_input.edit.errors.end_same',
              })} #${otherSequenceKey.substring(6)}`
            }
          }
        })

        // les sessions commencent à 10h00 généralement
        // parfois on veux faire commencer les classes virtuelles à 9h30
        // du coup on ne prends pas en compte l'heure en forcant l'heure à miniut
        const startAtWithoutHours = moment(sessionInfos.startAt).hour(0).minute(0).second(0)

        if (
          sessionInfos &&
          moment(values.vc[sequenceKey].start).isBetween(startAtWithoutHours, sessionInfos.endAt, undefined, '[)') ===
            false
        ) {
          if (typeof errors.vc[sequenceKey] === 'undefined') {
            errors.vc[sequenceKey] = {}
          }

          errors.vc[sequenceKey].start = `${I18nProvider.formatMessage({
            id: 'vc_input.edit.errors.between_session',
            values: {
              start: intl.formatDate(sessionInfos.startAt, numericFormat),
              end: intl.formatDate(sessionInfos.endAt, numericFormat),
            },
          })}`
        }
      }
    })

    return errors
  }

  return (
    <PageQuery
      query={gql`
        query SessionView($sessionId: ID!) {
          session(id: $sessionId) {
            id
            startAt
            endAt
            identifier
            virtualClassrooms {
              id
            }
            course {
              id
              title
              courseVirtualClassrooms {
                id
                sequenceNumber
                title
              }
            }
          }
        }
      `}
      variables={{ sessionId: idSession }}
      render={({ session }) => {
        setSessionInfos(session)
        return (
          <>
            {session.virtualClassrooms.length > 0 && (
              <>
                <Alert style={{ marginTop: '30px', marginBottom: '30px' }}>
                  Les classes virtuelles ont été créées.
                  <br />
                  <br />
                  Vous pouvez consulter la session{' '}
                  <Link to={I18nProvider.getLinkRoute('/expert/sessions/{id}', { id: session.id })}>
                    {session.identifier}
                  </Link>
                  &nbsp; ou retourner à la liste des{' '}
                  <Link to={I18nProvider.getLinkRoute('/expert/virtual-classrooms')}>classes virtuelles</Link>.
                </Alert>
              </>
            )}

            {session.virtualClassrooms.length === 0 && (
              <FinalForm
                validate={validateCvOnSameTime}
                decorators={[scrollOnError]}
                onSubmit={handleSubmitForm}
                subscription={{
                  submitting: true,
                  dirty: true,
                  submitError: true,
                  submitErrors: true,
                  submitFailed: true,
                  submitSucceeded: true,
                }}
                render={({ handleSubmit, submitting, dirty, submitFailed, submitSucceeded, submitError, form }) => {
                  const formState = {
                    submitting,
                    dirty,
                    submitFailed,
                    submitSucceeded,
                    submitError,
                  }

                  return (
                    <form onSubmit={handleSubmit}>
                      <FormHeader
                        {...formState}
                        form={form}
                        title={
                          <FormattedMessage
                            id="vc_input.edit.title"
                            values={{
                              sessionIdentifier: session.identifier,
                            }}
                          />
                        }
                      />
                      Session du {intl.formatDate(session.startAt, numericFormat)} au{' '}
                      {intl.formatDate(session.endAt, numericFormat)}.
                      <Alert style={{ marginTop: '30px', marginBottom: '30px' }}>
                        <FormattedMessage
                          id="vc_input.edit.what_to_do"
                          values={{
                            sessionIdentifier: session.identifier,
                          }}
                        />
                      </Alert>
                      {errorMessage && <Alert theme="error">{errorMessage}</Alert>}
                      <Alert style={{ marginTop: '30px', marginBottom: '30px' }} theme="warning">
                        <FormattedMessage id="vc_input.edit.best_practices.intro" />
                        <ul style={{ margin: 0 }}>
                          <li style={{ listStyleType: 'square' }}>
                            <FormattedMessage id="vc_input.edit.best_practices.line_1" />
                          </li>
                          <li style={{ listStyleType: 'square' }}>
                            <FormattedMessage id="vc_input.edit.best_practices.line_2" />
                          </li>
                        </ul>
                      </Alert>
                      {session.course.courseVirtualClassrooms.map(function (item) {
                        return <EditVirtualClassroomSequence item={item} form={form} />
                      })}
                      <Button
                        type="submit"
                        theme="primary"
                        label=<FormattedMessage id="form.submit_button.save" />
                        loading={submitting || requestLoading}
                        disabled={!dirty}
                        valid={!dirty && submitSucceeded}
                      />
                    </form>
                  )
                }}
              />
            )}
          </>
        )
      }}
    />
  )
}

EditPage.propTypes = {
  match: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
  user: PropTypes.shape().isRequired,
  intl: PropTypes.shape().isRequired,
}

export default injectIntl(withUser(EditPage))
