import React from 'react'
import { FormattedDate as IntlFormattedDate } from 'react-intl'
import moment from 'moment' // workaround for Safari/IE11 date format support

const FormattedDate = ({ value, ...props }) => <IntlFormattedDate value={moment(value)} {...props} />

FormattedDate.propTypes = {
  ...IntlFormattedDate.defaultProps,
}

FormattedDate.propTypes = {
  ...IntlFormattedDate.propTypes,
}

export default FormattedDate
