import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import gql from 'graphql-tag'
import _ from 'lodash'
import { Redirect } from 'react-router-dom'
import { Container } from '@unowmooc/react-ui-kit'
import ErrorBloc from 'components/ErrorBloc'

const QuizRedirect = ({
  location: { pathname },
  match: {
    params: { slugModule },
  },
  session: { modules },
}) => {
  const module = _.find(modules, { slug: slugModule })

  if (module) {
    return <Redirect to={pathname.replace(`/quiz-redirect/${slugModule}`, `/quiz/${module.id}`)} />
  }

  return (
    <Container>
      <ErrorBloc i18nMessage="not_found.resource_not_found" />
    </Container>
  )
}

QuizRedirect.fragments = {
  course: gql`
    fragment _ on Course {
      modules {
        id
        slug
      }
    }
  `,
}

QuizRedirect.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      slugModule: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  session: propType(QuizRedirect.fragments.course).isRequired,
}

export default QuizRedirect
