import I18nProvider from '@unowmooc/i18n'

export const createPathUtils = (slugCourse, sessionId, moduleId) => {
  const getBasePath = (path = '', variables = {}) => {
    if (moduleId) {
      return I18nProvider.getLinkRoute(`/courses/{slugCourse}/{sessionId}/lessons/quiz/{moduleId}${path}`, {
        ...variables,
        slugCourse,
        sessionId,
        moduleId,
      })
    }

    return I18nProvider.getLinkRoute(`/courses/{slugCourse}/{sessionId}/lessons/final-exam${path}`, {
      ...variables,
      slugCourse,
      sessionId,
    })
  }

  const getIntroductionPath = () => getBasePath('/introduction')

  const getQuestionPath = pageNumber => getBasePath('/questions/{pageNumber}', { pageNumber })

  const getResultsPath = () => getBasePath('/results')

  return {
    getBasePath,
    getIntroductionPath,
    getQuestionPath,
    getResultsPath,
  }
}
