import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Transition } from 'react-transition-group'

const Animation = styled.div`
  overflow: hidden;
  transition: ${({ duration }) => `max-height ${duration}ms ease-in-out`};

  max-height: ${({ state, maxHeight }) => {
    switch (state) {
      case 'entering':
      case 'exiting':
        return `${maxHeight}px`
      case 'exited':
        return 0
      case 'entered':
      default:
        return 'none'
    }
  }};
`

const ToggleTransition = ({ in: inProp, duration, children, className }) => {
  const ref = useRef()
  const [height, setHeight] = useState(0)

  useEffect(() => {
    setHeight(ref.current.scrollHeight)
  }, [inProp])

  return (
    <Transition
      in={inProp}
      timeout={{
        appear: 0,
        enter: duration,
        exit: 0,
      }}
    >
      {state => (
        <Animation state={state} maxHeight={height} duration={duration} ref={ref} className={className}>
          {children}
        </Animation>
      )}
    </Transition>
  )
}

ToggleTransition.defaultProps = {
  in: false,
  duration: 300,
  className: undefined,
}

ToggleTransition.propTypes = {
  children: PropTypes.node.isRequired,
  in: PropTypes.bool,
  duration: PropTypes.number,
  className: PropTypes.string,
}

export default ToggleTransition
