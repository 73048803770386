import PropTypes from 'prop-types'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { colors } from 'styles-resources'

const Wrapper = ({ css: cssProp, children }) => (
  <div
    css={css`
      width: 100%;
      height: calc(100% - 60px);
      background: ${colors.white};
      z-index: 100;

      position: fixed;
      top: 60px;
      ${cssProp}
    `}
  >
    {children}
  </div>
)

Wrapper.defaultProps = {
  css: undefined,
}

Wrapper.propTypes = {
  css: PropTypes.shape(),
  children: PropTypes.node.isRequired,
}

export default Wrapper
