import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { Clickable } from '@unowmooc/react-ui-kit'

const animationSpeed = '200ms'

const Button = styled(Clickable)`
  width: 20px;
  height: 20px;
`

const Icon = styled.div`
  display: flex;
  flex-direction: column;
  width: 20px;
  height: 20px;
  justify-content: space-between;
`

const Bar = styled.div`
  height: 2px;
  width: 100%;
  background: ${({ theme: { colors } }) => colors.unowGreen};
  transition: width ${animationSpeed} ease-in-out, transform ${animationSpeed} ease-in-out;
  border-radius: 1px;
`

const BarTop = styled(Bar)`
  ${props =>
    props.active &&
    css`
      width: 0%;
      transform: translate3d(12px, 10px, 0);
    `};
`

const BarBottom = styled(Bar)`
  ${({ active }) =>
    active &&
    css`
      width: 0%;
      transform: translate3d(12px, -10px, 0);
    `};
`

const BarTopMiddle = styled(Bar)`
  ${({ active }) =>
    active &&
    css`
      transform: rotate(45deg) translate3d(2px, 2px, 0);
    `};
`

const BarBottomMiddle = styled(Bar)`
  ${({ active }) =>
    active &&
    css`
      transform: rotate(-45deg) translate3d(2px, -2px, 0);
    `};
`

const HamburgerButton = ({ active, onClick, className }) => (
  <Button onClick={onClick} className={className}>
    <Icon>
      <BarTop active={active} />
      <BarTopMiddle active={active} />
      <BarBottomMiddle active={active} />
      <BarBottom active={active} />
    </Icon>
  </Button>
)

HamburgerButton.defaultProps = {
  active: false,
  className: undefined,
}

HamburgerButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool,
  className: PropTypes.string,
}

export default HamburgerButton
