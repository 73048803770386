import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { propType } from 'graphql-anywhere'
import gql from 'graphql-tag'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'
import Group from './Group'
import Participation from './Group/Participation'

const StyledGroup = styled(Group)`
  margin-bottom: 50px;

  &:last-child {
    margin-bottom: 0;
  }

  ${mq.sm(css`
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  `)};
`

class ParticipationsGroup extends Component {
  get inProgress() {
    const { participations } = this.props

    const inProgressParticipations = _.filter(
      participations,
      ({ status, session, advancement }) =>
        // participation not canceled
        status !== 'canceled' &&
        // and session in progress
        (session.runningStatus === 'in_progress' ||
          // or finished
          (session.runningStatus === 'finished' &&
            // and participant advancement is not complete
            ((!session.hasFinalExam && advancement.status !== 'finisher_not_certified') ||
              (session.hasFinalExam && advancement.status !== 'certified')))),
    )

    // show in progress sessions before finished sessions
    const statusRank = {
      in_progress: 1,
      finished: 2,
    }

    return _.sortBy(inProgressParticipations, ({ session: { runningStatus } }) => statusRank[runningStatus])
  }

  get planned() {
    const { participations } = this.props

    return _.filter(
      participations,
      ({ status, session }) =>
        // The API use "confirmed" running status only for "planned and confirmed" sessions
        status !== 'canceled' && (session.runningStatus === 'planned' || session.runningStatus === 'confirmed'),
    )
  }

  get finished() {
    const { participations } = this.props

    return _.filter(
      participations,
      ({ status, advancement, session }) =>
        // participation not canceled
        status !== 'canceled' &&
        session.runningStatus === 'finished' &&
        // participant advancement is complete
        ((!session.hasFinalExam && advancement.status === 'finisher_not_certified') ||
          (session.hasFinalExam && advancement.status === 'certified')),
    )
  }

  render() {
    const { className } = this.props

    const { inProgress, planned, finished } = this

    return (
      <div className={className}>
        {inProgress.length > 0 && (
          <StyledGroup
            type="progress"
            participations={inProgress}
            label={<FormattedMessage id="dashboard.participations.in_progress.label" />}
          />
        )}

        {planned.length > 0 && (
          <StyledGroup
            type="planned"
            participations={planned}
            label={<FormattedMessage id="dashboard.participations.planned.label" />}
          />
        )}

        {finished.length > 0 && (
          <StyledGroup
            type="finished"
            participations={finished}
            label={<FormattedMessage id="dashboard.participations.finished.label" />}
          />
        )}
      </div>
    )
  }
}

ParticipationsGroup.fragment = gql`
  fragment Participations on Participant {
    id
    session {
      id
      runningStatus
      hasFinalExam
    }
    advancement {
      status
    }
    ...Participation
  }
  ${Participation.fragment}
`

ParticipationsGroup.defaultProps = {
  className: undefined,
}

ParticipationsGroup.propTypes = {
  participations: PropTypes.arrayOf(propType(ParticipationsGroup.fragment)).isRequired,
  className: PropTypes.string,
}

export default ParticipationsGroup
