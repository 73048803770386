import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Tag from '@unowmooc/tags'
import ResponsiveTag from 'components/ResponsiveTag'

class TagSessionStatus extends Component {
  get theme() {
    const { status } = this.props

    switch (status) {
      case 'planned':
        return Tag.themes.lightHighlight
      case 'in_progress':
        return Tag.themes.lightGreen
      case 'confirmed':
        return Tag.themes.lightHighlight
      case 'finished':
        return Tag.themes.grey
      case 'canceled':
        return Tag.themes.lightRed
      default:
        return Tag.themes.lightGrey
    }
  }

  render() {
    const { status, currentWeek, ...props } = this.props

    return (
      <ResponsiveTag {...props} theme={this.theme} data-cy="tag-session-status">
        <div data-cy="card-session-status">
          <FormattedMessage
            id={`session_running_status.${status}`}
            values={{
              currentWeek: status === 'in_progress' ? currentWeek : undefined,
            }}
          />
        </div>
      </ResponsiveTag>
    )
  }
}

TagSessionStatus.defaultProps = {
  currentWeek: undefined,
  className: undefined,
}

TagSessionStatus.propTypes = {
  status: PropTypes.string.isRequired,
  currentWeek: PropTypes.number,
  className: PropTypes.string,
}

export default TagSessionStatus
