import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Box } from 'reflexbox'
import { ViewBoxInfo, Watch } from '@unowmooc/form'

const BoxSessionInfo = () => (
  <Box>
    <Watch fields={['pack', 'course', 'session']}>
      {({ pack, course, session }) => (
        <>
          <ViewBoxInfo width="auto" mr="30px" label={<FormattedMessage id="labels.course" />} value={course.label} />
          <ViewBoxInfo width="auto" mr="30px" label={<FormattedMessage id="labels.session" />} value={session.label} />
          <ViewBoxInfo width="auto" mr="30px" label={<FormattedMessage id="labels.pack" />} value={pack.label} />
        </>
      )}
    </Watch>
  </Box>
)

export default BoxSessionInfo
