import _ from 'lodash'

export function deepPick(object, path) {
  return _.reduce(
    object,
    (accumulator, value, key) => {
      if (_.isObject(value)) {
        return { ...accumulator, [key]: deepPick(value, path) }
      }

      if (key === path) {
        return { ...accumulator, [key]: value }
      }

      return accumulator
    },
    {},
  )
}

export default { deepPick }
