import React from 'react'
import PropTypes from 'prop-types'
import { propType, filter } from 'graphql-anywhere'
import gql from 'graphql-tag'
import { toInlineFragment } from 'fraql'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { Alert } from '@unowmooc/react-ui-kit'
import ShadowBox from '@unowmooc/shadow-box'
import I18nProvider from '@unowmooc/i18n'
import { mq, colors } from '@unowmooc/themes'
import { UserConsumer, useUserContext } from 'store/UserProvider'
import ResponsiveAvatar from 'components/ResponsiveAvatar'
import LinkButtonIcon from 'components/LinkButtonIcon'
import useToggle from 'hooks/useToggle'
import WriteMessageModal from 'modules/conversations/components/WriteMessageExpertProfileModalForm'
import SocialNetworks from './SocialNetworks'

const Wrapper = styled(ShadowBox)`
  padding: 0 30px 30px 30px;
  background-color: ${colors.white};
  text-align: center;

  ${mq.sm(css`
    padding: 0 20px 20px 20px;
  `)};
`

const StyledAvatar = styled(ResponsiveAvatar)`
  position: relative;
  top: -80px;
  margin-bottom: -80px;
  background-color: ${colors.white};

  > img,
  > div {
    position: static;
  }
`

const Fullname = styled.h1`
  margin: 20px auto 25px;
`

const EmptyProfileAlert = styled(Alert)`
  margin-bottom: 25px;
  text-align: left;
`

const JobAndOrganization = styled.p`
  margin-bottom: 25px;
`

const StyledSocialNetworks = styled(SocialNetworks)`
  margin-bottom: 30px;
`

const Presentation = styled.p`
  margin-bottom: 30px;
  font-size: 14px;
  line-height: 23px;
  text-align: left;
  color: ${colors.midGray};
`

const ContactButton = styled(LinkButtonIcon, { shouldForwardProp: prop => prop !== 'className' })`
  margin-top: 16px;
`

const UserDetails = ({
  user,
  user: {
    id,
    fullName,
    fonction,
    englishFonction,
    organization,
    presentation,
    englishPresentation,
    twitter,
    linkedIn,
    website,
    canBeContactedThroughConversationsByAuthenticatedAnimator,
  },
  className,
}) => {
  const {
    user: { id: authenticatedUserId, locale: authenticatedUserLocale },
  } = useUserContext()

  const [writeMessageModal, { open: openWriteMessageModal, close: closeWriteMessageModal }] = useToggle()
  let presentationText = null
  let fonctionText = null
  if (authenticatedUserLocale === 'fr') {
    presentationText = presentation
    fonctionText = fonction
  }
  if (authenticatedUserLocale === 'en') {
    presentationText = englishPresentation
    fonctionText = englishFonction
  }
  return (
    <>
      <Wrapper className={className}>
        <StyledAvatar size={160} user={filter(ResponsiveAvatar.fragments.user, user)} />

        <Fullname>{fullName || <FormattedMessage id="commons.unspecified" />}</Fullname>

        <UserConsumer>
          {({ user: me }) =>
            id === me.id &&
            (!fullName || (!presentationText && !organization && !presentationText)) && (
              <EmptyProfileAlert>
                <FormattedHTMLMessage id="profile.user_details.empty_profile" />
              </EmptyProfileAlert>
            )
          }
        </UserConsumer>

        {(fonctionText || organization) && (
          <JobAndOrganization>
            {fonctionText}
            {fonctionText && organization && <br />}
            {organization}
          </JobAndOrganization>
        )}

        <Presentation>{presentationText}</Presentation>

        {(twitter || linkedIn || website) && (
          <StyledSocialNetworks user={filter(SocialNetworks.fragments.user, user)} />
        )}

        {id === authenticatedUserId && (
          <LinkButtonIcon
            tag={Link}
            to={I18nProvider.getLinkRoute('/settings')}
            theme="secondary"
            icon="pencil"
            iconColor={colors.unowGreen}
            size="small"
            width="100%"
          >
            <FormattedMessage id="profile.user_details.settings_link" />
          </LinkButtonIcon>
        )}
        {canBeContactedThroughConversationsByAuthenticatedAnimator && (
          <ContactButton
            tag="button"
            onClick={openWriteMessageModal}
            theme="secondary"
            icon="mail"
            iconColor={colors.unowGreen}
            size="small"
            width="100%"
          >
            <FormattedMessage id="profile.user_details.contact" />
          </ContactButton>
        )}
      </Wrapper>

      {writeMessageModal && (
        <WriteMessageModal participantUserId={id} participantUserFullName={fullName} onClose={closeWriteMessageModal} />
      )}
    </>
  )
}

UserDetails.fragments = {
  user: gql`
    fragment _ on User {
      id
      fullName
      presentation
      englishPresentation
      fonction
      englishFonction
      organization
      slug
      twitter
      linkedIn
      website
      canBeContactedThroughConversationsByAuthenticatedAnimator
      ${toInlineFragment(SocialNetworks.fragments.user)}
      ${toInlineFragment(ResponsiveAvatar.fragments.user)}
    }
  `,
}

UserDetails.defaultProps = {
  className: undefined,
}

UserDetails.propTypes = {
  user: propType(UserDetails.fragments.user).isRequired,
  className: PropTypes.string,
}

export default UserDetails
