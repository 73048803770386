import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import gql from 'graphql-tag'
import _ from 'lodash'
import s from 'underscore.string'
import { Link } from 'react-router-dom'
import { Visible } from 'react-grid-system'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'
import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl'
import I18nProvider from '@unowmooc/i18n'
import StreamlineIcon from '@unowmooc/icons'
import Badge from 'components/Badge'
import SmallBadge from 'components/SmallBadge'

const BadgesWrapper = styled.div`
  margin-bottom: 14px;
  text-align: center;

  ${mq.sm(css`
    margin-bottom: 20px;
  `)};
`

const StyledBadge = styled(Badge)`
  width: 92px;
  height: 92px;
  margin-right: 34px;
  margin-bottom: 16px;
  opacity: ${({ missing }) => (missing ? 0.2 : 1)};

  &:last-of-type {
    margin-right: 0;
  }
`

const StyledSmallBadge = styled(SmallBadge)`
  margin-right: 40px;
  opacity: ${({ missing }) => (missing ? 0.2 : 1)};

  &:last-of-type {
    margin-right: 0;
  }
`

const Title = styled.h4`
  margin-bottom: 20px;

  ${mq.sm(css`
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 28px;
  `)};
`

const Description = styled.p`
  margin-bottom: 20px;
  line-height: 35px;

  ${mq.sm(css`
    font-size: 16px;
    line-height: 32px;
  `)};
`

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 0;
  }
`

const ArrowIcon = styled(StreamlineIcon)`
  margin-right: 10px;
  font-size: 24px;
  line-height: 15px;
`

const hasBadge = (badges, module) => !!_.find(badges, { module: { id: module.id } })
const getMissingModules = (badges, modules) =>
  _.filter(modules, module => !_.some(badges, { module: { id: module.id } }))

const getModuleNumbersSentence = modules => {
  const numbers = modules.map(module => module.publicPosition + 1)

  return s.toSentence(
    numbers,
    I18nProvider.formatMessage({ id: 'commons.enum_separator' }),
    I18nProvider.formatMessage({ id: 'commons.enum_last_separator' }),
  )
}

const MissingBadges = ({ session, badges, modules, className }) => {
  const missingModules = getMissingModules(badges, modules)

  return (
    <div className={className}>
      <BadgesWrapper>
        <Visible md lg xl>
          {modules.map(module => (
            <StyledBadge missing={!hasBadge(badges, module)} number={module.publicPosition + 1} key={module.id} />
          ))}
        </Visible>

        <Visible xs sm>
          {modules.map(module => (
            <StyledSmallBadge missing={!hasBadge(badges, module)} number={module.publicPosition + 1} key={module.id} />
          ))}
        </Visible>
      </BadgesWrapper>
      <Title>
        <FormattedMessage id="final_exam.intro.missing_badges.title" />
      </Title>
      <Description>
        <strong>
          <FormattedMessage
            id="final_exam.intro.missing_badges.modules_to_validate"
            values={{
              missingModuleNumbers: getModuleNumbersSentence(missingModules),
              missingModuleCounter: missingModules.length,
            }}
          />
        </strong>
        <br />
        <FormattedMessage id="final_exam.intro.missing_badges.description" />
      </Description>

      {missingModules.map(module => (
        <LinkWrapper key={module.id}>
          <Link
            to={I18nProvider.getLinkRoute('/courses/{slugCourse}/{idSession}/lessons/quiz/{idModule}', {
              slugCourse: session.course.slug,
              idSession: session.id,
              idModule: module.id,
            })}
          >
            <ArrowIcon icon="right" />
            <FormattedMessage
              id="final_exam.intro.missing_badges.access_quiz_link"
              values={{ moduleNumber: module.publicPosition + 1 }}
            />
          </Link>
        </LinkWrapper>
      ))}
    </div>
  )
}

MissingBadges.fragments = {
  session: gql`
    fragment _ on Session {
      id
      course {
        id
        slug
      }
    }
  `,
  badge: gql`
    fragment _ on Badge {
      id
      module {
        id
      }
    }
  `,
  module: gql`
    fragment _ on Module {
      id
      publicPosition
    }
  `,
}

MissingBadges.defaultProps = {
  className: undefined,
}

MissingBadges.propTypes = {
  session: propType(MissingBadges.fragments.session).isRequired,
  badges: PropTypes.arrayOf(propType(MissingBadges.fragments.badge)).isRequired,
  modules: PropTypes.arrayOf(propType(MissingBadges.fragments.module)).isRequired,
  className: PropTypes.string,
}

export default MissingBadges
