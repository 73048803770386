import React from 'react'
import PropTypes from 'prop-types'
import MediaQuery from 'react-responsive'
import Avatar from '@unowmooc/avatar'
import ShadowBox from '@unowmooc/shadow-box'
import styled from '@emotion/styled'

const Thread = styled(ShadowBox)`
  position: relative;
`

const StyledAvatar = styled(Avatar)`
  position: absolute;
  top: 23px;
  left: 9px;
`

const ThreadCreator = ({ editor, avatarSrc, fullName, className, editorAvatarClassName }) => (
  <Thread className={className}>
    <MediaQuery minWidth={768}>
      <StyledAvatar className={editorAvatarClassName} size={30} src={avatarSrc} name={fullName} />
    </MediaQuery>
    {editor}
  </Thread>
)

ThreadCreator.defaultProps = {
  avatarSrc: undefined,
  fullName: undefined,
  className: undefined,
  editorAvatarClassName: undefined,
}

ThreadCreator.propTypes = {
  avatarSrc: PropTypes.string,
  fullName: PropTypes.string,
  editor: PropTypes.element.isRequired,
  className: PropTypes.string,
  editorAvatarClassName: PropTypes.string,
}

export default ThreadCreator
