import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import StreamlineIcon from '@unowmooc/icons'
import styled from '@emotion/styled'

const StyledStreamlineIcon = styled(StreamlineIcon)`
  font-size: 22px;
`

const DownloadResultsLink = ({ url, className }) => (
  <a className={className} href={url} target="_blank" rel="noopener noreferrer">
    <FormattedMessage id="supervisor.download_link.label" />
    <StyledStreamlineIcon icon="download" />
  </a>
)

DownloadResultsLink.defaultProps = {
  className: undefined,
}

DownloadResultsLink.propTypes = {
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
}
export default DownloadResultsLink
