import React from 'react'
import PropTypes from 'prop-types'
import { propType, filter } from 'graphql-anywhere'
import { toInlineFragment } from 'fraql'
import gql from 'graphql-tag'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'
import StreamlineIcon from '@unowmooc/icons'
import { colors } from 'styles-resources'
import ResponsiveAvatar from 'components/ResponsiveAvatar'
import ReadMore from 'components/ReadMore'
import { useCourseContext } from 'modules/course/components/CourseContext/CourseContext'
import InfoBloc from '../InfoBloc'

const User = styled.div`
  display: flex;
  align-items: center;
`

const StyledAvatar = styled(ResponsiveAvatar)`
  margin-right: 10px;
  font-size: 28px;
`

const Name = styled.h4`
  overflow: hidden;
  font-size: 18px;
  line-height: 28px;
`

const Presentation = styled.p`
  margin-top: 18px;
  font-size: 14px;
  line-height: 24px;
  overflow: hidden;
  color: ${colors.shipGray};
`

const SocialNetworks = styled.div`
  margin-top: 18px;
`

const SocialLink = styled.a`
  margin-right: 14px;
  font-size: 24px;

  &:last-child {
    margin-right: 0;
  }
`

const TwitterIcon = styled(StreamlineIcon)`
  color: ${colors.twitter};
`

const LinkedInIcon = styled(StreamlineIcon)`
  color: ${colors.linkedin};
`

const PresentationBlock = ({ courseLocale, presentation, englishPresentation }) => {
  let text = null
  if (courseLocale === 'fr') {
    text = presentation
  }
  if (courseLocale === 'en') {
    text = englishPresentation
  }

  return (
    <>
      {text && (
        <Presentation>
          <ReadMore>{text}</ReadMore>
        </Presentation>
      )}
    </>
  )
}

const MainAnimator = ({
  user,
  user: { gender, fullName, presentation, englishPresentation, twitter, linkedIn },
  className,
}) => {
  const { courseLocale } = useCourseContext()

  return (
    <InfoBloc
      title={<FormattedMessage id="courses.course.infos.main_animator.title" values={{ gender }} />}
      className={className}
    >
      <User>
        <StyledAvatar size={50} user={filter(ResponsiveAvatar.fragments.user, user)} />
        <Name>{fullName}</Name>
      </User>
      <PresentationBlock
        courseLocale={courseLocale}
        presentation={presentation}
        englishPresentation={englishPresentation}
      />
      {(linkedIn || twitter) && (
        <SocialNetworks>
          {linkedIn && (
            <SocialLink href={linkedIn} target="_blank" rel="noopener noreferrer" title="LinkedIn">
              <LinkedInIcon icon="linkedin" />
            </SocialLink>
          )}

          {twitter && (
            <SocialLink href={twitter} target="_blank" rel="noopener noreferrer" title="Twitter">
              <TwitterIcon icon="twitter" />
            </SocialLink>
          )}
        </SocialNetworks>
      )}
    </InfoBloc>
  )
}

MainAnimator.fragments = {
  user: gql`
    fragment _ on User {
      id
      fullName
      twitter
      linkedIn
      gender
      presentation
      englishPresentation
      ${toInlineFragment(ResponsiveAvatar.fragments.user)}
    }
  `,
}

MainAnimator.defaultProps = {
  className: undefined,
}

MainAnimator.propTypes = {
  user: propType(MainAnimator.fragments.user).isRequired,
  className: PropTypes.string,
}

PresentationBlock.propTypes = {
  courseLocale: PropTypes.string.isRequired,
  presentation: PropTypes.string.isRequired,
  englishPresentation: PropTypes.string.isRequired,
}

export default MainAnimator
