import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import gql from 'graphql-tag'
import styled from '@emotion/styled'
import Avatar from '@unowmooc/avatar'

const StyledAvatar = styled(Avatar)`
  flex-shrink: 0;

  img {
    display: block;
  }
`

const ResponsiveAvatar = ({ user: { fullName, avatar }, size, className }) => (
  <StyledAvatar size={size} name={fullName} file={avatar} className={className} />
)

ResponsiveAvatar.fragments = {
  user: gql`
    fragment ResponsiveAvatar on User {
      fullName
      avatar {
        id
        mimeType
        fileStackId
        signature
        policy
      }
    }
  `,
}

ResponsiveAvatar.defaultProps = {
  size: 40,
  className: undefined,
}

ResponsiveAvatar.propTypes = {
  user: propType(ResponsiveAvatar.fragments.user).isRequired,
  size: PropTypes.number,
  className: PropTypes.string,
}

export default ResponsiveAvatar
