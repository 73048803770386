import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl'

const SubjectBox = styled.div`
  margin-bottom: 16px;
  color: ${({ theme: { colors } }) => colors.shipGray};
  font-size: 16px;
  font-weight: bold;
  line-height: 26px;
`

const Subject = ({ text }) => (
  <SubjectBox>
    <FormattedMessage id="commons.subject" />
    &nbsp;:&nbsp;{text}
  </SubjectBox>
)
Subject.propTypes = {
  text: PropTypes.string.isRequired,
}

export default Subject
