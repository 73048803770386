import React from 'react'
import { FormattedHTMLMessage } from 'react-intl'
import { Fieldset } from '@unowmooc/react-ui-kit'

const FieldsetIntl = ({ legend, help, id, ...props }) => {
  const i18nLegend = legend ? <FormattedHTMLMessage id={legend} /> : undefined
  const i18nHelp = help ? <FormattedHTMLMessage id={help} /> : undefined

  return <Fieldset legend={i18nLegend} help={i18nHelp} id={id || legend} {...props} />
}

FieldsetIntl.defaultProps = {
  ...Fieldset.defaultProps,
}

FieldsetIntl.propTypes = {
  ...Fieldset.propTypes,
}

export default FieldsetIntl
