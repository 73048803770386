import { useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/browser'
import ReactGA from 'react-ga4'
import useEventListener from 'hooks/useEventListener'
import { getUserRoleForGA } from 'modules/tracking/tracking.utils'
import useMe from 'hooks/useMe'
import config from 'config'

const configureTrackers = me => {
  // Sentry tracker
  Sentry.configureScope(scope => {
    scope.setUser({ id: me.id })
  })

  if (config.env.GA_TAG) {
    ReactGA.initialize(config.env.GA_TAG)
  }

  const userProperties = {}

  if (me) {
    userProperties.dimension1 = getUserRoleForGA(me)
    userProperties.dimension2 = me.id

    if (me.account) {
      userProperties.dimension3 = `${me.account.id} ${me.account.name}`
    } else {
      userProperties.dimension3 = '0 non défini'
    }
  }

  ReactGA.set({ user_properties: userProperties })
}

const RequiredAuthenticationZone = ({ render }) => {
  const { loading, me, refetch } = useMe()

  const refetchMe = () => {
    if (!document.hidden) {
      refetch()
    }
  }

  useEventListener('visibilitychange', refetchMe)

  useEffect(() => {
    if (me) {
      configureTrackers(me)
    }
  }, [me])

  if (loading) {
    return null
  }

  return render({ user: me })
}

RequiredAuthenticationZone.propTypes = {
  render: PropTypes.func.isRequired,
}

export default RequiredAuthenticationZone
