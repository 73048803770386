import { useEffect } from 'react'
import { useCourseContext } from './CourseContext/CourseContext'

const Breadcrumb = ({ moduleLabel }) => {
  const { breadcrumbUtils } = useCourseContext()

  useEffect(() => {
    breadcrumbUtils.setModule({ label: moduleLabel })

    return () => {
      breadcrumbUtils.cleanModule()
    }
  }, [moduleLabel])

  return null
}

export default Breadcrumb
