import React from 'react'
import PropTypes from 'prop-types'
import { FormattedHTMLMessage } from 'react-intl'
import Field from 'components/Field'

const FieldIntl = ({ label, labelValues, help, helpBottom, helpValues, helpBottomValues, ...props }) => {
  const i18nLabel = label ? <FormattedHTMLMessage id={label} values={labelValues} /> : undefined
  const i18nHelp = help ? <FormattedHTMLMessage id={help} values={helpValues} /> : undefined
  const i18nHelpBottom = helpBottom ? <FormattedHTMLMessage id={helpBottom} values={helpBottomValues} /> : undefined

  return <Field label={i18nLabel} help={i18nHelp} helpBottom={i18nHelpBottom} {...props} />
}

FieldIntl.defaultProps = {
  label: undefined,
  help: undefined,
  helpBottom: undefined,
  labelValues: {},
  helpValues: {},
  helpBottomValues: {},
}

FieldIntl.propTypes = {
  label: PropTypes.string,
  help: PropTypes.string,
  helpBottom: PropTypes.string,
  labelValues: PropTypes.shape(),
  helpValues: PropTypes.shape(),
  helpBottomValues: PropTypes.shape(),
  ...Field.propTypes,
}

export default FieldIntl
