import React from 'react'
import { propType } from 'graphql-anywhere'
import gql from 'graphql-tag'
import Collector from 'modules/collector/components/Collector'
import { ShadowBox } from '@unowmooc/react-ui-kit'
import styled from '@emotion/styled'
import { FormattedHTMLMessage } from 'react-intl'
import { toInlineFragment } from 'fraql'
import { animateScrollTo } from 'utils/scroll'
import { useCourseContext } from 'modules/course/components/CourseContext/CourseContext'
import ThanksScreen from './ThanksScreen'

const StyledShadowBox = styled(ShadowBox)`
  padding: 24px 30px;
`

/*
Ici on a hardcodé l'introduction QFF : ce n'est vraiment pas idéal.
Ce content est censé être un "FormBlock", il prend un formid et un contexte et l'affiche.
Sur le même principe, on a aussi le ThanksScreen qui n'a pas sa place ici : on perd la généricité du composant.
Par contre pour le moment ce composant n'est utilisé que pour QFF.
Si on veut le rendre générique, il y a plusieurs pistes :
- Pour le message de remerciement, le passer dans la configuration du bloc cans l'admin (mais du coup il restait répété à chaque fois et cela poserait des questions de maintenance). Pour cela on ajouterais des
champs d'introduction et de remerciement en base dans la table content_form, administrables dans la modale du bloc dans l'admin.
- Pour l'introduction, la passer dans un bloc texte au-dessus (mais là aussi la maintenance ne serait pas idéal, en cas de changement de wording, il faudrait repasser sur toutes les formations).
Pour le moment on a pas de solution idéale là-dessus. Prochainement il viendra un travail sur un mécanisme de CMS, pour pouvoir réutiliser des blocs sur plusieurs formations.
À ce moment là on pourra donc déplacer ce bloc d'introduction dans ce mécanisme de CMS.
Toi qui entre ici et voit cette chose hardcodée, n'abandonne pas toute espérance, cela a été fait en connaissance de cause et avec un plan pour corriger ça dans le futur.
Autre point, vu que le QFF n'est pas traduit, l'introduction n'est pas traduite non plus.
*/
const TopFragment = () => (
  <>
    <div style={{ marginBottom: '70px' }}>
      <p>
        <FormattedHTMLMessage id="qff.intro.line_1" />
      </p>
      <br />
      <p>
        <FormattedHTMLMessage id="qff.intro.line_2" />
      </p>
      <p>
        <FormattedHTMLMessage id="qff.intro.line_3" />
      </p>
      <p>
        <FormattedHTMLMessage id="qff.intro.line_4" />
      </p>
      <br />
      <p>
        <FormattedHTMLMessage id="qff.intro.line_5" />
      </p>
    </div>
  </>
)

const FormBlock = ({ block }) => {
  const { participantId } = useCourseContext()

  const handleSubmitSuccess = () => {
    animateScrollTo(document.body, { duration: 0 })
  }

  return (
    <>
      {block.formId !== null && block.context !== null && (
        <StyledShadowBox>
          <Collector
            formUnansweredTopComponent={TopFragment}
            formId={block.formId}
            participantId={participantId}
            formContext={block.context}
            onSubmit={handleSubmitSuccess}
            formAlreadyAnsweredScreen={<ThanksScreen />}
          />
        </StyledShadowBox>
      )}
    </>
  )
}

FormBlock.fragment = toInlineFragment(
  gql`
    fragment _ on Block {
      ... on FormBlock {
        blockType
        formId
        context
      }
    }
  `,
)

FormBlock.propTypes = {
  block: propType(FormBlock.fragment).isRequired,
}

export default FormBlock
