import React from 'react'
import PropTypes from 'prop-types'
import { propType, filter } from 'graphql-anywhere'
import { toInlineFragment } from 'fraql'
import gql from 'graphql-tag'
import I18nProvider from '@unowmooc/i18n'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'
import { FormattedMessage } from 'react-intl'
import Tag from '@unowmooc/tags'
import Helmet from 'components/HelmetIntl'
import PageQuery from 'components/PageQuery'
import Comments from 'modules/comments/components/Comments'
import { useCourseContext } from 'modules/course/components/CourseContext/CourseContext'
import useMe from 'hooks/useMe'
import Breadcrumb from 'modules/course/components/Breadcrumb'
import ScrollToTopButton from 'components/ScrollToTopButton'
import * as sectionBusiness from 'business/section'
import { Redirect } from 'react-router-dom'
import * as Sentry from '@sentry/browser'
import ProgressionManager from './ProgressionManager'
import ModuleTitle from './ModuleTitle'
import NbCommentsLink from './NbCommentsLink'
import NavBar from '../NavBar'
import Downloads from './Downloads'
import Bonuses from './Bonuses'
import ShowSectionAccessError from '../ShowSectionAccessError'
import Blocks from './Blocks'

const StyledModuleTitle = styled(ModuleTitle)`
  margin-bottom: 20px;
`

const TagWrapper = styled.div`
  ${mq.sm(css`
    margin-bottom: 10px;
  `)};
`

const SectionTitle = styled.h1`
  margin-bottom: 5px;
`

const StyledBlocks = styled(Blocks)`
  margin-bottom: 80px;

  ${mq.sm(css`
    margin-bottom: 50px;
  `)};
`

const ResourcesTitle = styled.h4`
  margin-bottom: 25px;
`

const ResourcesParagraph = styled.p`
  margin-bottom: 25px;
`

const Resources = styled.div`
  margin-bottom: 50px;
`

const StyledDownloads = styled(Downloads)`
  margin-bottom: 50px;
`

const StyledNavBar = styled(NavBar)`
  margin-bottom: 70px;
`

const SectionPage = ({
  session,
  section,
  section: { title, status, hasCommentsEnabled, configuration, downloads, bonuses, module },
  nbComments,
}) => {
  const { getErrorsShowModule, courseIdentifier, sessionIdentifier, sessionUrl } = useCourseContext()

  if (!session.hasAccessModuleZero && section.module.isZero) {
    Sentry.captureMessage(`[suppression module 0] Redirection automatique vers la page programme.`)

    return <Redirect to={`${sessionUrl}${I18nProvider.getLinkRoute(`program`)}`} />
  }

  const errorsShowModule = getErrorsShowModule({ isModuleZero: module.isZero })
  if (errorsShowModule.length > 0) {
    return <ShowSectionAccessError errors={errorsShowModule} />
  }

  const {
    me: { isLms },
  } = useMe()

  const displayComments = !isLms && hasCommentsEnabled

  const moduleLabel = `${I18nProvider.formatMessage({
    id: `sections.module_${module.bonus ? 'bonus_title' : 'title'}`,
    values: { number: module.isZero ? 0 : module.publicPosition + 1 },
  })} ${module.title}`

  return (
    <>
      <Helmet title="page_titles.sections.title" values={{ title }} />
      <Breadcrumb moduleLabel={moduleLabel} />
      <ProgressionManager sectionId={section.id} sectionStatus={status} />
      <StyledModuleTitle section={filter(ModuleTitle.fragments.section, section)} />

      {status === sectionBusiness.STATUS_DRAFT && (
        <TagWrapper>
          <Tag theme={Tag.themes.red} small>
            <FormattedMessage id="sections.draft" />
          </Tag>
        </TagWrapper>
      )}

      <SectionTitle>{title}</SectionTitle>
      {displayComments && (
        <NbCommentsLink
          to="#comments"
          nbComments={nbComments._meta.total}
          courseIdentifier={courseIdentifier}
          sessionIdentifier={sessionIdentifier}
          sectionId={section.id}
        />
      )}

      <StyledBlocks configuration={configuration} />

      {(downloads.length > 0 || bonuses.length > 0) && (
        <Resources>
          {downloads.length > 0 && (
            <>
              <ResourcesTitle>
                <FormattedMessage id="sections.downloads_title" />
              </ResourcesTitle>

              <StyledDownloads downloads={filter(Downloads.fragments.file, downloads)} />
            </>
          )}

          {bonuses.length > 0 && (
            <>
              <ResourcesTitle>
                <FormattedMessage id="sections.bonuses_title" />
              </ResourcesTitle>

              <ResourcesParagraph>
                <FormattedMessage id="sections.bonuses_desc" />
              </ResourcesParagraph>

              <Bonuses bonuses={filter(Bonuses.fragments.link, bonuses)} />
            </>
          )}
        </Resources>
      )}

      <StyledNavBar
        session={filter(NavBar.fragments.session, session)}
        section={filter(NavBar.fragments.section, section)}
      />

      {displayComments && (
        <div id="comments">
          <ResourcesTitle>
            <FormattedMessage id="sections.comments_title" />
          </ResourcesTitle>
          <ResourcesParagraph>
            <FormattedMessage id="sections.comments_explanation" />
            <br />
            <FormattedMessage id="sections.comments_explanation2" />
          </ResourcesParagraph>
          <Comments
            session={filter(Comments.fragments.session, session)}
            section={filter(Comments.fragments.section, section)}
          />
        </div>
      )}
      <ScrollToTopButton
        dataTrackingId="session.click_on_scroll_to_top_button"
        dataTrackingValues={JSON.stringify({
          courseIdentifier,
          sessionIdentifier,
          sectionId: section.id,
        })}
      />
    </>
  )
}

SectionPage.propTypes = {
  session: PropTypes.shape({
    hasAccessModuleZero: PropTypes.bool.isRequired,
  }).isRequired,
  section: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    hasCommentsEnabled: PropTypes.bool.isRequired,
    configuration: PropTypes.arrayOf(
      PropTypes.shape({
        blockType: PropTypes.string.isRequired,
      }),
    ).isRequired,
    downloads: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    bonuses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    module: PropTypes.shape({
      isZero: PropTypes.bool.isRequired,
      bonus: PropTypes.bool.isRequired,
      publicPosition: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  nbComments: PropTypes.shape({
    _meta: PropTypes.shape({
      total: PropTypes.number.isRequired,
    }),
  }).isRequired,
}

const SectionPageContainer = ({
  session,
  match: {
    params: { idSection },
  },
}) => (
  <PageQuery
    /*
      Attention à ce fetchPolicy à no-cache, sans celui-ci. Il ne fonctionne pas.

      Pour faire fonctionner la récupération des interfaces, on pourrait imaginer dans le fichier src/utils/graphql.js ajouter cela :
          const cache = new InMemoryCache({
           fragmentMatcher: new IntrospectionFragmentMatcher({
             introspectionQueryResultData: {
               __schema: {
                 types: [
                   {
                     kind: 'INTERFACE',
                     name: 'Block',
                     possibleTypes: [
                       { name: 'NewChallengeBlock' },
                       { name: 'FormBlock' },
                       { name: 'IframeBlock' },
                       { name: 'InitialSkillsBlock' },
                       { name: 'ScriptBlock' },
                       { name: 'SurveyBlock' },
                       { name: 'TextBlock' },
                       { name: 'VideoBlock' },
                       { name: 'VirtualClassroomBlock' },
                     ],
                   },
                 ],
               },
             },
           }),
          )

           export const client = new ApolloClient({
          -  cache,
          +  cache: new InMemoryCache(),


      Cela permettait de mettre en cache les différents blocs qui vont être récupérés par cette requête et qui utilisent une interface commune.
      (cf document de GraphQL sur les fragments d'unions et interfaces : https://www.apollographql.com/docs/react/v2/data/fragments/#fragments-on-unions-and-interfaces)

      Et avec cela la requête fonctionnerait.
      Mais cela aura des effets de bord.

      En effet, il y aurait notamment l'ajout de réponse dans les commentaires qui ne fonctionnerait pas.
      La gestion des commentaires utilise extensivement de l'écriture dans le cache.
      (Toute la gestion des commentaires pourrait être nettement simplifiée dans devoir faire ce genre de hack, en faisant les choses comme la gestion de la messagerie privée).

      On a donc lors de cet appel au writeFragment :  https://github.com/unowmooc/hub/blob/243aed906a063f7e6fcad9d5f65a38dab0279fe1/src/modules/comments/components/Comments/index.js#L165-L171
      Une erreur ` Error: Error writing result to store for query` si on configure le fragmentMatcher.
      Pour le moment la raison de cette erreur n'a pas été trouvée.

      Pour corriger cela, l'idéal serait, entre-autres, de refondre la gestion des commentaires, ce qui serait un chantier relativement long.
      On part donc ici sur une autre solution en ne configurant pas de fragmentMatcher et en ne mettant pas en cache les résultats de la requête.
      Cela ne devrait pas avoir d'incidence notamment : en effet le temps de chargement d'une page déjà consultée sera maintenant
      le même qu'une page non consultée au cours de la même session (mais on pouvait questionner l'intérêt de ce cache : cela n'était pas sûr qu'il soit réellement utile/utilisé).
     */
    fetchPolicy="no-cache"
    query={gql`
        query SectionPage($idSection: ID!, $nbCommentsFilters: Json, $idSession: ID!) {
          section(id: $idSection) {
            id
            status
            title
            hasCommentsEnabled
            configuration {
              blockType
              ${toInlineFragment(Blocks.fragments.configuration)}
            }
            downloads {
              id
              ${toInlineFragment(Downloads.fragments.file)}
            }
            bonuses {
              id
              ${toInlineFragment(Bonuses.fragments.link)}
            }
            module {
              id
              isZero
              bonus
              publicPosition
              title
            }
            ${toInlineFragment(ModuleTitle.fragments.section)}
            ${toInlineFragment(Comments.fragments.section)}
            ${toInlineFragment(NavBar.fragments.section)}
          }

          nbComments: comments(filters: $nbCommentsFilters) {
            _meta {
              total
            }
          }

          session(id: $idSession) {
            id
          }
        }
      `}
    variables={{
      idSection,
      nbCommentsFilters: JSON.stringify({
        session_id: session.id,
        section_id: idSection,
        deletedAt: null,
      }),
      // L'idSession sert ici à dynamiser le contenu des blocs de la section par rapport à la session courante.
      // Il s'agit d'un "hack" à destination du resolver GraphQL pour transmettre des informations de contextualisation.
      idSession: session.id,
    }}
    render={renderProps => <SectionPage {...renderProps} session={session} />}
  />
)

SectionPageContainer.fragments = {
  session: gql`
    fragment _ on Session {
      id
      ${toInlineFragment(Comments.fragments.session)}
      ${toInlineFragment(NavBar.fragments.session)}
    }
  `,
}

SectionPageContainer.propTypes = {
  session: propType(SectionPageContainer.fragments.session).isRequired,
  participantId: PropTypes.string.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      idSection: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export default SectionPageContainer
