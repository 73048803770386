import React, { Component } from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import moment from 'moment'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'
import { FormattedMessage } from 'react-intl'
import { isNight } from 'utils/date'
import Helmet from 'components/HelmetIntl'
import { withUser } from 'store/UserProvider'
import PageQuery from 'components/PageQuery'
import RouteTabs from 'components/RouteTabs'
import EmptyState from './EmptyState'
import Session from './Session'

const Title = styled.h2`
  margin-top: 20px;
  margin-bottom: 56px;

  ${mq.sm(css`
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
  `)};
`

const SubTitle = styled.h3`
  margin-bottom: 34px;

  ${mq.sm(css`
    margin-bottom: 20px;
  `)};
`

const StyledTabs = styled(RouteTabs)`
  margin-bottom: 45px;

  ${mq.sm(css`
    margin: 0 -15px 20px -15px;
  `)};
`

const StyledSession = styled(Session)`
  margin-bottom: 30px;

  ${mq.sm(css`
    margin-bottom: 20px;
  `)};

  &:last-child {
    margin-bottom: 0;
  }
`

class List extends Component {
  get filters() {
    const { status, user } = this.props

    const now = moment().format()

    const filters = { animations_user_id: user.id }

    switch (status) {
      case 'in_progress':
        return { ...filters, startAt: { lte: now }, endAt: { gt: now }, canceledAt: null }
      case 'finished':
        return { ...filters, endAt: { lte: now }, canceledAt: null }
      case 'planned':
        return { ...filters, startAt: { gt: now }, canceledAt: null }
      case 'all':
      default:
        return { ...filters }
    }
  }

  get variables() {
    const { filters } = this
    const { status } = this.props

    return { filters: JSON.stringify(filters), sort: [`startAt ${status === 'planned' ? 'ASC' : 'DESC'}`] }
  }

  render() {
    const {
      user,
      status,
      statuses,
      location: { pathname },
    } = this.props
    const { variables } = this

    return (
      <>
        <Helmet title="page_titles.expert.sessions.title" />
        <Title>
          <FormattedMessage
            id={`expert.title_${isNight() ? 'night' : 'day'}`}
            values={{ firstName: user.firstName, gender: user.gender }}
          />
        </Title>
        <SubTitle>
          <FormattedMessage id="expert.your_sessions" />
        </SubTitle>

        <StyledTabs pathname={pathname} messageBaseKey="sessions.tabs" items={statuses} />
        <PageQuery
          query={gql`
            query ExpertSessionsList($filters: Json, $sort: [String]) {
              sessions(filters: $filters, sort: $sort) {
                _meta {
                  total
                }
                items {
                  id
                  ...Session
                }
              }
            }
            ${Session.fragment}
          `}
          variables={variables}
          render={({ sessions: { items } }) => (
            <>
              <Helmet title={`page_titles.expert.sessions.${status}`} />
              {items.length ? (
                items.map(session => <StyledSession session={session} key={session.id} />)
              ) : (
                <EmptyState status={status} />
              )}
            </>
          )}
        />
      </>
    )
  }
}

List.propTypes = {
  user: PropTypes.shape().isRequired,
  status: PropTypes.oneOf(['in_progress', 'planned', 'finished', 'all']).isRequired,
  statuses: PropTypes.arrayOf(PropTypes.string).isRequired,
  location: PropTypes.shape().isRequired,
}

export default withUser(List)
