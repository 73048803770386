import React, { useContext } from 'react'
import { LocaleContext } from 'components/LocaleProvider'
import UWysiwyg from '@unowmooc/wysiwyg'

const Wysiwyg = props => {
  const { locale } = useContext(LocaleContext)

  return <UWysiwyg {...props} locale={locale} />
}

export default Wysiwyg
