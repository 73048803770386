import React, { useLayoutEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'

export const createPortalComponent = () => {
  const context = { element: null, set: null }

  const setElement = element => {
    context.element = element
    if (context.set && context.set.current) {
      context.set.current(element)
    }
  }

  const Target = ({ as: As, ...props }) => <As ref={setElement} {...props} />

  Target.defaultProps = {
    as: 'div',
  }

  Target.propTypes = {
    as: PropTypes.oneOf([PropTypes.string, PropTypes.element]),
  }

  const Source = ({ children }) => {
    const [internalElement, setInternalElement] = useState(null)

    useLayoutEffect(() => {
      const setRef = { current: setInternalElement }

      let previousSet
      if (context.set) {
        previousSet = context.set
        if (context.set.current) {
          context.set.current(null)
        }
      }

      context.set = setRef
      if (context.element !== undefined) {
        setInternalElement(context.element)
      }

      return () => {
        setRef.current = null
        context.set = null

        if (previousSet && previousSet.current) {
          context.set = previousSet
          if (context.element !== undefined) {
            previousSet.current(context.element)
          }
        }
      }
    }, [])

    if (!internalElement) {
      return null
    }

    return createPortal(children, internalElement)
  }

  return { Target, Source }
}
