import React, { Fragment } from 'react'
import I18nProvider from '@unowmooc/i18n'
import styled from '@emotion/styled'
import StreamlineIcon from '@unowmooc/icons'
import { Link } from 'react-router-dom'
import { useCourseContext } from 'modules/course/components/CourseContext/CourseContext'
import { colors } from '@unowmooc/themes'
import useMe from 'hooks/useMe'
import useIsMobile from '../../../hooks/useIsMobile'

const Icon = styled(StreamlineIcon)`
  font-size: 20px;
`

const IconContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  font-weight: 400;
  color: ${colors.woodsmoke};
`

const IconSeparator = styled(StreamlineIcon)`
  font-size: 18px;
`

const Label = styled('span')`
  position: relative;
  display: block;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: ${({ hasIcon }) => (hasIcon ? 30 : 12)}px;
  font-weight: ${({ bold }) => (bold ? '700' : '400')};
  line-height: 24px;
`

const Nav = styled.nav`
  margin-bottom: 12px;
  font-size: 14px;

  ol {
    display: flex;
    margin: 0;
    padding: 0px;
    white-space: nowrap;
    overflow: hidden;

    li {
      position: relative;
      display: block;
      display: inline-block;

      & + li {
        padding-left: 8px;
        margin-left: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex: 0 auto;
        flex-shrink: 1000;

        ${Label} {
          flex: 0 1 auto;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
`

const BreadcrumbRenderer = () => {
  const {
    breadcrumbUtils,
    sessionId,
    slugCourse,
    courseTitle,
    courseIdentifier,
    sessionIdentifier,
  } = useCourseContext()

  const isMobile = useIsMobile()

  const {
    me: { isLms },
  } = useMe()

  if (isMobile) {
    return null
  }

  const displayLinkToHome = !isLms

  const home = (
    <Label hasIcon bold>
      <IconContainer>
        <Icon icon="home">&nbsp;</Icon>
      </IconContainer>
      {courseTitle}
    </Label>
  )

  return (
    <Nav aria-label="BreadcrumbRenderer" data-cy="breadcrumb">
      <ol>
        <li>
          {displayLinkToHome && (
            <Link
              to={I18nProvider.getLinkRoute('/courses/{slugCourse}/{sessionId}/program', {
                slugCourse,
                sessionId,
              })}
              data-tracking-id="session.click_breadcrumb_session"
              data-tracking-values={JSON.stringify({
                courseIdentifier,
                sessionIdentifier,
              })}
            >
              {home}
            </Link>
          )}
          {!displayLinkToHome && home}
        </li>
        {breadcrumbUtils.crumbs.map(({ label, icon, linkTo }) => {
          const LinkTag = linkTo ? Link : Fragment
          const linkProps = linkTo ? { to: { linkTo } } : {}

          return (
            <li key={label}>
              <IconContainer>
                <IconSeparator icon="right" />
              </IconContainer>
              <LinkTag {...linkProps}>
                <Label hasIcon={!!icon}>
                  {icon && <Icon icon={icon}>&nbsp;</Icon>}
                  {label}
                </Label>
              </LinkTag>
            </li>
          )
        })}
      </ol>
    </Nav>
  )
}

export default BreadcrumbRenderer
