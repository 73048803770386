import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'
import styled from '@emotion/styled'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import I18nProvider from '@unowmooc/i18n'
import { FieldLayout } from '@unowmooc/react-ui-kit'
import { css } from '@emotion/core'
import { mq } from 'styles-resources'

import { ButtonCore } from '@unowmooc/buttons'
import StreamlineIcon from '@unowmooc/icons'

import { getCertificationLink } from 'modules/exam/exam.utils'
import FieldInputCopy from '../FieldInputCopy'

const Wrapper = styled.div`
  position: relative;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: scroll;
  padding: 42px 40px;
  box-sizing: border-box;

  > div:last-child {
    margin-bottom: 42px;

    ${mq.xs(css`
      margin-bottom: 22px;
    `)};
  }

  ${mq.xs(css`
    padding: 42px 20px 22px 20px;
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    max-height: 100vh;
  `)}

  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 13px 46px 0 rgba(0, 0, 0, 0.08);
`

const ButtonClose = styled(ButtonCore)`
  position: absolute;
  top: 25px;
  right: 25px;

  ${mq.xs(css`
    top: 15px;
    right: 15px;
  `)}

  i {
    font-size: 24px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.mischka};
  }
`

const Title = styled.h2`
  line-height: 35px;
  text-align: center;
  margin-bottom: 20px;
`

const Paragraph = styled.p`
  color: ${({ theme }) => theme.colors.woodsmoke};
`

const HelpMessage = styled.p`
  ${({ bottom }) => (bottom ? 'margin-top: 30px;' : '')}
  margin-bottom: 30px;
`

const FormShareLinkedin = ({ certificateNumber, certificateValidatedAt, course, sessionIdentifier, onClose }) => (
  <Wrapper>
    <ButtonClose onClick={onClose}>
      <StreamlineIcon icon="cross" />
    </ButtonClose>
    <Title>
      <FormattedMessage id="final_exam.results.success.share_on_linkedin" />
    </Title>
    <HelpMessage>
      <FormattedHTMLMessage
        id="final_exam.results.success.linkedin_info"
        values={{
          link: encodeURI(`https://www.linkedin.com/profile/add?startTask=${course.title}`),
          trackingId: 'session.navigate_to_certificate_page',
          trackingValues: JSON.stringify({
            courseIdentifier: course.identifier,
            sessionIdentifier,
          }),
        }}
      />
    </HelpMessage>
    <div>
      <FieldInputCopy courseId={course.id} value={course.title} field="course_name" />
      <FieldInputCopy courseId={course.id} value="Unow" field="organisation_name" />
      <FieldLayout label={<FormattedMessage id="final_exam.results.success.form.fields.expiration_date" />}>
        <Paragraph>
          {I18nProvider.formatMessage({ id: 'final_exam.results.success.form.infos.expiration_date' })}
        </Paragraph>
      </FieldLayout>
      <FieldLayout label={I18nProvider.formatMessage({ id: 'final_exam.results.success.form.fields.date' })}>
        <Paragraph>{_.capitalize(moment(certificateValidatedAt).format('MMMM YYYY'))}</Paragraph>
      </FieldLayout>
      <FieldInputCopy courseId={course.id} value={certificateNumber} field="id" />
      <FieldInputCopy courseId={course} value={getCertificationLink(certificateNumber)} field="url" />
    </div>
    <HelpMessage bottom>
      <FormattedHTMLMessage id="final_exam.results.success.message" />
    </HelpMessage>
  </Wrapper>
)

FormShareLinkedin.propTypes = {
  certificateNumber: PropTypes.number.isRequired,
  certificateValidatedAt: PropTypes.string.isRequired,
  course: PropTypes.shape().isRequired,
  onClose: PropTypes.func.isRequired,
  sessionIdentifier: PropTypes.string.isRequired,
}

export default FormShareLinkedin
