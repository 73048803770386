import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'components/HelmetIntl'
import ShadowBox from '@unowmooc/shadow-box'
import { Label } from '@unowmooc/form'
import styled from '@emotion/styled'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import I18nProvider from '@unowmooc/i18n'
import { colors, rgba } from 'styles-resources'
import { useCourseContext } from 'modules/course/components/CourseContext/CourseContext'
import ApiConnector from 'modules/collector/connector/api'
import PageLoader from 'components/PageLoader'
import SkillsForm from 'components/SkillsForm'
import { useTracking } from 'modules/tracking'
import desktopOnly from 'hoc/desktopOnly'
import TagInitial from './Tags/TagInitial'
import TagFinal from './Tags/TagFinal'
import TagUp from './Tags/TagUp'
import TagEqual from './Tags/TagEqual'
import TagDown from './Tags/TagDown'
import RestitutionRaw from './RestitutionRaw'
import FilterSelect from './SelectFilter'
import CtaBfc from './CtaBfc'
import { PROGRESSION_DIRECTION_ASC, VALID_PROGRESSION_DIRECTION } from './constants'
import CtaBic from './CtaBic'
import MobileMessage from './MobileMessage'
import CtaProgram from './CtaProgram'

const Wrapper = styled(ShadowBox)`
  margin-top: 40px;
  padding: 20px;
  background-color: ${colors.white};

  i.tag {
    display: inline-flex;
    align-items: center;
    padding: 0px 8px;
    border-radius: 100px;
    white-space: nowrap;
    height: 20px;
    font-style: normal;

    span {
      font-size: 11px;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 1px;
      line-height: normal;
    }

    &.tag-initial {
      color: ${colors.midGray};
      border: 1px solid ${colors.midGray};
    }

    &.tag-final {
      color: ${colors.white};
      background-color: ${colors.midGray};
    }

    &.tag-up {
      position: relative;
      width: 24px;
      height: 24px;
      padding: 0;
      color: ${colors.white};
      background-color: ${rgba(colors.unowGreen, 0.25)};

      &::before {
        content: ' ';
        position: absolute;
        top: 6px;
        right: 6px;
        bottom: 0;
        left: 6px;
        display: inline-block;
        height: 0;
        width: 0;
        border-right: 6px solid transparent;
        border-bottom: 9px solid ${colors.unowGreen};
        border-left: 6px solid transparent;
      }
    }

    &.tag-equal {
      position: relative;
      width: 24px;
      height: 24px;
      padding: 0;
      color: ${colors.white};
      background-color: ${rgba(colors.cornflowerBlue, 0.25)};

      &::before {
        content: ' ';
        position: absolute;
        top: 6px;
        right: 6px;
        bottom: 0;
        left: 6px;
        display: inline-block;
        height: 3px;
        background-color: ${colors.cornflowerBlue};
        width: 10px;
      }
    }

    &.tag-down {
      width: 24px;
      height: 24px;
      padding: 0;
      color: ${colors.white};
      background-color: ${colors.midGray};
    }
  }
`

const Title = styled.h1`
  margin-bottom: 24px;
`

const ParagraphIntro = styled.p`
  li {
    list-style-type: disc;
  }
`

const FormFilter = styled.div`
  margin-top: 44px;
  margin-bottom: 40px;
`

const getParagraphMessageKey = ({ hasBicAnswered, hasBfcAnswered, hasBfcAvailable, hasSpecificBfc }) => {
  switch (true) {
    case !hasBicAnswered && !hasBfcAnswered:
      return 'none'
    case hasBicAnswered && !hasBfcAnswered && !hasBfcAvailable:
    case hasBfcAnswered && !hasSpecificBfc:
      return 'only_bic'
    case hasBicAnswered && !hasBfcAnswered && hasBfcAvailable:
      return 'only_bic_and_bfc_available'
    default:
      return 'all'
  }
}

const formatMessageWithTag = formatMessageArgument =>
  I18nProvider.formatMessage(formatMessageArgument)
    .split(/##(taginitial|tagfinal|tagup|tagequal|tagdown|cta_bfc|cta_bic)##/g)
    .map(part => {
      switch (part) {
        case 'taginitial':
          return <TagInitial />
        case 'tagfinal':
          return <TagFinal />
        case 'tagup':
          return <TagUp size={22} />
        case 'tagequal':
          return <TagEqual size={22} />
        case 'tagdown':
          return <TagDown size={22} />
        case 'cta_bfc':
          return <CtaBfc />
        case 'cta_bic':
          return <CtaBic />
        default:
          return <span dangerouslySetInnerHTML={{ __html: part }} />
      }
    })

const getConfig = ({ formData, participantId }) => {
  const progressionCounter = { up: 0, equalOrDown: 0 }

  const questionRaws = formData.reduce((questionRawsAcc, question) => {
    const isQuestionSimple = VALID_PROGRESSION_DIRECTION.includes(question.progression_direction)
    const isQuestionMatrix =
      question?.subquestions?.every(q => VALID_PROGRESSION_DIRECTION.includes(q.progression_direction)) ?? false

    if (!isQuestionSimple && !isQuestionMatrix) {
      return questionRawsAcc
    }

    const questions = isQuestionSimple ? [question] : question.subquestions

    return [
      ...questionRawsAcc,
      {
        id: question.id,
        title: question.title,
        isQuestionSimple,
        isQuestionMatrix,
        questions: questions.map(questionItem => {
          const defaultConfig = {
            bic: null,
            bfc: null,
            progression: null,
            notImprovable: false,
            maxChoiceIndex:
              questionItem.progression_direction === PROGRESSION_DIRECTION_ASC ? questionItem.choices.length - 1 : 0,
          }

          const config =
            questionItem.participants?.[participantId]?.answers.reduce((configAcc, answer) => {
              const choiceIndex = questionItem.choices.findIndex(choice => choice.id === answer.choice_id)

              if (answer.context === SkillsForm.contexts.BIC) {
                return { ...configAcc, bic: { answerId: answer.choice_id, choiceIndex } }
              }
              if (answer.context === SkillsForm.contexts.BFC) {
                return { ...configAcc, bfc: { answerId: answer.choice_id, choiceIndex } }
              }
              return configAcc
            }, defaultConfig) ?? defaultConfig

          if (config.bic === null || config.bfc === null) {
            return { question: questionItem, config }
          }

          config.progression = (config.bfc.choiceIndex - config.bic.choiceIndex) * questionItem.progression_direction
          config.notImprovable = config.progression === 0 && config.maxChoiceIndex === config.bic.choiceIndex

          if (config.progression > 0) {
            progressionCounter.up += 1
          } else if (!config.notImprovable) {
            progressionCounter.equalOrDown += 1
          }

          return { question: questionItem, config }
        }),
      },
    ]
  }, [])

  return { questionRaws, progressionCounter }
}

const SKILLS_FORM_PROGRESSION = {
  BIC_MUST_BE_FILLED: 'none',
  BIC_IS_FILLED: 'only_bic',
  BFC_MUST_BE_FILLED: 'only_bic_and_bfc_available',
  BFC_IS_FILLED: 'all',
}

const SkillsPageRenderer = ({ courseTitle, formData }) => {
  const [filterValue, updateFilterValue] = useState(FilterSelect.values.ALL)
  const {
    participantId,
    hasBicAnswered,
    hasBfcAnswered,
    hasBfcAvailable,
    hasSpecificBfc,
    courseIdentifier,
    sessionIdentifier,
  } = useCourseContext()
  const { sendEvent } = useTracking()
  const { progressionCounter, questionRaws } = useMemo(() => getConfig({ formData, participantId }), [
    formData,
    participantId,
  ])

  const paragraphMessageKey = getParagraphMessageKey({
    hasBicAnswered,
    hasBfcAnswered,
    hasBfcAvailable,
    hasSpecificBfc,
  })

  useEffect(() => {
    sendEvent(
      'restitution.see_skills_page',
      JSON.stringify({
        courseIdentifier,
        sessionIdentifier,
        state: paragraphMessageKey,
      }),
    )
  })

  return (
    <>
      <Helmet title="courses.course.skills.helmet_title" values={{ title: courseTitle }} />
      <Wrapper>
        <Title>
          <FormattedMessage id={`courses.course.skills.title${hasBfcAnswered ? '_final' : ''}`} />
        </Title>
        {paragraphMessageKey === SKILLS_FORM_PROGRESSION.BIC_MUST_BE_FILLED && (
          <ParagraphIntro>
            <FormattedHTMLMessage id="courses.course.skills.paragraph.none.why" />
            <ul>
              <li>
                <FormattedHTMLMessage id="courses.course.skills.paragraph.none.why_bullet_1" />
              </li>
              <li>
                <FormattedHTMLMessage id="courses.course.skills.paragraph.none.why_bullet_2" />
              </li>
            </ul>
            <FormattedHTMLMessage id="courses.course.skills.paragraph.none.goal" />
            <CtaBic />
          </ParagraphIntro>
        )}
        {paragraphMessageKey === SKILLS_FORM_PROGRESSION.BIC_IS_FILLED && (
          <>
            <ParagraphIntro>
              <FormattedHTMLMessage id="courses.course.skills.paragraph.only_bic" />
            </ParagraphIntro>
            <CtaProgram />
          </>
        )}
        {paragraphMessageKey === SKILLS_FORM_PROGRESSION.BFC_MUST_BE_FILLED && (
          <ParagraphIntro>
            {formatMessageWithTag({
              id: `courses.course.skills.paragraph.only_bic_and_bfc_available`,
            })}
          </ParagraphIntro>
        )}
        {paragraphMessageKey === SKILLS_FORM_PROGRESSION.BFC_IS_FILLED && (
          <ParagraphIntro>
            {formatMessageWithTag({
              id: `courses.course.skills.paragraph.all`,
              values: { numberUp: progressionCounter.up, numberEqual: progressionCounter.equalOrDown },
            })}
          </ParagraphIntro>
        )}
      </Wrapper>
      {hasBicAnswered && (
        <Wrapper>
          <Title>
            <FormattedMessage id="courses.course.skills.restitution_title" />
          </Title>

          {hasBfcAnswered && hasSpecificBfc && (
            <FormFilter>
              <Label>
                <FormattedMessage id="courses.course.skills.filter_select.label" />
              </Label>
              <FilterSelect value={filterValue} onChange={updateFilterValue} />
            </FormFilter>
          )}
          {questionRaws.map(questionRaw => (
            <RestitutionRaw
              key={questionRaw.id}
              participantId={participantId}
              questionRaw={questionRaw}
              filterValue={filterValue}
            />
          ))}
        </Wrapper>
      )}
    </>
  )
}

SkillsPageRenderer.propTypes = {
  courseTitle: PropTypes.string.isRequired,
  formData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      subquestions: PropTypes.arrayOf(
        PropTypes.shape({
          choices: PropTypes.arrayOf(
            PropTypes.shape({
              label: PropTypes.string.isRequired,
            }),
          ).isRequired,
          progression_direction: PropTypes.number,
        }),
      ),
      progression_direction: PropTypes.number,
      choices: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
        }),
      ),
    }),
  ).isRequired,
}

const SkillsPage = props => {
  const { participantId, bic } = useCourseContext()
  const [formData, setFormData] = useState(null)

  useEffect(() => {
    ;(async () => {
      // récupération du bic spécifique de la session.
      const formId = bic.find(survey => survey.type === 'specific')?.id
      const connector = new ApiConnector({ participantId, formId })

      setFormData(await connector.loadData())
    })()
  }, [])

  if (formData === null) {
    return <PageLoader />
  }

  return <SkillsPageRenderer {...props} formData={formData} />
}

export default desktopOnly(SkillsPage, MobileMessage)
