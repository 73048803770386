import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Box, Flex } from 'reflexbox'
import { Form, FormState, Field, Label } from '@unowmooc/form'
import { InputFile } from '@unowmooc/inputs'
import { ButtonI18n } from '@unowmooc/buttons'
import I18nProvider from '@unowmooc/i18n'

import Card from 'components/Card'
import { minLength } from 'utils/validators'
import BoxSessionInfo from '../../BoxSessionInfo'

const Auto = ({ history }) => {
  const handleSubmit = () => {
    history.push(I18nProvider.getLinkRoute('/supervisor/registered_users/validation/auto'))
  }

  return (
    <Form name="userRegistration" form={{ mode: 'onChange' }} persist onSubmit={handleSubmit}>
      <BoxSessionInfo />
      <Box>
        <Card padding="30px" marginTop="30px">
          <Label>
            <FormattedMessage id="supervisor.registration.add.labels.model" />
          </Label>
          <a
            href="https://s3-eu-west-1.amazonaws.com/cdn.unow.fr/supervisor/template-import-participants.xlsx"
            target="_blank"
            rel="noopener noreferrer"
            download
            filename="template-import-participants.xlsx"
          >
            <ButtonI18n
              type="button"
              variant="link"
              i18nKey="supervisor.registration.add.links.download_model"
              icon="download"
              reverse
              mt="20px"
              mb="30px"
            />
          </a>
          <Field
            name="file"
            help={<FormattedMessage id="supervisor.registration.add.helps.add_file" />}
            label={<FormattedMessage id="supervisor.registration.add.labels.file" />}
            validate={{ minLength: minLength(1) }}
          >
            <InputFile accept=".xlsx" label={<FormattedMessage id="supervisor.registration.add.form.file" />} />
          </Field>
        </Card>
      </Box>
      <Flex marginTop="30px" justifyContent="center">
        <FormState>
          {({ isValid }) => (
            <ButtonI18n
              variant="primary"
              i18nKey="supervisor.registration.add.buttons.submitFile"
              disabled={!isValid}
              type="submit"
            />
          )}
        </FormState>
      </Flex>
    </Form>
  )
}

Auto.propTypes = {
  history: PropTypes.shape().isRequired,
}

export default withRouter(Auto)
