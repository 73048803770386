import React from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { Field } from '@unowmooc/form'
import { InputText } from '@unowmooc/inputs'
import Textarea from 'components/Textarea'
import gql from 'graphql-tag'
import SelectAutoFillField from 'components/SelectAutoFillField'
import WriteMessageModalFormContainer from './WriteMessageModalFormContainer'

const WRITE_MESSAGE_ANIMATOR_QUERY = gql`
  query SelectMainAnimatorUserQuery {
    me {
      id
      participants {
        id
        canceledAt
        session {
          id
          canceledAt
          course {
            id
            title
          }
          conversationsEnabled
          mainAnimator {
            id
            fullName
          }
        }
      }
    }
  }
`

const filterQueryResult = ({ data }) => {
  const options = []

  data.me.participants.forEach(participant => {
    // On ne récupère pas les participations annulées
    if (participant.canceledAt) {
      return
    }

    // On ne récupère pas les experts des sessions annulées
    if (participant.session.canceledAt) {
      return
    }

    // On ne récupère pas les experts des sessions qui ne sont pas activées
    if (!participant.session.conversationsEnabled) {
      return
    }

    if (!participant.session.mainAnimator) {
      return
    }

    options.push({
      value: participant,
      label: `${participant.session.mainAnimator.fullName} - ${participant.session.course.title}`,
    })
  })

  return options
}

/**
 * Modale affichée à un participant pour écrire à son expert.
 */
const WriteMessageParticipantModalForm = ({ onClose, onSubmitSuccess }) => {
  const prepareDataOnSubmit = data => {
    const sessionId = data.expert.value.session.id
    const memberUserIds = [data.expert.value.session.mainAnimator.id]
    const { subject } = data
    const { content } = data

    return { sessionId, memberUserIds, subject, content }
  }

  return (
    <WriteMessageModalFormContainer
      formTitle={<FormattedMessage id="conversation.write_message_label.participant" />}
      onClose={onClose}
      onSubmitSuccess={onSubmitSuccess}
      prepareDataOnSubmit={prepareDataOnSubmit}
      render={() => (
        <>
          <SelectAutoFillField
            query={WRITE_MESSAGE_ANIMATOR_QUERY}
            required={<FormattedMessage id="errors.isRequired" />}
            name="expert"
            label={<FormattedMessage id="conversation.form.expert" />}
            getOptions={filterQueryResult}
            selectProps={{
              placeholder: <FormattedMessage id="commons.search" />,
              noOptionsMessage: () => <FormattedMessage id="select.default_no_result" />,
            }}
          />
          <Field
            required={<FormattedMessage id="errors.isRequired" />}
            name="subject"
            label={<FormattedMessage id="conversation.form.subject" />}
          >
            <InputText />
          </Field>
          <Field
            required={<FormattedMessage id="errors.isRequired" />}
            name="content"
            label={<FormattedMessage id="conversation.form.message" />}
          >
            <Textarea minRows={5} maxRows={10} />
          </Field>
        </>
      )}
    />
  )
}

WriteMessageParticipantModalForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
}

export default WriteMessageParticipantModalForm
