import { AbilityBuilder } from '@casl/ability'
import { ADMIN } from 'modules/user/user.constants'

function domain(can, user) {
  can('access-domain', 'dashboard')

  if (user.nbSupervisions) {
    can('access-domain', 'supervisor')
  }

  if (user.nbAnimations) {
    can('access-domain', 'expert')
  }

  can('access-domain', 'settings')
  can('access-domain', 'settings:preferences')

  if (user.nbParticipations) {
    can('access-domain', 'settings:documents')
  }

  if (user.role === ADMIN) {
    can('admin-edit', 'section')
    can('admin-edit', 'module-quiz')
    can('admin-edit', 'final-exam')
  }

  if (user.conversationsParticipantEnabled) {
    can('access-domain', 'conversations')
    can('change-setting-notification', 'sendConversationMessageNotificationMail')
  }
}

function settingNotification(can, user) {
  if (user.nbParticipations || user.nbAnimations || user.nbSupervisions) {
    can('access-setting-notification', 'emails')
  }

  if (user.nbParticipations || user.nbAnimations) {
    can('change-setting-notification', 'sendParticipantCommentMail')
  }

  if (user.nbAnimations) {
    can('change-setting-notification', 'sendCommentReport')
  }

  if (user.nbSupervisions) {
    can('change-setting-notification', 'sendSupervisorAlert')
    can('change-setting-notification', 'sendSupervisorAskParticipantsPhoneNumberMail')
  }

  if (user.nbParticipations) {
    can('change-setting-notification', 'sendAnnouncementMail')
    can('change-setting-notification', 'sendReminderMail')
    can('access-setting-notification', 'phone')
    can('change-setting-notification', 'phoneCall')
  }
}

function comment(can, user) {
  if (user.role === ADMIN) {
    can(['delete', 'edit', 'pin', 'forward'], 'Comment')
  } else {
    can(['deleteOwn', 'edit'], 'Comment', { user: { id: user.id } })

    if (user.nbAnimations > 0) {
      can(['pin', 'forward'], 'Comment')
    }
  }
}

function subjectName(item) {
  if (!item || typeof item === 'string') {
    return item
  }

  return item.__typename
}

export function defineAbilitiesFor(user) {
  return AbilityBuilder.define({ subjectName }, can => {
    domain(can, user)
    settingNotification(can, user)
    comment(can, user)
  })
}

export default { defineAbilitiesFor }
