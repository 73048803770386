import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { toInlineFragment } from 'fraql'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import { Alert } from '@unowmooc/react-ui-kit'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { colors, mq } from '@unowmooc/themes'
import { ZeroHeightUnow } from '@unowmooc/zeroheight'
import PageQuery from 'components/PageQuery'
import { useCourseContext } from 'modules/course/components/CourseContext/CourseContext'
import * as virtualClassroomUtils from 'utils/virtualClassroom'
import Sequence from 'components/VirtualClassroom/Sequence'
import { SequenceProvider } from 'components/VirtualClassroom/Sequence/SequenceContext'
import SequenceSlot from 'components/VirtualClassroom/Sequence/SequenceSlot'
import { hasAtLeastOneWebexLink as hasAtLeastOneWebexLinkFn } from 'utils/virtualClassroom'
import { useUserContext } from 'store/UserProvider'
import { useTracking } from 'modules/tracking'
import { SEQUENCE_ORIGINS } from 'components/VirtualClassroom/Sequence/constantes'

const Description = styled.p`
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 30px;

  ${mq.sm(css`
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 24px;
  `)}
`

const WebexTestLink = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 36px;

  p {
    color: ${colors.black};

    a {
      font-weight: 700;
      text-decoration: underline;
    }
  }
`

const AlertInfo = styled(Alert)`
  padding: 30px;

  ul {
    margin: 0;
    padding: 0 0 0 20px;

    li {
      list-style-type: disc;
    }
  }
`

const DatesTitle = styled.h4`
  margin-bottom: 15px;
`

const SequenceSlotWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

const VirtualClassroomRenderer = ({
  block: { evaluationForm },
  session: { virtualClassroomsBySequence, hasVirtualClassroom },
}) => {
  const { user } = useUserContext()
  const { courseIdentifier, sessionIdentifier } = useCourseContext()
  const { sendEvent } = useTracking()
  const hasOnboardingVirtualClassroom = virtualClassroomUtils.hasOnboardingVirtualClassroom(virtualClassroomsBySequence)
  const hasNoOnboardingVirtualClassroom = virtualClassroomUtils.hasNoOnboardingVirtualClassroom(
    virtualClassroomsBySequence,
  )
  const nextVirtualClassroomIndex = virtualClassroomUtils.getNextVirtualClassroomIndex(virtualClassroomsBySequence)
  const hasAtLeastOneWebexLink = hasAtLeastOneWebexLinkFn(virtualClassroomsBySequence)

  const handleClickTestLink = () => {
    sendEvent(
      'virtual_classroom.click_on_webex_test_link',
      JSON.stringify({
        courseIdentifier,
        sessionIdentifier,
      }),
    )
  }

  return (
    <>
      <Description>
        {hasNoOnboardingVirtualClassroom && hasOnboardingVirtualClassroom && (
          <FormattedMessage id="virtual_classroom.intros.all" />
        )}
        {!hasNoOnboardingVirtualClassroom && hasOnboardingVirtualClassroom && (
          <FormattedMessage id="virtual_classroom.intros.only_all" />
        )}
        {hasNoOnboardingVirtualClassroom && !hasOnboardingVirtualClassroom && (
          <FormattedMessage id="virtual_classroom.intros.only_al" />
        )}
      </Description>

      {hasVirtualClassroom && (
        <>
          {hasAtLeastOneWebexLink && (
            <WebexTestLink>
              <ZeroHeightUnow height="48px" width="48px" name="IconHeadphone" mr="12px" />
              <p>
                <FormattedHTMLMessage id="virtual_classroom.webex_test_link_part_1" values={{ gender: user.gender }} />
                <a
                  href="https://www.webex.com/test-meeting.html/"
                  target="webex_test_link"
                  onClick={handleClickTestLink}
                >
                  <FormattedHTMLMessage id="virtual_classroom.webex_test_link_part_2" />
                </a>
              </p>
            </WebexTestLink>
          )}
        </>
      )}

      {virtualClassroomsBySequence.map((sequence, sequenceIndex) => (
        <SequenceProvider key={sequence.sequenceNumber} sequence={sequence} origin={SEQUENCE_ORIGINS.page}>
          <Sequence
            isNextSequence={sequenceIndex === nextVirtualClassroomIndex}
            showSlotHelperBlock
            isLastSequence={sequenceIndex + 1 === virtualClassroomsBySequence.length}
            render={contextSequence => (
              <SequenceSlotWrapper>
                {contextSequence.virtualClassrooms.map(virtualClassroom => (
                  <SequenceSlot virtualClassroom={virtualClassroom} evaluationForm={evaluationForm} />
                ))}
              </SequenceSlotWrapper>
            )}
          />
        </SequenceProvider>
      ))}
      {hasVirtualClassroom && hasNoOnboardingVirtualClassroom && (
        <AlertInfo>
          <>
            <DatesTitle>
              <FormattedHTMLMessage id="virtual_classroom.dates.title_info" />
            </DatesTitle>
            <FormattedHTMLMessage id="virtual_classroom.dates.li_info" values={{ hasReplay: 0 }} />
          </>
        </AlertInfo>
      )}
      {hasVirtualClassroom && !hasNoOnboardingVirtualClassroom && !hasOnboardingVirtualClassroom && (
        <Alert theme="warning">
          <FormattedHTMLMessage id="virtual_classroom.dates.unavailable" />
        </Alert>
      )}
    </>
  )
}

VirtualClassroomRenderer.fragments = {
  session: gql`
    fragment _ on Session {
      id
      hasVirtualClassroom
      virtualClassroomsBySequence {
        isOnboarding
      }
    }
  `,
}

VirtualClassroomRenderer.propTypes = {
  session: PropTypes.shape({
    hasVirtualClassroom: PropTypes.bool.isRequired,
    virtualClassroomsBySequence: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  block: PropTypes.shape({
    evaluationForm: PropTypes.shape({
      formId: PropTypes.number.isRequired,
      contextPrefix: PropTypes.string.isRequired,
    }),
  }).isRequired,
}

const VirtualClassroom = ({ block }) => {
  const { sessionId } = useCourseContext()

  return (
    <PageQuery
      fetchPolicy="no-cache"
      query={gql`
        query VirtualClassroom($idSession: ID!) {
          session(id: $idSession) {
            id
            ${toInlineFragment(VirtualClassroomRenderer.fragments.session)}
            virtualClassroomsBySequence {
              ${toInlineFragment(Sequence.fragments.sequence)}
            }
          }
        }
      `}
      variables={{ idSession: sessionId }}
      render={props => <VirtualClassroomRenderer block={block} {...props} />}
    />
  )
}

VirtualClassroom.fragment = toInlineFragment(
  gql`
    fragment _ on Block {
      ... on VirtualClassroomBlock {
        blockType
        evaluationForm {
          formId
          contextPrefix
        }
      }
    }
  `,
)

VirtualClassroom.propTypes = {
  block: PropTypes.shape({
    evaluationForm: PropTypes.shape({
      formId: PropTypes.number.isRequired,
      contextPrefix: PropTypes.string.isRequired,
    }),
  }).isRequired,
}

export default VirtualClassroom
