import { LinkButton } from '@unowmooc/react-ui-kit'
import I18nProvider from '@unowmooc/i18n'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import React from 'react'
import { useCourseContext } from 'modules/course/components/CourseContext/CourseContext'
import styled from '@emotion/styled'

const CtaStyled = styled.div`
  margin: 22px 0;
`

const CtaBfc = () => {
  const { slugCourse, sessionId, hasBfcAnswered, courseIdentifier, sessionIdentifier } = useCourseContext()

  if (hasBfcAnswered) {
    return null
  }

  return (
    <CtaStyled>
      <LinkButton
        tag={Link}
        to={I18nProvider.getLinkRoute('/courses/{slugCourse}/{sessionId}/final_skills_form', {
          slugCourse,
          sessionId,
        })}
        data-tracking-id="restitution.click_on_bfc_button_link"
        data-tracking-values={JSON.stringify({
          courseIdentifier,
          sessionIdentifier,
        })}
      >
        <FormattedMessage id="courses.course.skills.cta_bfc_label" />
      </LinkButton>
    </CtaStyled>
  )
}

export default CtaBfc
