import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Box } from 'reflexbox'
import { Link } from 'react-router-dom'
import { Text } from '@unowmooc/text'
import gql from 'graphql-tag'
import { getPathToSessionComment } from 'modules/comments/comment.utils'
import * as ConversationUI from 'modules/conversations/components/UI'
import { useMutation } from '@apollo/react-hooks'
import { useTracking } from 'modules/tracking'
import { isAdmin, isUserAnimatorOfTheSession } from 'modules/user/user.utils'
import { SEND_COMMENT_TO_SUPPORT } from '../../../comments.mutations'

const CommentActions = ({ mobile, comment }) => {
  const [sendCommentToSupport, { loading }] = useMutation(SEND_COMMENT_TO_SUPPORT)
  const { sendEvent } = useTracking()
  const isAdminOrAnimatorOfTheSession =
    isUserAnimatorOfTheSession(comment.user, comment.session) || isAdmin(comment.user)

  const handleSubmitForward = async () => {
    const variables = { commentId: comment.id }

    const {
      data: {
        sendCommentToSupport: {
          id: commentId,
          session: {
            identifier: sessionIdentifier,
            course: { identifier: courseIdentifier },
          },
        },
      },
    } = await sendCommentToSupport({ variables })

    sendEvent(
      'comment.send_discussion_comment_to_support',
      JSON.stringify({
        courseIdentifier,
        sessionIdentifier,
        commentId,
      }),
    )
  }

  return (
    <Box
      display={mobile ? ['inline-flex', 'none'] : ['none', 'inline-flex']}
      flexDirection="column"
      textAlign={mobile ? 'left' : 'right'}
      mb={mobile && '15px'}
    >
      <Text lineHeight={mobile && '22px'}>{comment.session.identifier}</Text>
      {comment.section ? (
        <>
          <Text
            lineHeight={mobile && '22px'}
            i18nKey="expert.comments.module_section"
            values={{ modulePosition: _.get(comment.section, 'module.publicPosition', 0) + 1 }}
          />
          <Link to={getPathToSessionComment(comment)}>
            <Text lineHeight={mobile && '22px'}>{comment.section.title}</Text>
          </Link>
        </>
      ) : (
        <Text lineHeight={mobile && '22px'} i18nKey="expert.comments.discussions" />
      )}
      {isAdminOrAnimatorOfTheSession === false && (
        <ConversationUI.ForwardButton
          onSubmit={handleSubmitForward}
          isForwarded={comment.isForwarded}
          loading={loading}
          gender={comment.user.gender}
        />
      )}
    </Box>
  )
}

CommentActions.fragment = gql`
  fragment CommentActionsFragment on Comment {
    id
    isForwarded
    session {
      id
      identifier
      course {
        id
        slug
      }
    }
    user {
      id
      role
      gender
      animations {
        session {
          id
        }
      }
    }
    section {
      id
      title
      module {
        id
        publicPosition
      }
    }
  }
`

CommentActions.defaultProps = {
  mobile: false,
}

CommentActions.propTypes = {
  comment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isForwarded: PropTypes.bool.isRequired,
    session: PropTypes.shape({
      identifier: PropTypes.string.isRequired,
    }).isRequired,
    user: PropTypes.shape({
      role: PropTypes.string.isRequired,
      gender: PropTypes.string.isRequired,
    }).isRequired,
    section: PropTypes.shape({
      title: PropTypes.string.isRequired,
      module: PropTypes.shape({
        publicPosition: PropTypes.number.isRequired,
      }).isRequired,
    }),
  }).isRequired,
  mobile: PropTypes.bool,
}

export default CommentActions
