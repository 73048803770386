import React from 'react'
import PropTypes from 'prop-types'
import { Query } from 'react-apollo'
import PageLoader from 'components/PageLoader'
import ErrorHandler from './ErrorHandler'

const PageQuery = ({ render, children, force, showNotFoundMessageIfNoData, ...props }) => {
  const renderCallback = children || render

  return (
    <Query {...props}>
      {({ loading, data, error, refetch }) => {
        if (loading && !data) {
          return <PageLoader />
        }

        const hasData = force ? true : data && data[Object.keys(data)[0]] !== null

        return (
          <>
            {error || (showNotFoundMessageIfNoData && !hasData) ? (
              <ErrorHandler error={error || 'resource_not_found'} />
            ) : (
              renderCallback(data, { refetch })
            )}
          </>
        )
      }}
    </Query>
  )
}

PageQuery.defaultProps = {
  ...Query.defaultProps,
  force: false,
  children: undefined,
  render: undefined,
  showNotFoundMessageIfNoData: true,
}

PageQuery.propTypes = {
  ...Query.propTypes,
  force: PropTypes.bool,
  children: PropTypes.func,
  render: PropTypes.func,
  showNotFoundMessageIfNoData: PropTypes.bool,
}

export default PageQuery
