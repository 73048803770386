import styled from '@emotion/styled'
import { layout, position } from 'styled-system'

export default styled.img`
  max-width: 100%;
  height: auto;

  ${layout}
  ${position}
`
