import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import I18nProvider from '@unowmooc/i18n'
import { getLocale } from '@unowmooc/locale'
import Files from '@unowmooc/files'
import RequiredAuthenticationZone from 'containers/RequiredAuthenticationZone'
import QueryMetadataProvider from 'store/QueryMetadataProvider'
import UserProvider from 'store/UserProvider'
import Layout from 'components/Layout'
import ModalContainer from 'components/ModalContainer'
import NotFoundPage from 'pages/404'
import OldRoutes from 'pages/old-routes'
import DashboardPage from 'pages/domains/dashboard'
import CoursesPage from 'pages/domains/courses'
import ConversationsPage from 'pages/domains/conversations'
import SupervisorAreaPage from 'pages/domains/supervisor'
import ExpertAreaPage from 'pages/domains/expert'
import CatalogPage from 'pages/domains/catalog'
import SettingsPage from 'pages/domains/settings'
import ProfilePage from 'pages/domains/profile'
import AbilityProvider from 'store/AbilityProvider'
import { defineAbilitiesFor } from 'business/permissions'
import Analytics from 'components/Analytics'
import CheckVersion from 'components/CheckVersion'
import LmsAuthorizationChecker from 'components/LmsAuthorizationChecker'
import { LocaleContext } from 'components/LocaleProvider'
import CollectorPage from './domains/collector'
import FinalSkillsFormRedirectPage from './domains/finalSkillsFormRedirect'
import { ADMIN } from '../modules/user/user.constants'
import { SkipLinkProvider } from '../modules/skipLink/provider'
import SkipLinkMenu from '../modules/skipLink/SkipLinkMenu'

const Router = ({ user }) => {
  const { switchLocale } = useContext(LocaleContext)

  // Permet de définir la locale à utiliser pour l'utilisateur
  useEffect(() => {
    const userLocale = user?.locale

    if (userLocale && getLocale() !== userLocale) {
      switchLocale(userLocale)
    }
  }, [user?.locale])

  // Application des credentials de securité pour FileStack
  useEffect(() => {
    const userFileStackConfig = user?.fileStack

    if (userFileStackConfig) {
      Files.setSecurity(userFileStackConfig)
    }
  }, [user?.fileStack])

  if (!user) {
    return null
  }

  return (
    <UserProvider user={user}>
      <div id="preview-image" />
      <AbilityProvider ability={defineAbilitiesFor(user)}>
        <QueryMetadataProvider>
          <BrowserRouter>
            <CheckVersion>
              <LmsAuthorizationChecker>
                <SkipLinkProvider>
                  <SkipLinkMenu />
                  <Route component={Analytics} />
                  <Switch>
                    <Route exact path="/" render={() => <Redirect to={I18nProvider.getLinkRoute('/dashboard')} />} />
                    <Layout path={I18nProvider.translatePath('/dashboard')} exact component={DashboardPage} />
                    <Route
                      path={I18nProvider.translatePath('/courses/:slugCourse/:idSession')}
                      component={CoursesPage}
                    />
                    <Layout path={I18nProvider.translatePath('/supervisor')} component={SupervisorAreaPage} />
                    <Layout path={I18nProvider.translatePath('/expert')} component={ExpertAreaPage} />
                    <Layout path={I18nProvider.translatePath('/catalog')} component={CatalogPage} />
                    <Layout path={I18nProvider.translatePath('/settings')} component={SettingsPage} />
                    <Layout path={I18nProvider.translatePath('/conversations')} component={ConversationsPage} />
                    <Layout path={I18nProvider.translatePath('/profile/:slug/:id')} component={ProfilePage} />
                    <Layout
                      path={I18nProvider.translatePath('/final-skills-form')}
                      component={FinalSkillsFormRedirectPage}
                    />
                    {user.role === ADMIN && <Route path="/collector" component={CollectorPage} />}
                    <Layout component={NotFoundPage} />
                    <Layout path="/404" component={NotFoundPage} />
                  </Switch>
                </SkipLinkProvider>
              </LmsAuthorizationChecker>
            </CheckVersion>
          </BrowserRouter>
        </QueryMetadataProvider>
      </AbilityProvider>
    </UserProvider>
  )
}

Router.defaultProps = {
  user: {},
}

Router.propTypes = {
  user: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    fileStack: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
  }),
}

const Pages = () => (
  <BrowserRouter>
    <>
      <OldRoutes />
      <ModalContainer />
      <RequiredAuthenticationZone render={({ user }) => <Router user={user} />} />
    </>
  </BrowserRouter>
)

export default Pages
