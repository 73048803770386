import React, { useEffect, useState } from 'react'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import { useCourseContext } from 'modules/course/components/CourseContext/CourseContext'
import Checkbox from 'components/Checkbox'
import gql from 'graphql-tag'
import PageQuery from 'components/PageQuery'
import { useMutation } from '@apollo/react-hooks'
import styled from '@emotion/styled'
import { colors } from '@unowmooc/themes'
import { toInlineFragment } from 'fraql'
import { propType } from 'graphql-anywhere'
import PropTypes from 'prop-types'

const StyledCheckbox = styled(Checkbox)`
  margin-top: 12px;
  margin-bottom: 40px;
`

const FootnoteParagraph = styled.p`
  font-size: 14px;
  line-height: 23px;
  color: ${colors.midGray};
`

const QUERY_FRAGMENT = gql`
  fragment _ on Session {
    id
    authenticatedParticipant {
      id
      hasCommittedToTakeTheCertification
      professionalCertification {
        id
        label
        isUnow
        certificatorName
      }
    }
  }
`

const QUERY = gql`
  query CertificationSlideContentQuery($id: ID!) {
    session(id: $id) {
        ${toInlineFragment(QUERY_FRAGMENT)}
    }
  }
`

const COMMIT_TO_TAKE_THE_CERTIFICATION = gql`
  mutation commitToTakeTheCertification($participantId: ID!) {
    commitToTakeTheCertification(participantId: $participantId) {
      id
      hasCommittedToTakeTheCertification
    }
  }
`

const Certification = ({ session: { authenticatedParticipant }, updateNextButtonDisabled }) => {
  const { participantId } = useCourseContext()
  const [commitToTakeTheCertification] = useMutation(COMMIT_TO_TAKE_THE_CERTIFICATION)
  const [hasCommittedToTakeTheCertification, updateHasCommittedToTakeTheCertification] = useState(
    authenticatedParticipant?.hasCommittedToTakeTheCertification,
  )

  useEffect(() => {
    updateNextButtonDisabled(!hasCommittedToTakeTheCertification)

    return () => {
      updateNextButtonDisabled(false)
    }
  }, [hasCommittedToTakeTheCertification])

  const handleCheckboxChange = () => {
    updateHasCommittedToTakeTheCertification(true)
    commitToTakeTheCertification({ variables: { participantId } }).catch(() => {
      updateHasCommittedToTakeTheCertification(false)
    })
  }

  return (
    <>
      <p>
        <FormattedHTMLMessage
          id="guide.slides.certification.content.paragraph_1"
          values={{ certificationName: authenticatedParticipant?.professionalCertification?.label ?? null }}
        />
      </p>
      <p>
        <FormattedHTMLMessage id="guide.slides.certification.content.paragraph_2" />
      </p>
      <p>
        <FormattedHTMLMessage id="guide.slides.certification.content.paragraph_3" />
      </p>
      <p>
        <FormattedHTMLMessage id="guide.slides.certification.content.paragraph_4" />
      </p>

      <StyledCheckbox
        label={<FormattedMessage id="guide.slides.certification.checkbox_label" />}
        onChange={handleCheckboxChange}
        checked={hasCommittedToTakeTheCertification}
      />

      <FootnoteParagraph>
        <FormattedHTMLMessage id="guide.slides.certification.content.cgv" />
      </FootnoteParagraph>
    </>
  )
}

Certification.propTypes = {
  session: propType(QUERY_FRAGMENT).isRequired,
  updateNextButtonDisabled: PropTypes.func.isRequired,
}

export default props => {
  const { sessionId } = useCourseContext()

  return (
    <PageQuery
      query={QUERY}
      variables={{ id: sessionId }}
      render={renderProps => <Certification {...renderProps} {...props} />}
    />
  )
}
