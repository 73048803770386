import React from 'react'
import PropTypes from 'prop-types'
import { Visible } from 'react-grid-system'
import TippyTooltip from '@unowmooc/tooltip'
import { Clickable } from '@unowmooc/buttons'
import StreamlineIcon from '@unowmooc/icons'
import styled from '@emotion/styled'

const Menu = styled.span`
  .tippy-active {
    color: ${({ theme: { colors } }) => colors.unowGreen} !important;
  }
`

const MobileButton = styled(Clickable)`
  font-size: 24px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.mischka};
  transition: color 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.manatee};
  }
`

const CommentActions = ({ active, preRender, className, children }) => {
  if (preRender) {
    return preRender
  }

  return active ? (
    children
  ) : (
    <>
      <Visible xs sm>
        <Menu>
          <TippyTooltip
            content={children}
            placement="left"
            flipBehavior={['left', 'bottom']}
            theme="white"
            trigger="click"
            interactive
          >
            <MobileButton className={className}>
              <StreamlineIcon icon="3dots" />
            </MobileButton>
          </TippyTooltip>
        </Menu>
      </Visible>
      <Visible md lg xl>
        <span className={className}>{children}</span>
      </Visible>
    </>
  )
}

CommentActions.defaultProps = {
  active: false,
  preRender: null,
  className: undefined,
}

CommentActions.propTypes = {
  active: PropTypes.bool,
  preRender: PropTypes.node,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default CommentActions
