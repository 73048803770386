import React from 'react'
import { Input } from '@unowmooc/react-ui-kit'
import QueryStringFilter from 'components/QueryStringFilter'
import I18nProvider from '@unowmooc/i18n'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'

const StyledInput = styled(Input)`
  width: auto;
  max-width: 170px;

  ${mq.xs(css`
    max-width: 100%;
  `)}
`

const SearchFilter = () => (
  <QueryStringFilter name="search" debounce={300}>
    <StyledInput placeholder={I18nProvider.formatMessage({ id: 'datagrid.toolbar.search_placeholder' })} />
  </QueryStringFilter>
)

export default SearchFilter
