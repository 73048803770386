function JsonConnector({ pivot, displayErrors }) {
  this.jsonData = pivot
  this.config = {
    displayErrors,
  }
}

const addIdMap = array =>
  array.map(arrayItem => {
    const nextValue = {
      // Les fichiers pivot sont dépourvus d'identifiants
      // On génère un identifiant unique pour chaque question/sous-question/réponse
      // Cela permet de faire fonctionner le formulaire collector
      id: Date.now().toString(36) + Math.random().toString(36).substr(2),
    }

    Object.keys(arrayItem).forEach(arrayItemKey => {
      if (!Array.isArray(arrayItem[arrayItemKey]) || ['conditional_question_index'].includes(arrayItemKey)) {
        nextValue[arrayItemKey] = arrayItem[arrayItemKey]

        return
      }

      nextValue[arrayItemKey] = addIdMap(arrayItem[arrayItemKey])
    })

    return nextValue
  })

const addConditionalIdMap = array =>
  array.map(arrayItem => {
    if (!arrayItem.choices) {
      return arrayItem
    }

    return {
      ...arrayItem,
      choices: arrayItem.choices.map(choiceItem => {
        const conditionalQuestionIndexValue = choiceItem.conditional_question_index

        if (!conditionalQuestionIndexValue) {
          return choiceItem
        }

        const conditionalQuestionIndexs = Array.isArray(conditionalQuestionIndexValue)
          ? conditionalQuestionIndexValue
          : [conditionalQuestionIndexValue]

        const conditionalQuestionsIds = conditionalQuestionIndexs.map(index => [array?.[index].id]).filter(v => !!v)

        return {
          ...choiceItem,
          conditional_question_id: conditionalQuestionsIds,
        }
      }),
    }
  })

JsonConnector.prototype.loadData = async function loadData() {
  const data = this.jsonData

  data.questions = addIdMap(data.questions)
  data.questions = addConditionalIdMap(data.questions)

  if (!this.config.displayErrors) {
    data.questions = data.questions.map(question => ({
      ...question,
      min_required_answers: 0,
    }))
  }

  return data.questions
}

JsonConnector.prototype.sendData = async function sendData(formData) {
  // Ce console.log permettra de valider le bon formattage des questions/réponses lors de la soumissions du formulaire
  // eslint-disable-next-line no-console
  console.log({ formData })
}

export default JsonConnector
