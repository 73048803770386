import React from 'react'
import { screens } from '@unowmooc/themes'
import styled from '@emotion/styled'
import Collector from 'modules/collector/components/Collector'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import SkipLinkAnchor from 'modules/skipLink/SkipLinkAnchor'
import ThanksScreen from './ThanksScreen'
import useMe from '../../../../hooks/useMe'

const Wrapper = styled.div`
  background-color: white;
  min-height: 100vh;
  padding-top: 60px;
`
const Container = styled.div`
  max-width: ${screens.mdMin};
  margin: auto;
  padding: 0 16px;
`

const Heading = styled.h2`
  margin-bottom: 32px;
`

const TopFragment = () => {
  const { me } = useMe()
  return (
    <>
      <div style={{ marginBottom: '70px' }}>
        <p>
          <FormattedMessage id="satisfaction_non_finisher.intro_line_1" values={{ gender: me.gender }} />
        </p>
        <br />
        <p>
          <FormattedMessage id="satisfaction_non_finisher.intro_line_2" />
        </p>
      </div>
    </>
  )
}

const SatisfactionNonFinisherForm = ({
  participantId,
  satisfactionNonFinisherFormId,
  slugCourse,
  sessionId,
  courseIdentifier,
  sessionIdentifier,
}) => (
  <Wrapper>
    <Container>
      <SkipLinkAnchor label={<FormattedMessage id="a11y.skip_to_content" />} anchor="content" priority={1} />
      <Heading>
        <FormattedMessage id="satisfaction_non_finisher.title" />
      </Heading>
      <Collector
        formUnansweredTopComponent={TopFragment}
        formId={satisfactionNonFinisherFormId}
        participantId={participantId}
        formAlreadyAnsweredScreen={
          <ThanksScreen
            slugCourse={slugCourse}
            sessionId={sessionId}
            courseIdentifier={courseIdentifier}
            sessionIdentifier={sessionIdentifier}
          />
        }
      />
    </Container>
  </Wrapper>
)

SatisfactionNonFinisherForm.propTypes = {
  participantId: PropTypes.number.isRequired,
  satisfactionNonFinisherFormId: PropTypes.number.isRequired,
  slugCourse: PropTypes.string.isRequired,
  sessionId: PropTypes.number.isRequired,
  courseIdentifier: PropTypes.string.isRequired,
  sessionIdentifier: PropTypes.string.isRequired,
}
export default SatisfactionNonFinisherForm
