import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import styled from '@emotion/styled'
import _ from 'lodash'
import DomainLayout, { DomainLayoutLink } from 'components/DomainLayout'
import { useQuery } from '@apollo/react-hooks'
import { UnowAwesome } from '@unowmooc/loaders'
import RegisteredUsersPage from 'pages/domains/supervisor/registered-users'
import DashboardPage from 'pages/domains/supervisor/dashboard'
import {
  getSupervisionTitle,
  getAvailibleSupervisions,
  getUnavailibleSupervisions,
} from 'modules/supervisor/supervisor.utils'
import SupervisionsDropdownMenu from 'modules/supervisor/components/SupervisionsDropdownMenu'
import SupervisionLink from 'modules/supervisor/components/SupervisionsDropdownMenu/components/Link'
import SupervisionLinkExpired from 'modules/supervisor/components/SupervisionsDropdownMenu/components/LinkExpired'
import { withUser } from 'store/UserProvider'

import SessionsPage from 'pages/domains/supervisor/sessions'
import WelcomePage from './welcome'
import SupervisionPage from './supervision'

const SyledWrapper = styled.div`
  margin-left: 16px;

  > a:first-child {
    :before {
      content: '';
      position: absolute;
      width: 10px;
      top: 0px;
      left: 0px;
      bottom: 23px;

      border-left: 1px solid ${({ theme }) => theme.colors.unowGreen};
      border-bottom: 1px solid ${({ theme }) => theme.colors.unowGreen};
    }
  }

  > a:not(:first-child) {
    :before {
      content: '';
      position: absolute;
      width: 10px;
      top: -23px;
      left: 0px;
      bottom: 23px;

      border-left: 1px solid ${({ theme }) => theme.colors.unowGreen};
      border-bottom: 1px solid ${({ theme }) => theme.colors.unowGreen};
    }
  }
`

export const query = gql`
  query Supervisions($filters: Json, $search: String, $offset: Int, $sort: [String]) {
    supervisions(filters: $filters, sort: $sort, search: $search, limit: 50, offset: $offset) {
      items {
        id
        project {
          id
          type
          nbAvailableLicenses
          nbLicenses
          expireAt
          displayedName
        }
      }
    }
  }
`

const REQUEST_DASHBOARDS = gql`
  query Sessions {
    supervisedDashboardsList {
      items {
        id
        label
        url
      }
    }
  }
`

const SupervisorPage = ({ user }) => {
  const { loading, data } = useQuery(query, {
    variables: {
      filters: JSON.stringify({
        user_id: user.id,
      }),
    },
  })

  const { loading: dashboardsLoading, data: dashboardsData } = useQuery(REQUEST_DASHBOARDS)

  const childrenExpiredPacks = loading ? (
    <UnowAwesome size={40} />
  ) : (
    _.map(getUnavailibleSupervisions(_.get(data, 'supervisions.items', [])), ({ id, project }, index) => (
      <SupervisionLinkExpired
        key={`supervision-expired-${id}`}
        domain="supervisor"
        name={id}
        label={getSupervisionTitle(project, index)}
      />
    ))
  )

  const childrenPacks = loading ? (
    <UnowAwesome size={40} />
  ) : (
    _.map(getAvailibleSupervisions(_.get(data, 'supervisions.items', [])), ({ id, project }, index) => (
      <SupervisionLink
        key={`supervision-${id}`}
        domain="supervisor"
        name={id}
        label={getSupervisionTitle(project, index)}
      />
    ))
  )

  if (dashboardsLoading) {
    return <UnowAwesome size={40} />
  }

  const hasDashboard =
    dashboardsData.supervisedDashboardsList.items && dashboardsData.supervisedDashboardsList.items.length > 0

  return (
    <>
      <DomainLayout domain="supervisor" routeIndex="home">
        <DomainLayoutLink name="home" icon="Notebook" component={WelcomePage} />
        <SupervisionsDropdownMenu
          name="packs"
          to="/espace-superviseur/packs/"
          icon="AllowBlog"
          component={SupervisionPage}
          param="supervisionId"
        >
          <SyledWrapper>{childrenPacks}</SyledWrapper>
        </SupervisionsDropdownMenu>
        <SupervisionsDropdownMenu
          name="packs-expired"
          to="/espace-superviseur/packs-expires/"
          icon="DisallowBlog"
          component={SupervisionPage}
          param="supervisionId"
        >
          <SyledWrapper>{childrenExpiredPacks}</SyledWrapper>
        </SupervisionsDropdownMenu>
        <DomainLayoutLink name="sessions" icon="Formation" component={SessionsPage} />
        <DomainLayoutLink name="registered_users" icon="Trophy" component={RegisteredUsersPage} />
        {hasDashboard && <DomainLayoutLink name="supervisor_dashboard" icon="Notebook" component={DashboardPage} />}
      </DomainLayout>
    </>
  )
}

SupervisorPage.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
}

export default withUser(SupervisorPage)
