import _ from 'lodash'
import { sprintf } from 'sprintf-js'

import { useGA } from './providers/ga'

export const useTracking = () => {
  const [config, ReactGA] = useGA()

  const sendEvent = (trackingId, trackingValues) => {
    const values = JSON.parse(trackingValues || '{}')

    const event = _.get(config, trackingId)

    if (event) {
      ReactGA.event(
        _.mapValues(event, (string, key) => {
          const text = sprintf(string, values)

          if (key === 'value') {
            return Number(text)
          }
          return text
        }),
      )
    }
  }

  return { sendEvent }
}
