import React from 'react'
import styled from '@emotion/styled'
import SkillsForm from 'components/SkillsForm'

const InitialSkillsWrapper = styled.div`
  margin: 60px 0;
`

const InitialSkillsBlock = () => (
  <InitialSkillsWrapper>
    <SkillsForm context={SkillsForm.contexts.BIC} hasHalfSummary />
  </InitialSkillsWrapper>
)

export default InitialSkillsBlock
