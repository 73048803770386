import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { colors } from '@unowmooc/themes'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import I18nProvider from '@unowmooc/i18n'

const TestimonialBox = styled.div`
  background: #ffffff;
  box-shadow: 0px 13px 46px rgba(223, 223, 231, 0.5);
  border-radius: 4px;
  margin-bottom: 30px;
  padding: 30px;
`

const Heading4 = styled.h4`
  line-height: 48px;
`

const ParticipantTestimonialContainer = styled.div`
  margin-top: 24px;
`

const IdentityName = styled.span``

const Identity = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`

const Summary = styled.p`
  strong {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    color: ${colors.unowGreen};
  }
`

const TestimonialContent = styled.p`
  font-size: 14px;
  line-height: 23px;
`

const ParticipantTestimonial = ({ name, testimonial }) => (
  <ParticipantTestimonialContainer>
    <Identity>
      <IdentityName>{name}</IdentityName>
    </Identity>
    <TestimonialContent>{testimonial}</TestimonialContent>
  </ParticipantTestimonialContainer>
)

const Testimonials = () => (
  <>
    <Heading4>
      <FormattedMessage id="virtual_classroom.testimonials_bloc.title" />
    </Heading4>
    <TestimonialBox>
      <Summary>
        <FormattedHTMLMessage id="virtual_classroom.testimonials_bloc.summary" />
      </Summary>
      <ParticipantTestimonial
        name={I18nProvider.formatMessage({ id: 'virtual_classroom.testimonials_bloc.testimonial_item_1.identity' })}
        testimonial={<FormattedMessage id="virtual_classroom.testimonials_bloc.testimonial_item_1.text" />}
      />
      <ParticipantTestimonial
        name={I18nProvider.formatMessage({ id: 'virtual_classroom.testimonials_bloc.testimonial_item_2.identity' })}
        testimonial={<FormattedMessage id="virtual_classroom.testimonials_bloc.testimonial_item_2.text" />}
      />
    </TestimonialBox>
  </>
)

ParticipantTestimonial.propTypes = {
  name: PropTypes.string.isRequired,
  testimonial: PropTypes.node.isRequired,
}

export default Testimonials
