import React from 'react'
import PropTypes from 'prop-types'
import SurveyProgression from 'components/SurveyProgression'
import { Button } from '@unowmooc/buttons'
import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl'
import { useCollector } from '../../provider'
import Question from '../Question'

const QuestionFooter = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  border-top: 1px solid ${({ theme: { colors } }) => colors.mischka};
  padding: 22px 0 24px;

  button {
    margin: 0 10px;
  }

  .collector-previous-button {
    color: ${({ theme: { colors } }) => colors.unowGreen};
  }
`

const RequiredTag = styled.span`
  color: ${({ theme: { colors } }) => colors.pastelRed};
`

const PageInformation = styled.p`
  text-align: right;
  padding: 16px 0;
`

const Page = ({
  nextButtonLabel,
  submitButtonLabel,
  formAlreadyAnsweredScreen,
  formUnansweredTopComponent: FormUnansweredTopComponent,
}) => {
  const {
    pageNumber,
    currentPage,
    currentQuestions,
    handleBackPage,
    isLastPage,
    isFirstPage,
    submitting,
    hasAnswers,
  } = useCollector()

  if (formAlreadyAnsweredScreen && hasAnswers) {
    return formAlreadyAnsweredScreen
  }

  return (
    <div>
      {FormUnansweredTopComponent && !hasAnswers && <FormUnansweredTopComponent />}

      {pageNumber > 1 && (
        <SurveyProgression
          label={<FormattedMessage id="survey.progression_label" />}
          numberOfQuestions={pageNumber}
          currentQuestionNumber={currentPage}
          showProgressBar
        />
      )}

      {currentQuestions.some(question => question.min_required_answers > 0) && (
        <PageInformation>
          <RequiredTag>*</RequiredTag>&nbsp;
          <FormattedMessage id="survey.page_info_required" />
        </PageInformation>
      )}

      {currentQuestions.map(question => (
        <Question key={question.id} question={question} />
      ))}

      <QuestionFooter>
        {!isFirstPage && pageNumber > 1 && (
          <Button
            type="button"
            className="collector-previous-button"
            theme="secondary"
            icon="left"
            size="large"
            onClick={handleBackPage}
            tabIndex={-1}
          />
        )}
        <Button size="large" type="submit" loading={submitting}>
          {isLastPage ? submitButtonLabel : nextButtonLabel}
        </Button>
      </QuestionFooter>
    </div>
  )
}

Page.defaultProps = {
  nextButtonLabel: <FormattedMessage id="survey.default_button_label.next" />,
  submitButtonLabel: <FormattedMessage id="survey.default_button_label.submit" />,
  formAlreadyAnsweredScreen: null,
  formUnansweredTopComponent: null,
}

Page.propTypes = {
  nextButtonLabel: PropTypes.node,
  submitButtonLabel: PropTypes.node,
  formAlreadyAnsweredScreen: PropTypes.node,
  formUnansweredTopComponent: PropTypes.node,
}

export default Page
