import PropTypes from 'prop-types'

export const questionPropTypesDefault = {
  min_required_answers: 0,
}

export const questionPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  min_required_answers: PropTypes.number,
  page_number: PropTypes.number.isRequired,
  position_in_page: PropTypes.number.isRequired,
  type: PropTypes.oneOf(['text', 'single_choice', 'multiple_choice', 'matrix']).isRequired,
  subtype: PropTypes.oneOf(['single', 'essay', 'radio_buttons', 'scale', 'dropdown', 'birth_year', 'checkboxes'])
    .isRequired,
  title: PropTypes.string.isRequired,
  subquestions: PropTypes.arrayOf(PropTypes.shape({})),
})
