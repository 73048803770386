import React, { useReducer } from 'react'
import keyMirror from 'keymirror'
import { FormattedMessage } from 'react-intl'
import { getVirtualClassroomLabel } from './utils'

const ACTION_TYPES = keyMirror({
  INIT_MATRIX: null,
  CHECK_PARTICIPANT_TO_VIRTUAL_CLASSROOM: null,
  UNCHECK_PARTICIPANT_TO_VIRTUAL_CLASSROOM: null,
  SET_SELECTED_VIRTUAL_CLASSROOM: null,
})

export const initMatrix = ({
  virtualClassrooms,
  participants,
  virtualClassroomsAttendances,
  selectedVirtualClassroom,
}) => ({
  type: ACTION_TYPES.INIT_MATRIX,
  payload: { virtualClassrooms, participants, virtualClassroomsAttendances, selectedVirtualClassroom },
})

export const checkParticipantToVirtualClassroom = ({ participantId, virtualClassroomId }) => ({
  type: ACTION_TYPES.CHECK_PARTICIPANT_TO_VIRTUAL_CLASSROOM,
  payload: { participantId, virtualClassroomId },
})

export const uncheckParticipantToVirtualClassroom = ({ participantId, virtualClassroomId }) => ({
  type: ACTION_TYPES.UNCHECK_PARTICIPANT_TO_VIRTUAL_CLASSROOM,
  payload: { participantId, virtualClassroomId },
})

export const setSelectedVirtualClassroom = ({ virtualClassroom }) => ({
  type: ACTION_TYPES.SET_SELECTED_VIRTUAL_CLASSROOM,
  payload: { virtualClassroom },
})

export const getAttendanceKey = ({ participantId, virtualClassroomId }) => `${participantId}_${virtualClassroomId}`

export const getVirtualClassroomsOptions = state => [
  {
    id: null,
    label: <FormattedMessage id="expert.virtual_classroom.option_label_all" />,
  },
  ...state.virtualClassrooms.map(virtualClassroom => ({
    ...virtualClassroom,
    label: getVirtualClassroomLabel(virtualClassroom),
  })),
]

export const getVirtualClassroomSelectValue = ({ selectedVirtualClassrooms }) => {
  if (selectedVirtualClassrooms.length !== 1) {
    return null
  }

  const virtualClassroom = selectedVirtualClassrooms[0]

  return {
    ...virtualClassroom,
    label: getVirtualClassroomLabel(virtualClassroom),
  }
}

const attendancesReducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.INIT_MATRIX: {
      const denormalizedAttendances = []
      action.payload.virtualClassroomsAttendances.forEach(virtualClassroomAttendance => {
        denormalizedAttendances.push(getAttendanceKey(virtualClassroomAttendance))
      })

      return {
        virtualClassrooms: action.payload.virtualClassrooms,
        participants: action.payload.participants,
        attendances: denormalizedAttendances,
        selectedVirtualClassrooms: action.payload.selectedVirtualClassroom
          ? [action.payload.selectedVirtualClassroom]
          : action.payload.virtualClassrooms,
      }
    }
    case ACTION_TYPES.CHECK_PARTICIPANT_TO_VIRTUAL_CLASSROOM:
      return {
        ...state,
        attendances: [...state.attendances, getAttendanceKey(action.payload)],
      }
    case ACTION_TYPES.UNCHECK_PARTICIPANT_TO_VIRTUAL_CLASSROOM: {
      const attendanceKey = getAttendanceKey(action.payload)
      const nextAttendances = state.attendances.filter(anAttendanceKey => anAttendanceKey !== attendanceKey)

      return {
        ...state,
        attendances: nextAttendances,
      }
    }
    case ACTION_TYPES.SET_SELECTED_VIRTUAL_CLASSROOM: {
      const virtualClassroom = action.payload.virtualClassroom ?? null

      return {
        ...state,
        selectedVirtualClassrooms: virtualClassroom ? [virtualClassroom] : [...state.virtualClassrooms],
      }
    }
    default:
      return state
  }
}

export const useAttendancesReducer = () =>
  useReducer(attendancesReducer, {
    virtualClassrooms: [],
    participants: [],
    attendances: [],
    selectedVirtualClassrooms: [],
  })
