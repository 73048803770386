import React, { useState } from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { Tabs as UITabs } from '@unowmooc/react-ui-kit'
import Card from 'components/Card'
import { Route, Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import Title from 'components/Title'
import { UnowAwesome } from '@unowmooc/loaders'

const REQUEST_DASHBOARDS = gql`
  query Sessions {
    supervisedDashboardsList {
      items {
        id
        label
        url
      }
    }
  }
`

const DashboardPage = () => {
  const [activeTab, setActiveTab] = useState(false)
  const [tabItems, setTabItems] = useState([])

  const { loading, data } = useQuery(REQUEST_DASHBOARDS, {
    onCompleted(dataCompleted) {
      const tabs = []

      if (dataCompleted.supervisedDashboardsList.items && dataCompleted.supervisedDashboardsList.items.length) {
        dataCompleted.supervisedDashboardsList.items.forEach(supervisedDashboardsListItem => {
          tabs.push({
            id: supervisedDashboardsListItem.id,
            name: supervisedDashboardsListItem.label,
          })
        })
      }

      if (tabs) {
        setActiveTab(tabs[0])
      }

      setTabItems(tabs)
    },
  })

  if (loading) {
    return <UnowAwesome size={40} />
  }

  if (!data.supervisedDashboardsList.items || data.supervisedDashboardsList.items.length === 0) {
    return <UnowAwesome size={40} />
  }

  let dashboardUrl = null
  let dashboardTitle = null

  data.supervisedDashboardsList.items.forEach(item => {
    if (item.id === activeTab.id) {
      dashboardTitle = item.label
      dashboardUrl = item.url
    }
  })

  return (
    <Route
      exact
      path="/espace-superviseur/dashboard_superviseur"
      render={() => (
        <>
          <Title style={{ marginBottom: '5px' }}>
            <FormattedMessage id="menu.supervisor.supervisor_dashboard" />
          </Title>

          <UITabs
            tabs={tabItems}
            active={activeTab}
            tabComponent={Link}
            tabProps={tab => ({
              to: `?dashboardId=${tab.id}`,
              relative: 'path',
              onClick(clickedTab) {
                setActiveTab(clickedTab)
              },
            })}
          />

          <Card style={{ marginTop: '15px' }}>
            <iframe
              title={dashboardTitle}
              width="100%"
              style={{
                border: 'none',
                height: '70vh',
              }}
              src={dashboardUrl}
            />
          </Card>
        </>
      )}
    />
  )
}

DashboardPage.propTypes = {}

export default DashboardPage
