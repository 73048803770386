import { useReducer } from 'react'
import keyMirror from 'keymirror'

const init = sequence => sequence

const ACTION_TYPES = keyMirror({
  INIT_VIRTUAL_CLASSROOM_SEQUENCE: null,
  REGISTER_VIRTUAL_CLASSROOM: null,
  UNREGISTER_VIRTUAL_CLASSROOM: null,
  CONFIRM_PRESENCE_VIRTUAL_CLASSROOM: null,
  REFRESH: null,
})

export const initVirtualClassroomSequence = sequence => ({
  type: ACTION_TYPES.INIT_VIRTUAL_CLASSROOM_SEQUENCE,
  payload: { sequence },
})

export const registerVirtualClassroom = virtualClassroomId => ({
  type: ACTION_TYPES.REGISTER_VIRTUAL_CLASSROOM,
  payload: { virtualClassroomId },
})

export const unregisterVirtualClassroom = virtualClassroomId => ({
  type: ACTION_TYPES.UNREGISTER_VIRTUAL_CLASSROOM,
  payload: { virtualClassroomId },
})

export const confirmPresenceVirtualClassroom = virtualClassroomId => ({
  type: ACTION_TYPES.CONFIRM_PRESENCE_VIRTUAL_CLASSROOM,
  payload: { virtualClassroomId },
})

export const refreshSequence = () => ({
  type: ACTION_TYPES.REFRESH,
})

const sequenceReducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.INIT_VIRTUAL_CLASSROOM_SEQUENCE:
      return {
        initialized: true,
        ...action.payload.sequence,
      }
    case ACTION_TYPES.CONFIRM_PRESENCE_VIRTUAL_CLASSROOM:
      return {
        ...state,
        virtualClassrooms: state.virtualClassrooms.map(vc => {
          if (vc.id !== action.payload.virtualClassroomId) {
            return vc
          }

          return {
            ...vc,
            hasAuthenticatedParticipantConfirmedPresence: true,
          }
        }),
      }
    case ACTION_TYPES.REGISTER_VIRTUAL_CLASSROOM:
      return {
        ...state,
        virtualClassrooms: state.virtualClassrooms.map(vc => {
          if (vc.id !== action.payload.virtualClassroomId) {
            return vc
          }

          return {
            ...vc,
            hasAuthenticatedParticipantRegistered: true,
            participantCount: vc.participantCount + 1,
          }
        }),
      }
    case ACTION_TYPES.UNREGISTER_VIRTUAL_CLASSROOM:
      return {
        ...state,
        virtualClassrooms: state.virtualClassrooms.map(vc => {
          if (vc.id !== action.payload.virtualClassroomId) {
            return vc
          }

          return {
            ...vc,
            hasAuthenticatedParticipantRegistered: false,
            participantCount: vc.participantCount - 1,
          }
        }),
      }
    case ACTION_TYPES.REFRESH:
      return { ...state }
    default:
      return state
  }
}

export const useSequenceReducer = () => useReducer(sequenceReducer, { virtualClassrooms: [] }, init)
