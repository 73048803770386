import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import qs from 'query-string'
import gql from 'graphql-tag'
import _ from 'lodash'
import { toInlineFragment } from 'fraql'
import { useLazyQuery } from '@apollo/react-hooks'
import { Flex, Box } from 'reflexbox'
import { ButtonI18n } from '@unowmooc/buttons'
import { Heading, Text } from '@unowmooc/text'
import { UnowOrange } from '@unowmooc/loaders'
import { ZeroHeightUnow } from '@unowmooc/zeroheight'
import ErrorHandler from 'components/PageQuery/ErrorHandler'
import { Pagination } from '@unowmooc/react-ui-kit'
import { withUser } from 'store/UserProvider'
import ExpertCommentStatusSelect from 'modules/comments/components/ExpertCommentStatusSelect'
import ExpertSessionSelect from 'modules/session/components/ExpertSessionSelect'
import ThreadExpert from 'modules/comments/components/ThreadExpert'
import { COMMENT_STATUS_SELECTOR } from 'modules/comments/comment.constants'
import { useDidUpdateEffect } from 'utils/useDidUpdateEffect'

const GET_COMMENTS = gql`
  query Comment($withSessionId: String, $withIsResolved: Boolean, $offset: Int, $limit: Int) {
    commentsForAuthenticatedAnimator(withSessionId: $withSessionId, withIsResolved: $withIsResolved, sortCode: "thread_last_message_created_at_desc", limit: $limit, offset: $offset) {
      items {
        id
        ${toInlineFragment(ThreadExpert.fragment)}
      }
      _meta {
        total
      }
    }
  }
`

const CommentsPage = ({ user, history: { push }, location: { search, pathname } }) => {
  const limit = 10
  const parsedQueryString = useMemo(() => qs.parse(search), [search])
  const [pageParams, setPageParams] = useState({
    sessionId: parsedQueryString.sessionId,
    commentStatus: parsedQueryString.commentStatus || COMMENT_STATUS_SELECTOR.UNRESOLVED,
    page: Number(parsedQueryString.page) || 1,
  })
  const [loadComments, { called, loading, error, data }] = useLazyQuery(GET_COMMENTS, {
    /**
     * Par defaut, la policy est cache-first
     *
     * Mais dans notre cas les données doivent être les plus récentes possible
     */
    fetchPolicy: 'cache-and-network',
  })
  const nbCommentsOnPage = _.get(data, 'commentsForAuthenticatedAnimator.items.length', 0)

  useDidUpdateEffect(() => {
    setPageParams({
      ...parsedQueryString,
      page: Number(parsedQueryString.page) || 1,
      commentStatus: parsedQueryString.commentStatus || COMMENT_STATUS_SELECTOR.UNRESOLVED,
    })
  }, [parsedQueryString])

  useEffect(() => {
    const diffOfSessionId = parsedQueryString.sessionId !== pageParams.sessionId
    const diffOfCommentStatus =
      parsedQueryString.commentStatus && parsedQueryString.commentStatus !== pageParams.commentStatus

    if (called && !loading && (diffOfSessionId || diffOfCommentStatus)) {
      delete parsedQueryString.page

      push({
        pathname,
        search: qs.stringify(parsedQueryString),
      })
    }
  }, [called, loading, push, parsedQueryString, nbCommentsOnPage])

  useEffect(() => {
    const variables = {
      limit,
      offset: pageParams.page ? (pageParams.page - 1) * limit : 0,
    }

    if (pageParams.sessionId) {
      variables.withSessionId = pageParams.sessionId
    }

    if ([COMMENT_STATUS_SELECTOR.RESOLVED, COMMENT_STATUS_SELECTOR.UNRESOLVED].includes(pageParams.commentStatus)) {
      variables.withIsResolved = pageParams.commentStatus === COMMENT_STATUS_SELECTOR.RESOLVED
    }

    loadComments({
      variables,
    })
  }, [pageParams])

  const resetFilters = () => {
    push({
      search: '',
    })
  }

  const onPageChange = ({ selected }) => {
    if (!selected) {
      delete parsedQueryString.page
    } else {
      parsedQueryString.page = selected + 1
    }

    push({
      search: qs.stringify(parsedQueryString),
    })
  }

  const total = _.get(data, 'commentsForAuthenticatedAnimator._meta.total', 0)
  const showResetFilters = pageParams.sessionId || pageParams.commentStatus === COMMENT_STATUS_SELECTOR.RESOLVED
  const showCongratulations = called && !loading && !error && !nbCommentsOnPage

  return (
    <Box maxWidth="1142px" mt={['20px', '0px']} mx={['-40px', '0px']}>
      <Heading as="h1" ml={['20px', '0px']} i18nKey="expert.comments.title" />

      <Box
        my="30px"
        variant="card"
        width={1}
        padding={['20px', '20px']}
        display="flex"
        flexDirection={['column', 'row']}
      >
        <Box width={[1, 1 / 3]} margin={['0 0 20px 0', '0 20px 0 0']}>
          <ExpertCommentStatusSelect />
        </Box>
        <Box width={[1, 1 / 3]}>
          <ExpertSessionSelect />
        </Box>
      </Box>

      <Box my="30px" ml={['20px', '0px']}>
        {!loading && total !== 0 && <Text fontSize="16px" i18nKey="datagrid.toolbar.counter" values={{ total }} />}
      </Box>

      {error && <ErrorHandler error={error || 'resource_not_found'} />}

      {!error && (
        <Flex justifyContent="center" alignItems="center">
          {called && loading && <UnowOrange size={40} />}
        </Flex>
      )}

      {showCongratulations && (
        <Flex justifyContent="center" alignItems="center">
          <ZeroHeightUnow height="100px" width="100px" name="IconPartyPopper" mr="30px" />
          <Box display="inline-flex" flexDirection="column">
            <Text fontSize="18px" i18nKey="expert.comments.congratulations" />
            {showResetFilters && (
              <ButtonI18n mt="10px" size="small" i18nKey="expert.comments.reset_filters" onClick={resetFilters} />
            )}
          </Box>
        </Flex>
      )}

      <ThreadExpert comments={_.get(data, 'commentsForAuthenticatedAnimator.items')} user={user} />

      {!loading && total !== 0 && (
        <Box mx={['20px']}>
          <Pagination
            nbPages={Math.ceil(total / limit)}
            currentPage={pageParams.page}
            onPageChange={onPageChange}
            nextLabel={<Text fontWeight="500" fontSize="16px" i18nKey="pagination.next_label" />}
            previousLabel={<Text fontWeight="500" fontSize="16px" i18nKey="pagination.previous_label" />}
          />
        </Box>
      )}
    </Box>
  )
}

CommentsPage.propTypes = {
  user: PropTypes.shape().isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
}

export default withUser(withRouter(CommentsPage))
