import React, { Component } from 'react'
import { propType } from 'graphql-anywhere'
import gql from 'graphql-tag'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { FormattedMessage } from 'react-intl'
import { Box } from 'reflexbox'
import { Oval } from '@unowmooc/react-ui-kit'
import { colors, mq } from 'styles-resources'

const StyledOval = styled(Oval)`
  margin-right: 10px;
`

const Status = styled.span`
  color: ${colors.midGray};

  ${mq.sm(css`
    font-size: 16px;
    line-height: 22px;
  `)};
`

class ParticipationAdvancement extends Component {
  get color() {
    const {
      advancement: { status },
    } = this.props

    switch (status) {
      case 'certified':
        return colors.unowOrange
      case 'up_to_date':
        return colors.unowGreen
      case 'late':
      case 'finisher_not_certified':
        return colors.perfume
      default:
        return colors.whisper
    }
  }

  render() {
    const {
      advancement: { status },
      user: { gender },
    } = this.props

    if (status === 'not_started') {
      return null
    }

    return (
      <Box display="inline">
        <StyledOval color={this.color} />
        <Status>
          <FormattedMessage id={`dashboard.participations.in_progress.advancement.${status}`} values={{ gender }} />
        </Status>
      </Box>
    )
  }
}

ParticipationAdvancement.fragments = {
  advancement: gql`
    fragment ParticipationAdvancement on Advancement {
      status
    }
  `,
  user: gql`
    fragment ParticipationAdvancementUser on User {
      gender
    }
  `,
}

ParticipationAdvancement.propTypes = {
  advancement: propType(ParticipationAdvancement.fragments.advancement).isRequired,
  user: propType(ParticipationAdvancement.fragments.user).isRequired,
}

export default ParticipationAdvancement
