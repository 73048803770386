import { getIdFromSession } from 'modules/session/session.selectors'
import { getAnimationBySessionId } from 'modules/animation/animation.utils'
import { ROLES } from './user.constants'
import { getRole } from './user.selectors'

export const isRoleValid = role => Object.keys(ROLES).findIndex(item => ROLES[item] === role) >= 0

export const isAdmin = user => getRole(user) === ROLES.ADMIN

export const isUserAnimatorOfTheSession = (user, session) =>
  !!getAnimationBySessionId(user?.animations, getIdFromSession(session))
