import React from 'react'
import { propType } from 'graphql-anywhere'
import gql from 'graphql-tag'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import I18nProvider from '@unowmooc/i18n'
import { StarItem } from 'components/TimelineMenu'
import { isSessionStarted } from 'modules/session/utils'
import PropTypes from 'prop-types'

const StyledLink = styled(Link)`
  outline-offset: -2px;

  &:hover {
    text-decoration: none;
  }
`

const FinalExamItem = ({ session, isMenuOpen }) => {
  const to = I18nProvider.getLinkRoute('/courses/{slugCourse}/{idSession}/lessons/final-exam', {
    slugCourse: session.course.slug,
    idSession: session.id,
  })

  return isSessionStarted(session) ? (
    <StyledLink to={to} data-cy="link-final-exam" tabIndex={isMenuOpen ? 0 : -1}>
      <StarItem title={<FormattedMessage id="courses.course.program.final_exam" />} />
    </StyledLink>
  ) : (
    <StarItem title={<FormattedMessage id="courses.course.program.final_exam" />} data-cy="link-final-exam" disabled />
  )
}

FinalExamItem.fragments = {
  session: gql`
    fragment _ on Session {
      id
      startAt
      confirmedAt
      course {
        id
        slug
        hasFlexibleStart
      }
    }
  `,
}

FinalExamItem.propTypes = {
  session: propType(FinalExamItem.fragments.session).isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
}

export default FinalExamItem
