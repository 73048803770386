import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { FormattedMessage } from 'react-intl'
import { withRouter, Link } from 'react-router-dom'
import I18nProvider from '@unowmooc/i18n'
import { Box, Flex } from 'reflexbox'
import { DataGrid } from '@unowmooc/react-ui-kit'
import DataGridCell from '@unowmooc/react-ui-kit/lib/DataGrid/Cell'

import { Form } from '@unowmooc/form'
import Alert from '@unowmooc/alert'
import { UnowAwesome } from '@unowmooc/loaders'
import Tag from '@unowmooc/tags'
import { ButtonI18n } from '@unowmooc/buttons'
import { useStateMachine } from 'little-state-machine'
import { importParticipants } from 'modules/participant/actions'
import BoxSessionInfo from '../BoxSessionInfo'

const clearSessionInfo = oldState => ({
  ...oldState,
  userRegistration: {
    ...oldState.userRegistration,
    pack: {},
    session: {},
    course: {},
  },
})

const errorsSession = [
  'missing_valid_project_id',
  'missing_valid_session_id',
  'missing_http_header',
  'not_supervisor',
  'project_not_pack',
  'project_expired',
  'session_mooc_forbidden',
  'session_not_linked_to_project',
  'session_already_started',
  'project_not_found',
  'session_not_found',
  'not_enough_licences',
]

const Validation = ({ history, match: { params } }) => {
  const [importId, setImportId] = useState()
  const { state, actions } = useStateMachine({ clearSessionInfo })
  const [result, setResult] = useState({ loading: true, error: undefined, data: undefined })

  useEffect(() => {
    importParticipants(state.userRegistration, params.mode)
      .then(data => {
        setImportId(_.get(data, 'data.import_id'))
        setResult({ data, loading: false, error: undefined })
      })
      .catch(error => {
        setResult({ data: undefined, loading: false, error })
      })
  }, [])

  useEffect(() => {
    if (params.mode === 'manuel' && !result.loading && !result.error) {
      history.push(I18nProvider.getLinkRoute('/supervisor/registered_users/confirm'))
    }
  }, [result])

  const validateParticipant = event => {
    event.preventDefault()

    history.push(I18nProvider.getLinkRoute(`/supervisor/registered_users/confirm`).concat(`/${importId}`))
  }

  const retryImport = event => {
    event.preventDefault()

    if (_.includes(errorsSession, _.get(result, 'error.response.data.error.code'))) {
      actions.clearSessionInfo()
      history.push(I18nProvider.getLinkRoute('/supervisor/registered_users/session'))
    } else {
      history.push(I18nProvider.getLinkRoute(`/supervisor/registered_users/add/`).concat(`/${params.mode}`))
    }
  }

  return (
    <Form name="userRegistration" onSubmit={() => {}} persist>
      <BoxSessionInfo />
      {result.loading && (
        <Flex alignItems="center" flexDirection="column" mt="40px">
          <Box mb="30px">
            <UnowAwesome size={90} />
          </Box>
          <FormattedMessage id="supervisor.registration.add.loading" />
        </Flex>
      )}
      {_.get(result, 'error.response.data.error.code') === 'invalid_participants' && (
        <Alert theme="error" mt="20px" mb="30px">
          <FormattedMessage
            id={`supervisor.registration.add.errors.${
              params.mode === 'manuel' ? 'manual.invalid_participants' : 'invalid_participants'
            }`}
            values={{
              number: _.uniqWith(
                _.get(result, 'error.response.data.error.errors'),
                (prev, next) => prev.line === next.line,
              ).length,
            }}
          />
        </Alert>
      )}
      {_.get(result, 'error.response.data.error') &&
        _.get(result, 'error.response.data.error.code') !== 'invalid_participants' && (
          <Alert theme="error" mt="20px" mb="30px">
            <FormattedMessage
              id={`supervisor.registration.add.errors.${_.get(result, 'error.response.data.error.code')}`}
              values={{
                number: _.uniqWith(
                  _.get(result, 'error.response.data.error.errors'),
                  (prev, next) => prev.line === next.line,
                ).length,
              }}
            />
          </Alert>
        )}

      {!result.loading && result.error && (
        <Flex justifyContent="flex-end" my="30px">
          <Link to={I18nProvider.getLinkRoute('/supervisor/registered_users')}>
            <ButtonI18n i18nKey="supervisor.registration.add.buttons.backToList" mr="25px" />
          </Link>
          <a href="/" onClick={retryImport}>
            <ButtonI18n i18nKey="supervisor.registration.add.buttons.backToImport" variant="primary" />
          </a>
        </Flex>
      )}
      {_.get(result, 'error.response.data.error.errors', []).length > 0 && (
        <DataGrid data={_.get(result, 'error.response.data.error.errors', [])}>
          <DataGridCell
            label={
              <FormattedMessage
                id={`supervisor.registration.add.form.${params.mode === 'manuel' ? 'numberColaborator' : 'line'}`}
              />
            }
            width={100}
            property="line"
            render={line => (params.mode === 'manuel' ? 1 : 2) + Number(line || 0)}
          />
          <DataGridCell
            label={<FormattedMessage id="supervisor.registration.add.form.message" />}
            width="auto"
            property="code"
            render={code => <FormattedMessage id={`supervisor.registration.add.errors.${code}`} />}
          />
          <DataGridCell
            label={<FormattedMessage id="supervisor.registration.add.form.field" />}
            property="field"
            width={200}
            render={field => (field ? <FormattedMessage id={`supervisor.registration.add.form.${field}`} /> : '-')}
          />
          <DataGridCell
            label={<FormattedMessage id="supervisor.registration.add.form.status" />}
            width={100}
            render={() => (
              <Box>
                <Tag theme="lightRed" small>
                  <FormattedMessage id="supervisor.registration.add.status.error" />
                </Tag>
              </Box>
            )}
          />
        </DataGrid>
      )}
      {_.get(result, 'data.data.participants', []).length > 0 && (
        <>
          <Alert theme="success" mt="20px" mb="30px">
            <FormattedMessage
              id="supervisor.registration.add.successValidate"
              values={{ number: _.get(result, 'data.data.participants.length', 0) }}
            />
          </Alert>

          <ButtonI18n
            my="30px"
            mx="auto"
            variant="primary"
            type="submit"
            reverse
            icon="check"
            i18nKey="supervisor.registration.add.buttons.successValidate"
            onClick={validateParticipant}
            disabled={!importId}
          />

          <DataGrid data={_.get(result, 'data.data.participants', [])}>
            <DataGridCell
              label={<FormattedMessage id="supervisor.registration.add.form.firstName" />}
              property="firstName"
              width={100}
            />
            <DataGridCell
              label={<FormattedMessage id="supervisor.registration.add.form.lastName" />}
              property="lastName"
              width={100}
            />
            <DataGridCell property="email" label={<FormattedMessage id="supervisor.registration.add.form.email" />} />
            <DataGridCell property="phone" label={<FormattedMessage id="supervisor.registration.add.form.phone" />} />
            <DataGridCell
              label={<FormattedMessage id="supervisor.registration.add.form.status" />}
              render={() => (
                <Box>
                  <Tag theme="lightGreen" small>
                    <FormattedMessage id="supervisor.registration.add.status.valid" />
                  </Tag>
                </Box>
              )}
            />
          </DataGrid>
        </>
      )}
    </Form>
  )
}

Validation.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape().isRequired,
  }).isRequired,
}

export default withRouter(Validation)
