import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-grid-system'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from 'styles-resources'
import { getDownloadCatalogUrl } from 'business/website'
import LinkButtonIcon from 'components/LinkButtonIcon'
import svgIlluCatalog from './assets/illu-catalog.svg'

const ColIllu = styled(Col)`
  text-align: right;

  ${mq.md(css`
    margin-bottom: 20px;
    text-align: center;
  `)}
`

const ColText = styled(Col)`
  ${mq.md(css`
    text-align: center;
  `)}
`

const IlluCatalog = styled.img`
  margin-right: 16px;
  max-width: 400px;

  ${mq.md(css`
    margin-right: 0;
  `)}

  ${mq.sm(css`
    width: 100%;
  `)}
`

const Title = styled.h4`
  margin-bottom: 16px;
`

const Description = styled.p`
  margin-bottom: 24px;
`

const CtaDownload = ({ className }) => (
  <Row className={className}>
    <ColIllu md={12} lg={5}>
      <IlluCatalog src={svgIlluCatalog} />
    </ColIllu>
    <ColText md={12} lg={7}>
      <Title>
        <FormattedMessage id="catalog.cta_download.title" />
      </Title>
      <Description>
        <FormattedMessage id="catalog.cta_download.description" />
      </Description>
      <LinkButtonIcon
        theme="highlight"
        size="large"
        icon="download"
        href={getDownloadCatalogUrl()}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FormattedMessage id="catalog.cta_download.link" />
      </LinkButtonIcon>
    </ColText>
  </Row>
)

CtaDownload.defaultProps = {
  className: undefined,
}

CtaDownload.propTypes = {
  className: PropTypes.string,
}

export default CtaDownload
