import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'
import { Button } from '@unowmooc/react-ui-kit'
import { colors } from '@unowmooc/themes'

const Wrapper = styled.div`
  display: flex;
`

const Infos = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`

const Actions = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button:last-child {
    margin-right: 0;
  }
`

const SubmitButton = styled(Button)`
  margin-right: 20px;

  i {
    color: ${colors.unowGreen};
  }
`

const Title = styled.h2(
  `
  transition: font-size 0.1s linear;
`,
  ({ sticky }) =>
    sticky && {
      fontSize: '15px',
      fontWeight: '600',
      lineHeight: '20px',
    },
)

const Content = ({ title, submitting, dirty, submitSucceeded, addButtonLabel, sticky }) => (
  <Wrapper>
    <Infos>
      <Title sticky={sticky}>{title}</Title>
    </Infos>
    <Actions sticky={sticky}>
      <SubmitButton
        type="submit"
        theme="primary"
        label={
          !dirty && submitSucceeded ? (
            <FormattedMessage id="form.submit_button.saved" />
          ) : (
            addButtonLabel || <FormattedMessage id="form.submit_button.save" />
          )
        }
        loading={submitting}
        disabled={!dirty}
        valid={!dirty && submitSucceeded}
      />
    </Actions>
  </Wrapper>
)

Content.defaultProps = {
  title: undefined,
  submitting: false,
  dirty: false,
  submitSucceeded: false,
  addButtonLabel: undefined,
  sticky: false,
}

Content.propTypes = {
  title: PropTypes.node,
  submitting: PropTypes.bool,
  dirty: PropTypes.bool,
  submitSucceeded: PropTypes.bool,
  addButtonLabel: PropTypes.node,
  sticky: PropTypes.bool,
}

export default Content
