import _ from 'lodash'
import { isSessionStarted } from 'modules/session/utils'
import { ADMIN } from 'modules/user/user.constants'

export const getPreviousSection = ({ section, session }, canAccessDraftSection) => {
  const {
    hasAccessModuleZero,
    course: { moduleZero, modules },
  } = session

  const normalModules = canAccessDraftSection ? modules : _.filter(modules, { status: 'publish' })

  const allModules = hasAccessModuleZero ? _.concat([moduleZero], normalModules) : normalModules

  if (section.id === 'final-exam') {
    const previousModule = _.last(allModules)

    if (previousModule.hasQuiz) {
      return {
        id: 'quiz',
        module: previousModule,
        isZero: false,
      }
    }

    return {
      ..._.last(previousModule.sections),
      isZero: moduleZero && moduleZero.id === previousModule.id,
    }
  }

  const currentModuleIndex = _.findIndex(allModules, { id: section.module.id })

  if (currentModuleIndex === -1) {
    throw new Error('Unable to find current module index')
  }

  const currentModule = allModules[currentModuleIndex]

  if (section.id === 'quiz') {
    return { ..._.last(currentModule.sections), isZero: currentModuleIndex - 1 === 0 }
  }

  const currentSectionIndex = _.findIndex(currentModule.sections, { id: section.id })

  // current section is the first of the module
  if (currentSectionIndex === 0) {
    // and this module is the first
    if (currentModuleIndex === 0) {
      // no previous section
      return null
    }

    const previousModule = allModules[currentModuleIndex - 1]

    if (previousModule.hasQuiz) {
      // link to quiz
      return {
        id: 'quiz',
        module: previousModule,
        isZero: currentModuleIndex - 1 === 0,
      }
    }

    return { ..._.last(previousModule.sections), isZero: currentModuleIndex - 1 === 0 }
  }

  return { ...currentModule.sections[currentSectionIndex - 1], isZero: currentModuleIndex === 0 }
}

export const getNextSection = ({ section, session }, canAccessDraftSection) => {
  const {
    hasAccessModuleZero,
    course: { moduleZero, modules },
  } = session

  const normalModules = canAccessDraftSection ? modules : _.filter(modules, { status: 'publish' })

  const allModules = hasAccessModuleZero ? _.concat([moduleZero], normalModules) : normalModules

  if (section.id === 'final-exam') {
    return null
  }

  const currentModuleIndex = _.findIndex(allModules, { id: section.module.id })

  if (currentModuleIndex === -1) {
    throw new Error('Unable to find current module index')
  }

  const currentModule = allModules[currentModuleIndex]

  const currentSectionIndex = _.findIndex(currentModule.sections, { id: section.id })

  if (section.id === 'quiz') {
    const nextModule = allModules[currentModuleIndex + 1]

    // already the last module
    if (!nextModule) {
      return session.hasFinalExam ? { id: 'final-exam', module: null } : null
    }

    // first section of next module
    return { ..._.head(nextModule.sections), module: nextModule }
  }

  // current section is the last of the module
  if (currentSectionIndex === currentModule.sections.length - 1) {
    if (currentModule.hasQuiz) {
      // link to quiz
      return {
        id: 'quiz',
        module: currentModule,
        isZero: false,
      }
    }

    // if this module is the last
    if (currentModuleIndex === allModules.length - 1) {
      // and the session has a final exam
      if (session.hasFinalExam) {
        // link to final exam
        return {
          id: 'final-exam',
          module: null,
        }
      }

      // no next section
      return null
    }

    const nextModule = allModules[currentModuleIndex + 1]

    // first section of next module
    return { ..._.head(nextModule.sections), module: nextModule, isZero: false }
  }

  return { ...currentModule.sections[currentSectionIndex + 1], module: currentModule, isZero: currentModuleIndex === 0 }
}

export const canAccessSection = (section, session, user) => {
  if (!section) {
    return false
  }

  if (section.id === 'final-exam' && !session.hasFinalExam) {
    return false
  }

  // only admin can access to section on an unstarted session expect for module zero
  // second case session not confirmed
  if (!section.isZero && !isSessionStarted(session)) {
    return user.role === ADMIN
  }

  return true
}
