import React, { useState } from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import moment from 'moment'
import qs from 'query-string'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { Flex } from 'reflexbox'
import { FormattedMessage } from 'react-intl'
import { ViewInfoBox, AwesomeUnowLoader } from '@unowmooc/react-ui-kit'
import styled from '@emotion/styled'
import { ButtonI18n } from '@unowmooc/buttons'

import { colors } from 'styles-resources'
import BoxRow from 'components/BoxRow'
import BoxNumber from 'components/BoxNumber'
import ProgressBox, { ProgressItem } from 'components/ProgressBox'
import { useQuery } from '@apollo/react-hooks'
import { getSupervisionTitle } from 'modules/supervisor/supervisor.utils'

import AlertLicences from 'modules/project/components/AlertLicences'

const Title = styled.h3`
  margin-bottom: 30px;
  color: ${colors.shipGray};
`

const Expired = styled.span`
  margin-left: 4px;
  font-size: 12px;
  color: ${colors.bittersweet};
`

const query = gql`
  query Supervision($id: ID!) {
    supervision(id: $id) {
      id
      project {
        id
        type
        startAt
        expireAt
        nbParticipants
        nbLicenses
        nbConsumedLicenses
        nbPlannedLicenses
        nbAvailableLicenses
        completionRate
        satisfactionRate
        displayedName
      }
    }
  }
`

const Supervision = ({
  match: {
    params: { supervisionId },
  },
}) => {
  const [highlightedBox, setHighlightedBox] = useState(null)
  const { loading, error, data } = useQuery(query, {
    variables: {
      id: supervisionId,
    },
  })

  if (loading) {
    return (
      <Flex justifyContent="center">
        <AwesomeUnowLoader />
      </Flex>
    )
  }

  if (error) {
    return (
      <span>
        <FormattedMessage id="supervisor.error" />
      </span>
    )
  }

  const {
    id,
    startAt,
    type,
    expireAt,
    nbParticipants,
    nbLicenses,
    nbConsumedLicenses,
    nbPlannedLicenses,
    nbAvailableLicenses,
    displayedName,
    completionRate,
    satisfactionRate,
  } = _.get(data, 'supervision.project', {})

  return (
    <>
      {expireAt && (
        <>
          <h1>{getSupervisionTitle(_.get(data, 'supervision.project', {}))}</h1>
          <div>
            <ViewInfoBox
              label={<FormattedMessage id="project.start_at" />}
              value={moment(startAt).format('DD/MM/YYYY')}
            />
            <ViewInfoBox
              label={<FormattedMessage id="project.expire_at" />}
              value={moment(expireAt).format('DD/MM/YYYY')}
            />
          </div>
        </>
      )}

      <AlertLicences expireAt={expireAt} nbAvailableLicenses={nbAvailableLicenses} />

      {moment().isBefore(expireAt) && nbAvailableLicenses > 0 && (
        <Link
          to={{
            pathname: '/espace-superviseur/inscrits/session',
            search: qs.stringify({
              packId: id,
              packLabel: getSupervisionTitle({ type, displayedName, nbLicenses }),
            }),
          }}
        >
          <ButtonI18n
            mx="auto"
            mb={['28px']}
            i18nKey="supervisor.welcome.buttons.add_colleague"
            variant="primary"
            data-tracking-id="session.register_participants"
          />
        </Link>
      )}

      <BoxRow>
        <BoxNumber
          number={nbParticipants}
          text={<FormattedMessage id="project.statistics.nb_participants_label" values={{ nbParticipants }} />}
        />
        <BoxNumber
          number={completionRate * 100}
          unit="%"
          text={<FormattedMessage id="project.statistics.completion_rate_label" />}
        />
        <BoxNumber
          number={satisfactionRate * 10}
          unit="/ 10"
          maximumFractionDigits={1}
          text={<FormattedMessage id="project.statistics.satisfaction_rate_label" />}
        />
      </BoxRow>
      <Title>
        <FormattedMessage id="project.licenses.title" />
      </Title>
      <ProgressBox point={nbLicenses}>
        <ProgressItem
          point={nbConsumedLicenses}
          color={colors.unowBluegreen}
          onHover={isHover => setHighlightedBox(isHover ? 'consumed' : null)}
        />
        <ProgressItem
          point={nbPlannedLicenses > 0 ? nbConsumedLicenses + nbPlannedLicenses : nbPlannedLicenses}
          label={nbPlannedLicenses}
          color={colors.moodyBlue}
          onHover={isHover => setHighlightedBox(isHover ? 'planned' : null)}
        />
      </ProgressBox>
      <BoxRow>
        <BoxNumber
          number={nbLicenses}
          text={<FormattedMessage id="project.statistics.nb_purchased_licenses" values={{ count: nbLicenses }} />}
        />
        <BoxNumber
          number={nbConsumedLicenses}
          text={
            <FormattedMessage id="project.statistics.nb_consumed_licenses" values={{ count: nbConsumedLicenses }} />
          }
          color={colors.unowBluegreen}
          highlight={highlightedBox === 'consumed'}
        />
        <BoxNumber
          number={nbPlannedLicenses}
          text={<FormattedMessage id="project.statistics.nb_planned_licenses" values={{ count: nbPlannedLicenses }} />}
          color={colors.moodyBlue}
          highlight={highlightedBox === 'planned'}
        />
        <BoxNumber
          number={nbAvailableLicenses}
          text={
            <>
              <FormattedMessage id="project.statistics.nb_available_licenses" values={{ count: nbAvailableLicenses }} />
              {expireAt && moment(expireAt).isSameOrBefore() && (
                <Expired>
                  <FormattedMessage id="project.statistics.pack_expired" />
                </Expired>
              )}
            </>
          }
          color={nbAvailableLicenses < 0 ? colors.bittersweet : colors.shipGray}
        />
      </BoxRow>
    </>
  )
}

Supervision.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      supervisionId: PropTypes.string.isRequired,
    }),
  }).isRequired,
}

export default Supervision
