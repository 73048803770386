import parse from 'url-parse'

export const addQueryStringParams = (url, params) => {
  const parsedUrl = parse(url, true)

  parsedUrl.set('query', { ...parsedUrl.query, ...params })

  return parsedUrl.toString()
}

export const toRelative = url => {
  const {
    location: { origin },
  } = window

  return url.replace(origin, '')
}
