import React from 'react'
import { FormattedMessage } from 'react-intl'
import Tag from '@unowmooc/tags'
import Tooltip from '@unowmooc/tooltip'

import { propType } from 'graphql-anywhere'
import gql from 'graphql-tag'

import { getParticipantStatusTheme } from '../../utils'

const TagParticipantStatus = ({ status }) => (
  <Tooltip content={<FormattedMessage id={`participant.status_tooltip.${status}`} />}>
    {/* Pour l'instant le composant tag ne forward pas la ref comme tippy le demande
            https://www.npmjs.com/package/@tippy.js/react/v/2.2.3#component-children */}
    <span>
      <Tag theme={getParticipantStatusTheme({ status })} small>
        <FormattedMessage id={`participant.status_values.${status}`} />
      </Tag>
    </span>
  </Tooltip>
)

TagParticipantStatus.fragment = gql`
  fragment TagParticipantStatusFragment on Participant {
    status
  }
`

TagParticipantStatus.propTypes = propType(TagParticipantStatus.fragment).isRequired

export default TagParticipantStatus
