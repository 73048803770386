import React from 'react'
import styled from '@emotion/styled'
import { Modal as BootstrapModal } from '@unowmooc/modal'
import { mq } from '@unowmooc/themes'
import { css } from '@emotion/core'
import PropTypes from 'prop-types'

const BootstrapModalStyled = styled(BootstrapModal)`
  z-index: 1;
`

const Overlay = styled('div')`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: ${({ overlayWidth }) => overlayWidth}px;
  max-height: calc(100vh - 80px);
  border: none;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: ${({ theme: { colors } }) => colors.white};
  padding: 40px;
  overflow-y: auto;
  z-index: 2;

  ${mq.sm(css`
    height: 100vh;
    width: 100vw;
    max-height: calc(100vh - 60px);
    margin-top: 60px;
    border-radius: 0;
  `)};
`

const Modal = props => (
  <BootstrapModalStyled color="woodsmoke">
    <Overlay {...props} />
  </BootstrapModalStyled>
)

Modal.defaultProps = {
  overlayWidth: 768,
}

Modal.propTypes = {
  overlayWidth: PropTypes.number,
}

export default Modal
