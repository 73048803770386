import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { useQuiz } from 'modules/quiz/provider'
import useMe from '../../../../../../../hooks/useMe'

const RemainingAttemptsLabel = ({ className }) => {
  const { remainingAttempts } = useQuiz()

  const {
    me: { isLms },
  } = useMe()

  // si on est lms, on peux faire autant de tentatives que l'on souhaite
  if (isLms) {
    return <></>
  }

  return (
    <p className={className}>
      <FormattedMessage
        id="final_exam.remaining_attempts_label"
        values={{ nbRemainingAttempts: remainingAttempts, nbTotalAttempts: 3 }}
      />
    </p>
  )
}

RemainingAttemptsLabel.defaultProps = {
  className: undefined,
}

RemainingAttemptsLabel.propTypes = {
  className: PropTypes.string,
}

export default RemainingAttemptsLabel
