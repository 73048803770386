import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Redirect, Link } from 'react-router-dom'
import { DataGrid } from '@unowmooc/react-ui-kit'
import moment from 'moment'
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import _ from 'lodash'
import qs from 'query-string'
import { withUser } from 'store/UserProvider'
import { Box, Flex } from 'reflexbox'
import { Visible } from 'react-grid-system'
import { getSupervisionResultsUrl } from 'business/supervisor'
import I18nProvider from '@unowmooc/i18n'
import styled from '@emotion/styled'
import { ButtonI18n } from '@unowmooc/buttons'
import DataGridCell from '@unowmooc/react-ui-kit/lib/DataGrid/Cell'
import DataGridCellDate from '@unowmooc/react-ui-kit/lib/DataGrid/CellDate'
import StreamlineIcon from '@unowmooc/icons'
import DataGridToolbar from 'components/DataGridToolbar'
import QueryString from 'components/QueryString'
import { Select } from '@unowmooc/inputs'
import { getAvailableSessionsToRegister } from 'modules/supervisor/supervisor.api'
import SelectMonth from 'components/Select/Month'

import {
  getPublicProductFile,
  getUniqueThemeFromCourses,
  getSelectorTitle,
  sortByTitle,
  getCoursesPlanning,
} from 'modules/course/course.utils'
import { List } from 'components/List'
import Image from 'components/Image'
import Card from 'components/Card'
import Supervisions from 'modules/supervisor/components/Supervisions'
import { sortByLabel } from 'modules/theme/theme.utils'

import {
  getSessionsByMonth,
  getSessionsByCourseId,
  getSessionsByThemeId,
  getUniqueCourseFromSessions,
  getSessionLabelSelector,
} from 'modules/session/session.utils'

import travailLaptop from '../assets/travail-laptop.svg'
import VirtualClassroomsTooltip from './VirtualClassroomsTooltip'

const Icon = styled(StreamlineIcon)`
  font-size: 24px;
  line-height: 24px;
  margin-right: 10px;
`

const ThemeLabel = styled.span`
  font-size: 11px;
  font-weight: 500;
  color: ${({ color }) => color};
`

const StyledList = styled(List)`
  > * {
    box-sizing: border-box;
    white-space: initial;
  }

  > :not(:first-child) {
    padding-left: 21px;
  }

  > :not(:last-child) {
    padding-right: 21px;
  }
`

const StyledBox = styled(Box)``

const Welcome = ({ user, intl, location: { search } }) => {
  const [loading, setLoading] = useState(true)
  const [sessions, setSessions] = useState([])

  useEffect(() => {
    // Fetch
    getAvailableSessionsToRegister()
      .then(resultat => {
        const sessionsArray = _.get(resultat, 'data.data.sessions.items')

        sessionsArray.sort((a, b) => new Date(a.startAt) - new Date(b.startAt))

        setSessions(sessionsArray)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])

  let dataSessions = [...sessions]

  const query = qs.parse(search)

  if (query.courseId) {
    dataSessions = getSessionsByCourseId(dataSessions, query.courseId)
  }

  if (query.month) {
    dataSessions = getSessionsByMonth(dataSessions, Number(query.month))
  }

  if (query.themeId) {
    dataSessions = getSessionsByThemeId(dataSessions, query.themeId)
  }

  const uniqueCourses = getUniqueCourseFromSessions(sessions)
  const uniqueTheme = getUniqueThemeFromCourses(uniqueCourses)

  return (
    <>
      <Visible xs sm>
        <Redirect to="/espace-superviseur/sessions/en-cours" />
      </Visible>

      <Flex flexDirection="column">
        <Card>
          <Flex>
            <Box width={2 / 3} padding={['20px', '40px']} pr={['0px', '0px']}>
              <Flex justifyContent="space-between" sx={{ position: 'relative' }}>
                <Box width={2 / 3}>
                  <Box>
                    <h2>
                      <FormattedHTMLMessage id="supervisor.welcome.titles.welcome" values={{ name: user.firstName }} />
                    </h2>
                    <Box py={['15px', '20px']}>
                      <p>
                        <FormattedHTMLMessage
                          id="supervisor.welcome.messages.colleague"
                          values={{ name: user.firstName }}
                        />
                      </p>
                    </Box>
                  </Box>
                  <a href={getSupervisionResultsUrl()} target="_blank" rel="noopener noreferrer">
                    <ButtonI18n i18nKey="supervisor.welcome.buttons.download" variant="secondary" />
                  </a>
                </Box>
                <Box width={1 / 3} margin="auto" textAlign="center" maxHeight="182px">
                  <Image zIndex={1} height="auto" width="auto" maxHeight="inherit" src={travailLaptop} />
                </Box>
              </Flex>
            </Box>
            <Box
              width={1 / 3}
              padding={['20px', '40px']}
              sx={{
                position: 'relative',
                backgroundColor: 'whiteLilac',
              }}
            >
              <p>
                <FormattedHTMLMessage id="supervisor.welcome.messages.independent" />
              </p>
              <Link to="/espace-superviseur/inscrits/session">
                <ButtonI18n
                  mt={['28px']}
                  i18nKey="supervisor.welcome.buttons.add_colleague"
                  variant="primary"
                  data-tracking-id="session.register_participants"
                />
              </Link>
            </Box>
          </Flex>
        </Card>

        <Box mt={['50px']}>
          <Box>
            <Flex justifyContent="space-between" alignItems="baseline">
              <h3>
                <FormattedHTMLMessage
                  id="supervisor.welcome.titles.current_licence"
                  values={{ name: user.firstName }}
                />
              </h3>
            </Flex>
          </Box>
          <Box overflowX="auto" overflowY="hidden">
            {/* padding on the list because of overflowY="hidden" */}
            <StyledList pb="50px" pt="30px">
              <Supervisions userId={user.id} />
            </StyledList>
          </Box>
        </Box>
        <Box>
          <Box mb={['30px']}>
            <Flex justifyContent="space-between" alignItems="baseline">
              <h3>
                <FormattedMessage id="supervisor.welcome.titles.add_collaborator" />
              </h3>
              <a href={getCoursesPlanning()} target="_blank" rel="noopener noreferrer">
                <Icon icon="eye" />
                <FormattedHTMLMessage id="supervisor.welcome.links.see_sessions_planning" />
              </a>
            </Flex>
          </Box>
          <StyledBox>
            <DataGridToolbar
              withSearch={false}
              withCounter={false}
              renderOnlyFilter
              defaultOpen
              renderFilters={() => (
                <Flex backgroundColor="white" p="24px" width="100%" sx={{ borderRadius: '4px' }}>
                  <Box width={1 / 3}>
                    <QueryString name="courseId">
                      <Select
                        isLoading={loading}
                        options={sortByTitle(uniqueCourses).map(course => ({
                          value: course.id,
                          label: getSelectorTitle(course),
                        }))}
                        placeholder={intl.formatMessage({ id: 'select.course.placeholder' })}
                        noOptionsMessage={() => intl.formatMessage({ id: 'select.default_no_result' })}
                        isClearable
                        isSearchable
                      />
                    </QueryString>
                  </Box>
                  <Box width={1 / 3} ml="20px">
                    <QueryString name="month">
                      <SelectMonth
                        clearable
                        searchable
                        placeholder={<FormattedMessage id="select.month.placeholder" />}
                      />
                    </QueryString>
                  </Box>
                  <Box width={1 / 3} ml="20px">
                    <QueryString name="themeId">
                      <Select
                        isLoading={loading}
                        options={sortByLabel(uniqueTheme).map(label => ({
                          value: label.id,
                          label: label.label,
                        }))}
                        placeholder={intl.formatMessage({ id: 'select.theme.placeholder' })}
                        noOptionsMessage={() => intl.formatMessage({ id: 'select.default_no_result' })}
                        isClearable
                      />
                    </QueryString>
                  </Box>
                </Flex>
              )}
            />

            <DataGrid data={dataSessions.slice(0, 100)}>
              <DataGridCell
                label={<FormattedMessage id="course.fields.title" />}
                property="course.title"
                render={(title, data) => (
                  <a
                    href={getPublicProductFile(_.get(data, 'data.course.identifier'))}
                    target="_blank"
                    without
                    rel="noopener noreferrer"
                  >
                    {getSelectorTitle(_.get(data, 'data.course'))}
                  </a>
                )}
              />
              <DataGridCell
                label={<FormattedMessage id="session.fields.startAt" />}
                property="startAt"
                render={resultCell => moment(resultCell).format('DD/MM/YYYY')}
              />
              <DataGridCellDate label={<FormattedMessage id="session.fields.endAt" />} property="endAt" />
              <DataGridCell
                label={<FormattedMessage id="theme.fields.label" />}
                property="course.mainTheme.label"
                render={(label, data) => (
                  <ThemeLabel color={_.get(data, 'data.course.mainTheme.color')}>{_.toUpper(label)}</ThemeLabel>
                )}
              />
              <DataGridCell
                label={<FormattedMessage id="supervisor.welcome.data_grid_cols.virtual_classrooms" />}
                align="center"
                render={session => {
                  if (!session.hasVirtualClassroom) {
                    return null
                  }
                  return <VirtualClassroomsTooltip sessionId={session.id} sessions={sessions} />
                }}
              />

              <DataGridCell
                label={<FormattedMessage id="course.fields.registration" />}
                render={session => {
                  const queryString = qs.stringify({
                    courseId: session.course.id,
                    courseTitle: session.course.title,
                    sessionId: session.id,
                    sessionTitle: getSessionLabelSelector(session),
                  })

                  return (
                    <Link
                      to={{
                        pathname: I18nProvider.getLinkRoute('/supervisor/registered_users/session'),
                        search: queryString,
                      }}
                    >
                      <ButtonI18n
                        size="small"
                        i18nKey="supervisor.welcome.buttons.add_colleague"
                        data-tracking-id="session.register_participants_with_session"
                        data-tracking-values={JSON.stringify({
                          courseIdentifier: session.course.identifier,
                          sessionIdentifier: session.identifier,
                        })}
                      />
                    </Link>
                  )
                }}
              />
            </DataGrid>
          </StyledBox>
        </Box>
      </Flex>
    </>
  )
}

Welcome.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  user: PropTypes.shape().isRequired,
  location: PropTypes.shape({ search: PropTypes.string.isRequired }).isRequired,
}

export default injectIntl(withRouter(withUser(Welcome)))
