export const STATUS_DONE = 'done'
export const STATUS_NEXT = 'next'
export const STATUS_LIVE = 'live'
export const STATUS_PLANNED = 'planned'

export const SEQUENCE_ORIGINS = {
  onboarding: 'onboarding',
  page: 'virtual-classroom-page',
  block: 'virtual-classroom-block',
  tab: 'virtual-classroom-tab',
}
