import I18nProvider from '@unowmooc/i18n'

export const retrieveCurrentQuestions = ({ questions, currentPage = 1, formValues = {}, clearValue }) => {
  const computedInfo = Object.keys(questions).reduce(
    (acc, questionId) => {
      const question = questions[questionId]

      // On ne prends pas en compte la question si elle sur une des pages suivantes
      if (question.page_number > currentPage + 1) {
        return acc
      }

      const nextAcc = { ...acc }

      // Gestion du cas de la question conditionnelle
      if (question.conditionToDisplay) {
        const isDisplayable = question.conditionToDisplay.some(condition => {
          const questionName = `page_${question.page_number}_question_${condition.questionId}`
          if (!formValues[questionName]) {
            return false
          }

          const valuesToCompare = Array.isArray(formValues[questionName])
            ? formValues[questionName]
            : [formValues[questionName]]

          return valuesToCompare.some(({ id }) => condition.choiceId === id)
        })

        if (isDisplayable) {
          nextAcc.questionCounter += 1
        } else {
          // Permet de s'assurer que les réponse du champ sont bien effacées dans le formulaire car il n'est plus affiché
          clearValue(`page_${currentPage}_question_${question.id}`, undefined)

          return nextAcc
        }
      } else {
        nextAcc.questionCounter += 1
      }

      if (question.page_number === currentPage) {
        nextAcc.currentQuestions.push({
          ...question,
          title: `${nextAcc.questionCounter} - ${question.title}${
            question.type === 'multiple_choice'
              ? I18nProvider.formatMessage({ id: 'survey.multiple_choice_information' })
              : ''
          }`,
        })
      }

      return nextAcc
    },
    {
      questionCounter: 0,
      currentQuestions: [],
    },
  )

  return computedInfo.currentQuestions
}
