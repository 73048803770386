import React from 'react'
import { FormattedHTMLMessage } from 'react-intl'
import { useCourseContext } from 'modules/course/components/CourseContext/CourseContext'
import gql from 'graphql-tag'
import PageQuery from 'components/PageQuery'
import { toInlineFragment } from 'fraql'
import { propType } from 'graphql-anywhere'
import Sequence from 'components/VirtualClassroom/Sequence'
import { SequenceProvider } from 'components/VirtualClassroom/Sequence/SequenceContext'
import { SEQUENCE_ORIGINS } from 'components/VirtualClassroom/Sequence/constantes'
import SingleSlot from './SingleSlot'
import MultiSlot from './MultiSlot'

const QUERY_FRAGMENT = gql`
  fragment _ on Session {
      id
      virtualClassroomsBySequence {
        ${toInlineFragment(Sequence.fragments.sequence)}
      }
  }
`

const QUERY = gql`
  query VirtualClassroomSlideContentQuery($id: ID!) {
    session(id: $id) {
        ${toInlineFragment(QUERY_FRAGMENT)}
    }
  }
`

const VirtualClassroom = ({ session: { virtualClassroomsBySequence } }) => (
  <>
    <p>
      <FormattedHTMLMessage id="guide.slides.virtualClassroom.content.paragraph_1" />
    </p>
    {virtualClassroomsBySequence.length > 0 ? (
      <p>
        <FormattedHTMLMessage id="guide.slides.virtualClassroom.content.paragraph_2" />
      </p>
    ) : (
      <p>
        <FormattedHTMLMessage id="guide.slides.virtualClassroom.content.paragraph_2_no_cv" />
      </p>
    )}
    {virtualClassroomsBySequence.map(sequence => (
      <SequenceProvider key={sequence.sequenceNumber} sequence={sequence} origin={SEQUENCE_ORIGINS.onboarding}>
        {sequence.virtualClassrooms.length === 1 ? <SingleSlot /> : <MultiSlot />}
      </SequenceProvider>
    ))}
  </>
)

VirtualClassroom.propTypes = {
  session: propType(QUERY_FRAGMENT).isRequired,
}

export default props => {
  const { sessionId } = useCourseContext()

  return (
    <PageQuery
      query={QUERY}
      fetchPolicy="no-cache"
      variables={{ id: sessionId }}
      render={renderProps => <VirtualClassroom {...renderProps} {...props} />}
    />
  )
}
