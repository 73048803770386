import { useEffect } from 'react'
import ReactGA from 'react-ga4'

import config from 'config'

const Analytics = ({ location: { pathname, search } }) => {
  useEffect(() => {
    if (config.env.GA_TAG) {
      ReactGA.send({ hitType: 'pageview', page: pathname + search })
    }
  }, [pathname, search])

  return null
}

export default Analytics
