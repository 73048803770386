import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import StreamlineIcon from '@unowmooc/icons'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: ${({ theme: { colors } }) => colors.shipGray};

  &::before {
    content: '';
    position: absolute;
    top: 12px;
    left: -37px;
    width: 26px;
    height: 26px;
    background-color: ${({ theme: { colors } }) => colors.white};
    border-radius: 50%;
  }
`

const StarIconWrapper = styled.div`
  position: absolute;
  top: 0;
  left: -48px;
`

const StarIcon = styled(StreamlineIcon)`
  position: absolute;
  top: 16px;
  left: 17px;
  font-size: 14px;
  color: ${({ theme: { colors } }) => colors.unowOrange};
`

const DisabledOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme: { colors } }) => colors.white};
  opacity: 0.5;
`

const RightIcon = styled(StreamlineIcon)`
  font-size: 26px;
  line-height: 16px;
  color: ${({ theme: { colors } }) => colors.unowOrange};
`

const StarItem = ({ title, disabled }) => (
  <Wrapper>
    <StarIconWrapper>
      <StarIcon icon="star-full" />
    </StarIconWrapper>

    {disabled && <DisabledOverlay />}

    <span>{title}</span>
    <RightIcon icon="right" />
  </Wrapper>
)

StarItem.defaultProps = {
  disabled: false,
}

StarItem.propTypes = {
  title: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
}

StarItem.displayName = 'StarItem'

export default StarItem
