import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { propType } from 'graphql-anywhere'
import { toInlineFragment } from 'fraql'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'
import Participation from './Participation'

const Title = styled.p`
  margin-bottom: 20px;
  font-size: 16px;
  color: ${({ theme: { colors } }) => colors.midGray};
`

const StyledParticipation = styled(Participation)`
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`

const Participations = ({ participations, className }) => (
  <div className={className}>
    <Title>
      <FormattedMessage id="profile.participations.title" />
    </Title>
    {participations.map(participation => (
      <StyledParticipation participation={participation} key={participation.id} />
    ))}
  </div>
)

Participations.fragments = {
  participation: gql`
    fragment _ on Participant {
      id
      ${toInlineFragment(Participation.fragments.participation)}
    }
  `,
}

Participations.defaultProps = {
  className: undefined,
}

Participations.propTypes = {
  participations: PropTypes.arrayOf(propType(Participations.fragments.participation)).isRequired,
  className: PropTypes.string,
}

export default Participations
