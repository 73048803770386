import React, { Component } from 'react'
import PropTypes from 'prop-types'
import I18nProvider from '@unowmooc/i18n'
import { FormattedHTMLMessage } from 'react-intl'
import { Link as LinkRoute, generatePath } from 'react-router-dom'
import { Tab } from '@unowmooc/react-ui-kit'
import s from 'underscore.string'
import styled from '@emotion/styled'

const StyledLinkRoute = styled(LinkRoute)`
  margin-right: 22px;

  &:last-child {
    margin-right: 0;
  }
`

class Link extends Component {
  constructor(props) {
    super(props)

    const { match, pageView } = props

    if (!match || !pageView) {
      throw new Error('Do not use PageViewLink outside PageView component')
    }
  }

  render() {
    const {
      name,
      pageView,
      match: { path, params },
      location: { pathname },
    } = this.props
    const linkRoute = `${generatePath(path, params)}${I18nProvider.getLinkRoute(`/${name}`)}`
    const activeLink = new RegExp(`^${linkRoute}`).test(pathname)

    return (
      <StyledLinkRoute key={name} to={linkRoute}>
        <Tab active={activeLink}>
          <FormattedHTMLMessage id={`menu.${pageView}.${s.replaceAll(name, '-', '_')}`} />
        </Tab>
      </StyledLinkRoute>
    )
  }
}

Link.isPageViewLayout = true

Link.defaultProps = {
  match: undefined,
  location: undefined,
  pageView: undefined,
}

Link.propTypes = {
  match: PropTypes.shape(),
  location: PropTypes.shape(),
  pageView: PropTypes.string,
  component: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
  name: PropTypes.string.isRequired,
}

export default Link
