import Cookies from 'js-cookie'
import { AUTH_TOKEN_KEY } from '@unowmooc/utils/constants'
import config from 'config'

// 4 heures
const AUTH_COOKIE_DURATION_IN_SECONDS = 14400

const DEBOUNCE_DURATION_IN_MILLISECONDS = 1000 * 60 * 5

let cookieCreationDate = 0

/**
 * Cette méthode reporte la durée de validation du cookie
 *
 * @param {string} token
 */
export const handleAuthCookie = token => {
  const millisecondsElapsedFromTheCookieCreation = Date.now() - cookieCreationDate

  if (millisecondsElapsedFromTheCookieCreation < DEBOUNCE_DURATION_IN_MILLISECONDS) {
    return
  }

  const options = {
    domain: config.env.COOKIE_DOMAIN,
    expires: AUTH_COOKIE_DURATION_IN_SECONDS / 60 / 60 / 24,
  }

  // Impossible de set une nouvelle valeur à une clée existante donc on supprime la key/value
  Cookies.remove(AUTH_TOKEN_KEY, options)
  Cookies.set(AUTH_TOKEN_KEY, token, options)
  cookieCreationDate = Date.now()
}
