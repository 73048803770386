import React from 'react'
import PropTypes from 'prop-types'
import { rgba } from '@unowmooc/themes'
import styled from '@emotion/styled'

const Title = styled.span`
  color: ${({ theme: { colors } }) => colors.shipGray};
`

const Icon = styled.span`
  height: 100%;
  margin-right: 10px;
  font-size: 24px;
  line-height: 14px;
  color: ${({ theme: { colors } }) => colors.bombay};
  transition: ${({ theme: { transitions } }) => transitions.color};
`

const Duration = styled.div`
  margin-top: 5px;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme: { colors } }) => colors.manatee};
`

const Wrapper = styled.div`
  position: relative;
  padding: 10px 20px;
  display: flex;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme: { colors } }) => colors.midGray};
  background-color: ${({ active, theme: { colors } }) => active && rgba(colors.unowGreen, 0.15)};
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ disabled, active, theme: { colors } }) => !disabled && !active && colors.whiteLilac};

    ${Icon} {
      color: ${({ disabled, theme: { colors } }) => (disabled ? colors.bombay : colors.manatee)};
    }
  }
`

const SubItem = ({ icon, title, duration, active, disabled }) => (
  <Wrapper active={active} disabled={disabled}>
    {icon && <Icon>{icon}</Icon>}

    <Title>
      {title}
      {duration && <Duration>{duration}</Duration>}
    </Title>
  </Wrapper>
)

SubItem.defaultProps = {
  icon: undefined,
  duration: undefined,
  active: false,
  disabled: false,
}

SubItem.propTypes = {
  title: PropTypes.node.isRequired,
  duration: PropTypes.node,
  icon: PropTypes.node,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default SubItem
