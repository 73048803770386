import React from 'react'
import PropTypes from 'prop-types'
import { propType, filter } from 'graphql-anywhere'
import { toInlineFragment } from 'fraql'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'
import StreamlineIcon from '@unowmooc/icons'
import gql from 'graphql-tag'
import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl'
import { Can } from 'store/AbilityProvider'
import EditLink from './EditLink'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;

  ${mq.sm(css`
    flex-direction: column-reverse;
  `)};
`

const Content = styled.h1`
  display: flex;
  margin: 0;
`

const StarIcon = styled(StreamlineIcon)`
  margin-right: 10px;
  font-size: 30px;
  color: ${({ theme: { colors } }) => colors.unowOrange};
`

const StyledEditLink = styled(EditLink)`
  margin-left: 15px;

  ${mq.sm(css`
    margin-left: 0;
    margin-bottom: 4px;
  `)};
`

const Title = ({ course, className }) => (
  <Wrapper className={className}>
    <Content>
      <StarIcon icon="star-full" />
      <span>
        <FormattedMessage id="final_exam.title" />
      </span>
    </Content>

    <Can I="admin-edit" of="final-exam">
      <StyledEditLink course={filter(EditLink.fragments.course, course)} />
    </Can>
  </Wrapper>
)

Title.fragments = {
  course: gql`
    fragment _ on Course {
      ${toInlineFragment(EditLink.fragments.course)}
    }
  `,
}

Title.defaultProps = {
  className: undefined,
}

Title.propTypes = {
  course: propType(Title.fragments.course).isRequired,
  className: PropTypes.string,
}

export default Title
