import React, { forwardRef } from 'react'
import styled from '@emotion/styled'
import { colors } from '@unowmooc/themes'

const CirclePath = styled.path`
  fill: ${colors.mischka};
  transition: fill 0.3s ease;
`

const Svg = styled.svg`
  &:hover {
    ${CirclePath} {
      fill: ${colors.bombay};
    }
  }
`

const InformationIcon = forwardRef((props, ref) => (
  <Svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" {...props} ref={ref}>
    <g fill="none" fillRule="evenodd">
      <path d="M-1-1h18v18H-1z" />
      <CirclePath
        d="M8 15.5C3.864 15.5.5 12.136.5 8 .5 3.865 3.865.5 8 .5c4.136 0 7.5 3.365 7.5 7.5 0 4.136-3.364 7.5-7.5 7.5zm.75-5.25v-3A.75.75 0 0 0 8 6.5H6.5V8h.75v2.25h-1.5v1.5h4.5v-1.5h-1.5zM8 5.937a.938.938 0 1 0 0-1.875.938.938 0 0 0 0 1.875z"
        fillRule="nonzero"
      />
    </g>
  </Svg>
))

export default InformationIcon
