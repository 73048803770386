import React from 'react'
import PropTypes from 'prop-types'
import { propType, filter } from 'graphql-anywhere'
import { toInlineFragment } from 'fraql'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'
import gql from 'graphql-tag'
import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl'
import { Can } from 'store/AbilityProvider'
import EditLink from './EditLink'
import useIsMobile from '../../../../../../hooks/useIsMobile'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const NumberTitle = styled.p`
  display: flex;
  font-size: 14px;
`

const Number = styled.span`
  padding-right: 4px;
  white-space: nowrap;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: ${({ theme: { colors } }) => colors.shipGray};

  ${mq.sm(css`
    display: block;
  `)};
`

const Title = styled.span`
  color: ${({ theme: { colors } }) => colors.midGray};
`

const StyledEditLink = styled(EditLink)`
  margin-left: 15px;
`

const ModuleTitle = ({ module, module: { position, title }, className }) => {
  const isMobile = useIsMobile()

  return (
    <Wrapper className={className}>
      {isMobile ? (
        <NumberTitle>
          <Number>
            <FormattedMessage id="sections.module_title" values={{ number: position + 1 }} />
          </Number>
          <Title>{title}</Title>
        </NumberTitle>
      ) : (
        <div />
      )}

      <Can I="admin-edit" of="module-quiz">
        <StyledEditLink module={filter(EditLink.fragments.module, module)} />
      </Can>
    </Wrapper>
  )
}

ModuleTitle.fragments = {
  module: gql`
    fragment _ on Module {
      id
      position
      title
      ${toInlineFragment(EditLink.fragments.module)}
    }
  `,
}

ModuleTitle.defaultProps = {
  className: undefined,
}

ModuleTitle.propTypes = {
  module: propType(ModuleTitle.fragments.module).isRequired,
  className: PropTypes.string,
}

export default ModuleTitle
