import React, { Component } from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { propType } from 'graphql-anywhere'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { Row, Col, Visible } from 'react-grid-system'
import ShadowBox from '@unowmooc/shadow-box'
import { colors, rgba, mq } from 'styles-resources'
import { isStarted as isSessionStarted } from 'utils/date'
import BoxCounter from 'components/BoxCounter'
import InlineCounter from 'components/InlineCounter'
import svgCertified from './assets/certified.svg'
import svgCheckCircle from './assets/check-circle.svg'
import svgActive from './assets/active.svg'
import svgWaiting from './assets/waiting.svg'
import svgHourglass from './assets/hourglass.svg'

const ParticipantCounterCol = styled(Col)`
  ${mq.sm(css`
    margin-bottom: 20px;
  `)}
`

const MultiCountersCol = styled(Col)`
  ${mq.lg(css`
    margin-top: 30px;
  `)}

  ${mq.sm(css`
    margin-top: 20px;
  `)}
`

const MultiCounters = styled(ShadowBox)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 42px;
  background-color: ${colors.white};

  ${mq.lg(css`
    height: auto;
    padding-top: 40px;
    padding-bottom: 40px;
  `)}

  ${mq.sm(css`
    flex-flow: wrap;
    height: auto;
    padding: 30px;
    padding-bottom: 10px;
  `)}
`

class InProgressCounters extends Component {
  get showCertified() {
    const {
      session: { nbCertifiedParticipants },
    } = this.props

    return nbCertifiedParticipants > 0
  }

  get satisfactionBoxCounter() {
    const {
      session: { nbParticipants, satisfactionRate },
    } = this.props

    return (
      <BoxCounter
        value={
          <>
            {satisfactionRate === null ? (
              '- '
            ) : (
              <FormattedNumber value={satisfactionRate * 10} maximumFractionDigits={1} />
            )}
            / 10
          </>
        }
        label={<FormattedMessage id="session.satisfaction" values={{ nbParticipants }} />}
      />
    )
  }

  render() {
    const {
      session: {
        startAt,
        nbParticipants,
        nbCertifiedParticipants,
        nbUpToDateParticipants,
        nbActiveParticipants,
        nbWaitingParticipants,
        nbRegisteredParticipants,
        nbLateParticipants,
      },
      className,
    } = this.props

    const { showCertified, satisfactionBoxCounter } = this
    const isStarted = isSessionStarted(startAt)

    return (
      <Row className={className}>
        <ParticipantCounterCol xs={12} sm={6} xl={2}>
          <BoxCounter
            value={nbParticipants}
            label={<FormattedMessage id="session.nb_participants_label" values={{ nbParticipants }} />}
          />
        </ParticipantCounterCol>
        <Visible xs sm md lg>
          <Col xs={12} sm={6} xl={2}>
            {satisfactionBoxCounter}
          </Col>
        </Visible>
        <MultiCountersCol xs={12} xl={8}>
          <MultiCounters>
            {showCertified && (
              <InlineCounter
                background={colors.unowOrange}
                svgSrc={svgCertified}
                tooltipContent={<FormattedMessage id="session.nb_certified_help" />}
              >
                <FormattedMessage id="session.nb_certified" values={{ nbCertifiedParticipants }} />
              </InlineCounter>
            )}
            {isStarted && (
              <InlineCounter
                background={rgba(colors.unowGreen, 0.2)}
                svgSrc={svgActive}
                tooltipContent={<FormattedMessage id="session.nb_active_help" />}
              >
                <FormattedMessage id="session.nb_active" values={{ nbActiveParticipants }} />
              </InlineCounter>
            )}
            <InlineCounter
              background={colors.whiteLilac}
              svgSrc={svgWaiting}
              tooltipContent={<FormattedMessage id="session.nb_waiting_help" />}
            >
              <FormattedMessage id="session.nb_waiting" values={{ nbWaitingParticipants }} />
            </InlineCounter>
            {!isStarted && (
              <InlineCounter
                background={rgba(colors.unowGreen, 0.2)}
                svgSrc={svgActive}
                tooltipContent={<FormattedMessage id="session.nb_registered_help" />}
              >
                <FormattedMessage id="session.nb_registered" values={{ nbRegisteredParticipants }} />
              </InlineCounter>
            )}
            {isStarted && !showCertified && (
              <InlineCounter
                background={rgba(colors.unowGreen)}
                svgSrc={svgCheckCircle}
                tooltipContent={<FormattedMessage id="session.nb_up_to_date_help" />}
              >
                <FormattedMessage id="session.nb_up_to_date" values={{ nbUpToDateParticipants }} />
              </InlineCounter>
            )}
            {isStarted && (
              <InlineCounter
                background={colors.perfume}
                svgSrc={svgHourglass}
                tooltipContent={<FormattedMessage id="session.nb_late_help" />}
              >
                <FormattedMessage id="session.nb_late" values={{ nbLateParticipants }} />
              </InlineCounter>
            )}
          </MultiCounters>
        </MultiCountersCol>
        <Visible xl>
          <Col md={2} xl={2}>
            {satisfactionBoxCounter}
          </Col>
        </Visible>
      </Row>
    )
  }
}

InProgressCounters.fragment = gql`
  fragment InProgressCountersFragment on Session {
    id
    startAt
    finalExamStartAt
    nbParticipants
    nbCertifiedParticipants
    nbUpToDateParticipants
    nbActiveParticipants
    nbWaitingParticipants
    nbRegisteredParticipants
    nbLateParticipants
    satisfactionRate
  }
`

InProgressCounters.defaultProps = {
  className: undefined,
}

InProgressCounters.propTypes = {
  session: propType(InProgressCounters.fragment).isRequired,
  className: PropTypes.string,
}

export default InProgressCounters
