import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { filter } from 'graphql-anywhere'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'
import { withUser } from 'store/UserProvider'
import PageViewLayout, { PageViewLayoutLink } from 'components/PageViewLayout'
import PageQuery from 'components/PageQuery'
import Helmet from 'components/HelmetIntl'
import Header from './Header'
import ParticipantsPage from './participants'
import Documents from './documents'

const StyledHeader = styled(Header)`
  margin-bottom: 50px;

  ${mq.sm(css`
    margin-top: 20px;
  `)}
`

const ViewPage = ({
  match: {
    params: { idSession },
  },
  user: { id: userId },
}) => (
  <PageQuery
    query={gql`
      query SessionView($sessionId: ID!, $supervisorId: ID!) {
        supervisedSessionStats(sessionId: $sessionId, supervisorId: $supervisorId) {
          ...SupervisedSessionHeader
          session {
            id
            course {
              id
              title
            }
          }
          ...DocumentsFragment
        }
      }
      ${Header.fragment}
      ${Documents.fragment}
    `}
    variables={{ sessionId: idSession, supervisorId: userId }}
    render={({ supervisedSessionStats }) => (
      <>
        <Helmet
          title="page_titles.supervisor.session.title"
          values={{ courseTitle: supervisedSessionStats.session.course.title }}
        />
        <StyledHeader supervisedSessionStats={supervisedSessionStats} />

        <PageViewLayout pageView="supervisor.session_view" routeIndex="participants">
          <PageViewLayoutLink
            name="participants"
            component={ParticipantsPage}
            supervisedSessionStats={supervisedSessionStats}
          />
          <PageViewLayoutLink
            name="documents"
            component={Documents}
            supervisedSessionStats={filter(Documents.fragment, supervisedSessionStats)}
          />
        </PageViewLayout>
      </>
    )}
  />
)

ViewPage.propTypes = {
  match: PropTypes.shape().isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
}

export default withUser(ViewPage)
