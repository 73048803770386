import moment from 'moment'

export function isNight() {
  const hour = parseInt(moment().format('HH'), 10)

  return hour >= 18 || hour < 6
}

export const isStarted = date => {
  if (!date) {
    return false
  }

  return moment(date) <= moment()
}

/**
 * Allow react-intl format as follow
 * @example
 * // returns 01/09/20
 * intl.formatDate(new Date, numericFormat)
 */
export const numericFormat = {
  year: '2-digit',
  month: 'numeric',
  day: 'numeric',
}

/**
 * Returns current timestamp in seconds
 *
 * @returns {number}
 */
export function now() {
  return Math.floor(Date.now() / 1000)
}

export default { isNight }
