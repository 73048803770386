import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Visible } from 'react-grid-system'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'
import I18nProvider from '@unowmooc/i18n'
import { Tabs as UITabs } from '@unowmooc/react-ui-kit'
import { colors } from 'styles-resources'
import { useTracking } from 'modules/tracking'

const MobileNav = styled.nav`
  display: flex;
  height: 50px;
  background-color: ${colors.white};
  border-top: 1px solid ${colors.whisper};
`

const MobileTab = styled(Link, { shouldForwardProp: prop => prop !== 'active' })`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  border-right: 1px solid ${colors.whisper};
  color: ${({ active }) => (active ? colors.unowGreen : colors.shipGray)};
  font-size: 24px;

  &:hover {
    text-decoration: none;
  }

  &::after {
    content: '';
    width: 100%;
    height: 4px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: ${({ active }) => (active ? colors.unowGreen : colors.mischka)};
  }

  &:last-child {
    border-right: 0;
  }
`

class Tabs extends Component {
  get tabs() {
    const { messageBaseKey, items } = this.props

    return items
      .filter(item => item.canDisplay ?? true)
      .map(item => ({
        id: item.name,
        name: item.label || I18nProvider.formatMessage({ id: `${messageBaseKey}.${item.name}` }),
        icon: item.icon,
        desktopOnly: item.desktopOnly || false,
      }))
  }

  get active() {
    const { pathname, items } = this.props
    const { tabs } = this

    const activePath = pathname.split('/').slice(-1)[0]

    const currentTab = items.find(item => I18nProvider.formatMessage({ id: `routes.${item.name}` }) === activePath)

    return currentTab ? tabs.find(tab => tab.id === currentTab.name) : false
  }

  render() {
    const { messageBaseKey, className, forceDesktop, courseIdentifier, sessionIdentifier } = this.props
    const { tabs, active } = this
    const { sendEvent } = useTracking()

    return (
      <>
        <Visible xs={!forceDesktop} sm={!forceDesktop}>
          <MobileNav className={className}>
            {tabs
              .filter(tab => !tab.desktopOnly)
              .map(tab => (
                <MobileTab
                  to={I18nProvider.formatMessage({ id: `routes.${tab.id}` })}
                  active={active ? active.id === tab.id : false}
                  key={tab.id}
                >
                  {tab.icon}
                </MobileTab>
              ))}
          </MobileNav>
        </Visible>
        <Visible xs={forceDesktop} sm={forceDesktop} md lg xl>
          <UITabs
            forceDesktop={forceDesktop}
            responsive={false}
            title={<FormattedMessage id={`${messageBaseKey}.title`} />}
            tabs={tabs}
            active={active}
            tabComponent={Link}
            tabProps={tab => ({
              'data-cy': `page-tab-${tab.id}`,
              to: I18nProvider.formatMessage({ id: `routes.${tab.id}` }),
              onClick(tabInfos) {
                sendEvent(
                  'session.click_on_program_page',
                  JSON.stringify({
                    courseIdentifier,
                    sessionIdentifier,
                    currentTab: active.id,
                    clickedTab: tabInfos.id,
                  }),
                )
              },
            })}
            className={className}
          />
        </Visible>
      </>
    )
  }
}

Tabs.defaultProps = {
  className: undefined,
  forceDesktop: false,
  courseIdentifier: null,
  sessionIdentifier: null,
}

Tabs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.node,
      icon: PropTypes.node,
    }),
  ).isRequired,
  pathname: PropTypes.string.isRequired,
  messageBaseKey: PropTypes.string.isRequired,
  forceDesktop: PropTypes.bool,
  className: PropTypes.string,
  courseIdentifier: PropTypes.string,
  sessionIdentifier: PropTypes.string,
}

export default Tabs
