import React from 'react'
import { FormattedTime as IntlFormattedTime } from 'react-intl'
import moment from 'moment' // workaround for Safari/IE11 date format support

const FormattedTime = ({ value, ...props }) => <IntlFormattedTime value={moment(value)} {...props} />

FormattedTime.propTypes = {
  ...IntlFormattedTime.defaultProps,
}

FormattedTime.propTypes = {
  ...IntlFormattedTime.propTypes,
}

export default FormattedTime
