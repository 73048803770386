import _ from 'lodash'

export const hasSeenSection = (section, progressions) => _.some(progressions, { section: { id: section.id } })
export const hasBadgeForModule = (module, badges) => _.some(badges, { module: { id: module.id } })

export const getProgressionPercentByModule = (module, progressions, badges) => {
  const nbSections = module.sections.length + (module.hasQuiz ? 1 : 0)
  const nbProgressionsOfModule =
    _.filter(progressions, progression => _.some(module.sections, { id: progression.section.id })).length +
    (hasBadgeForModule(module, badges) ? 1 : 0)

  return (nbProgressionsOfModule / nbSections) * 100
}

/**
 * https://app.tettra.co/teams/unowmooc/pages/comment-marche-le-bouton-continuer
 *
 * Cette fonction suit les règles susdit.
 *
 * @param course
 * @param progressions
 * @param badges
 * @param isAdmin
 * @param hasAccessModuleZero
 */
export const getUserProgression = (course, progressions = [], badges = [], isAdmin, hasAccessModuleZero = true) => {
  const moduleZero = hasAccessModuleZero ? _.get(course, 'moduleZero') : null
  const flattenSections = _.sortBy(
    // flatten sections
    _.reduce(
      moduleZero
        ? [{ ...moduleZero, publicPosition: -1 }, ..._.get(course, 'modules', [])]
        : _.get(course, 'modules', []),
      (acc, { id, sections, publicPosition, status, bonus }) => {
        if (status === 'publish' || isAdmin) {
          // add position modulePosition * 100
          _.forEach(sections, (section, index) => {
            if (section.status === 'publish' || isAdmin) {
              acc.push({
                lastSection: sections.length === index + 1,
                sectionId: section.id,
                moduleZero: publicPosition === -1,
                moduleBonus: bonus,
                position: section.position + publicPosition * 100,
                moduleId: id,
              })
            }
          })
        }

        return acc
      },
      [],
    ),
    'position',
  )

  const lastMoreAdvancedSection = _.find(_.reverse(flattenSections), ({ sectionId }) =>
    _.find(progressions, progression => _.get(progression, 'section.id') === sectionId),
  )

  // Restore the list asc (0, 1, 2 ..etc)
  _.reverse(flattenSections)

  if (!lastMoreAdvancedSection) {
    // Aucune progression
    // On revoit la premiere section
    return _.pick(_.get(flattenSections, '0', {}), [
      'sectionId',
      'moduleId',
      'moduleBonus',
      'lastSection',
      'moduleZero',
    ])
  }

  if (!lastMoreAdvancedSection.lastSection) {
    // Si on a une progression et que ce n'est pas la derniere section d'un module
    // On revoit la section
    return _.pick(lastMoreAdvancedSection, ['sectionId', 'moduleId', 'moduleBonus', 'lastSection', 'moduleZero'])
  }

  // Get the badge of the lastMoreAdvancedSection
  const badge = _.find(badges, ({ module: moduleItem }) => _.get(moduleItem, 'id') === lastMoreAdvancedSection.moduleId)

  // Si on a pas de badge sur la section
  // et que ce module n'est pas un module bonus
  // et que ce n'est pas la derniere section
  // On revoit la section
  if (!badge && !lastMoreAdvancedSection.moduleZero && !lastMoreAdvancedSection.lastSection) {
    return _.pick(lastMoreAdvancedSection, ['sectionId', 'moduleId', 'moduleBonus', 'lastSection', 'moduleZero'])
  }

  const lastMoreAdvancedSectionIndex = _.findIndex(
    flattenSections,
    ({ sectionId }) => sectionId === lastMoreAdvancedSection.sectionId,
  )

  const nextSectionAfterLastMoreAdvancedSection = _.get(flattenSections, lastMoreAdvancedSectionIndex + 1)

  // Si il la prochaine section
  // On la revoit
  if (nextSectionAfterLastMoreAdvancedSection) {
    // Some modules to do
    return _.pick(nextSectionAfterLastMoreAdvancedSection, [
      'sectionId',
      'moduleId',
      'moduleBonus',
      'lastSection',
      'moduleZero',
    ])
  }

  // Sinon, on est arrivé au bout et il est temps de passer l'exam !
  return {}
}

/**
 * Determine if the module is the next to validate based on other modules and the badges already won
 *
 * @param Object module
 * @param {Object[]} modules
 * @param {Object[]} badges
 */
export const isNextToBadge = (module, modules, badges) => {
  const unbadgedModules = _.filter(modules, ({ id }) => !_.some(badges, { module: { id } }))
  const firstUnbadgedModule = _.head(unbadgedModules)

  return !!firstUnbadgedModule && firstUnbadgedModule.id === module.id
}
