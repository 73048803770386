import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import qs from 'query-string'
import { withRouter } from 'react-router-dom'

const QueryString = ({ children, name, history, location }) => {
  const [value, setValue] = useState()

  useEffect(() => {
    const query = qs.parse(location.search)

    if (query[name]) {
      setValue(query[name])
    } else {
      setValue()
    }
  }, [location.search])

  const handleChange = event => {
    const parsed = qs.parse(location.search)

    if (event && event.traget && typeof event.target.value === 'string') {
      parsed[name] = event.target.value
    } else if (event && typeof event === 'object') {
      parsed[name] = event.value
    } else if (event && typeof event === 'string') {
      parsed[name] = event
    } else {
      delete parsed[name]
    }

    history.push({
      search: qs.stringify(parsed),
    })
  }

  return React.cloneElement(children, {
    value: (children.props.options || []).find(option => option.value === value) || value,
    onChange: handleChange,
  })
}

QueryString.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  location: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
}

export default withRouter(QueryString)
