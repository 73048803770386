import React from 'react'
import { propType } from 'graphql-anywhere'
import gql from 'graphql-tag'
import { toInlineFragment } from 'fraql'
import moment from 'moment'
import ResponsiveAvatar from 'components/ResponsiveAvatar'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'
import { Visible } from 'react-grid-system'
import MainAnimator from './MainAnimator'
import Certified from './Certified'
import VirtualClassroom from './VirtualClassroom'
import PlatformLink from '../PlatformLink'

const Wrapper = styled.div`
  margin-top: 36px;

  ${mq.sm(css`
    margin-top: 0px;
  `)};
`

const hasVirtualClassroomToCome = virtualClassrooms =>
  virtualClassrooms.filter(virtualClassroom => moment().isBefore(virtualClassroom.startDate)).length > 0

const Infos = ({ session }) => (
  <Wrapper>
    <Visible md lg xl>
      <PlatformLink />
    </Visible>
    {session.hasVirtualClassroom && (
      <VirtualClassroom
        sessionIdentifier={session.identifier}
        courseIdentifier={session.course.identifier}
        animators={session.virtualClassroomAnimators}
        virtualClassroomsNotYetAvailable={session.virtualClassrooms.length === 0}
        hasVirtualClassroomToCome={hasVirtualClassroomToCome(session.virtualClassrooms)}
      />
    )}
    {session.mainAnimator && <MainAnimator user={session.mainAnimator} />}
  </Wrapper>
)

Infos.fragments = {
  session: gql`
    fragment _ on Session {
      id
      identifier
      course {
        id
        identifier
      }
      ${toInlineFragment(Certified.fragments.session)}
      mainAnimator {
        id
        ${toInlineFragment(MainAnimator.fragments.user)}
      }
      hasVirtualClassroom
      virtualClassroomAnimators {
        id
        fullName
        presentation
        ${toInlineFragment(ResponsiveAvatar.fragments.user)}
      }
      virtualClassrooms{
        id
        startDate
      }
    }
  `,
}

Infos.propTypes = {
  session: propType(Infos.fragments.session).isRequired,
}

export default Infos
