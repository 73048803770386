import React from 'react'
import PropTypes from 'prop-types'
import { propType, filter } from 'graphql-anywhere'
import { toInlineFragment } from 'fraql'
import gql from 'graphql-tag'
import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl'
import { Can } from 'store/AbilityProvider'
import { colors } from 'styles-resources'
import useIsMobile from 'hooks/useIsMobile'
import EditLink from './EditLink'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const NumberTitle = styled.p`
  display: flex;
  font-size: 14px;
`

const Number = styled.span`
  display: block;
  padding-right: 4px;
  white-space: nowrap;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: ${colors.shipGray};
`

const Title = styled.span`
  color: ${colors.midGray};
`

const StyledEditLink = styled(EditLink)`
  margin-left: 15px;
`

const ModuleTitle = ({
  section,
  section: {
    module: { isZero, bonus, position, title },
  },
  className,
}) => {
  const isMobile = useIsMobile()

  return (
    <Wrapper className={className}>
      {isMobile ? (
        <NumberTitle>
          <Number>
            {bonus ? (
              <FormattedMessage id="sections.module_bonus_title" />
            ) : (
              <FormattedMessage id="sections.module_title" values={{ number: isZero ? 0 : position + 1 }} />
            )}
          </Number>
          <Title>{title}</Title>
        </NumberTitle>
      ) : (
        <div />
      )}

      <Can I="admin-edit" of="section">
        <StyledEditLink section={filter(EditLink.fragments.section, section)} />
      </Can>
    </Wrapper>
  )
}

ModuleTitle.fragments = {
  section: gql`
    fragment _ on Section {
      module {
        id
        isZero
        bonus
        position
        title
      }
      ${toInlineFragment(EditLink.fragments.section)}
    }
  `,
}

ModuleTitle.defaultProps = {
  className: undefined,
}

ModuleTitle.propTypes = {
  section: propType(ModuleTitle.fragments.section).isRequired,
  className: PropTypes.string,
}

export default ModuleTitle
