import React from 'react'
import { propType, filter } from 'graphql-anywhere'
import { toInlineFragment } from 'fraql'
import gql from 'graphql-tag'
import _ from 'lodash'
import { Redirect } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'
import ShadowBox from '@unowmooc/shadow-box'
import { QUIZ_ATTEMPT_STATUS } from 'modules/quiz/constants'
import { useQuiz } from 'modules/quiz/provider'
import NavBar from '../../NavBar'
import MissingBadges from './MissingBadges'
import Start from './Start'

const NbQuestions = styled.h4`
  margin-bottom: 20px;
`

const Box = styled(ShadowBox)`
  margin-bottom: 70px;
  padding: 30px;

  ${mq.sm(css`
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 30px;
    padding: 20px;
  `)};
`

const getModuleBadges = badges => _.filter(badges, ({ module }) => module !== null)
const getRequiredModules = modules => _.filter(modules, { hasQuiz: true })

const IntroPage = ({ participant, session }) => {
  const { getResultsPath, remainingAttempts, questionCounter } = useQuiz()

  // Si l'examen est validé ou si il n'y a plus de tentative on redirige sur la page de résultats
  if (participant.lastFinalExamQuizAttempt?.status === QUIZ_ATTEMPT_STATUS.SUCCESS || remainingAttempts === 0) {
    return <Redirect to={getResultsPath()} />
  }

  const moduleBadges = getModuleBadges(participant.badges)
  const requiredModules = getRequiredModules(session.course.modules)

  return (
    <>
      <NbQuestions>
        <FormattedMessage id="final_exam.intro.nb_questions" values={{ questionCounter }} />
      </NbQuestions>
      <Box>
        {moduleBadges.length < requiredModules.length ? (
          <MissingBadges
            session={filter(MissingBadges.fragments.session, session)}
            badges={filter(MissingBadges.fragments.badge, moduleBadges)}
            modules={filter(MissingBadges.fragments.module, requiredModules)}
          />
        ) : (
          <Start />
        )}
      </Box>
      <NavBar
        session={filter(NavBar.fragments.session, session)}
        section={filter(NavBar.fragments.section, { id: 'final-exam', module: null })}
      />
    </>
  )
}

IntroPage.fragments = {
  session: gql`
    fragment _ on Session {
      course {
        id
        modules {
          id
          hasQuiz
          ${toInlineFragment(MissingBadges.fragments.module)}
        }
      }
      ${toInlineFragment(MissingBadges.fragments.session)}
      ${toInlineFragment(NavBar.fragments.session)}
    }
  `,
  participant: gql`
    fragment _ on Participant {
      id
      badges {
        id
        module {
          id
        }
        ${toInlineFragment(MissingBadges.fragments.badge)}
      }
      lastFinalExamQuizAttempt {
        id
        status
      }
    }
  `,
}

IntroPage.propTypes = {
  session: propType(IntroPage.fragments.session).isRequired,
  participant: propType(IntroPage.fragments.participant).isRequired,
}

export default IntroPage
