import React, { useEffect, useState } from 'react'
import { Textarea as BootstrapTextarea } from '@unowmooc/inputs'

// Hack qui permet au composant Textarea de @unowmooc/inputs de bien calculer sa hauteur
const Textarea = props => {
  const [showChild, setShowChild] = useState(false)

  useEffect(() => {
    setShowChild(true)
  }, [])

  return showChild && <BootstrapTextarea {...props} />
}

export default Textarea
