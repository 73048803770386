import React, { Component } from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'
import { withUser } from 'store/UserProvider'
import Helmet from 'components/HelmetIntl'
import PageQuery from 'components/PageQuery'
import ParticipationDocuments from './ParticipationDocuments'

const Title = styled.h2`
  margin-top: 8px;
  margin-bottom: 30px;

  ${mq.sm(css`
    margin-top: 26px;
  `)};
`

const StyledParticipationDocuments = styled(ParticipationDocuments)`
  margin-bottom: 50px;

  ${mq.sm(css`
    margin-bottom: 40px;
  `)};

  &:last-child {
    margin-bottom: 0;
  }
`

class DocumentsPage extends Component {
  get filters() {
    const { user } = this.props

    return JSON.stringify({
      user_id: user.id,
      canceledAt: null,
      session_canceledAt: null,
    })
  }

  render() {
    const { filters } = this

    return (
      <>
        <Helmet title="page_titles.settings.documents.title" />
        <Title>
          <FormattedMessage id="settings.documents.page_title" />
        </Title>
        <PageQuery
          query={gql`
            query ParticipationsForDocuments($filters: Json, $sort: [String]) {
              participants(filters: $filters, sort: $sort) {
                items {
                  id
                  ...ParticipationDocumentsFragment
                }
              }
            }
            ${ParticipationDocuments.fragment}
          `}
          variables={{ filters, sort: ['session.startAt DESC'] }}
          render={({ participants: { items } }) =>
            items.map(participation => (
              <StyledParticipationDocuments participation={participation} key={participation.id} />
            ))
          }
        />
      </>
    )
  }
}

DocumentsPage.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.shape.isRequired,
  }).isRequired,
}

export default withUser(DocumentsPage)
