import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import styled from '@emotion/styled'
import { colors } from 'styles-resources'
import SectionBox from 'components/SectionBox'
import Item from './Item'

const Box = styled(SectionBox)`
  display: flex;
  height: 98px;
  padding: 21px 30px 12px;
  margin-bottom: 40px;
`

const Start = styled.div`
  padding: 34px 22px 0 0;
  color: ${colors.shipGray};
`

const BarContainer = styled.div`
  position: relative;
  flex: 1;
  padding-top: 30px;
`

const BarBackdrop = styled.div`
  position: absolute;
  top: 40px;
  right: 0;
  left: 0;
  height: 8px;
  background: ${colors.whisper};
  border-radius: 11.5px;
`

const End = styled.div`
  padding: 34px 0 0 22px;
  color: ${colors.shipGray};
`

class ProgressBox extends Component {
  get children() {
    const { children: childrenProp } = this.props

    const children = React.Children.map(childrenProp, child => child)

    const childrenToDisplay = children.filter(({ props: { point } }) => point > 0)

    return _.orderBy(childrenToDisplay, 'props.point', 'desc')
  }

  get endPoint() {
    const { point } = this.props
    const { children } = this.props

    const {
      props: { point: lastPoint },
    } = _.first(children)

    return lastPoint > point ? lastPoint : point
  }

  getWidth(itemPoint, progressPoint) {
    return (itemPoint / progressPoint) * 100
  }

  getChildProps(child, index) {
    const { endPoint } = this
    const width = this.getWidth(child.props.point, endPoint)

    return {
      ...child.props,
      position: index % 2 !== 0 ? 'top' : 'bottom',
      width,
      noLabel: child.props.point === endPoint,
    }
  }

  render() {
    const { children, endPoint } = this

    return (
      <Box>
        <Start>0</Start>
        <BarContainer>
          <BarBackdrop />
          {children.map((child, index) => React.cloneElement(child, this.getChildProps(child, index)))}
        </BarContainer>
        <End>{endPoint}</End>
      </Box>
    )
  }
}

ProgressBox.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  point: PropTypes.number.isRequired,
}

export { Item as ProgressItem }
export default ProgressBox
