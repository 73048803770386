import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import gql from 'graphql-tag'
import { FormattedMessage } from 'react-intl'
import { Link, withRouter } from 'react-router-dom'
import styled from '@emotion/styled'
import I18nProvider from '@unowmooc/i18n'
import Tag from '@unowmooc/tags'
import { SubItem } from 'components/TimelineMenu'
import * as sectionBusiness from 'business/section'
import SectionIcon from '../SectionIcon'

const StyledLink = styled(Link)`
  outline-offset: -2px;

  &:hover {
    text-decoration: none;
  }

  span + span {
    width: 100%;
  }
`

const TagWrapper = styled.div`
  float: right;
`

const getSectionIcon = section => {
  // if section has at least one video content we consider section as video section
  if (section.hasContentVideo) {
    return 'control-play'
  }

  return 'document'
}

const SectionItem = ({ section, session, disabled, draftModule, valid, onClick, location: { pathname } }) => {
  const to = I18nProvider.getLinkRoute('/courses/{slugCourse}/{idSession}/lessons/{idSection}', {
    slugCourse: session.course.slug,
    idSession: session.id,
    idSection: section.id,
  })

  const draft = (section.status === sectionBusiness.STATUS_DRAFT || draftModule) && (
    <TagWrapper>
      <Tag theme={Tag.themes.red} small>
        <FormattedMessage id="sections.draft" />
      </Tag>
    </TagWrapper>
  )

  const subItem = (
    <SubItem
      title={section.title}
      icon={<SectionIcon valid={valid} icon={getSectionIcon(section)} />}
      duration={
        section.duration ? (
          <>
            <span>
              <FormattedMessage id="courses.course.program.section_duration" values={{ duration: section.duration }} />
            </span>
            {draft}
          </>
        ) : (
          draft
        )
      }
      active={to === pathname}
      disabled={disabled}
      key={section.id}
    />
  )

  return disabled ? (
    subItem
  ) : (
    <StyledLink className={`section-item-${section.id}`} to={to} onClick={onClick} key={section.id}>
      {subItem}
    </StyledLink>
  )
}

SectionItem.fragments = {
  section: gql`
    fragment _ on Section {
      id
      title
      duration
      status
      hasContentVideo
    }
  `,
  session: gql`
    fragment _ on Session {
      id
      course {
        id
        slug
      }
    }
  `,
}

SectionItem.defaultProps = {
  disabled: false,
  valid: false,
  onClick: undefined,
  draftModule: false,
}

SectionItem.propTypes = {
  section: propType(SectionItem.fragments.section).isRequired,
  session: propType(SectionItem.fragments.session).isRequired,
  draftModule: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
  valid: PropTypes.bool,
  onClick: PropTypes.func,
}

export default withRouter(SectionItem)
