import React from 'react'
import hoistNonReactStatic from 'hoist-non-react-statics'
import { AbilityConsumer } from '.'

const withAbility = WrappedComponent => {
  const Wrapper = props => (
    <AbilityConsumer>{ability => <WrappedComponent {...props} ability={ability} />}</AbilityConsumer>
  )

  hoistNonReactStatic(Wrapper, WrappedComponent)

  Wrapper.displayName = `withAbility(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`

  return Wrapper
}

export default withAbility
