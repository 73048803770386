import React from 'react'
import PropTypes from 'prop-types'
import Alert from '@unowmooc/alert'
import gql from 'graphql-tag'
import { getMessageFromClosingPeriod } from 'modules/session/session.selectors'

const AlertClosingPeriod = ({ closingPeriod }) => {
  if (!closingPeriod) {
    return null
  }

  return <Alert mb={['20px', '30px']}>{getMessageFromClosingPeriod(closingPeriod)}</Alert>
}

AlertClosingPeriod.defaultProps = {
  closingPeriod: {},
}

AlertClosingPeriod.propTypes = {
  closingPeriod: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }),
}

AlertClosingPeriod.fragment = gql`
  fragment SessionAlertClosingPeriod on Session {
    closingPeriod {
      message
    }
  }
`

export default AlertClosingPeriod
