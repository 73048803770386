import React from 'react'
import PropTypes from 'prop-types'
import { propType, filter } from 'graphql-anywhere'
import { toInlineFragment } from 'fraql'
import gql from 'graphql-tag'
import styled from '@emotion/styled'
import Download from './Download'

const Resource = styled.div`
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`

const Downloads = ({ downloads, className }) => (
  <div className={className}>
    {downloads.map(download => (
      <Resource key={download.id}>
        <Download download={filter(Download.fragments.file, download)} />
      </Resource>
    ))}
  </div>
)

Downloads.fragments = {
  file: gql`
    fragment _ on File {
      id
      ${toInlineFragment(Download.fragments.file)}
    }
  `,
}

Downloads.defaultProps = {
  className: undefined,
}

Downloads.propTypes = {
  downloads: PropTypes.arrayOf(propType(Download.fragments.file)).isRequired,
  className: PropTypes.string,
}

export default Downloads
