export const QUIZ_ATTEMPT_STATUS = {
  PROCESSING: 'processing',
  SUCCESS: 'success',
  FAILURE: 'failure',
}

export const QUIZ_QUESTION_ATTEMPT_STATUS = {
  CREATED: 'created',
  SUCCESS: 'success',
  FAILURE: 'failure',
}

export const MINIMUM_CORRECT_ANSWERS_POURCENT = 0.7
