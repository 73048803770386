import qs from 'query-string'
import apiRest from 'utils/apiRest'

function ApiConnector({ formId, formContext, participantId }) {
  this.formId = formId
  this.formContext = formContext
  this.participantId = participantId
}

ApiConnector.prototype.loadData = async function loadData() {
  const queryString = qs.stringify({ context: this.formContext, participants: this.participantId })

  const response = await apiRest.get(`/forms/${this.formId}?${queryString}`)

  if (response.data.data.questions) {
    return response.data.data.questions
  }

  return []
}

ApiConnector.prototype.sendData = async function sendData(formData) {
  await apiRest.post(`/forms/${this.formId}/answers`, formData)
}

export default ApiConnector
