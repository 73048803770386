import React from 'react'
import PropTypes from 'prop-types'
import { CollectorProvider } from '../../provider'
import Page from '../Page'
import ApiConnector from '../../connector/api'

const Collector = ({ formId, formContext, participantId, onSubmit, onLoad, containerId, ...pageProps }) => {
  const connector = new ApiConnector({ formId, formContext, participantId })

  return (
    <CollectorProvider connector={connector} onSubmit={onSubmit} onLoad={onLoad} containerId={containerId}>
      <Page {...pageProps} />
    </CollectorProvider>
  )
}

Collector.defaultProps = {
  onSubmit: undefined,
  onLoad: undefined,
  formContext: null,
  containerId: undefined,
}

Collector.propTypes = {
  formId: PropTypes.number.isRequired,
  formContext: PropTypes.string,
  participantId: PropTypes.number.isRequired,
  onSubmit: PropTypes.func,
  onLoad: PropTypes.func,
  containerId: PropTypes.string,
}

export default Collector
