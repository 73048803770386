import React from 'react'
import { propType } from 'graphql-anywhere'
import gql from 'graphql-tag'
import _ from 'lodash'
import moment from 'moment'
import queryString from 'query-string'
import { toInlineFragment } from 'fraql'
import { useCourseContext } from 'modules/course/components/CourseContext/CourseContext'
import useMe from 'hooks/useMe'

const getSurveyUrl = ({ block, courseIdentifier, sessionIdentifier, participantId, user }) => {
  const params = {
    user_mail: user.email,
    user_id: user.id,
    course_identifier: courseIdentifier,
    session_identifier: sessionIdentifier,
    participant_id: participantId,
    page_url: window.location.href,
  }

  if (user.birthday) {
    params.user_birthday = moment(user.birthday).format('DD/MM/YYYY')
  }

  if (user.gender) {
    const valuesMapping = { f: 'Femme', m: 'Homme', other: 'Autre', nspp: 'NSPP' }
    // in case we have strange value in gender (should not happen)
    params.user_gender = _.get(valuesMapping, user.gender)
  }

  return `https://fr.surveymonkey.com/r/${block.surveyId}?${queryString.stringify(params)}`
}

const SurveyBlock = ({ block }) => {
  const { me: user } = useMe()
  const { courseIdentifier, sessionIdentifier, participantId } = useCourseContext()

  return (
    <iframe
      title={`Survey ${block.surveyId}`}
      src={getSurveyUrl({ block, user, courseIdentifier, participantId, sessionIdentifier })}
      frameBorder="0"
      height={block.height}
      width="100%"
      allowFullScreen
    />
  )
}

SurveyBlock.fragment = toInlineFragment(
  gql`
    fragment _ on Block {
      ... on SurveyBlock {
        blockType
        surveyId
        height
      }
    }
  `,
)

SurveyBlock.propTypes = {
  block: propType(SurveyBlock.fragment).isRequired,
}

export default SurveyBlock
