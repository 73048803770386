import { keyframes, css } from '@emotion/core'
import { colors, rgba } from 'styles-resources'

const scanAnimation = keyframes`
  0% {
    transform: translate3d(-30%, 0, 0);
  }
  100% {
    transform: translate3d(30%, 0, 0);
  }
`

export default css`
  position: relative;
  overflow: hidden;

  &:before {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    z-index: 1;
    width: 500%;
    margin-left: -250%;
    animation: ${scanAnimation} 1s linear infinite;
    background: linear-gradient(
        to right,
        ${rgba(colors.bombay, 0)} 40%,
        ${rgba(colors.bombay, 0.3)} 50%,
        ${rgba(colors.bombay, 0)} 60%
      )
      50% 50%;
  }
`
