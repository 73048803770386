import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { Flex, Box } from 'reflexbox'
import { mq } from '@unowmooc/themes'
import Tag from '@unowmooc/tags'
import { Text } from '@unowmooc/text'
import FormattedDateInterval from 'components/FormattedDateInterval'
import { withUser } from 'store/UserProvider'
import TagSessionType from 'components/TagSessionType'
import { isTagIdentifierDisplay } from 'modules/session/session.utils'
import DateClosingPeriod from 'modules/session/components/DateClosingPeriod'
import NbParticipantsAdvancement from 'components/NbParticipantsAdvancement'

const StyledTagSessionType = styled(TagSessionType)`
  margin-right: 12px;
`
const StyledNbParticipants = styled.span`
  display: inline-block;
  margin-right: 28px;
  line-height: 22px;

  ${mq.sm(css`
    display: block;
    margin-right: 0;
    font-size: 16px;
  `)};
`

const StyledNbParticipantsAdvancement = styled(NbParticipantsAdvancement)`
  display: inline-block;
  margin-right: 22px;
  line-height: 22px;
  white-space: nowrap;

  ${mq.sm(css`
    width: 50%;
    margin-right: 0;
    font-size: 16px;
  `)};
`

const SessionRow = ({
  className,
  sessionId,
  tags,
  details,
  buttons,
  shareResult,
  title,
  startAt,
  endAt,
  user,
  closingPeriod,
  sessionCode,
}) => (
  <Box variant="card" className={className} data-cy="session-row">
    <Flex display="inline-flex" alignItems="center">
      {isTagIdentifierDisplay({ id: sessionId }, user) && (
        <span style={{ whiteSpace: 'nowrap' }}>
          <Tag small mr="15px" data-cy="session-identifier">
            {sessionCode}
          </Tag>
        </span>
      )}
      <Text
        data-cy="course-title"
        as="strong"
        variant="strong"
        display={['none', 'none', 'inline']}
        fontSize={['18px']}
        color="shipGray"
      >
        {title}
      </Text>
      <Flex flexWrap="nowrap" ml="auto">
        {tags}
      </Flex>
    </Flex>
    <Box display={['block', 'block', 'none']} my={['9px', null, '10px']}>
      <Text as="strong" variant="strong" fontSize={['16px', null, '18px']} color="shipGray">
        {title}
      </Text>
    </Box>
    <Box mt={['0', '0', '2px']} mb={['0', '0', '8px']}>
      <Text color="manatee">
        <>
          <FormattedDateInterval id="commons.session_from_to" from={startAt} to={endAt} />
          <DateClosingPeriod closingPeriod={closingPeriod} />
        </>
      </Text>
    </Box>
    <Flex display="inline-flex" alignItems={['start', 'center']}>
      <Box
        data-cy="details"
        my={['22px', null, 0]}
        display="inline-flex"
        flexWrap="wrap"
        mr={[null, null, 'auto']}
        sx={{
          '> *:nth-child(odd)': {
            marginRight: '20px',
          },
        }}
      >
        {details}
      </Box>
      <Box
        display={['none', 'none', 'flex']}
        flexDirection={[null, null, 'row']}
        sx={{
          '> *:not(:last-child)': {
            marginRight: '20px',
          },
        }}
      >
        {buttons}
      </Box>
    </Flex>
    <Flex
      display={['flex', 'flex', 'none']}
      flexDirection={['column', 'column', 'row']}
      sx={{
        '> *:not(:last-child)': {
          marginBottom: '20px',
        },
      }}
    >
      {buttons}
    </Flex>
    {shareResult && <Box mt="10px">{shareResult}</Box>}
  </Box>
)

SessionRow.defaultProps = {
  className: undefined,
  tags: null,
  details: null,
  buttons: null,
  shareResult: null,
  closingPeriod: {},
}

SessionRow.propTypes = {
  className: PropTypes.string,
  tags: PropTypes.node,
  details: PropTypes.node,
  buttons: PropTypes.node,
  title: PropTypes.string.isRequired,
  startAt: PropTypes.string.isRequired,
  endAt: PropTypes.string.isRequired,
  shareResult: PropTypes.node,
  sessionCode: PropTypes.string.isRequired,
  sessionId: PropTypes.string.isRequired,
  closingPeriod: PropTypes.shape(),
  user: PropTypes.shape().isRequired,
}

export {
  StyledTagSessionType as TagSessionType,
  StyledNbParticipants as NbParticipants,
  StyledNbParticipantsAdvancement as NbParticipantsAdvancement,
}

export default withUser(SessionRow)
