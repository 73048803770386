import React, { useEffect, useState } from 'react'
import gql from 'graphql-tag'
import PropTypes from 'prop-types'
import { toInlineFragment } from 'fraql'
import Sequence from 'components/VirtualClassroom/Sequence'
import { SequenceProvider } from 'components/VirtualClassroom/Sequence/SequenceContext'
import SequenceSlot from 'components/VirtualClassroom/Sequence/SequenceSlot'
import styled from '@emotion/styled'
import * as virtualClassroomUtils from 'utils/virtualClassroom'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import { Alert } from '@unowmooc/react-ui-kit'
import { css } from '@emotion/core'
import { hasAtLeastOneWebexLink as hasAtLeastOneWebexLinkFn } from 'utils/virtualClassroom'
import { colors, mq } from '@unowmooc/themes'
import { ZeroHeightUnow } from '@unowmooc/zeroheight'
import useMe from 'hooks/useMe'
import { useTracking } from 'modules/tracking'
import { useCourseContext } from 'modules/course/components/CourseContext/CourseContext'
import { Visible } from 'react-grid-system'
import { Link } from 'react-router-dom'
import { SEQUENCE_ORIGINS } from 'components/VirtualClassroom/Sequence/constantes'
import { COURSE_FORMAT } from 'business/course'
import Testimonials from './Testimonials'

const Wrapper = styled.div`
  display: flex;
  padding-top: 36px;
`

const MainContainer = styled.main`
  flex: 1;
`

const AsideContainer = styled.aside`
  width: 260px;
  margin-left: 44px;
`

const Description = styled.p`
  margin-bottom: 36px;
  font-size: 18px;
  line-height: 30px;

  ${mq.sm(css`
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 24px;
  `)}
`

const SequenceSlotWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

const AlertInfo = styled(Alert)`
  padding: 30px;

  ul {
    margin: 0;
    padding: 0 0 0 20px;

    li {
      list-style-type: disc;
    }
  }
`

const DatesTitle = styled.h4`
  margin-bottom: 15px;
`

const WebexTestLink = styled.div`
  display: flex;

  p {
    flex: 1;
    color: ${colors.black};

    a {
      font-weight: 700;
      text-decoration: underline;
    }
  }
`

const SummaryList = styled.ul`
  margin: 0px;
  padding: 6px 0px 0px;
  font-size: 16px;
  li {
    margin-bottom: 4px;
    a {
      line-height: 30px;
      text-decoration: underline;
    }
  }
`

const VirtualClassrooms = ({ session: { virtualClassroomsBySequence } }) => {
  const { courseIdentifier, sessionIdentifier, courseFormat, mainAnimator } = useCourseContext()
  const [activeSequenceNumber, setActiveSequenceNumber] = useState(null)
  const hasAtLeastOneWebexLink = hasAtLeastOneWebexLinkFn(virtualClassroomsBySequence)
  const hasOnboardingVirtualClassroom = virtualClassroomUtils.hasOnboardingVirtualClassroom(virtualClassroomsBySequence)
  const hasNoOnboardingVirtualClassroom = virtualClassroomUtils.hasNoOnboardingVirtualClassroom(
    virtualClassroomsBySequence,
  )
  const nextVirtualClassroomIndex = virtualClassroomUtils.getNextVirtualClassroomIndex(virtualClassroomsBySequence)
  const { sendEvent } = useTracking()
  const {
    me: { gender },
  } = useMe()

  useEffect(() => {
    const hashMatch = window.location.hash.match(/^#sequence-(?<sequenceNumber>\d+)$/)
    setActiveSequenceNumber(parseInt(hashMatch?.groups.sequenceNumber, 10) ?? null)
  }, [window.location.hash])

  const handleClickTestLink = () => {
    sendEvent(
      'virtual_classroom.click_on_webex_test_link',
      JSON.stringify({
        courseIdentifier,
        sessionIdentifier,
      }),
    )
  }

  let hasReplay = 1
  virtualClassroomsBySequence.forEach(function (sequence) {
    sequence.virtualClassrooms.forEach(function (virtualclassroom) {
      if (virtualclassroom.isRecordingAvailable === false) {
        hasReplay = 0
      }
    })
  })

  return (
    <Wrapper>
      <MainContainer>
        <h1>
          <FormattedMessage id="virtual_classroom.heading_tab_title" />
        </h1>
        <Description>
          {courseFormat === COURSE_FORMAT.FOCUS ? (
            <FormattedMessage
              id="virtual_classroom.intros.focus"
              values={{ expertGender: mainAnimator?.gender ?? 'm' }}
            />
          ) : (
            <>
              {hasNoOnboardingVirtualClassroom && hasOnboardingVirtualClassroom && (
                <FormattedMessage id="virtual_classroom.intros.all" />
              )}
              {!hasNoOnboardingVirtualClassroom && hasOnboardingVirtualClassroom && (
                <FormattedMessage id="virtual_classroom.intros.only_all" />
              )}
              {hasNoOnboardingVirtualClassroom && !hasOnboardingVirtualClassroom && (
                <FormattedMessage id="virtual_classroom.intros.only_al" />
              )}
              <br />
              <br />
              <FormattedMessage id="virtual_classroom.intros.summary" />
            </>
          )}
          {courseFormat !== COURSE_FORMAT.FOCUS && (
            <SummaryList>
              {virtualClassroomsBySequence.map(sequence => (
                <li>
                  <Link to={`#sequence-${sequence.sequenceNumber}`}>
                    {sequence.title ? (
                      <>
                        {sequence.sequenceNumber} - {sequence.title}
                      </>
                    ) : (
                      <FormattedMessage
                        id="virtual_classroom.label_cms_block"
                        values={{ number: sequence.sequenceNumber }}
                      />
                    )}
                  </Link>
                </li>
              ))}
            </SummaryList>
          )}
        </Description>

        {virtualClassroomsBySequence.map((sequence, sequenceIndex) => (
          <SequenceProvider
            active={activeSequenceNumber === sequence.sequenceNumber}
            key={sequence.sequenceNumber}
            sequence={sequence}
            origin={SEQUENCE_ORIGINS.tab}
          >
            <Sequence
              showProgressLine={false}
              isNextSequence={sequenceIndex === nextVirtualClassroomIndex}
              showSlotHelperBlock={sequenceIndex === nextVirtualClassroomIndex}
              isLastSequence={sequenceIndex + 1 === virtualClassroomsBySequence.length}
              render={contextSequence => (
                <SequenceSlotWrapper>
                  {contextSequence.virtualClassrooms.map(virtualClassroom => (
                    <SequenceSlot virtualClassroom={virtualClassroom} />
                  ))}
                </SequenceSlotWrapper>
              )}
            />
          </SequenceProvider>
        ))}

        {hasNoOnboardingVirtualClassroom && (
          <AlertInfo>
            <>
              <DatesTitle>
                <FormattedHTMLMessage id="virtual_classroom.dates.title_info" />
              </DatesTitle>
              <FormattedHTMLMessage id="virtual_classroom.dates.li_info" values={{ hasReplay }} />
            </>
          </AlertInfo>
        )}

        {!hasNoOnboardingVirtualClassroom && !hasOnboardingVirtualClassroom && (
          <Alert theme="warning">
            <FormattedHTMLMessage id="virtual_classroom.dates.unavailable" />
          </Alert>
        )}
      </MainContainer>
      <Visible lg xl>
        <AsideContainer>
          <Testimonials />
          {hasAtLeastOneWebexLink && (
            <WebexTestLink>
              <ZeroHeightUnow height="48px" width="48px" name="IconHeadphone" mr="10px" />
              <p>
                <FormattedHTMLMessage id="virtual_classroom.webex_test_link_part_1" values={{ gender }} />{' '}
                <a
                  href="https://www.webex.com/test-meeting.html/"
                  target="webex_test_link"
                  onClick={handleClickTestLink}
                >
                  <FormattedHTMLMessage id="virtual_classroom.webex_test_link_part_2" />
                </a>
              </p>
            </WebexTestLink>
          )}
        </AsideContainer>
      </Visible>
    </Wrapper>
  )
}

VirtualClassrooms.fragments = {
  session: gql`
    fragment _ on Session {
      id
      virtualClassroomsBySequence {
        ${toInlineFragment(Sequence.fragments.sequence)}
      }
    }
  `,
}

VirtualClassrooms.propTypes = {
  session: PropTypes.shape({
    virtualClassroomsBySequence: PropTypes.arrayOf(
      PropTypes.shape({
        sequenceNumber: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
}

export default VirtualClassrooms
