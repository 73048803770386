import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Modal } from '@unowmooc/modal'
import { ButtonI18n } from '@unowmooc/buttons'

import FormShareLinkedin from '../FormShareLinkedin'

const StyledModal = styled(Modal)`
  background-color: rgba(24, 25, 27, 0.8);
  opacity: 1;
  z-index: 7000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const ButtonLinkedin = styled(ButtonI18n)`
  i {
    margin-right: 10px;
    font-size: 22px;
    line-height: 15px;
  }
`

const ButtonShareLinkedin = ({ certificateNumber, certificateValidatedAt, course, i18nKey, sessionIdentifier }) => {
  const [open, setOpen] = useState()

  return (
    <>
      <ButtonLinkedin
        reverse
        variant="none"
        bg="linkedin"
        borderRadius="4px"
        color="white"
        i18nKey={i18nKey}
        icon="linkedin"
        onClick={() => setOpen(true)}
        data-tracking-id="session.open_certificate_info"
        data-tracking-values={JSON.stringify({
          courseIdentifier: course.identifier,
          sessionIdentifier,
        })}
      />
      {open && (
        <StyledModal>
          <FormShareLinkedin
            onClose={() => setOpen(false)}
            certificateNumber={certificateNumber}
            certificateValidatedAt={certificateValidatedAt}
            sessionIdentifier={sessionIdentifier}
            course={course}
          />
        </StyledModal>
      )}
    </>
  )
}

ButtonShareLinkedin.defaultProps = {
  i18nKey: 'final_exam.results.success.sort_share_on_linkedin',
}

ButtonShareLinkedin.propTypes = {
  i18nKey: PropTypes.string,
  certificateNumber: PropTypes.number.isRequired,
  certificateValidatedAt: PropTypes.string.isRequired,
  course: PropTypes.shape().isRequired,
  sessionIdentifier: PropTypes.string.isRequired,
}

export default ButtonShareLinkedin
