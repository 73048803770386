import React from 'react'
import PropTypes from 'prop-types'
import { propType, filter } from 'graphql-anywhere'
import gql from 'graphql-tag'
import { toInlineFragment } from 'fraql'
import { FormattedMessage } from 'react-intl'
import { Item } from 'components/TimelineMenu'
import { hasSeenSection } from 'modules/progression/utils'
import SectionItem from '../SectionItem'

const getVisibleSections = (sections = [], session) =>
  sections.filter(section => {
    // On affiche pas l'item de menu d'accès aux classes virtuelles si elle ne sont pas programmées
    if (!session.hasVirtualClassroom && section.isVirtualClassroomType) {
      return false
    }

    return true
  })

const ModuleZeroItem = ({ session, module, progressions, onSectionClick, open, isMenuOpen, forwardedRef }) => (
  <Item
    legend={
      <span ref={forwardedRef}>
        <FormattedMessage id="courses.course.program.module_zero.legend" />
      </span>
    }
    title={module.title}
    open={open}
    isMenuOpen={isMenuOpen}
    id="module-zero"
  >
    {getVisibleSections(module.sections, session).map(section => {
      const valid = hasSeenSection(section, progressions)

      return (
        <SectionItem
          section={filter(SectionItem.fragments.section, section)}
          session={filter(SectionItem.fragments.session, session)}
          valid={valid}
          icon="document"
          onClick={() => onSectionClick(`section-${section.id}`)}
          key={section.id}
        />
      )
    })}
  </Item>
)

ModuleZeroItem.fragments = {
  module: gql`
    fragment _ on Module {
      title
      status
      sections {
        id
        status
        position
        isVirtualClassroomType
        ${toInlineFragment(SectionItem.fragments.section)}
      }
    }
  `,
  session: gql`
    fragment _ on Session {
      id
      startAt
      hasVirtualClassroom
      forms {
        commonSkillsInitialForm { id }
        specificSkillsInitialForm { id }
      }
      ${toInlineFragment(SectionItem.fragments.session)}
    }
  `,
  progression: gql`
    fragment _ on Progression {
      id
      section {
        id
      }
    }
  `,
}

ModuleZeroItem.defaultProps = {
  open: false,
  onSectionClick: undefined,
  forwardedRef: undefined,
}

ModuleZeroItem.propTypes = {
  module: propType(ModuleZeroItem.fragments.module).isRequired,
  session: propType(ModuleZeroItem.fragments.session).isRequired,
  progressions: PropTypes.arrayOf(propType(ModuleZeroItem.fragments.progression)).isRequired,
  open: PropTypes.bool,
  isMenuOpen: PropTypes.bool.isRequired,
  onSectionClick: PropTypes.func,
  forwardedRef: PropTypes.shape(),
}

export default ModuleZeroItem
