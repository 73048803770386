import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import StreamlineIcon from '@unowmooc/icons'

const Wrapper = styled.div`
  display: flex;
`

const Icon = styled(StreamlineIcon)`
  margin-right: 10px;
  font-size: 24px;
  color: ${({ theme: { colors } }) => colors.unowGreen};
`

const Link = styled.a`
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
`

const Type = styled.span`
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme: { colors } }) => colors.midGray};
`

const Resource = ({ title, href, icon, type, className }) => (
  <Wrapper className={className}>
    <Icon icon={icon} />
    <div>
      <Link href={href} target="_blank">
        {title}
      </Link>
      <Type>{type}</Type>
    </div>
  </Wrapper>
)

Resource.defaultProps = {
  icon: 'document',
  type: undefined,
  className: undefined,
}

Resource.propTypes = {
  title: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  icon: PropTypes.string,
  type: PropTypes.node,
  className: PropTypes.string,
}

export default Resource
