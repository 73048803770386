import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { FormattedMessage } from 'react-intl'
import queryString from 'query-string'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq, HiddenXsDataGrid } from 'styles-resources'
import _ from 'lodash'
import s from 'underscore.string'
import I18nProvider from '@unowmooc/i18n'
import { DataGrid } from '@unowmooc/react-ui-kit'
import Tooltip from '@unowmooc/tooltip'
import StreamlineIcon from '@unowmooc/icons'
import DataGridToolbar from 'components/DataGridToolbar'
import DocumentLink from './Link'

const { DataGridCell } = DataGrid

const DownloadIcon = styled(StreamlineIcon)`
  font-size: 24px;

  ${mq.xsDataGrid(css`
    display: inline;
    margin-right: 14px;
    font-size: 20px;
  `)}
`

export class DocumentsPage extends Component {
  get sessionCertificates() {
    const {
      supervisedSessionStats: { participations },
    } = this.props

    return _.reduce(
      participations,
      (result, { id, user: { fullName }, advancementStatus, certificateNumber }) => {
        if (advancementStatus !== 'certified') {
          return result
        }

        return [
          ...result,
          {
            id: `cs-${id}`,
            type: 'cs',
            certificateNumber,
            titleMessage: I18nProvider.formatMessage({
              id: `documents.labels.cs`,
              values: { fullName },
            }),
          },
        ]
      },
      [],
    )
  }

  get individualCertificates() {
    const {
      supervisedSessionStats: { participations },
    } = this.props

    return _.reduce(
      participations,
      (result, { id, user: { fullName }, status, session: { runningStatus } }) => {
        if (status !== 'finish' && runningStatus !== 'finished') {
          return result
        }

        return [
          ...result,
          {
            id: `ai-${id}`,
            type: 'ai',
            participantId: id,
            titleMessage: I18nProvider.formatMessage({
              id: `documents.labels.ai`,
              values: { fullName },
            }),
          },
        ]
      },
      [],
    )
  }

  get search() {
    const {
      location: { search },
    } = this.props

    const locationSearch = queryString.parse(search)

    const filters = locationSearch.filters && JSON.parse(locationSearch.filters)

    if (!filters) {
      return undefined
    }

    return s.cleanDiacritics(filters.search)
  }

  get documents() {
    const {
      supervisedSessionStats: { supervisions },
    } = this.props

    // documents by project
    const documents = _.reduce(
      [
        'cf', // Convention de formation
        'af', // Attestation collective
      ],
      (result, value) => {
        const projectDocuments = (supervisions || []).map(({ project }) => ({
          id: `${value}-${project.id}`,
          projectId: project.id,
          type: value,
          titleMessage: I18nProvider.formatMessage({
            id: `documents.labels.${value}`,
          }),
        }))
        return [...result, ...projectDocuments]
      },
      [],
    )

    // individual certificates and session certificates for every participants
    const allDocuments = documents.concat(this.individualCertificates, this.sessionCertificates)

    const { search } = this

    return search
      ? _.filter(allDocuments, ({ titleMessage }) => s.cleanDiacritics(titleMessage).match(new RegExp(search, 'i')))
      : allDocuments
  }

  render() {
    const {
      supervisedSessionStats: {
        session: { id: sessionId },
      },
    } = this.props

    return (
      <>
        <DataGridToolbar withCounter={false} />
        <DataGrid data={this.documents}>
          <DataGridCell
            label={<FormattedMessage id="documents.title" />}
            size="large"
            hideLabelOnMobile
            verticalAlign={DataGridCell.verticalAlign.top}
            render={(documentData, { data: { titleMessage } }) => (
              <DocumentLink
                {..._.pick(documentData, ['projectId', 'participantId', 'certificateNumber', 'type'])}
                sessionId={sessionId}
              >
                <HiddenXsDataGrid>
                  <DownloadIcon icon="download" />
                </HiddenXsDataGrid>
                {titleMessage}
              </DocumentLink>
            )}
          />
          <DataGridCell
            label={<FormattedMessage id="documents.actions" />}
            align="right"
            hideOnMobile
            render={documentData => (
              <Tooltip distance={15} placement="bottom-end" content={<FormattedMessage id="commons.download" />}>
                <DocumentLink
                  {..._.pick(documentData, ['projectId', 'participantId', 'certificateNumber', 'type'])}
                  sessionId={sessionId}
                >
                  <DownloadIcon icon="download" />
                </DocumentLink>
              </Tooltip>
            )}
          />
        </DataGrid>
      </>
    )
  }
}

DocumentsPage.propTypes = {
  supervisedSessionStats: PropTypes.shape().isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
}

DocumentsPage.fragment = gql`
  fragment DocumentsFragment on SupervisedSessionStats {
    session {
      id
    }
    participations {
      id
      user {
        id
        fullName
      }
      status
      advancementStatus
      certificateNumber
      session {
        id
        runningStatus
      }
    }
    supervisions {
      id
      project {
        id
      }
    }
  }
`

export default withRouter(DocumentsPage)
