import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'
import { Button } from '@unowmooc/buttons'
import { useQuiz } from 'modules/quiz/provider'
import Score from '../Score'
import smileyConfused from './assets/smiley-confused.svg'

const Wrapper = styled.div`
  text-align: center;
`

const Illu = styled.img`
  margin-top: 30px;
  margin-bottom: 30px;
`

const Description = styled.h4`
  margin-bottom: 15px;
`

const StyledScore = styled(Score)`
  margin-bottom: 70px;
`

const Failure = () => {
  const { startQuiz } = useQuiz()

  return (
    <Wrapper data-cy="quiz-failure-wrapper">
      <Illu src={smileyConfused} />

      <Description>
        <FormattedMessage id="quiz.results.failure.description" />
      </Description>

      <StyledScore />

      <Button
        size="large"
        onClick={() => {
          startQuiz()
        }}
      >
        <FormattedMessage id="quiz.results.restart_link" />
      </Button>
    </Wrapper>
  )
}

export default Failure
