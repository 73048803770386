import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Modal from 'components/Modal'
import StreamlineIcon from '@unowmooc/icons'
import Collector from 'modules/collector/components/Collector'
import { colors } from '@unowmooc/themes'
import { Button } from '@unowmooc/buttons'
import { FormattedMessage } from 'react-intl'
import { useCourseContext } from 'modules/course/components/CourseContext/CourseContext'

const ModalStyled = styled(Modal)`
  position: relative;
  height: ${({ answered }) => (answered ? 'auto' : '100vh')};
`

const CloseButton = styled.button`
  position: fixed;
  right: 10px;
  top: 6px;
  border: none;
  background: none;
  cursor: pointer;
  color: ${colors.white};

  i {
    font-size: 26px;
  }
`

const ThanksScreen = styled.div`
  text-align: center;
`

const ThanksScreenTitle = styled.h3`
  margin-bottom: 24px;
`

const StyledParagraph = styled.p`
  margin-top: 16px;
`

const ESCAPE_KEY_CODE = 27

const MODAL_CONTAINER_ID = 'evaluationForm'

const EvaluationModal = ({ evaluationFormId, evaluationFormContext, participantId, onClose }) => {
  const [answered, setAnswered] = useState(false)
  const { mainAnimator } = useCourseContext()

  const handleKeydown = event => {
    if (event.which !== ESCAPE_KEY_CODE) {
      return
    }

    onClose()
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown)
    return () => document.removeEventListener('keydown', handleKeydown)
  })

  const handleLoad = ({ hasAnswers }) => {
    if (hasAnswers) {
      setAnswered(true)
    }
  }

  return (
    <ModalStyled id={MODAL_CONTAINER_ID} answered={answered}>
      <CloseButton onClick={onClose}>
        <StreamlineIcon icon="cross" />
      </CloseButton>
      {!answered ? (
        <Collector
          formId={evaluationFormId}
          formContext={evaluationFormContext}
          participantId={participantId}
          onSubmit={onClose}
          containerId={MODAL_CONTAINER_ID}
          onLoad={handleLoad}
        />
      ) : (
        <ThanksScreen>
          <ThanksScreenTitle>
            <FormattedMessage id="virtual_classroom.collector_answered" />
            <StyledParagraph>
              <FormattedMessage
                id="virtual_classroom.collector_answered_paragraph"
                values={{ gender: mainAnimator?.gender ?? 'm' }}
              />
            </StyledParagraph>
          </ThanksScreenTitle>
          <Button onClick={onClose}>
            <FormattedMessage id="commons.close" />
          </Button>
        </ThanksScreen>
      )}
    </ModalStyled>
  )
}

EvaluationModal.propTypes = {
  evaluationFormId: PropTypes.number.isRequired,
  evaluationFormContext: PropTypes.string.isRequired,
  participantId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default EvaluationModal
