import React from 'react'
import PropTypes from 'prop-types'
import { Row } from 'react-grid-system'
import styled from '@emotion/styled'
import DomainFilter from './DomainFilter'
import Results from './Results'
import { CatalogContextProvider } from './CatalogContext'

const FilterBox = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
`

const Catalog = ({ className }) => (
  <div className={className}>
    <CatalogContextProvider>
      <FilterBox data-cy="catalog-filters">
        <DomainFilter />
      </FilterBox>
      <Row>
        <Results />
      </Row>
    </CatalogContextProvider>
  </div>
)

Catalog.defaultProps = {
  className: undefined,
}

Catalog.propTypes = {
  className: PropTypes.string,
}

export default Catalog
