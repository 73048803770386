import React, { createContext } from 'react'
import { IntlFragment } from '@unowmooc/react-ui-kit'
import { IntlProvider, IntlContext } from '@unowmooc/intl'
import moment from 'moment'
import PropTypes from 'prop-types'

const LocaleContext = createContext({})

const LocaleProvider = ({ defaultLocale, ...props }) => (
  <IntlProvider defaultLocale={defaultLocale} textComponent={IntlFragment}>
    <IntlContext.Consumer>
      {({ locale, switchLanguage }) => {
        const switchLocale = nextLocale => {
          switchLanguage(nextLocale)
          moment.locale(nextLocale)
        }

        return <LocaleContext.Provider value={{ locale, switchLocale }} {...props} />
      }}
    </IntlContext.Consumer>
  </IntlProvider>
)

LocaleProvider.propTypes = {
  defaultLocale: PropTypes.string.isRequired,
}

export { LocaleProvider, LocaleContext }
