import React from 'react'
import PropTypes from 'prop-types'
import { Query } from 'react-apollo'
import { propType, filter } from 'graphql-anywhere'
import { toInlineFragment } from 'fraql'
import _ from 'lodash'
import gql from 'graphql-tag'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { withRouter, Link } from 'react-router-dom'
import I18nProvider from '@unowmooc/i18n'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import StreamlineIcon from '@unowmooc/icons'
import { colors, mq } from 'styles-resources'
import { withUser } from 'store/UserProvider'
import { isStarted as isSessionStarted } from 'utils/date'
import TagSessionStatus from 'components/TagSessionStatus'
import LinkButtonIcon from 'components/LinkButtonIcon'
import SessionRow, { NbParticipants, NbParticipantsAdvancement } from 'components/SessionRow'
import { getPathToExpertCommentsBySessionId } from 'modules/comments/comment.utils'
import { getSessionCode } from 'modules/session/session.utils'
import DateClosingPeriod from 'modules/session/components/DateClosingPeriod'

const NbComments = styled.span`
  white-space: nowrap;
  color: ${colors.midGray};

  ${mq.sm(css`
    display: inline-block;
    width: 50%;
  `)};
`

const IconComment = styled(StreamlineIcon)`
  position: relative;
  margin-right: 5px;
  font-size: 24px;

  ${({ unread }) => (unread ? `color: ${colors.unowGreen};` : '')}

  ::after {
    ${({ unread, theme }) =>
      unread &&
      `
        position: absolute;
        top: 0;
        right: -2px;
        width: 6px;
        height: 6px;
        content: ' ';
        background-color: ${theme.colors.bittersweet};
        border: 2px solid ${theme.colors.white};
        border-radius: 50%;
    `}
  }
`

const LinkStyled = styled(Link)`
  & > span {
    font-weight: 400;
    text-decoration: underline;

    &:hover {
      color: ${colors.unowGreen};
    }
  }
`

const getExpertLinkStatusParticipants = (id, location) => {
  const searchParams = new URLSearchParams(location.search)

  return type => {
    searchParams.set('advancementStatus', type)

    return {
      pathname: I18nProvider.getLinkRoute('/expert/sessions/{id}/participants', { id }),
      search: searchParams.toString(),
    }
  }
}

const Session = ({
  user,
  session: {
    id,
    startAt,
    endAt,
    nbParticipants,
    nbWaitingParticipants,
    nbRegisteredParticipants,
    nbCertifiedParticipants,
    nbUpToDateParticipants,
    nbLateParticipants,
    runningStatus,
    currentWeek,
    hasFinalExam,
    course,
  },
  session,
  className,
  location,
}) => {
  const isStarted = isSessionStarted(startAt)

  const routeStatusParticipants = getExpertLinkStatusParticipants(id, location)

  return (
    <SessionRow
      className={className}
      title={course.title}
      startAt={startAt}
      sessionCode={getSessionCode(session)}
      sessionId={session.id}
      endAt={endAt}
      {...filter(DateClosingPeriod.fragment, session)}
      tags={<TagSessionStatus status={runningStatus} currentWeek={currentWeek} />}
      details={
        <>
          <NbParticipants>
            <FormattedHTMLMessage id="sessions.session.nb_participants" values={{ nbParticipants }} />
          </NbParticipants>
          {!isStarted && nbWaitingParticipants > 0 && (
            <NbParticipantsAdvancement status="waiting" nbParticipants={nbWaitingParticipants} />
          )}
          {!isStarted && nbRegisteredParticipants > 0 && (
            <NbParticipantsAdvancement status="registered" nbParticipants={nbRegisteredParticipants} />
          )}
          {hasFinalExam && nbCertifiedParticipants > 0 && (
            <LinkStyled to={routeStatusParticipants('certified')}>
              <NbParticipantsAdvancement status="certified" nbParticipants={nbCertifiedParticipants} />
            </LinkStyled>
          )}
          {isStarted && nbUpToDateParticipants > 0 && (
            <LinkStyled to={routeStatusParticipants('up_to_date')}>
              <NbParticipantsAdvancement status="up_to_date" nbParticipants={nbUpToDateParticipants} />
            </LinkStyled>
          )}
          {isStarted && nbLateParticipants > 0 && (
            <LinkStyled to={routeStatusParticipants('late')}>
              <NbParticipantsAdvancement status="late" nbParticipants={nbLateParticipants} />
            </LinkStyled>
          )}
          {isStarted && (
            <LinkStyled to={getPathToExpertCommentsBySessionId(id)}>
              <NbComments>
                <Query
                  query={gql`
                    query SessionUnreadComments($filters: Json!) {
                      comments(filters: $filters) {
                        _meta {
                          total
                        }
                      }
                    }
                  `}
                  variables={{
                    filters: JSON.stringify({
                      user_id: { neq: user.id },
                      session_id: id,
                      deletedAt: null,
                    }),
                  }}
                >
                  {({ data }) =>
                    _.get(data, 'comments._meta.total', 0) ? (
                      <>
                        <IconComment icon="chat" />
                        {_.get(data, 'comments._meta.total', 0)}
                      </>
                    ) : null
                  }
                </Query>
              </NbComments>
            </LinkStyled>
          )}
        </>
      }
      buttons={
        <>
          <LinkButtonIcon
            tag={Link}
            to={I18nProvider.getLinkRoute('/expert/sessions/{idSession}', { idSession: id })}
            theme="secondary"
            icon="right"
          >
            <FormattedMessage id="sessions.session.see_link" />
          </LinkButtonIcon>
          <LinkButtonIcon
            tag={Link}
            to={I18nProvider.getLinkRoute('/courses/{slugCourse}/{idSession}', {
              slugCourse: course.slug,
              idSession: id,
            })}
            icon="right"
          >
            <FormattedMessage id="sessions.session.go_to_link" />
          </LinkButtonIcon>
        </>
      }
    />
  )
}

Session.fragment = gql`
  fragment Session on Session {
    id
    startAt
    endAt
    type
    identifier
    runningStatus
    currentWeek
    hasFinalExam
    nbParticipants
    nbWaitingParticipants
    nbRegisteredParticipants
    nbCertifiedParticipants
    nbUpToDateParticipants
    nbLateParticipants
    course {
      id
      slug
      type
      title
    }
    ${toInlineFragment(DateClosingPeriod.fragment)}
  }
`

Session.defaultProps = {
  className: undefined,
}

Session.propTypes = {
  session: propType(Session.fragment).isRequired,
  user: PropTypes.shape().isRequired,
  className: PropTypes.string,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
}

export default withUser(withRouter(Session))
