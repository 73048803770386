import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Clickable } from '@unowmooc/buttons'
import StreamlineIcon from '@unowmooc/icons'

const Wrapper = styled.div`
  display: flex;
  align-items: stretch;
  width: 100%;
  background-color: ${({ theme: { colors } }) => colors.whiteLilac};
`

const ToggleButton = styled(Clickable)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 48px;
  min-height: 60px;
  background-color: ${({ theme: { colors } }) => colors.unowGreen};
`

const ArrowIcon = styled(StreamlineIcon, { shouldForwardProp: prop => prop !== 'rotate' })`
  font-size: 26px;
  color: ${({ theme: { colors } }) => colors.white};
  transition: transform 300ms ease-in-out;
  transform: ${({ rotate }) => `rotate3d(1, 0, 0, ${rotate ? 180 : 0}deg)`};
`

const Title = styled.h4`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme: { colors } }) => colors.shipGray};
`

const MobileToggleBar = ({ title, active, onClick }) => (
  <Wrapper>
    <ToggleButton onClick={onClick}>
      <ArrowIcon icon="down" rotate={active} />
    </ToggleButton>

    {title && <Title>{title}</Title>}
  </Wrapper>
)

MobileToggleBar.defaultProps = {
  title: undefined,
  active: false,
}

MobileToggleBar.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.node,
  active: PropTypes.bool,
}

export default MobileToggleBar
