import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { Row, Col } from 'react-grid-system'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'
import Field from 'components/FieldIntl'
import { Button, Input } from '@unowmooc/react-ui-kit'
import { withUser } from 'store/UserProvider'
import FormContainer from 'components/FormContainer'

const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 50px;
  padding: 20px 0;
  text-align: center;
  border-top: ${({ theme: { colors } }) => `1px solid ${colors.whisper}`};
  background-color: ${({ theme: { colors } }) => colors.white};
`

const FirstNameCol = styled(Col)`
  ${mq.xs(css`
    margin-bottom: 30px;
  `)}
`

const fragment = gql`
  fragment FormDataFragment on Me {
    id
    firstName
    lastName
    __fullName: fullName # usefull to update cache
  }
`

const Form = ({ user, onSubmit }) => (
  <FormContainer
    id={user.id}
    fragment={fragment}
    typeName="Me"
    mutationArgumentName="user"
    queryWithoutId
    onSubmitSucceeded={onSubmit}
    renderSubmitButton={({ submitting }) => (
      <ButtonWrapper>
        <Button
          type="submit"
          theme="primary"
          size="large"
          label={<FormattedMessage id="final_exam.submit_button" />}
          loading={submitting}
        />
      </ButtonWrapper>
    )}
  >
    <Row>
      <FirstNameCol xs={12} sm={6}>
        <Field required name="firstName" label="settings.profile.form.first_name">
          <Input />
        </Field>
      </FirstNameCol>
      <Col xs={12} sm={6}>
        <Field required name="lastName" label="settings.profile.form.last_name">
          <Input />
        </Field>
      </Col>
    </Row>
  </FormContainer>
)

Form.defaultProps = {
  onSubmit: undefined,
}

Form.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.shape.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func,
}

export default withUser(Form)
