import useIsMobile from 'hooks/useIsMobile'
import hoistNonReactStatic from 'hoist-non-react-statics'
import React from 'react'

const desktopOnly = (WrappedComponent, MobileComponent) => {
  const Wrapper = props => {
    const isMobile = useIsMobile()

    if (!isMobile) {
      return <WrappedComponent {...props} />
    }

    return <MobileComponent {...props} />
  }

  hoistNonReactStatic(Wrapper, WrappedComponent)

  Wrapper.displayName = `desktopOnly(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`

  return Wrapper
}

export default desktopOnly
