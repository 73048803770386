import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'
import gql from 'graphql-tag'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import Session from '../../expert/sessions/list/Session'

const Label = styled.h3`
  margin-bottom: 30px;

  ${mq.sm(css`
    margin-bottom: 20px;
  `)};
`

const StyledSession = styled(Session)`
  margin-bottom: 30px;

  ${mq.sm(css`
    margin-bottom: 20px;
  `)};

  &:last-child {
    margin-bottom: 0;
  }
`

const Animations = ({ animations, status }) => (
  <div data-cy={`animations-status-${status}`}>
    <Label>
      <FormattedMessage id={`dashboard.animations.${status}.label`} />
    </Label>
    {animations.map(animation => (
      <StyledSession session={animation.session} key={animation.session.id} />
    ))}
  </div>
)

Animations.fragment = gql`
  fragment Animations on Animation {
    session {
      ...Session
    }
  }
  ${Session.fragment}
`

Animations.propTypes = {
  animations: PropTypes.arrayOf(propType(Animations.fragment)).isRequired,
  status: PropTypes.oneOf(['in_progress', 'planned']).isRequired,
}

export default Animations
