import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import ParticlesBackground from './ParticlesBackground'

import illuBadge from './assets/badge.svg'

const Wrapper = styled.div`
  position: relative;
`

const BadgeImg = styled.img`
  position: relative;
`

const StyledParticlesBackground = styled(ParticlesBackground)`
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
`

const Badge = ({ className }) => (
  <Wrapper className={className}>
    <StyledParticlesBackground />

    <BadgeImg src={illuBadge} />
  </Wrapper>
)

Badge.defaultProps = {
  className: undefined,
}

Badge.propTypes = {
  className: PropTypes.string,
}

export default Badge
