import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { toInlineFragment } from 'fraql'
import styled from '@emotion/styled'
import PageQuery from 'components/PageQuery'
import { useCourseContext } from 'modules/course/components/CourseContext/CourseContext'
import Sequence from 'components/VirtualClassroom/Sequence'
import { SequenceProvider } from 'components/VirtualClassroom/Sequence/SequenceContext'
import SequenceSlot from 'components/VirtualClassroom/Sequence/SequenceSlot'
import { SEQUENCE_ORIGINS } from 'components/VirtualClassroom/Sequence/constantes'

const SequenceSlotWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

const VirtualClassroomSequenceBlockRenderer = ({ virtualClassroomBySequence }) =>
  virtualClassroomBySequence && (
    <SequenceProvider sequence={virtualClassroomBySequence} origin={SEQUENCE_ORIGINS.block}>
      <Sequence
        showProgressLine={false}
        showSlotHelperBlock
        render={contextSequence => (
          <SequenceSlotWrapper>
            {contextSequence.virtualClassrooms.map(virtualClassroom => (
              <SequenceSlot virtualClassroom={virtualClassroom} />
            ))}
          </SequenceSlotWrapper>
        )}
      />
    </SequenceProvider>
  )

VirtualClassroomSequenceBlockRenderer.defaultProps = {
  virtualClassroomBySequence: null,
}

VirtualClassroomSequenceBlockRenderer.propTypes = {
  virtualClassroomBySequence: PropTypes.shape({
    sequenceNumber: PropTypes.number.isRequired,
  }),
}

const VirtualClassroomSequenceBlock = ({ block }) => {
  const { sessionId } = useCourseContext()
  const { sequenceNumber } = block

  return (
    <PageQuery
      fetchPolicy="no-cache"
      showNotFoundMessageIfNoData={false}
      query={gql`
        query VirtualClassroomSequenceBlock($sessionId: ID!, $sequenceNumber: Int!) {
          virtualClassroomBySequence(sessionId: $sessionId, sequenceNumber: $sequenceNumber) {
              ${toInlineFragment(Sequence.fragments.sequence)}
           }
        }
      `}
      variables={{ sessionId, sequenceNumber }}
      render={props => <VirtualClassroomSequenceBlockRenderer block={block} {...props} />}
    />
  )
}

VirtualClassroomSequenceBlock.fragment = toInlineFragment(
  gql`
    fragment _ on Block {
      ... on VirtualClassroomSequenceBlock {
        blockType
        sequenceNumber
      }
    }
  `,
)

VirtualClassroomSequenceBlock.propTypes = {
  block: PropTypes.shape({
    sequenceNumber: PropTypes.number.isRequired,
  }).isRequired,
}

export default VirtualClassroomSequenceBlock
