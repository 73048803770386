import { useEffect } from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import { useCourseContext } from 'modules/course/components/CourseContext/CourseContext'
import * as sectionBusiness from 'business/section'

// Le fait de récupérer le timeSpentHours permet de rafraichir le temps passé dans le courseContext
// et ainsi lors de l'appel au sendDataToLmsConnector envoyer une information à jour (vu que le setProgression
// va potentiellement modifier sa valeur)
const PROGRESSION_FRAGMENT = gql`
  fragment ProgressionFragment on Progression {
    id
    # for cache updating
    participant {
      id
      timeSpentHours
      progressions {
        id
        section {
          id
          module {
            id
          }
        }
      }
    }
  }
`

const SET_PROGRESSION = gql`
  mutation setProgression($sectionId: ID!, $participantId: ID!) {
    setProgression(sectionId: $sectionId, participantId: $participantId) {
      ...ProgressionFragment
    }
  }
  ${PROGRESSION_FRAGMENT}
`

const ProgressionManager = ({ sectionId, sectionStatus }) => {
  const { participantId, lms } = useCourseContext()
  const [setProgression] = useMutation(SET_PROGRESSION, {
    onCompleted: () => {
      lms.sendDataToLmsConnector()
    },
  })

  useEffect(() => {
    if (!participantId) {
      return
    }

    if (sectionStatus !== sectionBusiness.STATUS_PUBLISH) {
      return
    }

    setProgression({
      variables: { sectionId, participantId },
    })
  }, [sectionId])

  return null
}

ProgressionManager.propTypes = {
  sectionId: PropTypes.string.isRequired,
  sectionStatus: PropTypes.oneOf(Object.values(sectionBusiness.statuses)),
}

export default ProgressionManager
