import React from 'react'
import PropTypes from 'prop-types'
import { colors } from '@unowmooc/themes'
import { Box } from 'reflexbox'

const HelpIcon = ({ active }) => {
  const color = active ? colors.white : colors.midGray

  return (
    <Box height="24px" width="24px" mr="8px">
      <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="11" cy="11.5" r="10" stroke={color} strokeWidth="2" />
        <path
          d="M11.0534 7.804C10.68 7.804 10.3227 7.852 9.98138 7.948C9.64004 8.03333 9.28271 8.17733 8.90938 8.38L8.36538 6.892C8.76004 6.668 9.20804 6.492 9.70938 6.364C10.2214 6.22533 10.744 6.156 11.2774 6.156C11.9174 6.156 12.4454 6.24667 12.8614 6.428C13.2774 6.59867 13.608 6.81733 13.8534 7.084C14.0987 7.35067 14.2694 7.644 14.3654 7.964C14.4614 8.284 14.5094 8.59333 14.5094 8.892C14.5094 9.25467 14.44 9.58 14.3014 9.868C14.1734 10.156 14.008 10.4227 13.8054 10.668C13.6027 10.9133 13.384 11.148 13.1494 11.372C12.9147 11.5853 12.696 11.8093 12.4934 12.044C12.2907 12.268 12.12 12.508 11.9814 12.764C11.8534 13.02 11.7894 13.308 11.7894 13.628C11.7894 13.6813 11.7894 13.7453 11.7894 13.82C11.7894 13.884 11.7947 13.948 11.8054 14.012H10.1414C10.12 13.9053 10.104 13.7933 10.0934 13.676C10.0827 13.548 10.0774 13.4307 10.0774 13.324C10.0774 12.972 10.136 12.6573 10.2534 12.38C10.3707 12.1027 10.52 11.8467 10.7014 11.612C10.8827 11.3773 11.0747 11.1587 11.2774 10.956C11.4907 10.7533 11.688 10.5507 11.8694 10.348C12.0507 10.1453 12.2 9.93733 12.3174 9.724C12.4347 9.51067 12.4934 9.276 12.4934 9.02C12.4934 8.668 12.3707 8.38 12.1254 8.156C11.8907 7.92133 11.5334 7.804 11.0534 7.804ZM12.3014 16.444C12.3014 16.8173 12.1787 17.1213 11.9334 17.356C11.688 17.5907 11.384 17.708 11.0214 17.708C10.6694 17.708 10.3654 17.5907 10.1094 17.356C9.86404 17.1213 9.74138 16.8173 9.74138 16.444C9.74138 16.0707 9.86404 15.7667 10.1094 15.532C10.3654 15.2867 10.6694 15.164 11.0214 15.164C11.384 15.164 11.688 15.2867 11.9334 15.532C12.1787 15.7667 12.3014 16.0707 12.3014 16.444Z"
          fill={color}
        />
      </svg>
    </Box>
  )
}

HelpIcon.defaultProps = {
  active: false,
}

HelpIcon.propTypes = {
  active: PropTypes.bool,
}

export default HelpIcon
