import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { propType, filter as graphqlFilter } from 'graphql-anywhere'
import gql from 'graphql-tag'
import { toInlineFragment } from 'fraql'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { animateScrollTo } from 'utils/scroll'
import I18nProvider from '@unowmooc/i18n'
import { Text } from '@unowmooc/text'
import UIComments from 'modules/comments/components/UI'
import { getTheme } from 'modules/comments/comment.utils'
import { useUserContext } from 'store/UserProvider'
import Actions from './Actions'
import EditForm from './EditForm'
import Content from './Content'
import Reactions from './Reactions'
import Tag from '../../Tag'
import DateCommentExpert from './DateCommentExpert'
import DateComment from './DateComment'
import Badge from '../../Badge'

const ProfileText = styled(Text)`
  font-weight: 700;
`

const ProfileLink = styled(Link)`
  font-weight: inherit;
  color: inherit;
`

const Comment = React.memo(
  ({ user, comment, child, isThreadExpert, sortCommentsOnCache, highlighted, historyReplace, pathname }) => {
    const [commentAction, setCommentAction] = useState({})
    const commentRef = useRef(null)

    // handle scroll to comment
    useEffect(() => {
      if (commentRef && highlighted) {
        animateScrollTo(commentRef.current)
      }
    }, [highlighted])

    const theme = getTheme(comment.authorRole)
    const commentIsAnonymous = comment.isAnonymous && !comment.user
    const { isAdmin, isAnimatorFromSession } = useUserContext()
    const isAdminOrAnimatorOfTheSession = isAdmin() || isAnimatorFromSession(comment.session.id)

    return (
      <UIComments.Comment
        refProp={commentRef}
        actionTheme={commentAction.color}
        commentId={comment.id}
        isChild={child}
        isPinned={comment.isPinned}
        isForwarded={comment.isForwarded && isAdminOrAnimatorOfTheSession}
        isAnonymous={commentIsAnonymous}
        theme={theme}
        isDeleted={comment.deletedAt !== null}
        deletedCommentLabel={<Text i18nKey="comment.deleted" />}
        className={`comment-${comment.id}`}
        info={
          <UIComments.CommentInfo
            theme={theme}
            author={
              commentIsAnonymous ? (
                <ProfileText i18nKey="comment.anonymous_full_name" />
              ) : (
                <ProfileLink
                  to={I18nProvider.getLinkRoute('/profile/{slug}/{id}', {
                    slug: comment.user.slug,
                    id: comment.user.id,
                  })}
                  data-tracking-id="session.click_on_profile_page"
                  data-tracking-values={JSON.stringify({
                    from: 'PostedComment',
                  })}
                >
                  {comment.user.fullName}
                </ProfileLink>
              )
            }
            avatarName={commentIsAnonymous ? '' : comment.user.fullName}
            avatarFile={commentIsAnonymous ? null : comment.user.avatar}
            tagLabel={<Tag comment={graphqlFilter(Tag.fragment, comment)} />}
            badgeLabel={<Badge comment={graphqlFilter(Badge.fragment, comment)} />}
            date={
              isThreadExpert ? (
                <DateCommentExpert comment={graphqlFilter(DateCommentExpert.fragment, comment)} />
              ) : (
                <DateComment comment={graphqlFilter(DateComment.fragment, comment)} pathname={pathname} />
              )
            }
          />
        }
        actions={
          <Actions
            key={'actions-'.concat(comment.id)}
            commentAction={commentAction}
            setCommentAction={setCommentAction}
            comment={graphqlFilter(Actions.fragments.comment, comment)}
            onPinUnpin={(pinned, cache) => {
              if (pinned) {
                historyReplace({
                  search: `?comment=${comment.id}`,
                })
              }

              sortCommentsOnCache(cache)
            }}
          />
        }
        content={
          <UIComments.CommentContent
            active={commentAction.edit}
            onSubmitSuccess={() => setCommentAction({ edit: false, color: null })}
            content={<Content comment={graphqlFilter(Content.fragments.comment, comment)} />}
            editor={<EditForm comment={graphqlFilter(EditForm.fragments.comment, comment)} embed={child} />}
          />
        }
        reactions={<Reactions reactions={comment.reactions} commentId={comment.id} user={user} />}
      />
    )
  },
  (
    { historyReplace, sortCommentsOnCache, ...prevProps },
    { historyReplace: nextHistoryReplace, sortCommentsOnCache: nextSortCommentsOnCache, ...nextProps },
  ) => _.isEqual(prevProps, nextProps),
)

Comment.fragments = {
  session: gql`
    fragment _ on Session {
      id
    }
  `,
  comment: gql`
    fragment _ on Comment {
      id
      isPinned
      isForwarded
      isResolved
      isAnonymous
      hasAnonymousCommentInThread
      createdAt
      deletedAt
      text
      authorRole
      user {
        id
        slug
        fullName
        participants {
          id
          advancementStatus
          session {
            id
          }
        }
        avatar {
          id
          fileStackId
          policy
          signature
        }
      }
      session {
        id
      }
      ${toInlineFragment(Tag.fragment)}
      ${toInlineFragment(Badge.fragment)}
      ${toInlineFragment(Actions.fragments.comment)}
      ${toInlineFragment(Content.fragments.comment)}
      ${toInlineFragment(Reactions.fragments.comment)}
    }
  `,
  user: gql`
    fragment _ on User {
      id
    }
  `,
}

Comment.defaultProps = {
  highlighted: false,
  child: false,
  sortCommentsOnCache: undefined,
  isThreadExpert: false,
}

Comment.propTypes = {
  user: propType(Comment.fragments.user).isRequired,
  session: propType(Comment.fragments.session).isRequired,
  comment: propType(Comment.fragments.comment).isRequired,
  historyReplace: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
  sortCommentsOnCache: PropTypes.func,
  highlighted: PropTypes.bool,
  child: PropTypes.bool,
  isThreadExpert: PropTypes.bool,
}

export default Comment
