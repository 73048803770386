import React, { useState } from 'react'
import IntroBox from 'modules/collector/components/IntroBox'
import styled from '@emotion/styled'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { useCourseContext } from 'modules/course/components/CourseContext/CourseContext'
import Page from 'modules/collector/components/Page'
import Tag from '@unowmooc/tags'
import I18nProvider from '@unowmooc/i18n'
import { ButtonSkin } from '@unowmooc/buttons'
import { StreamlineIcon } from '@unowmooc/react-ui-kit'
import { animateScrollTo } from 'utils/scroll'
import PropTypes from 'prop-types'
import Collector from 'modules/collector/components/Collector'

const StyledStreamlineIcon = styled(StreamlineIcon)`
  color: ${({ theme: { colors }, show }) => (show ? colors.unowGreen : 'transparent')};
`

const StyledTag = styled(Tag, { shouldForwardProp: () => true })`
  margin-left: 6px;
`

const contexts = {
  BIC: 'bic',
  BFC: 'bfc',
}

const getConfig = (courseContext, context) => {
  switch (context) {
    case contexts.BIC:
      return { forms: courseContext.bic, hasAnswered: courseContext.hasBicAnswered }
    case contexts.BFC:
      return { forms: courseContext.bfc, hasAnswered: courseContext.hasBfcAnswered }
    default:
      throw new Error(`Le contexte ${context} n'est pas valide.`)
  }
}

const SkillsForm = ({ context, hasHalfSummary }) => {
  const [currentSurvey, updateCurrentSurvey] = useState(null)
  const {
    participantId,
    mainAnimator,
    refetchQuery,
    courseIdentifier,
    sessionIdentifier,
    slugCourse,
    sessionId,
    ...courseContext
  } = useCourseContext()
  const { forms, hasAnswered } = getConfig(courseContext, context)

  if (currentSurvey !== null) {
    const currentSurveyIsLast = currentSurvey && currentSurvey.type === forms?.[forms.length - 1].type

    return (
      <Collector
        formId={currentSurvey.id}
        formContext={context}
        participantId={participantId}
        onSubmit={async () => {
          animateScrollTo(document.body)
          await refetchQuery()
          updateCurrentSurvey(null)
        }}
      >
        <Page
          submitButtonLabel={
            currentSurveyIsLast ? (
              <FormattedMessage id="survey.last_button_label_submit" />
            ) : (
              <FormattedMessage id="survey.default_button_label.submit" />
            )
          }
        />
      </Collector>
    )
  }

  const atLeastOneSurveyAnswered = forms.some(s => s.answered)

  return (
    <IntroBox
      buttonLabel={
        atLeastOneSurveyAnswered ? (
          <FormattedMessage id={`survey.intro_box.${context}.half_button_label`} />
        ) : (
          <FormattedMessage id={`survey.intro_box.${context}.start_button_label`} />
        )
      }
      showButton={!hasAnswered}
      buttonClick={() => {
        const surveyNotAnswered = forms.find(survey => !survey.answered) ?? null

        updateCurrentSurvey(surveyNotAnswered)
      }}
    >
      <IntroBox.Steps>
        {forms.map((survey, index) => (
          <IntroBox.Step>
            {atLeastOneSurveyAnswered && <StyledStreamlineIcon icon="check" show={survey.answered} />}
            <FormattedHTMLMessage id={`survey.intro_box.${context}.${survey.type}`} values={{ index: index + 1 }} />
            &nbsp;
            <FormattedHTMLMessage
              id="survey.intro_box.question_duration"
              values={{ numberOfQuestions: survey.questionCount, duration: survey.duration }}
            />
            {survey.answered && (
              <StyledTag small theme="lightGreen">
                <FormattedMessage id="survey.intro_box.step_answered" />
              </StyledTag>
            )}
          </IntroBox.Step>
        ))}
      </IntroBox.Steps>
      <IntroBox.Summary>
        {hasAnswered && <FormattedHTMLMessage id={`survey.intro_box.${context}.thanks_summary`} />}
        {!hasAnswered && atLeastOneSurveyAnswered && hasHalfSummary && (
          <FormattedHTMLMessage
            id={`survey.intro_box.${context}.half_summary`}
            values={{ numberOfQuestions: forms?.[1].questionCount }}
          />
        )}
        {!atLeastOneSurveyAnswered && (
          <FormattedHTMLMessage
            id={`survey.intro_box.${context}.start_summary`}
            values={{ gender: mainAnimator?.gender }}
          />
        )}
        {hasAnswered && (
          <ButtonSkin
            tag={Link}
            to={I18nProvider.getLinkRoute('/courses/{slugCourse}/{sessionId}/skills', {
              slugCourse,
              sessionId,
            })}
            data-tracking-id="restitution.click_on_show_skills_button_link"
            data-tracking-values={JSON.stringify({
              courseIdentifier,
              sessionIdentifier,
              context,
            })}
          >
            <FormattedMessage id={`survey.intro_box.${context}.cta_show_skills_link`} />
          </ButtonSkin>
        )}
      </IntroBox.Summary>
    </IntroBox>
  )
}

SkillsForm.contexts = contexts

SkillsForm.defaultProps = {
  hasHalfSummary: false,
}

SkillsForm.propTypes = {
  context: PropTypes.oneOf(Object.keys(SkillsForm.contexts)).isRequired,
  hasHalfSummary: PropTypes.bool,
}

export default SkillsForm
