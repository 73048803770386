import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import _ from 'lodash'
import { Box, Flex } from 'reflexbox'
import { Form, FormState, Field, FieldArray } from '@unowmooc/form'
import { InputText } from '@unowmooc/inputs'
import I18nProvider from '@unowmooc/i18n'
import { ButtonI18n } from '@unowmooc/buttons'
import { isRequired, isEmail } from 'utils/validators'

import Card from 'components/Card'
import BoxSessionInfo from '../../BoxSessionInfo'

const CrearErrors = () => {
  const { clearErrors } = useFormContext()

  useEffect(() => {
    setTimeout(() => {
      clearErrors()
    }, 0)
  }, [])

  return null
}

const Manual = ({ history }) => {
  // const [defaultValue, setDefaultValue] = useState(false)
  const handleSubmit = () => {
    history.push(I18nProvider.getLinkRoute('/supervisor/registered_users/validation/manual'))
  }

  return (
    <Form
      name="userRegistration"
      defaultValues={{ collaborators: [{ cpf: false }] }}
      form={{ mode: 'onChange' }}
      persist
      onSubmit={handleSubmit}
    >
      <BoxSessionInfo />
      <Box>
        <FieldArray name="collaborators" minLength={{ value: 1 }}>
          {({ fields, append, remove }) => (
            <>
              <CrearErrors />
              {_.map(fields, (item, index) => (
                <Card key={item.id} padding="30px" marginTop="30px">
                  <Flex justifyContent="space-between">
                    <h3>
                      <FormattedMessage
                        id="supervisor.registration.add.form.collaborator"
                        values={{ number: index + 1 }}
                      />
                    </h3>
                    <ButtonI18n
                      variant="nude"
                      size="square"
                      color="bittersweet"
                      icon="bin"
                      onClick={() => remove(index)}
                    />
                  </Flex>

                  <Flex>
                    <Box width="100%">
                      <Field
                        name={`collaborators[${index}].lastName`}
                        label={<FormattedMessage id="supervisor.registration.add.form.lastName" />}
                        required={isRequired()}
                      >
                        <InputText />
                      </Field>
                    </Box>
                    <Box minWidth="22px" height="22px" />
                    <Box width="100%">
                      <Field
                        name={`collaborators[${index}].firstName`}
                        label={<FormattedMessage id="supervisor.registration.add.form.firstName" />}
                        required={isRequired()}
                      >
                        <InputText />
                      </Field>
                    </Box>
                  </Flex>
                  <Flex>
                    <Box width="100%">
                      <Field
                        name={`collaborators[${index}].email`}
                        label={<FormattedMessage id="supervisor.registration.add.form.email" />}
                        required={isRequired()}
                        validate={{
                          isEmail,
                        }}
                      >
                        <InputText />
                      </Field>
                    </Box>
                    <Box minWidth="22px" height="22px" />
                    <Box width="100%">
                      <Field
                        name={`collaborators[${index}].phone`}
                        label={<FormattedMessage id="supervisor.registration.add.form.phone" />}
                      >
                        <InputText placeholder="+33123456789" />
                      </Field>
                    </Box>
                  </Flex>
                </Card>
              ))}
              <ButtonI18n
                variant="link"
                i18nKey="supervisor.registration.add.buttons.add"
                icon="plus"
                reverse
                marginTop="30px"
                onClick={() => append({ cpf: false })}
              />
            </>
          )}
        </FieldArray>
      </Box>
      <Flex marginTop="30px" justifyContent="center">
        <FormState>
          {({ isValid }) => (
            <ButtonI18n
              variant="primary"
              i18nKey="supervisor.registration.add.buttons.submit"
              type="submit"
              disabled={!isValid}
            />
          )}
        </FormState>
      </Flex>
    </Form>
  )
}

Manual.propTypes = {
  history: PropTypes.shape().isRequired,
}

export default withRouter(Manual)
