import config from 'config'

export function getCatalogUrl() {
  return `${config.env.WEBSITE_URL}/formations`
}

export function getDownloadCatalogUrl() {
  return `${config.env.WEBSITE_URL}/catalogue-formations-professionnelles-en-ligne-unow`
}

export function getCatalogCourseUrl(slug) {
  return `${getCatalogUrl()}/${slug}`
}

export default { getCatalogUrl, getDownloadCatalogUrl, getCatalogCourseUrl }
