import React from 'react'
import PropTypes from 'prop-types'
import { propType, filter } from 'graphql-anywhere'
import _ from 'lodash'
import { toInlineFragment } from 'fraql'
import gql from 'graphql-tag'
import { Switch, Route, Redirect } from 'react-router-dom'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'
import I18nProvider from '@unowmooc/i18n'
import { FormattedMessage } from 'react-intl'
import Helmet from 'components/HelmetIntl'
import PageQuery from 'components/PageQuery'
import { hasBadgeForModule } from 'modules/progression/utils'
import { QuizProvider } from 'modules/quiz/provider'
import { useCourseContext } from 'modules/course/components/CourseContext/CourseContext'
import Breadcrumb from 'modules/course/components/Breadcrumb'
import ModuleTitle from './ModuleTitle'
import IntroPage from './intro'
import QuestionsPage from './questions'
import ResultsPage from './results'
import ShowSectionAccessError from '../ShowSectionAccessError'

const StyledModuleTitle = styled(ModuleTitle)`
  margin-bottom: 20px;
`

const Title = styled.h1`
  margin-bottom: 20px;

  ${mq.sm(css`
    margin-bottom: 10px;
  `)};
`

const QuizPage = ({ matchUrl, session, module, module: { isZero }, participant }) => {
  const { getErrorsShowModule } = useCourseContext()

  const errorsShowModule = getErrorsShowModule({ isModuleZero: isZero })
  if (errorsShowModule.length > 0) {
    return <ShowSectionAccessError errors={errorsShowModule} />
  }

  const hasBadge = hasBadgeForModule(module, _.get(participant, 'badges', []))
  const moduleLabel = `${I18nProvider.formatMessage({
    id: `sections.module_${module.bonus ? 'bonus_title' : 'title'}`,
    values: { number: module.isZero ? 0 : module.publicPosition + 1 },
  })} ${module.title}`

  return (
    <>
      <Breadcrumb moduleLabel={moduleLabel} />
      <QuizProvider moduleId={module.id} key={module.id} questionCounter={module.nbQuizQuestions}>
        <Route
          exact
          path={matchUrl}
          render={() => (
            <Redirect to={`${matchUrl}/${I18nProvider.translatePath(hasBadge ? 'results' : 'introduction')}`} />
          )}
        />

        <Helmet title="page_titles.quiz.title" values={{ moduleNumber: module.publicPosition + 1 }} />
        <StyledModuleTitle module={filter(ModuleTitle.fragments.module, module)} />
        <Title>
          <FormattedMessage id="quiz.title" values={{ moduleNumber: module.publicPosition + 1 }} />
        </Title>

        <Switch>
          <Route
            path={`${matchUrl}/${I18nProvider.translatePath('introduction')}`}
            render={props => (
              <IntroPage
                {...props}
                session={filter(IntroPage.fragments.session, session)}
                module={filter(IntroPage.fragments.module, module)}
              />
            )}
          />
          <Route path={`${matchUrl}/${I18nProvider.translatePath('questions')}`} component={QuestionsPage} />
          <Route
            path={`${matchUrl}/${I18nProvider.translatePath('results')}`}
            render={props => (
              <ResultsPage
                {...props}
                hasBadge={hasBadge}
                session={filter(ResultsPage.fragments.session, session)}
                module={filter(ResultsPage.fragments.module, module)}
              />
            )}
          />
        </Switch>
      </QuizProvider>
    </>
  )
}

QuizPage.propTypes = {
  matchUrl: PropTypes.string.isRequired,
  session: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  participant: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  module: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    bonus: PropTypes.bool.isRequired,
    isZero: PropTypes.bool.isRequired,
    publicPosition: PropTypes.number.isRequired,
    nbQuizQuestions: PropTypes.number.isRequired,
  }).isRequired,
}

const QuizPageContainer = ({
  session,
  match: {
    url,
    params: { idModule },
  },
}) => {
  const { participantId } = useCourseContext()

  return (
    <PageQuery
      query={gql`
      query QuizPage($idModule: ID!, $idParticipant: ID, $includeParticipant: Boolean!) {
        module(id: $idModule) {
          id
          title
          isZero
          publicPosition
          nbQuizQuestions
          ${toInlineFragment(ModuleTitle.fragments.module)}
          ${toInlineFragment(IntroPage.fragments.module)}
          ${toInlineFragment(ResultsPage.fragments.module)}
        }
        participant: participant (id: $idParticipant) @include(if: $includeParticipant) {
          id
          badges {
            id
            module {
              id
            }
          }
        }
      }
    `}
      fetchPolicy="network-only"
      variables={{
        idModule,
        idParticipant: participantId,
        includeParticipant: !!participantId,
      }}
      render={renderProps => <QuizPage {...renderProps} session={session} matchUrl={url} />}
    />
  )
}

QuizPageContainer.fragments = {
  session: gql`
    fragment _ on Session {
      id
      ${toInlineFragment(IntroPage.fragments.session)}
      ${toInlineFragment(ResultsPage.fragments.session)}
    }
  `,
}

QuizPageContainer.propTypes = {
  session: propType(QuizPageContainer.fragments.session).isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      idModule: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export default QuizPageContainer
