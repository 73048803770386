import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, Redirect } from 'react-router-dom'
import I18nProvider from '@unowmooc/i18n'
import ListPage from './list'
import ViewPage from './view'

const statuses = ['in_progress', 'planned', 'finished', 'all']

const SessionsPage = ({ match: { url } }) => (
  <Switch>
    <Route exact path={url} render={() => <Redirect to={`${url}${I18nProvider.getLinkRoute('/in_progress')}`} />} />

    {statuses.map(status => (
      <Route
        exact
        path={`${url}/${I18nProvider.translatePath(status)}`}
        render={props => <ListPage status={status} statuses={statuses} {...props} />}
        key={status}
      />
    ))}

    <Route path={`${url}/:idSession`} component={ViewPage} />
  </Switch>
)

SessionsPage.propTypes = {
  match: PropTypes.shape().isRequired,
}

export default SessionsPage
