import React from 'react'
import useMe from 'hooks/useMe'
import { Col, Row } from 'react-grid-system'
import styled from '@emotion/styled'
import BasicContent from 'components/BasicContent'

// eslint-disable-next-line prettier/prettier
const LMS_ALLOWED_URLS = [
  /^\/formations/,
  /^\/courses/,
]

const RowWrapper = styled(Row)`
  padding-top: 100px;
  text-align: center;
`

const LmsAuthorizationChecker = ({ children }) => {
  const href = `${window.location.pathname}${window.location.search}${window.location.hash}`

  const {
    me: { isLms },
  } = useMe()

  if (isLms && !LMS_ALLOWED_URLS.some(regexp => regexp.test(href))) {
    return (
      <RowWrapper>
        <Col xs={12}>
          <BasicContent i18nTitle="errors.unauthorized" />
        </Col>
      </RowWrapper>
    )
  }

  return children
}

export default LmsAuthorizationChecker
