import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { color } from 'styled-system'
import { FormattedMessage } from 'react-intl'

import { propType } from 'graphql-anywhere'
import gql from 'graphql-tag'

import Oval from './components/Oval'
import { getAdvancementColor } from '../../utils/advancement'
import { ADVANCEMENT_STATUS } from '../../constants'

const Span = styled.span`
  ${color}
`

const ParticipantAdvancement = ({ advancement }) => {
  const isOnFinalExam =
    _.get(advancement, 'status') === ADVANCEMENT_STATUS.certified || _.get(advancement, 'nbFailedExamAttempt') > 0
  const lastObtainedBadgeModulePublicPosition = _.get(advancement, 'lastObtainedBadge.module.publicPosition', -1) + 1
  const hasNotStarted = !lastObtainedBadgeModulePublicPosition && !isOnFinalExam

  return hasNotStarted ? (
    <Span color="lightGray">
      <FormattedMessage
        id="participation.advancement.module"
        values={{ number: lastObtainedBadgeModulePublicPosition }}
      />
    </Span>
  ) : (
    <span>
      <Oval backgroundColor={getAdvancementColor(advancement)} />
      {isOnFinalExam ? (
        <FormattedMessage id="participation.advancement.final_exam" />
      ) : (
        <Span color="gray">
          <FormattedMessage
            id="participation.advancement.module"
            values={{ number: lastObtainedBadgeModulePublicPosition }}
          />
        </Span>
      )}
    </span>
  )
}

ParticipantAdvancement.fragment = gql`
  fragment ParticipantAdvancementFragment on Advancement {
    status
    nbRemainedExamAttempt
    nbFailedExamAttempt
    lastObtainedBadge {
      id
      module {
        id
        publicPosition
      }
    }
  }
`

ParticipantAdvancement.propTypes = {
  advancement: propType(ParticipantAdvancement.fragment).isRequired,
  user: PropTypes.shape({ gender: PropTypes.string }).isRequired,
}

export default ParticipantAdvancement
