import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'
import { FormattedMessage } from 'react-intl'
import NavItem from '../NavItem'
import Arrow from '../Arrow'

const PreviousItem = styled(NavItem)`
  padding: 20px 100px 20px 10px;

  ${mq.xs(css`
    padding-right: 50px;
  `)};
`

const PreviousText = styled.strong`
  ${mq.sm(css`
    display: none;
  `)};
`

const PreviousLinkContent = ({ disabled }) => (
  <PreviousItem disabled={disabled}>
    <Arrow icon="left" />
    <PreviousText>
      <FormattedMessage id="sections.navbar.previous" />
    </PreviousText>
  </PreviousItem>
)

PreviousLinkContent.defaultProps = {
  disabled: false,
}

PreviousLinkContent.propTypes = {
  disabled: PropTypes.bool,
}

export default PreviousLinkContent
