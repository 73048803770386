import React from 'react'
import { FormattedHTMLMessage } from 'react-intl'

const Onboarding = () => (
  <>
    <p>
      <FormattedHTMLMessage id="guide.slides.goals.content.paragraph_1" />
    </p>
    <h3>
      <FormattedHTMLMessage id="guide.slides.goals.content.heading_1" />
    </h3>
    <p>
      <FormattedHTMLMessage id="guide.slides.goals.content.paragraph_2" />
    </p>
    <h3>
      <FormattedHTMLMessage id="guide.slides.goals.content.heading_2" />
    </h3>
    <p>
      <FormattedHTMLMessage id="guide.slides.goals.content.paragraph_3" />
    </p>
    <p>
      <FormattedHTMLMessage id="guide.slides.goals.content.paragraph_4" />
    </p>
  </>
)

export default Onboarding
