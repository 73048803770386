import React from 'react'
import PropTypes from 'prop-types'
import { propType, filter } from 'graphql-anywhere'
import gql from 'graphql-tag'
import { Visible } from 'react-grid-system'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'
import I18nProvider from '@unowmooc/i18n'
import GoBackLink from 'components/GoBackLink'
import TagSessionType from 'components/TagSessionType'
import TagSessionStatus from 'components/TagSessionStatus'
import FormattedDateInterval from 'components/FormattedDateInterval'
import InProgressCounters from './InProgressCounters'
import FinishedCounters from './FinishedCounters'

const Top = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 22px;

  ${mq.sm(css`
    display: block;
  `)}
`

const Title = styled.h3``

const Tags = styled.div`
  > * {
    margin-right: 12px;

    &:last-child {
      margin-right: 0;
    }
  }

  ${mq.sm(css`
    margin-bottom: 20px;
  `)}
`

const Dates = styled.p`
  margin-bottom: 36px;
  font-size: 15px;

  ${mq.sm(css`
    font-size: 14px;
  `)}
`

const Header = ({ session, session: { course, startAt, endAt, type, runningStatus, currentWeek }, className }) => (
  <div className={className}>
    <GoBackLink to={I18nProvider.getLinkRoute('/expert/sessions')} i18nLabel="session.go_back_all_session" />
    <Top>
      <Visible xs sm>
        <Tags>
          <TagSessionType type={type} small />
          <TagSessionStatus status={runningStatus} currentWeek={currentWeek} small />
        </Tags>
      </Visible>
      <Title>{course.title}</Title>
      <Visible md lg xl>
        <Tags>
          <TagSessionType type={type} />
          <TagSessionStatus status={runningStatus} currentWeek={currentWeek} />
        </Tags>
      </Visible>
    </Top>

    <Dates>
      <FormattedDateInterval id="commons.session_from_to" from={startAt} to={endAt} />
    </Dates>

    {runningStatus === 'finished' ? (
      <FinishedCounters session={filter(FinishedCounters.fragment, session)} />
    ) : (
      <InProgressCounters session={filter(InProgressCounters.fragment, session)} />
    )}
  </div>
)

Header.fragment = gql`
  fragment SessionHeader on Session {
    id
    startAt
    endAt
    type
    runningStatus
    currentWeek
    course {
      id
      title
    }
    ...InProgressCountersFragment
    ...FinishedCountersFragment
  }
  ${InProgressCounters.fragment}
  ${FinishedCounters.fragment}
`

Header.defaultProps = {
  className: undefined,
}

Header.propTypes = {
  session: propType(Header.fragment).isRequired,
  className: PropTypes.string,
}

export default Header
