import moment from 'moment'
import _ from 'lodash'
import { isAdmin, isUserAnimatorOfTheSession } from 'modules/user/user.utils'
import { getCourseIndexById } from 'modules/course/course.utils'
import { COURSE_TYPE } from 'modules/course/course.constants'
import { getPackIndexById } from 'modules/project/project.selectors'
import {
  getCourseFromSession,
  getCourseIdFromSession,
  getStartAtFromSession,
  getThemeIdFromSession,
  getPacksFromSession,
  getPackIdsFromSession,
  getSessionIdentifier,
  getSessionModules,
  getStartAtFromSessionModule,
  getTypeFromSession,
} from './session.selectors'

const sessionContainsPackId = (session, packId) => {
  const packIdsSession = getPackIdsFromSession(session)

  return packIdsSession.includes(packId)
}

export const getUniqueCourseFromSessions = (sessions = []) =>
  sessions.reduce((acc, value) => {
    const course = getCourseFromSession(value)
    const courseIsUnique = getCourseIndexById(acc, course.id) < 0

    if (courseIsUnique) {
      acc.push(course)
    }

    return acc
  }, [])

export const getUniquePacksFromSessions = (sessions = []) =>
  sessions.reduce((acc, value) => {
    const packs = getPacksFromSession(value)

    packs.forEach(pack => {
      const packIsUnique = getPackIndexById(acc, pack.id) < 0

      if (packIsUnique) {
        acc.push(pack)
      }
    })

    return acc
  }, [])

export const getSessionsByCourseId = (sessions, courseId) =>
  sessions.reduce((acc, value) => {
    const sessionCourseId = getCourseIdFromSession(value)

    if (sessionCourseId === courseId) {
      acc.push(value)
    }

    return acc
  }, [])

export const getSessionsByPackId = (sessions, packId) =>
  sessions.reduce((acc, value) => {
    const containsPackId = sessionContainsPackId(value, packId)

    if (containsPackId) {
      acc.push(value)
    }

    return acc
  }, [])

/**
 * Months from 0 to 11
 * 0 is January
 * 11 is December
 *  */
export const getSessionsByMonth = (sessions, month) => {
  if (month >= 0 && month <= 11) {
    // Set start of the month
    const currentDate = moment().startOf('month')
    const monthToAdd = month >= currentDate.month() ? month - currentDate.month() : 12 - currentDate.month() + month

    const minDate = currentDate.clone().add(monthToAdd, 'months')
    const maxDate = currentDate.clone().add(monthToAdd + 1, 'months')

    return sessions.reduce((acc, value) => {
      const startAt = getStartAtFromSession(value)

      if (moment(startAt).isBetween(minDate, maxDate)) {
        acc.push(value)
      }

      return acc
    }, [])
  }

  return []
}

export const getSessionsByThemeId = (sessions, themeId) =>
  sessions.reduce((acc, value) => {
    const sessionThemeId = getThemeIdFromSession(value)

    if (sessionThemeId === themeId) {
      acc.push(value)
    }

    return acc
  }, [])

export const getPacksBySessionId = (sessions, sessionId) => {
  const sessionSelected = _.find(sessions, session => session.id === sessionId)

  if (sessionSelected) {
    return getPacksFromSession(sessionSelected)
  }

  return []
}

export const getSessionLabelSelector = (session, sessions = []) => {
  const label = moment(session.startAt).format('LL')

  if (sessions.find(s => s.id !== session.id && s.startAt === session.startAt)) {
    return `${label} (${session.identifier})`
  }

  return label
}

export const getSessionCode = session => (getSessionIdentifier(session) || '').toUpperCase()

export const isTagIdentifierDisplay = (session, user) => isAdmin(user) || isUserAnimatorOfTheSession(user, session)

export const getSessionModuleStartAtByPosition = (session, position) => {
  const sessionModules = getSessionModules(session)

  const sessionModule = (sessionModules || [])[position]

  return getStartAtFromSessionModule(sessionModule)
}

export const isSessionModuleStarted = (session, position) => {
  const moduleStartAt = getSessionModuleStartAtByPosition(session, position)

  return moment(moduleStartAt).isBefore(moment())
}

export const isSessionStarted = session => moment(getStartAtFromSession(session)).isBefore(moment())

export const isCurrentModule = (session, position) => {
  const module = session.modules[position]

  return module && module.current
}

export const isClosingPeriod = closingPeriod => !!closingPeriod

export const showDatesOnProgressTimeline = session => getTypeFromSession(session) === COURSE_TYPE.inter

export const getSkillsFinalForm = (sessionForms, authenticatedParticipantFormAdvancementStates) => {
  const bfc = [
    {
      type: 'specific',
      ...(sessionForms.specificSkillsFinalForm ?? {}),
      exists: !!sessionForms.specificSkillsFinalForm?.id,
      answered: !!authenticatedParticipantFormAdvancementStates?.answeredSpecificSkillsFinalForm,
    },
    {
      type: 'common',
      ...(sessionForms.commonSkillsFinalForm ?? {}),
      exists: !!sessionForms.commonSkillsFinalForm?.id,
      answered: !!authenticatedParticipantFormAdvancementStates?.answeredCommonSkillsFinalForm,
    },
  ].filter(survey => survey.exists)

  const hasBfc = bfc.length > 0
  const hasBfcAnswered = bfc.every(survey => survey.answered)
  const hasSpecificBfc = bfc.some(survey => survey.type === 'specific')

  return { bfc, hasBfc, hasBfcAnswered, hasSpecificBfc }
}
