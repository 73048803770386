import Cookies from 'js-cookie'

const MESSAGE_TYPE_NAME = 'unow_data_update'

const builder = ({
  sessionId,
  advancementStatus,
  lastFinalExamQuizAttemptScore,
  lastFinalExamQuizAttemptStatus,
  timeSpentHours,
}) => ({
  sendDataToLmsConnector: () => {
    const parentWindow = window?.parent

    if (!parentWindow) {
      return
    }

    const authDataParsed = JSON.parse(Cookies.get('unow_auth_data'))

    const lmsRelatedSessionId = authDataParsed?.lmsRelatedSessionId

    // Si on est pas sur la même session que sur celle connecté (par exemple si on bidouillé avec les urls)
    // on ne remonte pas les infos LMS pour éviter de les fausser
    if (lmsRelatedSessionId !== parseInt(sessionId, 10)) {
      return
    }

    const lmsAllowedOrigin = authDataParsed?.lmsAllowedOrigin

    const messageToSend = {
      type: MESSAGE_TYPE_NAME,
      data: {
        advancementStatus,
        lastFinalExamQuizAttemptScore,
        lastFinalExamQuizAttemptStatus,
        timeSpentHours,
      },
    }

    parentWindow.postMessage(messageToSend, lmsAllowedOrigin)
  },
})

export default builder
