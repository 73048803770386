import React, { Component } from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import _ from 'lodash'
import hoistNonReactStatic from 'hoist-non-react-statics'

export const withGridGraphqlQuery = WrappedComponent => {
  class QueryComponent extends Component {
    constructor(props) {
      super(props)

      this.state = { query: this.query() }
    }

    componentDidUpdate(prevProps) {
      const { queryName, queryFragment } = this.props

      if (prevProps.queryName !== queryName || prevProps.queryFragment !== queryFragment) {
        // eslint-disable-next-line
        this.setState(() => ({ query: this.query() }))
      }
    }

    query = () => {
      const { queryName, queryFragment } = this.props

      return gql`query ${queryName}($limit: Int!, $offset: Int!, $filters: Json, $search: String, $sort: [String]) {
      dataGridRows: ${queryName}(limit: $limit, offset: $offset, filters: $filters, search: $search, sort: $sort) {
        _meta {
          total
        }
        items {
          ...${_.get(queryFragment, 'definitions.0.name.value')}
        }
      }
    }
    ${queryFragment}
    `
    }

    render() {
      const { query } = this.state

      return <WrappedComponent query={query} {...this.props} />
    }
  }

  QueryComponent.propTypes = {
    queryFragment: PropTypes.shape().isRequired,
    queryName: PropTypes.string.isRequired,
  }

  hoistNonReactStatic(QueryComponent, WrappedComponent)

  QueryComponent.displayName = `withGridGraphqlQuery(${
    WrappedComponent.displayName || WrappedComponent.name || 'Component'
  })`

  return QueryComponent
}
