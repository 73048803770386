import keyMirror from 'keymirror'

export const COURSE_TYPE = keyMirror({
  inter: null,
  intra: null,
  mooc: null,
})

export const MODULE_TYPE_CODES = keyMirror({
  main: null,
  bonus: null,
  certification: null,
})
