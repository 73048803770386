import React, { createContext, useContext, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import PageLoader from 'components/PageLoader'

const CatalogContext = createContext({
  courses: [],
})

const COURSES_CATALOG = gql`
  query coursesCatalog($filters: Json) {
    courses(filters: $filters) {
      items {
        id
        title
        tagline
        slug
        cpfCode
        mainTheme {
          id
          color
          label
        }
        themes {
          id
          label
          color
        }
        hero {
          id
          url
          policy
          signature
          secureUrl
        }
        thumbnail {
          id
          url
          policy
          signature
          secureUrl
        }
      }
    }
  }
`

const INITIALIZE = 'INITIALIZE'
const TOGGLE_CPF_ELIGIBLE = 'TOGGLE_CPF_ELIGIBLE'
const RESET_THEME = 'RESET_THEME'
const TOGGLE_THEME = 'TOGGLE_THEME'

const initialState = { loaded: false, cpfEligible: false, courses: [], themes: [], selectedThemeIds: [] }

const mergeCoursesThemes = courses =>
  courses.map(course => ({
    ...course,
    themes: [course.mainTheme, ...course.themes.filter(theme => theme.id !== course.mainTheme.id)],
  }))

const filterThemeReducer = (courses, cpfEligible) =>
  courses.reduce((themes, course) => {
    if (cpfEligible && !course.cpfCode) {
      return themes
    }

    const nextAcc = [...(themes ?? [])]
    course.themes.forEach(theme => {
      const themeIndex = nextAcc.findIndex(findIndexTheme => findIndexTheme.id === theme.id)

      if (themeIndex > -1) {
        // eslint-disable-next-line no-plusplus
        nextAcc[themeIndex].count++
      } else {
        nextAcc.push({
          ...theme,
          count: 1,
        })
      }
    })

    return nextAcc
  }, [])

const reducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE: {
      const nextCourses = mergeCoursesThemes(action.payload)

      return {
        ...state,
        loaded: true,
        cpfEligible: false,
        selectedThemeIds: [],
        courses: nextCourses,
        themes: filterThemeReducer(nextCourses),
      }
    }

    case TOGGLE_CPF_ELIGIBLE: {
      const nextCpfEligibleState = !state.cpfEligible
      return {
        ...state,
        cpfEligible: nextCpfEligibleState,
        themes: filterThemeReducer(state.courses, nextCpfEligibleState),
      }
    }

    case RESET_THEME: {
      return {
        ...state,
        selectedThemeIds: [],
      }
    }

    case TOGGLE_THEME: {
      const nextSelectedThemeIdsState =
        state.selectedThemeIds.indexOf(action.payload.themeId) > -1
          ? state.selectedThemeIds.filter(tid => tid !== action.payload.themeId)
          : [...state.selectedThemeIds, action.payload.themeId]

      return {
        ...state,
        selectedThemeIds: nextSelectedThemeIdsState,
      }
    }

    default:
      return state
  }
}

export const CatalogContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { loading, data } = useQuery(COURSES_CATALOG, {
    variables: { filters: JSON.stringify({ showInCatalog: true }) },
  })

  useEffect(() => {
    if (data) {
      dispatch({ type: INITIALIZE, payload: data.courses.items })
    }
  }, [loading])

  const toggleCpfEligible = () => {
    dispatch({ type: TOGGLE_CPF_ELIGIBLE })
  }

  const toggleTheme = theme => {
    dispatch({ type: TOGGLE_THEME, payload: { themeId: theme?.id ?? theme } })
  }

  const resetTheme = () => {
    dispatch({ type: RESET_THEME })
  }

  if (!state.loaded) {
    return <PageLoader />
  }

  const providerValues = {
    cpfEligible: state.cpfEligible,
    themes: state.themes,
    selectedThemeIds: state.selectedThemeIds,
  }

  providerValues.courses = state.courses.filter(course => {
    let isValid = true

    if (isValid && state.cpfEligible) {
      isValid = !!course.cpfCode
    }

    if (isValid && state.selectedThemeIds.length > 0) {
      isValid = course.themes.filter(theme => state.selectedThemeIds.includes(theme.id)).length > 0
    }

    return isValid
  })

  return (
    <CatalogContext.Provider value={{ ...providerValues, loading, toggleTheme, resetTheme, toggleCpfEligible }}>
      {children}
    </CatalogContext.Provider>
  )
}

CatalogContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const useCatalogContext = () => useContext(CatalogContext)
