import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const ContentBox = styled.p``

const Content = ({ text }) => <ContentBox>{text}</ContentBox>

Content.propTypes = {
  text: PropTypes.string.isRequired,
}

export default Content
