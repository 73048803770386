import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'
import I18nProvider from '@unowmooc/i18n'
import ConversationList from 'modules/conversations/components/List'
import ConversationDetail from 'modules/conversations/components/Detail'
import styled from '@emotion/styled'
import { mq } from '@unowmooc/themes'
import { css } from '@emotion/core'

const PageWrapper = styled.div`
  ${mq.sm(css`
    margin: 20px -20px;
  `)};
`

const ConversationRouter = ({ match: { url } }) => (
  <Switch>
    <Route
      exact
      path={`${url}${I18nProvider.translatePath('/:idConversation')}`}
      render={props => (
        <PageWrapper>
          <ConversationDetail {...props} backUrl={url} />
        </PageWrapper>
      )}
    />
    <Route
      path={url}
      render={props => (
        <PageWrapper>
          <ConversationList {...props} />
        </PageWrapper>
      )}
    />
  </Switch>
)

ConversationRouter.propTypes = {
  match: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
}

export default ConversationRouter
