import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { rgba } from 'styles-resources'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import Field, { ErrorMessage, ErrorMessageIcon } from '../Field'
import RadioMatrix from './RadioMatrix'
import { useCollector } from '../../provider'

const TableStyled = styled.table`
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;

  ${mq.sm(css`
    display: block;

    tr,
    td {
      display: block;
    }
  `)};

  thead td {
    text-align: center;
    vertical-align: bottom;
    margin-bottom: 6px;
    font-size: 14px;
  }

  th {
    font-weight: normal;
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    width: 300px;
    padding: 6px;
    ${mq.sm(css`
      font-family: Nunito;
      display: inline-block;
      color: ${({ theme: { colors } }) => colors.woodsmoke};
      font-size: 18px;
      font-weight: bold;
      line-height: 28px;
      margin-bottom: 12px;
      width: auto;
    `)};
  }

  tbody {
    tr {
      &:nth-child(odd) {
        background-color: ${({ theme: { colors } }) => colors.whiteLilac};

        ${mq.sm(css`
          background-color: ${({ theme: { colors } }) => colors.white};
        `)};
      }
      ${mq.sm(css`
        margin-bottom: 28px;
        background: ${({ theme: { colors } }) => colors.white};
        border-radius: 3px;
        padding: 6px;
        box-shadow: 0 13px 46px 0 ${({ theme: { colors } }) => rgba(colors.mischka, 0.5)};
      `)};
    }

    td {
      padding: 3px;
      height: 34px;
      text-align: center;
      width: auto;

      ${mq.sm(css`
        height: auto;
      `)};
    }
  }
`

const TheadStyled = styled.thead`
  ${mq.sm(css`
    display: none;
  `)}
`

const TheadTdLabel = styled.td`
  text-align: center;
  vertical-align: bottom;
  padding-bottom: 4px;
  color: ${({ theme: { colors } }) => colors.woodsmoke};
`

const MatrixScale = ({ fieldName, required, id: questionId, subquestions }) => {
  const { errors } = useFormContext()
  const { currentPage } = useCollector()

  const componentHasError =
    Object.keys(errors).filter(key => key.indexOf(`page_${currentPage}_question_${questionId}`) === 0).length > 0

  return (
    <>
      <TableStyled cellSpacing={0}>
        <TheadStyled>
          <tr>
            <th>&nbsp;</th>
            {subquestions?.[0].choices.map(choice => (
              <TheadTdLabel>{choice.label}</TheadTdLabel>
            ))}
          </tr>
        </TheadStyled>
        <tbody>
          {subquestions.map(subquestion => (
            <tr>
              <th>{subquestion.title}</th>
              <Field
                required={required}
                displayErrorMessage={false}
                name={`${fieldName}_subquestion_${subquestion.id}`}
              >
                <RadioMatrix options={subquestion.choices} />
              </Field>
            </tr>
          ))}
        </tbody>
      </TableStyled>
      {componentHasError && (
        <ErrorMessage>
          <ErrorMessageIcon icon="interface-alert-circle" /> <FormattedMessage id="survey.required_field" />
        </ErrorMessage>
      )}
    </>
  )
}

MatrixScale.propTypes = {
  id: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  subquestions: PropTypes.arrayOf(
    PropTypes.shape({
      choices: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          label: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
}

export default MatrixScale
