import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { Row, Col } from 'react-grid-system'
import { mq } from '@unowmooc/themes'
import Placeholder from './Placeholder'

const ColPlaceholder = styled(Col)`
  margin-bottom: 20px;
  padding-left: 10px !important;
  padding-right: 10px !important;

  ${mq.sm(css`
    padding-left: 15px !important;
    padding-right: 15px !important;
  `)};
`

const StyledPlaceholder = styled(Placeholder)`
  width: 100%;
  height: 100%;
`

const Placeholders = ({ length, className }) => (
  <Row className={className}>
    {[...Array(length).keys()].map(key => (
      <ColPlaceholder xs={12} sm={6} md={6} lg={4} xl={3} key={key}>
        <StyledPlaceholder />
      </ColPlaceholder>
    ))}
  </Row>
)

Placeholders.defaultProps = {
  length: 6,
  className: undefined,
}

Placeholders.propTypes = {
  length: PropTypes.number,
  className: PropTypes.string,
}

export default Placeholders
