// https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md#feature-detection

let isSupportsPassive = false

try {
  const opts = Object.defineProperty({}, 'passive', {
    get() {
      isSupportsPassive = true

      return true
    },
  })
  window.addEventListener('testPassive', null, opts)
  window.removeEventListener('testPassive', null, opts)
  // eslint-disable-next-line no-empty
} catch (error) {}

const supportsPassive = isSupportsPassive

export const eventListenerOpts = supportsPassive ? { passive: true } : false

export default supportsPassive
