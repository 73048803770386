import React from 'react'
import PropTypes from 'prop-types'
import Tag from '@unowmooc/tags'
import ResponsiveTag from 'components/ResponsiveTag'

const TagSessionType = ({ type, ...props }) => (
  <ResponsiveTag {...props} theme={Tag.themes.grey} data-cy="tag-session-type">
    {type}
  </ResponsiveTag>
)

TagSessionType.defaultProps = {
  className: undefined,
}

TagSessionType.propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default TagSessionType
