import React, { createContext, useContext, useEffect, useState } from 'react'
import qs from 'query-string'
import { useDomainContext } from '../../../../components/DomainLayout/Context'

export const FilterContext = createContext({})

const computeDefaultFilters = (defaultFilters, domain) => {
  if (domain !== 'expert') {
    return {}
  }

  const parsedQuery = qs.parse(window.location.search)

  return {
    ...defaultFilters,
    ...parsedQuery,
  }
}

export const injectFilterContext = (Component, defaultFilters = {}) => props => {
  const { domain } = useDomainContext()

  const [filters, setFilters] = useState(computeDefaultFilters(defaultFilters, domain))

  useEffect(() => {
    if (Object.keys(filters).length === 0) {
      return
    }

    window.history.replaceState(null, null, `?${qs.stringify(filters)}`)
  }, [filters])

  return (
    <FilterContext.Provider value={{ filters, setFilters }}>
      <Component {...props} />
    </FilterContext.Provider>
  )
}

export const useFilterContext = () => useContext(FilterContext)
