import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import StreamlineIcon from '@unowmooc/icons'

const Line = styled(StreamlineIcon)`
  ${props => (props.content ? `font-family: Ubuntu;` : '')}
  position: relative;
  border: solid ${({ theme, isLast }) => (isLast ? '0px' : `1px ${theme.colors.unowGreen}`)};
  border-top: none;
  font-size: 20px;
  font-weight: bold;
  background: ${({ theme }) => theme.colors.unowGreen};
  margin-right: 36px;
  margin-left: 18px;

  :before {
    position: absolute;
    top: 0px;
    left: -18px;
    display: inline-block;
    width: 32px;
    height: 32px;
    ${props => (props.content ? `content: "${props.content}";` : '')}
    line-height: 30px;
    background: ${({ done, isNext, theme }) => (done || isNext ? theme.colors.unowGreen : theme.colors.white)};
    color: ${({ done, isNext, theme }) => (done || isNext ? theme.colors.white : theme.colors.unowGreen)};
    border-radius: 50%;
    border: 2px solid ${({ theme }) => theme.colors.unowGreen};
    text-align: center;
  }
`

const ProgressLine = ({ isLast, isOnboarding, done, isNext, number }) => {
  const content = isOnboarding ? ':-)' : number

  return <Line icon="check" isLast={isLast} isNext={isNext} done={done} content={done ? null : content} />
}

ProgressLine.propTypes = {
  done: PropTypes.bool.isRequired,
  isOnboarding: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
  isNext: PropTypes.bool.isRequired,
  number: PropTypes.number.isRequired,
}

export default ProgressLine
