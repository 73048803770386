import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import I18nProvider from '@unowmooc/i18n'

const HelmetIntl = ({ templateTitle, title, values, ...props }) => (
  <Helmet
    titleTemplate={I18nProvider.formatMessage({ id: templateTitle || 'helmet.title_template', values })}
    title={I18nProvider.formatMessage({ id: title || 'helmet.default_title', values })}
    {...props}
  />
)

HelmetIntl.defaultProps = {
  templateTitle: undefined,
  title: undefined,
  values: undefined,
}

HelmetIntl.propTypes = {
  templateTitle: PropTypes.string,
  title: PropTypes.string,
  values: PropTypes.shape(),
}

export default HelmetIntl
