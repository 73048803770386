import React, { useState } from 'react'
import { propType, filter } from 'graphql-anywhere'
import { toInlineFragment } from 'fraql'
import gql from 'graphql-tag'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'
import ShadowBox from '@unowmooc/shadow-box'
import { Button } from '@unowmooc/buttons'
import { useQuiz } from 'modules/quiz/provider'
import { MINIMUM_CORRECT_ANSWERS_POURCENT } from 'modules/quiz/constants'
import NavBar from '../../NavBar'
import illuBadge from './assets/illu-badge.svg'

const NbQuestions = styled.h4`
  margin-bottom: 20px;
`

const Box = styled(ShadowBox)`
  margin-bottom: 30px;
  padding: 30px;
  text-align: center;

  ${mq.sm(css`
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 20px;
    padding: 20px;
  `)};
`

const Illu = styled.img`
  margin-bottom: 22px;
`

const Tagline = styled.h4`
  margin-bottom: 10px;

  ${mq.sm(css`
    font-size: 20px;
    line-height: 28px;
  `)};
`

const Description = styled.p`
  margin-bottom: 22px;

  ${mq.sm(css`
    font-size: 16px;
    line-height: 26px;
  `)};
`

const ExplanationList = styled.ul`
  padding: 0;
  margin: 0 0 80px 0;

  ${mq.sm(css`
    margin-bottom: 50px;
  `)};
`

const ExplanationListItem = styled.li`
  position: relative;
  color: ${({ theme: { colors } }) => colors.woodsmoke};
  font-size: 16px;
  line-height: 32px;
  margin-left: 16px;

  &::before {
    position: absolute;
    left: -14px;
    content: '•';
    padding-right: 10px;
    color: ${({ theme: { colors } }) => colors.unowGreen};
  }
`

const IntroPage = ({ session, module }) => {
  const { startQuiz, questionCounter, minimumCorrectAnswerCounter } = useQuiz()
  const [quizLoading, updateQuizLoading] = useState(false)

  const handleClick = async () => {
    try {
      updateQuizLoading(true)
      await startQuiz()
    } catch (error) {
      updateQuizLoading(false)
    }
  }

  return (
    <>
      <NbQuestions>
        <FormattedMessage id="quiz.intro.nb_questions" values={{ questionCounter }} />
      </NbQuestions>
      <Box>
        <Illu src={illuBadge} />
        <Tagline>
          <FormattedMessage id="quiz.intro.tagline" />
        </Tagline>
        <Description>
          <FormattedMessage
            id="quiz.intro.description"
            values={{
              minimumCorrectAnswerPourcent: MINIMUM_CORRECT_ANSWERS_POURCENT * 100,
              minimumCorrectAnswerCounter,
              questionCounter,
            }}
          />
        </Description>
        <Button loading={quizLoading} size="large" onClick={handleClick}>
          <FormattedMessage id="quiz.intro.start_link" />
        </Button>
      </Box>
      <ExplanationList>
        <ExplanationListItem>
          <FormattedMessage id="quiz.intro.explanations_1" />
        </ExplanationListItem>
        <ExplanationListItem>
          <FormattedMessage id="quiz.intro.explanations_2" />
        </ExplanationListItem>
      </ExplanationList>
      <NavBar
        session={filter(NavBar.fragments.session, session)}
        section={filter(NavBar.fragments.section, { id: 'quiz', module })}
      />
    </>
  )
}

IntroPage.fragments = {
  session: gql`
    fragment _ on Session {
      ${toInlineFragment(NavBar.fragments.session)}
    }
  `,
  module: gql`
    fragment _ on Module {
      id
    }
  `,
}

IntroPage.propTypes = {
  session: propType(IntroPage.fragments.session).isRequired,
  module: propType(IntroPage.fragments.module).isRequired,
}

export default IntroPage
