import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import gql from 'graphql-tag'
import styled from '@emotion/styled'
import { useQuery } from '@apollo/react-hooks'
import { UnowAwesome } from '@unowmooc/loaders'
import { Link } from 'react-router-dom'

import LicenseCard, { LicenseCardFragment } from 'modules/project/components/LicenseCard'
import { Item } from 'components/List'

import { getAvailibleSupervisions } from '../../supervisor.utils'

const StyledLink = styled(Link)`
  /* Fix ie11 */
  width: 100%;

  display: contents;

  &:hover {
    text-decoration: none;
  }
`

export const query = gql`
  query Supervisions($filters: Json, $search: String, $offset: Int, $sort: [String]) {
    supervisions(filters: $filters, sort: $sort, search: $search, limit: 50, offset: $offset) {
      items {
        id
        project {
          nbAvailableLicenses
          expireAt
          ...LicenseCard
        }
        user {
          id
        }
      }
    }
  }
  ${LicenseCardFragment}
`

const Supervisions = ({ userId }) => {
  const { loading, error, data } = useQuery(query, {
    variables: {
      filters: JSON.stringify({
        user_id: userId,
      }),
    },
  })

  if (loading) return <UnowAwesome size={40} />
  if (error) return `Error! ${error.message}`

  return _.map(getAvailibleSupervisions(_.get(data, 'supervisions.items', [])), ({ project, id }) => (
    <Item width={[1 / 2]}>
      <StyledLink to={`/espace-superviseur/packs/${id}`} key={id}>
        <LicenseCard project={project} />
      </StyledLink>
    </Item>
  ))
}

Supervisions.propTypes = {
  userId: PropTypes.string.isRequired,
}

export default Supervisions
