import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'
import { Visible } from 'react-grid-system'
import Helmet from 'components/HelmetIntl'
import styled from '@emotion/styled'
import { Global, css } from '@emotion/core'
import { colors } from 'styles-resources'
import { FormattedMessage } from 'react-intl'
import SkipLinkAnchor from 'modules/skipLink/SkipLinkAnchor'
import DomainNav from './DomainNav'
import MobileNav from './MobileNav'
import Header from './Header'

const StyledLayout = styled.div`
  overflow: hidden;
`

class Layout extends Component {
  constructor(props) {
    super(props)

    this.handleMenuMobileToggle = this.handleMenuMobileToggle.bind(this)

    this.state = { menuMobileOpen: false }
  }

  componentDidMount() {
    const {
      router: { history },
    } = this.context

    history.listen(() => {
      this.setState({ menuMobileOpen: false })
    })
  }

  handleMenuMobileToggle() {
    const { menuMobileOpen } = this.state

    if (menuMobileOpen) {
      this.setState({ menuMobileOpen: false })
    } else {
      this.setState({ menuMobileOpen: true })
    }
  }

  render() {
    const { component: RouteComponent, hideDomainNav, componentParameters, ...props } = this.props
    const { menuMobileOpen } = this.state

    return (
      <Route
        {...props}
        render={matchProps => (
          <StyledLayout>
            <Global
              styles={css`
                html,
                body {
                  margin: 0;
                  background-color: ${colors.whiteLilac};
                }

                a:hover {
                  text-decoration: none;
                }

                /* Si on a des boutons qui sont comme cela :
                   button > i
                 ou des liens comme cela :
                   a > span > span

                 Dans le fichier src/modules/tracking/tracking.provider.js on va avoir l'event remonté sur les enfants (les i ou les spans),
                 et celui-ci n'est pas remonté non plus au parents.

                 Et cela est problématique vu que les data attributes de tracking se trouvent sur le parent (le button ou le a).
                 Cela cause le problème suivant : sur un bouton qui contient par exemple une balise "i",
                 le tracking n'est pas fait quand on clique sur la balise "i".

                 Avec la propriété CSS pointer-events (https://developer.mozilla.org/fr/docs/Web/CSS/pointer-events), on peux désactiver
                 le fait que ça les events soient lancés sur les enfants et comme cela, c'est toujours le parent qui est remonté dans l'event de tracking, on peux
                 donc accéder au data attributes et bien remonter l'event sur Google Analytics.
                 */

                [data-tracking-id] * {
                  pointer-events: none;
                }
              `}
            />
            <Helmet />
            <Header {...matchProps} onMenuMobileToggle={this.handleMenuMobileToggle} menuMobileOpen={menuMobileOpen} />
            <Visible xs sm>
              <MobileNav {...this.props} visible={menuMobileOpen} />
            </Visible>
            {!hideDomainNav && (
              <Visible md lg xl>
                <SkipLinkAnchor
                  label={<FormattedMessage id="a11y.skip_to_menu" />}
                  anchor="menu-domain"
                  priority={10}
                />
                <DomainNav {...matchProps} />
              </Visible>
            )}
            {/*
                HideDomainNav est utilisé sur les pages de formations. Ces pages cachent le menu principal pour en ajouter
                un spécifique à la formation (refonte de l'espace formation). Ici, le lien d'évitement apparaitra uniquement
                sur les autres pages. Il est déclaré spécifiquement en dessous du menu de la formation sur les pages de formations.
            */}
            {!hideDomainNav && (
              <SkipLinkAnchor label={<FormattedMessage id="a11y.skip_to_content" />} anchor="content" priority={1} />
            )}
            <RouteComponent {...matchProps} {...componentParameters} />
          </StyledLayout>
        )}
      />
    )
  }
}

Layout.defaultProps = {
  hideDomainNav: false,
  componentParameters: {},
}

Layout.propTypes = {
  component: PropTypes.func.isRequired,
  componentParameters: PropTypes.shape(),
  hideDomainNav: PropTypes.bool,
}

Layout.contextTypes = {
  router: PropTypes.shape().isRequired,
}

export default Layout
