import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { propType } from 'graphql-anywhere'
import { Row, Col } from 'react-grid-system'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'
import KeyNumber from './KeyNumber'

import svgBubbleChatHeart from './assets/bubble-chat-heart.svg'
import svgBadgeCheck from './assets/badge-check.svg'
import svgCertificate from './assets/certificate.svg'
import svgSchoolGraduation from './assets/school-graduation.svg'

const StyledKeyNumber = styled(KeyNumber)`
  margin-bottom: 40px;
`

const KeyNumbers = ({ user: { nbComments, nbValidatedModules, nbCertificates, nbFinishedSessions }, className }) => (
  <Row className={className}>
    <Col xs={6} sm={3} md={6} lg={3}>
      <StyledKeyNumber
        illu={svgBubbleChatHeart}
        number={nbComments}
        legend={<FormattedMessage id="profile.key_numbers.nb_comments" values={{ number: nbComments }} />}
      />
    </Col>
    <Col xs={6} sm={3} md={6} lg={3}>
      <StyledKeyNumber
        illu={svgBadgeCheck}
        number={nbValidatedModules}
        legend={
          <FormattedMessage id="profile.key_numbers.nb_validated_modules" values={{ number: nbValidatedModules }} />
        }
      />
    </Col>
    <Col xs={6} sm={3} md={6} lg={3}>
      <StyledKeyNumber
        illu={svgCertificate}
        number={nbCertificates}
        legend={<FormattedMessage id="profile.key_numbers.nb_certifications" values={{ number: nbCertificates }} />}
      />
    </Col>
    <Col xs={6} sm={3} md={6} lg={3}>
      <StyledKeyNumber
        illu={svgSchoolGraduation}
        number={nbFinishedSessions}
        legend={<FormattedMessage id="profile.key_numbers.nb_ended_sessions" values={{ number: nbFinishedSessions }} />}
      />
    </Col>
  </Row>
)

KeyNumbers.fragments = {
  user: gql`
    fragment _ on User {
      id
      nbComments
      nbValidatedModules
      nbCertificates
      nbFinishedSessions
    }
  `,
}

KeyNumbers.defaultProps = {
  className: undefined,
}

KeyNumbers.propTypes = {
  user: propType(KeyNumbers.fragments.user).isRequired,
  className: PropTypes.string,
}

export default KeyNumbers
