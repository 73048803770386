import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const Wrapper = styled.div`
  display: inline-flex;
  letter-spacing: -5px;
`

const TagUp = ({ size }) => (
  <Wrapper>
    &nbsp;
    <svg width={size} height={size} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12.5" cy="12.5" r="12.5" fill="#C1EEE5" />
      <path
        d="M11.6522 8.35551C12.0439 7.72922 12.9561 7.72922 13.3478 8.35551L17.172 14.4697C17.5886 15.1358 17.1098 16 16.3242 16H8.67579C7.89021 16 7.41139 15.1358 7.82797 14.4697L11.6522 8.35551Z"
        fill="#00CC99"
      />
    </svg>
  </Wrapper>
)

TagUp.defaultProps = {
  size: 25,
}

TagUp.propTypes = {
  size: PropTypes.number,
}

export default TagUp
