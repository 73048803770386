import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import StreamlineIcon from '@unowmooc/icons'
import { mq, screens, rgba } from '@unowmooc/themes'
import Tag from '@unowmooc/tags'
import { Text } from '@unowmooc/text'
import PinFlag from './PinFlag'

const StyledComment = styled.div`
  position: relative;
  padding: ${({ child }) => (child ? '8px 25px' : '20px 25px 8px')};

  @media (max-width: ${screens.xsMax}) {
    padding: ${({ child }) => (child ? '8px 12px' : '10px 20px 8px 20px')};
  }

  ${({ theme: { colors }, color, child }) => {
    if (color === 'grey' && child) {
      return `border-left: 5px solid ${colors.mischka};`
    }
    if (color === 'green') {
      return `border-left: 5px solid ${colors.unowGreen};`
    }
    if (color === 'orange') {
      return `border-left: 5px solid ${colors.unowOrange};`
    }

    return ''
  }}

  background-color: ${({ actionTheme, theme: { colors } }) => (actionTheme ? rgba(colors[actionTheme], 0.1) : '')};
`

const StyledDeletedComment = styled.div`
  display: flex;
  font-size: 16px;
  line-height: 23px;
  color: ${({ theme: { colors } }) => colors.bombay};
  align-items: center;
  margin-left: ${({ child }) => (child ? '0px' : '25px')};

  padding: ${({ child }) => (child ? '0px 25px 0px 0px' : '19px 0px 0px 0px')};

  @media (max-width: ${screens.xsMax}) {
    font-size: 14px;
    margin-left: 22px;
    padding: ${({ child }) => (child ? '3px 0px;' : '20px 12px 0px 0px')};
  }
`

const SpanIcon = styled.span`
  padding: 4px 6px 6px;
  margin-right: 12px;
  font-size: 22px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.bombay};
  border-radius: 4px;
`

const Head = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: ${({ pinned }) => (pinned ? -4 : 15)}px;
  justify-content: space-between;

  ${mq.xs(css`
    margin-bottom: 6px;
  `)}
`

const ActionsPinWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`

const StyledPinFlag = styled(PinFlag)`
  position: relative;
  top: -20px;
  margin-left: 15px;

  ${mq.xs(css`
    top: -10px;
  `)}
`

const StyledContent = styled.div`
  margin: 0px 0 3px 0;
  max-width: 730px;

  ${mq.md(css`
    margin: 0px 0 3px 0;
  `)}
`

const Reactions = styled.div`
  width: 100%;
`

const ForwardBox = styled.div`
  margin-right: 10px;
  line-height: 24px;
`

const Comment = ({
  isChild,
  isPinned,
  isForwarded,
  isDeleted,
  deletedCommentLabel,
  info,
  content,
  TopHead,
  theme,
  actionTheme,
  actions,
  reactions,
  refProp,
  className,
}) => {
  if (isDeleted) {
    return (
      <StyledDeletedComment child={isChild} ref={refProp}>
        <SpanIcon>
          <StreamlineIcon icon="bin" />
        </SpanIcon>
        {deletedCommentLabel}
      </StyledDeletedComment>
    )
  }

  return (
    <StyledComment child={isChild} className={className} color={theme} actionTheme={actionTheme} ref={refProp}>
      {TopHead}
      <Head pinned={isPinned} child={isChild}>
        {info}
        <ActionsPinWrapper>
          {isForwarded && (
            <ForwardBox>
              <Tag theme="lightHitPink" small style={{}}>
                <Text fontSize="11px" i18nKey="conversation.forward.forwarded_tag" />
              </Tag>
            </ForwardBox>
          )}
          {actions}
          {isPinned && <StyledPinFlag />}
        </ActionsPinWrapper>
      </Head>
      <StyledContent>{content}</StyledContent>
      {reactions && <Reactions>{reactions}</Reactions>}
    </StyledComment>
  )
}

Comment.themes = {
  grey: 'grey',
  green: 'green',
  orange: 'orange',
}

Comment.defaultProps = {
  theme: undefined,
  isChild: false,
  isPinned: false,
  isForwarded: false,
  isDeleted: false,
  deletedCommentLabel: 'This comment has been deleted.',
  reactions: undefined,
  actions: undefined,
  className: undefined,
  refProp: undefined,
  actionTheme: undefined,
  TopHead: null,
}

Comment.propTypes = {
  theme: PropTypes.oneOf(_.values(Comment.themes)),
  isChild: PropTypes.bool,
  isPinned: PropTypes.bool,
  isForwarded: PropTypes.bool,
  isDeleted: PropTypes.bool,
  TopHead: PropTypes.node,
  content: PropTypes.node.isRequired,
  info: PropTypes.node.isRequired,
  actionTheme: PropTypes.string,
  actions: PropTypes.node,
  deletedCommentLabel: PropTypes.node,
  reactions: PropTypes.node,
  refProp: PropTypes.shape(),
  className: PropTypes.string,
}

export default Comment
