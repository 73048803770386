import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import MediaQuery from 'react-responsive'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { Clickable } from '@unowmooc/buttons'
import { mq, rgba } from '@unowmooc/themes'
import StreamlineIcon from '@unowmooc/icons'

export const Separator = styled.div`
  width: calc(100% - 25px);
  height: 1px;
  background-color: ${({ theme }) => rgba(theme.colors.mischka, 0.5)};

  ${mq.xs(css`
    width: 100%;
  `)}
`

const Collapsed = styled.div`
  margin-left: 25px;

  ${Separator} {
    margin-top: 21px;
    margin-bottom: 25px;
  }
`

const StyledAnswers = styled.div`
  padding-left: 25px;

  & > ${Separator} {
    &:first-of-type {
      margin-top: 21px;
      margin-bottom: 25px;
    }

    margin-top: 25px;
    margin-bottom: 25px;
  }

  ${mq.xs(css`
    padding-left: 20px;
    padding-right: 18px;
  `)}
`

const Button = styled(Clickable)`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.unowGreen};

  &:hover {
    color: darken(${({ theme }) => theme.colors.unowGreen}, 2);
  }
`

const Icon = styled(StreamlineIcon)`
  margin-right: 8px;
  font-size: 24px;
`

const Answers = ({ answers, render, collapsed, onUncollapseButtonClick, uncollapseButtonLabel }) => {
  if (collapsed) {
    return (
      <Collapsed>
        <Separator />
        <Button onClick={onUncollapseButtonClick}>
          <MediaQuery minWidth={480}>
            <Icon icon="chat" />
          </MediaQuery>
          {uncollapseButtonLabel}
        </Button>
      </Collapsed>
    )
  }

  return (
    <StyledAnswers>
      {answers.map(answer => (
        <Fragment key={answer.id}>
          <Separator />
          <div>{render(answer)}</div>
        </Fragment>
      ))}
    </StyledAnswers>
  )
}

Answers.defaultProps = {
  uncollapseButtonLabel: 'Display answers',
  collapsed: false,
}

Answers.propTypes = {
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  render: PropTypes.func.isRequired,
  onUncollapseButtonClick: PropTypes.func.isRequired,
  collapsed: PropTypes.bool,
  uncollapseButtonLabel: PropTypes.node,
}

export default Answers
