import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'
import { FormattedHTMLMessage } from 'react-intl'
import { Alert } from '@unowmooc/react-ui-kit'
import { useQuiz } from 'modules/quiz/provider'
import { MINIMUM_CORRECT_ANSWERS_POURCENT } from 'modules/quiz/constants'
import useMe from '../../../../../../../hooks/useMe'

const Explanations = styled.p`
  margin-bottom: 30px;
  line-height: 34px;

  ${mq.sm(css`
    font-size: 16px;
    line-height: 32px;
  `)};
`

const StyledAlert = styled(Alert)`
  line-height: 32px;

  ${mq.sm(css`
    padding: 20px;
    font-size: 16px;
    line-height: 28px;
  `)};
`

const Instructions = ({ className }) => {
  const { minimumCorrectAnswerCounter, questionCounter } = useQuiz()

  const {
    me: { isLms },
  } = useMe()

  return (
    <div className={className}>
      <Explanations>
        <FormattedHTMLMessage
          id="final_exam.instructions.explanations_1"
          values={{
            minimumCorrectAnswerPourcent: MINIMUM_CORRECT_ANSWERS_POURCENT * 100,
            minimumCorrectAnswerCounter,
            questionCounter,
          }}
        />
        <br />
        {!isLms && <FormattedHTMLMessage id="final_exam.instructions.explanations_2" />}
        {!isLms && <br />}
        <FormattedHTMLMessage
          id="final_exam.instructions.explanations_3"
          values={{ requiredMinutes: questionCounter }}
        />
      </Explanations>

      <StyledAlert>
        <>
          <FormattedHTMLMessage id="final_exam.instructions.warning_1" />
          {!isLms && <br />}
          {!isLms && <FormattedHTMLMessage id="final_exam.instructions.warning_2" />}
        </>
      </StyledAlert>
    </div>
  )
}

Instructions.defaultProps = {
  className: undefined,
}

Instructions.propTypes = {
  className: PropTypes.string,
}

export default Instructions
