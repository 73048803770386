import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import I18nProvider from '@unowmooc/i18n'
import { Tabs as UITabs } from '@unowmooc/react-ui-kit'

class RouteTabs extends Component {
  get tabs() {
    const { messageBaseKey, items } = this.props

    return items.map(item => ({
      id: item,
      name: I18nProvider.formatMessage({ id: `${messageBaseKey}.${item}` }),
    }))
  }

  get active() {
    const { pathname } = this.props
    const { tabs } = this

    const activePath = pathname.split('/').slice(-1)[0]

    const predicate = tab => I18nProvider.formatMessage({ id: `routes.${tab.id}` }) === activePath

    return tabs.find(predicate)
  }

  render() {
    const { messageBaseKey, className } = this.props
    const { tabs, active } = this

    return (
      <UITabs
        title={<FormattedMessage id={`${messageBaseKey}.title`} />}
        tabs={tabs}
        active={active}
        tabComponent={Link}
        tabProps={tab => ({
          'data-cy': `page-tab-${tab.id}`,
          to: I18nProvider.formatMessage({ id: `routes.${tab.id}` }),
        })}
        className={className}
      />
    )
  }
}

RouteTabs.defaultProps = {
  className: undefined,
}

RouteTabs.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  pathname: PropTypes.string.isRequired,
  messageBaseKey: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default RouteTabs
