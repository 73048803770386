import React from 'react'
import PropTypes from 'prop-types'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import { Container } from '@unowmooc/react-ui-kit'
import styled from '@emotion/styled'
import CtaBic from '../../course/skills/CtaBic'

const StyledContainer = styled(Container)`
  h1 {
    padding-top: 40px;
    margin-bottom: 32px;
  }

  p {
    margin-bottom: 18px;
  }
`

const SectionTitle = styled.h1`
  margin-bottom: 5px;
`

const ShowSectionAccessError = ({ errors }) => {
  const errorKey = errors.join('_and_')

  const message = (
    <p>
      <FormattedHTMLMessage id={`module_access_errors.${errorKey}`} />
    </p>
  )

  let cta = null
  if (['not_started_and_bic_not_answered', 'bic_not_answered'].includes(errorKey)) {
    cta = <CtaBic />
  }

  return (
    <StyledContainer>
      <SectionTitle>
        <FormattedMessage id="module_access_errors.title" />
      </SectionTitle>

      {message}
      {cta}
    </StyledContainer>
  )
}

ShowSectionAccessError.defaultProps = {
  errors: [],
}

ShowSectionAccessError.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
}

ShowSectionAccessError.types = {
  NOT_STARTED: 'not_started',
  BIC_NOT_ANSWERED: 'bic_not_answered',
}

export default ShowSectionAccessError
