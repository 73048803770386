import React, { useState } from 'react'
import { propType } from 'graphql-anywhere'
import gql from 'graphql-tag'
import { toInlineFragment } from 'fraql'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { Clickable, ToggleTransition } from '@unowmooc/react-ui-kit'
import StreamlineIcon from '@unowmooc/icons'
import { mq } from '@unowmooc/themes'
import DownloadLinksTooltip from 'modules/vimeo/components/DownloadLinksTooltip'
import Player from './Player'

const Description = styled.p`
  margin-top: 30px;
  white-space: pre-line;
  color: ${({ theme: { colors } }) => colors.woodsmoke};
`

const VideoActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  text-align: right;

  ${mq.sm(css`
    margin-top: 10px;
  `)};
`

const VideoActionLink = styled(Clickable)`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${({ theme: { colors } }) => colors.unowGreen};

  &:hover > span {
    text-decoration: underline;
  }
`

const VideoActionLinkIcon = styled(StreamlineIcon)`
  margin-right: 6px;
  font-size: 20px;
`

const TranscriptContent = styled.div`
  margin-top: 30px;
  border-left-width: 3px;
  border-left-style: solid;
  border-left-color: ${({ theme: { colors } }) => colors.unowGreen};
  padding-left: 21px;

  p {
    padding: 10px 0;
  }

  li {
    line-height: 25px;
    color: ${({ theme: { colors } }) => colors.shipGray};
  }

  ol > li {
    list-style: decimal;
  }

  ul > li {
    list-style: disc;
  }
`

const VideoBlock = ({ block }) => {
  const [transcriptOn, setTranscriptOn] = useState(false)

  const toggleTranscript = () => {
    setTranscriptOn(!transcriptOn)
  }

  return (
    <>
      <Player videoLink={block.videoLink} />

      <VideoActionWrapper>
        {block.transcript ? (
          <VideoActionLink onClick={toggleTranscript}>
            <VideoActionLinkIcon icon={transcriptOn ? 'hide' : 'eye'} />
            <span data-tracking-id={`session.click_on_transcript_${transcriptOn ? 'hide' : 'show'}`}>
              <FormattedMessage id={`sections.content.video.transcript_${transcriptOn ? 'on' : 'off'}`} />
            </span>
          </VideoActionLink>
        ) : (
          <div />
        )}

        <DownloadLinksTooltip vimeoId={block.vimeoId}>
          <VideoActionLink>
            <VideoActionLinkIcon icon="download" />
            <FormattedMessage id="sections.content.video.download" />
          </VideoActionLink>
        </DownloadLinksTooltip>
      </VideoActionWrapper>

      {block.transcript && (
        <ToggleTransition in={transcriptOn}>
          <div>
            <TranscriptContent dangerouslySetInnerHTML={{ __html: block.transcript }} />
            <VideoActionLink onClick={toggleTranscript} style={{ marginTop: 30 }}>
              <VideoActionLinkIcon icon="hide" />
              <span data-tracking-id="session.click_on_transcript_hide_bottom">
                <FormattedMessage id="sections.content.video.transcript_on" />
              </span>
            </VideoActionLink>
          </div>
        </ToggleTransition>
      )}

      {block.description && <Description>{block.description}</Description>}
    </>
  )
}

VideoBlock.fragment = toInlineFragment(
  gql`
    fragment _ on Block {
      ... on VideoBlock {
        blockType
        title
        description
        transcript
        vimeoId
        videoLink
      }
    }
  `,
)

VideoBlock.propTypes = {
  block: propType(VideoBlock.fragment).isRequired,
}

export default VideoBlock
