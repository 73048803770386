import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'
import { Alert, Clickable } from '@unowmooc/react-ui-kit'
import StreamlineIcon from '@unowmooc/icons'
import FilePreview from 'components/FilePreview'
import { colors } from 'styles-resources'
import axios from 'utils/axios'

const PaperclipIcon = styled(StreamlineIcon)`
  position: relative;
  top: -1px;
  margin-right: 4px;
  color: ${colors.midGray};
`

const AttachFileButton = styled(Clickable)`
  font-size: 14px;
  color: ${colors.unowGreen};

  &:hover {
    color: ${colors.unowBluegreen};
  }
`

const UPLOAD_TIMEOUT_MILISECONDS = 240000
const COMPLETED_PERCENT_THRESHOLD = 90

const InputFilev2 = ({ value, onChange, className }) => {
  const fileInputRef = useRef(null)

  const [filePreviewState, setFilePreviewState] = useState()
  const [fileUploadProgress, setFileUploadProgress] = useState(0)
  const [fileUploadError, setFileUploadError] = useState()

  const prepareBasePreviewFromFile = function (file) {
    return { filename: file.name, preview: URL.createObjectURL(file), mimeType: file.type }
  }

  function uploadFile(file) {
    const formData = new FormData()
    formData.append('file', file)

    const config = {
      timeout: UPLOAD_TIMEOUT_MILISECONDS,
      onUploadProgress: progressEvent => {
        let percentCompleted = Math.round((progressEvent.loaded / progressEvent.total) * 100)

        // La progression ne compte que le temps d'upload, on a aussi le temps de traitement de la requpete
        // on bloque à un certain seuil pour bien prendre cela en compte
        if (percentCompleted > COMPLETED_PERCENT_THRESHOLD) {
          percentCompleted = COMPLETED_PERCENT_THRESHOLD
        }

        setFileUploadProgress(percentCompleted)
      },
    }

    axios
      .post('/filestack-upload', formData, config)
      .then(function (returnValue) {
        setFileUploadProgress(100)
        const preview = prepareBasePreviewFromFile(file)
        preview.fileStackId = returnValue.data.filestack_id
        onChange(preview)
      })
      .catch(function () {
        setFileUploadError(<FormattedMessage id="uploader.upload_error" />)
      })
  }

  return (
    <>
      <input
        ref={fileInputRef}
        type="file"
        onChange={event => {
          const file = _.head(event.target.files)

          // show preview
          const filePreview = prepareBasePreviewFromFile(file)
          onChange(filePreview)

          setFilePreviewState(filePreview)

          uploadFile(file)
        }}
        style={{ display: 'none' }}
      />
      {!value && (
        <>
          <AttachFileButton
            onClick={() => {
              fileInputRef.current.click()
            }}
            className={className}
          >
            <PaperclipIcon icon="paperclip" />
            <FormattedMessage id="comment.attach_file" />
          </AttachFileButton>
        </>
      )}
      {fileUploadError && <Alert theme="error">{fileUploadError}</Alert>}
      {filePreviewState && value && (
        <>
          <FilePreview
            file={{ ...value, url: value.preview }}
            progress={fileUploadProgress}
            error={fileUploadError}
            onDelete={() => {
              setFileUploadError(null)
              onChange(null)
            }}
          />
        </>
      )}
    </>
  )
}

InputFilev2.defaultProps = {
  value: undefined,
  className: undefined,
}

InputFilev2.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape(),
  className: PropTypes.string,
}

export default InputFilev2
