import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Select } from '@unowmooc/react-ui-kit'

const SelectIntl = props => (
  <Select
    placeholder={<FormattedMessage id="select.default_placeholder" />}
    noResultsText={<FormattedMessage id="select.default_no_result" />}
    {...props}
  />
)

export default SelectIntl
