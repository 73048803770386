import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import gql from 'graphql-tag'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'
import { withUser } from 'store/UserProvider'
import I18nProvider from '@unowmooc/i18n'
import { ADMIN } from 'modules/user/user.constants'
import { getPreviousSection, getNextSection, canAccessSection } from './utils'
import PreviousLinkContent from './PreviousLinkContent'
import NextLinkContent from './NextLinkContent'

const Wrapper = styled.div`
  border: ${({ theme: { colors } }) => `1px solid ${colors.mischka}`};
  border-left: 0;
  border-right: 0;
  display: flex;
  justify-content: space-between;

  ${mq.sm(css`
    margin-left: -20px;
    margin-right: -20px;
  `)};
`

const StyledLink = styled(Link)`
  display: flex;

  &:hover {
    text-decoration: none;
  }
`

const PreviousLink = styled(StyledLink)``
const NextLink = styled(StyledLink)`
  flex: 1;
`

const getSectionLink = (section, session) => {
  const {
    id: idSession,
    course: { slug: slugCourse },
  } = session

  if (section.id === 'final-exam') {
    return I18nProvider.getLinkRoute('/courses/{slugCourse}/{idSession}/lessons/final-exam', {
      slugCourse,
      idSession,
    })
  }

  if (section.id === 'quiz') {
    return I18nProvider.getLinkRoute('/courses/{slugCourse}/{idSession}/lessons/quiz/{idModule}', {
      slugCourse,
      idSession,
      idModule: section.module.id,
    })
  }

  return I18nProvider.getLinkRoute('/courses/{slugCourse}/{idSession}/lessons/{idSection}', {
    slugCourse,
    idSession,
    idSection: section.id,
  })
}

const NavBar = ({ section, session, user, className }) => {
  const previousSection = getPreviousSection({ session, section }, user.role === ADMIN)
  const nextSection = getNextSection({ session, section }, user.role === ADMIN)

  return (
    <Wrapper className={className}>
      {previousSection && previousSection.id && canAccessSection(previousSection, session, user) ? (
        <PreviousLink to={getSectionLink(previousSection, session)}>
          <PreviousLinkContent />
        </PreviousLink>
      ) : (
        <PreviousLinkContent disabled />
      )}

      {nextSection &&
        nextSection.id &&
        (canAccessSection(nextSection, session, user) ? (
          <NextLink data-cy="section-next-link" to={getSectionLink(nextSection, session)}>
            <NextLinkContent
              title={
                nextSection.id === 'quiz' ? (
                  <FormattedMessage id="sections.navbar.next_quiz" values={{ moduleTitle: nextSection.module.title }} />
                ) : (
                  <>
                    {nextSection.id === 'final-exam' ? (
                      <FormattedMessage id="sections.navbar.next_final_exam" />
                    ) : (
                      nextSection.title
                    )}
                  </>
                )
              }
            />
          </NextLink>
        ) : (
          <NextLinkContent disabled title={nextSection.title} />
        ))}
    </Wrapper>
  )
}

NavBar.fragments = {
  session: gql`
    fragment _ on Session {
      id
      startAt
      confirmedAt
      hasFinalExam
      hasAccessModuleZero
      course {
        id
        slug
        hasFlexibleStart
        moduleZero {
          id
          sections {
            id
            title
          }
        }
        modules {
          id
          status
          slug
          title
          hasQuiz
          sections {
            id
            title
          }
        }
      }
    }
  `,
  section: gql`
    fragment _ on Section {
      id
      module {
        id
      }
    }
  `,
}

NavBar.defaultProps = {
  className: undefined,
}

NavBar.propTypes = {
  session: propType(NavBar.fragments.session).isRequired,
  section: propType(NavBar.fragments.section).isRequired,
  user: PropTypes.shape().isRequired,
  className: PropTypes.string,
}

export default withUser(NavBar)
