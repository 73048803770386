import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { propType, filter } from 'graphql-anywhere'
import { toInlineFragment } from 'fraql'
import _ from 'lodash'
import gql from 'graphql-tag'
import { withRouter } from 'react-router-dom'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import I18nProvider from '@unowmooc/i18n'
import { mq, rgba } from '@unowmooc/themes'
import ProgramTimelineMenu from 'modules/session/components/ProgramTimelineMenu'
import useIsMobile from 'hooks/useIsMobile'
import { useTracking } from 'modules/tracking'
import { useCourseContext } from 'modules/course/components/CourseContext/CourseContext'

const Wrapper = styled.div`
  max-width: 400px;
  overflow: hidden;
  box-shadow: ${({ theme: { colors } }) => `0 2px 7px 0 ${rgba(colors.black, 0.16)}`};

  ${mq.sm(css`
    max-width: none;
  `)};
`

const StyledProgramTimelineMenu = styled(ProgramTimelineMenu)`
  height: 100%;
`

const getSelectedModule = (idModule, idSection, session) => {
  if (!idModule && !idSection) {
    return null
  }

  const {
    hasAccessModuleZero,
    course: { moduleZero, modules },
  } = session

  const allModules = hasAccessModuleZero ? _.concat([moduleZero], modules) : modules

  const moduleIndex = _.findIndex(allModules, module =>
    idModule ? module.id === idModule : _.some(module.sections, { id: idSection }),
  )

  return moduleIndex >= 0 ? moduleIndex : null
}

const ProgramTimelineMenuBar = ({ location, match, location: { pathname }, session, ...props }) => {
  const isMobile = useIsMobile()
  const { sendEvent } = useTracking()
  const [open, setOpen] = useState(!isMobile)
  const { courseIdentifier, sessionIdentifier, sessionId, slugCourse } = useCourseContext()

  const url = I18nProvider.getLinkRoute('/courses/{slugCourse}/{sessionId}/lessons', {
    slugCourse,
    sessionId,
  })

  const idModule = _.get(pathname.match(new RegExp(`^${url}/quiz/(\\d+)`)), '[1]')
  const idSection = _.get(pathname.match(new RegExp(`^${url}/(\\d+)$`)), '[1]')

  const selectedModule = getSelectedModule(idModule, idSection, session)

  const handleToggleTimelineMenu = ({ open: timelineOpen }) => {
    setOpen(timelineOpen)
    sendEvent(
      'session.click_on_program_menu_toggle_button',
      JSON.stringify({
        courseIdentifier,
        sessionIdentifier,
        toggleStatus: timelineOpen ? 'Opening' : 'Closing',
      }),
    )
  }

  return (
    <Wrapper>
      <StyledProgramTimelineMenu
        {...props}
        session={filter(ProgramTimelineMenu.fragments.session, session)}
        onToggle={handleToggleTimelineMenu}
        onSectionClick={() => {
          if (isMobile) {
            setOpen(false)
          }
        }}
        open={open}
        selectedModule={selectedModule}
      />
    </Wrapper>
  )
}

ProgramTimelineMenuBar.fragments = {
  ...ProgramTimelineMenu.fragments,
  session: gql`
    fragment _ on Session {
      id
      hasAccessModuleZero
      course {
        slug
        moduleZero {
          sections {
            id
          }
        }
        modules {
          sections {
            id
          }
        }
      }
      ${toInlineFragment(ProgramTimelineMenu.fragments.session)}
      authenticatedParticipant {
        id
        ${toInlineFragment(ProgramTimelineMenu.fragments.participation)}
      }
    }
  `,
}

ProgramTimelineMenuBar.defaultProps = {
  ...ProgramTimelineMenu.defaultProps,
}

ProgramTimelineMenuBar.propTypes = {
  ...ProgramTimelineMenu.propTypes,
  session: propType(ProgramTimelineMenuBar.fragments.session).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default withRouter(ProgramTimelineMenuBar)
