import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { Box } from 'reflexbox'
import FormattedDateInterval from 'components/FormattedDateInterval'
import {
  getMessageFromClosingPeriod,
  getStartAtFromClosingPeriod,
  getEndAtFromClosingPeriod,
} from 'modules/session/session.selectors'

const DateClosingPeriod = ({ closingPeriod }) => {
  if (!closingPeriod) {
    return null
  }

  return (
    <Box title={getMessageFromClosingPeriod(closingPeriod)}>
      <FormattedDateInterval
        id="commons.session_closing_period_from_to"
        from={getStartAtFromClosingPeriod(closingPeriod)}
        to={getEndAtFromClosingPeriod(closingPeriod)}
      />
    </Box>
  )
}

DateClosingPeriod.defaultProps = {
  closingPeriod: {},
}

DateClosingPeriod.propTypes = {
  closingPeriod: PropTypes.shape({
    startAt: PropTypes.string.isRequired,
    endAt: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
  }),
}

DateClosingPeriod.fragment = gql`
  fragment SessionDateClosingPeriod on Session {
    closingPeriod {
      startAt
      endAt
      message
    }
  }
`

export default DateClosingPeriod
