import queryString from 'query-string'
import config from 'config'

export const logoutUrl = `${config.env.AUTH_CLIENT_URL}/logout`

export function redirectToAuthenticationPage() {
  window.location.href = `${config.env.AUTH_CLIENT_URL}?${queryString.stringify({
    redirect: window.location.href,
  })}`
}

export function redirectToLogoutPage() {
  window.location.href = logoutUrl
}
