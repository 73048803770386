import React from 'react'
import { FormattedHTMLMessage } from 'react-intl'

const Pace = () => (
  <>
    <p>
      <FormattedHTMLMessage id="guide.slides.pace.content.paragraph_1" />
      <ul>
        <li>
          <FormattedHTMLMessage id="guide.slides.pace.content.paragraph_1_item_1" />
        </li>
        <li>
          <FormattedHTMLMessage id="guide.slides.pace.content.paragraph_1_item_2" />
        </li>
        <li>
          <FormattedHTMLMessage id="guide.slides.pace.content.paragraph_1_item_3" />
        </li>
      </ul>
    </p>
    <p>
      <FormattedHTMLMessage id="guide.slides.pace.content.paragraph_2" />
    </p>
    <p>
      <FormattedHTMLMessage id="guide.slides.pace.content.paragraph_3" />
    </p>
  </>
)

export default Pace
