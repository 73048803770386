import Thread from './Thread'
import ThreadCreator from './ThreadCreator'
import Comment from './Comment'
import CommentInfo from './CommentInfo'
import CommentAction from './CommentActions/CommentAction'
import CommentActions from './CommentActions'
import CommentContent from './CommentContent'
import CommentInput from './CommentInput'
import Reactions from './Reactions'
import Answers from './Answers'

export default {
  Thread,
  ThreadCreator,
  Comment,
  CommentInfo,
  CommentActions,
  CommentAction,
  CommentContent,
  CommentInput,
  Answers,
  Reactions,
}
