import React from 'react'
import PropTypes from 'prop-types'
import { FormattedHTMLMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'
import I18nProvider from '@unowmooc/i18n'

import assetImageLogo from './assets/logo.svg'

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  margin-left: 30px;

  &:hover {
    text-decoration: none;
  }

  ${mq.sm(css`
    margin-left: 20px;
  `)};
`

const StyledTitle = styled.h3`
  display: flex;
  align-items: center;
  font-size: 17px;
  font-weight: 600;
  line-height: 23px;
  color: ${({ theme: { colors } }) => colors.unowBluegreen};
`

const Logo = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 20px;
`

const Title = ({ className }) => (
  <StyledLink data-cy="link-home" to={I18nProvider.getLinkRoute('/dashboard')}>
    <StyledTitle className={className}>
      <Logo src={assetImageLogo} alt="" />
      <FormattedHTMLMessage id="commons.unow" />
    </StyledTitle>
  </StyledLink>
)

Title.defaultProps = {
  className: undefined,
}

Title.propTypes = {
  className: PropTypes.string,
}

export default Title
