import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import Auto from './auto'
import Manual from './manual'

const AddPage = ({ match: { params } }) => {
  if (params && params.mode === 'automatique') {
    return <Auto />
  }

  return <Manual />
}

AddPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      mode: PropTypes.string,
    }).isRequired,
  }).isRequired,
}

export default withRouter(AddPage)
