import React from 'react'
import PropTypes from 'prop-types'

export const SkipLinkContext = React.createContext({})

export const SkipLinkProvider = ({ children }) => {
  const [skipLinks, updateSkipLinks] = React.useState([])

  return (
    <SkipLinkContext.Provider
      value={{ skipLinks: skipLinks.sort((a, b) => (a.priority < b.priority ? -1 : 1)), updateSkipLinks }}
    >
      {children}
    </SkipLinkContext.Provider>
  )
}

SkipLinkProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const useSkipLinkContext = () => React.useContext(SkipLinkContext)
