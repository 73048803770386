import React, { memo, useMemo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useFormContext, Controller } from 'react-hook-form'
import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl'
import { StreamlineIcon } from '@unowmooc/react-ui-kit'

export const ErrorMessage = styled.div`
  margin-top: 12px;
  color: ${({ theme: { colors } }) => colors.bittersweet};
  display: flex;
  align-items: center;
`

export const ErrorMessageIcon = styled(StreamlineIcon)`
  font-size: 24px;
  margin-right: 8px;
`

const FieldRenderer = memo(
  ({ name, label, children, control, clearErrors, ...props }) => (
    <>
      <Controller
        control={control}
        name={name}
        rules={_.pick(props, ['required', 'validate'])}
        render={controllerProps =>
          useMemo(
            () =>
              React.cloneElement(children, {
                ...controllerProps,
                name,
                onChange: (...args) => {
                  clearErrors(name)

                  if (controllerProps.onChange) {
                    controllerProps.onChange(...args)
                  }
                },
              }),
            [controllerProps.value],
          )
        }
      />
    </>
  ),
  (prevProps, nextProps) => {
    const prevValue = prevProps.getValues(prevProps.name)
    const nextValue = nextProps.getValues(nextProps.name)

    return prevProps.formState.isDirty === nextProps.formState.isDirty && _.isEqual(prevValue, nextValue)
  },
)

FieldRenderer.defaultProps = {
  label: null,
  displayErrorMessage: true,
}

FieldRenderer.propTypes = {
  name: PropTypes.string.isRequired,
  displayErrorMessage: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  required: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  control: PropTypes.shape({}).isRequired,
  clearErrors: PropTypes.func.isRequired,
  errors: PropTypes.shape({}).isRequired,
}

const Field = ({ displayErrorMessage, ...props }) => {
  const methods = useFormContext()

  const { name } = props
  const { errors } = methods
  const fieldError = _.get(errors, name)

  if (fieldError && fieldError.type === 'required') {
    fieldError.message = <FormattedMessage id="survey.required_field" />
  }

  return (
    <>
      <FieldRenderer {...props} {...methods} />
      {displayErrorMessage && fieldError && fieldError.message && (
        <ErrorMessage>
          <ErrorMessageIcon icon="interface-alert-circle" /> {fieldError.message}
        </ErrorMessage>
      )}
    </>
  )
}

Field.defaultProps = {
  ...FieldRenderer.defaultProps,
  displayErrorMessage: true,
}

Field.propTypes = {
  ...FieldRenderer.propTypes,
  displayErrorMessage: PropTypes.bool,
  name: PropTypes.string.isRequired,
}

export default Field
