import React from 'react'
import PropTypes from 'prop-types'
import { FormattedHTMLMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import classnames from 'classnames'
import { Dropdown, DropdownMenu, DropdownMenuLink } from '@unowmooc/react-ui-kit'
import { redirectToLogoutPage } from 'utils/routes'
import { withUser } from 'store/UserProvider'
import I18nProvider from '@unowmooc/i18n'
import { Can } from 'store/AbilityProvider'
import Target from './Target'

const StyledDropdownMenu = styled(DropdownMenu)`
  right: 5;
`

const StyledDropdownMenuLink = styled(DropdownMenuLink)`
  padding: 14px 20px;
  line-height: 16px;
  color: ${({ theme: { colors } }) => colors.manatee};
  text-align: right;

  &:hover {
    color: ${({ theme: { colors } }) => colors.unowGreen};
    text-decoration: none;
  }
`

const StyledDropdownMenuLinkLogout = styled(StyledDropdownMenuLink)`
  color: ${({ theme: { colors } }) => colors.pastelRed};
`

const Content = styled.div`
  display: flex;
  align-items: center;
`

const UserDropdown = ({ user, className }) => (
  <Dropdown
    render={({ active, open }) => <Target active={active} onClick={open} data-cy="menu-dropdown" />}
    className={classnames('user-dropdown', className)}
  >
    <StyledDropdownMenu>
      <StyledDropdownMenuLink
        tag={Link}
        to={I18nProvider.getLinkRoute('/profile/{slug}/{id}', { slug: user.slug, id: user.id })}
        data-cy="link-profile"
        data-tracking-id="session.click_on_profile_page"
        data-tracking-values={JSON.stringify({
          from: 'UserDropdown',
        })}
      >
        <Content>
          <FormattedHTMLMessage id="user_dropdown.profile" />
        </Content>
      </StyledDropdownMenuLink>
      <Can I="access-domain" of="settings">
        <StyledDropdownMenuLink tag={Link} to={I18nProvider.getLinkRoute('/settings')}>
          <Content>
            <FormattedHTMLMessage id="user_dropdown.settings" />
          </Content>
        </StyledDropdownMenuLink>
      </Can>
      <Can I="access-domain" of="conversations">
        <StyledDropdownMenuLink tag={Link} to={I18nProvider.getLinkRoute('/conversations')}>
          <Content>
            <FormattedHTMLMessage id="user_dropdown.conversations" />
          </Content>
        </StyledDropdownMenuLink>
      </Can>
      <StyledDropdownMenuLinkLogout onClick={redirectToLogoutPage}>
        <Content>
          <FormattedHTMLMessage id="user_dropdown.logout" />
        </Content>
      </StyledDropdownMenuLinkLogout>
    </StyledDropdownMenu>
  </Dropdown>
)

UserDropdown.defaultProps = {
  className: undefined,
}

UserDropdown.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string,
}

export default withUser(UserDropdown)
