import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { propType } from 'graphql-anywhere'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { Row, Col } from 'react-grid-system'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import Tooltip from '@unowmooc/tooltip'
import { mq } from '@unowmooc/themes'
import InformationIcon from 'components/InformationIcon'
import BoxCounter from 'components/BoxCounter'

const StyledCol = styled(Col)`
  ${mq.md(css`
    &:nth-of-type(1),
    &:nth-of-type(2) {
      margin-bottom: 20px;
    }
  `)};

  ${mq.sm(css`
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  `)};
`

const StyledInformationIcon = styled(InformationIcon)`
  margin-left: 10px;
`

const FinishedCounters = ({
  supervisedSessionStats: { nbParticipants, nbCertifiedParticipants, completionRate, satisfactionRate },
  className,
}) => (
  <Row className={className}>
    <StyledCol xs={12} sm={6} md={3}>
      <BoxCounter
        value={nbParticipants}
        label={<FormattedMessage id="session.nb_participants_label" values={{ nbParticipants }} />}
      />
    </StyledCol>
    <StyledCol xs={12} sm={6} md={3}>
      <BoxCounter
        value={nbCertifiedParticipants}
        label={<FormattedMessage id="session.nb_certified_label" values={{ nbCertifiedParticipants }} />}
      />
    </StyledCol>
    <StyledCol xs={12} sm={6} md={3}>
      <BoxCounter
        value={
          <>
            {completionRate === null ? (
              '- '
            ) : (
              <FormattedNumber value={completionRate} style="percent" maximumFractionDigits={1} />
            )}
          </>
        }
        label={
          <>
            <FormattedMessage id="session.completion_rate" />
            <Tooltip placement="bottom" content={<FormattedMessage id="session.completion_rate_helper" />}>
              <StyledInformationIcon />
            </Tooltip>
          </>
        }
      />
    </StyledCol>
    <StyledCol xs={12} sm={6} md={3}>
      <BoxCounter
        value={
          <>
            {satisfactionRate === null ? (
              '- '
            ) : (
              <FormattedNumber value={satisfactionRate * 10} maximumFractionDigits={1} />
            )}
            / 10
          </>
        }
        label={<FormattedMessage id="session.satisfaction" values={{ nbParticipants }} />}
      />
    </StyledCol>
  </Row>
)

FinishedCounters.fragment = gql`
  fragment FinishedCountersFragment on SupervisedSessionStats {
    nbParticipants
    nbCertifiedParticipants
    nbFinisherParticipants
    completionRate
    satisfactionRate
  }
`

FinishedCounters.defaultProps = {
  className: undefined,
}

FinishedCounters.propTypes = {
  supervisedSessionStats: propType(FinishedCounters.fragment).isRequired,
  className: PropTypes.string,
}

export default FinishedCounters
