import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import DropZone from '@unowmooc/react-ui-kit/lib/DropZone'
import ImageUploadPreview from '@unowmooc/react-ui-kit/lib/ImageUploadPreview'
import DropZoneContent from '@unowmooc/react-ui-kit/lib/DropZoneContent'
import { picture as svgPicture } from '@unowmooc/react-ui-kit/assets/icons'

class ImagePicker extends Component {
  constructor(props) {
    super(props)

    this.handlePick = this.handlePick.bind(this)
    this.handleDelete = this.handleDelete.bind(this)

    this.state = { file: props.file }
  }

  get src() {
    const { file } = this.state

    return _.get(file, 'preview')
  }

  get filename() {
    const { file } = this.state

    return _.get(file, 'name')
  }

  handlePick(files) {
    const file = _.head(files)

    this.setState({ file })

    const { onPick } = this.props

    if (onPick) {
      onPick(file)
    }
  }

  handleDelete() {
    const { file } = this.state

    this.setState({ file: null })

    const { onDelete } = this.props

    if (onDelete) {
      onDelete(file)
    }
  }

  render() {
    const {
      addLabel,
      dropLabel,
      progress,
      error,
      uploadProgressLabel,
      downloadLabel,
      deleteLabel,
      allowedFormatsLabel,
      recommendedSizeLabel,
      rounded,
      recommendedWidth,
      recommendedHeight,
      allowedFormats,
      disableDelete,
    } = this.props
    const { file } = this.state

    return (
      <div>
        {file ? (
          <ImageUploadPreview
            src={this.src}
            filename={this.filename}
            progress={progress}
            error={error}
            onDelete={disableDelete ? undefined : this.handleDelete}
            uploadProgressLabel={uploadProgressLabel}
            downloadLabel={downloadLabel}
            deleteLabel={deleteLabel}
            rounded={rounded}
          />
        ) : (
          <DropZone
            onDrop={this.handlePick}
            render={({ active, openDialog }) => (
              <DropZoneContent
                active={active}
                openDialog={openDialog}
                icon={svgPicture}
                addLabel={addLabel}
                dropLabel={dropLabel}
                allowedFormatsLabel={allowedFormatsLabel}
                recommendedSizeLabel={recommendedSizeLabel}
                recommendedWidth={recommendedWidth}
                recommendedHeight={recommendedHeight}
                allowedFormats={allowedFormats}
              />
            )}
          />
        )}
      </div>
    )
  }
}

ImagePicker.defaultProps = {
  file: undefined,
  onPick: undefined,
  onDelete: undefined,
  progress: undefined,
  error: undefined,
  addLabel: 'Ajouter une image',
  dropLabel: 'Glissez l’image ici pour l’importer',
  uploadProgressLabel: undefined,
  downloadLabel: undefined,
  deleteLabel: undefined,
  allowedFormatsLabel: undefined,
  recommendedSizeLabel: undefined,
  rounded: false,
  recommendedWidth: undefined,
  recommendedHeight: undefined,
  allowedFormats: ['JPG', 'PNG'],
  disableDelete: false,
}

ImagePicker.propTypes = {
  disableDelete: PropTypes.bool,
  file: PropTypes.shape({
    preview: PropTypes.string,
    name: PropTypes.string,
  }),
  onPick: PropTypes.func,
  onDelete: PropTypes.func,
  progress: PropTypes.number,
  error: PropTypes.node,
  addLabel: PropTypes.node,
  dropLabel: PropTypes.node,
  uploadProgressLabel: PropTypes.node,
  downloadLabel: PropTypes.node,
  deleteLabel: PropTypes.node,
  allowedFormatsLabel: PropTypes.string,
  recommendedSizeLabel: PropTypes.string,
  rounded: PropTypes.bool,
  recommendedWidth: PropTypes.number,
  recommendedHeight: PropTypes.number,
  allowedFormats: PropTypes.arrayOf(PropTypes.string),
}

export default ImagePicker
