import React from 'react'
import { ApolloProvider } from 'react-apollo'
import { IntlFragment } from '@unowmooc/react-ui-kit'
import { UnowThemeProvider } from '@unowmooc/themes'
import { StyleTooltip } from '@unowmooc/tooltip'
import { StateMachineProvider, createStore } from 'little-state-machine'
import Router from 'pages'
import { client } from 'utils/graphql'
import { LocaleProvider } from 'components/LocaleProvider'
import ErrorBoundary from './ErrorBoundary'

createStore({
  userRegistration: {},
})

const App = () => (
  <StateMachineProvider>
    <ApolloProvider client={client}>
      <LocaleProvider defaultLocale="fr" textComponent={IntlFragment}>
        <UnowThemeProvider>
          <ErrorBoundary>
            <StyleTooltip />
            <Router />
          </ErrorBoundary>
        </UnowThemeProvider>
      </LocaleProvider>
    </ApolloProvider>
  </StateMachineProvider>
)

export default App
