import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { propType, filter } from 'graphql-anywhere'
import styled from '@emotion/styled'
import { injectIntl } from 'react-intl'
import { toInlineFragment } from 'fraql'
import { Box, Flex } from 'reflexbox'
import { Text } from '@unowmooc/text'
import Tag from '@unowmooc/tags'
import gql from 'graphql-tag'
import {
  hasSeenSection,
  hasBadgeForModule,
  getProgressionPercentByModule,
  getUserProgression,
} from 'modules/progression/utils'
import { withUser } from 'store/UserProvider'
import { animateScrollTo } from 'utils/scroll'
import TimelineMenu, { Item } from 'components/TimelineMenu'
import { isSessionStarted } from 'modules/session/utils'
import { isTagIdentifierDisplay, getSessionCode } from 'modules/session/session.utils'
import { ADMIN } from 'modules/user/user.constants'
import { numericFormat } from 'utils/date'
import { mq } from 'styles-resources'
import { css } from '@emotion/core'
import moment from 'moment'
import ModuleZeroItem from './ModuleZeroItem'
import ModuleLegend from './ModuleLegend'
import SectionItem from './SectionItem'
import QuizSectionItem from './QuizSectionItem'
import FinalExamItem from './FinalExamItem'

const TagWrapper = styled.div`
  float: right;
`

const MenuLabelText = styled.span`
  font-size: 15px;
  line-height: 28px;
  font-weight: 500;

  ${mq.sm(css`
    line-height: inherit;
  `)}
`

class ProgramTimelineMenu extends Component {
  constructor(props) {
    super(props)

    this.modulesRef = props.session.course.modules.map(() => React.createRef())

    this.modulesRef.unshift(React.createRef())
  }

  componentDidUpdate() {
    const { scrollOnSelectedModule, selectedModule } = this.props

    if (scrollOnSelectedModule && selectedModule !== null) {
      const element = this.modulesRef[selectedModule]

      animateScrollTo(element.current)
    }
  }

  render() {
    const {
      user,
      session,
      session: { course, startAt, endAt },
      participation: { progressions, badges },
      title,
      open,
      onToggle,
      onSectionClick,
      selectedModule,
      className,
      intl,
    } = this.props

    const sessionStarted = isSessionStarted(session)

    const modules = user.role === ADMIN ? course.modules : _.filter(course.modules, { status: 'publish' })

    const moduleToOpen = getUserProgression(
      course,
      progressions,
      badges,
      user.role === ADMIN,
      session.hasAccessModuleZero,
    )

    let sessionFromToType = 'multiple_days'

    if (moment(startAt).format('YYYY-MM-DD') === moment(endAt).format('YYYY-MM-DD')) {
      sessionFromToType = 'single_day'
    }

    return (
      <TimelineMenu
        data-cy="timeline-menu"
        // Si title n'est pas renseigné alors nous sommes sur
        // le dashbord de la session. Donc pas de titre ni de
        // tag Identifier. Sinon il serait present en double.
        title={
          title && (
            <Flex flexDirection="column">
              <Box>
                <MenuLabelText>{title}</MenuLabelText>
              </Box>
              {isTagIdentifierDisplay(session, user) && (
                <Box mt="15px">
                  <Tag data-cy="session-identifier" theme="green" small>
                    {getSessionCode(session)}
                  </Tag>
                  <br />
                  <Box mt="6px">
                    <Text
                      color="#929296"
                      i18nKey="commons.session_from_to"
                      values={{
                        from: intl.formatDate(startAt, numericFormat),
                        to: intl.formatDate(endAt, numericFormat),
                        type: sessionFromToType,
                      }}
                    />
                  </Box>
                </Box>
              )}
            </Flex>
          )
        }
        open={open}
        onToggle={onToggle}
        className={className}
      >
        {session.hasAccessModuleZero && (
          <ModuleZeroItem
            module={filter(ModuleZeroItem.fragments.module, session.course.moduleZero)}
            session={filter(ModuleZeroItem.fragments.session, session)}
            progressions={filter(ModuleZeroItem.fragments.progression, progressions)}
            onSectionClick={onSectionClick}
            progression={getProgressionPercentByModule(session.course.moduleZero, progressions, badges)}
            isMenuOpen={open}
            open={selectedModule === 0 || session.course.moduleZero.id === moduleToOpen.moduleId}
            forwardedRef={this.modulesRef[0]}
          />
        )}
        {modules.map((module, index) => {
          const progressionPercent = getProgressionPercentByModule(module, progressions, badges)
          const hasBadge = hasBadgeForModule(module, badges)
          const draft = user.role === ADMIN && module.status === 'draft' && (
            <TagWrapper>
              <Tag theme={Tag.themes.red} small>
                {intl.formatMessage({ id: 'sections.draft' })}
              </Tag>
            </TagWrapper>
          )

          return (
            <Item
              isMenuOpen={open}
              open={
                sessionStarted && selectedModule === null
                  ? _.get(moduleToOpen, 'moduleId') === module.id
                  : selectedModule === index + (session.hasAccessModuleZero ? 1 : 0)
              }
              legend={
                <>
                  <ModuleLegend
                    module={filter(ModuleLegend.fragments.module, module)}
                    session={filter(ModuleLegend.fragments.session, session)}
                    ref={this.modulesRef[index + 1]}
                  />
                  {draft}
                </>
              }
              title={module.title}
              progression={progressionPercent}
              id={module.id}
              key={module.id}
            >
              {module.sections.map(section => {
                const valid = hasSeenSection(section, progressions)

                return (
                  <SectionItem
                    section={filter(SectionItem.fragments.section, section)}
                    session={filter(SectionItem.fragments.session, session)}
                    valid={valid}
                    draftModule={user.role === ADMIN && module.status === 'draft'}
                    onClick={() => onSectionClick(`section-${section.id}`)}
                    key={section.id}
                  />
                )
              })}
              {module.hasQuiz && (
                <QuizSectionItem
                  valid={hasBadge}
                  module={filter(QuizSectionItem.fragments.module, module)}
                  session={filter(QuizSectionItem.fragments.session, session)}
                  onClick={() => onSectionClick(`quiz-module-${module.id}`)}
                />
              )}
            </Item>
          )
        })}
        {session.hasFinalExam && (
          <FinalExamItem
            isMenuOpen={open}
            session={filter(FinalExamItem.fragments.session, session)}
            onClick={() => onSectionClick('final-exam')}
          />
        )}
      </TimelineMenu>
    )
  }
}

ProgramTimelineMenu.fragments = {
  session: gql`
    fragment _ on Session {
      id
      identifier
      startAt
      confirmedAt
      hasFinalExam
      hasAccessModuleZero
      currentWeek
      startAt
      endAt
      course {
        id
        hasFlexibleStart
        moduleZero {
          id
          ${toInlineFragment(ModuleZeroItem.fragments.module)}
        }
        modules {
          id
          status
          slug
          bonus
          hasQuiz
          status
          publicPosition
          title
          sections {
            id
            title
            status
            position
            duration
            ${toInlineFragment(SectionItem.fragments.section)}
          }
          ${toInlineFragment(ModuleLegend.fragments.module)}
          ${toInlineFragment(QuizSectionItem.fragments.module)}
        }
      }
      ${toInlineFragment(ModuleZeroItem.fragments.session)}
      ${toInlineFragment(ModuleLegend.fragments.session)}
      ${toInlineFragment(FinalExamItem.fragments.session)}
      ${toInlineFragment(SectionItem.fragments.session)}
      ${toInlineFragment(QuizSectionItem.fragments.session)}
    }
  `,
  participation: gql`
    fragment _ on Participant {
      badges {
        id
        module {
          id
        }
      }
      progressions {
        id
        section {
          id
        }
        ${toInlineFragment(ModuleZeroItem.fragments.progression)}
      }
    }
  `,
}

ProgramTimelineMenu.defaultProps = {
  title: undefined,
  open: undefined,
  onToggle: undefined,
  onSectionClick: undefined,
  scrollOnSelectedModule: false,
  selectedModule: null,
  className: undefined,
}

ProgramTimelineMenu.propTypes = {
  user: PropTypes.shape().isRequired,
  session: propType(ProgramTimelineMenu.fragments.session).isRequired,
  participation: propType(ProgramTimelineMenu.fragments.participation).isRequired,
  title: PropTypes.node,
  open: PropTypes.bool,
  onToggle: PropTypes.func,
  onSectionClick: PropTypes.func,
  scrollOnSelectedModule: PropTypes.bool,
  selectedModule: PropTypes.number,
  className: PropTypes.string,
  intl: PropTypes.shape({
    formatDate: PropTypes.func.isRequired,
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
}

export default injectIntl(withUser(ProgramTimelineMenu))
