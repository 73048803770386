import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { ButtonI18n } from '@unowmooc/buttons'
import styled from '@emotion/styled'
import { useMutation } from '@apollo/react-hooks'
import { useTracking } from 'modules/tracking'
import { MARK_COMMENTS_AS_RESOLVED, MARK_COMMENTS_AS_UNRESOLVED } from '../../comments.mutations'
import { useThreadContext } from '../Context/ThreadContext'

const Button = styled(ButtonI18n)`
  i {
    margin-right: 14px;
  }
`

const ThreadResolverButton = ({ comment }) => {
  const { sendEvent } = useTracking()
  const [markAsResolved] = useMutation(MARK_COMMENTS_AS_RESOLVED)
  const [markAsUnresolved] = useMutation(MARK_COMMENTS_AS_UNRESOLVED)
  const { isFormDirtyAndValid } = useThreadContext()

  const action = () => {
    if (comment.isResolved) {
      markAsUnresolved({ variables: { commentsIds: [comment.id] } }).then(
        ({
          data: {
            markCommentsAsUnresolved: { items: comments },
          },
        }) => {
          sendEvent(
            'comment.click_on_unresolve_conversation',
            JSON.stringify({
              courseIdentifier: comments[0].session.course.identifier,
              sessionIdentifier: comments[0].session.identifier,
              commentId: comments[0].id,
            }),
          )
        },
      )
    } else {
      markAsResolved({ variables: { commentsIds: [comment.id] } }).then(
        ({
          data: {
            markCommentsAsResolved: { items: comments },
          },
        }) => {
          sendEvent(
            'comment.click_on_resolve_conversation',
            JSON.stringify({
              courseIdentifier: comments[0].session.course.identifier,
              sessionIdentifier: comments[0].session.identifier,
              commentId: comments[0].id,
            }),
          )
        },
      )
    }
  }

  return (
    <Button
      marginLeft={[0, 'auto']}
      onClick={action}
      fontSize="16px"
      disabled={isFormDirtyAndValid}
      reverse
      variant="secondary"
      icon={comment.isResolved ? 'cross' : 'check'}
      i18nKey={`expert.comments.mark_as_${comment.isResolved ? 'undone' : 'done'}`}
    />
  )
}

ThreadResolverButton.fragment = gql`
  fragment ThreadResolverButtonFragment on Comment {
    id
    isResolved
  }
`

ThreadResolverButton.propTypes = {
  comment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isResolved: PropTypes.bool,
  }).isRequired,
}

export default ThreadResolverButton
