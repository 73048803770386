import React from 'react'
import { useSkipLinkContext } from 'modules/skipLink/provider'
import styled from '@emotion/styled'
import { colors } from '@unowmooc/themes'

const SkipLinkMenuList = styled.ul`
  margin: 0;
  padding: 0;
`

const SkipLinkMenuItemLink = styled.a`
  display: inline-block;
  color: #555;
  background: ${colors.unowGreen};
  color: ${colors.white};
  padding: 0.5em;
  position: absolute;
  top: 12px;
  left: -99999rem;
  z-index: 100;

  &:focus {
    left: 8px;
  }
`

const SkipLinkMenu = () => {
  const { skipLinks } = useSkipLinkContext()

  return (
    <SkipLinkMenuList>
      {skipLinks.map(skipLink => (
        <li>
          <SkipLinkMenuItemLink href={skipLink.url ? skipLink.url : `#${skipLink.anchor}`}>
            {skipLink.label}
          </SkipLinkMenuItemLink>
        </li>
      ))}
    </SkipLinkMenuList>
  )
}

export default SkipLinkMenu
