import React from 'react'
import PropTypes from 'prop-types'
import { filter } from 'graphql-anywhere'
import { toInlineFragment } from 'fraql'
import gql from 'graphql-tag'
import styled from '@emotion/styled'
import Block from './Block'

const StyledBlock = styled(Block)`
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
`

const Blocks = ({ configuration, className }) => (
  <div className={className}>
    {configuration.map(conf => (
      <StyledBlock block={filter(Block.fragments.block, conf)} />
    ))}
  </div>
)

Blocks.fragments = {
  configuration: gql`
    fragment _ on Block {
      blockType
      ${toInlineFragment(Block.fragments.block)}
    }
  `,
}

Blocks.defaultProps = {
  className: undefined,
}

Blocks.propTypes = {
  configuration: PropTypes.arrayOf({
    blockType: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string,
}

export default Blocks
