import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'
import StreamlineIcon from '@unowmooc/icons'
import { useQuiz } from '../../../provider'
import { QUIZ_QUESTION_ATTEMPT_STATUS } from '../../../constants'

const Wrapper = styled.div`
  display: flex;
  padding: 20px 20px 30px 20px;
  border: ${({ theme: { colors } }) => `1px solid ${colors.mischka}`};
  border-top: ${({ correct, theme: { colors } }) => `10px solid ${correct ? colors.unowGreen : colors.bittersweet}`};
  border-radius: 10px;
`

const ThumbIcon = styled(StreamlineIcon)`
  display: block;
  font-size: 30px;
  margin-right: 20px;
  color: ${({ correct, theme: { colors } }) => (correct ? colors.unowGreen : colors.bittersweet)};
`

const Title = styled.strong`
  display: block;
  margin-bottom: ${({ hasExplanation }) => (hasExplanation ? 20 : 0)}px;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  color: ${({ theme: { colors } }) => colors.shipGray};
`

const Explanation = styled.div`
  > p {
    color: ${({ theme: { colors } }) => colors.midGray};
  }
`

const Feedback = ({ className }) => {
  const {
    currentQuestion: {
      status,
      quizQuestion: { explanation },
    },
  } = useQuiz()

  if (status === QUIZ_QUESTION_ATTEMPT_STATUS.CREATED) {
    return null
  }

  const isCorrect = status === QUIZ_QUESTION_ATTEMPT_STATUS.SUCCESS

  return (
    <Wrapper data-cy="quiz-question-feedback" correct={isCorrect} className={className}>
      <ThumbIcon icon={isCorrect ? 'thumbsup' : 'thumbsdown'} correct={isCorrect} />
      <div>
        <Title hasExplanation={!!explanation}>
          <FormattedMessage id={`quiz.question.feedback.${isCorrect ? 'correct' : 'wrong'}`} />
        </Title>
        {explanation && <Explanation dangerouslySetInnerHTML={{ __html: explanation }} />}
      </div>
    </Wrapper>
  )
}

Feedback.defaultProps = {
  className: undefined,
}

Feedback.propTypes = {
  className: PropTypes.string,
}

export default Feedback
