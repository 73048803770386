import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { UnowLoader } from '@unowmooc/react-ui-kit'
import StreamlineIcon from '@unowmooc/icons'
import { mq } from '@unowmooc/themes'

const Indicator = styled.div`
  display: inline-block;

  ${mq.sm(css`
    margin-left: 10px;
  `)};
`

const SavingIndicator = styled(UnowLoader)``

const SavedIndicator = styled(StreamlineIcon)`
  font-size: 14px;
  color: ${({ theme: { colors } }) => colors.unowGreen};
`

const SaveIndicator = ({ saving, className }) => (
  <Indicator data-cy="save-indicator" className={className}>
    {saving ? <SavingIndicator size={12} /> : <SavedIndicator icon="check" />}
  </Indicator>
)

SaveIndicator.defaultProps = {
  className: undefined,
}

SaveIndicator.propTypes = {
  saving: PropTypes.bool.isRequired,
  className: PropTypes.string,
}

export default SaveIndicator
