import React, { useState } from 'react'
import _ from 'lodash'
import gql from 'graphql-tag'
import { filter } from 'graphql-anywhere'
import { toInlineFragment } from 'fraql'
import Cookies from 'js-cookie'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { Row, Col } from 'react-grid-system'
import { Container } from '@unowmooc/react-ui-kit'
import { FormattedMessage } from 'react-intl'
import { mq } from '@unowmooc/themes'
import { isNight } from 'utils/date'
import Helmet from 'components/HelmetIntl'
import PageQuery from 'components/PageQuery'
import CompleteProfileBanner from './CompleteProfileBanner'
import SupervisedSessionsStatsList from './SupervisedSessionsStatsList'
import Participations from './Participations'
import RecommendedCourses from './RecommendedCourses'
import BrowseCatalog from './BrowseCatalog'
import Animations from './Animations'

const Wrapper = styled.div`
  position: relative;
  padding-bottom: 100px;
`

const StyledCompleteProfileBanner = styled(CompleteProfileBanner)`
  margin-bottom: 40px;

  ${mq.sm(css`
    margin-bottom: 20px;
  `)};
`

const RowWelcome = styled(Row)`
  margin-bottom: 56px;

  ${mq.sm(css`
    margin-bottom: 30px;
    text-align: center;
  `)};
`

const Title = styled.h2`
  margin-top: 50px;

  ${mq.sm(css`
    margin-top: 30px;
  `)};
`

const RowPrincipal = styled(Row)`
  margin-bottom: 50px;
`

const RowRecommendedCourses = styled(Row)`
  margin-bottom: 40px;

  ${mq.sm(css`
    margin-bottom: 6px;
  `)};
`

const DashboardPage = () => {
  const [bannerVisible, setBannerVisible] = useState(!Cookies.get('unow_hide_dashboard_banner'))

  const closeBanner = () => {
    Cookies.set('unow_hide_dashboard_banner', true)
    setBannerVisible(false)
  }

  return (
    <Wrapper>
      <Helmet title="page_titles.dashboard.title" />

      <PageQuery
        query={gql`
        query DashboardMeAndMyParticipations {
          me {
            id
            firstName
            avatar {
              id
            }
            ${toInlineFragment(CompleteProfileBanner.fragments.me)}
            supervisedSessionsStatsList: supervisedSessionStats {
              ...SupervisedSessionStatsList
            }
            participations: participants {
              ...Participations
              id
              canceledAt
              status
              professionalCertificationNumber
              advancement {
                status
              }
              session {
                id
                runningStatus
              }
            }
            recommendedCourses {
              ...RecommendedCourses
            }
            animations {
              ...Animations
            }
          }
        }
        ${SupervisedSessionsStatsList.fragment}
        ${Participations.fragment}
        ${RecommendedCourses.fragment}
        ${Animations.fragment}
      `}
        render={({
          me,
          me: { firstName, avatar, supervisedSessionsStatsList, participations, recommendedCourses, animations },
        }) => {
          const runningAnimations = _.filter(animations, {
            session: { runningStatus: 'in_progress' },
          })

          const plannedAnimations = _.filter(animations, {
            session: { runningStatus: 'planned' },
          })

          const runningSupervisedSessionStatsList = _.filter(supervisedSessionsStatsList, {
            session: { runningStatus: 'in_progress' },
          })

          const showBanner = bannerVisible && !avatar

          return (
            <>
              {showBanner && (
                <StyledCompleteProfileBanner
                  me={filter(CompleteProfileBanner.fragments.me, me)}
                  onClose={() => {
                    closeBanner()
                  }}
                />
              )}
              <Container>
                {!showBanner && (
                  <RowWelcome>
                    <Col>
                      <Title>
                        <FormattedMessage
                          id={`dashboard.welcome_${isNight() ? 'night' : 'day'}`}
                          values={{ firstName }}
                        />
                      </Title>
                    </Col>
                  </RowWelcome>
                )}

                {runningAnimations.length > 0 && (
                  <RowPrincipal>
                    <Col>
                      <Animations animations={runningAnimations} status="in_progress" />
                    </Col>
                  </RowPrincipal>
                )}
                {runningAnimations.length === 0 && plannedAnimations.length > 0 && (
                  <RowPrincipal>
                    <Col>
                      <Animations animations={plannedAnimations} status="planned" />
                    </Col>
                  </RowPrincipal>
                )}
                {runningSupervisedSessionStatsList.length > 0 && (
                  <RowPrincipal>
                    <Col>
                      <SupervisedSessionsStatsList supervisedSessionsStatsList={runningSupervisedSessionStatsList} />
                    </Col>
                  </RowPrincipal>
                )}
                <RowPrincipal>
                  <Col>
                    <Participations participations={participations} />
                  </Col>
                </RowPrincipal>
                {recommendedCourses.length > 0 && (
                  <RowRecommendedCourses>
                    <Col>
                      <RecommendedCourses courses={recommendedCourses} />
                    </Col>
                  </RowRecommendedCourses>
                )}
                <Row>
                  <Col>
                    <BrowseCatalog />
                  </Col>
                </Row>
              </Container>
            </>
          )
        }}
      />
    </Wrapper>
  )
}

export default DashboardPage
