import React from 'react'
import styled from '@emotion/styled'
import { ButtonI18n } from '@unowmooc/buttons'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import Modal from 'components/Modal'
import characterSvg from './assets/character.svg'

const ModalStyled = styled(Modal)`
  align-items: center;
`

const ContentWrapper = styled.p`
  text-align: left;
`

const Title = styled.h2`
  margin-bottom: 50px;
  text-align: center;
`

const CharacterImage = styled.img`
  margin: 30px 0;
  width: 120px;
  height: 144px;
`

const ButtonI18nStyled = styled(ButtonI18n)`
  margin: 40px 0 0;
`

const EthicsCodeModal = ({ onClose }) => (
  <ModalStyled overlayWidth={768}>
    <Title>
      <FormattedMessage id="conversation.ethics_code.title" />
    </Title>
    <ContentWrapper>
      <FormattedHTMLMessage id="conversation.ethics_code.content" />
    </ContentWrapper>
    <CharacterImage src={characterSvg} />
    <div>
      <ButtonI18nStyled variant="primary" onClick={onClose}>
        <FormattedMessage id="commons.close" />
      </ButtonI18nStyled>
    </div>
  </ModalStyled>
)

EthicsCodeModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default EthicsCodeModal
