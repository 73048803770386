import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import { Mutation } from 'react-apollo'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import { withRouter } from 'react-router-dom'
import classnames from 'classnames'
import { withUser } from 'store/UserProvider'
import { isAdmin, isUserAnimatorOfTheSession } from 'modules/user/user.utils'
import { useTracking } from 'modules/tracking'
import Form from '../Form'

import { CREATE_COMMENT, MARK_COMMENTS_AS_RESOLVED } from '../../../comments.mutations'

const AddForm = ({
  user,
  session,
  section,
  addCommentOnCache,
  fragment,
  parent,
  placeholder,
  autofocus,
  history,
  className,
  hasAnonymousCommentInThread,
}) => {
  const { sendEvent } = useTracking()
  const [markAsResolved] = useMutation(MARK_COMMENTS_AS_RESOLVED)
  const initialValues = {
    filestackId: null,
    markAsResolved: true,
    isAnonymous: parent !== null && parent.isAnonymous && parent.user !== null && parent.user.id === user.id,
  }
  const isAnimator = isUserAnimatorOfTheSession(user, session)

  const handleSubmit = createComment => (params, form, allParams) => {
    const commentCreateInput = {
      sessionId: session.id,
      sectionId: section?.id,
      parentId: parent?.id,
      text: params.text,
      isAnonymous: params.isAnonymous,
      filestackId: params.file?.fileStackId,
    }
    return createComment({ variables: { comment: commentCreateInput } }).then(
      ({ data: { createComment2: comment } }) => {
        if (!isAnimator) {
          history.replace({
            search: `?comment=${comment.id}`,
          })
        }

        if (isAnimator) {
          if (allParams.markAsResolved && parent) {
            markAsResolved({ variables: { commentsIds: [parent.id] } })
          }
          sendEvent(
            allParams.markAsResolved
              ? 'comment.send_comment_with_resolved_flag_checked'
              : 'comment.send_comment_with_resolved_flag_unchecked',
            JSON.stringify({
              courseIdentifier: comment.session.course.identifier,
              sessionIdentifier: comment.session.identifier,
              commentId: comment.id,
            }),
          )
        }

        // Sans le setTimeout on a une erreur
        // Uncaught (in promise) Error: Cannot reset() in onSubmit(), use setTimeout(form.reset)
        setTimeout(form.reset)
      },
    )
  }

  return (
    <Mutation
      mutation={CREATE_COMMENT(fragment)}
      update={(cache, { data: { createComment2: comment } }) => addCommentOnCache(cache, comment)}
    >
      {createComment => (
        <Form
          user={user}
          session={session}
          initialValues={initialValues}
          showMarkAsResolved={isAnimator && parent && parent.user.id !== user.id && !parent.isResolved}
          showIsAnonymous={!isAnimator && !isAdmin(user)}
          onSubmit={handleSubmit(createComment)}
          embed={!!parent}
          autofocus={autofocus}
          placeholder={placeholder}
          wysiwygOffset
          className={classnames('add-form', className)}
          hasAnonymousCommentInThread={hasAnonymousCommentInThread}
        />
      )}
    </Mutation>
  )
}

AddForm.fragments = {
  session: gql`
    fragment _ on Session {
      id
    }
  `,
  section: gql`
    fragment _ on Section {
      id
    }
  `,
  parent: gql`
    fragment _ on Comment {
      id
      isResolved
      isAnonymous
      user {
        id
      }
    }
  `,
}

AddForm.defaultProps = {
  section: null,
  parent: null,
  placeholder: undefined,
  autofocus: false,
  className: undefined,
  hasAnonymousCommentInThread: false,
}

AddForm.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
  addCommentOnCache: PropTypes.func.isRequired,
  user: PropTypes.shape().isRequired,
  fragment: PropTypes.shape().isRequired,
  session: propType(AddForm.fragments.session).isRequired,
  section: propType(AddForm.fragments.section),
  parent: propType(AddForm.fragments.parent),
  placeholder: PropTypes.string,
  autofocus: PropTypes.bool,
  className: PropTypes.string,
  hasAnonymousCommentInThread: PropTypes.bool,
}

export default withUser(withRouter(AddForm))
