import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import gql from 'graphql-tag'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { Alert, DataGrid } from '@unowmooc/react-ui-kit'
import Tooltip from '@unowmooc/tooltip'
import StreamlineIcon from '@unowmooc/icons'
import { colors, mq, HiddenXsDataGrid } from 'styles-resources'
import { getIndividualCourseCertificateUrl } from 'business/participant'
import { getCertificateDownloadUrl } from 'business/certificate'
import FormattedDateInterval from 'components/FormattedDateInterval'

const { DataGridCell } = DataGrid

const DownloadIcon = styled(StreamlineIcon)`
  font-size: 24px;

  ${mq.xsDataGrid(css`
    display: inline;
    margin-right: 14px;
    font-size: 20px;
  `)}
`

const Title = styled.h3`
  margin-bottom: 8px;
`

const Dates = styled.p`
  margin-bottom: 26px;
  font-size: 14px;
  line-height: 16px;
  color: ${colors.manatee};
`

class ParticipationDocuments extends Component {
  get canDownloadIndividualCourseCertificate() {
    const {
      participation: { consumeLicense, session, advancementStatus },
    } = this.props

    return consumeLicense && (advancementStatus === 'certified' || session.runningStatus === 'finished')
  }

  get canDownloadCertificate() {
    const {
      participation: { advancementStatus },
    } = this.props

    return advancementStatus === 'certified'
  }

  get documents() {
    const { canDownloadIndividualCourseCertificate, canDownloadCertificate } = this

    const {
      participation: { id, certificateNumber },
    } = this.props

    const documents = []

    if (canDownloadIndividualCourseCertificate) {
      documents.push({
        id: '1',
        url: getIndividualCourseCertificateUrl(id),
        i18nTitle: 'settings.documents.individual_course_certificate',
      })
    }

    if (canDownloadCertificate) {
      documents.push({
        id: '2',
        url: getCertificateDownloadUrl(certificateNumber),
        i18nTitle: 'settings.documents.certificate',
      })
    }

    return documents
  }

  render() {
    const {
      participation: { session },
      className,
    } = this.props

    const { documents } = this

    return (
      <div className={className}>
        <Title>{session.course.title}</Title>
        <Dates>
          <FormattedDateInterval id="commons.session_from_to" from={session.startAt} to={session.endAt} />
        </Dates>

        {!documents.length ? (
          <Alert theme="info">
            <p>
              <FormattedMessage id="settings.documents.no_document" />
            </p>
          </Alert>
        ) : (
          <DataGrid data={documents}>
            <DataGridCell
              label={<FormattedMessage id="settings.documents.document" />}
              size="large"
              hideLabelOnMobile
              verticalAlign={DataGridCell.verticalAlign.top}
              render={({ url, i18nTitle }) => (
                <a href={url} target="_blank" rel="noopener noreferrer">
                  <HiddenXsDataGrid>
                    <DownloadIcon icon="download" />
                  </HiddenXsDataGrid>
                  <FormattedMessage id={i18nTitle} />
                </a>
              )}
            />
            <DataGridCell
              label={<FormattedMessage id="settings.documents.actions" />}
              align="right"
              hideOnMobile
              render={({ url }) => (
                <Tooltip distance={15} placement="bottom-end" content={<FormattedMessage id="commons.download" />}>
                  <a href={url} target="_blank" rel="noopener noreferrer">
                    <DownloadIcon icon="download" />
                  </a>
                </Tooltip>
              )}
            />
          </DataGrid>
        )}
      </div>
    )
  }
}

ParticipationDocuments.fragment = gql`
  fragment ParticipationDocumentsFragment on Participant {
    id
    consumeLicense
    advancementStatus
    certificateNumber
    session {
      id
      runningStatus
      startAt
      endAt
      course {
        id
        title
      }
    }
  }
`

ParticipationDocuments.defaultProps = {
  className: undefined,
}

ParticipationDocuments.propTypes = {
  participation: propType(ParticipationDocuments.fragment).isRequired,
  className: PropTypes.string,
}

export default ParticipationDocuments
