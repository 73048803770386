import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'
import { useQuiz } from 'modules/quiz/provider'

const Wrapper = styled.p`
  ${mq.sm(css`
    font-size: 16px;
    line-height: 32px;
  `)};
`

const CorrectAnswers = styled.strong`
  color: ${({ theme: { colors } }) => colors.unowGreen};
`

const WrongAnswers = styled.strong`
  color: ${({ theme: { colors } }) => colors.bittersweet};
`

const Score = ({ className }) => {
  const { score, questionCounter } = useQuiz()

  return (
    <Wrapper className={className}>
      <FormattedMessage
        id="quiz.results.score"
        values={{
          correctAnswers: (
            <CorrectAnswers>
              <FormattedMessage id="quiz.results.correct" values={{ correct: score }} />
            </CorrectAnswers>
          ),
          wrongAnswers: (
            <WrongAnswers>
              <FormattedMessage id="quiz.results.wrong" values={{ wrong: questionCounter - score }} />
            </WrongAnswers>
          ),
        }}
      />
    </Wrapper>
  )
}

Score.defaultProps = {
  className: undefined,
}

Score.propTypes = {
  className: PropTypes.string,
}

export default Score
