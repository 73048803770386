import { useState } from 'react'

const useToggle = (defaultState = false) => {
  const [state, setState] = useState(defaultState)

  const open = () => {
    setState(true)
  }

  const close = () => {
    setState(false)
  }

  const toggle = () => {
    setState(!state)
  }

  return [state, { open, close, toggle }]
}

export default useToggle
