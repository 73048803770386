import { useEffect, useRef } from 'react'

const useEventListener = (eventName, handler) => {
  const ref = useRef()

  useEffect(() => {
    ref.current = handler
  }, [handler])

  useEffect(() => {
    const eventListener = event => ref.current(event)

    window.addEventListener(eventName, eventListener)

    // Remove event listener on cleanup
    return () => window.removeEventListener(eventName, eventListener)
  }, [eventName])
}

export default useEventListener
