import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl'
import UIComments from 'modules/comments/components/UI'
import { withAbility } from 'store/AbilityProvider'
import { Clickable } from '@unowmooc/buttons'
import I18nProvider from '@unowmooc/i18n'
import { useTracking } from 'modules/tracking'
import { useCourseContext } from 'modules/course/components/CourseContext/CourseContext'
import { isAdmin, isUserAnimatorOfTheSession } from 'modules/user/user.utils'
import { SEND_COMMENT_TO_SUPPORT } from '../../../../comments.mutations'

const DELETE_COMMENT = gql`
  mutation deleteComment($id: ID!) {
    deleteComment(id: $id) {
      id
      deletedAt
    }
  }
`

const DELETE_MY_COMMENT = gql`
  mutation deleteMyComment($id: ID!) {
    deleteMyComment(id: $id) {
      id
      deletedAt
    }
  }
`

const PIN_COMMENT = gql`
  mutation pinComment($id: ID!) {
    pinComment(id: $id) {
      id
      isPinned
      pinnedAt
    }
  }
`

const UNPIN_COMMENT = gql`
  mutation unpinComment($id: ID!) {
    unpinComment(id: $id) {
      id
      isPinned
      pinnedAt
    }
  }
`

const CancelAction = styled(Clickable)`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.unowGreen};

  &:hover {
    text-decoration: underline;
  }
`

const Actions = ({ comment, ability, onPinUnpin, commentAction, setCommentAction }) => {
  const [hasError, setHasError] = useState(false)
  const [deleteComment] = ability.can('delete', comment) ? useMutation(DELETE_COMMENT) : useMutation(DELETE_MY_COMMENT)
  const { courseIdentifier, sessionIdentifier } = useCourseContext()
  const { sendEvent } = useTracking()

  const [pinComment] = useMutation(PIN_COMMENT, {
    update: cache => onPinUnpin(true, cache),
  })
  const [unpinComment] = useMutation(UNPIN_COMMENT, {
    update: cache => onPinUnpin(false, cache),
  })
  const [forwardComment] = useMutation(SEND_COMMENT_TO_SUPPORT, {
    update: cache => onPinUnpin(false, cache),
  })

  // Dont use callback because with cache thats not works
  const onConfirmPinUnpinComment = () => {
    const mutate = comment.isPinned ? unpinComment : pinComment

    return mutate({ variables: { id: comment.id } })
  }

  const onConfirmSendCommentToSupport = async () => {
    const variables = { commentId: comment.id }

    const forwardCommentResult = await forwardComment({ variables })

    sendEvent(
      'comment.send_comment_to_support',
      JSON.stringify({
        courseIdentifier,
        sessionIdentifier,
        commentId: comment.id,
      }),
    )

    return forwardCommentResult
  }

  const onConfirmDeleteComment = useCallback(
    () =>
      deleteComment({ variables: { id: comment.id } }).catch(() => {
        setHasError(true)
      }),
    [],
  )

  const canEdit = ability.can('edit', comment)
  const canDelete = ability.can('delete', comment) || ability.can('deleteOwn', comment)
  const canPin = ability.can('pin', comment) && comment.parent === null
  const isAdminOrAnimatorOfTheSession =
    isUserAnimatorOfTheSession(comment.user, comment.session) || isAdmin(comment.user)

  const canForward =
    ability.can('forward', comment) && comment.isForwarded === false && isAdminOrAnimatorOfTheSession === false

  if (!canEdit && !canDelete && !canPin && !canForward) {
    return null
  }

  const beforeAction = ({ name }) => {
    if (name === 'pin') {
      setCommentAction({ pin: true, color: 'unowOrange' })
    }

    if (name === 'forward') {
      setCommentAction({ forward: true, color: 'unowOrange' })
    }

    if (name === 'edit') {
      setCommentAction({ edit: true, color: 'unowOrange' })
    }

    if (name === 'delete') {
      setCommentAction({ delete: true, color: 'bittersweet' })
    }
  }

  const onCancel = ({ name }) => {
    if (name === 'pin') {
      setCommentAction({ pin: false, color: null })
    }

    if (name === 'forward') {
      setCommentAction({ forward: false, color: null })
    }

    if (name === 'edit') {
      setCommentAction({ edit: false, color: null })
    }

    if (name === 'delete') {
      setCommentAction({ delete: false, color: null })
    }
  }

  return (
    <UIComments.CommentActions
      active={!!['edit', 'forward', 'delete', 'pin'].find(action => commentAction[action])}
      preRender={
        commentAction.edit ? (
          <CancelAction onClick={() => onCancel({ name: 'edit' })}>
            <FormattedMessage id="comment.actions.cancel" />
          </CancelAction>
        ) : null
      }
    >
      {canForward && (
        <UIComments.CommentAction
          name="forward"
          actionLabel={I18nProvider.formatMessage({ id: `comment.actions.forward` })}
          messageLabel={<FormattedMessage id="comment.actions.confirm_forward" />}
          confirmLabel={<FormattedMessage id="comment.actions.confirm" />}
          cancelLabel={<FormattedMessage id="comment.actions.cancel" />}
          active={commentAction.forward}
          onAction={onConfirmSendCommentToSupport}
          beforeAction={beforeAction}
          onCancel={onCancel}
          icon="forward"
        />
      )}
      {canPin && (
        <UIComments.CommentAction
          name="pin"
          actionLabel={I18nProvider.formatMessage({ id: `comment.actions.${comment.isPinned ? 'unpin' : 'pin'}` })}
          messageLabel={
            <FormattedMessage id={`comment.actions.${comment.isPinned ? 'confirm_unpin' : 'confirm_pin'}`} />
          }
          confirmLabel={<FormattedMessage id="comment.actions.confirm" />}
          cancelLabel={<FormattedMessage id="comment.actions.cancel" />}
          active={commentAction.pin}
          onAction={onConfirmPinUnpinComment}
          beforeAction={beforeAction}
          onCancel={onCancel}
          icon={comment.isPinned ? 'unpin' : 'pin'}
        />
      )}
      {canEdit && (
        <UIComments.CommentAction
          name="edit"
          actionLabel={I18nProvider.formatMessage({ id: 'comment.actions.edit' })}
          messageLabel={<FormattedMessage id="comment.actions.editing" />}
          cancelLabel={<FormattedMessage id="comment.actions.cancel" />}
          beforeAction={beforeAction}
          onCancel={onCancel}
          active={commentAction.edit}
          icon="pencil"
        />
      )}
      {canDelete && (
        <UIComments.CommentAction
          name="delete"
          actionLabel={I18nProvider.formatMessage({ id: 'comment.actions.delete' })}
          cancelLabel={<FormattedMessage id="comment.actions.cancel" />}
          confirmLabel={<FormattedMessage id="comment.actions.confirm" />}
          messageLabel={<FormattedMessage id="comment.actions.confirm_deletion" />}
          executingLabel={<FormattedMessage id="comment.actions.deleting" />}
          active={commentAction.delete}
          hasError={hasError}
          errorFeedback={<FormattedMessage id="comment.actions.unexpected_delete_error" />}
          onAction={onConfirmDeleteComment}
          beforeAction={beforeAction}
          onCancel={onCancel}
          icon="bin"
        />
      )}
    </UIComments.CommentActions>
  )
}

Actions.fragments = {
  comment: gql`
    fragment _ on Comment {
      id
      isPinned
      isForwarded
      isResolved
      user {
        id
        role
        animations {
          id
          session {
            id
          }
        }
      }
      session {
        id
      }
      parent {
        id
      }
      __typename # usefull for ability
    }
  `,
}

Actions.defaultProps = {
  onPinUnpin: undefined,
}

Actions.propTypes = {
  commentAction: PropTypes.shape().isRequired,
  setCommentAction: PropTypes.func.isRequired,
  comment: propType(Actions.fragments.comment).isRequired,
  ability: PropTypes.shape().isRequired,
  onPinUnpin: PropTypes.func,
}

export default withAbility(Actions)
