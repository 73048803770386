import React from 'react'
import { propType } from 'graphql-anywhere'
import gql from 'graphql-tag'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'
import { css, keyframes } from '@emotion/core'
import { mq } from '@unowmooc/themes'
import I18nProvider from '@unowmooc/i18n'
import StreamlineIcon from '@unowmooc/icons'
import { LinkButton } from '@unowmooc/react-ui-kit'
import { Button } from '@unowmooc/buttons'
import { ADMIN } from 'modules/user/user.constants'
import Badge from 'components/Badge'
import { getNextSection } from 'pages/domains/courses/sections/NavBar/utils'
import useMe from 'hooks/useMe'
import { useQuiz } from 'modules/quiz/provider'
import { QUIZ_ATTEMPT_STATUS } from 'modules/quiz/constants'
import Score from '../Score'

const zoom = keyframes`
  0% {
    transform: scale(0.7);
  }

  50% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
`

const Wrapper = styled.div`
  text-align: center;
`

const BadgeWrapper = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
  margin: 60px auto 40px auto;
  animation: ${zoom} 0.6s ease-in-out;

  ${mq.sm(css`
    width: 130px;
    height: 130px;
    margin: 55px auto 20px auto;
  `)};
`

const StyledBadge = styled(Badge)`
  width: 100%;
  height: 100%;
`

const Star = styled(StreamlineIcon)`
  position: absolute;
  color: ${({ theme: { colors } }) => colors.unowOrange};
`

const StarLeft = styled(Star)`
  font-size: 32px;
  left: -24px;

  ${mq.sm(css`
    font-size: 25px;
    left: -16px;
  `)};
`

const StarRight = styled(Star)`
  font-size: 32px;
  right: -24px;

  ${mq.sm(css`
    font-size: 25px;
    right: -16px;
  `)};
`

const StarCenter = styled(Star)`
  font-size: 40px;
  top: -46px;
  left: 50%;
  transform: translateX(-50%);

  ${mq.sm(css`
    font-size: 32px;
    top: -38px;
  `)};
`

const Description = styled.h4`
  margin-bottom: ${({ hasScore }) => (hasScore ? 15 : 50)}px;

  ${mq.sm(css`
    font-size: 20px;
    line-height: 28px;
  `)};
`

const StyledScore = styled(Score)`
  margin-bottom: 70px;

  ${mq.sm(css`
    margin-bottom: 40px;
  `)};
`

const LinksWrapper = styled.div`
  > a {
    margin: 0 10px 20px 10px;
  }
`

const Success = ({ module, session }) => {
  const {
    me: { role: userRole },
  } = useMe()
  const { status, startQuiz } = useQuiz()

  const nextSection = getNextSection({ section: { id: 'quiz', module }, session }, userRole === ADMIN)
  const quizEnded = [QUIZ_ATTEMPT_STATUS.FAILURE, QUIZ_ATTEMPT_STATUS.SUCCESS].includes(status)
  const quizFailed = status === QUIZ_ATTEMPT_STATUS.FAILURE

  return (
    <Wrapper data-cy="quiz-success-wrapper">
      <BadgeWrapper>
        <StarLeft icon="star-full" />
        <StarCenter icon="star-full" />
        <StarRight icon="star-full" />
        <StyledBadge number={module.publicPosition + 1} />
      </BadgeWrapper>

      <Description hasScore={quizEnded}>
        {quizEnded && quizFailed ? (
          <FormattedMessage id="quiz.results.failure_already_success.description" />
        ) : (
          <FormattedMessage
            id="quiz.results.success.description"
            values={{ moduleNumber: module.publicPosition + 1 }}
          />
        )}
      </Description>

      {quizEnded && <StyledScore />}

      <LinksWrapper>
        <Button
          size="large"
          theme="secondary"
          onClick={() => {
            startQuiz()
          }}
        >
          <FormattedMessage id="quiz.results.restart_link" />
        </Button>

        {nextSection && (
          <>
            {nextSection.id === 'final-exam' && (
              <LinkButton
                tag={Link}
                to={I18nProvider.getLinkRoute('/courses/{slugCourse}/{idSession}/lessons/final-exam', {
                  slugCourse: session.course.slug,
                  idSession: session.id,
                })}
                size="large"
              >
                <FormattedMessage id="quiz.results.success.start_final_exam_link" />
              </LinkButton>
            )}
            {nextSection.id !== 'final-exam' && (
              <LinkButton
                tag={Link}
                to={I18nProvider.getLinkRoute('/courses/{slugCourse}/{idSession}/lessons/{idSection}', {
                  slugCourse: session.course.slug,
                  idSession: session.id,
                  idSection: nextSection.id,
                })}
                size="large"
              >
                <FormattedMessage id="quiz.results.success.start_module_link" />
              </LinkButton>
            )}
          </>
        )}
      </LinksWrapper>
    </Wrapper>
  )
}

Success.fragments = {
  module: gql`
    fragment _ on Module {
      id
      status
      publicPosition
    }
  `,
  session: gql`
    fragment _ on Session {
      id
      hasFinalExam
      course {
        id
        slug
        modules {
          id
          status
          slug
          sections {
            id
          }
        }
      }
    }
  `,
}

Success.propTypes = {
  module: propType(Success.fragments.module).isRequired,
  session: propType(Success.fragments.session).isRequired,
}

export default Success
