import React from 'react'
import PropTypes from 'prop-types'
import keyMirror from 'keymirror'
import Select from 'components/Select'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'
import { useTracking } from 'modules/tracking'
import { useCourseContext } from 'modules/course/components/CourseContext/CourseContext'

const StyledSelect = styled(Select)`
  width: 350px;
`

const FilterSelectValues = keyMirror({
  ALL: null,
  UP: null,
  EQUAL_AND_DOWN: null,
})

const options = Object.keys(FilterSelectValues).map(value => ({
  value,
  label: <FormattedMessage id={`courses.course.skills.filter_select.${value}`} />,
}))

const FilterSelect = ({ onChange, ...props }) => {
  const { sendEvent } = useTracking()
  const { courseIdentifier, sessionIdentifier } = useCourseContext()

  const handleChange = value => {
    onChange?.(value)

    sendEvent(
      'restitution.change_filter_state',
      JSON.stringify({
        courseIdentifier,
        sessionIdentifier,
        state: value,
      }),
    )
  }

  return <StyledSelect {...props} onChange={handleChange} options={options} />
}

FilterSelect.defaultProps = {
  onChange: null,
}

FilterSelect.propTypes = {
  onChange: PropTypes.func,
}

FilterSelect.values = FilterSelectValues

export default FilterSelect
