import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import gql from 'graphql-tag'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { Row, Col } from 'react-grid-system'
import { Container, StreamlineIcon, Clickable } from '@unowmooc/react-ui-kit'
import { mq } from '@unowmooc/themes'
import I18nProvider from '@unowmooc/i18n'
import { isNight } from 'utils/date'

const Wrapper = styled.div`
  padding: 40px 0;
  background-color: ${({ theme: { colors } }) => colors.white};

  ${mq.sm(css`
    padding: 30px 0;
  `)}
`

const Title = styled.h3`
  margin-right: 24px;
  margin-bottom: 4px;
  line-height: 32px;

  ${mq.sm(css`
    line-height: 30px;
  `)}
`

const Description = styled.p`
  margin-bottom: 3px;
  font-size: 14px;
  line-height: 25px;
`

const StyledLink = styled(Link)`
  font-size: 14px;
  line-height: 25px;
`

const IconPlus = styled(StreamlineIcon)`
  margin-right: 5px;
`

const CloseButton = styled(Clickable)`
  position: absolute;
  top: -12px;
  right: 12px;
  font-size: 24px;
  color: ${({ theme: { colors } }) => colors.bombay};
  transition: ${({ theme: { transitions } }) => transitions.color};

  &:hover {
    color: ${({ theme: { colors } }) => colors.midGray};
  }
`

const CompleteProfileBanner = ({ me, onClose, className }) => (
  <Wrapper className={className}>
    <Container>
      <Row>
        <Col xs={12}>
          <Title>
            <FormattedMessage
              id={`dashboard.welcome_${isNight() ? 'night' : 'day'}`}
              values={{ firstName: me.firstName }}
            />
          </Title>
          <Description>
            <FormattedMessage id="dashboard.complete_profile.description" />
          </Description>
          <StyledLink to={I18nProvider.getLinkRoute('/settings/profile')}>
            <IconPlus icon="plus" />
            <FormattedMessage id="dashboard.complete_profile.link_label" />
          </StyledLink>
          {onClose && (
            <CloseButton onClick={onClose}>
              <StreamlineIcon icon="cross" />
            </CloseButton>
          )}
        </Col>
      </Row>
    </Container>
  </Wrapper>
)

CompleteProfileBanner.fragments = {
  me: gql`
    fragment _ on Me {
      firstName
    }
  `,
}

CompleteProfileBanner.defaultProps = {
  onClose: undefined,
  className: undefined,
}

CompleteProfileBanner.propTypes = {
  me: propType(CompleteProfileBanner.fragments.me).isRequired,
  onClose: PropTypes.func,
  className: PropTypes.string,
}

export default CompleteProfileBanner
