import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect, withRouter } from 'react-router-dom'
import I18nProvider from '@unowmooc/i18n'
import styled from '@emotion/styled'
import Link from './Link'

const Menu = styled.div`
  margin: 40px 0 30px;
`

class PageViewLayout extends Component {
  get children() {
    const { children } = this.props

    return (Array.isArray(children) ? children : [children]).filter(child => child.type.isPageViewLayout)
  }

  render() {
    const { children } = this
    const { routeIndex, location, pageView, match } = this.props
    const { url } = match

    return (
      <>
        <Menu>
          {React.Children.map(children, child =>
            React.cloneElement(child, { ...child.props, pageView, location, match }),
          )}
        </Menu>
        {routeIndex && (
          <Route exact path={url} render={() => <Redirect to={`${url}${I18nProvider.getLinkRoute(routeIndex)}`} />} />
        )}
        {children.map(({ props: { name, component: RouteComponent, ...rest } }) => (
          <Route
            key={name}
            path={`${url}/${I18nProvider.translatePath(name)}`}
            render={() => <RouteComponent {...rest} />}
          />
        ))}
      </>
    )
  }
}

PageViewLayout.defaultProps = {
  routeIndex: undefined,
}

PageViewLayout.propTypes = {
  children: PropTypes.node.isRequired,
  routeIndex: PropTypes.string,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  pageView: PropTypes.string.isRequired,
}

export { Link as PageViewLayoutLink }

export default withRouter(PageViewLayout)
