import React from 'react'
import styled from '@emotion/styled'
import { AwesomeUnowLoader } from '@unowmooc/react-ui-kit'

const Wrapper = styled.div`
  margin-top: 80px;
  text-align: center;
`

const PageLoader = () => (
  <Wrapper>
    <AwesomeUnowLoader />
  </Wrapper>
)

export default PageLoader
