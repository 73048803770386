import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import { Flex, Box } from 'reflexbox'
import styled from '@emotion/styled'
import I18nProvider from '@unowmooc/i18n'

const Bullet = styled.div`
  position: relative;

  width: 26px;
  height: 26px;

  font-weight: bold;
  line-height: 26px;
  color: ${({ theme, active }) => (active ? theme.colors.white : theme.colors.unowGreen)};
  text-align: center;

  background-color: ${({ theme, active }) => (active ? theme.colors.unowGreen : theme.colors.white)};

  border: 2px solid ${({ theme }) => theme.colors.unowGreen};
  border-radius: 50%;
  z-index: 1;
`
const HR = styled(Box)`
  position: absolute;
  top: 13px;
  right: 0;
  left: 0;

  background-color: ${({ theme }) => theme.colors.mischka};
  border-color: ${({ theme }) => theme.colors.mischka};
  border: none;

  width: 83%;
  height: 2px;
  margin: 0 auto;
  z-index: 0;
`

const Text = styled.div`
  margin-top: 9px;
  color: ${({ theme }) => theme.colors.woodsmoke};
  text-align: center;
  opacity: ${({ active }) => (active ? 1 : 0.5)};
`

const Container = styled(Flex)`
  position: relative;
  height: 76px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto 44px auto;
`

const BulletContainer = styled(Flex)`
  align-items: center;
`

const StepsLine = ({ location: { pathname } }) => {
  const routes = [
    I18nProvider.getLinkRoute('/session'),
    I18nProvider.getLinkRoute('/method'),
    I18nProvider.getLinkRoute('/add'),
    I18nProvider.getLinkRoute('/validation'),
    I18nProvider.getLinkRoute('/confirm'),
  ]
  const index = _.findIndex(routes, string => _.includes(pathname, string))

  return (
    <Container>
      <BulletContainer flexDirection="column" width={1 / 5}>
        <Bullet active={index >= 0}>1</Bullet>
        <Text active={index >= 0}>
          <FormattedMessage id="supervisor.registration.steps.course" />
        </Text>
      </BulletContainer>
      <HR as="hr" width={4 / 5} />
      <BulletContainer flexDirection="column" width={1 / 5}>
        <Bullet active={index >= 1}>2</Bullet>
        <Text active={index >= 1}>
          <FormattedMessage id="supervisor.registration.steps.method" />
        </Text>
      </BulletContainer>
      <BulletContainer flexDirection="column" width={1 / 5}>
        <Bullet active={index >= 2}>3</Bullet>
        <Text active={index >= 2}>
          <FormattedMessage id="supervisor.registration.steps.add" />
        </Text>
      </BulletContainer>
      <BulletContainer flexDirection="column" width={1 / 5}>
        <Bullet active={index >= 3}>4</Bullet>
        <Text active={index >= 3}>
          <FormattedMessage id="supervisor.registration.steps.validation" />
        </Text>
      </BulletContainer>
      <BulletContainer flexDirection="column" width={1 / 5}>
        <Bullet active={index >= 4}>5</Bullet>
        <Text active={index >= 4}>
          <FormattedMessage id="supervisor.registration.steps.confirmation" />
        </Text>
      </BulletContainer>
    </Container>
  )
}

StepsLine.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default withRouter(StepsLine)
