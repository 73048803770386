import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import gql from 'graphql-tag'
import { captureException } from '@sentry/browser'
import { Alert } from '@unowmooc/react-ui-kit'
import { FormattedMessage } from 'react-intl'
import TextBlock from './TextBlock'
import VideoBlock from './VideoBlock'
import VirtualClassroomBlock from './VirtualClassroomBlock'
import SurveyBlock from './SurveyBlock'
import ChallengeBlock from './ChallengeBlock'
import ScriptBlock from './ScriptBlock'
import IframeBlock from './IframeBlock'
import FormBlock from './FormBlock'
import InitialSkillsBlock from './InitialSkillsBlock'
import VirtualClassroomSequenceBlock from './VirtualClassroomSequenceBlock'

const renderContent = block => {
  switch (block.blockType) {
    case 'challenge':
      return <ChallengeBlock block={block} />
    case 'form':
      return <FormBlock block={block} />
    case 'iframe':
      return <IframeBlock block={block} />
    case 'initial_skills':
      return <InitialSkillsBlock />
    case 'script':
      return <ScriptBlock block={block} />
    case 'survey':
      return <SurveyBlock block={block} />
    case 'text':
      return <TextBlock block={block} />
    case 'video':
      return <VideoBlock block={block} />
    case 'virtual_classroom':
      return <VirtualClassroomBlock block={block} />
    case 'virtual_classroom_sequence':
      return <VirtualClassroomSequenceBlock block={block} />
    default:
      captureException(new Error(`[Section Block type: ${block.blockType}]: block not yet implemented`))

      return null
  }
}

class Block extends Component {
  constructor(props) {
    super(props)

    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error) {
    const { block } = this.props

    captureException(new Error(`[Section Block type: ${block.blockType}]: ${error.toString()}`))
  }

  render() {
    const { hasError } = this.state

    if (hasError) {
      return (
        <Alert>
          <FormattedMessage id="sections.content.error_unavailable" />
        </Alert>
      )
    }

    const { block, className } = this.props

    return <div className={className}>{renderContent(block)}</div>
  }
}

Block.fragments = {
  block: gql`
    fragment _ on Block {
      ${ChallengeBlock.fragment}
      ${FormBlock.fragment}
      ${IframeBlock.fragment}
      ${ScriptBlock.fragment}
      ${SurveyBlock.fragment}
      ${TextBlock.fragment}
      ${VideoBlock.fragment}
      ${VirtualClassroomBlock.fragment}
      ${VirtualClassroomSequenceBlock.fragment}
    }
  `,
}

Block.defaultProps = {
  className: undefined,
}

Block.propTypes = {
  block: propType(Block.fragments.block).isRequired,
  className: PropTypes.string,
}

export default Block
