import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'
import { withUser } from 'store/UserProvider'
import Helmet from 'components/HelmetIntl'
import Form from './Form'

const Title = styled.h2`
  margin-top: 8px;

  ${mq.sm(css`
    margin-top: 26px;
  `)};
`

const PreferencesPage = ({ user }) => (
  <>
    <Helmet title="page_titles.settings.preferences.title" />
    <Title>
      <FormattedMessage id="settings.preferences.page_title" />
    </Title>
    <Form id={user.id} />
  </>
)

PreferencesPage.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.shape.isRequired,
  }).isRequired,
}

export default withUser(PreferencesPage)
