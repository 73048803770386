import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import { Box, Flex } from 'reflexbox'
import gql from 'graphql-tag'
import { FormattedHTMLMessage } from 'react-intl'
import Tag from '@unowmooc/tags'

import Card from 'components/Card'

const LicenseCard = ({ project: { type, nbLicenses, nbAvailableLicenses, expireAt } }) => (
  <Card
    p="28px"
    sx={{
      '&:hover': {
        boxShadow: '0 13px 46px 0 rgba(0, 0, 0, 0.21)',
      },
    }}
  >
    <Flex justifyContent="space-between" flexDirection="column">
      <Flex justifyContent="space-between">
        <p>
          <FormattedHTMLMessage
            id="supervisor.welcome.messages.project"
            values={{
              type: _.capitalize(type),
              nbLicenses,
            }}
          />
        </p>
        <Box>
          {expireAt && (
            <Tag theme="lightGreen" small>
              <FormattedHTMLMessage
                id="supervisor.welcome.messages.expired"
                values={{ date: moment(expireAt).format('DD/MM/YYYY') }}
              />
            </Tag>
          )}
        </Box>
      </Flex>
      <Box>
        <br />
        <p>
          <FormattedHTMLMessage
            id="supervisor.welcome.messages.licences"
            values={{
              nbLicenses,
              nbAvailableLicenses,
            }}
          />
        </p>
      </Box>
    </Flex>
  </Card>
)

LicenseCard.propTypes = {
  project: PropTypes.shape({
    type: PropTypes.string.isRequired,
    nbLicenses: PropTypes.number.isRequired,
    nbAvailableLicenses: PropTypes.number.isRequired,
    expireAt: PropTypes.string.isRequired,
    startAt: PropTypes.string.isRequired,
  }).isRequired,
}

export const LicenseCardFragment = gql`
  fragment LicenseCard on Project {
    id
    type
    nbLicenses
    nbAvailableLicenses
    expireAt
    startAt
  }
`

export default LicenseCard
