import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { propType } from 'graphql-anywhere'
import { FormattedMessage, injectIntl } from 'react-intl'
import Tag from '@unowmooc/tags'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from 'styles-resources'
import { getSessionCode, isTagIdentifierDisplay } from 'modules/session/session.utils'
import { numericFormat } from 'utils/date'
import { colors } from '@unowmooc/themes'
import useMe from 'hooks/useMe'
import TagSessionStatus from 'components/TagSessionStatus'
import { advancementStatus as participantAdvancementStatus } from 'business/participant'
import moment from 'moment/moment'

const Wrapper = styled.div`
  position: relative;
  padding: 30px 0;

  ${mq.sm(css`
    padding: 24px 0;
    text-align: center;
  `)};
`

const Title = styled.h3`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 32px;
`

const TagStyled = styled(Tag)`
  margin-left: 20px;
`

const DateContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`

const TagSessionStatusStyled = styled(TagSessionStatus)`
  margin-left: 20px;
`

const Tagline = styled.p`
  margin-top: 16px;
  font-size: 16px;
  text-align: left;
  font-weight: ${({ bold }) => (bold ? 500 : 'normal')};
`

const Date = styled(FormattedMessage)`
  color: ${colors.midGray};
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
`

export const Banner = injectIntl(
  ({
    course: { title, tagline },
    session,
    session: { startAt, endAt, runningStatus, currentWeek },
    participation: { advancementStatus },
    intl,
    className,
  }) => {
    const { me: user } = useMe()
    const participantIsCertified = advancementStatus === participantAdvancementStatus.CERTIFIED
    const isAdminOrAnimatorUser = isTagIdentifierDisplay(session, user)

    let sessionFromToType = 'multiple_days'

    if (moment(startAt).format('YYYY-MM-DD') === moment(endAt).format('YYYY-MM-DD')) {
      sessionFromToType = 'single_day'
    }

    return (
      <Wrapper className={className}>
        <Title>
          {title}
          {isAdminOrAnimatorUser && (
            <TagStyled data-cy="session-identifier" small>
              {getSessionCode(session)}
            </TagStyled>
          )}
        </Title>
        {isAdminOrAnimatorUser && (
          <DateContainer>
            <Date
              id="commons.session_from_to"
              values={{
                from: intl.formatDate(startAt, numericFormat),
                to: intl.formatDate(endAt, numericFormat),
                type: sessionFromToType,
              }}
            />
            <TagSessionStatusStyled small status={runningStatus} currentWeek={currentWeek} />
          </DateContainer>
        )}
        <Tagline bold={participantIsCertified}>
          {participantIsCertified ? intl.formatMessage({ id: 'courses.course.congratulations' }) : tagline}
        </Tagline>
      </Wrapper>
    )
  },
)

Banner.fragments = {
  course: gql`
    fragment _ on Course {
      title
      tagline
    }
  `,
  participation: gql`
    fragment _ on Participant {
      advancementStatus
      certificateNumber
      session {
        id
        identifier
        startAt
        endAt
        runningStatus
        currentWeek
      }
    }
  `,
  session: gql`
    fragment _ on Session {
      id
      identifier
      startAt
      endAt
      runningStatus
      currentWeek
    }
  `,
}

Banner.defaultProps = {
  className: undefined,
}

Banner.propTypes = {
  course: propType(Banner.fragments.course).isRequired,
  participation: propType(Banner.fragments.participation).isRequired,
  className: PropTypes.string,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
    formatDate: PropTypes.func.isRequired,
  }).isRequired,
}

export default Banner
