import s from 'underscore.string'
import _ from 'lodash'
import moment from 'moment'
import isUrl from 'is-url'
import I18nProvider from '@unowmooc/i18n'
import { FormattedMessage } from 'react-intl'
import React from 'react'

export const required = value => {
  if (_.isString(value) || _.isNumber(value)) {
    return !s.isBlank(value) ? undefined : I18nProvider.formatMessage({ id: 'validators.required_field' })
  }

  return !_.isNil(value) ? undefined : I18nProvider.formatMessage({ id: 'validators.required_field' })
}

export const maxCharacters = max => value => {
  if (s.isBlank(value)) {
    return undefined
  }

  return value.length <= max ? undefined : I18nProvider.formatMessage({ id: 'validators.length_too_long' })
}

export const url = value => {
  if (s.isBlank(value) || isUrl(value)) {
    return undefined
  }

  return I18nProvider.formatMessage({ id: 'validators.url_invalid' })
}

export const pattern = (matcher, i18nErrorMessage) => value => {
  if (s.isBlank(value) || matcher.test(value)) {
    return undefined
  }

  return I18nProvider.formatMessage({ id: i18nErrorMessage })
}

export const email = pattern(/.+@.+\..+/, 'validators.email_invalid')

export const twitter = pattern(
  /^https?:\/\/([w.]{4})?twitter.com\/[a-zA-Z0-9_]{1,15}$/,
  'validators.twitter_url_invalid',
)

export const linkedIn = pattern(/^https?:\/\/([w.]{4})?linkedin.com\/(.+$)/, 'validators.linked_in_url_invalid')

export const website = pattern(/^https?:\/\/([w.]{4})?[a-zA-Z0-9-_.]+(\.[a-zA-Z0-9]{2,})/, 'validators.url_invalid')

export const youtubeEmbedVideoUrl = pattern(
  /^https?:\/\/www\.youtube\.com\/embed\/\S*$/,
  'validators.youtube_embed_video_url_invalid',
)

export const sameAs = (field, i18nErrorMessage) => (value = null, allValues) => {
  const sameAsValue = allValues[field] || null

  if (value === sameAsValue) {
    return undefined
  }

  return I18nProvider.formatMessage({ id: i18nErrorMessage })
}

export const mergeValidators = validators => (value, allValues) => {
  let error

  validators.find(validator => {
    error = validator(value, allValues)

    return error
  })

  return error
}

export const realDate = value => {
  if (!value) {
    return undefined
  }

  return moment(value).isValid() ? undefined : I18nProvider.formatMessage({ id: 'validators.date_invalid' })
}

export const minChoices = min => (value = []) =>
  value.length >= min ? undefined : <FormattedMessage id="validators.min_choices" values={{ number: min }} />
