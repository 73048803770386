import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import styled from '@emotion/styled'
import { FieldLayout } from '@unowmooc/react-ui-kit'
import SaveIndicator from '../SaveIndicator'

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
`

const StyledFieldLayout = styled(FieldLayout)`
  margin-bottom: 0;
`

const StyledSaveIndicator = styled(SaveIndicator)`
  position: relative;
  top: -2px;
  margin-left: 12px;
`

const AutoSaveFieldLayout = ({
  meta: {
    data: { saving, saved },
  },
  input,
  className,
  ...props
}) => (
  <Wrapper data-name={_.get(input, 'name')} className={className}>
    <StyledFieldLayout input={input} {...props} />
    {(saving || saved) && <StyledSaveIndicator saving={saving} />}
  </Wrapper>
)

AutoSaveFieldLayout.defaultProps = {
  label: undefined,
  input: undefined,
  meta: {},
  className: undefined,
}

AutoSaveFieldLayout.propTypes = {
  children: PropTypes.node.isRequired,
  input: PropTypes.shape(),
  label: PropTypes.node,
  meta: PropTypes.shape(),
  className: PropTypes.string,
}

export default AutoSaveFieldLayout
