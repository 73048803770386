import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink, withRouter } from 'react-router-dom'
import styled from '@emotion/styled'
import { colors, transitions } from 'styles-resources'

const Block = styled.div`
  min-width: 32px;
  min-height: 32px;
`

const StyledLink = styled(({ active, ...props }) => <RouterLink {...props} />)`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 48px;
  color: ${({ active }) => (active ? colors.black : colors.manatee)};
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: ${transitions.color};

  &:hover {
    color: ${colors.black};
    text-decoration: none;
  }

  position: relative;
`

const Label = styled.span`
  white-space: nowrap;
`

class Link extends Component {
  constructor(props) {
    super(props)

    const { location, domain } = props

    if (!location || !domain) {
      throw new Error('Do not use DomainLayoutLink outside DomainLayout component')
    }
  }

  render() {
    const { hidden } = this.props

    if (hidden) {
      return null
    }

    const {
      name,
      label,
      location: { pathname },
    } = this.props

    const to = `/espace-superviseur/packs/${name}`
    const active = new RegExp(`^${to}`).test(pathname)

    return (
      <StyledLink key={name} to={`/espace-superviseur/packs/${name}`} active={active}>
        <Block className="link-dropdown__symbol" />
        <Label>{label}</Label>
      </StyledLink>
    )
  }
}

Link.isDomainLayout = true

Link.defaultProps = {
  location: undefined,
  domain: undefined,
  hidden: false,
}

Link.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  domain: PropTypes.string,
  label: PropTypes.string.isRequired,
  component: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
  name: PropTypes.string.isRequired,
  hidden: PropTypes.bool,
}

export default withRouter(Link)
