import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { Link } from 'react-router-dom'
import { IntlMessage } from '@unowmooc/text'
import FormattedDate from 'components/FormattedDate'
import FormattedTime from 'components/FormattedTime'

const DateComment = ({ comment, pathname }) => (
  <Link
    to={{
      pathname,
      search: `?comment=${comment.id}`,
    }}
  >
    <IntlMessage
      i18nKey="comment.date"
      values={{
        date: <FormattedDate value={comment.createdAt} year="2-digit" month="2-digit" day="2-digit" />,
        time: <FormattedTime value={comment.createdAt} hour="2-digit" minute="2-digit" />,
      }}
    />
  </Link>
)

DateComment.fragment = gql`
  fragment DateCommentFragment on Comment {
    id
    createdAt
  }
`

DateComment.propTypes = {
  comment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
  }).isRequired,
  pathname: PropTypes.string.isRequired,
}

export default DateComment
