import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { FormattedMessage } from 'react-intl'
import { withRouter } from 'react-router-dom'
import qs from 'query-string'
import { useStateMachine } from 'little-state-machine'
import I18nProvider from '@unowmooc/i18n'

import { Flex, Box } from 'reflexbox'
import { ButtonI18n } from '@unowmooc/buttons'
import { FormState, Form, Field, Watch, WatchAndReset } from '@unowmooc/form'
import { Select } from '@unowmooc/inputs'

import Card from 'components/Card'
import { getAvailableSessionsToRegister } from 'modules/supervisor/supervisor.api'

import { getSelectorTitle, sortByTitle } from 'modules/course/course.utils'
import {
  getUniqueCourseFromSessions,
  getSessionsByCourseId,
  getPacksBySessionId,
  getSessionsByPackId,
  getUniquePacksFromSessions,
  getSessionLabelSelector,
} from 'modules/session/session.utils'
import { getPackExpireAtDateLabel } from 'modules/project/project.utils'
import VirtualClassroomsInfo from '../../../components/VirtualClassroomsInfo'
import { getSupervisionTitle } from '../../../../../../modules/supervisor/supervisor.utils'

const cleanStore = oldState => ({
  ...oldState,
  userRegistration: {},
})

const Session = ({ location: { search }, history }) => {
  const [loading, setLoading] = useState(true)
  const [sessions, setSessions] = useState([])
  const query = qs.parse(search)

  useEffect(() => {
    // Fetch
    getAvailableSessionsToRegister()
      .then(resultat => {
        const sessionsArray = _.get(resultat, 'data.data.sessions.items')
        sessionsArray.sort((a, b) => new Date(a.startAt) - new Date(b.startAt))

        setSessions(sessionsArray)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])

  const onSubmit = () => {
    history.push(I18nProvider.getLinkRoute('/supervisor/registered_users/method'))
  }

  const cameFromPack = query.packLabel && query.packId
  const defaultValues = {}

  if (query.packId) {
    defaultValues.pack = {
      value: query.packId,
      label: query.packLabel,
    }
  }

  if (query.sessionId) {
    defaultValues.session = {
      value: query.sessionId,
      label: query.sessionTitle,
    }
  }

  if (query.courseId) {
    defaultValues.course = {
      value: query.courseId,
      label: query.courseTitle,
    }
  }

  return (
    <Form
      name="userRegistration"
      defaultValues={defaultValues}
      form={{
        mode: 'onChange',
      }}
      persist
      onSubmit={onSubmit}
      mb="150px"
    >
      <Card padding="30px">
        <Flex justifyContent="space-between">
          {cameFromPack ? (
            <>
              <WatchAndReset watchFields={['pack']} resetFields={['course']} />
              <Box mr="21px" width={1 / 3}>
                <Field name="pack" required label={<FormattedMessage id="labels.pack" />}>
                  <Select
                    options={getUniquePacksFromSessions(sessions).map(pack => ({
                      value: pack.id,
                      label: `PACK ${getSupervisionTitle(pack)} - ${getPackExpireAtDateLabel(pack)}`,
                    }))}
                    clearable
                    loading={loading}
                    placeholder={<FormattedMessage id="select.pack.placeholder" />}
                  />
                </Field>
              </Box>

              <WatchAndReset watchFields={['course']} resetFields={['session']} />
              <Box mr="21px" width={1 / 3}>
                <Field name="course" required label={<FormattedMessage id="labels.course" />}>
                  <Select
                    options={sortByTitle(getUniqueCourseFromSessions(getSessionsByPackId(sessions, query.packId))).map(
                      course => ({
                        value: course.id,
                        label: getSelectorTitle(course),
                      }),
                    )}
                    loading={loading}
                    clearable
                    placeholder={<FormattedMessage id="select.course.placeholder" />}
                  />
                </Field>
              </Box>
              <Box mr="21px" width={1 / 3}>
                <Watch fields={['course']}>
                  {({ course }) => (
                    <Field name="session" required label={<FormattedMessage id="labels.session" />}>
                      <Select
                        options={getSessionsByPackId(
                          getSessionsByCourseId(sessions, course && course.value),
                          query.packId,
                        ).map((session, sessionIndex, sessionsByCourseId) => ({
                          value: session.id,
                          label: getSessionLabelSelector(session, sessionsByCourseId),
                        }))}
                        clearable
                        loading={loading}
                        isDisabled={!(course && course.value)}
                        placeholder={<FormattedMessage id="select.session.placeholder" />}
                      />
                    </Field>
                  )}
                </Watch>
              </Box>
            </>
          ) : (
            <>
              <WatchAndReset watchFields={['course']} resetFields={['session']} />
              <Box mr="21px" width={1 / 3}>
                <Field name="course" required label={<FormattedMessage id="labels.course" />}>
                  <Select
                    options={sortByTitle(getUniqueCourseFromSessions(sessions)).map(course => ({
                      value: course.id,
                      label: getSelectorTitle(course),
                    }))}
                    loading={loading}
                    clearable
                    placeholder={<FormattedMessage id="select.course.placeholder" />}
                  />
                </Field>
              </Box>

              <WatchAndReset watchFields={['session']} resetFields={['pack']} />
              <Box mr="21px" width={1 / 3}>
                <Watch fields={['course']}>
                  {({ course }) => (
                    <Field name="session" required label={<FormattedMessage id="labels.session" />}>
                      <Select
                        options={getSessionsByCourseId(sessions, course && course.value).map(
                          (session, sessionIndex, sessionsByCourseId) => ({
                            value: session.id,
                            label: getSessionLabelSelector(session, sessionsByCourseId),
                          }),
                        )}
                        clearable
                        loading={loading}
                        isDisabled={!(course && course.value)}
                        placeholder={<FormattedMessage id="select.session.placeholder" />}
                      />
                    </Field>
                  )}
                </Watch>
              </Box>
              <Box mr="21px" width={1 / 3}>
                <Watch fields={['session']}>
                  {({ session }) => (
                    <Field name="pack" required label={<FormattedMessage id="labels.pack" />}>
                      <Select
                        options={getPacksBySessionId(sessions, session && session.value).map(pack => ({
                          value: pack.id,
                          label: `PACK ${getSupervisionTitle(pack)} - ${getPackExpireAtDateLabel(pack)}`,
                        }))}
                        clearable
                        loading={loading}
                        isDisabled={!(session && session.value)}
                        placeholder={<FormattedMessage id="select.pack.placeholder" />}
                      />
                    </Field>
                  )}
                </Watch>
              </Box>
            </>
          )}
        </Flex>
        {!loading && (
          <Watch fields={['session']}>
            {({ session }) => <VirtualClassroomsInfo selectedSessionId={session?.value || null} sessions={sessions} />}
          </Watch>
        )}
      </Card>
      <Flex justifyContent="center" mt="30px">
        <FormState>
          {({ isValid }) => (
            <ButtonI18n
              variant="primary"
              type="submit"
              disabled={!isValid}
              i18nKey="supervisor.registration.buttons.continue"
            />
          )}
        </FormState>
      </Flex>
    </Form>
  )
}

Session.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
}

const ResetPage = props => {
  const [isMounted, setIsMonted] = useState(false)
  const { actions } = useStateMachine({ cleanStore })

  useEffect(() => {
    actions.cleanStore()
    setIsMonted(true)
  }, [])

  return isMounted ? <Session {...props} /> : false
}

export default withRouter(ResetPage)
