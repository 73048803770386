import React from 'react'
import { IntlMessage } from '@unowmooc/text'
import { Select } from '@unowmooc/inputs'
import QueryString from 'components/QueryString'

import { COMMENT_STATUS_SELECTOR } from '../../comment.constants'

const options = [
  {
    value: COMMENT_STATUS_SELECTOR.ALL,
    label: <IntlMessage i18nKey="expert.comments.status.all" />,
  },
  {
    value: COMMENT_STATUS_SELECTOR.RESOLVED,
    label: <IntlMessage i18nKey="expert.comments.status.resolved" />,
  },
  {
    value: COMMENT_STATUS_SELECTOR.UNRESOLVED,
    label: <IntlMessage i18nKey="expert.comments.status.unresolved" />,
  },
]

const ExpertCommentStatusSelect = props => (
  <QueryString name="commentStatus">
    <Select
      options={options}
      defaultValue={options[2]}
      placeholder={<IntlMessage i18nKey="expert.comments.status.all" />}
      {...props}
    />
  </QueryString>
)

export default ExpertCommentStatusSelect
