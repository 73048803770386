import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { FormattedHTMLMessage } from 'react-intl'
import I18nProvider from '@unowmooc/i18n'
import { Navbar } from '@unowmooc/react-ui-kit'

class Item extends Component {
  get route() {
    const { domain, route } = this.props

    return I18nProvider.getLinkRoute(`/${domain}${route ? `/${route}` : ''}`)
  }

  get isActive() {
    const { pathname } = this.props
    const { route } = this

    return new RegExp(`^${route}`).test(pathname)
  }

  render() {
    const { domain, route: routeProp, ...props } = this.props
    const { route, isActive } = this

    return (
      <Navbar.NavItem
        {...props}
        tag={Link}
        to={route}
        active={isActive}
        label={<FormattedHTMLMessage id={`domains.${domain}.${routeProp || 'title'}`} />}
      />
    )
  }
}

Item.isNavItem = true

Item.defaultProps = {
  route: '',
}

Item.propTypes = {
  pathname: PropTypes.string.isRequired,
  domain: PropTypes.string.isRequired,
  route: PropTypes.string,
}

export default Item
