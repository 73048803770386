/**
 * Object
 * category 	String. Required.
 * A top level category for these events. E.g. 'User', 'Navigation', 'App Editing', etc.
 *
 * action 	String. Required.
 * A description of the behaviour. E.g. 'Clicked Delete', 'Added a component', 'Deleted account', etc.
 *
 * label 	String. Optional.
 * More precise labelling of the related action. E.g. alongside the 'Added a component' action, we could add the name of a component as the label. E.g. 'Survey', 'Heading', 'Button', etc.
 *
 * value 	Int. Optional.
 * A means of recording a numerical value against an event. E.g. a rating, a score, etc.
 *
 * nonInteraction 	Boolean. Optional.
 * If an event is not triggered by a user interaction, but instead by our code (e.g. on page load, it should be flagged as a nonInteraction event to avoid skewing bounce rate data.
 *
 * transport 	String. Optional.
 * This specifies the transport mechanism with which hits will be sent. Valid values include 'beacon', 'xhr', or 'image'.
 *
 * @description https://github.com/react-ga/react-ga#reactgaeventargs
 */

export default {
  session: {
    timeline_menu: {
      category: 'Session',
      action: 'Click on section from the timeline menu',
      label: 'Click on timeline menu %(courseIdentifier)s|%(sessionIdentifier)s|%(sectionName)s',
    },
    download_certificate: {
      category: 'Session',
      action: 'Download certificate',
      label: 'Certificate %(courseIdentifier)s|%(sessionIdentifier)s',
    },
    open_certificate_info: {
      category: 'Session',
      action: 'Click on Add certificate to LinkedIn',
      label: 'Certificate %(courseIdentifier)s|%(sessionIdentifier)s',
    },
    navigate_to_certificate_page: {
      category: 'Session',
      action: 'Go to LinkedIn certification page',
      label: 'Certificate %(courseIdentifier)s|%(sessionIdentifier)s',
    },
    go_to_help_center: {
      category: 'Session',
      action: 'Go to help center',
      label: 'Help center from %(courseIdentifier)s|%(sessionIdentifier)s',
    },
    participants_registration_completed: {
      category: 'Session',
      action: 'Participants registration completed',
      label: 'Participants registered by supervisor in mode %(registrationMode)s',
      value: '%(registrationNumber)s',
    },
    register_participants_with_session: {
      category: 'Session',
      action: 'Register participants',
      label: 'Supervision registration %(courseIdentifier)s|%(sessionIdentifier)s',
    },
    register_participants: {
      category: 'Session',
      action: 'Register participants',
      label: 'Supervision registration',
    },
    show_virtual_classroom_tooltip: {
      category: 'Session',
      action: 'Show virtual classroom tooltip',
      label: 'Show virtual classroom tooltip',
    },
    click_on_program_page: {
      category: 'Session',
      action: 'Click on tab',
      label: 'Tab "%(clickedTab)s" clicked from %(courseIdentifier)s|%(sessionIdentifier)s',
    },
    click_on_start_course: {
      category: 'Session',
      action: 'Click on start course button',
      label: 'Click on start course button %(courseIdentifier)s|%(sessionIdentifier)s',
    },
    click_on_profile_page: {
      category: 'Session',
      action: 'Click on profile page link',
      label: 'Profile page link clicked from %(from)s',
    },
    click_on_transcript_show: {
      category: 'Session',
      action: 'Click on show transcript button',
    },
    click_on_transcript_hide: {
      category: 'Session',
      action: 'Click on hide transcript button',
    },
    click_on_transcript_hide_bottom: {
      category: 'Session',
      action: 'Click on hide transcript button (bottom)',
    },
    click_breadcrumb_session: {
      category: 'Session',
      action: 'Click on session link of the breadcrumb',
      label: 'Breadcrumb %(courseIdentifier)s|%(sessionIdentifier)s',
    },
    click_on_scroll_to_top_button: {
      category: 'Session',
      action: 'Click on scroll to top button',
      label: 'Session %(courseIdentifier)s|%(sessionIdentifier)s|%(sectionId)s',
    },
    click_on_program_menu_toggle_button: {
      category: 'Session',
      action: 'Click on program menu toggle button',
      label: '%(toggleStatus)s the program menu on %(courseIdentifier)s|%(sessionIdentifier)s',
    },
    satisfaction_non_finisher_click_on_cta_go_to_program_page: {
      category: 'Session',
      action: 'Click on show go back to training button link',
      label: 'Satisfaction non finisher %(courseIdentifier)s|%(sessionIdentifier)s',
    },
  },
  notification: {
    show_notifications: {
      category: 'Notification',
      action: 'Click on notification icon',
      label: 'Show Notifications',
    },
  },
  virtual_classroom: {
    watch_replay: {
      category: 'Virtual classroom',
      action: 'Watch replay',
      label:
        'Virtual classroom %(courseIdentifier)s|%(sessionIdentifier)s|%(virtualClassroomSequence)s|%(virtualClassroomId)s',
    },
    connect_to_virtual_classroom: {
      category: 'Virtual classroom',
      action: 'Connect to virtual classroom',
      label:
        'Virtual classroom %(courseIdentifier)s|%(sessionIdentifier)s|%(virtualClassroomSequence)s|%(virtualClassroomId)s',
    },
    confirm_presence_to_virtual_classroom: {
      category: 'Virtual classroom',
      action: 'Confirm presence to to virtual classroom',
      label:
        'Virtual classroom %(courseIdentifier)s|%(sessionIdentifier)s|%(virtualClassroomSequence)s|%(virtualClassroomId)s',
    },
    see_slots: {
      category: 'Virtual classroom',
      action: 'See slots',
      label: 'Virtual classroom %(courseIdentifier)s|%(sessionIdentifier)s|%(state)s',
    },
    click_on_review_link: {
      category: 'Virtual classroom',
      action: 'Click on review link',
      label: 'Virtual classroom %(courseIdentifier)s|%(sessionIdentifier)s|%(virtualClassroomId)s',
    },
    click_on_webex_test_link: {
      category: 'Virtual classroom',
      action: 'Click on Webex test link',
      label: 'Virtual classroom %(courseIdentifier)s|%(sessionIdentifier)s',
    },
    click_on_webex_help_link: {
      category: 'Virtual classroom',
      action: 'Click on Webex help link',
      label: 'Virtual classroom %(courseIdentifier)s|%(sessionIdentifier)s|%(virtualClassroomSequence)s',
    },
  },
  comment: {
    send_comment_with_resolved_flag_checked: {
      category: 'Comment',
      action: 'Send comment with resolved flag checked',
      label: 'Comment %(courseIdentifier)s|%(sessionIdentifier)s|%(commentId)s',
    },
    send_comment_with_resolved_flag_unchecked: {
      category: 'Comment',
      action: 'Send comment with resolved flag unchecked',
      label: 'Comment %(courseIdentifier)s|%(sessionIdentifier)s|%(commentId)s',
    },
    click_on_resolve_conversation: {
      category: 'Comment',
      action: 'Click on resolve conversation button',
      label: 'Comment parent %(courseIdentifier)s|%(sessionIdentifier)s|%(commentId)s',
    },
    click_on_unresolve_conversation: {
      category: 'Comment',
      action: 'Click on unresolve conversation button',
      label: 'Comment parent %(courseIdentifier)s|%(sessionIdentifier)s|%(commentId)s',
    },
    click_on_link_to_comments_with_comments: {
      category: 'Comment',
      action: 'Click on link to comments with comments',
      label: 'Section %(courseIdentifier)s|%(sessionIdentifier)s|%(sectionId)s',
    },
    click_on_link_to_comments_without_comments: {
      category: 'Comment',
      action: 'Click on link to comments without comments',
      label: 'Section %(courseIdentifier)s|%(sessionIdentifier)s|%(sectionId)s',
    },
    send_discussion_comment_to_support: {
      category: 'Comment',
      action: 'Click on send discussion comment to support',
      label: 'Comment %(courseIdentifier)s|%(sessionIdentifier)s|%(commentId)s',
    },
    send_comment_to_support: {
      category: 'Comment',
      action: 'Click on send comment to support',
      label: 'Comment %(courseIdentifier)s|%(sessionIdentifier)s|%(commentId)s',
    },
  },
  conversation: {
    send_message_with_resolved_flag_checked: {
      category: 'Conversation',
      action: 'Send message with resolved flag checked',
      label: 'Conversation %(courseIdentifier)s|%(sessionIdentifier)s|%(conversationId)s',
    },
    send_message_with_resolved_flag_unchecked: {
      category: 'Conversation',
      action: 'Send message with resolved flag unchecked',
      label: 'Conversation %(courseIdentifier)s|%(sessionIdentifier)s|%(conversationId)s',
    },
    click_on_resolve_conversation: {
      category: 'Conversation',
      action: 'Click on resolve conversation button',
      label: 'Conversation %(courseIdentifier)s|%(sessionIdentifier)s|%(conversationId)s',
    },
    click_on_unresolve_conversation: {
      category: 'Conversation',
      action: 'Click on unresolve conversation button',
      label: 'Conversation %(courseIdentifier)s|%(sessionIdentifier)s|%(conversationId)s',
    },
    send_conversation_to_support: {
      category: 'Conversation',
      action: 'Click on send conversation to support',
      label: 'Conversation %(courseIdentifier)s|%(sessionIdentifier)s|%(conversationId)s|%(messageId)s',
    },
  },
  restitution: {
    click_on_program_button_link: {
      category: 'Restitution',
      action: 'Click on program button link',
      label: 'Restitution  %(courseIdentifier)s|%(sessionIdentifier)s',
    },
    click_on_bic_button_link: {
      category: 'Restitution',
      action: 'Click on bic button link',
      label: 'Restitution  %(courseIdentifier)s|%(sessionIdentifier)s',
    },
    click_on_bfc_button_link: {
      category: 'Restitution',
      action: 'Click on bfc button link',
      label: 'Restitution  %(courseIdentifier)s|%(sessionIdentifier)s',
    },
    change_filter_state: {
      category: 'Restitution',
      action: 'Change filter state',
      label: 'Restitution  %(courseIdentifier)s|%(sessionIdentifier)s|%(state)s',
    },
    click_on_show_skills_button_link: {
      category: 'Restitution',
      action: 'Click on show skills button link',
      label: 'Restitution  %(courseIdentifier)s|%(sessionIdentifier)s|%(context)s',
    },
    see_skills_page: {
      category: 'Restitution',
      action: 'See skills page',
      label: 'Restitution  %(courseIdentifier)s|%(sessionIdentifier)s|%(state)s',
    },
  },
  header_menu: {
    click_on_expert_contact_button_link: {
      category: 'Header Menu',
      action: 'Click on expert contact button link',
      label: 'Header Menu',
    },
    click_on_help_menu_item: {
      category: 'Header Menu',
      action: 'Click on help menu item',
      label: 'Header Menu',
    },
    click_on_help_center_button_link: {
      category: 'Header Menu',
      action: 'Click on help center button link',
      label: 'Header Menu',
    },
    click_on_writing_to_support_button_link: {
      category: 'Header Menu',
      action: 'Click on writing to support button link',
      label: 'Header Menu',
    },
    click_on_procedure_rules_button_link: {
      category: 'Header Menu',
      action: 'Click on read the rules of procedure button link',
      label: 'Header Menu',
    },
  },
}
