import React from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { Field } from '@unowmooc/form'
import { InputText } from '@unowmooc/inputs'
import Textarea from 'components/Textarea'
import WriteMessageModalFormContainer from './WriteMessageModalFormContainer'

/**
 * Modale affichée à un participant pour écrire à son expert.
 *
 * L'expert doit être défini en entrée
 */
const WriteMessageParticipantExpertModalForm = ({ animatorUserId, sessionId, onClose }) => {
  const prepareDataOnSubmit = data => {
    const memberUserIds = [animatorUserId]
    const { subject, content } = data

    return { sessionId, memberUserIds, subject, content }
  }

  return (
    <WriteMessageModalFormContainer
      formTitle={<FormattedMessage id="conversation.write_message_label.participant" />}
      onClose={onClose}
      prepareDataOnSubmit={prepareDataOnSubmit}
      render={() => (
        <>
          <Field
            required={<FormattedMessage id="errors.isRequired" />}
            name="subject"
            label={<FormattedMessage id="conversation.form.subject" />}
          >
            <InputText />
          </Field>
          <Field
            required={<FormattedMessage id="errors.isRequired" />}
            name="content"
            label={<FormattedMessage id="conversation.form.message" />}
          >
            <Textarea minRows={5} maxRows={10} />
          </Field>
        </>
      )}
    />
  )
}

WriteMessageParticipantExpertModalForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  sessionId: PropTypes.string.isRequired,
  animatorUserId: PropTypes.string.isRequired,
}

export default WriteMessageParticipantExpertModalForm
