import _ from 'lodash'
import s from 'underscore.string'

export const mimeTypeMatches = {
  image: 'image',
  audio: 'audio',
  video: 'video',
  'application/pdf': 'pdf',
  'application/msword': 'word',
  'application/vnd.ms-word': 'word',
  'application/vnd.oasis.opendocument.text': 'word',
  'application/vnd.openxmlformats-officedocument.wordprocessingml': 'word',
  'application/vnd.ms-excel': 'excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml': 'excel',
  'application/vnd.oasis.opendocument.spreadsheet': 'excel',
  'application/vnd.ms-powerpoint': 'presentation',
  'application/vnd.openxmlformats-officedocument.presentationml': 'presentation',
  'application/vnd.oasis.opendocument.presentation': 'presentation',
  'text/plain': 'text',
  'text/html': 'html',
  'application/gzip': 'archive',
  'application/zip': 'archive',
}

export const iconMatches = {
  image: 'image',
  audio: 'music',
  video: 'video',
  pdf: 'document',
  word: 'document',
  excel: 'document',
  presentation: 'projector-screen',
  text: 'text',
  html: 'code',
  archive: 'folder-zip',
}

export const getType = mimeType => {
  if (!mimeType) {
    return 'pdf'
  }

  if (_.has(mimeTypeMatches, mimeType)) {
    return mimeTypeMatches[mimeType]
  }

  return _.find(mimeTypeMatches, (type, mimeKey) => s.include(mimeType, mimeKey)) || 'pdf'
}

export const getIcon = type => iconMatches[type]
