import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import ShadowBox from '@unowmooc/shadow-box'
import { mq } from '@unowmooc/themes'

const Wrapper = styled(ShadowBox)`
  display: inline-block;
  box-sizing: border-box;
  padding: 25px 20px;
  max-width: 360px;
  background-color: ${({ theme: { colors } }) => colors.white};
  text-align: center;

  ${mq.xs(css`
    width: 100%;
    max-width: none;
    padding: 20px;
  `)};
`

const Illu = styled.div`
  position: relative;
  height: 46px;
  margin-bottom: 14px;
`

const Legend = styled.p``

const Card = ({ illu, legend, className }) => (
  <Wrapper className={className}>
    <Illu>{illu}</Illu>
    <Legend>{legend}</Legend>
  </Wrapper>
)

Card.defaultProps = {
  className: undefined,
}

Card.propTypes = {
  illu: PropTypes.node.isRequired,
  legend: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default Card
