import React from 'react'
import { Button } from '@unowmooc/buttons'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'
import { useSequenceContext } from 'components/VirtualClassroom/Sequence/SequenceContext'
import moment from 'moment'
import DateInterval from './DateInterval'
import SlotTitle from './SlotTitle'

const Wrapper = styled.div`
  margin-bottom: 40px;
`

const SubmitButton = styled(Button, { shouldForwardProp: () => true })`
  margin-top: 6px;
`

const SingleSlot = () => {
  const { register, unregister, updatingSlot, sequence } = useSequenceContext()

  const virtualClassroom = sequence.virtualClassrooms[0]

  const handleRegister = event => {
    event.preventDefault()

    register(virtualClassroom)
  }

  const handleUnregister = event => {
    event.preventDefault()

    unregister(virtualClassroom)
  }

  return (
    <Wrapper>
      <SlotTitle title={sequence.title} sequenceNumber={sequence.sequenceNumber} />
      <div style={{ marginBottom: 16 }}>
        <DateInterval startDate={virtualClassroom.startDate} />
      </div>
      {!virtualClassroom.hasAuthenticatedParticipantRegistered && (
        <form onSubmit={handleRegister}>
          <SubmitButton
            size="small"
            type="submit"
            loading={updatingSlot}
            disabled={moment().isSameOrAfter(virtualClassroom.endDate)}
          >
            <FormattedMessage id="virtual_classroom.slot_register_label" />
          </SubmitButton>
        </form>
      )}
      {virtualClassroom.hasAuthenticatedParticipantRegistered && (
        <form onSubmit={handleUnregister}>
          <SubmitButton
            size="small"
            theme="secondary"
            type="submit"
            loading={updatingSlot}
            disabled={moment().isSameOrAfter(virtualClassroom.startDate)}
          >
            <FormattedMessage id="virtual_classroom.slot_unregister_label" />
          </SubmitButton>
        </form>
      )}
    </Wrapper>
  )
}

export default SingleSlot
