import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from 'styles-resources'
import { LinkButton } from '@unowmooc/react-ui-kit'
import StreamlineIcon from '@unowmooc/icons'

const StyledLinkButton = styled(LinkButton, { shouldForwardProp: prop => prop !== 'width' })`
  width: ${({ width }) => width};
  white-space: nowrap;

  ${mq.sm(css`
    width: 100%;
    padding-left: 16px !important;
    padding-right: 16px !important;
    text-align: center;
  `)};
`

const Icon = styled(StreamlineIcon)`
  margin-left: 10px;
  font-size: ${({ size }) => (size === 'small' ? 16 : 24)}px;
  line-height: 15px;
  color: ${({ color }) => color};
`

const LinkButtonIcon = ({ icon, iconColor, children, size, className, ...props }) => (
  <StyledLinkButton size={size} className={className} {...props}>
    {children}
    <Icon icon={icon} color={iconColor} size={size} />
  </StyledLinkButton>
)

LinkButtonIcon.defaultProps = {
  className: undefined,
  iconColor: undefined,
  size: 'medium',
  width: 'auto',
}

LinkButtonIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  iconColor: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  width: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default LinkButtonIcon
