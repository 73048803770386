import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import ShadowBox from '@unowmooc/shadow-box'
import { mq, colors } from 'styles-resources'

const Wrapper = styled.div`
  margin-top: 36px;

  ${mq.sm(css`
    margin-bottom: 30px;
  `)};

  &:last-child {
    margin-bottom: 0;
  }
`

const Title = styled.h4`
  margin-bottom: 12px;
  font-size: 18px;
`

const Bloc = styled(ShadowBox)`
  padding: 30px;
  background-color: ${colors.white};
  overflow: hidden;
`

const InfoBloc = ({ title, children, className }) => (
  <Wrapper className={className}>
    <Title>{title}</Title>
    <Bloc>{children}</Bloc>
  </Wrapper>
)

InfoBloc.defaultProps = {
  className: undefined,
}

InfoBloc.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default InfoBloc
