import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import styled from '@emotion/styled'
import TippyTooltip from '@unowmooc/tooltip'
import { Clickable } from '@unowmooc/buttons'
import StreamlineIcon from '@unowmooc/icons'
import Emoji from '@unowmooc/emoji'
import Reaction from './Reaction'

const StyledReactions = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row wrap;

  @media (max-width: ${({ theme }) => theme.screens.xsMax}) {
    align-items: center;
  }
`

const StyledEmoji = styled(Emoji)`
  transition: transform 0.15s cubic-bezier(0.2, 0, 0.13, 2);
`

const TooltipItem = styled(Clickable)`
  margin: 4px 0 0 15px;

  &:first-of-type {
    margin-left: 0;
  }

  &:hover {
    ${StyledEmoji} {
      transform: scale(1.2);
    }
  }
`

const TooltipContent = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;

  &:first-of-type {
    margin-left: 0;
  }
`

const ReactionWrapper = styled.div`
  margin-top: 18px;
  margin-right: 20px;
`

const IconSmile = styled(StreamlineIcon)`
  font-size: 22px;
`

const IconPlus = styled(StreamlineIcon)`
  margin-left: 5px;
`

const ButtonReaction = styled(Clickable)`
  display: block;
  margin-top: 18px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.bombay};
  transition: color 0.3s ease;

  &.tippy-active {
    color: ${({ theme }) => theme.colors.midGray};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.midGray};
  }

  &:focus {
    outline: none;
  }
`

const reactionTypes = ['thumbsup', 'thinkingface', 'smile', 'tada', 'rocket', 'clap']

const sortReactions = reactions => _.sortBy(reactions, ({ shortname }) => reactionTypes.indexOf(shortname))

const Reactions = ({ reactions, onNewReaction, renderReaction }) => {
  const tooltipContent = (
    <TooltipContent>
      {reactionTypes.map(reactionType => (
        <TooltipItem onClick={onNewReaction(reactionType)} key={reactionType}>
          <StyledEmoji name={reactionType} size={22} />
        </TooltipItem>
      ))}
    </TooltipContent>
  )

  return (
    <StyledReactions>
      {sortReactions(reactions).map(reaction => (
        <ReactionWrapper key={reaction.id}>{renderReaction(reaction)}</ReactionWrapper>
      ))}
      <TippyTooltip
        content={tooltipContent}
        placement="right"
        flipBehavior={['right', 'top']}
        theme="white"
        trigger="click"
        interactive
      >
        <ButtonReaction>
          <IconSmile icon="smiley-smile" />
          <IconPlus icon="plus" />
        </ButtonReaction>
      </TippyTooltip>
    </StyledReactions>
  )
}

Reactions.Reaction = Reaction

Reactions.propTypes = {
  reactions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      shortname: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onNewReaction: PropTypes.func.isRequired,
  renderReaction: PropTypes.func.isRequired,
}

export default Reactions
