import React from 'react'
import SkillsForm from 'components/SkillsForm'
import { screens } from '@unowmooc/themes'
import I18nProvider from '@unowmooc/i18n'
import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl'
import { useCourseContext } from 'modules/course/components/CourseContext/CourseContext'
import { Redirect } from 'react-router-dom'
import SkipLinkAnchor from 'modules/skipLink/SkipLinkAnchor'

const Wrapper = styled.div`
  background-color: white;
  min-height: 100vh;
  padding-top: 60px;
`
const Container = styled.div`
  max-width: ${screens.mdMin};
  margin: auto;
  padding: 0 16px;
`

const Heading = styled.h2`
  margin-bottom: 32px;
`
const FinalSkillsFormPage = () => {
  const { hasBicAnswered, slugCourse, sessionId } = useCourseContext()

  if (!hasBicAnswered) {
    return (
      <Redirect
        to={`${I18nProvider.getLinkRoute('/courses/{slugCourse}/{sessionId}/initial_skills_form', {
          slugCourse,
          sessionId,
        })}`}
      />
    )
  }

  return (
    <Wrapper>
      <Container>
        <SkipLinkAnchor label={<FormattedMessage id="a11y.skip_to_content" />} anchor="content" priority={1} />
        <Heading>
          <FormattedMessage id="survey.bfc_page_title" />
        </Heading>
        <SkillsForm context={SkillsForm.contexts.BFC} />
      </Container>
    </Wrapper>
  )
}

export default FinalSkillsFormPage
