import React from 'react'
import PropTypes from 'prop-types'
import { useSkipLinkContext } from 'modules/skipLink/provider'

const SkipLinkAnchor = ({ label, anchor, priority }) => {
  const { updateSkipLinks } = useSkipLinkContext()

  React.useEffect(() => {
    const skipLink = { label, anchor, priority }

    updateSkipLinks(state => [...state, skipLink])

    return () => {
      updateSkipLinks(state => state.filter(skipLinkState => skipLinkState !== skipLink))
    }
  }, [])

  // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid
  return <a id={anchor} tabIndex="-1" />
}

SkipLinkAnchor.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  anchor: PropTypes.string.isRequired,
  priority: PropTypes.number.isRequired,
}

export default SkipLinkAnchor
