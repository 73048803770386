import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl'
import Tag from '@unowmooc/tags'
import { STATUS_LIVE, STATUS_NEXT } from './constantes'
import { useSequenceContext } from './SequenceContext'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  text-align: left;
  color: ${({ theme }) => theme.colors.woodsmoke};
  font-size: 24px;
  line-height: 38px;
`

const Description = styled.h3`
  margin-right: 10px;
  line-height: 30px;
`

const SequenceTitle = ({ sequenceStatus, sequenceTitle, className }) => {
  const { sequenceTitlePrefix, sequence } = useSequenceContext()

  return (
    <Wrapper className={className}>
      <Description>
        {sequenceTitle ? (
          <>
            {sequenceTitlePrefix}&nbsp;-&nbsp;{sequenceTitle}
          </>
        ) : (
          <FormattedMessage id="virtual_classroom.label_cms_block" values={{ number: sequence.sequenceNumber }} />
        )}
      </Description>
      {(sequenceStatus === STATUS_NEXT || sequenceStatus === STATUS_LIVE) && (
        <Tag theme="grey" small>
          <FormattedMessage id="virtual_classroom.next_al" />
        </Tag>
      )}
    </Wrapper>
  )
}

SequenceTitle.defaultProps = {
  className: undefined,
  sequenceTitle: null,
}

SequenceTitle.propTypes = {
  sequenceStatus: PropTypes.oneOf(['programed', 'next', 'live', 'done']).isRequired,
  sequenceTitle: PropTypes.string,
  className: PropTypes.string,
}

export default SequenceTitle
