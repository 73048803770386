import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

const SlotTitle = ({ title, sequenceNumber }) => (
  <h3>
    {title ? (
      <>
        {sequenceNumber} - {title}
      </>
    ) : (
      <FormattedMessage id="guide.slides.virtualClassroom.empty_title_label" values={{ sequenceNumber }} />
    )}
  </h3>
)

SlotTitle.defaultProps = {
  title: null,
}

SlotTitle.propTypes = {
  title: PropTypes.string,
  sequenceNumber: PropTypes.number.isRequired,
}

export default SlotTitle
