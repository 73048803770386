import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { transitions, colors } from 'styles-resources'
import { Clickable } from '@unowmooc/react-ui-kit'
import UserAvatar from 'components/UserAvatar'

const Button = styled(Clickable, { shouldForwardProp: prop => prop !== 'active' })`
  position: relative;
  height: 60px;
  padding: 0 21px;
  transition: ${transitions.backgroundColor}, ${transitions.color};
  background-color: ${({ active }) => (active ? colors.unowGreen : undefined)};
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 14px;
  line-height: 16px;
  color: ${({ active }) => (active ? colors.white : colors.midGray)};
  cursor: pointer;
  border: 0;
`

const Target = ({ active, onClick, ...props }) => (
  <Button active={active} onClick={onClick} {...props}>
    <UserAvatar size={24} />
  </Button>
)

Target.defaultProps = {
  active: false,
}

Target.propTypes = {
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool,
}

export default Target
