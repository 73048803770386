import React from 'react'
import styled from '@emotion/styled'
import { colors, mq } from '@unowmooc/themes'
import { css } from '@emotion/core'
import { UnowLogo } from '@unowmooc/react-ui-kit'
import PropTypes from 'prop-types'
import { Visible } from 'react-grid-system'

const Grid = styled.div`
  display: grid;
  grid-template-columns:
    minmax(auto, 500px)
    minmax(920px, 1fr);
  grid-auto-rows: 100vh;

  ${mq.sm(css`
    grid-template-columns: none;
    grid-auto-rows: auto;
  `)};
`

const HeaderMobile = styled.div`
  padding: 16px 24px;
`

const HeaderDesktop = styled.div`
  display: flex;
  padding: 0px 24px;
  z-index: 1;
`

const HeaderLogo = styled(UnowLogo)`
  margin-bottom: 40px;
`

const HeaderTitle = styled.div`
  margin-bottom: 16px;
`

const Picture = styled.div`
  background-color: ${colors.shipGray};
  background-image: ${({ pictureUrls: { desktop } }) => `url(${desktop})`};
  background-size: 500px 1024px;
  background-repeat: no-repeat;
  background-position: center;

  ${({ pictureUrls: { mobile } }) =>
    mq.sm(css`
      background-size: cover;
      background-image: url(${mobile});
      height: 200px;
    `)};
`

const Main = styled.div`
  display: grid;
  grid-template-rows: 80px 1fr;
  grid-auto-rows: 100vh;
  padding: 16px 0px;

  ${mq.sm(css`
    display: block;
  `)};
`

const Content = styled.div`
  display: grid;
  align-items: center;
  padding: 16px 24px;
  overflow-y: auto;
  max-height: 100vh;

  ${mq.sm(css`
    display: block;
    max-height: none;
    overflow: initial;
  `)};

  h2 {
    margin-bottom: 40px;
  }

  h3 {
    margin-bottom: 16px;
    font-family: Ubuntu, Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 26px;
  }

  p {
    margin-bottom: 28px;

    ul {
      margin: 0;
      li {
        list-style-type: disc;
      }
    }
  }
`

const ContentNavigation = styled.div`
  display: grid;
  justify-content: flex-end;
  margin-top: 40px;

  ${mq.sm(css`
    justify-content: center;
  `)}

  & > div {
    ${mq.sm(css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    `)};
  }
`

const LeaveButtonContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;

  & > button {
    font-size: 14px;
  }
`

const SlideLayout = ({
  title,
  pictureUrls,
  content,
  previousButton,
  removeNavigationButton,
  nextButton,
  leaveButton,
  breadcrumb,
}) => (
  <Grid>
    <Visible xs sm>
      <HeaderMobile>
        <HeaderLogo width={84} height={24} />
        <HeaderTitle>
          {title && <h2>{title}</h2>}
          {breadcrumb}
        </HeaderTitle>
      </HeaderMobile>
    </Visible>
    <Picture pictureUrls={pictureUrls} />
    <Main>
      <Visible md lg xl>
        <HeaderDesktop>
          <HeaderLogo width={160} height={45} />
          {breadcrumb}
        </HeaderDesktop>
      </Visible>
      <Content>
        <div>
          <Visible md lg xl>
            {title && <h2>{title}</h2>}
          </Visible>
          {content}
          {!removeNavigationButton && (
            <ContentNavigation>
              <div>
                {previousButton || <div />}
                {nextButton || <div />}
              </div>
              <LeaveButtonContainer>{leaveButton}</LeaveButtonContainer>
            </ContentNavigation>
          )}
        </div>
      </Content>
    </Main>
  </Grid>
)

SlideLayout.propTypes = {
  title: PropTypes.node.isRequired,
  pictureUrls: PropTypes.shape({
    mobile: PropTypes.string.isRequired,
    desktop: PropTypes.string.isRequired,
  }).isRequired,
  content: PropTypes.node.isRequired,
  removeNavigationButton: PropTypes.bool.isRequired,
  previousButton: PropTypes.node.isRequired,
  leaveButton: PropTypes.node.isRequired,
  nextButton: PropTypes.node.isRequired,
  breadcrumb: PropTypes.node.isRequired,
}

export default SlideLayout
