import React from 'react'
import PropTypes from 'prop-types'
import { propType, filter } from 'graphql-anywhere'
import gql from 'graphql-tag'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'
import ShadowBox from '@unowmooc/shadow-box'
import { toInlineFragment } from 'fraql'
import Score from '../Score'
import Badge from './Badge'
import illuCertificate from './assets/certificate.svg'
import ShareResult from '../ShareResult'

const Wrapper = styled.div`
  text-align: center;
`

const StyledBadge = styled(Badge)`
  margin: 60px auto 30px auto;

  ${mq.sm(css`
    margin: 20px auto 20px auto;
  `)};
`

const Description = styled.h4`
  margin-bottom: 15px;
`

const StyledScore = styled(Score)`
  margin-bottom: 30px;
`

const Box = styled(ShadowBox)`
  margin-bottom: 70px;
  padding: 30px;

  ${mq.sm(css`
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 30px;
    padding: 20px;
  `)};
`

const MyCertificate = styled.h3`
  color: ${({ theme }) => theme.colors.woodsmoke};
  margin-bottom: 20px;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
`

const CertificateImg = styled.img`
  display: block;
  margin: 0 auto 16px auto;
`

const Success = ({ participant, course, sessionIdentifier }) => (
  <Wrapper>
    <StyledBadge />

    <Description>
      <FormattedMessage id="final_exam.results.success.description_1" />
      <br />
      <FormattedMessage id="final_exam.results.success.description_2" />
    </Description>

    <StyledScore participant={filter(Score.fragments.participant, participant)} />

    <Box>
      <CertificateImg src={illuCertificate} />
      <MyCertificate>
        <FormattedMessage id="courses.course.my_certificate" />
      </MyCertificate>
      <ShareResult
        certificateNumber={participant.certificateNumber}
        certificateValidatedAt={participant.certificateValidatedAt}
        course={course}
        sessionIdentifier={sessionIdentifier}
      />
    </Box>
  </Wrapper>
)

Success.fragments = {
  participant: gql`
    fragment _ on Participant {
      id
      certificateNumber
      certificateValidatedAt
      lastFinalExamQuizAttempt {
        id
        score
      }
      ${toInlineFragment(Score.fragments.participant)}
    }
  `,
  course: gql`
    fragment _ on Course {
      identifier
      title
    }
  `,
}

Success.propTypes = {
  participant: propType(Success.fragments.participant).isRequired,
  course: propType(Success.fragments.course).isRequired,
  sessionIdentifier: PropTypes.string.isRequired,
}

export default Success
