import 'whatwg-fetch' // polyfill for fetch
import 'es6-promise/auto' // polyfill for Promise
import 'location-origin' // polyfill for window.location.origin
import 'url-polyfill' // polyfill for URLSearchParams and URL
import 'array-flat-polyfill'
import '@fastly/performance-observer-polyfill/polyfill' // polyfill for PerformanceObserver
import 'date-time-format-timezone/build/browserified/date-time-format-timezone-no-data-min'
import 'date-time-format-timezone/build/browserified/data/metazone'
import 'date-time-format-timezone/build/browserified/data/locales/locale-fr'
import 'date-time-format-timezone/build/browserified/data/timezones/tzdata-europe-paris'
import '@unowmooc/react-ui-kit/lib/fonts/main.css'

import React from 'react'
import ReactDOM from 'react-dom'
import I18nProvider from '@unowmooc/i18n'
import { AppContainer } from 'react-hot-loader'
import moment from 'moment'
import * as Sentry from '@sentry/browser'
import Files from '@unowmooc/files'
import { TrackingProvider } from 'modules/tracking'
import config from 'config'

import en from 'i18n/en/locale.json'
import fr from 'i18n/fr/locale.json'
import routes from 'i18n/fr/routes.json'

/**
 * Nous importons `utils/graphql` pour qu'il puisse configurer GraphQL correctement lors des appels.
 * Utile ici pour désactiver les messages d'avertissement sur les noms de fragments identiques.
 * Il doit être appelé avant components/App pour éviter que le traitement GQL soit fait en amont.
 */
import 'utils/graphql'
import App from './components/App'

Files.init(config.env.FILESTACK_API_KEY)

if (config.env.ENABLE_SENTRY) {
  Sentry.init({
    dsn: 'https://3b4bff15296a4625869ce7ed77b77f24@o61817.ingest.sentry.io/1312886',
    environment: config.env.BUILD_ENV,
    ignoreErrors: [/TypeError: Failed to fetch/, /Network error: Failed to fetch/, /You can not update entity/],
  })
}

I18nProvider.addLocale('en', { ...en, routes })
I18nProvider.addLocale('fr', { ...fr, routes })

moment.locale('fr')

ReactDOM.render(
  <AppContainer warnings={false}>
    <TrackingProvider>
      <App />
    </TrackingProvider>
  </AppContainer>,
  document.getElementById('app'),
)

// Hot Module Replacement API
if (module.hot) {
  module.hot.accept()
}
