/* eslint-disable no-process-env */

import merge from 'deepmerge'
import debugFactory from 'debug'

import defaultConfig from './default'

const config = require(`./${process.env.BUILD_ENV}`).default

export const debug = debugFactory('app:hub:config')

export const mergeConfig = (target, object) => {
  try {
    if (!target || !object) {
      throw new Error('Target or object are undefined')
    }

    if (typeof object === 'string') {
      return merge(target, JSON.parse(object))
    }

    return merge(target, object)
  } catch (error) {
    debug(error)
  }

  return target || {}
}

export default mergeConfig(defaultConfig, config)
