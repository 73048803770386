import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { propType, filter } from 'graphql-anywhere'
import gql from 'graphql-tag'
import _ from 'lodash'
import { toInlineFragment } from 'fraql'
import { FormattedMessage } from 'react-intl'
import UIComments from 'modules/comments/components/UI'
import Comment from '../Comment'
import AddForm from '../AddForm'

const Answers = ({
  session,
  answers,
  user,
  startCollapsed,
  isThreadExpert,
  highlightedCommentId,
  historyReplace,
  pathname,
}) => {
  const [collapsed, setCollapsed] = useState(startCollapsed)

  useEffect(() => {
    if (collapsed && _.find(answers, { id: highlightedCommentId })) {
      setCollapsed(false)
    }
  }, [highlightedCommentId, answers])

  return (
    <UIComments.Answers
      answers={answers}
      render={answer => (
        <Comment
          child
          isThreadExpert={isThreadExpert}
          comment={filter(Comment.fragments.comment, answer)}
          session={filter(Comment.fragments.session, session)}
          user={filter(Comment.fragments.user, user)}
          historyReplace={historyReplace}
          pathname={pathname}
          highlighted={answer.id === highlightedCommentId}
        />
      )}
      onUncollapseButtonClick={() => {
        setCollapsed(false)
      }}
      collapsed={collapsed}
      uncollapseButtonLabel={
        <FormattedMessage id="comment.collapsed_answers_link" values={{ nbAnswers: answers.length }} />
      }
    />
  )
}

Answers.fragments = {
  session: gql`
    fragment _ on Session {
      ${toInlineFragment(AddForm.fragments.session)}
    }
  `,
  user: gql`
    fragment _ on User {
      ${toInlineFragment(Comment.fragments.user)}
    }
  `,
  comment: gql`
    fragment _ on Comment {
      id
      ${toInlineFragment(Comment.fragments.comment)}
    }
  `,
}

Answers.defaultProps = {
  highlightedCommentId: undefined,
  startCollapsed: false,
  isThreadExpert: false,
}

Answers.propTypes = {
  answers: PropTypes.arrayOf(propType(Answers.fragments.comment)).isRequired,
  session: propType(Answers.fragments.session).isRequired,
  user: PropTypes.shape().isRequired,
  historyReplace: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
  highlightedCommentId: PropTypes.string,
  startCollapsed: PropTypes.bool,
  isThreadExpert: PropTypes.bool,
}

export default Answers
