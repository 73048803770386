import styled from '@emotion/styled'

const Timeline = styled.div`
  & > div {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  position: relative;
  padding-bottom: 30px; // permet de laisser afficher le box-shadow du dernier element

  &:before {
    position: absolute;
    content: '';
    top: 0;
    bottom: 30px;
    left: 40px;
    width: 1px;
    background-color: ${({ theme: { colors } }) => colors.bombay};
  }
`

export default Timeline
