import _ from 'lodash'
import I18nProvider from '@unowmooc/i18n'
import { FORM_ERROR } from 'final-form'

export function formatGraphQLAPIErrors(graphQLErrors) {
  return _.reduce(
    graphQLErrors,
    (result, { property, code, constraint, message }) => {
      let formattedMessage

      try {
        if (constraint) {
          formattedMessage = I18nProvider.formatMessage({ id: `api_errors.constraints.${constraint}` })
        } else if (code) {
          formattedMessage = I18nProvider.formatMessage({ id: `api_errors.${code}` })
        } else {
          formattedMessage = message
        }
      } catch (e) {
        // unreferenced API error message
        formattedMessage = message
      }

      return _.set({ ...result }, property || FORM_ERROR, formattedMessage)
    },
    {},
  )
}

export function dataToSelectOptions(data, i18nLabelPrefix) {
  return _.map(data, value => {
    const option = { value }

    try {
      option.label = I18nProvider.formatMessage({ id: `${i18nLabelPrefix}.${value}` })
    } catch (error) {
      option.label = value
    }

    return option
  })
}

export default { formatGraphQLAPIErrors, dataToSelectOptions }
