import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { colors, rgba, mq } from '@unowmooc/themes'
import { Visible } from 'react-grid-system'
import SaveIndicator from '../SaveIndicator'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;

  &:hover {
    background-color: ${rgba(colors.whiteLilac, 0.5)};
  }

  ${mq.sm(css`
    display: block;
    padding: 12px 15px;
  `)};
`

const Label = styled.p`
  ${mq.sm(css`
    margin-bottom: 10px;
  `)};
`

const ControlWrapper = styled.div`
  white-space: nowrap;
`

const StyledSaveIndicator = styled(SaveIndicator)`
  margin-right: 10px;

  ${mq.sm(css`
    margin-left: 10px;
  `)};
`

const Error = styled.div`
  color: ${colors.bittersweet};
`

class InlineAutoSaveFieldLayout extends Component {
  get showError() {
    const {
      meta: { active, error, submitError, pristine, submitFailed },
    } = this.props

    return (error || submitError) && !active && (submitFailed || !pristine)
  }

  get showErrorMessage() {
    if (!this.showError) {
      return false
    }

    const {
      meta: { error, submitError },
    } = this.props

    return (error && error !== true) || (submitError && submitError !== true)
  }

  render() {
    const {
      label,
      input,
      meta: {
        error,
        submitError,
        data: { saving, saved },
      },
      children,
      className,
    } = this.props

    const { showError, showErrorMessage } = this

    return (
      <Wrapper data-cy="setting-field" data-name={_.get(input, 'name')} className={className}>
        <Label>{label}</Label>

        <ControlWrapper>
          {(saving || saved) && (
            <Visible md lg xl>
              <StyledSaveIndicator saving={saving} />
            </Visible>
          )}

          {React.cloneElement(children, { ...input, error: showError })}

          {(saving || saved) && (
            <Visible xs sm>
              <StyledSaveIndicator saving={saving} />
            </Visible>
          )}
        </ControlWrapper>

        {showErrorMessage && <Error>{error || submitError}</Error>}
      </Wrapper>
    )
  }
}

InlineAutoSaveFieldLayout.defaultProps = {
  label: undefined,
  input: undefined,
  meta: {},
  className: undefined,
}

InlineAutoSaveFieldLayout.propTypes = {
  children: PropTypes.node.isRequired,
  input: PropTypes.shape(),
  label: PropTypes.node,
  meta: PropTypes.shape(),
  className: PropTypes.string,
}

export default InlineAutoSaveFieldLayout
