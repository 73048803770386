import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { withRouter } from 'react-router-dom'
import { Visible } from 'react-grid-system'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'
import { FilterDropdown } from '@unowmooc/react-ui-kit'
import SearchFilter from './SearchFilter'
import Counter from './Counter'

const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;

  ${mq.md(css`
    flex-direction: column;
  `)}
`

const ToolbarLeft = styled.div`
  position: relative;
  display: flex;
  align-items: baseline;
  width: ${({ hasRightItem }) => (hasRightItem ? 'auto' : '100%')};

  ${mq.xs(css`
    display: block;
  `)}
`

const ToolbarRight = styled.div`
  position: relative;
  display: flex;
  align-items: baseline;

  ${mq.md(css`
    margin-top: 20px;
  `)}
`

const CounterFilterWrapper = styled.div`
  display: flex;
  margin-right: 30px;

  ${mq.xs(css`
    position: relative;
    margin-top: 20px;
    margin-right: 0;
  `)}
`

const StyledFilterDropdown = styled(FilterDropdown)`
  margin-left: 30px;
`

class DataGridToolbar extends Component {
  get filterDropdownStartVisible() {
    const { location } = this.props

    const searchParams = new URLSearchParams(location.search)

    return searchParams.has('advancementStatus')
  }

  render() {
    const { renderFilters, renderOnlyFilter, withSearch, withCounter, className, rightItem } = this.props

    return (
      <Toolbar className={className}>
        <ToolbarLeft hasRightItem={!!rightItem}>
          {withSearch && (
            <Visible xs>
              <SearchFilter />
            </Visible>
          )}
          {renderOnlyFilter && renderFilters && renderFilters()}
          {(withCounter || renderFilters) && (
            <CounterFilterWrapper>
              {withCounter && <Counter />}

              {!renderOnlyFilter && renderFilters && (
                <StyledFilterDropdown
                  startVisible={this.filterDropdownStartVisible}
                  label={<FormattedMessage id="datagrid.toolbar.filter" />}
                >
                  {renderFilters()}
                </StyledFilterDropdown>
              )}
            </CounterFilterWrapper>
          )}

          {withSearch && (
            <Visible sm md lg xl>
              <SearchFilter />
            </Visible>
          )}
        </ToolbarLeft>
        {rightItem && <ToolbarRight>{rightItem}</ToolbarRight>}
      </Toolbar>
    )
  }
}

DataGridToolbar.defaultProps = {
  renderFilters: undefined,
  withSearch: true,
  withCounter: true,
  className: undefined,
  rightItem: undefined,
  renderOnlyFilter: false,
}

DataGridToolbar.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  renderOnlyFilter: PropTypes.bool,
  renderFilters: PropTypes.func,
  withSearch: PropTypes.bool,
  withCounter: PropTypes.bool,
  className: PropTypes.string,
  rightItem: PropTypes.node,
}

export default withRouter(DataGridToolbar)
