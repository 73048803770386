import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Tag from '@unowmooc/tags'

const TagParticipantType = ({ type, ...props }) => (
  <Tag {...props} theme={Tag.themes.lightGrey}>
    <FormattedMessage id={`participant.type.${type}`} />
  </Tag>
)

TagParticipantType.propTypes = {
  type: PropTypes.oneOf(['mooc', 'demo', 'project', 'observer']).isRequired,
}

export default TagParticipantType
