import gql from 'graphql-tag'
import styled from '@emotion/styled'
import { useMutation } from '@apollo/react-hooks'
import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Alert, Box, Button, RadioGroup } from '@unowmooc/react-ui-kit'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import CheckboxGroup from 'components/CheckboxGroup'
import { useCourseContext } from 'modules/course/components/CourseContext/CourseContext'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Field from '../../../../../../../../../modules/collector/components/Field'
import { toRelative } from '../../../../../../../../../utils/url'

const VALIDATE_CHALLENGE = gql`
  mutation validateChallenges($challengesIds: [Int]!, $sessionId: Int!, $haveChallengesBeenUseful: Boolean!) {
    validateChallenges(
      challengesIds: $challengesIds
      sessionId: $sessionId
      haveChallengesBeenUseful: $haveChallengesBeenUseful
    )
  }
`

const Footer = styled.div`
  margin-top: 20px;
  text-align: center;
`

const Label = styled.div`
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 25px;
`

const Question = styled.div`
  margin-bottom: 30px;
`

const RequiredTag = styled.span`
  color: ${({ theme: { colors } }) => colors.pastelRed};
`

const hasChallengesBeenUsefuloptions = [
  {
    id: '1',
    label: 'Oui',
  },
  {
    id: '0',
    label: 'Non',
  },
]

const ChallengeValidationBlock = ({ challenges }) => {
  const {
    sessionId,
    acceptedChallenges,
    refetchQuery,
    haveChallengesBeenUseful,
    challengesAcceptancePageUrl,
  } = useCourseContext()

  const options = acceptedChallenges.map(acceptedChallenge => ({
    id: acceptedChallenge.challenge.id,
    label: acceptedChallenge.challenge.label,
  }))

  const [validateChallenge] = useMutation(VALIDATE_CHALLENGE)

  const [isCallbackValidateChallengesLoading, setCallbackValidateChallengesLoading] = useState(false)

  const courseChallengesCount = challenges.length
  const hasChallenge = options.length > 0
  const hasAnswered = haveChallengesBeenUseful !== null

  const methods = useForm({
    mode: 'onSubmit ',
    reValidateMode: 'onSubmit',
    shouldUnregister: true,
  })

  const handleSubmit = methods.handleSubmit(async data => {
    setCallbackValidateChallengesLoading(true)

    const validatedChallenges = data.validated_challenges ?? []

    const challengesIds = validatedChallenges.map(validatedChallenge => validatedChallenge.id)

    const variables = {
      challengesIds,
      sessionId,
      haveChallengesBeenUseful: data.have_challenges_been_useful.id === '1',
    }

    validateChallenge({ variables }).then(async () => {
      await refetchQuery()
      setCallbackValidateChallengesLoading(false)
    })
  })

  return (
    <>
      <Alert theme="info" style={{ marginTop: '20px', marginBottom: '40px' }}>
        <FormattedMessage id="challenges.validation.intro" values={{ challengesCount: courseChallengesCount }} />
      </Alert>

      <Box padding="40px">
        {hasChallenge ? (
          <>
            {!hasAnswered ? (
              <>
                <FormProvider {...methods}>
                  <form onSubmit={handleSubmit}>
                    <Question>
                      <Label>
                        <FormattedMessage id="challenges.validation.question_validated_challenges" />
                      </Label>
                      <Field name="validated_challenges" required={false}>
                        <CheckboxGroup inline={false} options={options} name="validated_challenges" />
                      </Field>
                    </Question>

                    <Question>
                      <Label>
                        <FormattedMessage id="challenges.validation.question_usefulness" />
                        &nbsp;
                        <RequiredTag>*</RequiredTag>
                      </Label>
                      <Field required name="have_challenges_been_useful">
                        <RadioGroup
                          inline={false}
                          name="have_challenges_been_useful"
                          options={hasChallengesBeenUsefuloptions}
                        />
                      </Field>

                      <Footer>
                        <Button disabled={isCallbackValidateChallengesLoading}>
                          <FormattedMessage id="challenges.validation.cta" />
                        </Button>
                      </Footer>
                    </Question>
                  </form>
                </FormProvider>
              </>
            ) : (
              <>
                <p>
                  <FormattedMessage id="challenges.validation.confirmation_intro" />
                </p>
                <br />
                {challengesAcceptancePageUrl && (
                  <p>
                    <FormattedHTMLMessage id="challenges.validation.confirmation_content_part_1" />
                    &nbsp;
                    <Link to={toRelative(challengesAcceptancePageUrl)}>
                      <FormattedHTMLMessage id="challenges.validation.confirmation_content_part_2" />
                    </Link>
                    <FormattedHTMLMessage id="challenges.validation.confirmation_content_part_3" />
                  </p>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <p>
              <FormattedMessage id="challenges.validation.no_challenge_intro" />
            </p>
            <br />
            {challengesAcceptancePageUrl && (
              <p>
                <FormattedHTMLMessage id="challenges.validation.no_challenge_content_part_1" />
                &nbsp;
                <Link to={toRelative(challengesAcceptancePageUrl)}>
                  <FormattedHTMLMessage id="challenges.validation.no_challenge_content_part_2" />
                </Link>
                <FormattedHTMLMessage id="challenges.validation.no_challenge_content_part_3" />
              </p>
            )}
            <br />
            <p>
              <FormattedMessage id="challenges.validation.no_challenge_outro" />
            </p>
          </>
        )}
      </Box>
    </>
  )
}

ChallengeValidationBlock.propTypes = {
  challenges: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  sessionId: PropTypes.number.isRequired,
}

export default ChallengeValidationBlock
