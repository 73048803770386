import React from 'react'
import { Visible } from 'react-grid-system'
import Tag from '@unowmooc/tags'

const ResponsiveTag = props => (
  <>
    <Visible xs sm>
      <Tag {...props} small />
    </Visible>
    <Visible md lg xl>
      <Tag {...props} />
    </Visible>
  </>
)

export default ResponsiveTag
