import React from 'react'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import ButtonSkin from '@unowmooc/react-ui-kit/lib/ButtonSkin'
import { Link } from 'react-router-dom'
import I18nProvider from '@unowmooc/i18n'
import PropTypes from 'prop-types'

const ThanksScreen = ({ slugCourse, sessionId, courseIdentifier, sessionIdentifier }) => (
  <div>
    <p>
      <FormattedHTMLMessage id="qff.thanks.label" />
    </p>

    <p>
      <FormattedHTMLMessage id="qff.thanks.from" />
    </p>

    <br />

    <ButtonSkin
      tag={Link}
      to={I18nProvider.getLinkRoute('/courses/{slugCourse}/{sessionId}', {
        slugCourse,
        sessionId,
      })}
      data-tracking-id="session.satisfaction_non_finisher_click_on_cta_go_to_program_page"
      data-tracking-values={JSON.stringify({
        courseIdentifier,
        sessionIdentifier,
      })}
    >
      <FormattedMessage id="satisfaction_non_finisher.cta_show_course" />
    </ButtonSkin>
  </div>
)

ThanksScreen.propTypes = {
  slugCourse: PropTypes.string.isRequired,
  sessionId: PropTypes.number.isRequired,
  courseIdentifier: PropTypes.string.isRequired,
  sessionIdentifier: PropTypes.string.isRequired,
}

export default ThanksScreen
