import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Container } from '@unowmooc/react-ui-kit'
import qs from 'query-string'
import { CollectorProvider } from 'modules/collector/provider'
import Page from 'modules/collector/components/Page'
import styled from '@emotion/styled'
import { Textarea, Checkbox } from '@unowmooc/inputs'
import { Form, Field } from '@unowmooc/form'
import { Button } from '@unowmooc/buttons'
import JsonConnector from 'modules/collector/connector/json'
import Collector from 'modules/collector/components/Collector'

const Wrapper = styled(Container)`
  margin: 60px auto;
`

const CollectorPage = ({ location }) => {
  const [jsonConfig, updateJsonConfig] = useState()
  const [jsonSubmitted, updateJsonSubmitted] = useState(false)

  useEffect(() => {
    if (jsonConfig?.pivot) {
      updateJsonSubmitted(true)
    }
  }, [jsonConfig])

  const { formContext, formId, participantId } = qs.parse(location.search)
  if (formId) {
    return (
      <Wrapper>
        <Box>
          <Collector formId={formId} participantId={participantId} formContext={formContext} />
        </Box>
      </Wrapper>
    )
  }

  const handleSubmit = ({ pivot, displayErrors }) => {
    updateJsonSubmitted(false)
    updateJsonConfig({ pivot: JSON.parse(pivot), displayErrors })
  }

  return (
    <Wrapper>
      {jsonSubmitted && (
        <Wrapper>
          <Box>
            <CollectorProvider connector={new JsonConnector(jsonConfig)}>
              <Page />
            </CollectorProvider>
          </Box>
        </Wrapper>
      )}
      <Box>
        <Form onSubmit={handleSubmit} defaultValues={{ displayErrors: true }}>
          <Field name="pivot" label="Définition du formulaire">
            <Textarea minRows={10} />
          </Field>
          <Field name="displayErrors" label="Activer les erreurs ?">
            <Checkbox />
          </Field>
          <Button type="submit">Voir le formulaire</Button>
        </Form>
      </Box>
    </Wrapper>
  )
}

CollectorPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
}

export default CollectorPage
