import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl'
import { css } from '@emotion/core'
import { mq } from 'styles-resources'
import config from 'config'
import LinkButtonIcon from 'components/LinkButtonIcon'
import ButtonShareLinkedin from 'modules/exam/components/ButtonShareLinkedin'

const LinksWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  align-items: center;

  > *:first-child,
  > *:last-child {
    width: 258px;

    ${mq.xs(css`
      width: 100%;
    `)}
  }

  ${mq.xs(css`
    margin-top: 20px;
    flex-direction: column;

    button {
      width: 100%;
    }
  `)}
`

const Separtor = styled.div`
  min-width: 30px;
  min-height: 20px;
`
const ShareResult = ({ certificateNumber, certificateValidatedAt, course, sessionIdentifier }) => (
  <>
    <LinksWrapper>
      <LinkButtonIcon
        href={`${config.env.GRAPHQL_API_URL}/certificate/${certificateNumber}`}
        target="_blank"
        theme="highlight"
        icon="download"
        data-tracking-id="session.download_certificate"
        data-tracking-values={JSON.stringify({
          courseIdentifier: course.identifier,
          sessionIdentifier,
        })}
      >
        <FormattedMessage id="commons.download" />
      </LinkButtonIcon>
      <Separtor />
      <ButtonShareLinkedin
        certificateNumber={certificateNumber}
        certificateValidatedAt={certificateValidatedAt}
        course={course}
        sessionIdentifier={sessionIdentifier}
      />
    </LinksWrapper>
  </>
)

ShareResult.propTypes = {
  certificateNumber: PropTypes.number.isRequired,
  certificateValidatedAt: PropTypes.string.isRequired,
  course: PropTypes.shape().isRequired,
  sessionIdentifier: PropTypes.string.isRequired,
}

export default ShareResult
