import React from 'react'
import PropTypes from 'prop-types'
import { Visible } from 'react-grid-system'
import classnames from 'classnames'
import { Navbar } from '@unowmooc/react-ui-kit'
import styled from '@emotion/styled'
import { withAbility } from 'store/AbilityProvider'
import { withUser } from 'store/UserProvider'
import { FormattedHTMLMessage } from 'react-intl'
import Item from './Item'
import { useTracking } from '../../../modules/tracking'

const MainMenuNavbar = styled(Navbar)`
  margin-bottom: 0;
`

const DomainNav = ({ user, ability, location: { pathname }, className }) => {
  const { sendEvent } = useTracking()
  const handleClickHelpItem = () => {
    sendEvent('header_menu.click_on_help_menu_item')
  }

  return (
    <MainMenuNavbar className={classnames('main-menu-navbar', className)} key={pathname}>
      {ability.can('access-domain', 'dashboard') && <Item pathname={pathname} domain="dashboard" />}
      {ability.can('access-domain', 'supervisor') && (
        <Item pathname={pathname} domain="supervisor">
          <Item pathname={pathname} domain="supervisor" route="sessions" />
        </Item>
      )}
      {ability.can('access-domain', 'expert') && (
        <Item pathname={pathname} domain="expert">
          <Item pathname={pathname} domain="expert" route="sessions" />
          <Item pathname={pathname} domain="expert" route="comments" />
          {user.conversationsAnimatorEnabled && <Item pathname={pathname} domain="expert" route="conversations" />}
        </Item>
      )}

      {(!user.account || (user.account && user.account.showCatalog === true)) && (
        <Item pathname={pathname} domain="catalog" />
      )}

      {ability.can('access-domain', 'settings') && (
        <Visible xs sm>
          <Item pathname={pathname} domain="settings">
            <Item pathname={pathname} domain="settings" route="profile" />
            <Item pathname={pathname} domain="settings" route="account" />
            {ability.can('access-domain', 'settings:preferences') && (
              <Item pathname={pathname} domain="settings" route="preferences" />
            )}
            {ability.can('access-domain', 'settings:documents') && (
              <Item pathname={pathname} domain="settings" route="documents" />
            )}
          </Item>
        </Visible>
      )}

      {ability.can('access-domain', 'conversations') && (
        <Visible xs sm>
          <Item pathname={pathname} domain="conversations" />
        </Visible>
      )}

      <Visible xs sm>
        <Item pathname={pathname} domain="help" onClick={handleClickHelpItem}>
          <Navbar.NavItem
            tag="a"
            target="help_website"
            href="https://aide.unow.fr"
            data-tracking-id="header_menu.click_on_help_center_button_link"
            label={<FormattedHTMLMessage id="domains.help.help_center" />}
          />
          <Navbar.NavItem
            tag="a"
            href="mailto:aide@unow.fr"
            data-tracking-id="header_menu.click_on_writing_to_support_button_link"
            label={<FormattedHTMLMessage id="domains.help.support_contact" />}
          />
          <Navbar.NavItem
            tag="a"
            target="procedure_rules_website"
            href="https://www.unow.fr/reglement-interieur/"
            data-tracking-id="header_menu.click_on_procedure_rules_button_link"
            label={<FormattedHTMLMessage id="domains.help.procedure_rules" />}
          />
        </Item>
      </Visible>
    </MainMenuNavbar>
  )
}

DomainNav.defaultProps = {
  className: undefined,
}

DomainNav.propTypes = {
  user: PropTypes.shape().isRequired,
  ability: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
  className: PropTypes.string,
}

export default withUser(withAbility(DomainNav))
