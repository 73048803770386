import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Switch } from '@unowmooc/react-ui-kit'

const SwitchIntl = props => (
  <Switch
    onLabel={<FormattedMessage id="switch.default_on" />}
    offLabel={<FormattedMessage id="switch.default_off" />}
    {...props}
  />
)

export default SwitchIntl
