import React from 'react'
import PropTypes from 'prop-types'
import Radio from 'components/Radio'
import styled from '@emotion/styled'

const StyledRadio = styled(Radio)`
  justify-content: normal;

  @media (min-width: 768px) {
    justify-content: center;

    .radio-label {
      margin-left: 0;
      display: none;
    }
  }
`

const RadioMatrix = ({ name, options, value, onChange, disabled, success, error, inline, className, ...props }) =>
  options.map(option => (
    <td>
      <StyledRadio
        {...props}
        value={option.id}
        id={`${name}[${option.id}]`}
        checked={!!value && value.id === option.id}
        disabled={disabled || option.disabled}
        success={success || option.success}
        name={name}
        onChange={() => {
          if (!value || value.id !== option.id) {
            onChange(option)
          }
        }}
        label={option.label}
        key={option.id}
      />
    </td>
  ))

RadioMatrix.defaultProps = {
  inline: false,
  disabled: false,
  success: false,
  error: false,
  value: null,
  className: undefined,
}

RadioMatrix.propTypes = {
  name: PropTypes.string.isRequired,
  inline: PropTypes.bool,
  disabled: PropTypes.bool,
  success: PropTypes.bool,
  error: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.node.isRequired,
      disabled: PropTypes.bool,
      error: PropTypes.bool,
    }),
  ).isRequired,
  value: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }),
  className: PropTypes.string,
}

export default RadioMatrix
