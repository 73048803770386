import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { FormattedHTMLMessage } from 'react-intl'
import styled from '@emotion/styled'
import StreamlineIcon from '@unowmooc/icons'
import { colors, rgba, transitions } from 'styles-resources'

const StyledLink = styled(Link)`
  display: inline-block;
  margin-bottom: 30px;
  font-size: 14px;
  line-height: 30px;
  color: ${colors.midGray};
  text-decoration: none;
  transition: ${transitions.color};

  &:hover {
    text-decoration: none;
    color: ${rgba(colors.manatee, 0.9)};
  }
`

const StyledIcon = styled(StreamlineIcon)`
  margin-right: 10px;
  font-size: 30px;
  color: ${colors.bombay};
`

const GoBackLink = ({ i18nLabel, to }) => (
  <StyledLink to={to}>
    <StyledIcon icon="left" />
    <FormattedHTMLMessage id={i18nLabel} />
  </StyledLink>
)

GoBackLink.propTypes = {
  i18nLabel: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
}

export default GoBackLink
