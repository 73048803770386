import styled from '@emotion/styled'
import { Row, Col } from 'react-grid-system'
import { css } from '@emotion/core'
import { mq, rgba, colors } from 'styles-resources'

const border = css`
  content: '';
  position: absolute;
  top: 0;
  width: 1px;
  height: calc(40px + 100%);
  background-color: ${rgba(colors.mischka, 0.8)};
`

const bullet = css`
  content: '';
  position: absolute;
  z-index: 1;
  width: 13px;
  height: 13px;
  background-color: ${colors.unowGreen};
  border-radius: 50%;
`

const Date = styled.p`
  font-size: 14px;
  line-height: 23px;
  color: ${colors.midGray};

  :first-letter {
    text-transform: uppercase;
  }
`

const StyledRow = styled(Row)`
  margin-bottom: 40px;

  ${mq.xs(css`
    margin-bottom: 30px;

    ${Date} {
      margin-bottom: 12px;
    }
  `)};

  &:last-of-type {
    padding-top: 20px;
    margin-bottom: 0;

    ${Date} {
      margin-bottom: 12px;
    }

    ${mq.xs(css`
      padding-top: 0;
    `)};
  }
`

const LeftCol = styled(Col)`
  text-align: right;

  ${mq.xs(css`
    text-align: center;
  `)};

  &:before {
    ${bullet};
    top: calc(50% - 6px);
    right: -6px;

    ${mq.xs(css`
      top: calc(50% + 6px);
      left: 13px;
    `)};
  }

  &:after {
    ${border};
    right: 0;

    ${mq.xs(css`
      top: 40px;
      left: 19px;
    `)};
  }

  &:first-of-type {
    &:after {
      top: 2px;

      ${mq.xs(css`
        top: 40px;
      `)};
    }
  }
`

const RightCol = styled(Col)`
  text-align: left;

  ${mq.xs(css`
    text-align: center;
  `)};

  &:after {
    ${border};
    left: -1px;
  }
`

const LastCol = styled(Col)`
  text-align: center;

  &:before {
    ${bullet};
    top: -20px;
    left: calc(50% - 7px);

    ${mq.xs(css`
      top: calc(50% + 6px);
      left: 13px;
    `)};
  }

  ${mq.xs(css`
    &:after {
      ${border};
      height: 100%;
      top: 0;
      left: 19px;
    }
  `)};
`

export { StyledRow as Row, LeftCol, RightCol, LastCol }
