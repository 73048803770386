import React from 'react'
import styled from '@emotion/styled'
import { color } from 'styled-system'

const Oval = styled.span`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  margin-right: 10px;

  ${color}
`

export default props => <Oval backgroundColor="unowGreen" {...props} />
