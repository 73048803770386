import gql from 'graphql-tag'
import { toInlineFragment } from 'fraql'

export const CREATE_COMMENT = fragment => gql`
  mutation createComment2($comment: CommentInputForCreation!) {
    createComment2(comment: $comment) {
      id
      session {
        id
        identifier
        course {
          id
          identifier
        }
      }
      ${toInlineFragment(fragment)}
    }
  }
`

export const MARK_COMMENTS_AS_UNRESOLVED = gql`
  mutation markCommentsAsUnresolved($commentsIds: [ID]!) {
    markCommentsAsUnresolved(commentsIds: $commentsIds) {
      items {
        id
        isResolved
        session {
          id
          identifier
          course {
            id
            identifier
          }
        }
      }
    }
  }
`

export const MARK_COMMENTS_AS_RESOLVED = gql`
  mutation markCommentsAsResolved($commentsIds: [ID]!) {
    markCommentsAsResolved(commentsIds: $commentsIds) {
      items {
        id
        isResolved
        session {
          id
          identifier
          course {
            id
            identifier
          }
        }
      }
    }
  }
`

export const SEND_COMMENT_TO_SUPPORT = gql`
  mutation sendCommentToSupport($commentId: ID!) {
    sendCommentToSupport(commentId: $commentId) {
      id
      isForwarded
      isResolved
      session {
        id
        identifier
        course {
          id
          identifier
        }
      }
    }
  }
`
