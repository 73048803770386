import styled from '@emotion/styled'
import { rgba } from '@unowmooc/themes'
import { Clickable } from '@unowmooc/react-ui-kit'

export default styled(Clickable)`
  display: flex;
  padding: 3px;
  z-index: 100;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 20px;
  opacity: 0;
  transition: opacity 0.3s ease;
  color: ${({ theme: { colors } }) => colors.white};
  background: ${({ theme: { colors } }) => rgba(colors.black, 0.6)};
`
