import React from 'react'
import PropTypes from 'prop-types'
import { Flex } from 'reflexbox'
import Avatar from '@unowmooc/avatar'
import { Text } from '@unowmooc/text'
import styled from '@emotion/styled'

const StyledAvatar = styled(Avatar)`
  flex-shrink: 0;

  img {
    margin-top: 5px;
  }
`

const CommentInfo = ({ author, avatarFile, avatarSrc, avatarName, date, tagLabel, badgeLabel }) => (
  <Flex>
    <StyledAvatar size={30} file={avatarFile} src={avatarSrc} name={avatarName} />
    <Flex flexDirection="column" ml="15px" sx={{ '> *:not(:last-child)': { mb: '5px' } }}>
      <Text fontSize="14px" lineHeight="19px" fontWeight="bold">
        {author}
      </Text>
      {tagLabel}
      {badgeLabel}
      <Text lineHeight="13px" fontSize="12px" color="bombay">
        {date}
      </Text>
    </Flex>
  </Flex>
)

CommentInfo.defaultProps = {
  avatarFile: undefined,
  avatarSrc: undefined,
  tagLabel: undefined,
  badgeLabel: undefined,
}

CommentInfo.propTypes = {
  avatarName: PropTypes.string.isRequired,
  author: PropTypes.node.isRequired,
  date: PropTypes.node.isRequired,
  avatarFile: PropTypes.shape(),
  avatarSrc: PropTypes.string,
  tagLabel: PropTypes.node,
  badgeLabel: PropTypes.node,
}

export default CommentInfo
