import React from 'react'
import DomainLayout, { DomainLayoutLink } from 'components/DomainLayout'
import SessionsPage from 'pages/domains/expert/sessions'
import CommentsPage from 'pages/domains/expert/comments'
import ReportsPage from 'pages/domains/expert/reports'
import VirtualClassroomsPage from 'pages/domains/expert/virtual_classrooms'
import { useUserContext } from 'store/UserProvider'
import ConversationsPage from './conversations'

const ExpertPage = () => {
  const {
    user: { conversationsAnimatorEnabled, virtualclassroomInputEnabled },
  } = useUserContext()

  return (
    <DomainLayout domain="expert" routeIndex="sessions">
      <DomainLayoutLink name="sessions" icon="AllowBlog" component={SessionsPage} />
      <DomainLayoutLink name="comments" icon="ChatBundle" component={CommentsPage} />
      {conversationsAnimatorEnabled && (
        <DomainLayoutLink name="conversations" icon="EmailEnvelope" component={ConversationsPage} />
      )}
      <DomainLayoutLink name="reports" icon="AllowBlog" component={ReportsPage} />
      {virtualclassroomInputEnabled && (
        <DomainLayoutLink name="virtual-classrooms" icon="CalendarCheck" component={VirtualClassroomsPage} />
      )}
    </DomainLayout>
  )
}

export default ExpertPage
