import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import DataGridCell from '@unowmooc/react-ui-kit/lib/DataGrid/Cell'
import { withUser } from 'store/UserProvider'
import I18nProvider from '@unowmooc/i18n'
import DataGridGraphQL from 'components/DataGridGraphQL'
import DataGridToolbar from 'components/DataGridToolbar'
import QueryStringFilter from 'components/QueryStringFilter'
import SelectAdvancementStatus from 'components/Select/AdvancementStatus'
import TagParticipantStatus from 'components/TagParticipantStatus'
import TagParticipantType from 'components/TagParticipantType'
import DownloadResultsLink from 'components/DownloadResultsLink'
import { getSupervisionResultsUrlBySession } from 'business/supervisor'
import ParticipantAdvancement from 'modules/participant/components/ParticipantAdvancement'
import TagParticipantAdvancementStatus from 'modules/participant/components/TagParticipantAdvancementStatus'

const fragment = gql`
  fragment ListParticipantFragment on Participant {
    id
    status
    type
    user {
      id
      slug
      fullName
    }
    advancement {
      ...ParticipantAdvancementFragment
      ...TagParticipantAdvancementStatusFragment
    }
  }
  ${ParticipantAdvancement.fragment}
  ${TagParticipantAdvancementStatus.fragment}
`

const StyledTagParticipantType = styled(TagParticipantType)`
  margin-left: 20px;
`

const ParticipantsPage = ({
  supervisedSessionStats: {
    session: { id: sessionId },
  },
  user: { id: userId },
}) => (
  <>
    <DataGridToolbar
      renderFilters={() => (
        <QueryStringFilter name="advancementStatus">
          <SelectAdvancementStatus clearable />
        </QueryStringFilter>
      )}
      rightItem={<DownloadResultsLink url={getSupervisionResultsUrlBySession(sessionId)} />}
    />
    <DataGridGraphQL
      queryName="participants"
      queryFragment={fragment}
      typeName="Participant"
      filters={{
        canceledAt: null,
        user_deletedAt: null,
        session_id: sessionId,
        project_supervisions_user_id: userId,
      }}
      sort={['updatedAt DESC']}
      limit={50}
    >
      <DataGridCell
        label={<FormattedMessage id="participant.title" />}
        property="user.fullName"
        size="large"
        textTransform="capitalize"
        hideLabelOnMobile
        render={(fullName, { data: { type, user } }) => (
          <>
            {fullName ? (
              <Link
                to={I18nProvider.getLinkRoute('/profile/{slug}/{id}', { slug: user.slug, id: user.id })}
                data-tracking-id="session.click_on_profile_page"
                data-tracking-values={JSON.stringify({
                  from: 'SupervisorSessionParticipants',
                })}
              >
                {fullName}
              </Link>
            ) : (
              <FormattedMessage id="commons.unspecified" />
            )}
            {(type === 'demo' || type === 'observer') && <StyledTagParticipantType type={type} small />}
          </>
        )}
      />
      <DataGridCell
        label={<FormattedMessage id="participant.status" />}
        property="status"
        render={status => (
          <div style={{ display: 'flex' }}>
            <TagParticipantStatus status={status} />
          </div>
        )}
      />
      <DataGridCell
        width="auto"
        align="right"
        label={<FormattedMessage id="registered_users.advancementLastStepValidate" />}
        property="advancement"
        render={advancement => <ParticipantAdvancement advancement={advancement} />}
      />
      <DataGridCell
        width="auto"
        align="right"
        label={<FormattedMessage id="registered_users.advancementStatus" />}
        property="advancement"
        render={(advancement, { data: { user: userAdvancement } }) => (
          // On doit ici ajouter une div afin que l'on ne tombe pas sur une règle CSS de l'ancien UI-Kit sur DataGrid
          // (.dataGrid___3eGIY > div:last-child > div:last-child > div:last-child),
          // qui casserais la mise en forme du dernier élément du tableau"
          <div>
            <TagParticipantAdvancementStatus advancement={advancement} user={userAdvancement} />
          </div>
        )}
      />
    </DataGridGraphQL>
  </>
)

ParticipantsPage.propTypes = {
  supervisedSessionStats: PropTypes.shape().isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
}

export default withUser(ParticipantsPage)
