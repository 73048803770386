import config from 'config'

export function getCourseAgreementUrl(projectId, sessionId) {
  return `${config.env.GRAPHQL_API_URL}/documents/course-agreement?project=${projectId}&session=${sessionId}`
}

export function getCollectiveCourseCertificateUrl(projectId, sessionId) {
  return `${config.env.GRAPHQL_API_URL}/documents/collective-course-certificate?project=${projectId}&session=${sessionId}`
}

export function getIndividualCourseCertificateUrl(participantId) {
  return `${config.env.GRAPHQL_API_URL}/documents/individual-course-certificate?participant=${participantId}`
}

export function getSupervisionResultsUrlBySession(sessionId) {
  return `${config.env.GRAPHQL_API_URL}/supervisions/sessions/${sessionId}`
}

export function getSupervisionResultsUrl() {
  return `${config.env.GRAPHQL_API_URL}/supervisions`
}
