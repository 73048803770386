import { animateScroll } from 'react-scroll'

export const animateScrollTo = (element, options = {}) => {
  const currentTop = window.pageYOffset || document.documentElement.scrollTop
  const { top } = element.getBoundingClientRect()
  const defaultOptions = { duration: 200, delay: 0, smooth: true }

  const mergedOptions = { ...defaultOptions, ...options }
  const { offset, ...finalOptions } = mergedOptions

  // manual offset calculation because react-scroll doesn't handle "offset" option with a pixel value for "to"
  const to = top + currentTop + (offset || 0)

  animateScroll.scrollTo(to, finalOptions)
}
