import React from 'react'
import PropTypes from 'prop-types'
import { propType, filter } from 'graphql-anywhere'
import gql from 'graphql-tag'
import { toInlineFragment } from 'fraql'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'
import ShadowBox from '@unowmooc/shadow-box'
import ResponsiveAvatar from 'components/ResponsiveAvatar'

const Box = styled(ShadowBox)`
  position: relative;
  margin-bottom: 60px;
  padding: 56px 40px 46px 40px;
  text-align: center;

  ${mq.sm(css`
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 30px;
    padding: 56px 20px 30px 20px;
  `)};
`

const AvatarWrapper = styled.div`
  position: absolute;
  top: -45px;
  left: 50%;
  transform: translateX(-50%);
`

const StyledAvatar = styled(ResponsiveAvatar)`
  font-size: 28px;
`

const Title = styled.p`
  margin-bottom: 24px;
  font-size: 16px;
`

const Quote = styled.p`
  font-size: 18px;
  line-height: 30px;
`

const Help = styled.p`
  font-size: 14px;
  line-height: 23px;
`

const NoMoreAttempt = ({ mainAnimatorUser, className }) => (
  <div className={className}>
    <Box>
      <AvatarWrapper>
        <StyledAvatar size={90} user={filter(ResponsiveAvatar.fragments.user, mainAnimatorUser)} />
      </AvatarWrapper>
      <Title>
        <FormattedHTMLMessage
          id="final_exam.results.failure.expert_word.title"
          values={{ expertName: mainAnimatorUser.fullName, gender: mainAnimatorUser.gender }}
        />
      </Title>
      <Quote>
        <FormattedMessage id="final_exam.results.failure.expert_word.description" />
        <br />
        <em>
          <FormattedMessage id="final_exam.results.failure.expert_word.quote" />
        </em>
      </Quote>
    </Box>
    <Help>
      <FormattedHTMLMessage id="final_exam.results.failure.help" />
    </Help>
  </div>
)

NoMoreAttempt.fragments = {
  mainAnimatorUser: gql`
    fragment _ on User {
      id
      fullName
      gender
      ${toInlineFragment(ResponsiveAvatar.fragments.user)}
    }
  `,
}

NoMoreAttempt.defaultProps = {
  className: undefined,
}

NoMoreAttempt.propTypes = {
  mainAnimatorUser: propType(NoMoreAttempt.fragments.mainAnimatorUser).isRequired,
  className: PropTypes.string,
}

export default NoMoreAttempt
