import React from 'react'
import PropTypes from 'prop-types'
import { propType, filter } from 'graphql-anywhere'
import { toInlineFragment } from 'fraql'
import gql from 'graphql-tag'
import { Switch, Route, Redirect } from 'react-router-dom'
import styled from '@emotion/styled'
import I18nProvider from '@unowmooc/i18n'
import Helmet from 'components/HelmetIntl'
import PageQuery from 'components/PageQuery'
import { mockParticipation } from 'modules/participant/utils'
import { QUIZ_ATTEMPT_FRAGMENT, QuizProvider } from 'modules/quiz/provider'
import { useCourseContext } from 'modules/course/components/CourseContext/CourseContext'
import Breadcrumb from 'modules/course/components/Breadcrumb'
import { QUIZ_ATTEMPT_STATUS } from 'modules/quiz/constants'
import Title from './components/Title'
import IntroPage from './intro'
import ConfirmIdentityPage from './confirm-identity'
import QuestionsPage from './questions'
import ResultsPage from './results'
import ShowSectionAccessError from '../ShowSectionAccessError'

const StyledTitle = styled(Title)`
  margin-bottom: 20px;
`

const query = gql`
  query QuizPage($idSession: ID!, $idParticipant: ID, $includeParticipant: Boolean!) {
    session(id: $idSession) {
      id
      course {
        id
        title
        calculatedNbFinalExamQuestions
        ${toInlineFragment(Title.fragments.course)}
      }
      ${toInlineFragment(ResultsPage.fragments.session)}
    }
    participant: participant (id: $idParticipant) @include(if: $includeParticipant) {
      id
      ${toInlineFragment(ResultsPage.fragments.participant)}
      advancement {
        nbRemainedExamAttempt
      }
      lastFinalExamQuizAttempt {
        id
      ...QuizAttemptFragment
      }
      finalExamAttempts {
        id
        isSuccess
      }
      ${toInlineFragment(ResultsPage.fragments.participant)}
      ${toInlineFragment(IntroPage.fragments.participant)}
    }
  }
  ${QUIZ_ATTEMPT_FRAGMENT}
`

const FinalExamPage = ({ session, sessionProp, participant: realParticipant, matchUrl }) => {
  const { getErrorsShowModule } = useCourseContext()

  const errorsShowModule = getErrorsShowModule({ isModuleZero: false })
  if (errorsShowModule.length > 0) {
    return <ShowSectionAccessError errors={errorsShowModule} />
  }

  // mock for admin and expert users
  const participant = realParticipant || mockParticipation()

  return (
    <>
      <Breadcrumb moduleLabel={I18nProvider.formatMessage({ id: 'courses.course.program.final_exam' })} />
      <QuizProvider
        questionCounter={session.course.calculatedNbFinalExamQuestions}
        remainingAttempts={participant.advancement.nbRemainedExamAttempt}
        lastFinalExamQuizAttempt={participant.lastFinalExamQuizAttempt}
        key={session.id}
      >
        <Route
          exact
          path={matchUrl}
          render={() => (
            <Redirect
              to={`${matchUrl}/${I18nProvider.translatePath(
                participant.lastFinalExamQuizAttempt?.status === QUIZ_ATTEMPT_STATUS.SUCCESS ||
                  participant.advancement.nbRemainedExamAttempt === 0
                  ? 'results'
                  : 'introduction',
              )}`}
            />
          )}
        />
        <Helmet title="page_titles.final_exam.title" values={{ courseTitle: session.course.title }} />
        <StyledTitle course={filter(Title.fragments.course, session.course)} />

        <Switch>
          <Route
            path={`${matchUrl}/${I18nProvider.translatePath('introduction')}`}
            render={props => (
              <IntroPage
                {...props}
                session={filter(IntroPage.fragments.session, sessionProp)}
                participant={filter(IntroPage.fragments.participant, participant)}
              />
            )}
          />
          <Route
            path={`${matchUrl}/${I18nProvider.translatePath('confirm-identity')}`}
            component={ConfirmIdentityPage}
          />
          <Route path={`${matchUrl}/${I18nProvider.translatePath('questions')}`} component={QuestionsPage} />
          <Route
            path={`${matchUrl}/${I18nProvider.translatePath('results')}`}
            render={props => (
              <ResultsPage
                {...props}
                session={filter(ResultsPage.fragments.session, session)}
                participant={filter(ResultsPage.fragments.participant, participant)}
              />
            )}
          />
        </Switch>
      </QuizProvider>
    </>
  )
}

FinalExamPage.propTypes = {
  session: PropTypes.shape({
    id: PropTypes.string.isRequired,
    course: PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      calculatedNbFinalExamQuestions: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  sessionProp: PropTypes.shape({}).isRequired,
  participant: PropTypes.shape({
    advancement: PropTypes.shape({
      nbRemainedExamAttempt: PropTypes.number.isRequired,
    }).isRequired,
    lastFinalExamQuizAttempt: PropTypes.shape({
      status: PropTypes.string.isRequired,
    }),
  }).isRequired,
  matchUrl: PropTypes.string.isRequired,
}

const FinalExamPageContainer = ({ match: { url }, session: sessionProp }) => {
  const { participantId } = useCourseContext()

  return (
    <PageQuery
      query={query}
      fetchPolicy="network-only"
      variables={{
        idSession: sessionProp.id,
        idParticipant: participantId,
        includeParticipant: !!participantId,
      }}
      render={renderProps => <FinalExamPage {...renderProps} sessionProp={sessionProp} matchUrl={url} />}
    />
  )
}
FinalExamPageContainer.fragments = {
  session: gql`
    fragment _ on Session {
      id
      ${toInlineFragment(IntroPage.fragments.session)}
    }
  `,
}

FinalExamPageContainer.propTypes = {
  session: propType(FinalExamPageContainer.fragments.session).isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
}

export default FinalExamPageContainer
