import React from 'react'
import styled from '@emotion/styled'
import { colors } from '@unowmooc/themes'
import PropTypes from 'prop-types'
import { Visible } from 'react-grid-system'
import { FormattedMessage } from 'react-intl'

const Grid = styled.ul`
  display: flex;
  flex: 1;
  margin: 0;
  margin-left: 10px;
  padding: 0;
`

const Number = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 6px;
  width: 20px;
  height: 20px;
  border-radius: 50%;

  font-family: 'Nunito';
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: ${colors.white};
`

const Title = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
`

const Item = styled.li`
  position: relative;
  flex: 1;
  text-align: center;
  opacity: ${({ active }) => (active ? 1 : 0.6)};

  & > ${Number} {
    ${({ active, read }) => {
      if (active) {
        return `background-color: ${colors.unowGreen};`
      }
      if (read) {
        return `background-color: ${colors.unowBluegreen};`
      }

      return `
            color: ${colors.unowBluegreen};
            background-color: ${colors.white};
            border: 1px solid ${colors.unowBluegreen};
        `
    }}
  }

  & > ${Title} {
    color: ${({ active }) => (active ? colors.unowGreen : colors.unowBluegreen)};
    font-weight: ${({ active }) => (active ? 700 : 400)};
  }

  &::before {
    content: '';
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${colors.unowBluegreen};
    opacity: ${({ active }) => (active ? 0.6 : 1)};
    z-index: -1;
  }

  &:first-child {
    &::before {
      left: 50%;
      width: 50%;
    }
  }

  &:last-child {
    &::before {
      right: 50%;
      width: 50%;
    }
  }
`

const Step = styled.span`
  font-weight: 500;
  font-size: 11px;
  line-height: 20px;
  text-transform: uppercase;
  color: ${colors.manatee};
`

const Breadcrumb = ({ slideIndex, slideConfiguration }) => (
  <>
    <Visible xs sm>
      <Step>
        <FormattedMessage id="guide.step_label" values={{ page: slideIndex + 1, total: slideConfiguration.length }} />
      </Step>
    </Visible>
    <Visible md xl lg>
      <Grid>
        {slideConfiguration.map(({ breadcrumb }, currentSlideIndex) => (
          <Item active={currentSlideIndex === slideIndex} read={currentSlideIndex < slideIndex}>
            <Number>{currentSlideIndex + 1}</Number>
            <br />
            <Title>{breadcrumb}</Title>
          </Item>
        ))}
      </Grid>
    </Visible>
  </>
)

Breadcrumb.propTypes = {
  slideIndex: PropTypes.number.isRequired,
  slideConfiguration: PropTypes.arrayOf(
    PropTypes.shape({
      breadcrumb: PropTypes.node.isRequired,
    }),
  ).isRequired,
}

export default Breadcrumb
