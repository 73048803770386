import _ from 'lodash'
import config from 'config'
import gql from 'graphql-tag'
import I18nProvider from '@unowmooc/i18n'
import { getThemeFromCourse, getCourseTitle } from './course.selectors'
import { getUserProgression } from '../progression/utils'

// pdf
export const getPublicProductFile = code =>
  config.env.GRAPHQL_API_URL.concat('/program/generate/').concat(_.toLower(code))

export const getCoursesPlanning = () => config.env.GRAPHQL_API_URL.concat('/courses/planning')

export const getCourseIndexById = (courses = [], id) => _.findIndex(courses, course => course.id === id)

export const getThemeIndexByLabel = (themes = [], label) => _.findIndex(themes, theme => theme.label === label)

export const getUniqueThemeFromCourses = (courses = []) =>
  courses.reduce((acc, value) => {
    const theme = getThemeFromCourse(value)
    const themeIsUnique = getThemeIndexByLabel(acc, theme.label) < 0

    if (themeIsUnique) {
      acc.push(theme)
    }

    return acc
  }, [])

export const getSelectorTitle = course =>
  course.title + (course.type === 'intra' ? ' - INTRA' : '') + (course.locale === 'en' ? ' - EN' : '')

export const sortByTitle = (courses = []) =>
  courses.sort((first, second) =>
    getCourseTitle(first).toLowerCase().trim().localeCompare(getCourseTitle(second).toLowerCase().trim()),
  )

export const getResumeLink = ({ session, userIsAdmin = false }) => {
  const baseUrl = I18nProvider.getLinkRoute('/courses/{courseSlug}/{sessionId}/lessons', {
    courseSlug: session.course.slug,
    sessionId: session.id,
  })

  const resumeSection = getUserProgression(
    session.course,
    session.authenticatedParticipant?.progressions ?? [],
    session.authenticatedParticipant?.badges ?? [],
    userIsAdmin,
    session.hasAccessModuleZero,
  )

  if (_.isEmpty(resumeSection)) {
    return `${baseUrl}${I18nProvider.getLinkRoute('/final-exam')}`
  }

  if (resumeSection.lastSection && !resumeSection.moduleBonus && !resumeSection.moduleZero) {
    return `${baseUrl}${I18nProvider.getLinkRoute('/quiz/{moduleId}', { moduleId: resumeSection.moduleId })}`
  }

  return `${baseUrl}/${resumeSection.sectionId}`
}

getResumeLink.fragments = {
  session: gql`
    fragment getResumeLinkFragment on Session {
      id
      startAt
      confirmedAt
      hasAccessModuleZero
      authenticatedParticipant {
        id
        progressions {
          id
          section {
            id
            position
            module {
              id
              position
            }
          }
        }
        badges {
          id
          module {
            id
          }
        }
        advancementStatus
      }
      course {
        id
        slug
        hasFlexibleStart
        moduleZero {
          id
          status
          sections {
            id
            status
            position
          }
        }
        modules {
          id
          publicPosition
          status
          bonus
          hasQuiz
          sections {
            id
            status
            position
          }
        }
      }
    }
  `,
}
