import React from 'react'
import { propType } from 'graphql-anywhere'
import gql from 'graphql-tag'
import parse from 'html-react-parser'
import Helmet from 'react-helmet'
import { toInlineFragment } from 'fraql'

const scriptJsRegexpString = '<script[^>]*>([^<]+)</script>'
const scriptSrcRegexpString = '<script[^>]+[/]?>(</script>)?'

const getScriptMatches = (scriptRegexpString, raw) => {
  const scriptRegexp = new RegExp(scriptRegexpString, 'gi')
  return scriptRegexp.exec(raw)
}

const getHtmlMatches = (scriptRegexpString, raw) => {
  const htmlRegexp = new RegExp(`^(.*)?${scriptRegexpString}(.*)?$`, 'gi')
  return htmlRegexp.exec(raw)
}

const ScriptBlock = ({ block }) => {
  // check whether a raw js script is present
  const scriptJsMatch = getScriptMatches(scriptJsRegexpString, block.raw)

  if (scriptJsMatch) {
    const htmlMatch = getHtmlMatches(scriptJsRegexpString, block.raw)

    return (
      <div>
        <Helmet>
          <script>{`${scriptJsMatch[1]}`}</script>
        </Helmet>
        {htmlMatch && (
          <>
            {htmlMatch[1] && parse(htmlMatch[1])}
            {htmlMatch[3] && parse(htmlMatch[3])}
          </>
        )}
      </div>
    )
  }

  // check whether a src script is present
  const scriptSrcMatch = getScriptMatches(scriptSrcRegexpString, block.raw)

  if (scriptSrcMatch) {
    const htmlMatch = getHtmlMatches(scriptSrcRegexpString, block.raw)

    return (
      <div>
        <Helmet>{parse(scriptSrcMatch[0])}</Helmet>
        {htmlMatch && (
          <>
            {htmlMatch[1] && parse(htmlMatch[1])}
            {htmlMatch[3] && parse(htmlMatch[3])}
          </>
        )}
      </div>
    )
  }

  // otherwise just display raw html without changes
  return <div>{parse(block.raw)}</div>
}

ScriptBlock.fragment = toInlineFragment(
  gql`
    fragment _ on Block {
      ... on ScriptBlock {
        blockType
        raw
      }
    }
  `,
)

ScriptBlock.propTypes = {
  block: propType(ScriptBlock.fragments).isRequired,
}

export default ScriptBlock
