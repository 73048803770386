import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'
import gql from 'graphql-tag'
import SupervisedSessionStats from 'components/SupervisedSessionStats'

const Label = styled.h3`
  margin-bottom: 30px;

  ${mq.sm(css`
    margin-bottom: 20px;
  `)};
`

const StyledSupervisedSessionStats = styled(SupervisedSessionStats)`
  margin-bottom: 30px;

  ${mq.sm(css`
    margin-bottom: 20px;
  `)};

  &:last-child {
    margin-bottom: 0;
  }
`

const SupervisedSessionsStatsList = ({ supervisedSessionsStatsList }) => (
  <div data-cy="animations-status">
    <Label>
      <FormattedMessage id="dashboard.supervisions.label" />
    </Label>

    {supervisedSessionsStatsList.map(supervisedSessionStats => (
      <StyledSupervisedSessionStats
        key={supervisedSessionStats.session.id}
        supervisedSessionStats={supervisedSessionStats}
      />
    ))}
  </div>
)

SupervisedSessionsStatsList.fragment = gql`
  fragment SupervisedSessionStatsList on SupervisedSessionStats {
    ...SupervisedSessionStats
  }
  ${SupervisedSessionStats.fragment}
`

SupervisedSessionsStatsList.propTypes = {
  supervisedSessionsStatsList: PropTypes.arrayOf(propType(SupervisedSessionStats.fragment)).isRequired,
}

export default SupervisedSessionsStatsList
