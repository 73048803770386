import React from 'react'
import { FormattedHTMLMessage } from 'react-intl'
import { useCourseContext } from 'modules/course/components/CourseContext/CourseContext'
import moment from 'moment'

const Onboarding = () => {
  const { sessionStartAt } = useCourseContext()

  return (
    <>
      <p>
        <FormattedHTMLMessage
          id="guide.slides.onboarding.content.paragraph_1"
          values={{ startDate: moment(sessionStartAt).format('dddd D MMMM YYYY') }}
        />
      </p>
      <p>
        <FormattedHTMLMessage id="guide.slides.onboarding.content.paragraph_2" />
      </p>
    </>
  )
}

export default Onboarding
