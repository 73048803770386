import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'
import I18nProvider from '@unowmooc/i18n'
import ConversationList from 'modules/conversations/components/List'
import ConversationDetail from 'modules/conversations/components/Detail'
import { Container } from '@unowmooc/react-ui-kit'
import styled from '@emotion/styled'
import { mq } from '@unowmooc/themes'
import { css } from '@emotion/core'

const PageWrapper = styled.div`
  margin: 30px 0;
  ${mq.sm(css`
    margin: 20px 0 30px;
  `)};
`

const ConversationRouter = ({ match: { url } }) => (
  <Container>
    <PageWrapper>
      <Switch>
        <Route
          exact
          path={`${url}${I18nProvider.translatePath('/:idConversation')}`}
          render={props => <ConversationDetail {...props} backUrl={url} />}
        />
        <Route path={url} component={ConversationList} />
      </Switch>
    </PageWrapper>
  </Container>
)

ConversationRouter.propTypes = {
  match: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
}

export default ConversationRouter
