import React from 'react'
import PropTypes from 'prop-types'
import { propType, filter } from 'graphql-anywhere'
import gql from 'graphql-tag'
import { Visible } from 'react-grid-system'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'
import I18nProvider from '@unowmooc/i18n'
import GoBackLink from 'components/GoBackLink'
import TagSessionType from 'components/TagSessionType'
import TagSessionStatus from 'components/TagSessionStatus'
import FormattedDateInterval from 'components/FormattedDateInterval'
import InProgressCounters from './InProgressCounters'
import FinishedCounters from './FinishedCounters'
import VirtualClassroomsInfo from '../../../components/VirtualClassroomsInfo'
import Card from '../../../../../../components/Card'

const Top = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 22px;

  ${mq.sm(css`
    display: block;
  `)}
`

const Title = styled.h3``

const Tags = styled.div`
  display: flex;

  > * {
    margin-right: 12px;

    &:last-child {
      margin-right: 0;
    }
  }

  ${mq.sm(css`
    margin-bottom: 20px;
  `)}
`

const Dates = styled.p`
  margin-bottom: 36px;
  font-size: 15px;

  ${mq.sm(css`
    font-size: 14px;
  `)}
`

const Header = ({
  supervisedSessionStats,
  supervisedSessionStats: {
    session: { course, startAt, endAt, type, runningStatus, currentWeek },
  },
  className,
}) => (
  <div className={className}>
    <GoBackLink to={I18nProvider.getLinkRoute('/supervisor/sessions')} i18nLabel="session.go_back_all_course" />
    <Top>
      <Visible xs sm>
        <Tags>
          <TagSessionType type={type} small />
          <TagSessionStatus status={runningStatus} currentWeek={currentWeek} small />
        </Tags>
      </Visible>
      <Title>{course.title}</Title>
      <Visible md lg xl>
        <Tags>
          <TagSessionType type={type} />
          <TagSessionStatus status={runningStatus} currentWeek={currentWeek} />
        </Tags>
      </Visible>
    </Top>

    <Dates>
      <FormattedDateInterval id="commons.session_from_to" from={startAt} to={endAt} />
    </Dates>

    {runningStatus === 'finished' ? (
      <FinishedCounters supervisedSessionStats={filter(FinishedCounters.fragment, supervisedSessionStats)} />
    ) : (
      <InProgressCounters supervisedSessionStats={filter(InProgressCounters.fragment, supervisedSessionStats)} />
    )}

    {supervisedSessionStats.session.virtualClassrooms && (
      <Card padding="30px" marginTop="20px">
        <VirtualClassroomsInfo
          selectedSessionId={supervisedSessionStats.session.id}
          sessions={[supervisedSessionStats.session]}
        />
      </Card>
    )}
  </div>
)

Header.fragment = gql`
  fragment SupervisedSessionHeader on SupervisedSessionStats {
    session {
      id
      startAt
      endAt
      type
      runningStatus
      currentWeek
      course {
        id
        title
      }
      hasVirtualClassroom
      virtualClassrooms {
        id
        sequenceNumber
        isOnboarding
        startDate
      }
    }
    ...InProgressCountersFragment
    ...FinishedCountersFragment
  }
  ${InProgressCounters.fragment}
  ${FinishedCounters.fragment}
`

Header.defaultProps = {
  className: undefined,
}

Header.propTypes = {
  supervisedSessionStats: propType(Header.fragment).isRequired,
  className: PropTypes.string,
}

export default Header
