import { ROLES } from 'modules/user/user.constants'

export const getUserRoleForGA = user => {
  if (!user) {
    return 'non défini'
  }

  if (user.role === ROLES.ADMIN) {
    return 'administrateur'
  }

  if (user.nbAnimations) {
    return 'animateur'
  }

  if (user.nbSupervisions) {
    return 'superviseur'
  }

  return 'utilisateur'
}
