import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Button } from '@unowmooc/react-ui-kit'
import { FormattedMessage } from 'react-intl'
import { useQuiz } from '../../provider'
import { QUIZ_QUESTION_ATTEMPT_STATUS } from '../../constants'

const Wrapper = styled.div`
  width: 100%;
  padding: 20px 0;
  text-align: center;
  border-top: ${({ theme: { colors } }) => `1px solid ${colors.whisper}`};
  background-color: ${({ theme: { colors } }) => colors.white};
`

const SubmitAnswerButton = ({ className }) => {
  const [loading, updateLoading] = useState(false)
  const { currentQuestion, pendingAnswer, validateAnswer, nextQuestion } = useQuiz()

  const handleSubmitAnswer = async () => {
    try {
      updateLoading(true)
      await validateAnswer()
    } finally {
      updateLoading(false)
    }
  }

  const handleContinue = () => {
    nextQuestion()
  }

  if (currentQuestion.status === QUIZ_QUESTION_ATTEMPT_STATUS.CREATED) {
    return (
      <Wrapper className={className} data-cy="submit-answer-button">
        <Button onClick={handleSubmitAnswer} size="large" disabled={!pendingAnswer} loading={loading}>
          <FormattedMessage id="quiz.submit_button" />
        </Button>
      </Wrapper>
    )
  }

  return (
    <Wrapper className={className} data-cy="submit-answer-button">
      <Button onClick={handleContinue} size="large" loading={loading}>
        <FormattedMessage id="quiz.continue_button" />
      </Button>
    </Wrapper>
  )
}

SubmitAnswerButton.defaultProps = {
  className: undefined,
}

SubmitAnswerButton.propTypes = {
  className: PropTypes.string,
}

export default SubmitAnswerButton
