import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Emoji from '@unowmooc/emoji'
import { Clickable } from '@unowmooc/buttons'
import TippyTooltip from '@unowmooc/tooltip'
import styled from '@emotion/styled'

const StyledEmoji = styled(Emoji)`
  transition: transform 0.15s cubic-bezier(0.2, 0, 0.13, 2);
`

const People = styled.div`
  text-align: left;
`

const Counter = styled.div`
  margin-left: 5px;
  font-size: 12px;
  font-weight: bold;
  line-height: 22px;
  color: ${({ theme, highlight }) => (highlight ? theme.colors.unowOrange : theme.colors.midGray)};
`

const ButtonReaction = styled(Clickable)`
  display: flex;
  align-items: center;

  &:hover {
    ${StyledEmoji} {
      transform: scale(1.2);
    }
  }
`

class Reaction extends Component {
  constructor(props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
  }

  get people() {
    const { people, andMorePeopleFeedback } = this.props

    const peopleSample = people.slice(0, 9)

    return (
      <People>
        {peopleSample.map(({ id, label }) => (
          <div key={id}>{label}</div>
        ))}
        {people.length !== peopleSample.length && <div>{andMorePeopleFeedback}</div>}
      </People>
    )
  }

  handleClick() {
    const { onClick, shortname } = this.props

    if (onClick) {
      onClick(shortname)
    }
  }

  render() {
    const { shortname, counter, isHighlighted } = this.props

    return (
      <TippyTooltip content={this.people} touchHold trigger="mouseenter">
        <ButtonReaction onClick={this.handleClick}>
          <StyledEmoji size={22} name={shortname} />
          <Counter highlight={isHighlighted}>{counter}</Counter>
        </ButtonReaction>
      </TippyTooltip>
    )
  }
}

Reaction.defaultProps = {
  onClick: undefined,
  isHighlighted: false,
  andMorePeopleFeedback: ' and more...',
}

Reaction.propTypes = {
  shortname: PropTypes.string.isRequired,
  counter: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  isHighlighted: PropTypes.bool,
  andMorePeopleFeedback: PropTypes.node,
  people: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default Reaction
