import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { propType } from 'graphql-anywhere'
import gql from 'graphql-tag'

import Tag from '@unowmooc/tags'

import { getAdvancementTheme } from '../../utils/advancement'

const TagParticipantAdvancementStatus = ({ advancement, user: { gender } }) => (
  <Tag theme={getAdvancementTheme(advancement)} small>
    <FormattedMessage id={`participant.advancement.status.${_.get(advancement, 'status')}`} values={{ gender }} />
  </Tag>
)

TagParticipantAdvancementStatus.fragment = gql`
  fragment TagParticipantAdvancementStatusFragment on Advancement {
    status
  }
`

TagParticipantAdvancementStatus.propTypes = {
  advancement: propType(TagParticipantAdvancementStatus.fragment).isRequired,
  user: PropTypes.shape({ gender: PropTypes.string }).isRequired,
}

export default TagParticipantAdvancementStatus
