import React, { Component } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { FormSpy } from 'react-final-form'

class AutoSave extends Component {
  constructor(props) {
    super(props)

    this.state = {
      dirtyFields: {},
    }
  }

  static getDerivedStateFromProps(nextProps) {
    const { form } = nextProps
    const { dirtyFields } = form.getState()

    return { dirtyFields }
  }

  shouldComponentUpdate(nextProps) {
    const { form } = nextProps
    const { dirtyFields: nextDirtyFields } = form.getState()
    const { dirtyFields } = this.state

    return !_.isEqual(dirtyFields, nextDirtyFields)
  }

  componentDidUpdate() {
    const { dirty } = this.props
    const { dirtyFields } = this.state

    // save each time the form is dirty
    if (dirty) {
      const { save } = this.props

      this.bulkSetFieldData(dirtyFields, { saving: true, saved: false })

      save()
        .then(() => {
          this.bulkSetFieldData(dirtyFields, { saving: false, saved: true })
        })
        .catch(() => {
          this.bulkSetFieldData(dirtyFields, { saving: false, saved: false })
        })
    }
  }

  bulkSetFieldData(fields, value) {
    const {
      form: {
        mutators: { setFieldData },
      },
    } = this.props

    Object.keys(fields).forEach(fieldName => {
      setFieldData(fieldName, value)
    })
  }

  render() {
    return null
  }
}

AutoSave.propTypes = {
  dirty: PropTypes.bool.isRequired,
  save: PropTypes.func.isRequired,
  form: PropTypes.shape().isRequired,
}

export default props => <FormSpy {...props} subscription={{ dirty: true }} component={AutoSave} />
