import React from 'react'
import hoistNonReactStatic from 'hoist-non-react-statics'
import { UserConsumer } from '.'

const withUser = WrappedComponent => {
  const Wrapper = props => <UserConsumer>{store => <WrappedComponent {...props} {...store} />}</UserConsumer>

  hoistNonReactStatic(Wrapper, WrappedComponent)

  Wrapper.displayName = `withUser(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`

  return Wrapper
}

export default withUser
