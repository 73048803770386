import { useState } from 'react'

const useBreadcrumbUtils = () => {
  const [crumbs, setCrumbs] = useState([])

  const setModule = ({ label, icon, linkTo } = { label: null, icon: null, linkTo: null }) => {
    setCrumbs([{ label, icon, linkTo }])
  }

  const cleanModule = () => {
    setCrumbs([])
  }

  return { crumbs, setModule, cleanModule }
}

export default useBreadcrumbUtils
