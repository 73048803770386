import React from 'react'
import PropTypes from 'prop-types'
import ShadowBox from '@unowmooc/shadow-box'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'

const StyledShadowBox = styled(ShadowBox)`
  border-radius: 4px;
  margin-bottom: 30px;
  background-color: ${({ theme }) => theme.colors.white};
  overflow: hidden;

  ${mq.xs(css`
    margin-bottom: 15px;
  `)}
`

const Thread = ({ children, ...rest }) => (
  <StyledShadowBox data-cy="thread" {...rest}>
    {children}
  </StyledShadowBox>
)

Thread.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Thread
