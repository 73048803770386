import React, { createContext } from 'react'
import PropTypes from 'prop-types'
import { createContextualCan } from '@casl/react'
import withAbility from './withAbility'

const AbilityContext = createContext()

const AbilityProvider = ({ children, ability }) => (
  <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>
)

AbilityProvider.propTypes = {
  ability: PropTypes.shape().isRequired,
  children: PropTypes.node.isRequired,
}

export default AbilityProvider

export const AbilityConsumer = AbilityContext.Consumer
export const Can = createContextualCan(AbilityConsumer)
export { withAbility }
