import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import _ from 'lodash'
import Icon from '@unowmooc/icons'
import { Text, IntlMessage } from '@unowmooc/text'
import { colors } from '@unowmooc/themes'

const Badge = ({ comment }) => {
  if (!comment.user) {
    return null
  }
  const participant = _.find(comment.user.participants, { session: { id: comment.session.id } })

  if (participant && participant.advancementStatus === 'certified') {
    return (
      <Text lineHeight="13px" color="midGray" data-cy={'badge-'.concat(comment.id)}>
        <Icon
          icon="star-circle"
          style={{
            color: colors.unowOrange,
            marginRight: '2px',
          }}
        />
        <IntlMessage i18nKey="badge.certified" values={{ gender: comment.user.gender }} />
      </Text>
    )
  }

  return null
}

Badge.fragment = gql`
  fragment CommentBadgeFragment on Comment {
    id
    session {
      id
    }
    user {
      id
      gender
      participants {
        id
        advancementStatus
        session {
          id
        }
      }
    }
  }
`

Badge.propTypes = {
  comment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    user: PropTypes.shape({
      gender: PropTypes.string,
      participants: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    }),
    session: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export default Badge
