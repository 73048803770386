import React from 'react'
import { propType, filter } from 'graphql-anywhere'
import gql from 'graphql-tag'
import { toInlineFragment } from 'fraql'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'
import { useQuiz } from 'modules/quiz/provider'
import Score from '../Score'
import NoMoreAttempt from './NoMoreAttempt'
import Retry from './Retry'
import smileyConfused from './assets/smiley-confused.svg'

const Wrapper = styled.div`
  text-align: center;
`

const Illu = styled.img`
  margin-top: 30px;
  margin-bottom: 30px;

  ${mq.sm(css`
    margin-top: 20px;
  `)};
`

const Description = styled.h4`
  margin-bottom: 15px;
`

const StyledScore = styled(Score)`
  margin-bottom: 60px;

  ${mq.sm(css`
    margin-bottom: 40px;
  `)};
`

const StyledNoMoreAttempt = styled(NoMoreAttempt)`
  margin-top: 94px;

  ${mq.sm(css`
    margin-top: 80px;
  `)};
`

const Failure = ({ participant, mainAnimatorUser }) => {
  const { remainingAttempts } = useQuiz()
  const hasNoMoreAttempts = remainingAttempts === 0

  return (
    <Wrapper>
      <Illu src={smileyConfused} />

      <Description>
        <FormattedMessage
          id={`final_exam.results.failure.${hasNoMoreAttempts ? 'description_no_more_attempt' : 'description'}`}
        />
      </Description>

      <StyledScore participant={filter(Score.fragments.participant, participant)} />

      {hasNoMoreAttempts ? (
        <>
          {mainAnimatorUser && (
            <StyledNoMoreAttempt
              mainAnimatorUser={filter(NoMoreAttempt.fragments.mainAnimatorUser, mainAnimatorUser)}
            />
          )}
        </>
      ) : (
        <Retry />
      )}
    </Wrapper>
  )
}

Failure.fragments = {
  participant: gql`
    fragment _ on Participant {
      id
      lastFinalExamQuizAttempt {
        id
        score
      }
    }
  `,
  mainAnimatorUser: gql`
    fragment _ on User {
      ${toInlineFragment(NoMoreAttempt.fragments.mainAnimatorUser)}
    }
  `,
}

Failure.defaultProps = {
  mainAnimatorUser: undefined,
}

Failure.propTypes = {
  participant: propType(Failure.fragments.participant).isRequired,
  mainAnimatorUser: propType(Failure.fragments.mainAnimatorUser),
}

export default Failure
