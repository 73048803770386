import React from 'react'
import PropTypes from 'prop-types'
import { withAbility } from 'store/AbilityProvider'
import DomainLayout, { DomainLayoutLink } from 'components/DomainLayout'
import ProfilePage from 'pages/domains/settings/profile'
import AccountPage from 'pages/domains/settings/account'
import PreferencesPage from 'pages/domains/settings/preferences'
import DocumentsPage from 'pages/domains/settings/documents'
import iconUsers from './assets/users.svg'
import iconAccount from './assets/account.svg'
import iconStars from './assets/stars.svg'
import iconNotebook from './assets/notebook.svg'

const SettingsPage = ({ ability }) => (
  <DomainLayout domain="settings" routeIndex="profile">
    <DomainLayoutLink name="profile" icon={iconUsers} component={ProfilePage} />
    <DomainLayoutLink name="account" icon={iconAccount} component={AccountPage} />
    {ability.can('access-domain', 'settings:preferences') && (
      <DomainLayoutLink name="preferences" icon={iconStars} component={PreferencesPage} />
    )}
    {ability.can('access-domain', 'settings:documents') && (
      <DomainLayoutLink name="documents" icon={iconNotebook} component={DocumentsPage} />
    )}
  </DomainLayout>
)

SettingsPage.propTypes = {
  ability: PropTypes.shape().isRequired,
}

// we can't use Can component because DomainLayoutLink need to be direct children of DomainLayout
export default withAbility(SettingsPage)
