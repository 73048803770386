import React, { useState } from 'react'
import { propType } from 'graphql-anywhere'
import { mq } from '@unowmooc/themes'
import { css } from '@emotion/core'
import gql from 'graphql-tag'
import { Flex } from 'reflexbox'
import { ZeroHeightUnow, ZeroHeightDev } from '@unowmooc/zeroheight'
import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import WysiwygView from 'components/WysiwygView'
import { ButtonI18n } from '@unowmooc/buttons'
import { useCourseContext } from 'modules/course/components/CourseContext/CourseContext'
import Tag from '@unowmooc/tags'
import { useMutation } from '@apollo/react-hooks'

const ChallengeContainer = styled.div`
  padding: 30px;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 30px;

  ${mq.sm(css`
    padding: 10px;
  `)};
`

const TitleContainer = styled.div`
  margin: 0 0 20px 0;

  h2 {
    display: inline-block;
    line-height: 48px;
    vertical-align: middle;

    ${mq.sm(css`
      padding-left: 0;
    `)};
  }

  div {
    margin-right: 15px;
    ${mq.sm(css``)};
  }

  ${mq.sm(css`
    div,
    h2 {
      display: block;
      width: 100%;
      text-align: center;
    }
  `)};
`

const ChallengeParagraph = styled.p`
  margin: 0 0 20px 0;
`

const iconTagFromIconType = iconType => {
  switch (iconType) {
    case 'Unow':
      return ZeroHeightUnow
    case 'Dev':
      return ZeroHeightDev
    default:
      return null
  }
}

const SimpleParagraph = ({ value }) => (
  <ChallengeParagraph dangerouslySetInnerHTML={{ __html: value.replace('\n', '<br>') }} />
)

SimpleParagraph.propTypes = {
  value: PropTypes.string.isRequired,
}

const WysiwygParagraph = ({ value }) => <ChallengeParagraph dangerouslySetInnerHTML={{ __html: value }} />

WysiwygParagraph.propTypes = {
  value: PropTypes.string.isRequired,
}

const ChallengeIcon = ({ icon }) => {
  const [iconType, iconValue] = icon.split('-')

  const IconTag = iconTagFromIconType(iconType)

  if (!IconTag) {
    return {}
  }

  return <IconTag height="48px" width="48px" name={iconValue} display="inline-block" verticalAlign="middle" />
}

ChallengeIcon.propTypes = {
  icon: PropTypes.string.isRequired,
}

const ACCEPT_CHALLENGE = gql`
  mutation challengeAccept($challengeId: Int!, $sessionId: Int!) {
    challengeAccept(challengeId: $challengeId, sessionId: $sessionId)
  }
`

const ChallengeAcceptationBlock = ({ challenges, withButtons }) => {
  const [acceptChallenge] = useMutation(ACCEPT_CHALLENGE)

  const { sessionId, refetchQuery, acceptedChallenges } = useCourseContext()

  const acceptedChallengesByChallengeId = {}
  acceptedChallenges.forEach(acceptedChallenge => {
    acceptedChallengesByChallengeId[acceptedChallenge.challenge.id] = acceptedChallenge
  })

  const [isCallbackAcceptChallengeLoading, setCallbackAcceptChallengeLoading] = useState(false)

  const callbackAcceptChallenge = e => {
    setCallbackAcceptChallengeLoading(true)
    acceptChallenge({ variables: { challengeId: e.target.dataset.challengeId, sessionId } }).then(async () => {
      await refetchQuery()
      setCallbackAcceptChallengeLoading(false)
    })
  }

  return (
    <>
      {challenges.map(challenge => (
        <ChallengeContainer style={{ border: `4px solid ${challenge.color}` }}>
          <TitleContainer>
            <h2 style={{ color: challenge.color }}>
              <ChallengeIcon icon={challenge.icon} /> <FormattedMessage id="challenges.label_prefix" />
              &nbsp;: {challenge.label}&nbsp;
              {acceptedChallengesByChallengeId[challenge.id] && (
                // On force le display et width afin pour qu'en mobile la surcharge qu'on fait plus haut sur le h2 ne case pas le rendu
                <Tag small theme="green" style={{ verticalAlign: 'middle', display: 'inline-flex', width: 'auto' }}>
                  <FormattedMessage id="challenges.accepted_label" />
                </Tag>
              )}
            </h2>
          </TitleContainer>

          {challenge.why && (
            <>
              <h4>
                <FormattedMessage id="challenges.why" />
              </h4>
              <SimpleParagraph value={challenge.why} />
            </>
          )}

          {challenge.who && (
            <>
              <h4>
                <FormattedMessage id="challenges.who" />
              </h4>
              <SimpleParagraph value={challenge.who} />
            </>
          )}

          {challenge.how && (
            <>
              <h4>
                <FormattedMessage id="challenges.how" />
              </h4>
              <WysiwygView content={challenge.how} />
            </>
          )}
          <Flex style={{ justifyContent: 'center' }}>
            {withButtons &&
              (acceptedChallengesByChallengeId[challenge.id] ? (
                <ButtonI18n
                  i18nKey="challenges.accepted_action_label"
                  variant="secondary"
                  icon="check"
                  reverse
                  disabled
                />
              ) : (
                <ButtonI18n
                  i18nKey="challenges.accept_action_label"
                  variant="primary"
                  onClick={callbackAcceptChallenge}
                  data-challenge-id={challenge.id}
                  disabled={!!isCallbackAcceptChallengeLoading}
                />
              ))}
          </Flex>
        </ChallengeContainer>
      ))}
    </>
  )
}

ChallengeAcceptationBlock.propTypes = {
  challenges: propType.isRequired,
  withButtons: propType.isRequired,
}

export default ChallengeAcceptationBlock
