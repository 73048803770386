import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import gql from 'graphql-tag'
import { FormattedMessage } from 'react-intl'
import { getType, getIcon } from 'modules/file/utils'
import Resource from '../../Resource'

const Download = ({ download, className }) => {
  const type = getType(download.mimeType)

  return (
    <Resource
      title={download.title}
      href={download.secureUrl}
      icon={getIcon(type)}
      type={<FormattedMessage id={`sections.download.types.${type}`} />}
      className={className}
    />
  )
}

Download.fragments = {
  file: gql`
    fragment _ on File {
      id
      mimeType
      title
      secureUrl
    }
  `,
}

Download.defaultProps = {
  className: undefined,
}

Download.propTypes = {
  download: propType(Download.fragments.file).isRequired,
  className: PropTypes.string,
}

export default Download
