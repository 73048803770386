import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import CSSTransition from 'react-transition-group/CSSTransition'

const transition = css`
  transition: transform 250ms ease-out;
`

const origins = {
  top: css`
    transform: translate3d(0, -100%, 0);

    &.slide-enter-done {
      transform: translate3d(0, 0, 0);
    }

    &.slide-enter-active,
    &.slide-exit-active {
      transform: translate3d(0, -100%, 0);
    }

    &.slide-exit-done {
      transform: translate3d(0, -100%, 0);
    }
  `,
  right: css`
    transform: translate3d(100%, 0, 0);

    &.slide-enter-done {
      transform: translate3d(0, 0, 0);
    }

    &.slide-enter-active,
    &.slide-exit-active {
      transform: translate3d(100%, 0, 0);
    }

    &.slide-exit-done {
      transform: translate3d(100%, 0, 0);
    }
  `,
  bottom: css`
    transform: translate3d(0, 100%, 0);

    &.slide-enter-done {
      transform: translate3d(0, 0, 0);
    }

    &.slide-enter-active,
    &.slide-exit-active {
      transform: translate3d(0, 100%, 0);
    }

    &.slide-exit-done {
      transform: translate3d(0, 100%, 0);
    }
  `,
  left: css`
    transform: translate3d(-100%, 0, 0);

    &.slide-enter-done {
      transform: translate3d(0, 0, 0);
    }

    &.slide-enter-active,
    &.slide-exit-active {
      transform: translate3d(-100%, 0, 0);
    }

    &.slide-exit-done {
      transform: translate3d(-100%, 0, 0);
    }
  `,
}

const SlideTransition = ({ in: isIn, from, children }) => (
  <CSSTransition classNames="slide" timeout={0} in={isIn}>
    {React.cloneElement(children, {
      css: css`
        ${transition};
        ${origins[from]};
      `,
    })}
  </CSSTransition>
)

SlideTransition.defaultProps = {
  in: false,
  from: 'left',
}

SlideTransition.propTypes = {
  children: PropTypes.node.isRequired,
  from: PropTypes.oneOf(Object.keys(origins)),
  in: PropTypes.bool,
}

export default SlideTransition
