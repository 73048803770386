import React from 'react'
import gql from 'graphql-tag'
import { toInlineFragment } from 'fraql'
import { propType, filter } from 'graphql-anywhere'
import Helmet from 'components/HelmetIntl'
import styled from '@emotion/styled'
import Trombinoscope, { queryFilters } from './Trombinoscope'

const Wrapper = styled.div`
  margin-top: 40px;
`

const TrombinoscopePage = ({ session }) => (
  <Wrapper>
    <Helmet title="courses.course.trombinoscope.helmet_title" values={{ title: session.course.title }} />
    <Trombinoscope session={filter(Trombinoscope.fragments.session, session)} />
  </Wrapper>
)

TrombinoscopePage.fragments = {
  session: gql`
    fragment _ on Session {
      id
      course {
        title
      }
      ${toInlineFragment(Trombinoscope.fragments.session)}
    }
  `,
}

TrombinoscopePage.propTypes = {
  session: propType(TrombinoscopePage.fragments.session).isRequired,
}

export { queryFilters }

export default TrombinoscopePage
