import React from 'react'
import PropTypes from 'prop-types'
import StreamlineIcon from '@unowmooc/icons'
import styled from '@emotion/styled'
import { colors } from 'styles-resources'

const StyledStreamlineIcon = styled(StreamlineIcon, { shouldForwardProp: prop => prop !== 'valid' })`
  color: ${({ valid }) => (valid ? colors.unowGreen : undefined)};
`

const SectionIcon = ({ valid, icon }) => <StyledStreamlineIcon valid={valid} icon={valid ? 'check' : icon} />

SectionIcon.defaultProps = {
  valid: false,
}

SectionIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  valid: PropTypes.bool,
}

export default SectionIcon
