import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { FormattedMessage } from 'react-intl'
import { Alert } from '@unowmooc/react-ui-kit'

const GeneralError = ({ submitError, ...props }) => {
  if (!submitError) {
    return null
  }

  return (
    <Alert theme="error" {...props}>
      {_.isString(submitError) ? (
        submitError
      ) : (
        <FormattedMessage id="api_errors.general_error" values={{ message: submitError.message }} />
      )}
    </Alert>
  )
}

GeneralError.defaultProps = {
  submitError: undefined,
}

GeneralError.propTypes = {
  submitError: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
}

export default GeneralError
