import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import ErrorBloc from 'components/ErrorBloc'

const StyledErrorBloc = styled(ErrorBloc)`
  margin: 50px 0;
  padding: 0 30px;
`

class ErrorHandler extends Component {
  get isResourceNotFound() {
    const { error } = this.props

    return error === 'resource_not_found'
  }

  render() {
    const { isResourceNotFound } = this

    return (
      <StyledErrorBloc i18nMessage={`not_found.${isResourceNotFound ? 'resource_not_found' : 'unexpected_error'}`} />
    )
  }
}

ErrorHandler.propTypes = {
  error: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]).isRequired,
}

export default ErrorHandler
