import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import gql from 'graphql-tag'
import { toInlineFragment } from 'fraql'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { Row, Col } from 'react-grid-system'
import { mq } from '@unowmooc/themes'
import CourseBox from 'components/CourseBox'

const Title = styled.h2`
  margin-bottom: 54px;
  text-align: center;

  ${mq.sm(css`
    margin-bottom: 30px;
  `)};
`

const RowCourses = styled(Row)`
  ${mq.md(css`
    padding-bottom: 24px;
    flex-wrap: nowrap !important;
    overflow-x: scroll !important;
    overflow-y: hidden !important;
  `)};
`

const ColCourseBox = styled(Col)`
  ${mq.md(css`
    &:not(:last-child) {
      padding-right: 0 !important;
    }
  `)};
`

const RecommendedCourses = ({ courses, className }) => (
  <div className={className}>
    <Title>
      <FormattedMessage id="dashboard.recommended_courses.title" />
    </Title>
    <RowCourses>
      {courses.map(course => (
        <ColCourseBox xs={11} sm={7} md={6} lg={4} key={course.id}>
          <CourseBox course={course} />
        </ColCourseBox>
      ))}
    </RowCourses>
  </div>
)

RecommendedCourses.fragment = gql`
  fragment RecommendedCourses on Course {
    id
    ${toInlineFragment(CourseBox.fragments.course)}
  }
`

RecommendedCourses.defaultProps = {
  className: undefined,
}

RecommendedCourses.propTypes = {
  courses: PropTypes.arrayOf(propType(RecommendedCourses.fragment)).isRequired,
  className: PropTypes.string,
}

export default RecommendedCourses
