import React, { Component, createContext } from 'react'
import PropTypes from 'prop-types'

const QueryMetadataContext = createContext()

class QueryMetadataProvider extends Component {
  constructor(props) {
    super(props)

    this.state = {
      metadata: null,
    }

    this.storeMetadata = metadata => {
      this.setState(() => ({ metadata }))
    }

    this.clearMetadata = () => {
      this.setState({ metadata: null })
    }
  }

  render() {
    const { children, metadata: propsMetadata } = this.props
    const { metadata } = this.state

    return (
      <QueryMetadataContext.Provider
        value={{
          metadata: propsMetadata || metadata,
          storeMetadata: this.storeMetadata,
          clearMetadata: this.clearMetadata,
        }}
      >
        {children}
      </QueryMetadataContext.Provider>
    )
  }
}
QueryMetadataProvider.defaultProps = {
  metadata: undefined,
}

QueryMetadataProvider.propTypes = {
  metadata: PropTypes.shape(),
  children: PropTypes.node.isRequired,
}

export default QueryMetadataProvider

export const Context = QueryMetadataContext
export const QueryMetadataConsumer = QueryMetadataContext.Consumer

export const withQueryMetadata = ComponentParam => props => (
  <QueryMetadataConsumer>{store => <ComponentParam {...props} {...store} />}</QueryMetadataConsumer>
)
