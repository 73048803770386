import React from 'react'
import PropTypes from 'prop-types'
import { propType, filter } from 'graphql-anywhere'
import gql from 'graphql-tag'
import moment from 'moment'
import { toInlineFragment } from 'fraql'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { FormattedMessage, FormattedHTMLMessage, FormattedRelative } from 'react-intl'
import { Link, withRouter } from 'react-router-dom'
import I18nProvider from '@unowmooc/i18n'
import { colors, mq } from '@unowmooc/themes'
import LinkButtonIcon from 'components/LinkButtonIcon'
import ParticipationAdvancement from 'components/ParticipationAdvancement'
import TagSessionStatus from 'components/TagSessionStatus'
import SessionRow, { NbParticipants } from 'components/SessionRow'
import { isParticipationStarted } from 'modules/participant/utils'
import { getPlatformLinkLabel, isSessionStarted } from 'modules/session/utils'
import { getSessionCode } from 'modules/session/session.utils'
import DateClosingPeriod from 'modules/session/components/DateClosingPeriod'
import classnames from 'classnames'
import ShareResult from './ShareResult'

const Wrapper = styled(SessionRow)`
  &.certified {
    border-left: 4px solid ${colors.unowOrange};
    padding-left: 36px;

    ${mq.sm(css`
      padding-left: 20px;
      border-top: 4px solid ${colors.unowOrange};
      border-left: 0;
    `)};
  }
`

const Participation = ({
  participation: {
    session,
    user,
    badges,
    progressions,
    advancement,
    certificateNumber,
    certificateValidatedAt,
    professionalCertificationNumber,
  },
  className,
}) => {
  const sessionIsStarted = isSessionStarted(session)
  const participationStarted = isParticipationStarted({
    badges,
    progressions,
  })
  const classNames = classnames({
    [className]: true,
    certified:
      (!session.hasFinalExam && advancement.status === 'finisher_not_certified') ||
      (session.hasFinalExam && advancement.status === 'certified'),
  })

  return (
    <Wrapper
      title={session.course.title}
      startAt={session.startAt}
      endAt={session.endAt}
      sessionCode={getSessionCode(session)}
      sessionId={session.id}
      {...filter(DateClosingPeriod.fragment, session)}
      tags={<TagSessionStatus status={session.runningStatus} currentWeek={session.currentWeek} />}
      details={
        (!sessionIsStarted && (
          // Session à venir
          <FormattedMessage
            id="dashboard.participations.planned.open_at"
            values={{
              relativeDate: <FormattedRelative value={moment(session.startAt)} />,
            }}
          />
        )) || (
          // Toutes les autres
          <>
            <NbParticipants>
              <FormattedHTMLMessage
                id="dashboard.participations.in_progress.nb_participants"
                values={{ nbParticipants: session.nbParticipants }}
              />
            </NbParticipants>
            <ParticipationAdvancement advancement={advancement} user={user} />
          </>
        )
      }
      shareResult={
        certificateNumber && (
          <ShareResult
            certificateNumber={certificateNumber}
            certificateValidatedAt={certificateValidatedAt}
            professionalCertificationNumber={professionalCertificationNumber}
            course={session.course}
            sessionIdentifier={session.identifier}
          />
        )
      }
      buttons={
        <>
          {!isSessionStarted(session) && session.hasGuide && (
            <LinkButtonIcon
              tag={Link}
              to={I18nProvider.getLinkRoute(
                `/courses/{courseSlug}/{idSession}/guide?light&from=${window.location.href}`,
                {
                  courseSlug: session.course.slug,
                  idSession: session.id,
                },
              )}
              icon="document"
              iconColor={colors.unowGreen}
              theme="secondary"
            >
              <FormattedMessage id="dashboard.participations.planned.show_guide" />
            </LinkButtonIcon>
          )}

          {(session.hasAccessModuleZero ||
            isSessionStarted(session) ||
            (session.course.type === 'mooc' && session.runningStatus === 'confirmed')) &&
            !certificateNumber && (
              <LinkButtonIcon
                data-cy="button-start-session"
                tag={Link}
                to={I18nProvider.getLinkRoute('/courses/{slugCourse}/{idSession}', {
                  slugCourse: session.course.slug,
                  idSession: session.id,
                })}
                icon="right"
              >
                <FormattedMessage id={getPlatformLinkLabel({ sessionIsStarted, participationStarted })} />
              </LinkButtonIcon>
            )}

          {certificateNumber && (
            <Link
              tag={Link}
              to={I18nProvider.getLinkRoute('/courses/{slugCourse}/{idSession}', {
                slugCourse: session.course.slug,
                idSession: session.id,
              })}
              icon="right"
            >
              <FormattedMessage id="dashboard.participations.finished.see_course" />
            </Link>
          )}
        </>
      }
      className={classNames}
    />
  )
}

Participation.fragment = gql`
  fragment Participation on Participant {
    id
    type
    status
    professionalCertificationNumber
    advancement {
      ...ParticipationAdvancement
    }
    user {
      id
      ...ParticipationAdvancementUser
    }
    badges {
      id
    }
    progressions {
      id
    }
    certificateNumber
    certificateValidatedAt
    session {
      id
      token
      runningStatus
      currentWeek
      startAt
      confirmedAt
      endAt
      nbParticipants
      identifier
      hasGuide
      hasAccessModuleZero
      hasFinalExam
      course {
        id
        slug
        type
        hasFlexibleStart
        title
        identifier
      }
      ${toInlineFragment(DateClosingPeriod.fragment)}
    }
  }
  ${ParticipationAdvancement.fragments.advancement}
  ${ParticipationAdvancement.fragments.user}
`

Participation.defaultProps = {
  className: undefined,
}

Participation.propTypes = {
  participation: propType(Participation.fragment).isRequired,
  className: PropTypes.string,
}

export default withRouter(Participation)
