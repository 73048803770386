import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'
import { Link, Switch, Route } from 'react-router-dom'
import { Box, Flex } from 'reflexbox'
import { Text } from '@unowmooc/text'
import { ButtonCore, ButtonI18n } from '@unowmooc/buttons'
import Icon from '@unowmooc/icons'
import { Alert } from '@unowmooc/react-ui-kit'
import DataGridCell from '@unowmooc/react-ui-kit/lib/DataGrid/Cell'
import I18nProvider from '@unowmooc/i18n'
import { withUser } from 'store/UserProvider'
import Helmet from 'components/HelmetIntl'
import DataGridGraphQL from 'components/DataGridGraphQL'
import DataGridToolbar from 'components/DataGridToolbar'
import Title from 'components/Title'
import { getSupervisionResultsUrl } from 'business/supervisor'

import TagParticipantStatus from 'modules/participant/components/TagParticipantStatus'
import ParticipantAdvancement from 'modules/participant/components/ParticipantAdvancement'
import TagParticipantAdvancementStatus from 'modules/participant/components/TagParticipantAdvancementStatus'
import AddPage from './add'

const Helper = styled(Alert)`
  margin-bottom: 30px;
`

const fragment = gql`
  fragment ListRegisteredUsersFragment on Participant {
    id
    user {
      id
      slug
      fullName
    }
    session {
      id
      course {
        title
      }
    }
    advancement {
      ...ParticipantAdvancementFragment
      ...TagParticipantAdvancementStatusFragment
    }
    ...TagParticipantStatusFragment
  }
  ${TagParticipantStatus.fragment}
  ${ParticipantAdvancement.fragment}
  ${TagParticipantAdvancementStatus.fragment}
`

const ParticipantsPage = ({ user }) => (
  <Switch>
    <Route
      exact
      path="/espace-superviseur/inscrits"
      render={() => (
        <>
          <Helmet title="page_titles.supervisor.registered_users.title" />
          <Title>
            <FormattedMessage id="registered_users.page_title" />
          </Title>
          <Helper>
            <FormattedMessage id="registered_users.register_user_helper" />
          </Helper>
          <DataGridToolbar
            rightItem={
              <Flex alignItems="center" ml="20px">
                <a href={getSupervisionResultsUrl()}>
                  <ButtonCore size="normal" variant="secondary" fontFamily="body" fontWeight="action">
                    <Icon icon="download" color="unowGreen" mr="14px" />
                    <Text i18nKey="supervisor.download_link.label" />
                  </ButtonCore>
                </a>
                <Box minWidth="20px" minHeight="20px" />
                <Link to="/espace-superviseur/inscrits/session">
                  <ButtonI18n
                    variant="primary"
                    i18nKey="supervisor.welcome.buttons.add_colleague"
                    data-tracking-id="session.register_participants"
                  />
                </Link>
              </Flex>
            }
          />
          <DataGridGraphQL
            queryName="participants"
            queryFragment={fragment}
            typeName="Participant"
            filters={{
              canceledAt: null,
              project_supervisions_user_id: user.id,
            }}
            limit={50}
          >
            <DataGridCell
              label={<FormattedMessage id="registered_users.participant" />}
              size="large"
              render={({ user: { id, fullName, slug } }) =>
                fullName ? (
                  <Link
                    to={I18nProvider.getLinkRoute('/profile/{slug}/{id}', { slug, id })}
                    data-tracking-id="session.click_on_profile_page"
                    data-tracking-values={JSON.stringify({
                      from: 'SupervisorRegisteredUsers',
                    })}
                  >
                    {fullName}
                  </Link>
                ) : (
                  <FormattedMessage id="commons.unspecified" />
                )
              }
            />
            <DataGridCell
              label={<FormattedMessage id="registered_users.course" />}
              property="session"
              render={({ id, course: { title } }) => (
                <>
                  <Link to={I18nProvider.getLinkRoute('/supervisor/sessions/{id}', { id })}>{title}</Link>
                  <br />
                </>
              )}
            />
            <DataGridCell
              label={<FormattedMessage id="registered_users.status" />}
              property="status"
              render={status => <TagParticipantStatus status={status} />}
            />
            <DataGridCell
              width="auto"
              label={<FormattedMessage id="registered_users.advancementLastStepValidate" />}
              property="advancement"
              render={advancement => <ParticipantAdvancement advancement={advancement} />}
            />
            <DataGridCell
              width="auto"
              label={<FormattedMessage id="registered_users.advancementStatus" />}
              property="advancement"
              render={(advancement, { data: { user: userAdvancement } }) => (
                // On doit ici ajouter une div afin que l'on ne tombe pas sur une règle CSS de l'ancien UI-Kit sur DataGrid
                // (.dataGrid___3eGIY > div:last-child > div:last-child > div:last-child),
                // qui casserais la mise en forme du dernier élément du tableau"
                <div>
                  <TagParticipantAdvancementStatus advancement={advancement} user={userAdvancement} />
                </div>
              )}
            />
          </DataGridGraphQL>
        </>
      )}
    />
    <Route path="/espace-superviseur/inscrits" component={AddPage} />
  </Switch>
)

ParticipantsPage.propTypes = {
  user: PropTypes.shape().isRequired,
}

export default withUser(ParticipantsPage)
