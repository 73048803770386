import React from 'react'
import PropTypes from 'prop-types'
import { Flex } from 'reflexbox'
import StreamlineIcon from '@unowmooc/icons'
import { mq } from '@unowmooc/themes'
import { css } from '@emotion/core'
import styled from '@emotion/styled'

const Input = styled('input', { shouldForwardProp: () => true })`
  position: absolute;
  opacity: 0;
`

const Message = styled('span', { shouldForwardProp: () => true })`
  display: flex;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.shipGray};
  user-select: none;
  &[data-label-direction='right'] {
    margin-left: 13px;
  }
  &[data-label-direction='left'] {
    margin-right: 13px;
  }

  ${mq.sm(css`
    font-size: 14px;
  `)};
`

const Checkmark = styled.span`
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.mischka};
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.white};
`

const Label = styled(Flex)`
  position: relative;
  min-height: 21px;
  cursor: pointer;
  user-select: none;

  display: flex;

  &[data-label-direction='top'],
  &[data-label-direction='bottom'] {
    flex-direction: column;
    align-items: center;
  }
  &[data-label-direction='right'],
  &[data-label-direction='left'] {
    flex-direction: row;
    align-items: flex-start;
  }

  & input:checked ~ span ${Checkmark} {
    color: ${({ theme }) => theme.colors.unowGreen};
    border-color: ${({ theme }) => theme.colors.unowGreen};
    &:after {
      background-color: ${({ theme }) => theme.colors.unowGreen};
    }
  }
  & input:focus ~ span ${Checkmark} {
    box-shadow: 0 0 4px ${({ theme }) => theme.colors.unowBluegreen};
    border-color: ${({ theme }) => theme.colors.unowGreen};
  }
  &:hover > span ${Checkmark} {
    border-color: ${({ theme }) => theme.colors.unowGreen};
  }
  & input:disabled ~ ${Message}, & input:disabled ~ span ${Checkmark} {
    color: ${({ theme }) => theme.colors.mischka};
    border-color: ${({ theme }) => theme.colors.mischka};
  }
  & input:disabled {
    cursor: not-allowed;
  }
  & input:disabled ~ span ${Checkmark}, & input:disabled ~ ${Message} {
    cursor: not-allowed;
  }
`

const Icon = styled(StreamlineIcon)`
  position: absolute;
  top: -2px;
  left: -2px;
  font-size: 24px;
  line-height: 24px;
  transition: transform 0.1s ease;
`

const Checkbox = React.forwardRef(
  ({ label, labelDirection, className, onChange, isChecked, disabled, tabIndex, ...props }, ref) => {
    const handleChange = event => {
      onChange(event.target.checked)
    }

    const checked = isChecked(props)

    return (
      <Label as="label" data-label-direction={labelDirection} className={className}>
        <Input
          disable={disabled}
          checked={checked}
          onChange={handleChange}
          ref={ref}
          type="checkbox"
          tabIndex={tabIndex}
        />
        {label && ['left', 'top'].includes(labelDirection) && (
          <Message className="checkbox-label" data-label-direction={labelDirection}>
            {label}
          </Message>
        )}
        <span>
          <Checkmark>{checked && <Icon icon="check" />}</Checkmark>
        </span>
        {label && ['right', 'bottom'].includes(labelDirection) && (
          <Message className="checkbox-label" data-label-direction={labelDirection}>
            {label}
          </Message>
        )}
      </Label>
    )
  },
)

Checkbox.type = 'radio'

Checkbox.defaultProps = {
  label: '',
  labelDirection: 'right',
  className: null,
  disabled: false,
  isChecked: ({ checked }) => checked ?? false,
  tabIndex: null,
}

Checkbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  labelDirection: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isChecked: PropTypes.func,
  tabIndex: PropTypes.number,
}

export default Checkbox
