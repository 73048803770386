import styled from '@emotion/styled'
import { layout, flexbox, space } from 'styled-system'

export const List = styled.ul`
  margin: 0;
  padding: 0;

  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;

  ${space}
`

export const Item = styled.li`
  display: inline-flex;
  float: none;

  ${space}
  ${flexbox}
  ${layout}
`
