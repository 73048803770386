import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import axios from 'utils/axios'

const UPLOAD_TIMEOUT_MILISECONDS = 240000
const COMPLETED_PERCENT_THRESHOLD = 90

const prepareBasePreviewFromFile = function (file) {
  return { filename: file.name, preview: URL.createObjectURL(file), mimeType: file.type }
}

// cf https://github.com/unowmooc/hub/pull/780/files

const FileStackUploaderv2 = ({ render, value, onChange }) => {
  let defaultFile = null
  if (value) {
    defaultFile = {
      filename: value.filename,
      preview: value.secureUrl,
    }
  }

  const [file, setFile] = useState(defaultFile)
  const [fileUploadProgress, setFileUploadProgress] = useState(0)
  const [fileUploadError, setFileUploadError] = useState(false)

  const doUploadFile = async function (files) {
    const formData = new FormData()

    const file2 = files[0]
    formData.append('file', file2)

    const config = {
      timeout: UPLOAD_TIMEOUT_MILISECONDS,
      onUploadProgress: progressEvent => {
        let percentCompleted = Math.round((progressEvent.loaded / progressEvent.total) * 100)

        // La progression ne compte que le temps d'upload, on a aussi le temps de traitement de la requpete
        // on bloque à un certain seuil pour bien prendre cela en compte
        if (percentCompleted > COMPLETED_PERCENT_THRESHOLD) {
          percentCompleted = COMPLETED_PERCENT_THRESHOLD
        }

        setFileUploadProgress(percentCompleted)
      },
    }

    axios
      .post('/filestack-upload', formData, config)
      .then(function (returnValue) {
        setFileUploadProgress(100)
        const preview = prepareBasePreviewFromFile(file2)
        preview.fileStackId = returnValue.data.filestack_id

        setFile({
          filename: preview.filename,
          preview: preview.preview,
        })

        onChange(preview)
      })
      .catch(function () {
        setFileUploadError(true)
      })
  }

  // Dans le cas où aucune progression ne serait retournée on se réfère à l'existence du fichier retourné par FileStack pour définir si l'upload est terminé.
  const progressRender = fileUploadProgress || (file ? 100 : 0)

  return render({
    file,
    onChange: doUploadFile,
    progress: progressRender,
    error: fileUploadError && <FormattedMessage id="uploader.upload_error" />,
  })
}

FileStackUploaderv2.defaultProps = {
  value: undefined,
  onChange: undefined,
}

FileStackUploaderv2.propTypes = {
  render: PropTypes.func.isRequired,
  value: PropTypes.shape(),
  onChange: PropTypes.func,
}

export default FileStackUploaderv2
