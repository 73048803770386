import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const ListItem = styled.li`
  line-height: 33px;

  strong {
    color: ${({ theme: { colors } }) => colors.woodsmoke};
  }

  i {
    font-size: 28px;
    margin-right: 12px;
  }
`

const IntroBoxStep = ({ children }) => <ListItem>{children}</ListItem>

IntroBoxStep.propTypes = {
  children: PropTypes.node.isRequired,
}

export default IntroBoxStep
