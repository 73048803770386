import CalendarPicker from '@unowmooc/react-ui-kit/lib/CalendarPicker'
import moment from 'moment/moment'

class CustomCalendarPicker extends CalendarPicker {
  handleChange = value => {
    const { onChange } = this.props

    this.setState({ value, isFieldDirty: true })

    if (onChange) {
      if (value instanceof moment) {
        onChange(value)

        return
      }

      // Dans le cas où le champ est vide on retourne bien une valeur null
      if (!value) {
        onChange(null)

        return
      }

      // Il faut forcer une date invalide quand le composant react-datetime ne retourne pas l'objet Moment.
      // Cela va permettre de ne pas se retrouver dans des configurations où il valide une date "1/6/2"
      onChange(moment('invalid date'))
    }

    this.props.secondaryOnChange(value)
  }

  render() {
    // permet depuis la date de début, forcer la valeur
    const { forcedValue } = this.props

    // Il faut bien mettre le undefined sinon en prod ça ne passe pas
    if (forcedValue && (this.state.isFieldDirty === false || this.state.isFieldDirty === undefined)) {
      const momentValue = moment(forcedValue)

      if (momentValue && momentValue instanceof moment && momentValue.isValid()) {
        this.state = {
          value: momentValue.isValid()
            ? momentValue.format(this.props.type === CalendarPicker.types.date ? 'L' : 'LT')
            : undefined,
          isFieldDirty: false,
        }
      }
    }

    // reprise du parent en faisant un disabled
    // (sans disabled on peux juste faire un return super.render())
    return super.render()
  }
}
export default CustomCalendarPicker
