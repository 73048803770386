import { useEffect, useRef } from 'react'

const callback = () => {}

/**
 *
 * Comme le useEffect, mais le useDidUpdateEffect est appelé au changement de props et au UnMount.
 * @param {*} fn
 * @param {*} inputs
 */
export const useDidUpdateEffect = (fn, inputs) => {
  const didMountRef = useRef(false)

  useEffect(() => {
    if (didMountRef.current) {
      // On doit retourner cette fonction pour que le unmount soit pris en compte.
      return fn()
    }

    didMountRef.current = true
    return callback
  }, inputs)
}
