import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Checkbox from 'components/Checkbox'

const Wrapper = styled('div', { shouldForwardProp: () => true })`
  display: flex;

  &[data-inline='true'] {
    flex-direction: row;
  }
  &[data-inline='false'] {
    flex-direction: column;
  }
`

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 10px;
`

const isCheckedOption = (value, option) => value.some(val => val.id === option.id)

const CheckboxGroup = ({ name, options, value, onChange, disabled, success, error, inline, className, ...props }) => (
  <Wrapper data-inline={inline} className={className}>
    {options.map(option => (
      <StyledCheckbox
        {...props}
        id={`${name}[${option.id}]`}
        checked={isCheckedOption(value, option)}
        disabled={disabled || option.disabled}
        success={success || option.success}
        error={error || option.error}
        label={option.label}
        name={`${name}[${option.id}]`}
        onChange={() => {
          const nextValue = isCheckedOption(value, option)
            ? value.filter(val => val.id !== option.id)
            : [...value, option]

          onChange(nextValue)
        }}
        inline={inline}
        key={option.id}
      />
    ))}
  </Wrapper>
)

CheckboxGroup.defaultProps = {
  inline: false,
  disabled: false,
  success: false,
  error: false,
  value: [],
  className: undefined,
}

CheckboxGroup.propTypes = {
  name: PropTypes.string.isRequired,
  inline: PropTypes.bool,
  disabled: PropTypes.bool,
  success: PropTypes.bool,
  error: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.node.isRequired,
      disabled: PropTypes.bool,
      error: PropTypes.bool,
    }),
  ).isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
  ),
  className: PropTypes.string,
}

export default CheckboxGroup
