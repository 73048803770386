import gql from 'graphql-tag/lib/graphql-tag.umd'

const genericFragment = gql`
  fragment ConversationGenericFragment on ConversationInList {
    id
    subject
    date
    session {
      id
      identifier
      slug
      course {
        id
        title
      }
    }
    members {
      id
      user {
        id
        fullname
        avatarUrl
      }
    }
  }
`

const config = {
  participant: gql`
    query ConversationsQuery($limit: Int, $withIsResolved: Boolean, $withSessionIds: [String]) {
      conversations: conversationsForAuthenticatedParticipant(
        limit: $limit
        withIsResolved: $withIsResolved
        withSessionIds: $withSessionIds
      ) {
        items {
          ...ConversationGenericFragment
        }
        _meta {
          total
        }
      }
    }
    ${genericFragment}
  `,
  expert: gql`
    query ConversationsQuery($limit: Int, $withIsResolved: Boolean, $withSessionIds: [String]) {
      conversations: conversationsForAuthenticatedAnimator(
        limit: $limit
        withIsResolved: $withIsResolved
        withSessionIds: $withSessionIds
      ) {
        items {
          id
          isResolved
          hasForwardedMessage
          session {
            id
            identifier
          }
          ...ConversationGenericFragment
        }
        _meta {
          total
          totalWithoutFilters
        }
      }
    }
    ${genericFragment}
  `,
}

const getQuery = domain => {
  if (!Object.prototype.hasOwnProperty.call(config, domain)) {
    throw new Error('Domain undefined for query ConversationsQuery')
  }

  return config[domain]
}

export default getQuery
