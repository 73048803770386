import React, { Component } from 'react'
import PropTypes from 'prop-types'
import s from 'underscore.string'
import I18nProvider from '@unowmooc/i18n'
import { FormattedHTMLMessage } from 'react-intl'
import { Link as RouterLink, withRouter } from 'react-router-dom'
import styled from '@emotion/styled'
import { colors, transitions } from 'styles-resources'
import { ZeroHeightDev } from '@unowmooc/zeroheight'

const StyledLink = styled(({ active, ...props }) => <RouterLink {...props} />)`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 48px;
  color: ${({ active }) => (active ? colors.black : colors.manatee)};
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: ${transitions.color};

  &:hover {
    color: ${colors.black};
    text-decoration: none;

    div {
      opacity: 1;
    }
  }

  div {
    opacity: ${({ active }) => (active ? 1 : 0.6)};
    transition: ${transitions.opacity};
  }
`

const Label = styled.span`
  margin-left: 18px;
  white-space: nowrap;
`

class Link extends Component {
  constructor(props) {
    super(props)

    const { location, domain } = props

    if (!location || !domain) {
      throw new Error('Do not use DomainLayoutLink outside DomainLayout component')
    }
  }

  render() {
    const { hidden } = this.props

    if (hidden) {
      return null
    }

    const {
      domain,
      name,
      icon,
      location: { pathname },
    } = this.props

    const to = I18nProvider.getLinkRoute(`/${domain}/${name}`)
    const active = new RegExp(`^${to}`).test(pathname)

    return (
      <StyledLink key={name} to={to} active={active}>
        <ZeroHeightDev name={icon} width="32px" height="32px" active={active} />
        <Label>
          <FormattedHTMLMessage id={`menu.${domain}.${s.replaceAll(name, '-', '_')}`} />
        </Label>
      </StyledLink>
    )
  }
}

Link.isDomainLayout = true

Link.defaultProps = {
  location: undefined,
  domain: undefined,
  hidden: false,
}

Link.propTypes = {
  location: PropTypes.shape(),
  domain: PropTypes.string,
  component: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
  name: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  hidden: PropTypes.bool,
}

export default withRouter(Link)
