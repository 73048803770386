import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import Truncate from 'react-truncate'
import PropTypes from 'prop-types'
import { Clickable } from '@unowmooc/react-ui-kit'
import styled from '@emotion/styled'

const Button = styled(Clickable)`
  color: ${({ theme: { colors } }) => colors.unowGreen};
  font-size: 14px;
  line-height: 16px;

  &:hover {
    text-decoration: underline;
  }
`

class ReadMore extends Component {
  constructor(props) {
    super(props)

    this.state = { expanded: false }

    this.toggleLines = this.toggleLines.bind(this)
  }

  toggleLines() {
    const { expanded } = this.state

    this.setState({ expanded: !expanded })
  }

  render() {
    const { children, lines } = this.props

    const { expanded } = this.state

    return (
      <>
        <Truncate
          lines={!expanded && lines}
          ellipsis={
            <>
              <FormattedMessage id="read_more.ellipsis" />
              <br />
              <Button onClick={this.toggleLines}>
                <FormattedMessage id="read_more.see_more" />
              </Button>
            </>
          }
        >
          {children}
        </Truncate>
        {expanded && (
          <>
            <br />
            <Button onClick={this.toggleLines}>
              <FormattedMessage id="read_more.see_minus" />
            </Button>
          </>
        )}
      </>
    )
  }
}

ReadMore.defaultProps = {
  lines: 6,
}

ReadMore.propTypes = {
  children: PropTypes.node.isRequired,
  lines: PropTypes.number,
}

export default ReadMore
