import moment from 'moment'
import I18nProvider from '@unowmooc/i18n'

export const getPackExpireAtDateLabel = pack => {
  if (!pack.expireAt) {
    return I18nProvider.formatMessage({ id: 'project.expire_at_label_undefined' })
  }

  return I18nProvider.formatMessage({
    id: 'project.expire_at_label_defined',
    values: { date: moment(pack.expireAt).format('L') },
  })
}
