import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { FormattedMessage } from 'react-intl'
import { withRouter, Link } from 'react-router-dom'
import { Box, Flex } from 'reflexbox'
import { DataGrid } from '@unowmooc/react-ui-kit'
import DataGridCell from '@unowmooc/react-ui-kit/lib/DataGrid/Cell'
import { UnowAwesome } from '@unowmooc/loaders'
import I18nProvider from '@unowmooc/i18n'

import { ViewBoxInfo } from '@unowmooc/form'
import Alert from '@unowmooc/alert'
import Tag from '@unowmooc/tags'
import { ButtonI18n } from '@unowmooc/buttons'
import { useStateMachine } from 'little-state-machine'
import { processParticipantsFromFile } from 'modules/participant/actions'
import { useTracking } from 'modules/tracking'

const cleanStore = oldState => ({
  ...oldState,
  userRegistration: {},
})

const Confirm = ({ history, match: { params } }) => {
  const { sendEvent } = useTracking()
  const { state, actions } = useStateMachine({ cleanStore })
  const [result, setResult] = useState({ loading: true, error: undefined, data: undefined })

  useEffect(() => {
    if (params.importId) {
      processParticipantsFromFile(params.importId)
        .then(data => {
          setResult({
            loading: false,
            data: {
              ...state.userRegistration,
              collaborators: data.data.participants,
            },
            error: undefined,
          })
          sendEvent(
            'session.participants_registration_completed',
            JSON.stringify({
              registrationMode: 'file',
              registrationNumber: data.data.participants.length,
            }),
          )
        })
        .catch(error => {
          setResult({
            loading: false,
            data: undefined,
            error,
          })
        })
    } else {
      setResult({ loading: false, data: state.userRegistration, error: undefined })
      sendEvent(
        'session.participants_registration_completed',
        JSON.stringify({
          registrationMode: 'manual',
          registrationNumber: state.userRegistration.collaborators.length,
        }),
      )
    }

    return () => {
      actions.cleanStore()
    }
  }, [])

  if (!state.userRegistration || _.isEmpty(state.userRegistration)) {
    history.push(I18nProvider.getLinkRoute('/supervisor/registered_users'))

    return null
  }

  const hanleRedirectionRegisterUsers = event => {
    event.preventDefault()

    actions.cleanStore()
    history.push(I18nProvider.getLinkRoute('/supervisor/registered_users/session'))
  }

  return (
    <>
      <Box>
        <ViewBoxInfo
          width={1 / 3}
          margin="0"
          maxWidth="260px"
          label={<FormattedMessage id="labels.course" />}
          value={_.get(result, 'data.course.label')}
        />
        <ViewBoxInfo
          width={1 / 3}
          margin="0"
          maxWidth="260px"
          label={<FormattedMessage id="labels.session" />}
          value={_.get(result, 'data.session.label')}
        />
        <ViewBoxInfo
          width={1 / 3}
          margin="0"
          maxWidth="260px"
          label={<FormattedMessage id="labels.pack" />}
          value={_.get(result, 'data.pack.label')}
        />
      </Box>
      {result.loading && (
        <Flex alignItems="center" flexDirection="column" mt="40px">
          <Box mb="30px">
            <UnowAwesome size={90} />
          </Box>
          <FormattedMessage id="supervisor.registration.add.loading" />
        </Flex>
      )}

      {_.get(result, 'error.response.data.error') && (
        <Alert theme="error" mt="20px" mb="30px">
          <FormattedMessage
            id={`supervisor.registration.add.errors.${_.get(result, 'error.response.data.error.code')}`}
            values={{
              number: _.uniqWith(
                _.get(result, 'error.response.data.error.errors'),
                (prev, next) => prev.line === next.line,
              ).length,
            }}
          />
        </Alert>
      )}

      {_.get(result, 'data.collaborators') && (
        <>
          <Alert mt="20px" mb="30px">
            <FormattedMessage
              id="supervisor.registration.add.success"
              values={{
                number: _.get(result, 'data.collaborators.length', 0),
              }}
            />
          </Alert>
          <Flex justifyContent="center" my="30px">
            <Link to={I18nProvider.getLinkRoute('/supervisor/registered_users')}>
              <ButtonI18n i18nKey="supervisor.registration.add.buttons.backToList" mr="25px" />
            </Link>
            <a href="/" onClick={hanleRedirectionRegisterUsers}>
              <ButtonI18n i18nKey="supervisor.registration.add.buttons.newRegistration" variant="primary" />
            </a>
          </Flex>
          <DataGrid data={_.get(result, 'data.collaborators', [])}>
            <DataGridCell
              label={<FormattedMessage id="supervisor.registration.add.form.firstName" />}
              property="firstName"
              width={100}
            />
            <DataGridCell
              label={<FormattedMessage id="supervisor.registration.add.form.lastName" />}
              property="lastName"
              width={100}
            />
            <DataGridCell property="email" label={<FormattedMessage id="supervisor.registration.add.form.email" />} />
            <DataGridCell property="phone" label={<FormattedMessage id="supervisor.registration.add.form.phone" />} />
            <DataGridCell
              width={100}
              label={<FormattedMessage id="supervisor.registration.add.form.status" />}
              render={() => (
                <Box>
                  <Box>
                    <Tag theme="lightGreen" small flex="0 1 auto">
                      <span>
                        <FormattedMessage id="supervisor.registration.add.status.register" />
                      </span>
                    </Tag>
                  </Box>
                </Box>
              )}
            />
          </DataGrid>
        </>
      )}
    </>
  )
}

Confirm.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape().isRequired,
  }).isRequired,
}

export default withRouter(Confirm)
