import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import styled from '@emotion/styled'

const Wrapper = styled.div`
  text-align: center;
`

const Illu = styled.img`
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
`

const Digit = styled.p`
  margin-bottom: 6px;
  font-size: 35px;
  line-height: 25px;
`

const Legend = styled.p`
  font-size: 14px;
  line-height: 23px;
  color: ${({ theme: { colors } }) => colors.midGray};
`

const KeyNumber = ({ illu, number, legend, className }) => (
  <Wrapper className={className}>
    <Illu src={illu} />
    <Digit>{number}</Digit>
    <Legend>{legend}</Legend>
  </Wrapper>
)

KeyNumber.fragments = {
  user: gql`
    fragment _ on User {
      id
    }
  `,
}

KeyNumber.defaultProps = {
  className: undefined,
}

KeyNumber.propTypes = {
  illu: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  legend: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default KeyNumber
