import React, { useState } from 'react'
import styled from '@emotion/styled'
import { ButtonI18n } from '@unowmooc/buttons'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { FormProvider, useForm } from 'react-hook-form'
import Modal from 'components/Modal'
import { useMutation } from '@apollo/react-hooks'
import { captureException } from '@sentry/browser'
import { mq } from '@unowmooc/themes'
import { css } from '@emotion/core'
import { CREATE_CONVERSATION_MUTATION } from '../mutations'

const Title = styled.h2`
  margin-bottom: 50px;
  text-align: center;
`

const FormFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${mq.sm(css`
    flex-direction: column-reverse;
    & > button:first-child {
      margin-top: 20px;
    }
  `)};
`

const GlobalError = styled.div`
  margin-bottom: 30px;
  color: ${({ theme: { colors } }) => colors.pastelRed};
`

const WriteMessageModalFormContainer = ({ formTitle, onClose, prepareDataOnSubmit, onSubmitSuccess, render }) => {
  const [submitLoading, setSubmitLoading] = useState(false)
  const [createConversation] = useMutation(CREATE_CONVERSATION_MUTATION)

  const methods = useForm({
    mode: 'onSubmit ',
    reValidateMode: 'onSubmit',
    shouldUnregister: true,
  })

  const handleSubmit = event => {
    methods.clearErrors('FORM_ERROR')

    return methods.handleSubmit(async data => {
      try {
        setSubmitLoading(true)
        await createConversation({ variables: prepareDataOnSubmit(data) })

        if (onSubmitSuccess) {
          await onSubmitSuccess()
        }

        onClose()
      } catch (error) {
        setSubmitLoading(false)
        methods.setError('FORM_ERROR', error)
        captureException(new Error(`[createConversation]: ${JSON.stringify(error)}`))
      }
    })(event)
  }

  return (
    <Modal>
      <Title>{formTitle}</Title>

      {methods.errors?.FORM_ERROR && (
        <GlobalError>
          <FormattedMessage id="errors.unexpected_error" />
        </GlobalError>
      )}

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit}>
          {render(methods)}
          <FormFooter>
            <ButtonI18n i18nKey="commons.cancel" type="button" variant="secondary" onClick={onClose} />
            <ButtonI18n
              i18nKey="conversation.submit"
              type="submit"
              variant="primary"
              loading={submitLoading}
              disabled={submitLoading}
            />
          </FormFooter>
        </form>
      </FormProvider>
    </Modal>
  )
}

WriteMessageModalFormContainer.defaultProps = {
  onSubmitSuccess: null,
}

WriteMessageModalFormContainer.propTypes = {
  formTitle: PropTypes.oneOf([PropTypes.string, PropTypes.node]).isRequired,
  onClose: PropTypes.func.isRequired,
  prepareDataOnSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
  render: PropTypes.func.isRequired,
}

export default WriteMessageModalFormContainer
