import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, Redirect, Link } from 'react-router-dom'
import I18nProvider from '@unowmooc/i18n'
import { CourseContextProvider, useCourseContext } from 'modules/course/components/CourseContext/CourseContext'
import * as HeaderSession from 'portal-components/HeaderSession'
import styled from '@emotion/styled'
import Layout from 'components/Layout'
import { FormattedMessage } from 'react-intl'
import SkipLinkAnchor from 'modules/skipLink/SkipLinkAnchor'
import CoursePage from './course'
import SectionsPage from './sections'
import FinalSkillsFormPage from './finalSkillsForm'
import InitialSkillsFormPage from './initialSkillsForm'
import Navigation from './course/components/Navigation'
import SatisfactionNonFinisherForm from './satisfactionNonFinisherForm'
import GuidePage from './guide'

const HeaderSessionLink = styled(Link)`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  display: inline-block;
  position: relative;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 25px;
  color: ${({ theme: { colors } }) => colors.unowBluegreen};
  margin-left: 8px;
  padding-left: 8px;
  white-space: nowrap;
  &::before {
    position: absolute;
    top: 2px;
    left: 0px;
    bottom: 2px;
    content: '';
    background-color: ${({ theme: { colors } }) => colors.unowBluegreen};
    width: 2px;
    border-radius: 5px;
  }
`

const CoursesPage = ({
  match: {
    url,
    params: { slugCourse },
  },
  location: { pathname },
}) => {
  const {
    sessionId,
    participantId,
    courseTitle,
    sessionUrl,
    hasReadGuide,
    hasGuide,
    satisfactionNonFinisherFormId,
    courseIdentifier,
    sessionIdentifier,
  } = useCourseContext()

  if (!hasReadGuide && hasGuide) {
    return <Redirect to={`${url}/${I18nProvider.translatePath('guide')}`} push />
  }

  return (
    <>
      <HeaderSession.Source>
        <HeaderSessionLink to={sessionUrl}>{courseTitle}</HeaderSessionLink>
      </HeaderSession.Source>
      <SkipLinkAnchor label={<FormattedMessage id="a11y.skip_to_menu" />} anchor="menu-formation" priority={10} />
      <Navigation baseUrl={url} pathname={pathname} />
      <Switch>
        <Route exact path={url} render={() => <Redirect to={`${url}/${I18nProvider.translatePath('program')}`} />} />
        <Route
          path={`${url}/${I18nProvider.translatePath('lessons')}`}
          render={({ ...routeProps }) => <SectionsPage {...routeProps} idSession={sessionId} slugCourse={slugCourse} />}
        />
        <Route
          path={`${url}/${I18nProvider.translatePath('final_skills_form')}`}
          render={({ ...routeProps }) => (
            <FinalSkillsFormPage {...routeProps} idSession={sessionId} slugCourse={slugCourse} />
          )}
        />
        <Route
          path={`${url}/${I18nProvider.translatePath('initial_skills_form')}`}
          render={({ ...routeProps }) => (
            <InitialSkillsFormPage {...routeProps} idSession={sessionId} slugCourse={slugCourse} />
          )}
        />
        {satisfactionNonFinisherFormId && (
          <Route
            path={`${url}/${I18nProvider.translatePath('satisfaction_non_finisher')}`}
            render={({ ...routeProps }) => (
              <SatisfactionNonFinisherForm
                {...routeProps}
                sessionId={sessionId}
                slugCourse={slugCourse}
                participantId={participantId}
                satisfactionNonFinisherFormId={satisfactionNonFinisherFormId}
                courseIdentifier={courseIdentifier}
                sessionIdentifier={sessionIdentifier}
              />
            )}
          />
        )}
        <Route
          path={url}
          render={({ ...routeProps }) => <CoursePage {...routeProps} idSession={sessionId} slugCourse={slugCourse} />}
        />
      </Switch>
    </>
  )
}

CoursesPage.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      idSession: PropTypes.string.isRequired,
      slugCourse: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

const CoursePageWithContext = ({
  match,
  location,
  match: {
    url,
    params: { idSession, slugCourse },
  },
}) => (
  <CourseContextProvider sessionId={idSession} slugCourse={slugCourse} routerLocation={location} key={idSession}>
    <Switch>
      <Route path={`${url}/${I18nProvider.translatePath('guide')}`} component={GuidePage} />
      <Layout path={url} component={CoursesPage} componentParameters={{ match }} hideDomainNav />
    </Switch>
  </CourseContextProvider>
)

CoursePageWithContext.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      idSession: PropTypes.string.isRequired,
      slugCourse: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({}).isRequired,
}

export default CoursePageWithContext
