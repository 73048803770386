import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import gql from 'graphql-tag'
import styled from '@emotion/styled'
import StreamlineIcon from '@unowmooc/icons'
import I18nProvider from '@unowmooc/i18n'
import { colors } from '@unowmooc/themes'

const SocialLink = styled(({ children, ...props }) => (
  <a {...props} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
))`
  margin-right: 14px;
  font-size: 24px;

  &:last-child {
    margin-right: 0;
  }
`

const TwitterIcon = styled(StreamlineIcon)`
  color: ${colors.twitter};
`

const LinkedInIcon = styled(StreamlineIcon)`
  color: ${colors.linkedin};
`

const SocialNetworks = ({ user: { twitter, linkedIn, website }, className }) => (
  <div className={className}>
    {linkedIn && (
      <SocialLink
        href={linkedIn}
        title={I18nProvider.formatMessage({ id: 'profile.user_details.social_networks.title.linked_in' })}
      >
        <LinkedInIcon icon="linkedin" />
      </SocialLink>
    )}

    {twitter && (
      <SocialLink
        href={twitter}
        title={I18nProvider.formatMessage({ id: 'profile.user_details.social_networks.title.twitter' })}
      >
        <TwitterIcon icon="twitter" />
      </SocialLink>
    )}

    {website && (
      <SocialLink
        href={website}
        title={I18nProvider.formatMessage({ id: 'profile.user_details.social_networks.title.website' })}
      >
        <StreamlineIcon icon="link" />
      </SocialLink>
    )}
  </div>
)

SocialNetworks.fragments = {
  user: gql`
    fragment _ on User {
      twitter
      linkedIn
      website
    }
  `,
}

SocialNetworks.defaultProps = {
  className: undefined,
}

SocialNetworks.propTypes = {
  user: propType(SocialNetworks.fragments.user).isRequired,
  className: PropTypes.string,
}

export default SocialNetworks
