import React, { useMemo, useState } from 'react'
import { Button } from '@unowmooc/buttons'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'
import { useSequenceContext } from 'components/VirtualClassroom/Sequence/SequenceContext'
import { Select } from '@unowmooc/inputs'
import moment from 'moment'
import DateInterval from './DateInterval'
import SlotTitle from './SlotTitle'

const Wrapper = styled.div`
  margin-bottom: 40px;
`

const StyledSelect = styled(Select)`
  margin-bottom: 16px;
`

const MultiSlot = () => {
  const { register, unregister, updatingSlot, sequence } = useSequenceContext()
  const [userSelectedValue, setUserSelectedValue] = useState()

  const options = useMemo(
    () =>
      sequence.virtualClassrooms.map(virtualClassroom => ({
        value: virtualClassroom,
        label: (
          <>
            <DateInterval startDate={virtualClassroom.startDate} />
          </>
        ),
      })),
    [sequence],
  )

  const value = useMemo(() => options.find(option => option.value.hasAuthenticatedParticipantRegistered), [options])

  const hasAuthenticatedParticipantRegistered = useMemo(
    () => sequence.virtualClassrooms.some(virtualClassroom => virtualClassroom.hasAuthenticatedParticipantRegistered),
    [sequence],
  )

  const handleRegister = event => {
    event.preventDefault()

    register(userSelectedValue.value)
  }

  const handleUnregister = event => {
    event.preventDefault()

    unregister(value.value)
    setUserSelectedValue(null)
  }

  const selectDefaultProps = {
    name: 'virtual_classroom',
    placeholder: <FormattedMessage id="virtual_classroom.slot_multi_select_placeholder" />,
    isSearchable: false,
    value,
    // On supprime les créneaux qui sont terminés de la liste de choix.
    options: hasAuthenticatedParticipantRegistered
      ? options
      : options.filter(option => moment().isBefore(option.value.endDate)),
    onChange: setUserSelectedValue,
  }

  return (
    <Wrapper>
      <SlotTitle title={sequence.title} sequenceNumber={sequence.sequenceNumber} />
      {!hasAuthenticatedParticipantRegistered && (
        <form onSubmit={handleRegister}>
          <StyledSelect {...selectDefaultProps} />
          <Button
            size="small"
            type="submit"
            loading={updatingSlot}
            disabled={!userSelectedValue || selectDefaultProps.options.length === 0}
          >
            <FormattedMessage id="virtual_classroom.slot_register_label" />
          </Button>
        </form>
      )}
      {hasAuthenticatedParticipantRegistered && (
        <form onSubmit={handleUnregister}>
          <StyledSelect {...selectDefaultProps} isDisabled />
          <Button
            size="small"
            theme="secondary"
            type="submit"
            loading={updatingSlot}
            disabled={moment().isSameOrAfter(value.value.startDate)}
          >
            <FormattedMessage id="virtual_classroom.slot_unregister_label" />
          </Button>
        </form>
      )}
    </Wrapper>
  )
}

export default MultiSlot
