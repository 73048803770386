import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import gql from 'graphql-tag'
import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl'
import StreamlineIcon from '@unowmooc/icons'
import config from 'config'

const Link = styled.a`
  flex-shrink: 0;
  font-size: 14px;
  line-height: 25px;
`

const EditIcon = styled(StreamlineIcon)`
  font-size: 16px;
  padding-left: 6px;
`

const EditLink = ({ course, className }) => (
  <Link
    target="_blank"
    rel="noopener noreferrer"
    href={`${config.env.ADMIN_URL}/catalogue/formations/${course.id}/questions-de-quiz`}
    className={className}
  >
    <FormattedMessage id="final_exam.edit" />
    <EditIcon icon="pencil" />
  </Link>
)

EditLink.fragments = {
  course: gql`
    fragment _ on Course {
      id
    }
  `,
}

EditLink.defaultProps = {
  className: undefined,
}

EditLink.propTypes = {
  course: propType(EditLink.fragments.course).isRequired,
  className: PropTypes.string,
}

export default EditLink
