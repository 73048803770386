import React from 'react'
import PropTypes from 'prop-types'
import { Flex } from 'reflexbox'
import { mq } from '@unowmooc/themes'
import { css } from '@emotion/core'
import styled from '@emotion/styled'

const Input = styled.input`
  position: absolute;
  opacity: 0;
`

const Message = styled('span', { shouldForwardProp: () => true })`
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.shipGray};
  user-select: none;
  &[data-label-direction='right'] {
    margin-left: 13px;
  }
  &[data-label-direction='left'] {
    margin-right: 13px;
  }

  ${mq.sm(css`
    font-size: 14px;
  `)};
`

const Checkmark = styled.span`
  position: relative;
  display: inline-block;
  width: 22px;
  height: 22px;
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.mischka};
  border-radius: 50%;
  :after {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: transparent;
  }
`

const Label = styled(Flex)`
  position: relative;
  min-height: 21px;
  cursor: pointer;
  user-select: none;
  display: flex;
  &[data-label-direction='top'],
  &[data-label-direction='bottom'] {
    flex-direction: column;
    align-items: center;
  }
  &[data-label-direction='right'],
  &[data-label-direction='left'] {
    flex-direction: row;
    align-items: flex-start;
  }
  & input:checked ~ span ${Checkmark} {
    color: ${({ theme }) => theme.colors.unowGreen};
    border-color: ${({ theme }) => theme.colors.unowGreen};
    &:after {
      background-color: ${({ theme }) => theme.colors.unowGreen};
    }
  }
  & input:focus ~ span ${Checkmark} {
    box-shadow: 0 0 4px ${({ theme }) => theme.colors.unowBluegreen};
    border-color: ${({ theme }) => theme.colors.unowGreen};
  }
  &:hover > span ${Checkmark} {
    border-color: ${({ theme }) => theme.colors.unowGreen};
  }
  & input:disabled ~ ${Message}, & input:disabled ~ span ${Checkmark} {
    color: ${({ theme }) => theme.colors.mischka};
    border-color: ${({ theme }) => theme.colors.mischka};
  }
  & input:disabled {
    cursor: not-allowed;
  }
  & input:disabled ~ span ${Checkmark}, & input:disabled ~ ${Message} {
    cursor: not-allowed;
  }
`

const Radio = React.forwardRef(({ label, labelDirection, className, ...props }, ref) => (
  <Label as="label" data-label-direction={labelDirection} className={className}>
    <Input {...props} ref={ref} type="radio" />
    {label && ['left', 'top'].includes(labelDirection) && (
      <Message className="radio-label" data-label-direction={labelDirection}>
        {label}
      </Message>
    )}
    <span>
      <Checkmark />
    </span>
    {label && ['right', 'bottom'].includes(labelDirection) && (
      <Message className="radio-label" data-label-direction={labelDirection}>
        {label}
      </Message>
    )}
  </Label>
))

Radio.type = 'radio'

Radio.defaultProps = {
  label: '',
  labelDirection: 'right',
  className: null,
}

Radio.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  labelDirection: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  className: PropTypes.string,
}

export default Radio
