import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import I18nProvider from '@unowmooc/i18n'
import FileStackUploaderv2 from 'components/FileStackUploader/indexv2'
import UIKitImagePicker from '../InternalUiKit/ImagePicker'

const ImagePickerv2 = ({ rounded, value, onChange, ...props }) => (
  <FileStackUploaderv2
    // on reproduit le même comportement qu'actuellement en prod et n'affiche pas la valeur courante
    // value={value}
    // cela pourra être décommenté quand on gèrera la suppression d'image de profil, ça sera améliorera l'UX
    {...props}
    onChange={onChange}
    render={({ onChange: uploadFile, ...pickerProps }) => (
      <UIKitImagePicker
        {...pickerProps}
        addLabel={<FormattedMessage id="image_picker.add_label" />}
        dropLabel={<FormattedMessage id="image_picker.drop_label" />}
        allowedFormatsLabel={I18nProvider.formatMessage({ id: 'image_picker.allowed_formats_label' })}
        recommendedSizeLabel={I18nProvider.formatMessage({ id: 'image_picker.recommended_size_label' })}
        uploadProgressLabel={<FormattedMessage id="file_preview.upload_progress_label" />}
        downloadLabel={<FormattedMessage id="file_preview.download_label" />}
        deleteLabel={<FormattedMessage id="file_preview.delete_label" />}
        onPick={newFile => uploadFile([newFile])}
        // on ne met pas le delete pour le moment, il faudrait changer d'autres mécanismes, et il n'était pas géré dans l'autre version
        disableDelete
        onDelete={() => onChange()}
        rounded={rounded}
      />
    )}
  />
)

ImagePickerv2.defaultProps = {
  rounded: false,
  value: undefined,
}

ImagePickerv2.propTypes = {
  onChange: PropTypes.func.isRequired,
  rounded: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
}

export default ImagePickerv2
