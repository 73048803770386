import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import { colors } from '@unowmooc/themes'

const variants = {
  initial: {
    scale: 1,
    rotate: '0deg',
  },
  animate: {
    scale: 1.1,
    rotate: '360deg',
    transition: {
      scale: { ease: 'linear', duration: 2, yoyo: true },
      rotate: { ease: 'linear', duration: 4, loop: Infinity },
    },
  },
}

const SvgWrapper = styled(motion.div, { shouldForwardProp: prop => prop !== 'size' })`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
`

const Star = ({ size, color, className }) => (
  <div className={className}>
    <SvgWrapper initial="initial" animate="animate" variants={variants} size={size}>
      <svg width={size} height={size}>
        <path
          d="M9.334 6.81c-.417-.103-.375-1.708.093-3.584.468-1.876 1.184-3.313 1.6-3.21.417.105.375 1.71-.093 3.586-.468 1.876-1.184 3.313-1.6 3.209zm1.042.886c-.181-.39 1.093-1.367 2.845-2.184 1.752-.817 3.32-1.164 3.501-.776.181.39-1.092 1.367-2.845 2.184-1.752.817-3.32 1.164-3.5.776zm-.036 1.318c.195-.383 1.75.02 3.472.897 1.723.878 2.962 1.9 2.767 2.281-.194.383-1.749-.02-3.472-.897-1.722-.878-2.961-1.9-2.767-2.281zm-1.088.833c.42-.09 1.086 1.372 1.488 3.263.402 1.891.387 3.497-.032 3.586-.42.089-1.086-1.372-1.488-3.263-.402-1.892-.387-3.497.032-3.586zM7.98 9.53c.329.275-.412 1.7-1.655 3.18-1.243 1.482-2.517 2.46-2.845 2.184-.329-.276.412-1.7 1.655-3.182 1.243-1.48 2.517-2.458 2.845-2.182zm-.57-1.247c-.007.429-1.58.75-3.513.715C1.963 8.965.402 8.59.41 8.161c.007-.429 1.58-.75 3.514-.715 1.933.033 3.494.408 3.487.837zm.588-1.18c-.343.258-1.564-.785-2.727-2.33C4.107 3.23 3.44 1.77 3.784 1.512c.342-.259 1.563.784 2.727 2.328 1.164 1.544 1.83 3.005 1.487 3.264z"
          fill={color}
          fillRule="evenodd"
        />
      </svg>
    </SvgWrapper>
  </div>
)

Star.defaultProps = {
  size: 17,
  color: colors.unowGreen,
  className: undefined,
}

Star.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
}

export default Star
