import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { FormattedMessage } from 'react-intl'
import { Clickable } from '@unowmooc/react-ui-kit'
import Tag from '@unowmooc/tags'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from 'styles-resources'
import { useCatalogContext } from './CatalogContext'

const Wrapper = styled.div`
  text-align: left;

  ${mq.sm(css`
    text-align: center;
  `)};
`

const StyledClickable = styled(Clickable)`
  margin: 0 16px 16px 0;
`

const DomainFilter = ({ className }) => {
  const { themes, selectedThemeIds, resetTheme, toggleTheme } = useCatalogContext()

  return (
    <Wrapper className={className}>
      <StyledClickable onClick={resetTheme}>
        <Tag theme={selectedThemeIds.length === 0 ? 'lightGreen' : 'grey'}>
          <FormattedMessage id="catalog.all_domains" />
        </Tag>
      </StyledClickable>

      {_.orderBy(themes, ['label']).map(theme => (
        <StyledClickable
          onClick={() => {
            toggleTheme(theme)
          }}
          key={theme.value}
        >
          <Tag theme={selectedThemeIds.includes(theme.id) ? 'lightGreen' : 'grey'}>
            {theme.label} ({theme.count})
          </Tag>
        </StyledClickable>
      ))}
    </Wrapper>
  )
}

DomainFilter.defaultProps = {
  className: undefined,
}

DomainFilter.propTypes = {
  className: PropTypes.string,
}

export default DomainFilter
