import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import moment from 'moment'
import gql from 'graphql-tag/lib/graphql-tag.umd'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import { Alert } from '@unowmooc/react-ui-kit'
import { toInlineFragment } from 'fraql'
import { colors } from 'styles-resources'
import { useCourseContext } from 'modules/course/components/CourseContext/CourseContext'
import { useTracking } from 'modules/tracking'
import SequenceTitle from './SequenceTitle'
import { STATUS_DONE, STATUS_LIVE, STATUS_NEXT, STATUS_PLANNED } from './constantes'
import { useSequenceContext } from './SequenceContext'
import SequenceSlot from './SequenceSlot'
import ProgressLine from './ProgressLine'

const meetingAssetSvg = require('./assets/meeting.svg')

const Container = styled.div`
  display: flex;
  flex-direction: row;
`

const Wrapper = styled.div`
  padding-bottom: 50px;
  font-size: 16px;
  line-height: 25px;
  color: ${({ theme }) => theme.colors.woodsmoke};
  width: 100%;
`

const SequenceTitleStyled = styled(SequenceTitle)`
  margin-bottom: 5px;
`

const OnboardingParagraph = styled.p`
  margin-top: 10px;
  font-style: italic;
`

const SlotSelectorLabel = styled.p`
  margin-top: 10px;
`

const SlotHelper = styled.p`
  margin-top: 10px;
  color: ${colors.manatee};
`

const StyledAlert = styled(Alert)`
  display: flex;
  align-items: center;
  margin-top: 20px;
  padding: 16px 20px;

  p {
    color: ${colors.black};
    margin-left: 14px;
  }
`

const Info = styled.div`
  margin-bottom: 8px;
  strong {
    font-weight: 500;
  }
`

const InfoGroup = styled.div`
  & > ${Info}:first-child {
    margin-top: 16px;
  }
  & > ${Info}:last-child {
    margin-bottom: 16px;
  }
  & > ${Info} strong {
    display: ${({ addInfoLineBreak }) => (addInfoLineBreak ? 'block' : 'inline')};
  }
`

const getSequenceStatus = ({ sequence, isNextSequence }) => {
  if (sequence.virtualClassrooms.every(({ endDate }) => moment().isSameOrAfter(endDate))) {
    return STATUS_DONE
  }

  if (
    sequence.virtualClassrooms.some(
      ({ startDate, endDate }) => moment(startDate).subtract('15', 'minutes').isBefore() && moment(endDate).isAfter(),
    )
  ) {
    return STATUS_LIVE
  }

  if (isNextSequence) {
    return STATUS_NEXT
  }

  return STATUS_PLANNED
}

const Sequence = ({
  isLastSequence,
  isNextSequence,
  showProgressLine,
  showSlotHelperBlock,
  showWhyInfo,
  showRequirementsInfo,
  addInfoLineBreak,
  render,
}) => {
  const { courseIdentifier, sessionIdentifier, hasRSVP } = useCourseContext()
  const { sequence, hasPlannedRegisteredVirtualClassroom, hasAtLeastOneWebexLink } = useSequenceContext()
  const { sendEvent } = useTracking()
  const sequenceStatus = useMemo(() => getSequenceStatus({ sequence, isNextSequence }), [sequence, isNextSequence])

  const handleClickHelpLink = () => {
    sendEvent(
      'virtual_classroom.click_on_webex_help_link',
      JSON.stringify({
        courseIdentifier,
        sessionIdentifier,
        virtualClassroomSequence: sequence.sequenceNumber,
      }),
    )
  }

  return (
    <Container>
      {showProgressLine && (
        <ProgressLine
          isLast={isLastSequence}
          isNext={isNextSequence}
          number={sequence.sequenceNumber}
          isOnboarding={sequence.isOnboarding}
          done={sequenceStatus === STATUS_DONE}
        />
      )}
      <Wrapper>
        <SequenceTitleStyled
          isOnboardingSequence={sequence.isOnboarding}
          sequenceStatus={sequenceStatus}
          sequenceNumber={sequence.sequenceNumber}
          sequenceTitle={sequence.title}
        />
        <InfoGroup addInfoLineBreak={addInfoLineBreak}>
          {sequence.goal && (
            <Info>
              <strong>
                <FormattedMessage id="virtual_classroom.goal_title" />
                &nbsp;:{' '}
              </strong>
              <div dangerouslySetInnerHTML={{ __html: sequence.goal }} />
            </Info>
          )}
          {showWhyInfo && sequence.why && (
            <Info>
              <strong>
                <FormattedMessage id="virtual_classroom.why_title" />
                &nbsp;:{' '}
              </strong>
              <div dangerouslySetInnerHTML={{ __html: sequence.why }} />
            </Info>
          )}
          {showRequirementsInfo && sequence.requirements && (
            <Info>
              <strong>
                <FormattedMessage id="virtual_classroom.requirements_title" />
                &nbsp;:{' '}
              </strong>
              <div dangerouslySetInnerHTML={{ __html: sequence.requirements }} />
            </Info>
          )}
        </InfoGroup>
        {sequence.isOnboarding && (
          <OnboardingParagraph>
            <FormattedMessage id="virtual_classroom.presentation_all" />
          </OnboardingParagraph>
        )}
        {hasRSVP && sequenceStatus !== STATUS_DONE && (
          <SlotSelectorLabel>
            <FormattedHTMLMessage
              id="virtual_classroom.slot_selector_label"
              values={{ virtualClassroomCounter: sequence.virtualClassrooms.length }}
            />
          </SlotSelectorLabel>
        )}
        {sequenceStatus === STATUS_DONE &&
          sequence.virtualClassrooms.some(virtualClassroom => virtualClassroom.isRecordingAvailable) && (
            <SlotSelectorLabel>
              <FormattedHTMLMessage
                id="virtual_classroom.slot_done"
                // Si RSVP est désactivé, la valeur sera forcée à 1 car une seule CV sera visible.
                values={{ virtualClassroomCounter: hasRSVP ? 1 : sequence.virtualClassrooms.length }}
              />
            </SlotSelectorLabel>
          )}
        {render(sequence)}
        {hasPlannedRegisteredVirtualClassroom && (
          <SlotHelper>
            <FormattedHTMLMessage id="virtual_classroom.info" />
          </SlotHelper>
        )}
        {showSlotHelperBlock && hasAtLeastOneWebexLink && (
          <StyledAlert theme="success" mt="20px">
            <img
              alt={<FormattedHTMLMessage id="virtual_classroom.webex_image_label" />}
              width={41}
              height={37}
              src={meetingAssetSvg}
            />
            <p>
              <FormattedHTMLMessage id="virtual_classroom.webex_help_label" />
              <br />
              <a href="https://aide.unow.fr/fr/?q=webex" target="webex_help_link" onClick={handleClickHelpLink}>
                <FormattedHTMLMessage id="virtual_classroom.webex_help_link_label" />
              </a>
            </p>
          </StyledAlert>
        )}
      </Wrapper>
    </Container>
  )
}

Sequence.fragments = {
  sequence: gql`
    fragment Sequence on VirtualClassroomSequence {
      sequenceNumber
      isOnboarding
      title
      goal
      why
      requirements
      virtualClassrooms {
        id
        startDate
        endDate
        isRecordingAvailable
        ${toInlineFragment(SequenceSlot.fragments.virtualClassroom)}
      }
    }
  `,
}

Sequence.defaultProps = {
  isLastSequence: false,
  isNextSequence: false,
  showProgressLine: true,
  showSlotHelperBlock: false,
  showWhyInfo: true,
  showRequirementsInfo: true,
  addInfoLineBreak: true,
}

Sequence.propTypes = {
  isLastSequence: PropTypes.bool,
  isNextSequence: PropTypes.bool,
  showProgressLine: PropTypes.bool,
  showSlotHelperBlock: PropTypes.bool,
  showWhyInfo: PropTypes.bool,
  showRequirementsInfo: PropTypes.bool,
  addInfoLineBreak: PropTypes.bool,
  render: PropTypes.func.isRequired,
}

export default Sequence
