import React from 'react'
import PropTypes from 'prop-types'
import { colors } from '@unowmooc/themes'
import I18nProvider from '@unowmooc/i18n'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import LinkButtonIcon from 'components/LinkButtonIcon'
import { getPlatformLinkLabel } from 'modules/session/utils'
import { useCourseContext } from 'modules/course/components/CourseContext/CourseContext'
import { getCertificateDownloadUrl } from 'business/certificate'
import { advancementStatus as participantAdvancementStatus } from 'business/participant'

const PlatformLink = ({ className }) => {
  const {
    courseIdentifier,
    sessionIdentifier,
    sessionIsStarted,
    participationStarted,
    advancementStatus,
    certificateNumber,
    sessionUrl,
  } = useCourseContext()
  const participantIsCertified = advancementStatus === participantAdvancementStatus.CERTIFIED

  if (participantIsCertified) {
    return (
      <LinkButtonIcon
        icon="download"
        iconColor={colors.unowOrange}
        theme="secondary"
        href={getCertificateDownloadUrl(certificateNumber)}
        target="_blank"
        rel="noopener noreferrer"
        className={className}
        data-tracking-id="session.download_certificate"
        data-tracking-values={JSON.stringify({
          courseIdentifier,
          sessionIdentifier,
        })}
      >
        <FormattedMessage id="courses.course.download_certificate" />
      </LinkButtonIcon>
    )
  }

  return (
    <LinkButtonIcon
      to={`${sessionUrl}${I18nProvider.getLinkRoute('/lessons')}`}
      tag={Link}
      theme="highlight"
      icon="right"
      className={className}
      data-tracking-id="session.click_on_start_course"
      data-tracking-values={JSON.stringify({
        courseIdentifier,
        sessionIdentifier,
      })}
    >
      <FormattedMessage id={getPlatformLinkLabel({ sessionIsStarted, participationStarted })} />
    </LinkButtonIcon>
  )
}

PlatformLink.defaultProps = {
  className: null,
}

PlatformLink.propTypes = {
  className: PropTypes.string,
}

export default PlatformLink
