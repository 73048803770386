import React, { Component } from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import { Input, Fieldset } from '@unowmooc/react-ui-kit'
import FieldsetIntl from 'components/FieldsetIntl'
import FormContainer from 'components/FormContainer'
import Field from 'components/FieldIntl'
import { sameAs as sameAsValidator } from 'business/validators'

const fragment = gql`
  fragment FormDataFragment on Me {
    id
    __email: email
    plainPassword
  }
`

const StyledFirstFieldset = styled(Fieldset)`
  margin-top: 0;

  ${mq.sm(css`
    margin-top: 8px;
  `)};
`

const PasswordTitle = styled.h4`
  margin-top: 50px;
  margin-bottom: 16px;
`

class Form extends Component {
  constructor(props) {
    super(props)

    this.handleSubmitSucceeded = this.handleSubmitSucceeded.bind(this)
  }

  get initialValues() {
    return {
      plainPassword: null,
      __plainPassword: null,
    }
  }

  handleSubmitSucceeded(response, form) {
    // reset as initial values because API doesn't return the sent value for plainPassword field, it returns null
    form.change('plainPassword', null)
    form.change('__plainPassword', null)
  }

  render() {
    const { canUpdatePassword } = this.props

    const sameAsValidate = sameAsValidator('plainPassword', 'validators.repeat_password_invalid')

    return (
      <FormContainer
        {...this.props}
        fragment={fragment}
        typeName="Me"
        mutationArgumentName="user"
        queryWithoutId
        initialValues={this.initialValues}
        onSubmitSucceeded={this.handleSubmitSucceeded}
      >
        <StyledFirstFieldset standalone>
          <Field required name="__email" label="settings.account.form.email">
            <Input disabled />
          </Field>
          {canUpdatePassword && (
            <>
              <PasswordTitle>
                <FormattedMessage id="settings.account.form.change_password" />
              </PasswordTitle>
              <Field name="plainPassword" label="settings.account.form.new_password">
                <Input type="password" />
              </Field>
              <Field name="__plainPassword" label="settings.account.form.new_password_repeat" validate={sameAsValidate}>
                <Input type="password" />
              </Field>
            </>
          )}
        </StyledFirstFieldset>
        <FieldsetIntl legend="settings.account.form.delete_account" standalone>
          <p>
            <FormattedHTMLMessage id="settings.account.form.delete_account_helper" />
          </p>
        </FieldsetIntl>
      </FormContainer>
    )
  }
}

Form.defaultProps = {
  canUpdatePassword: true,
}

Form.propTypes = {
  canUpdatePassword: PropTypes.bool,
}

export default Form
