import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { IntlMessage } from '@unowmooc/text'
import FormattedDate from 'components/FormattedDate'

const DateCommentExpert = ({ comment }) => (
  <IntlMessage
    i18nKey="comment.date_expert"
    values={{
      date: <FormattedDate value={comment.createdAt} year="2-digit" month="2-digit" day="2-digit" />,
    }}
  />
)

DateCommentExpert.fragment = gql`
  fragment DateCommentExpertFragment on Comment {
    id
    createdAt
  }
`

DateCommentExpert.propTypes = {
  comment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
  }).isRequired,
}

export default DateCommentExpert
