import React from 'react'
import PropTypes from 'prop-types'
import { Field } from '@unowmooc/form'
import Textarea from 'components/Textarea'
import { InputText } from '@unowmooc/inputs'
import { renderLabel } from 'components/Select/Session'
import { useUserContext } from 'store/UserProvider'
import { FormattedMessage } from 'react-intl'
import gql from 'graphql-tag/lib/graphql-tag.umd'
import WriteMessageModalFormContainer from './WriteMessageModalFormContainer'
import SelectAutoFillField from '../../../components/SelectAutoFillField'

const WRITE_MESSAGE_SESSION_QUERY = gql`
  query writeMessageExpert_SessionFieldQuery($filters: Json) {
    select: sessions(filters: $filters) {
      items {
        id
        identifier
        startAt
        nbParticipants
      }
    }
  }
`

/**
 * Modale affichée à un expert pour écrire à un particpant.
 *
 * Le participant doit être défini en entrée
 */
const WriteMessageExpertProfileModalFormRenderer = ({ participantUserId }) => {
  const {
    user: { id: authenticatedUserId },
  } = useUserContext()

  return (
    <>
      <SelectAutoFillField
        query={WRITE_MESSAGE_SESSION_QUERY}
        filters={{
          animations_user_id: authenticatedUserId,
          animations_isMain: true,
          participants_user_id: participantUserId,
          conversationsEnabled: true,
          canceledAt: null,
        }}
        getOptions={queryResult => queryResult.data.select.items?.filter(option => option.nbParticipants > 0)}
        required={<FormattedMessage id="errors.isRequired" />}
        name="session"
        label={<FormattedMessage id="conversation.form.session" />}
        selectProps={{
          placeholder: <FormattedMessage id="commons.search" />,
          getOptionLabel: renderLabel,
          noOptionsMessage: () => <FormattedMessage id="select.default_no_result" />,
        }}
      />
      <Field
        required={<FormattedMessage id="errors.isRequired" />}
        name="subject"
        label={<FormattedMessage id="conversation.form.subject" />}
      >
        <InputText />
      </Field>
      <Field
        required={<FormattedMessage id="errors.isRequired" />}
        name="content"
        label={<FormattedMessage id="conversation.form.message" />}
      >
        <Textarea minRows={5} maxRows={10} />
      </Field>
    </>
  )
}

WriteMessageExpertProfileModalFormRenderer.propTypes = {
  participantUserId: PropTypes.string.isRequired,
}

const WriteMessageExpertModalForm = ({ onClose, participantUserId, participantUserFullName, onSubmitSuccess }) => {
  const prepareDataOnSubmit = data => {
    const sessionId = data.session.id
    const memberUserIds = [participantUserId]
    const { subject } = data
    const { content } = data

    return { sessionId, memberUserIds, subject, content }
  }

  return (
    <WriteMessageModalFormContainer
      formTitle={
        <FormattedMessage
          id="conversation.write_message_label.profile"
          values={{ fullName: participantUserFullName }}
        />
      }
      onClose={onClose}
      prepareDataOnSubmit={prepareDataOnSubmit}
      onSubmitSuccess={onSubmitSuccess}
      render={() => <WriteMessageExpertProfileModalFormRenderer participantUserId={participantUserId} />}
    />
  )
}

WriteMessageExpertModalForm.defaultProps = {
  onSubmitSuccess: () => {},
}

WriteMessageExpertModalForm.propTypes = {
  participantUserId: PropTypes.string.isRequired,
  participantUserFullName: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
}

export default WriteMessageExpertModalForm
