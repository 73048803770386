import React from 'react'
import styled from '@emotion/styled'
import { colors } from 'styles-resources'
import { FormattedMessage } from 'react-intl'
import TagDefault from './TagDefault'

const TagInitial = styled(({ className }) => (
  <TagDefault small className={className}>
    <span>
      <FormattedMessage id="courses.course.skills.tag_label.initial" />
    </span>
  </TagDefault>
))`
  color: ${colors.midGray};
  border: 1px solid ${colors.midGray};
`

export default TagInitial
