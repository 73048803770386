import React from 'react'
import { ClassNames, Global, css } from '@emotion/core'
import { ModalContainer as RModalContainer } from 'react-router-modal'

export default () => (
  <>
    <Global
      styles={css`
        body.react-router-modal__modal-open {
          overflow: hidden;
        }
      `}
    />
    <ClassNames>
      {({ css: localCss }) => (
        <RModalContainer
          backdropClassName={localCss`
          position: fixed;
          top: 0;
          left: 0;
          z-index: 1000;
          width: 100%;
          height: 100%;
        `}
          modalClassName={localCss`
          position: fixed;
          z-index: 1001;
          overflow-y: auto;
          width: 100%;
          height: 100%;
          opacity: 0;
          transform: scale(0.9);
          transition: opacity 300ms, transform 300ms;
        `}
          modalInClassName={localCss`
          opacity: 1;
          transform: scale(1);
        `}
          outDelay={300} // for out transition
        />
      )}
    </ClassNames>
  </>
)
