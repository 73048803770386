import styled from '@emotion/styled'

const NavItem = styled.div`
  display: flex;
  align-items: center;
  color: ${({ disabled, theme: { colors } }) => (disabled ? colors.bombay : colors.unowGreen)};
  background-color: ${({ theme: { colors } }) => colors.white};
  transition: ${({ theme: { transitions } }) => transitions.backgroundColor};

  &:hover {
    background-color: ${({ disabled, theme: { colors } }) => (disabled ? colors.white : colors.whiteLilac)};
  }
`

export default NavItem
