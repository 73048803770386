import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl'
import { css } from '@emotion/core'
import { mq } from 'styles-resources'
import config from 'config'
import I18nProvider from '@unowmooc/i18n'
import LinkButtonIcon from 'components/LinkButtonIcon'
import ButtonShareLinkedin from 'modules/exam/components/ButtonShareLinkedin'
import { ButtonDropdown, DropdownMenuLink } from '@unowmooc/react-ui-kit'

const LinksWrapper = styled.div`
  display: flex;
  justify-content: flex-start;

  &:first-child {
    margin-right: 30px;

    ${mq.xs(css`
      margin-right: 0;
    `)}
  }

  ${mq.xs(css`
    margin-top: 20px;
    flex-direction: column;

    button {
      width: 100%;
    }
  `)}
`

const Separtor = styled.div`
  min-width: 30px;
  min-height: 20px;
`

const StyledButtonDropdown = styled(ButtonDropdown, { shouldForwardProp: () => true })`
  button {
    height: 49px;
  }
`

const ShareResult = ({
  certificateNumber,
  certificateValidatedAt,
  professionalCertificationNumber,
  course,
  sessionIdentifier,
}) => (
  <>
    <LinksWrapper>
      {certificateNumber && !professionalCertificationNumber && (
        <LinkButtonIcon
          href={`${config.env.GRAPHQL_API_URL}/certificate/${certificateNumber}`}
          target="_blank"
          theme="highlight"
          icon="download"
          data-tracking-id="session.download_certificate"
          data-tracking-values={JSON.stringify({
            courseIdentifier: course.identifier,
            sessionIdentifier,
          })}
        >
          <FormattedMessage id="final_exam.results.success.download_certificate_link" />
        </LinkButtonIcon>
      )}
      {certificateNumber && professionalCertificationNumber && (
        <StyledButtonDropdown
          label={I18nProvider.formatMessage({ id: 'final_exam.results.success.download_certificates_links' })}
          theme="highlight"
          icon="download"
        >
          <DropdownMenuLink
            onClick={() => window.open(`${config.env.GRAPHQL_API_URL}/certificate/${certificateNumber}`, '_blank')}
          >
            <FormattedMessage id="final_exam.results.success.download_certificate_link" />
          </DropdownMenuLink>
          <DropdownMenuLink
            onClick={() =>
              window.open(
                `${config.env.GRAPHQL_API_URL}/documents/professional-certification/${professionalCertificationNumber}`,
                '_blank',
              )
            }
          >
            <FormattedMessage id="final_exam.results.success.download_professional_certificate_link" />
          </DropdownMenuLink>
        </StyledButtonDropdown>
      )}
      <Separtor />
      <ButtonShareLinkedin
        i18nKey="final_exam.results.success.share_on_linkedin"
        certificateNumber={certificateNumber}
        certificateValidatedAt={certificateValidatedAt}
        sessionIdentifier={sessionIdentifier}
        course={course}
      />
    </LinksWrapper>
  </>
)

ShareResult.propTypes = {
  certificateNumber: PropTypes.number.isRequired,
  certificateValidatedAt: PropTypes.string.isRequired,
  professionalCertificationNumber: PropTypes.string.isRequired,
  course: PropTypes.shape().isRequired,
  sessionIdentifier: PropTypes.string.isRequired,
}

export default ShareResult
