import keyMirror from 'keymirror'

/**
 *   Possible advancement status for a participation
 */
export const ADVANCEMENT_STATUS = keyMirror({
  // participant is up to date in module advancement
  up_to_date: null,
  // Session not started and participant without module
  not_started: null,
  // Participant is late in module advancement
  late: null,
  // Participant finish all module but not certified
  finisher_not_certified: null,
  // Participant is certified
  certified: null,
  // Participant is canceled
  canceled: null,
})
