import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { FormattedMessage } from 'react-intl'
import { Box } from 'reflexbox'
import UTag from '@unowmooc/tags'

import { getTheme } from 'modules/comments/comment.utils'
import Tooltip from '@unowmooc/tooltip'

const Tag = ({ comment }) => {
  if (comment.isAnonymous && comment.user) {
    return (
      <Box>
        <Tooltip content={<FormattedMessage id="comment.anonymous_tag_tooltip" />}>
          <UTag theme="red" small>
            <FormattedMessage id="comment.anonymous_tag_label" />
          </UTag>
        </Tooltip>
      </Box>
    )
  }

  if (comment.authorRole === 'participant' || !comment.user) {
    return null
  }

  const theme = getTheme(comment.authorRole)

  return (
    <Box>
      <UTag theme={theme === 'orange' ? UTag.themes.highlight : theme} small>
        <FormattedMessage id={`comment.role_labels.${comment.authorRole}`} values={{ gender: comment.user.gender }} />
      </UTag>
    </Box>
  )
}

Tag.fragment = gql`
  fragment CommentTagFragment on Comment {
    id
    isAnonymous
    authorRole
    user {
      id
      gender
    }
  }
`

Tag.propTypes = {
  comment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    authorRole: PropTypes.string.isRequired,
    isAnonymous: PropTypes.bool.isRequired,
    user: {
      gender: PropTypes.string,
    },
  }).isRequired,
}

export default Tag
