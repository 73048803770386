import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import gql from 'graphql-tag'
import { toInlineFragment } from 'fraql'
import { Mutation } from 'react-apollo'
import styled from '@emotion/styled'
import { withUser } from 'store/UserProvider'
import { colors } from 'styles-resources'
import FilePreview from 'components/FilePreview'
import { isAdmin, isUserAnimatorOfTheSession } from 'modules/user/user.utils'
import Form from '../../Form'

const StyledForm = styled(Form)`
  background-color: ${colors.white};
  border-radius: 4px;
  border: 1px solid ${colors.mischka};
`

const EditForm = ({ user, comment, placeholder, embed, onSubmitSuccess }) => (
  <Mutation
    mutation={gql`
      mutation updateComment2($comment: CommentInputForUpdate!) {
        updateComment2(comment: $comment) {
          id
          text
          isAnonymous
          file {
            id
            ${toInlineFragment(FilePreview.fragments.file)}
          }
        }
      }
    `}
  >
    {updateComment => (
      <StyledForm
        user={user}
        session={comment.session}
        initialValues={{ ...comment }}
        onSubmit={params =>
          updateComment({
            variables: { comment: { id: comment.id, text: params.text, isAnonymous: params.isAnonymous } },
          }).then(onSubmitSuccess)
        }
        showIsAnonymous={!isUserAnimatorOfTheSession(user, comment.session) && !isAdmin(user)}
        embed={embed}
        placeholder={placeholder}
        canEditFile={false}
      />
    )}
  </Mutation>
)

EditForm.fragments = {
  comment: gql`
    fragment _ on Comment {
      id
      text
      isAnonymous
      file {
        id
        ${toInlineFragment(FilePreview.fragments.file)}
      }
      session {
        id
      }
    }
  `,
}

EditForm.defaultProps = {
  placeholder: undefined,
  embed: false,
}

EditForm.propTypes = {
  user: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
  comment: propType(EditForm.fragments.comment).isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  embed: PropTypes.bool,
}

export default withUser(EditForm)
