import React from 'react'
import PropTypes from 'prop-types'
import { propType, filter } from 'graphql-anywhere'
import gql from 'graphql-tag'
import { toInlineFragment } from 'fraql'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Visible } from 'react-grid-system'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import I18nProvider from '@unowmooc/i18n'
import { mq, colors } from 'styles-resources'
import ShadowBox from '@unowmooc/shadow-box'
import ResponsiveAvatar from 'components/ResponsiveAvatar'

const Wrapper = styled(ShadowBox)`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  transition: box-shadow 0.2s ease, transform 0.2s ease;
`

const ProfileLink = styled(Link)`
  color: inherit;

  &:hover {
    color: inherit;
    text-decoration: none;

    ${Wrapper} {
      box-shadow: 0 13px 24px 0 ${colors.mischka};
      transform: translateY(-2px);
    }
  }
`

const Content = styled.div`
  height: 100%;
  padding: 30px 30px 40px 30px;
  text-align: center;

  ${mq.xs(css`
    display: flex;
    align-items: center;
    padding: 20px;
    text-align: left;
  `)}
`

const StyledAvatar = styled(ResponsiveAvatar)`
  margin-bottom: 16px;

  ${mq.xs(css`
    margin-bottom: 0;
    margin-right: 10px;
  `)}
`

const Presentation = styled.div``

const Fullname = styled.p`
  margin-bottom: 5px;
  color: ${colors.woodsmoke};

  ${mq.xs(css`
    margin-bottom: 0;
  `)}
`

const Fonction = styled.p`
  font-size: 14px;
  color: ${colors.migGray};

  ${mq.xs(css`
    font-size: 12px;
  `)}
`

const Card = ({ participant: { user }, className }) => {
  const content = (
    <Wrapper className={className} data-cy="trombinoscope-card">
      <Content>
        <Visible xs>
          <StyledAvatar size={45} user={filter(ResponsiveAvatar.fragments.user, user)} />
        </Visible>
        <Visible sm md lg xl>
          <StyledAvatar size={100} user={filter(ResponsiveAvatar.fragments.user, user)} />
        </Visible>
        <Presentation>
          <Fullname>{user.fullName || <FormattedMessage id="commons.unspecified" />}</Fullname>
          {user.fonction && <Fonction>{user.fonction}</Fonction>}
        </Presentation>
      </Content>
    </Wrapper>
  )

  return user.slug ? (
    <ProfileLink
      to={I18nProvider.getLinkRoute('/profile/{slug}/{id}', { slug: user.slug, id: user.id })}
      data-tracking-id="session.click_on_profile_page"
      data-tracking-values={JSON.stringify({
        from: 'Trombinoscope',
      })}
    >
      {content}
    </ProfileLink>
  ) : (
    content
  )
}

Card.fragments = {
  participant: gql`
    fragment _ on Participant {
      id
      status
      user {
        id
        slug
        fullName
        fonction
        ${toInlineFragment(ResponsiveAvatar.fragments.user)}
      }
    }
  `,
}

Card.defaultProps = {
  className: undefined,
}

Card.propTypes = {
  participant: propType(Card.fragments.participant).isRequired,
  className: PropTypes.string,
}

export default Card
