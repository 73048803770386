import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'
import { withQueryMetadata } from 'store/QueryMetadataProvider'

const Wrapper = styled.div`
  min-width: 76px;
  white-space: nowrap;
`

class Counter extends Component {
  get hasTotal() {
    const { metadata } = this.props

    const total = _.get(metadata, 'total')

    return !_.isUndefined(total)
  }

  get total() {
    const { hasTotal } = this

    if (!hasTotal) {
      return null
    }

    const { metadata } = this.props

    return metadata.total
  }

  render() {
    const { hasTotal, total } = this

    return (
      <Wrapper>
        {hasTotal ? (
          <FormattedMessage id="datagrid.toolbar.counter" values={{ total }} />
        ) : (
          <FormattedMessage id="datagrid.toolbar.counter_undefined" />
        )}
      </Wrapper>
    )
  }
}

Counter.defaultProps = {
  metadata: null,
}

Counter.propTypes = {
  metadata: PropTypes.shape(),
}

export default withQueryMetadata(Counter)
