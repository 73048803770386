import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import svgBadge from './assets/badge.svg'

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 38px;
  height: 52px;
`

const Badge = styled.img`
  width: 100%;
  height: 100%;
`

const NumberBadge = styled.div`
  position: absolute;
  top: 5px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 21px;
  font-weight: 500;
  text-align: center;
  color: ${({ theme: { colors } }) => colors.unowGreen};
`

const SmallBadge = ({ number, className }) => (
  <Wrapper className={className}>
    <Badge src={svgBadge} />
    <NumberBadge>{number}</NumberBadge>
  </Wrapper>
)

SmallBadge.defaultProps = {
  className: undefined,
}

SmallBadge.propTypes = {
  number: PropTypes.number.isRequired,
  className: PropTypes.string,
}

export default SmallBadge
