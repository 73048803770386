import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl'
import { Fieldset, RadioGroup } from '@unowmooc/react-ui-kit'
import ShadowBox from '@unowmooc/shadow-box'
import I18nProvider from '@unowmooc/i18n'
import { LocaleContext } from 'components/LocaleProvider'
import config from 'config'
import { Can } from 'store/AbilityProvider'
import FormContainer from 'components/FormContainer'
import Field from 'components/FieldIntl'
import Switch from 'components/Switch'
import InlineAutoSaveFieldLayout from './InlineAutoSaveFieldLayout'
import AutoSaveFieldLayout from './AutoSaveFieldLayout'

const fragment = gql`
  fragment FormDataFragment on Me {
    id
    locale
    setting {
      id
      sendAnnouncementMail
      sendReminderMail
      sendParticipantCommentMail
      sendCommentReport
      sendSupervisorAlert
      sendSupervisorAskParticipantsPhoneNumberMail
      sendConversationMessageNotificationMail
      phoneCall
    }
  }
`

const FormGroup = styled.section`
  margin-bottom: 50px;

  &:last-child {
    margin-bottom: 0;
  }
`

const Title = styled.h3`
  margin-bottom: 20px;
`

const Description = styled.p`
  margin-bottom: 20px;
`

const StyledShadowBox = styled(ShadowBox)`
  background-color: ${({ theme: { colors } }) => colors.white};
`

const StyledFieldset = styled(Fieldset)`
  margin-top: 0;
`

const StyledSwitch = styled(Switch)`
  display: inline-block;
  min-width: 90px;
`

const Form = ({ id }) => {
  const localeRadioGroupValues = ['fr', 'en'].map(locale => ({
    label: I18nProvider.formatMessage({ id: `locales.${locale}` }),
    id: locale,
  }))

  return (
    <LocaleContext.Consumer>
      {({ switchLocale }) => (
        <FormContainer
          id={id}
          fragment={fragment}
          typeName="Me"
          mutationArgumentName="user"
          queryWithoutId
          autoSave
          onSubmitSucceeded={user => {
            if (user.locale) {
              switchLocale(user.locale)
            }
          }}
        >
          <Can I="access-setting-notification" of="emails">
            <FormGroup>
              <Title>
                <FormattedMessage id="settings.notifications.emails.section_title" />
              </Title>
              <Description>
                <FormattedMessage id="settings.notifications.emails.description" />
              </Description>
              <StyledShadowBox>
                <Can I="change-setting-notification" of="sendAnnouncementMail">
                  <Field
                    name="setting.sendAnnouncementMail"
                    label="settings.notifications.emails.send_announcement_mail"
                    layout={InlineAutoSaveFieldLayout}
                  >
                    <StyledSwitch onOffLabelEnabled />
                  </Field>
                </Can>
                <Can I="change-setting-notification" of="sendReminderMail">
                  <Field
                    name="setting.sendReminderMail"
                    label="settings.notifications.emails.send_reminder_mail"
                    layout={InlineAutoSaveFieldLayout}
                  >
                    <StyledSwitch onOffLabelEnabled />
                  </Field>
                </Can>
                <Can I="change-setting-notification" of="sendParticipantCommentMail">
                  <Field
                    name="setting.sendParticipantCommentMail"
                    label="settings.notifications.emails.send_participant_comment_mail"
                    layout={InlineAutoSaveFieldLayout}
                  >
                    <StyledSwitch onOffLabelEnabled />
                  </Field>
                </Can>
                <Can I="change-setting-notification" of="sendCommentReport">
                  <Field
                    name="setting.sendCommentReport"
                    label="settings.notifications.emails.send_animator_comment_mail"
                    layout={InlineAutoSaveFieldLayout}
                  >
                    <StyledSwitch onOffLabelEnabled />
                  </Field>
                </Can>
                <Can I="change-setting-notification" of="sendSupervisorAlert">
                  <Field
                    name="setting.sendSupervisorAlert"
                    label="settings.notifications.emails.send_supervisor"
                    layout={InlineAutoSaveFieldLayout}
                  >
                    <StyledSwitch onOffLabelEnabled />
                  </Field>
                </Can>
                <Can I="change-setting-notification" of="sendSupervisorAskParticipantsPhoneNumberMail">
                  <Field
                    name="setting.sendSupervisorAskParticipantsPhoneNumberMail"
                    label="settings.notifications.emails.send_supervisor_ask_participants_phone_number_mail"
                    layout={InlineAutoSaveFieldLayout}
                  >
                    <StyledSwitch onOffLabelEnabled />
                  </Field>
                </Can>
                <Can I="change-setting-notification" of="sendConversationMessageNotificationMail">
                  <Field
                    name="setting.sendConversationMessageNotificationMail"
                    label="settings.notifications.emails.send_conversation_notification_mail"
                    layout={InlineAutoSaveFieldLayout}
                  >
                    <StyledSwitch onOffLabelEnabled />
                  </Field>
                </Can>
              </StyledShadowBox>
            </FormGroup>
          </Can>
          <Can I="access-setting-notification" of="phone">
            <FormGroup>
              <Title>
                <FormattedMessage id="settings.notifications.phone.section_title" />
              </Title>
              <Description>
                <FormattedMessage id="settings.notifications.phone.description" />
              </Description>
              <StyledShadowBox>
                <Can I="change-setting-notification" of="phoneCall">
                  <Field
                    name="setting.phoneCall"
                    label="settings.notifications.phone.phone_call"
                    layout={InlineAutoSaveFieldLayout}
                  >
                    <StyledSwitch onOffLabelEnabled />
                  </Field>
                </Can>
              </StyledShadowBox>
            </FormGroup>
          </Can>
          {config.featureFlags.switchUserLanguage && (
            <FormGroup>
              <Title>
                <FormattedMessage id="settings.preferences.language.section_title" />
              </Title>
              <Description>
                <FormattedMessage id="settings.preferences.language.description" />
              </Description>
              <StyledFieldset standalone>
                <Field name="locale" label="settings.preferences.language.locale" layout={AutoSaveFieldLayout}>
                  <RadioGroup returnsPlainObject={false} options={localeRadioGroupValues} />
                </Field>
              </StyledFieldset>
            </FormGroup>
          )}
        </FormContainer>
      )}
    </LocaleContext.Consumer>
  )
}

Form.defaultProps = {
  id: undefined,
}

Form.propTypes = {
  id: PropTypes.string,
}

export default Form
