import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import { ButtonI18n } from '@unowmooc/buttons'
import { mq } from '@unowmooc/themes'
import { css } from '@emotion/core'
import ShadowBox from '@unowmooc/shadow-box'
import { useDomainContext } from 'components/DomainLayout/Context'
import characterSvg from './assets/character.svg'

const TitlePlaceholder = styled.h3`
  margin-bottom: 28px;
`

const ShadowBoxStyled = styled(ShadowBox)`
  margin-top: 40px;
  padding: 30px 25px;
`

const PlaceholderWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const CharacterImage = styled.img`
  margin-top: 30px;
  margin-right: 45px;
  width: 206px;
  height: 248px;

  ${mq.sm(css`
    visibility: hidden;
    position: absolute;
  `)};
`

const ButtonI18nStyled = styled(ButtonI18n)`
  margin-top: 40px;
`

const EthicsCodePlaceholder = ({ onButtonClick }) => {
  const { domain } = useDomainContext()

  return (
    <ShadowBoxStyled>
      <PlaceholderWrapper>
        <CharacterImage alt="" src={characterSvg} />
        <div>
          <TitlePlaceholder>
            <FormattedMessage id="conversation.ethics_code.title" />
          </TitlePlaceholder>
          <p>
            <FormattedHTMLMessage id="conversation.ethics_code.content" />
          </p>
          <ButtonI18nStyled
            variant="primary"
            i18nKey={`conversation.ethics_code.write_message_button_label.${domain}`}
            onClick={onButtonClick}
          />
        </div>
      </PlaceholderWrapper>
    </ShadowBoxStyled>
  )
}

EthicsCodePlaceholder.propTypes = {
  onButtonClick: PropTypes.func.isRequired,
}

export default EthicsCodePlaceholder
