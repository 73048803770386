import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'
import { FormattedMessage } from 'react-intl'
import SurveyProgression from 'components/SurveyProgression'
import SurveyQuestionLabel from 'components/SurveyQuestionLabel'
import { withRouter } from 'react-router-dom'
import Choices from './Choices'
import Feedback from './Feedback'
import { useQuiz } from '../../provider'
import SubmitAnswerButton from '../SubmitAnswerButton'

const Wrapper = styled.div`
  margin-top: 50px;

  ${mq.sm(css`
    margin-top: 20px;
  `)};
`

const StyledChoices = styled(Choices)`
  margin-bottom: 40px;
`

const Question = ({ className, history }) => {
  const [loadingValidation, updateLoadingValidation] = useState(true)
  const { getBasePath, isValid, currentQuestion, questionIndex, questionCounter } = useQuiz()

  useEffect(() => {
    isValid({ onValid: () => updateLoadingValidation(false) })
  }, [])

  if (loadingValidation) {
    return null
  }

  // Ce cas peut arriver lors d'un history back depuis la page de résultats.
  if (!currentQuestion) {
    history.push(getBasePath())

    return null
  }

  const {
    quizQuestion: { text, hasManyValidChoices },
  } = currentQuestion

  return (
    <Wrapper className={className}>
      <SurveyProgression
        label={<FormattedMessage id="quiz.question.progression_label" />}
        currentQuestionNumber={questionIndex + 1}
        numberOfQuestions={questionCounter}
      />
      <SurveyQuestionLabel>
        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: text }} />
        {hasManyValidChoices && (
          <>
            &nbsp;(
            <FormattedMessage id="quiz.question.help_selection.multi" />)
          </>
        )}
      </SurveyQuestionLabel>
      <StyledChoices />
      <Feedback />
      <SubmitAnswerButton />
    </Wrapper>
  )
}

Question.defaultProps = {
  className: undefined,
}

Question.propTypes = {
  className: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
}

export default withRouter(Question)
