import React from 'react'
import hexToRgba from 'hex-to-rgba'
import { css } from '@emotion/core'
import MediaQuery from 'react-responsive'
import colors from './colors.scss'
import transitions from './transitions.scss'

const breakpoints = {
  xsDataGrid: 481 - 1,
  xs: 576 - 1,
  sm: 768 - 1,
  md: 992 - 1,
  lg: 1200 - 1,
}

// create media queries
const mq = Object.keys(breakpoints).reduce(
  (accumulator, name) => ({
    ...accumulator,
    [name]: cls =>
      css`
        @media (max-width: ${breakpoints[name]}px) {
          ${cls};
        }
      `,
  }),
  {},
)

const rgba = hexToRgba

const VisibleXsDataGrid = props => <MediaQuery {...props} query={`(min-width: ${breakpoints.xsDataGrid}px)`} />
const HiddenXsDataGrid = props => <MediaQuery {...props} query={`(max-width: ${breakpoints.xsDataGrid}px)`} />

export { colors, transitions, breakpoints, mq, rgba, VisibleXsDataGrid, HiddenXsDataGrid }
