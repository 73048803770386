import { useReducer } from 'react'
import _ from 'lodash'

const API_FIELD_CHOICE_CONDITION_QUESTION_ID = 'conditional_question_id'

/*
 * Action creators
 */

const INITIALIZE_FORM = 'INITIALIZE_FORM'
const GO_PREV_PAGE = 'GO_PREV_PAGE'
const GO_NEXT_PAGE = 'GO_NEXT_PAGE'
const SUBMIT_ANSWERS = 'SUBMIT_ANSWERS'

export const initializeForm = ({ questions, participantId }) => ({ type: INITIALIZE_FORM, questions, participantId })

export const goPrevPage = () => ({ type: GO_PREV_PAGE })

export const goNextPage = () => ({ type: GO_NEXT_PAGE })

export const submitAnswers = () => ({ type: SUBMIT_ANSWERS })

/**
 * Reducer
 */

export const initialState = {
  loaded: false,
  pageCounter: 0,
  questions: {},
  currentPage: 1,
  hasAnswers: false,
  answers: {},
}

const positionInPageComparison = (a, b) =>
  `${a.page_number}${_.padStart(a.position_in_page, 3, '0')}` >
  `${b.page_number}${_.padStart(b.position_in_page, 3, '0')}`
    ? 1
    : -1

const reducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE_FORM: {
      const sortedQuestions = action.questions.sort(positionInPageComparison)

      // Permet de récupérer un tableau ayant pour valeur les numéros de page de façon unique
      const pageMapping = Array.from(new Set(sortedQuestions.map(question => question.page_number)))

      const nextState = sortedQuestions.reduce(
        (acc, question) => {
          // Récupération du numéro de page réel
          const pageNumber = pageMapping.findIndex(page => page === question.page_number) + 1

          // Permet de formater les données de l'API en context lisible pour le client.
          const nextAcc = {
            pageCounter: Math.max(pageNumber, acc.pageCounter),
            questions: {
              ...acc.questions,
              // On stock les questions dans un objet ayant pour clé l'ID de la question
              // qui permettra de récupérer plus tard la définition d'une question en fonction de son ID
              [question.id]: {
                ...(acc.questions[question.id] || {}),
                ...question,
                page_number: pageNumber,
              },
            },
          }

          if (!Array.isArray(question.choices)) {
            return nextAcc
          }

          // Si le choix d'une question doit déclencher l'appartition d'une question
          // on ajoute un attribut conditionToDisplay dans la définition de la question.
          question.choices.forEach(choice => {
            const conditionalQuestionIdValue = choice[API_FIELD_CHOICE_CONDITION_QUESTION_ID]

            if (!conditionalQuestionIdValue) {
              return
            }

            const conditionalQuestionIds = Array.isArray(conditionalQuestionIdValue)
              ? conditionalQuestionIdValue
              : [conditionalQuestionIdValue]

            conditionalQuestionIds.forEach(conditionalQuestionId => {
              const nextQuestion = nextAcc.questions[conditionalQuestionId] || {}

              nextAcc.questions = {
                ...nextAcc.questions,
                [conditionalQuestionId]: {
                  ...nextQuestion,
                  conditionToDisplay: [
                    ...(nextQuestion.conditionToDisplay || []),
                    {
                      choiceId: choice.id,
                      questionId: question.id,
                    },
                  ],
                },
              }
            })
          })

          return nextAcc
        },
        {
          pageCounter: 0,
          questions: {},
        },
      )

      const hasAnswers = action.questions.some(question => question?.participants?.[action.participantId])

      return {
        ...state,
        ...nextState,
        loaded: true,
        currentPage: 1,
        hasAnswers,
        answers: {},
      }
    }

    case GO_PREV_PAGE: {
      return {
        ...state,
        currentPage: Math.max(state.currentPage - 1, 1),
      }
    }

    case GO_NEXT_PAGE: {
      return {
        ...state,
        currentPage: Math.min(state.currentPage + 1, state.pageCounter),
      }
    }

    case SUBMIT_ANSWERS: {
      return {
        ...state,
        hasAnswers: true,
      }
    }

    default: {
      return state
    }
  }
}

export const useCollectorReducer = () => useReducer(reducer, initialState)
