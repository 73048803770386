import React from 'react'
import PropTypes from 'prop-types'
import { propType, filter } from 'graphql-anywhere'
import gql from 'graphql-tag'
import { toInlineFragment } from 'fraql'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import { Visible } from 'react-grid-system'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import I18nProvider from '@unowmooc/i18n'
import { mq, rgba } from 'styles-resources'
import { Alert } from '@unowmooc/react-ui-kit'
import { getModuleSelected } from 'utils/query'
import { isSessionStarted } from 'modules/session/utils'
import Helmet from 'components/HelmetIntl'
import FormattedDate from 'components/FormattedDate'
import ProgramTimelineMenu from 'modules/session/components/ProgramTimelineMenu'
import AlertClosingPeriod from 'modules/session/components/AlertClosingPeriod'
import { Link } from 'react-router-dom'
import { useCourseContext } from 'modules/course/components/CourseContext/CourseContext'
import { useTracking } from 'modules/tracking'
import { Banner } from '../components/Banner'
import PlatformLink from '../components/PlatformLink'

const StyledAlert = styled(Alert)`
  margin-bottom: 48px;

  ${mq.sm(css`
    margin-bottom: 30px;
  `)};
`

const TimelineMenuWrapper = styled.div`
  background-color: ${({ theme: { colors } }) => colors.white};
  box-shadow: ${({ theme: { colors } }) => `0 13px 46px 0 ${rgba(colors.black, 0.08)}`};

  ${mq.sm(css`
    border-radius: 4px;
    overflow: hidden;
  `)};
`

const StyledProgramTimelineMenu = styled(ProgramTimelineMenu)`
  /* Target gradient bloc  */
  > div > div > div:first-of-type {
    border-radius: 4px 0 0 4px;

    ${mq.sm(css`
      border-radius: 0;
    `)};
  }

  /* Target first item bloc  */
  > div > div > div:nth-of-type(2) > div:first-of-type > div:first-of-type > div:first-of-type {
    border-radius: 0 4px 0 0;

    ${mq.sm(css`
      border-radius: 0;
    `)};
  }
`

const PlatformLinkStyled = styled(PlatformLink)`
  margin-bottom: 20px;
`

const ProgramPage = ({
  location: { search },
  session,
  session: { course, startAt, hasAccessModuleZero },
  participation,
}) => {
  const { hasBic, hasBicAnswered, sessionUrl, courseIdentifier, sessionIdentifier } = useCourseContext()
  const { sendEvent } = useTracking()

  const handleSectionClick = sectionName => {
    sendEvent(
      'session.timeline_menu',
      JSON.stringify({
        courseIdentifier,
        sessionIdentifier,
        sectionName,
      }),
    )
  }

  return (
    <>
      <Helmet title="courses.course.program.helmet_title" values={{ title: course.title }} />
      <Banner
        course={filter(Banner.fragments.course, session.course)}
        participation={filter(Banner.fragments.participation, participation)}
        session={filter(Banner.fragments.session, session)}
      />
      <AlertClosingPeriod {...filter(AlertClosingPeriod.fragment, session)} />

      <Visible xs sm>
        <PlatformLinkStyled />
      </Visible>

      {!isSessionStarted(session) && hasAccessModuleZero && (
        <StyledAlert>
          <FormattedMessage
            id="courses.course.program.session_not_started"
            values={{
              startAt: <FormattedDate value={startAt} year="2-digit" month="2-digit" day="2-digit" />,
            }}
          />
        </StyledAlert>
      )}
      {hasBic && !hasBicAnswered && (
        <StyledAlert>
          <Link to={`${sessionUrl}${I18nProvider.getLinkRoute('/skills')}`}>
            <FormattedMessage id="courses.course.program.session_bic_not_answered.link_label" />
          </Link>{' '}
          <FormattedHTMLMessage id="courses.course.program.session_bic_not_answered.paragraph" />
        </StyledAlert>
      )}
      <TimelineMenuWrapper>
        <StyledProgramTimelineMenu
          open
          session={filter(ProgramTimelineMenu.fragments.session, session)}
          participation={filter(ProgramTimelineMenu.fragments.participation, participation)}
          selectedModule={getModuleSelected(search)}
          scrollOnSelectedModule
          onSectionClick={handleSectionClick}
        />
      </TimelineMenuWrapper>
    </>
  )
}

ProgramPage.fragments = {
  session: gql`
    fragment _ on Session {
      startAt
      confirmedAt
      hasAccessModuleZero
      course {
        id
        title
        hasFlexibleStart
        ${toInlineFragment(Banner.fragments.course)}
      }
      ${toInlineFragment(ProgramTimelineMenu.fragments.session)}
      ${toInlineFragment(AlertClosingPeriod.fragment)}
      ${toInlineFragment(Banner.fragments.session)}
    }
  `,
  participation: gql`
    fragment _ on Participant {
      advancement {
        status
      }
      ${toInlineFragment(ProgramTimelineMenu.fragments.participation)}
      ${toInlineFragment(Banner.fragments.participation)}
    }
  `,
}

ProgramPage.propTypes = {
  session: propType(ProgramPage.fragments.session).isRequired,
  participation: propType(ProgramPage.fragments.participation).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
}

export default ProgramPage
