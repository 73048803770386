import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { toInlineFragment } from 'fraql'
import { propType, filter } from 'graphql-anywhere'
import gql from 'graphql-tag'
import { withRouter, Link } from 'react-router-dom'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import I18nProvider from '@unowmooc/i18n'
import { colors } from 'styles-resources'
import { isStarted as isSessionStarted } from 'utils/date'
import TagSessionStatus from 'components/TagSessionStatus'
import LinkButtonIcon from 'components/LinkButtonIcon'
import SessionRow, { TagSessionType, NbParticipants, NbParticipantsAdvancement } from 'components/SessionRow'
import { getSessionCode } from 'modules/session/session.utils'
import DateClosingPeriod from 'modules/session/components/DateClosingPeriod'

const LinkStyled = styled(Link)`
  & > span {
    font-weight: 400;

    &:hover {
      text-decoration: underline;
      color: ${colors.unowGreen};
    }
  }
`

const getLinkStatusParticipants = (id, location) => {
  const searchParams = new URLSearchParams(location.search)

  return type => {
    searchParams.set('advancementStatus', type)

    return {
      pathname: I18nProvider.getLinkRoute('/supervisor/sessions/{id}/participants', { id }),
      search: searchParams.toString(),
    }
  }
}

const SupervisedSessionStats = ({
  location,
  supervisedSessionStats: {
    nbParticipants,
    nbWaitingParticipants,
    nbRegisteredParticipants,
    nbUpToDateParticipants,
    nbLateParticipants,
    nbFinisherParticipants,
    session: { id, startAt, endAt, type, runningStatus, currentWeek, course },
    session,
  },
  className,
}) => {
  const isStarted = isSessionStarted(startAt)
  const routeStatusParticipants = getLinkStatusParticipants(id, location)

  return (
    <SessionRow
      className={className}
      title={course.title}
      startAt={startAt}
      sessionCode={getSessionCode(session)}
      sessionId={id}
      endAt={endAt}
      {...filter(DateClosingPeriod.fragment, session)}
      tags={
        <>
          <TagSessionType type={type} />
          <TagSessionStatus status={runningStatus} currentWeek={currentWeek} />
        </>
      }
      details={
        <>
          <NbParticipants>
            <FormattedHTMLMessage id="sessions.session.nb_participants" values={{ nbParticipants }} />
          </NbParticipants>
          {!isStarted && nbWaitingParticipants > 0 && (
            <NbParticipantsAdvancement status="waiting" nbParticipants={nbWaitingParticipants} />
          )}
          {!isStarted && nbRegisteredParticipants > 0 && (
            <NbParticipantsAdvancement status="registered" nbParticipants={nbRegisteredParticipants} />
          )}
          {isStarted && nbFinisherParticipants > 0 && (
            <LinkStyled to={routeStatusParticipants('finisher_not_certified+certified')}>
              <NbParticipantsAdvancement status="finisher" nbParticipants={nbFinisherParticipants} />
            </LinkStyled>
          )}
          {isStarted && nbUpToDateParticipants > 0 && (
            <LinkStyled to={routeStatusParticipants('up_to_date')}>
              <NbParticipantsAdvancement status="up_to_date" nbParticipants={nbUpToDateParticipants} />
            </LinkStyled>
          )}
          {isStarted && nbLateParticipants > 0 && (
            <LinkStyled to={routeStatusParticipants('late')}>
              <NbParticipantsAdvancement status="late" nbParticipants={nbLateParticipants} />
            </LinkStyled>
          )}
        </>
      }
      buttons={
        <LinkButtonIcon
          data-cy={`link-session-${id}`}
          tag={Link}
          to={I18nProvider.getLinkRoute('/supervisor/sessions/{id}', { id })}
          theme="secondary"
          icon="right"
        >
          <FormattedMessage id="sessions.session.see_link" />
        </LinkButtonIcon>
      }
    />
  )
}

SupervisedSessionStats.fragment = gql`
  fragment SupervisedSessionStats on SupervisedSessionStats {
    session {
      id
      startAt
      endAt
      type
      identifier
      runningStatus
      currentWeek
      hasFinalExam
      course {
        id
        title
        type
      }
      ${toInlineFragment(DateClosingPeriod.fragment)}
    }
    nbParticipants
    nbWaitingParticipants
    nbRegisteredParticipants
    nbFinisherParticipants
    nbUpToDateParticipants
    nbLateParticipants
  }
`

SupervisedSessionStats.defaultProps = {
  className: undefined,
}

SupervisedSessionStats.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  supervisedSessionStats: propType(SupervisedSessionStats.fragment).isRequired,
  className: PropTypes.string,
}

export default withRouter(SupervisedSessionStats)
