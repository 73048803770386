import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Radio from 'components/Radio'

const ScaleAnswerButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`

const ScaleAnswer = ({ value, options = [], onChange, name, ...props }) => (
  <ScaleAnswerButtonWrapper>
    {options.map(option => (
      <div>
        <Radio
          {...props}
          value={option.id}
          id={`${name}[${option.id}]`}
          checked={!!value && value.id === option.id}
          name={name}
          onChange={() => {
            if (!value || value.id !== option.id) {
              onChange(option)
            }
          }}
          label={option.label}
          labelDirection="bottom"
          key={option.id}
        />
      </div>
    ))}
  </ScaleAnswerButtonWrapper>
)

ScaleAnswer.defaultProps = {
  value: undefined,
  options: [],
}

ScaleAnswer.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
  ),
  onChange: PropTypes.func.isRequired,
}

export default ScaleAnswer
