import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import FormattedDate from 'components/FormattedDate'
import moment from 'moment'

const FormattedDateInterval = ({ id, from, to }) => {
  let type = 'multiple_days'

  if (moment(from).format('YYYY-MM-DD') === moment(to).format('YYYY-MM-DD')) {
    type = 'single_day'
  }

  return (
    <FormattedMessage
      id={id}
      values={{
        from: <FormattedDate value={from} year="2-digit" month="2-digit" day="2-digit" />,
        to: <FormattedDate value={to} year="2-digit" month="2-digit" day="2-digit" />,
        type,
      }}
    />
  )
}

FormattedDateInterval.propTypes = {
  id: PropTypes.string.isRequired,
  from: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]).isRequired,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]).isRequired,
}

export default FormattedDateInterval
