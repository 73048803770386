import React from 'react'
import styled from '@emotion/styled'
import travailLaptop from 'assets/travailLaptop.svg'
import { Link } from 'react-router-dom'
import { ButtonSkin } from '@unowmooc/buttons'
import I18nProvider from '@unowmooc/i18n'
import { FormattedMessage } from 'react-intl'
import { useCourseContext } from 'modules/course/components/CourseContext/CourseContext'

const Wrapper = styled.div`
  text-align: center;
`

const Paragraph = styled.p`
  margin-top: 24px;
  margin-bottom: 32px;
`

const MobileMessage = () => {
  const { slugCourse, sessionId } = useCourseContext()

  return (
    <Wrapper>
      <img alt={I18nProvider.formatMessage({ id: 'courses.course.skills.mobile.alt' })} src={travailLaptop} />
      <Paragraph>
        <strong>
          <FormattedMessage id="courses.course.skills.mobile.paragraph_1" />
        </strong>
        &nbsp;
        <FormattedMessage id="courses.course.skills.mobile.paragraph_2" />
      </Paragraph>
      <ButtonSkin
        tag={Link}
        to={I18nProvider.getLinkRoute('/courses/{slugCourse}/{sessionId}/program', {
          slugCourse,
          sessionId,
        })}
      >
        <FormattedMessage id="courses.course.skills.mobile.cta_label" />
      </ButtonSkin>
    </Wrapper>
  )
}

export default MobileMessage
