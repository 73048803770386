import { FormattedMessage, FormattedTime } from 'react-intl'
import moment from 'moment'
import React from 'react'

export const getVirtualClassroomLabel = (virtualClassroom, { inline } = { inline: true }) => (
  <span>
    <FormattedMessage
      id="expert.virtual_classroom.label"
      values={{ sequenceNumber: virtualClassroom.sequenceNumber }}
    />
    {inline ? ' - ' : <br />}
    <FormattedMessage
      id="commons.date_at_time"
      values={{
        date: moment(virtualClassroom.startDate).format('D MMMM'),
        time: (
          <FormattedTime
            value={new Date(virtualClassroom.startDate)}
            timeZone="Europe/Paris"
            hour="2-digit"
            minute="2-digit"
          />
        ),
      }}
    />
  </span>
)
