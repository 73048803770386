import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const Wrapper = styled.div`
  display: inline-flex;
  letter-spacing: -5px;
`

const TagDown = ({ size }) => (
  <Wrapper>
    &nbsp;
    <svg width={size} height={size} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12.5" cy="12.5" r="12.5" fill="#FFB10F" fillOpacity="0.2" />
      <path
        d="M13.3478 17.5195C12.9561 18.1458 12.0439 18.1458 11.6522 17.5195L7.82797 11.4053C7.41139 10.7392 7.89021 9.875 8.67579 9.875L16.3242 9.875C17.1098 9.875 17.5886 10.7392 17.172 11.4053L13.3478 17.5195Z"
        fill="#FFB10F"
      />
    </svg>
  </Wrapper>
)

TagDown.defaultProps = {
  size: 25,
}

TagDown.propTypes = {
  size: PropTypes.number,
}

export default TagDown
