import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { FormattedMessage } from 'react-intl'
import { DataGridPaginated } from '@unowmooc/react-ui-kit'
import { Context as QueryMetadataContext } from 'store/QueryMetadataProvider'

class Pagination extends Component {
  componentDidUpdate(prevProps) {
    const { metadata } = this.props

    if (prevProps.metadata !== metadata) {
      const { storeMetadata } = this.context

      storeMetadata(metadata)
    }
  }

  componentWillUnmount() {
    const { clearMetadata } = this.context

    clearMetadata()
  }

  render() {
    const { loading, data, metadata, children, limit, pageIndex, typeName, onPageChange } = this.props

    const total = _.get(metadata, 'total')

    return (
      <DataGridPaginated
        loading={loading}
        data={loading ? undefined : data}
        nbPages={Math.ceil(total / limit)}
        currentPage={pageIndex + 1}
        onPageChange={onPageChange}
        previousLabel={<FormattedMessage id="pagination.previous_label" />}
        nextLabel={<FormattedMessage id="pagination.next_label" />}
        emptyStateMessage={<FormattedMessage id="datagrid.empty_state.message" />}
      >
        {React.Children.map(children, child => {
          if (!child.type.isDataGridGraphql) {
            return child
          }

          return React.cloneElement(child, { ...child.props, typeName })
        })}
      </DataGridPaginated>
    )
  }
}

Pagination.defaultProps = {
  data: undefined,
  metadata: undefined,
}

Pagination.propTypes = {
  typeName: PropTypes.string.isRequired,
  limit: PropTypes.number.isRequired,
  pageIndex: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()),
  metadata: PropTypes.shape(),
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
}

Pagination.contextType = QueryMetadataContext

export default Pagination
