import Tag from '@unowmooc/tags'

export const getAdvancementTheme = ({ status }) => {
  switch (status) {
    case 'certified':
      return Tag.themes.highlight
    case 'up_to_date':
    case 'finisher_not_certified':
      return Tag.themes.green
    case 'late':
      return Tag.themes.purple
    case 'canceled':
      return Tag.themes.lightRed
    case 'not_started':
    default:
      return Tag.themes.grey
  }
}

export const getAdvancementColor = ({ status, lastObtainedBadge, nbFailedExamAttempt }) => {
  switch (status) {
    case 'certified':
      return 'unowGreen'
    case 'up_to_date': {
      if (lastObtainedBadge.module && nbFailedExamAttempt > 0) {
        return 'midGray'
      }

      return 'unowGreen'
    }
    case 'late':
      return 'perfume'
    default:
      return 'whisper'
  }
}
