import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { colors } from '@unowmooc/themes'
import Tooltip from '@unowmooc/tooltip'
import { useTracking } from 'modules/tracking'
import I18nProvider from '@unowmooc/i18n'
import VirtualClassroomsInfo from '../components/VirtualClassroomsInfo'

const meetingAssetSvg = require('assets/meeting.svg')

const StyledVirtualClassroomsInfo = styled(VirtualClassroomsInfo)`
color: ${colors.shipGray};
text-align: left;
}
`

const VirtualClassroomsTooltip = ({ sessionId, sessions }) => {
  const { sendEvent } = useTracking()
  const handleShow = () => {
    sendEvent('session.show_virtual_classroom_tooltip')
  }

  return (
    <Tooltip
      onShow={handleShow}
      placement="left"
      theme="white"
      interactive
      content={<StyledVirtualClassroomsInfo sessions={sessions} selectedSessionId={sessionId} maxColumnsNumber={1} />}
    >
      <img
        alt={I18nProvider.formatMessage({ id: 'supervisor.welcome.data_grid_cols.virtual_classrooms_alt' })}
        width={29}
        height={26}
        src={meetingAssetSvg}
      />
    </Tooltip>
  )
}

VirtualClassroomsTooltip.propTypes = {
  sessionId: PropTypes.string.isRequired,
  sessions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default VirtualClassroomsTooltip
