import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'
import I18nProvider from '@unowmooc/i18n'
import { LinkButton } from '@unowmooc/react-ui-kit'
import ShadowBox from '@unowmooc/shadow-box'
import RemainingAttemptsLabel from '../../../components/RemainingAttemptsLabel'
import Instructions from '../../../components/Instructions'

const StyledRemainingAttemptsLabel = styled(RemainingAttemptsLabel)`
  margin-top: 15px;
  margin-bottom: 20px;
  font-size: 16px;
`

const Box = styled(ShadowBox)`
  margin-bottom: 70px;
  padding: 30px;
  text-align: left;

  ${mq.sm(css`
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 30px;
    padding: 20px;
  `)};
`

const InstructionsReminder = styled.h4`
  margin-bottom: 15px;

  ${mq.sm(css`
    font-size: 20px;
    line-height: 28px;
  `)};
`

const Retry = () => (
  <>
    <LinkButton tag={Link} to={I18nProvider.translatePath('confirm-identity')} size="large">
      <FormattedMessage id="final_exam.results.failure.retry_link" />
    </LinkButton>

    <StyledRemainingAttemptsLabel />
    <Box>
      <InstructionsReminder>
        <FormattedMessage id="final_exam.results.failure.instructions_reminder" />
      </InstructionsReminder>
      <Instructions />
    </Box>
  </>
)

export default Retry
