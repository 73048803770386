import React from 'react'
import { FormattedMessage } from 'react-intl'

export const isRequired = value => !!value || <FormattedMessage id="errors.isRequired" />

export const isEmail = value => {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return re.test(String(value).toLowerCase()) || <FormattedMessage id="errors.isEmail" />
}

export const minLength = min => value =>
  (value && value.length >= min) || <FormattedMessage id="errors.minLength" values={{ number: min }} />

export const maxLength = max => value =>
  (value && value.length <= max) || <FormattedMessage id="errors.maxLength" values={{ number: max }} />
