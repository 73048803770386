import React from 'react'
import PropTypes from 'prop-types'
import Guide from 'modules/guide'
import qs from 'query-string'
import I18nProvider from '@unowmooc/i18n'
import { useCourseContext } from 'modules/course/components/CourseContext/CourseContext'
import { Redirect, Route, Switch } from 'react-router-dom'

const GuidePage = ({ location: { search }, match: { url }, history }) => {
  const { sessionUrl } = useCourseContext()

  // Actuellement la queryString ?light est passée à l'ouverture du guide manuelle.
  const parsedQueryString = qs.parse(search)
  const light = Object.prototype.hasOwnProperty.call(parsedQueryString, 'light')

  const handleFinish = () => {
    history.push(`${sessionUrl}/${I18nProvider.translatePath('program')}`)
  }

  const handleLeave = () => {
    if (parsedQueryString.from) {
      // On accède à la page via location native, car il est impossible de certifier que l'URL passée en paramètre provient de l'application React
      // De plus, cela permet de réinitialiser l'état de la page formations avec les données à jour.
      window.location.href = parsedQueryString.from

      return
    }

    handleFinish()
  }

  const getSlidePage = slidePage => `${url}/slide/${slidePage}${search}`

  return (
    <Switch>
      <Route
        path={`${url}/slide/:slidePage`}
        render={renderProps => (
          <Guide
            {...renderProps}
            getSlidePage={getSlidePage}
            onLeave={handleLeave}
            onFinish={handleFinish}
            lightMode={light}
          />
        )}
      />
      <Route exact path={url} render={() => <Redirect to={getSlidePage(1)} />} />
    </Switch>
  )
}

GuidePage.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
}

export default GuidePage
