import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const ContentWrapper = styled.div`
  color: ${({ theme }) => theme.colors.shipGray};

  p {
    font-size: 16px;
    line-height: 26px;
    color: ${({ theme }) => theme.colors.shipGray};
  }
`

const CommentContent = ({ editor: Editor, content: Content, active, onSubmitSuccess, className }) => (
  <ContentWrapper className={className}>
    {active &&
      React.cloneElement(Editor, {
        onSubmitSuccess,
      })}
    {!active && Content}
  </ContentWrapper>
)

CommentContent.defaultProps = {
  active: false,
  className: undefined,
  onSubmitSuccess: () => {},
}

CommentContent.propTypes = {
  active: PropTypes.bool,
  onSubmitSuccess: PropTypes.func,
  content: PropTypes.node.isRequired,
  editor: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default CommentContent
