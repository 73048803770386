import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'
import Participation from './Participation'

const Label = styled.h3`
  margin-bottom: 30px;

  ${mq.sm(css`
    margin-bottom: 20px;
  `)};
`

const ParticipationWrapper = styled.div`
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
`

class Group extends Component {
  get participations() {
    const { participations } = this.props

    return _.orderBy(participations, ['session.startAt'], ['desc'])
  }

  render() {
    const { label, type, className } = this.props
    const { participations } = this

    return (
      <div className={className} data-cy={`animations-status-${type}`}>
        <Label>{label}</Label>

        {participations.map(participation => (
          <ParticipationWrapper data-cy="session-list-item" key={participation.id}>
            <Participation participation={participation} />
          </ParticipationWrapper>
        ))}
      </div>
    )
  }
}

Group.defaultProps = {
  className: undefined,
}

Group.propTypes = {
  participations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  label: PropTypes.node.isRequired,
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default Group
