import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import ResponsiveAvatar from 'components/ResponsiveAvatar'
import moment from 'moment'
import I18nProvider from '@unowmooc/i18n'

const SenderBox = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
`

const SenderInfo = styled.div`
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  color: ${({ theme: { colors } }) => colors.midGray};

  & > div {
    margin-bottom: 2px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const SenderFullName = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 15px;
`

const SenderSentAt = styled.div`
  font-size: 12px;
  line-height: 13px;
`

const formatDateLabel = date => {
  if (!date) {
    return null
  }

  // Cela permet d'afficher par exemple "Il y a 8 minutes" ou bien "8 minutes ago" en fonction de la locale
  const momentDate = moment(date)
  const formattedDateTime = I18nProvider.formatMessage({
    id: `commons.the_date_at_time`,
    values: { date: momentDate.format('dddd LL'), time: momentDate.format('LT') },
  })

  if (momentDate.isAfter(moment().subtract(7, 'days'))) {
    return <span title={formattedDateTime}>{momentDate.fromNow()}</span>
  }

  return formattedDateTime
}

const Sender = ({ fullName, avatarUrl, sentAt }) => (
  <SenderBox>
    <ResponsiveAvatar user={{ fullName, avatar: avatarUrl ? { secureUrl: avatarUrl } : null }} size={30} />
    <SenderInfo>
      <SenderFullName>{fullName}</SenderFullName>
      {sentAt && <SenderSentAt>{formatDateLabel(sentAt)}</SenderSentAt>}
    </SenderInfo>
  </SenderBox>
)

Sender.defaultProps = {
  avatarUrl: undefined,
  sentAt: undefined,
}

Sender.propTypes = {
  fullName: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string,
  sentAt: PropTypes.string,
}

export default Sender
