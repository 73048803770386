import React from 'react'
import moment from 'moment'
import { PropTypes } from 'prop-types'
import _ from 'lodash'
import { FormattedMessage } from 'react-intl'
import { Select } from '@unowmooc/react-ui-kit'

const currentDate = moment()
const months = [...Array(12)].map((month, index) => {
  currentDate.add(index === 0 ? 0 : 1, 'months')
  return {
    label: _.capitalize(currentDate.format('YYYY-MM')),
    value: currentDate.month().toString(),
  }
})

const SelectMonth = ({ value, ...props }) => (
  <Select
    placeholder={<FormattedMessage id="select.default_placeholder" />}
    noResultsText={<FormattedMessage id="select.default_no_result" />}
    value={months.find(option => option.value === value)}
    {...props}
    options={months}
    valueProperty="gt"
    labelProperty="label"
  />
)

SelectMonth.defaultProps = {
  value: '',
}

SelectMonth.propTypes = {
  value: PropTypes.string,
}

export default SelectMonth
