import React from 'react'
import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import { ApolloLink } from 'apollo-link'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { disableFragmentWarnings } from 'graphql-tag'
import * as Sentry from '@sentry/browser'
import { getToken } from '@unowmooc/utils/auth'
import { redirectToAuthenticationPage } from 'utils/routes'
import { handleAuthCookie } from 'utils/auth'
import _ from 'lodash'
import config from 'config'
import PageQuery from 'components/PageQuery'

disableFragmentWarnings()

const httpLink = new HttpLink({ uri: config.env.GRAPHQL_API_URL })

// eslint-disable-next-line consistent-return
const authLink = setContext((operation, { headers }) => {
  /**
   * getToken throw un erreur donc pas de besoin de rajouter un if sur le handleAuthCookie
   *
   * Le if aurait servit a appelé la methode handleAuthCookie si le token était défini.
   */
  try {
    const token = getToken()

    handleAuthCookie(token)

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : null,
      },
    }
  } catch (error) {
    redirectToAuthenticationPage()

    // Arrêt de la requête GraphQL
    throw error
  }
})

const excludedErrorCodes = ['access_me_require_authentication']

const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (graphQLErrors) {
    if (_.isPlainObject(graphQLErrors)) {
      const { code, message } = graphQLErrors

      Sentry.withScope(scope => {
        scope.setExtra('message', message)
        scope.setExtra('code', code)

        Sentry.captureException(new Error(`[GraphQL error]: Message: ${message}, Code: ${code}`))
      })
    }

    if (Array.isArray(graphQLErrors)) {
      graphQLErrors.forEach(({ message, path, code }) => {
        if (!_.includes(excludedErrorCodes, code)) {
          Sentry.withScope(scope => {
            scope.setExtra('message', message)
            scope.setExtra('code', code)
            scope.setExtra('path', path)

            Sentry.captureException(new Error(`[GraphQL error]: [${code}] - ${message}`))
          })
        }
      })
    }
  }

  /**
   * Redirect on the authentication page if :
   * * status code 503 (API maintenance mode).
   * * status code 401, 403 (The user attempts to access a resource that requires authentication).
   */
  if ([503, 401, 403].includes(networkError && networkError.statusCode)) {
    window.location.href = config.env.AUTH_CLIENT_URL
    return
  }

  if (networkError) {
    Sentry.captureMessage(new Error(`[Network error]: ${networkError}`))
  }
})

const link = ApolloLink.from([authLink, errorLink, httpLink])

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
})

export const injectQuery = (Component, { query, variables }) => props => (
  <PageQuery
    query={query}
    variables={variables}
    render={gqlQueryResult => <Component {...props} gqlQueryResult={gqlQueryResult} />}
  />
)
