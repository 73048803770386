import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { rgba, colors } from 'styles-resources'
import moment from 'moment'
import gql from 'graphql-tag/lib/graphql-tag.umd'
import { FormattedHTMLMessage, FormattedMessage, FormattedTime } from 'react-intl'
import { Button, ButtonSkin } from '@unowmooc/buttons'
import { useCourseContext } from 'modules/course/components/CourseContext/CourseContext'
import { withRouter } from 'react-router-dom'
import { mq } from '@unowmooc/themes'
import { css } from '@emotion/core'
import { getSlotStatus, SLOT_STATUS } from 'utils/virtualClassroom'
import { useUserContext } from 'store/UserProvider'
import { useSequenceContext } from './SequenceContext'
import EvaluationModal from './EvaluationModal'

const Wrapper = styled.div`
  background-color: ${({ authenticatedParticipantRegistered }) =>
    authenticatedParticipantRegistered ? rgba(colors.unowGreen, 0.05) : colors.white};
  border-radius: 5px;
  box-shadow: 0 0px 0px 1px
    ${({ authenticatedParticipantRegistered }) =>
      authenticatedParticipantRegistered ? rgba(colors.unowGreen, 0.5) : rgba(colors.unowGreen, 0.1)};
  border-color: ${({ authenticatedParticipantRegistered }) =>
    rgba(colors.unowGreen, authenticatedParticipantRegistered ? 1 : 0.3)};
  border-width: 1px;
  border-style: solid;
  padding: 14px;
  width: calc((100% / 3) - 20px);
  margin-right: 20px;
  margin-top: 12px;
  text-align: center;
  box-sizing: border-box;

  ${mq.lg(css`
    width: calc((100% / 2) - 20px);
  `)};

  ${mq.md(css`
    width: 100%;
  `)};
`

const DayLabel = styled.p`
  font-weight: 500;
  color: ${colors.shipGray};
`

const ParticipantCountLabel = styled.p`
  margin-top: 8px;
  margin-bottom: 12px;
  color: ${colors.manatee};
`

const WrapperDoneStyle = styled(Wrapper)`
  background-color: ${rgba(colors.mischka, 0.1)};
  box-shadow: none;
  border-color: ${rgba(colors.manatee, 0.2)};
  color: ${colors.manatee};

  ${DayLabel} {
    color: ${colors.manatee};
  }

  ${ParticipantCountLabel} {
    color: ${colors.manatee};
  }
`

const ButtonWrapper = styled.div`
  margin-top: 12px;
`

const UnregisterButtonStyled = styled(Button, { shouldForwardProp: () => true })`
  padding-left: 14px;
  padding-right: 14px;
`

const EvaluationModalButtonLink = styled(Button, { shouldForwardProp: () => true })`
  i {
    font-size: 18px;
    margin-top: -2px;
  }
`

const computeHasStartDatePassed = function (startDate) {
  return moment().isSameOrAfter(startDate, 'second')
}

const SequenceSlot = ({ virtualClassroom, virtualClassroom: { startDate, endDate } }) => {
  const {
    sessionId,
    courseIdentifier,
    sessionIdentifier,
    participantId,
    virtualClassroomEvaluationForm,
    hasRSVP,
  } = useCourseContext()
  const { isAdmin, isAnimatorFromSession } = useUserContext()
  const { register, unregister, updatingSlot, refresh, confirmPresence } = useSequenceContext()
  const [showEvaluationModal, setShowEvaluationModal] = useState(false)
  const status = getSlotStatus(virtualClassroom)
  const showEvaluationModalLink = useMemo(
    () => moment().isBetween(startDate, moment(endDate).add(72, 'hours')) && !!virtualClassroomEvaluationForm,
    [startDate, endDate, virtualClassroomEvaluationForm],
  )

  const [hasStartDatePassed, setHasStartDatePassed] = useState(computeHasStartDatePassed(startDate))

  useEffect(() => {
    if (status === SLOT_STATUS.OPENED) {
      setTimeout(() => {
        refresh()
      }, moment(startDate).subtract('15', 'minutes').diff(moment()))
    }
  }, [])

  // on force le refresh pour le hasStartDatePassed
  useEffect(() => {
    setInterval(() => {
      setHasStartDatePassed(computeHasStartDatePassed(startDate))
    }, 30 * 1000)
  })

  // Dans le cas où RSVP est désactivé, seuls les créneaux de classes virtuelles auxquels le participant est inscrit sont affichés.
  if (
    !hasRSVP &&
    !virtualClassroom.hasAuthenticatedParticipantRegistered &&
    status !== SLOT_STATUS.DONE &&
    !isAdmin() &&
    !isAnimatorFromSession(sessionId)
  ) {
    return null
  }

  const handleConfirmPresence = event => {
    event.preventDefault()

    confirmPresence(virtualClassroom)
  }

  const handleConfirmPresenceOnConnectButton = () => {
    if (!virtualClassroom.hasAuthenticatedParticipantConfirmedPresence) {
      confirmPresence(virtualClassroom)
    }
  }

  const handleRegister = event => {
    event.preventDefault()

    register(virtualClassroom)
  }

  const handleUnregister = event => {
    event.preventDefault()

    unregister(virtualClassroom)
  }

  const handleShowEvaluationModal = () => {
    setShowEvaluationModal(true)
  }

  const handleCloseEvaluationModal = () => {
    setShowEvaluationModal(false)
  }

  const WrapperComponent = status === SLOT_STATUS.DONE ? WrapperDoneStyle : Wrapper

  return (
    <WrapperComponent authenticatedParticipantRegistered={virtualClassroom.hasAuthenticatedParticipantRegistered}>
      <DayLabel>{moment(virtualClassroom.startDate).format('dddd D MMMM')}</DayLabel>
      <DayLabel>
        <FormattedMessage
          id="virtual_classroom.time_slot"
          values={{
            timeStart: (
              <FormattedTime
                value={new Date(virtualClassroom.startDate)}
                timeZone="Europe/Paris"
                hour="2-digit"
                minute="2-digit"
              />
            ),
            timeEnd: (
              <FormattedTime
                value={new Date(virtualClassroom.endDate)}
                timeZone="Europe/Paris"
                hour="2-digit"
                minute="2-digit"
              />
            ),
          }}
        />{' '}
        (<FormattedHTMLMessage id="commons.paris_time" />)
      </DayLabel>
      {hasRSVP && (
        <ParticipantCountLabel>
          <FormattedHTMLMessage
            id="virtual_classroom.participant_count_label"
            values={{ number: virtualClassroom.participantCount }}
          />
        </ParticipantCountLabel>
      )}
      {hasRSVP &&
        status === SLOT_STATUS.OPENED &&
        !virtualClassroom.hasAuthenticatedParticipantRegistered &&
        participantId && (
          <ButtonWrapper>
            <form onSubmit={handleRegister}>
              <Button size="small" type="submit" loading={updatingSlot}>
                <FormattedMessage id="virtual_classroom.slot_register_label" />
              </Button>
            </form>
          </ButtonWrapper>
        )}
      {hasRSVP && status === SLOT_STATUS.OPENED && virtualClassroom.hasAuthenticatedParticipantRegistered && (
        <ButtonWrapper>
          <form onSubmit={handleUnregister}>
            <UnregisterButtonStyled size="small" theme="secondary" type="submit" loading={updatingSlot}>
              <FormattedMessage id="virtual_classroom.slot_unregister_label" />
            </UnregisterButtonStyled>
          </form>
        </ButtonWrapper>
      )}
      {status === SLOT_STATUS.LIVE && virtualClassroom.hasAuthenticatedParticipantRegistered && (
        <>
          <ButtonWrapper>
            <ButtonSkin
              tag="a"
              href={virtualClassroom.link}
              target="virtual_classroom_live"
              onClick={handleConfirmPresenceOnConnectButton}
              size="small"
              data-tracking-id="virtual_classroom.connect_to_virtual_classroom"
              data-tracking-values={JSON.stringify({
                courseIdentifier,
                sessionIdentifier,
                virtualClassroomSequence: virtualClassroom.sequenceNumber,
                virtualClassroomId: virtualClassroom.id,
              })}
            >
              <FormattedMessage id="virtual_classroom.login" />
            </ButtonSkin>
          </ButtonWrapper>
          {virtualClassroom.hasSelfPresenceConfirmationEnabled && (
            <ButtonWrapper style={{ display: 'none' }}>
              {!virtualClassroom.hasAuthenticatedParticipantConfirmedPresence && (
                <ButtonSkin
                  tag="a"
                  theme="secondary"
                  onClick={handleConfirmPresence}
                  size="small"
                  data-tracking-id="virtual_classroom.confirm_presence_to_virtual_classroom"
                  data-tracking-values={JSON.stringify({
                    courseIdentifier,
                    sessionIdentifier,
                    virtualClassroomSequence: virtualClassroom.sequenceNumber,
                    virtualClassroomId: virtualClassroom.id,
                  })}
                >
                  <FormattedMessage id="virtual_classroom.confirm_my_presence" />
                </ButtonSkin>
              )}
              {virtualClassroom.hasAuthenticatedParticipantConfirmedPresence && (
                <ButtonSkin tag="a" theme="secondary" disabled="disabled" size="small">
                  <FormattedMessage id="virtual_classroom.presence_confirmed" />
                </ButtonSkin>
              )}
            </ButtonWrapper>
          )}
        </>
      )}
      {hasStartDatePassed && virtualClassroom.hasAuthenticatedParticipantRegistered && virtualClassroom.supportFileUrl && (
        <ButtonWrapper>
          <a href={virtualClassroom.supportFileUrl} target="vc_support">
            <FormattedMessage id="virtual_classroom.support_link_label" />
          </a>
        </ButtonWrapper>
      )}
      {showEvaluationModal && (
        <EvaluationModal
          key={`${virtualClassroomEvaluationForm.contextPrefix}${virtualClassroom.id}`}
          evaluationFormId={virtualClassroomEvaluationForm.formId}
          evaluationFormContext={`${virtualClassroomEvaluationForm.contextPrefix}${virtualClassroom.id}`}
          participantId={participantId}
          onClose={handleCloseEvaluationModal}
        />
      )}
      {showEvaluationModalLink && virtualClassroom.hasAuthenticatedParticipantRegistered && (
        <ButtonWrapper>
          <EvaluationModalButtonLink
            onClick={handleShowEvaluationModal}
            size="small"
            theme="link"
            icon="survey"
            label={<FormattedMessage id="virtual_classroom.collector_label" />}
            data-tracking-id="virtual_classroom.click_on_review_link"
            data-tracking-values={JSON.stringify({
              courseIdentifier,
              sessionIdentifier,
              virtualClassroomId: virtualClassroom.id,
            })}
          />
        </ButtonWrapper>
      )}
      {virtualClassroom.replayLink && (
        <ButtonWrapper>
          <ButtonSkin
            tag="a"
            href={virtualClassroom.replayLink}
            target="virtual_classroom_replay"
            size="small"
            theme="highlight"
            data-tracking-id="virtual_classroom.watch_replay"
            data-tracking-values={JSON.stringify({
              courseIdentifier,
              sessionIdentifier,
              virtualClassroomSequence: virtualClassroom.sequenceNumber,
              virtualClassroomId: virtualClassroom.id,
            })}
          >
            <FormattedMessage id="virtual_classroom.replay_label" />
          </ButtonSkin>
        </ButtonWrapper>
      )}
    </WrapperComponent>
  )
}

SequenceSlot.fragments = {
  virtualClassroom: gql`
    fragment SequenceSlot on VirtualClassroom {
      id
      sequenceNumber
      startDate
      endDate
      participantCount
      hasAuthenticatedParticipantRegistered
      hasAuthenticatedParticipantConfirmedPresence
      hasSelfPresenceConfirmationEnabled
      link
      replayLink
      isRecordingAvailable
      type
      meetingNumber
      supportFileUrl
    }
  `,
}

SequenceSlot.propTypes = {
  virtualClassroom: PropTypes.shape({
    id: PropTypes.string.isRequired,
    sequenceNumber: PropTypes.number.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    participantCount: PropTypes.number.isRequired,
    hasAuthenticatedParticipantRegistered: PropTypes.bool.isRequired,
    hasAuthenticatedParticipantConfirmedPresence: PropTypes.bool.isRequired,
    hasSelfPresenceConfirmationEnabled: PropTypes.bool.isRequired,
    link: PropTypes.string,
    replayLink: PropTypes.string,
    isRecordingAvailable: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
    meetingNumber: PropTypes.string,
    supportFileUrl: PropTypes.string,
  }).isRequired,
}

export default withRouter(SequenceSlot)
