/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { FormattedMessage } from 'react-intl'
import { FileUploadPreview, ImageUploadPreview } from '@unowmooc/react-ui-kit'
import { mq } from '@unowmooc/themes'
import { isImage, getThumbnailSrc } from 'modules/image/utils'
import styled from '@emotion/styled'

const Wrapper = css`
  margin: 0 12px;

  ${mq.xs(css`
    margin-bottom: 12px;
  `)}
`

const WrapperIcon = css`
  margin-left: 58px;
  margin-bottom: 20px;

  ${mq.xs(css`
    margin-left: 13px;
    margin-bottom: 10px;
  `)}
`

// cf https://github.com/unowmooc/unow-react-ui-kit/blob/master/src/FileUploadPreview/styles.module.scss#L35
// Cela permet de corriger, sur mobile un souci quand on a un nom de fichier trop long : celui-ci empêchait d'afficher
// le bouton de soumission du formulaire
// On ne corrige pas le souci à la source dans unow-react-ui-kit (non modifiable actuellement), mais corrige cela en
// définissant le max-width sur un div parent.
const StyledFileUploadPreview = styled(FileUploadPreview)`
  ${mq.sm(css`
    max-width: 350px;
  `)}
`
const StyledImageUploadPreview = styled(ImageUploadPreview)`
  ${mq.sm(css`
    max-width: 350px;
  `)}
`

const FilePreview = ({ file, progress, error, onDelete, className }) => {
  const uploadProgressLabel = <FormattedMessage id="file_preview.upload_progress_label" />
  const downloadLabel = <FormattedMessage id="file_preview.download_label" />
  const deleteLabel = <FormattedMessage id="file_preview.delete_label" />

  return isImage(file.mimeType) ? (
    <StyledImageUploadPreview
      src={file.secureUrl || file.url}
      css={Wrapper}
      thumbnailSrc={getThumbnailSrc(file)}
      showDimensions={false}
      openOnClick
      filename={file.filename || ''}
      progress={progress}
      onDelete={onDelete}
      uploadProgressLabel={uploadProgressLabel}
      downloadLabel={downloadLabel}
      deleteLabel={deleteLabel}
      error={error}
      className={className}
    />
  ) : (
    <StyledFileUploadPreview
      url={file.secureUrl || file.url}
      filename={file.filename || ''}
      css={WrapperIcon}
      progress={progress}
      onDelete={onDelete}
      uploadProgressLabel={uploadProgressLabel}
      downloadLabel={downloadLabel}
      deleteLabel={deleteLabel}
      error={error}
      className={className}
    />
  )
}

FilePreview.fragments = {
  file: gql`
    fragment _ on File {
      filename
      secureUrl
      mimeType
      fileStackId
      signature
      policy
    }
  `,
}

FilePreview.defaultProps = {
  progress: undefined,
  error: undefined,
  onDelete: undefined,
  className: undefined,
}

FilePreview.propTypes = {
  file: PropTypes.shape({
    filename: PropTypes.string.isRequired,
    url: PropTypes.string,
    secureUrl: PropTypes.string,
    mimeType: PropTypes.string,
    fileStackId: PropTypes.string,
    signature: PropTypes.string,
    policy: PropTypes.string,
  }).isRequired,
  progress: PropTypes.number,
  error: PropTypes.node,
  onDelete: PropTypes.func,
  className: PropTypes.string,
}

export default FilePreview
