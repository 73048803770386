import _ from 'lodash'
import { animateScrollTo } from 'utils/scroll'
import createOnErrorDecorator from './onError'

const createScrollOnErrorDecorator = ({ offsetTop = 0 }) => {
  const scrollOnFirstError = errors => {
    const allFields = document.querySelectorAll('[data-name]')

    const firstFieldInError = _.find(allFields, field => _.get(errors, field.dataset.name))

    if (firstFieldInError) {
      animateScrollTo(firstFieldInError, { offset: offsetTop })
    }
  }

  return createOnErrorDecorator(scrollOnFirstError)
}

export default createScrollOnErrorDecorator
