import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import StreamlineIcon from '@unowmooc/icons'
import flagBackground from './assets/flag-bg.svg'

const Flag = styled.div`
  flex-shrink: 0;
  width: 48px;
  height: 62px;
  background: url('${flagBackground}') no-repeat;
  text-align: center;
`

const PinIcon = styled(StreamlineIcon)`
  margin-top: 16px;
  color: ${({ theme: { colors } }) => colors.white};
  font-size: 24px;
`

const PinFlag = ({ className }) => (
  <Flag className={className}>
    <PinIcon icon="pin" />
  </Flag>
)

PinFlag.defaultProps = {
  className: undefined,
}

PinFlag.propTypes = {
  className: PropTypes.string,
}

export default PinFlag
