import React from 'react'
import { Box } from 'reflexbox'
import styled from '@emotion/styled'

const StyledBox = styled(Box)`
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 13px 46px 0 rgba(0, 0, 0, 0.08);
  transition: box-shadow 0.3s ease-in-out;
`

const Card = props => <StyledBox backgroundColor="white" {...props} />

export default Card
