import React from 'react'
import gql from 'graphql-tag'
import { propType } from 'graphql-anywhere'
import { toInlineFragment } from 'fraql'

const IframeBlock = ({ block }) => (
  <iframe title={block.url} src={block.url} frameBorder="0" height={block.height} width="100%" allowFullScreen />
)

IframeBlock.fragment = toInlineFragment(
  gql`
    fragment _ on Block {
      ... on IframeBlock {
        blockType
        url
        height
      }
    }
  `,
)

IframeBlock.propTypes = {
  block: propType(IframeBlock.fragment).isRequired,
}

export default IframeBlock
