import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  getCourseAgreementUrl,
  getCollectiveCourseCertificateUrl,
  getIndividualCourseCertificateUrl,
} from 'business/supervisor'
import { getCertificateDownloadUrl } from 'business/certificate'

class Link extends Component {
  get url() {
    const { sessionId, projectId, participantId, certificateNumber, type } = this.props

    switch (type) {
      case 'cf':
        return getCourseAgreementUrl(projectId, sessionId)
      case 'af':
        return getCollectiveCourseCertificateUrl(projectId, sessionId)
      case 'ai':
        return getIndividualCourseCertificateUrl(participantId)
      case 'cs':
        return getCertificateDownloadUrl(certificateNumber)
      default:
        throw new Error('Document Link exception: type unknown')
    }
  }

  render() {
    const { children, innerRef } = this.props
    const { url } = this

    return (
      <a href={url} target="_blank" rel="noopener noreferrer" ref={innerRef}>
        {children}
      </a>
    )
  }
}

Link.defaultProps = {
  innerRef: undefined,
  projectId: undefined,
  participantId: undefined,
  certificateNumber: undefined,
}

Link.propTypes = {
  sessionId: PropTypes.string.isRequired,
  projectId: PropTypes.string,
  participantId: PropTypes.string,
  certificateNumber: PropTypes.string,
  type: PropTypes.oneOf(['af', 'cf', 'ai', 'cs']).isRequired,
  children: PropTypes.node.isRequired,
  innerRef: PropTypes.func,
}

const LinkWithRef = React.forwardRef((props, ref) => <Link innerRef={ref} {...props} />)

export default LinkWithRef
