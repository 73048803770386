import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import DataGridCell from '@unowmooc/react-ui-kit/lib/DataGrid/Cell'
import DataGridGraphQL from 'components/DataGridGraphQL'
import DataGridToolbar from 'components/DataGridToolbar'
import QueryStringFilter from 'components/QueryStringFilter'
import SelectAdvancementStatus from 'components/Select/AdvancementStatus'
import TagParticipantStatus from 'components/TagParticipantStatus'
import TagParticipantType from 'components/TagParticipantType'

import ParticipantAdvancement from 'modules/participant/components/ParticipantAdvancement'
import TagParticipantAdvancementStatus from 'modules/participant/components/TagParticipantAdvancementStatus'

import I18nProvider from '@unowmooc/i18n'
import Tag from '@unowmooc/tags'

const fragment = gql`
  fragment ListParticipantFragment on Participant {
    id
    status
    type
    hasUsedCpf
    user {
      id
      slug
      fullName
    }
    advancement {
      ...ParticipantAdvancementFragment
      ...TagParticipantAdvancementStatusFragment
    }
  }
  ${ParticipantAdvancement.fragment}
  ${TagParticipantAdvancementStatus.fragment}
`

const StyledTagParticipantType = styled(TagParticipantType)`
  margin-left: 20px;
`

const ParticipantsPage = ({ session }) => (
  <>
    <DataGridToolbar
      renderFilters={() => (
        <QueryStringFilter name="advancementStatus">
          <SelectAdvancementStatus clearable />
        </QueryStringFilter>
      )}
    />
    <DataGridGraphQL
      queryName="participants"
      queryFragment={fragment}
      typeName="Participant"
      filters={{
        canceledAt: null,
        session_id: session.id,
      }}
      limit={50}
    >
      <DataGridCell
        label={<FormattedMessage id="participant.title" />}
        property="user.fullName"
        size="large"
        textTransform="capitalize"
        hideLabelOnMobile
        render={(fullName, { data: { type, user, hasUsedCpf } }) => (
          <>
            {fullName ? (
              <Link
                to={I18nProvider.getLinkRoute('/profile/{slug}/{id}', { slug: user.slug, id: user.id })}
                data-tracking-id="session.click_on_profile_page"
                data-tracking-values={JSON.stringify({
                  from: 'ExpertParticipantList',
                })}
              >
                {fullName}
              </Link>
            ) : (
              <FormattedMessage id="commons.unspecified" />
            )}
            {(type === 'demo' || type === 'observer') && <StyledTagParticipantType type={type} small />}
            {hasUsedCpf && (
              <span style={{ marginLeft: '12px' }}>
                <Tag theme="highlight" small>
                  <FormattedMessage id="commons.cpf" />
                </Tag>
              </span>
            )}
          </>
        )}
      />
      <DataGridCell
        label={<FormattedMessage id="participant.status" />}
        property="status"
        render={status => <TagParticipantStatus status={status} />}
      />
      <DataGridCell
        width="auto"
        align="right"
        label={<FormattedMessage id="registered_users.advancementLastStepValidate" />}
        property="advancement"
        render={advancement => <ParticipantAdvancement advancement={advancement} />}
      />
      <DataGridCell
        width="auto"
        align="right"
        label={<FormattedMessage id="registered_users.advancementStatus" />}
        property="advancement"
        render={(advancement, { data: { user: userAdvancement } }) => (
          // On doit ici ajouter une div afin que l'on ne tombe pas sur une règle CSS de l'ancien UI-Kit sur DataGrid
          // (.dataGrid___3eGIY > div:last-child > div:last-child > div:last-child),
          // qui casserais la mise en forme du dernier élément du tableau"
          <div>
            <TagParticipantAdvancementStatus advancement={advancement} user={userAdvancement} />
          </div>
        )}
      />
    </DataGridGraphQL>
  </>
)

ParticipantsPage.propTypes = {
  session: PropTypes.shape().isRequired,
}

export default ParticipantsPage
