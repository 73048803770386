import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'components/Modal'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'
import { Button } from '@unowmooc/react-ui-kit'
import { getVirtualClassroomLabel } from './utils'

const ModalTitle = styled.h2`
  margin-bottom: 36px;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
`

const ErrorModal = ({ errorModal }) => {
  if (!errorModal) {
    return null
  }

  const {
    present,
    virtualClassroom,
    participant: {
      user: { fullName: userFullName },
    },
  } = errorModal

  const handleClick = () => {
    window.location.reload()
  }

  return (
    <Modal>
      <ModalTitle>
        <FormattedMessage id="commons.error" />
      </ModalTitle>
      <p>
        <FormattedHTMLMessage
          id="expert.virtual_classroom.error_message_paragraph_1"
          values={{ present, userFullName }}
        />{' '}
        <strong>{getVirtualClassroomLabel(virtualClassroom)}</strong>.
      </p>
      <p>
        <FormattedHTMLMessage id="expert.virtual_classroom.error_message_paragraph_2" />
      </p>
      <ButtonWrapper>
        <Button onClick={handleClick}>
          <FormattedMessage id="commons.reload" />
        </Button>
      </ButtonWrapper>
    </Modal>
  )
}

ErrorModal.defaultProps = {
  errorModal: null,
}

ErrorModal.propTypes = {
  errorModal: PropTypes.shape({
    present: PropTypes.bool.isRequired,
    virtualClassroom: PropTypes.shape({
      startDate: PropTypes.string.isRequired,
      sequenceNumber: PropTypes.number.isRequired,
    }).isRequired,
    participant: PropTypes.shape({
      user: PropTypes.shape({
        fullName: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }),
}

export default ErrorModal
