import React from 'react'
import PropTypes from 'prop-types'
import I18nProvider from '@unowmooc/i18n'
import badge1 from './assets/badge-module-1.svg'
import badge2 from './assets/badge-module-2.svg'
import badge3 from './assets/badge-module-3.svg'
import badge4 from './assets/badge-module-4.svg'
import badge5 from './assets/badge-module-5.svg'
import badge6 from './assets/badge-module-6.svg'

const badges = [badge1, badge2, badge3, badge4, badge5, badge6]

const Badge = ({ number, className }) => (
  <img
    src={badges[number - 1]}
    alt={I18nProvider.formatMessage({ id: 'badge.alt', values: { number } })}
    className={className}
  />
)

Badge.defaultProps = {
  className: undefined,
}

Badge.propTypes = {
  number: PropTypes.oneOf([1, 2, 3, 4, 5, 6]).isRequired,
  className: PropTypes.string,
}

export default Badge
