import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { LinkButton } from '@unowmooc/react-ui-kit'
import { mq } from '@unowmooc/themes'
import I18nProvider from '@unowmooc/i18n'
import Instructions from '../../components/Instructions'
import RemainingAttemptsLabel from '../../components/RemainingAttemptsLabel'
import illuChecklistPen from './assets/illu-checklist-pen.svg'

const Illu = styled.img`
  display: block;
  margin: 0 auto 16px auto;

  ${mq.sm(css`
    margin-bottom: 22px;
  `)};
`

const Title = styled.h4`
  text-align: center;
  margin-bottom: 18px;

  ${mq.sm(css`
    font-size: 20px;
    line-height: 28px;
  `)};
`

const StyledInstructions = styled(Instructions)`
  margin-bottom: 30px;
`

const LinkWrapper = styled.div`
  margin-bottom: 18px;
  text-align: center;
`

const StyledRemainingAttemptsLabel = styled(RemainingAttemptsLabel)`
  text-align: center;
  font-size: 16px;
`

const Start = ({ className }) => (
  <div className={className}>
    <Illu src={illuChecklistPen} />
    <Title>
      <FormattedMessage id="final_exam.intro.start.title" />
    </Title>
    <StyledInstructions />
    <LinkWrapper>
      <LinkButton tag={Link} to={I18nProvider.translatePath('confirm-identity')} size="large">
        <FormattedMessage id="final_exam.intro.start.start_link" />
      </LinkButton>
    </LinkWrapper>
    <StyledRemainingAttemptsLabel />
  </div>
)

Start.defaultProps = {
  className: undefined,
}

Start.propTypes = {
  className: PropTypes.string,
}

export default Start
