import PropTypes from 'prop-types'
/** @jsx jsx */
import { jsx, ClassNames } from '@emotion/core'
import { StickyHeader as UIKitStickyHeader } from '@unowmooc/react-ui-kit'
import { transitions, colors, rgba } from 'styles-resources'

const StickyHeader = ({ render }) => (
  <ClassNames>
    {({ css }) => (
      <UIKitStickyHeader
        className={css`
          display: flex;
          box-sizing: border-box;
          flex-direction: column;
          justify-content: center;
          background-color: transparent;
          transition: ${transitions.backgroundColor};
        `}
        activeClassName={css`
          right: 0;
          left: 0;
          z-index: 20;
          height: 60px;
          padding: 0 25px;
          background-color: ${rgba(colors.white, 0.95)};
          box-shadow: 0 1px 0 0 ${colors.whisper};
        `}
        render={render}
      />
    )}
  </ClassNames>
)

StickyHeader.propTypes = {
  render: PropTypes.func.isRequired,
}

export default StickyHeader
