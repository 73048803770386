import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect, withRouter } from 'react-router-dom'
import { Visible } from 'react-grid-system'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'
import I18nProvider from '@unowmooc/i18n'
import LinkDropdown from './LinkDropdown'
import Dropdown from './Dropdown'
import Link from './Link'
import { DomainContextProvider } from './Context'

const Layout = styled.div`
  display: flex;
  padding-top: 30px;

  ${mq.sm(css`
    padding-top: 0;
  `)};
`

const Nav = styled.nav`
  padding: 0 25px;
  box-sizing: border-box;
  width: 30%;
  max-width: 300px;
  min-width: 260px;
`

const Content = styled.div`
  flex: 1 1 auto;
  width: 80%;
  margin-bottom: 100px;
  padding-bottom: 30px; /* prevent box-shadow cutting */
  padding: 0 25px;
  box-sizing: border-box;

  ${mq.sm(css`
    margin-bottom: 30px;
  `)};
`

const Inner = styled.div`
  min-width: 640px;

  ${mq.sm(css`
    min-width: auto;
    padding: 0 15px;
  `)};
`

class DomainLayout extends Component {
  get children() {
    const { children } = this.props

    return (Array.isArray(children) ? children : [children]).filter(child => child && child.type.isDomainLayout)
  }

  render() {
    const { children } = this
    const {
      routeIndex,
      domain,
      location,
      match: { url },
    } = this.props

    return (
      <DomainContextProvider domain={domain}>
        <Layout>
          <Visible md lg xl>
            <Nav data-cy="page-nav">
              {React.Children.map(children, child =>
                child.props.icon ? React.cloneElement(child, { ...child.props, domain, location }) : null,
              )}
            </Nav>
          </Visible>
          <Content>
            <Inner>
              {routeIndex && (
                <Route
                  exact
                  path={url}
                  render={() => <Redirect to={`${url}${I18nProvider.getLinkRoute(routeIndex)}`} />}
                />
              )}
              {children.map(
                ({ props: { name, component, param } }) =>
                  component && (
                    <Route
                      key={name}
                      path={`${url}/${I18nProvider.translatePath(name)}${param ? `/:${param}` : ''}`}
                      component={component}
                    />
                  ),
              )}
            </Inner>
          </Content>
        </Layout>
      </DomainContextProvider>
    )
  }
}

DomainLayout.defaultProps = {
  routeIndex: undefined,
}

DomainLayout.propTypes = {
  children: PropTypes.node.isRequired,
  domain: PropTypes.string.isRequired,
  routeIndex: PropTypes.string,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export { Link as DomainLayoutLink, Dropdown as DomainLayoutDropdown, LinkDropdown as DomainLayoutLinkDropdown }

export default withRouter(DomainLayout)
