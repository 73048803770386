import React from 'react'
import { Col } from 'react-grid-system'
import { filter } from 'graphql-anywhere'
import styled from '@emotion/styled'
import CourseBox from 'components/CourseBox'
import { FadeTransition } from '@unowmooc/react-ui-kit'
import { useCatalogContext } from './CatalogContext'

const StyledCol = styled(Col)`
  margin-bottom: 40px;
`

const Results = () => {
  const { courses } = useCatalogContext()

  return courses.map(course => (
    <FadeTransition appear in key={course.id}>
      <StyledCol xs={12} sm={6} md={6} lg={4}>
        <CourseBox course={filter(CourseBox.fragments.course, course)} />
      </StyledCol>
    </FadeTransition>
  ))
}

export default Results
