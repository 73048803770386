import _ from 'lodash'

export const getCourseFromSession = session => session && session.course

export const getTypeFromSession = session => session && session.type

export const getCourseIdFromSession = session => _.get(session, 'course.id')

export const getStartAtFromSession = session => session && session.startAt

export const getThemeIdFromSession = session => _.get(session, 'course.mainTheme.id')

export const getIdFromSession = session => session.id

export const getPacksFromSession = session =>
  ((session && session.projects) || []).sort((a, b) => new Date(a.expireAt) - new Date(b.expireAt))

export const getPackIdsFromSession = session => getPacksFromSession(session).map(project => project.id)

export const getSessionIdentifier = session => session && session.identifier

export const getSessionModules = session => session && session.modules

export const getStartAtFromSessionModule = sessionModule => sessionModule && sessionModule.startAt

export const getCurrentWeek = session => session && session.currentWeek

/* CLOSING PERIOD */

export const getStartAtFromClosingPeriod = closingPeriod => closingPeriod && closingPeriod.startAt

export const getEndAtFromClosingPeriod = closingPeriod => closingPeriod && closingPeriod.endAt

export const getMessageFromClosingPeriod = closingPeriod => closingPeriod && closingPeriod.message
