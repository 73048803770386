import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'

import Question from 'modules/quiz/components/Question'

const QuestionsPage = ({ match: { url } }) => (
  <>
    <Route exact path={url} render={() => <Redirect to={`${url}/1`} />} />

    <Route exact path={`${url}/:questionNumber`} component={Question} />
  </>
)

QuestionsPage.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
}

export default QuestionsPage
