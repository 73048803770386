import gql from 'graphql-tag'

export const CREATE_CONVERSATION_MUTATION = gql`
  mutation CreateConversationMutation($sessionId: ID!, $memberUserIds: [ID]!, $subject: String!, $content: String!) {
    createConversation(sessionId: $sessionId, memberUserIds: $memberUserIds, subject: $subject, content: $content) {
      id
    }
  }
`

export const REPLY_CONVERSATION_MUTATION = gql`
  mutation ReplyConversationMutation($conversationId: ID!, $content: String!, $markAsResolved: Boolean) {
    replyConversation(conversationId: $conversationId, content: $content, markAsResolved: $markAsResolved) {
      id
      isResolved
      session {
        id
        identifier
        course {
          id
          identifier
        }
      }
    }
  }
`

export const MARK_CONVERSATION_AS_RESOLVED = gql`
  mutation MarkConversationAsResolved($conversationId: ID!) {
    markConversationAsResolved(conversationId: $conversationId) {
      id
      session {
        id
        identifier
        course {
          id
          identifier
        }
      }
    }
  }
`

export const MARK_CONVERSATION_AS_UNRESOLVED = gql`
  mutation MarkConversationAsUnresolved($conversationId: ID!) {
    markConversationAsUnresolved(conversationId: $conversationId) {
      id
      session {
        id
        identifier
        course {
          id
          identifier
        }
      }
    }
  }
`
