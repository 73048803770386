import React from 'react'
import PropTypes from 'prop-types'
import { filter } from 'graphql-anywhere'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'
import ResponsiveAvatar from 'components/ResponsiveAvatar'
import { withUser } from 'store/UserProvider'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const StyledAvatar = styled(ResponsiveAvatar)`
  font-size: 20px;
`

const FullName = styled.span`
  margin-left: 10px;

  ${mq.sm(css`
    font-size: 14px;
    line-height: 23px;
  `)};
`

const UserAvatar = ({ user, size, className }) => (
  <Wrapper className={className}>
    <StyledAvatar size={size} user={filter(ResponsiveAvatar.fragments.user, user)} />
    <FullName>{user.fullName}</FullName>
  </Wrapper>
)

UserAvatar.defaultProps = {
  size: 40,
  className: undefined,
}

UserAvatar.propTypes = {
  user: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
    avatar: PropTypes.shape(),
  }).isRequired,
  size: PropTypes.number,
  className: PropTypes.string,
}

export default withUser(UserAvatar)
