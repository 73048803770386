import React from 'react'
import PropTypes from 'prop-types'
import { FormattedNumber } from 'react-intl'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq, colors } from 'styles-resources'

const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;

  ${mq.xs(css`
    width: 100%;
  `)};
`

const Bar = styled.div`
  position: relative;
  margin-right: 8px;
  width: 100px;
  height: 5px;
  border-radius: 12px;
  background-color: ${colors.mischka};

  ${mq.xs(css`
    width: 100%;
  `)};

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: ${({ percent }) => percent}%;
    height: 5px;
    border-radius: 12px;
    background: ${({ percent }) => (percent === 100 ? colors.gradientUnowGreen : colors.gradientCornflowerBlue)};
  }
`

const Percent = styled.p`
  font-size: 14px;
  line-height: 23px;
  color: ${colors.midGray};
`

const Participation = ({ rate }) => (
  <Wrapper>
    <Bar percent={rate * 100} />
    <Percent>
      <FormattedNumber value={rate} style="percent" maximumFractionDigits={0} />
    </Percent>
  </Wrapper>
)

Participation.propTypes = {
  rate: PropTypes.number.isRequired,
}

export default Participation
