import React, { useCallback } from 'react'
import { propType } from 'graphql-anywhere'
import gql from 'graphql-tag'
import { toInlineFragment } from 'fraql'
import { useMutation } from '@apollo/react-hooks'
import styled from '@emotion/styled'
import { captureException } from '@sentry/browser'
import UploadingFilePreview from 'components/FilePreview'
import { FilePreview } from '@unowmooc/files'
import { addQueryStringParams } from 'utils/url'

const UPDATE_COMMENT = gql`
  mutation updateComment($comment: CommentInput!) {
    updateComment(comment: $comment) {
      id
      file {
        id
      }
    }
  }
`

const FilePreviewWrapper = styled.div`
  margin-top: 30px;

  svg {
    z-index: 0;
  }
`

export const getFileWithUrls = file => {
  if (!file || (!file.secureUrl && !file.url)) {
    return null
  }

  const linkUrl = file.secureUrl || file.url

  return {
    ...file,
    linkUrl,
    downloadUrl: addQueryStringParams(linkUrl, { dl: true }),
  }
}

const Content = ({ comment: { id, text, file, user }, ...props }) => {
  const [updateComment] = useMutation(UPDATE_COMMENT)
  const handleDeleteFile = useCallback(event => {
    event.preventDefault()
    event.stopPropagation()

    updateComment({ variables: { comment: { id, file: null } } }).catch(error =>
      captureException(new Error(`[Comment file]: ${JSON.stringify(error)}`)),
    )
  })

  const fileWithUrls = getFileWithUrls(file)

  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: text }} />
      {fileWithUrls && (
        <FilePreviewWrapper>
          <FilePreview key={fileWithUrls.id} file={fileWithUrls} onDelete={handleDeleteFile} author={user} {...props} />
        </FilePreviewWrapper>
      )}
    </>
  )
}

Content.fragments = {
  comment: gql`
    fragment _ on Comment {
      id
      text
      file {
        id
        createdAt
        filename
        ${toInlineFragment(UploadingFilePreview.fragments.file)}
      }
      user {
        id
        fullName
        avatar {
          id
          url
          secureUrl
          fileStackId
          policy
          signature
        }
      }
    }
  `,
}

Content.propTypes = {
  comment: propType(Content.fragments.comment).isRequired,
}

export default Content
