import React from 'react'
import { propType, filter } from 'graphql-anywhere'
import gql from 'graphql-tag'
import { toInlineFragment } from 'fraql'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'
import Helmet from 'components/HelmetIntl'
import Comments from 'modules/comments/components/Comments'

const Wrapper = styled.div`
  margin-top: 40px;
`

const Title = styled.h4`
  margin-bottom: 36px;
  text-align: center;
`

const DiscussionsPage = ({ session }) => (
  <Wrapper>
    <Helmet title="courses.course.discussions.helmet_title" values={{ title: session.course.title }} />
    <Title>
      <FormattedMessage id="courses.course.discussions.title" />
    </Title>
    <Comments session={filter(Comments.fragments.session, session)} />
  </Wrapper>
)

DiscussionsPage.fragments = {
  session: gql`
    fragment _ on Session {
      id
      course {
        title
      }
      ${toInlineFragment(Comments.fragments.session)}
    }
  `,
}

DiscussionsPage.propTypes = {
  session: propType(DiscussionsPage.fragments.session).isRequired,
}

export default DiscussionsPage
