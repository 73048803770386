import React, { forwardRef } from 'react'
import { propType } from 'graphql-anywhere'
import gql from 'graphql-tag'
import { FormattedMessage } from 'react-intl'
import FormattedDate from 'components/FormattedDate'
import { isStarted as isSessionStarted } from 'utils/date'
import styled from '@emotion/styled'
import Tag from '@unowmooc/tags'
import StreamlineIcon from '@unowmooc/icons'
import { MODULE_TYPE_CODES } from '../../../../course/course.constants'

const Title = styled.span`
  margin-right: ${({ hasTag }) => (hasTag ? 10 : 0)}px;
`

const CurrentWeekTag = styled(Tag, { shouldForwardProp: () => true })`
  line-height: 14px;
  white-space: nowrap;
`

const BonusStreamlineIcon = styled(StreamlineIcon)`
  position: relative;
  top: -1px;
  margin-right: 6px;
`

const ModuleLegend = forwardRef(({ module, session }, ref) => {
  const showCurrentWeekTag = session.currentWeek === module.publicPosition + 1

  return (
    <>
      <div ref={ref} />
      <Title hasTag={showCurrentWeekTag}>
        {module.typeCode !== MODULE_TYPE_CODES.main ? (
          <>
            <BonusStreamlineIcon
              icon={module.typeCode === MODULE_TYPE_CODES.certification ? 'graduation-hat' : 'gift'}
            />
            <FormattedMessage id={`courses.course.program.module_${module.typeCode}`} />
          </>
        ) : (
          <FormattedMessage id="courses.course.program.module_number" values={{ number: module.publicPosition + 1 }} />
        )}
      </Title>

      {!isSessionStarted(session.startAt) && (
        <FormattedMessage
          id="courses.course.program.open_at"
          values={{
            startAt: <FormattedDate value={session.startAt} year="2-digit" month="2-digit" day="2-digit" />,
          }}
        />
      )}

      {showCurrentWeekTag && (
        <CurrentWeekTag small theme="purple">
          <FormattedMessage id="courses.course.program.current_week" />
        </CurrentWeekTag>
      )}
    </>
  )
})

ModuleLegend.fragments = {
  module: gql`
    fragment _ on Module {
      bonus
      typeCode
      publicPosition
    }
  `,
  session: gql`
    fragment _ on Session {
      startAt
      currentWeek
    }
  `,
}

ModuleLegend.propTypes = {
  module: propType(ModuleLegend.fragments.module).isRequired,
  session: propType(ModuleLegend.fragments.session).isRequired,
}

export default ModuleLegend
