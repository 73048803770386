const PARTICIPANT = 'participant'

const EXPERT = 'expert'

const ADMIN = 'admin'

export const AUTHOR_ROLES = {
  ADMIN,
  EXPERT,
  PARTICIPANT,
}

export const COMMENT_STATUS_SELECTOR = {
  RESOLVED: 'resolved',
  UNRESOLVED: 'unresolved',
  ALL: 'all',
}
