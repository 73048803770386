import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { propType, filter } from 'graphql-anywhere'
import gql from 'graphql-tag'
import { toInlineFragment } from 'fraql'
import _ from 'lodash'
import { withRouter } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import UIComments from 'modules/comments/components/UI'
import moment from 'moment'
import { Box } from 'reflexbox'
import queryString from 'query-string'
import styled from '@emotion/styled'
import { ClassNames } from '@emotion/core'
import { colors } from 'styles-resources'
import I18nProvider from '@unowmooc/i18n'
import { withUser } from 'store/UserProvider'
import PageQuery from 'components/PageQuery'
import { isUserAnimatorOfTheSession } from 'modules/user/user.utils'
import Comment from './Comment'
import Answers from './Answers'
import AddForm from './AddForm'
import ThreadResolverButton from '../ThreadResolverButton'
import { ThreadContextProvider } from '../Context/ThreadContext'

const StyledThreadCreator = styled(UIComments.ThreadCreator)`
  margin-bottom: 40px;
`

const StyledAddForm = styled(AddForm)`
  border-radius: 4px;
`

const StyledThread = styled(UIComments.Thread, { shouldForwardProp: prop => prop !== 'user' })`
  margin-bottom: 30px;
  margin-right: -20px;
  margin-left: -20px;
  background-color: ${colors.white};
  overflow: visible;

  .mention {
    border-radius: 6px;
    background-color: rgba(0, 204, 153, 0.08);
    padding: 3px 0;
    color: ${colors.unowGreen};
  }

  .mention[data-id*='${({ user }) => user.id}'] {
    background-color: rgb(255, 230, 179, 0.4);
  }

  .mention[data-id='-1'] {
    color: #3e3e42;
  }

  .mention > span {
    margin: 0 3px;
  }
`

const query = gql`
  query Discussions($filters: Json, $sort: [String]) {
    comments(filters: $filters, sort: $sort, limit: 100) {
      items {
        id
        isPinned
        isResolved
        isForwarded
        isAnonymous
        hasAnonymousCommentInThread
        createdAt
        pinnedAt
        deletedAt
        children {
          id
          deletedAt
          ${toInlineFragment(Comment.fragments.comment)}
        }
        ${toInlineFragment(Comment.fragments.comment)}
      }
    }
  }
`

const AddFormFragment = gql`
  fragment _ on Comment {
    id
    isResolved
    isForwarded
    children {
      id
      ${toInlineFragment(Comment.fragments.comment)}
    }
    ${toInlineFragment(Comment.fragments.comment)}
  }
`

const sortComments = comments =>
  _.orderBy(comments, [({ pinnedAt }) => pinnedAt || '1970-01-01', 'createdAt'], ['desc', 'desc'])

const sortCommentsOnCache = (cache, queryVariables) => {
  const { comments } = cache.readQuery({ query, variables: queryVariables })

  cache.writeQuery({
    query,
    variables: queryVariables,
    data: { comments: { ...comments, items: sortComments(comments.items) } },
  })
}

const addCommentOnCache = (cache, queryVariables, comment) => {
  const { comments } = cache.readQuery({ query, variables: queryVariables })

  cache.writeQuery({
    query,
    variables: queryVariables,
    data: {
      comments: {
        ...comments,
        items: sortComments([
          {
            ...comment,
            createdAt: moment().format(),
            pinnedAt: null,
            deletedAt: null,
            isPinned: false,
          },
          ...comments.items,
        ]),
      },
    },
  })
}

const addChildCommentOnCache = (cache, comment, parent) => {
  const fragment = gql`
    fragment _ on Comment {
      id
      isPinned
      isForwarded
      isAnonymous
      hasAnonymousCommentInThread
      createdAt
      pinnedAt
      deletedAt
      children {
        id
        deletedAt
        ${toInlineFragment(Comment.fragments.comment)}
      }
      ${toInlineFragment(Comment.fragments.comment)}
    }
  `

  const storeParentId = `Comment:${parent.id}`

  const storeParent = cache.readFragment({
    id: storeParentId,
    fragment,
  })

  const { children } = storeParent

  cache.writeFragment({
    id: storeParentId,
    fragment,
    data: {
      children: [...children, { ...comment, deletedAt: null }],
    },
  })
}

const isCommentVisible = comment => comment.deletedAt === null || _.some(comment.children, { deletedAt: null })

const Comments = ({ session, section, user, location: { search, pathname }, history }) => {
  const variables = {
    filters: JSON.stringify({
      session_id: session.id,
      section_id: section ? section.id : null,
      parent_id: null,
    }),
    sort: ['pinnedAt DESC', 'createdAt DESC'],
  }

  const { comment: highlightedCommentId } = queryString.parse(search)
  const isAnimator = isUserAnimatorOfTheSession(user, session)

  const onAddCommentOnCache = useCallback((cache, comment) => addCommentOnCache(cache, variables, comment), [variables])
  const onAddChildCommentOnCache = useCallback((cache, newComment, comment) =>
    addChildCommentOnCache(cache, newComment, comment),
  )
  const onSortCommentOnCache = useCallback(cache => sortCommentsOnCache(cache, variables), [variables])

  const avatarSrc = _.get(user.avatar, 'secureUrl')

  return (
    <>
      <StyledThreadCreator
        avatarSrc={avatarSrc}
        fullName={user.fullName}
        editor={
          <StyledAddForm
            key={`session${session.id}-${section ? `section${section.id}` : 'discussions'}`}
            session={filter(AddForm.fragments.session, session)}
            section={section ? filter(AddForm.fragments.section, section) : undefined}
            addCommentOnCache={onAddCommentOnCache}
            fragment={AddFormFragment}
            placeholder={I18nProvider.formatMessage({ id: 'comment.placeholder' })}
            hasAnonymousCommentInThread={false}
          />
        }
      />

      <PageQuery
        query={query}
        variables={variables}
        render={({ comments: { items } }) => (
          <ClassNames>
            {({ css }) => {
              const cssAvatar = css`
                top: 23px;
              `

              const addCommentOnCacheByComment = comment => (cache, newComment) =>
                onAddChildCommentOnCache(cache, newComment, comment)

              return (
                <>
                  {_.filter(items, isCommentVisible).map(comment => (
                    <ThreadContextProvider key={comment.id}>
                      <StyledThread key={comment.id} user={user}>
                        <Comment
                          comment={filter(Comment.fragments.comment, comment)}
                          session={filter(Comment.fragments.session, session)}
                          user={filter(Comment.fragments.user, user)}
                          sortCommentsOnCache={onSortCommentOnCache}
                          pathname={pathname}
                          historyReplace={history.replace}
                          highlighted={comment.id === highlightedCommentId}
                        />
                        {comment.children.length > 0 && (
                          <Answers
                            answers={filter(Answers.fragments.comment, comment.children)}
                            session={filter(Answers.fragments.session, session)}
                            user={filter(Answers.fragments.user, user)}
                            historyReplace={history.replace}
                            pathname={pathname}
                            startCollapsed={comment.isPinned && !_.find(comment.children, { id: highlightedCommentId })}
                            highlightedCommentId={highlightedCommentId}
                          />
                        )}
                        <UIComments.CommentInput
                          avatarSrc={avatarSrc}
                          fullName={user.fullName}
                          placeholder={<FormattedMessage id="comment.answer_placeholder" />}
                          editorAvatarClassName={cssAvatar}
                          editor={
                            <AddForm
                              autofocus
                              session={filter(AddForm.fragments.session, session)}
                              section={section ? filter(AddForm.fragments.section, section) : undefined}
                              parent={filter(AddForm.fragments.parent, comment)}
                              addCommentOnCache={addCommentOnCacheByComment(comment)}
                              fragment={Comment.fragments.comment}
                              placeholder={I18nProvider.formatMessage({ id: 'comment.answer_placeholder' })}
                              hasAnonymousCommentInThread={comment.hasAnonymousCommentInThread}
                            />
                          }
                        />
                        {isAnimator && comment.user.id !== user.id && (
                          <Box
                            sx={{
                              padding: ['20px', '0px 25px 25px'],
                            }}
                          >
                            <ThreadResolverButton comment={filter(ThreadResolverButton.fragment, comment)} />
                          </Box>
                        )}
                      </StyledThread>
                    </ThreadContextProvider>
                  ))}
                </>
              )
            }}
          </ClassNames>
        )}
      />
    </>
  )
}

Comments.fragments = {
  session: gql`
    fragment _ on Session {
      id
      course {
        id
      }
      ${toInlineFragment(AddForm.fragments.session)}
    }
  `,
  section: gql`
    fragment _ on Section {
      id
      ${toInlineFragment(AddForm.fragments.section)}
    }
  `,
}

Comments.defaultProps = {
  section: undefined,
}

Comments.propTypes = {
  session: propType(Comments.fragments.session).isRequired,
  section: propType(Comments.fragments.section),
  user: PropTypes.shape().isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
}

export default withRouter(withUser(Comments))
