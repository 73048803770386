import React from 'react'
import Helmet from 'components/HelmetIntl'
import styled from '@emotion/styled'
import ErrorBloc from 'components/ErrorBloc'

const StyledPage404 = styled.div`
  padding-top: 100px;
`

const Page404 = () => (
  <StyledPage404>
    <Helmet title="not_found.page_title" />
    <ErrorBloc i18nMessage="not_found.message" />
  </StyledPage404>
)

export default Page404
