import React, { Component } from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import moment from 'moment'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'
import { FormattedMessage } from 'react-intl'
import Helmet from 'components/HelmetIntl'
import { withUser } from 'store/UserProvider'
import PageQuery from 'components/PageQuery'
import RouteTabs from 'components/RouteTabs'
import SupervisedSessionStats from 'components/SupervisedSessionStats'
import EmptyState from './EmptyState'

const Title = styled.h2`
  margin-top: 20px;
  margin-bottom: 56px;

  ${mq.sm(css`
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
  `)};
`

const StyledTabs = styled(RouteTabs)`
  margin-bottom: 45px;

  ${mq.sm(css`
    margin: 0 -15px 20px -15px;
  `)};
`

const StyledSupervisedSessionStats = styled(SupervisedSessionStats)`
  margin-bottom: 30px;

  ${mq.sm(css`
    margin-bottom: 20px;
  `)};

  &:last-child {
    margin-bottom: 0;
  }
`

class List extends Component {
  get filters() {
    const { status } = this.props

    const now = moment().format()

    const filters = {}

    switch (status) {
      case 'in_progress':
        return { ...filters, startAt: { lte: now }, endAt: { gt: now }, canceledAt: null }
      case 'finished':
        return { ...filters, endAt: { lte: now }, canceledAt: null }
      case 'planned':
        return { ...filters, startAt: { gt: now }, canceledAt: null }
      case 'all':
      default:
        return { ...filters }
    }
  }

  get variables() {
    const { user } = this.props
    const { filters } = this

    return { supervisorId: user.id, filters: JSON.stringify(filters), sort: ['startAt DESC'] }
  }

  render() {
    const {
      status,
      statuses,
      location: { pathname },
    } = this.props
    const { variables } = this

    return (
      <>
        <Helmet title="page_titles.supervisor.sessions.title" />
        <Title>
          <FormattedMessage id="supervisor.sessions" />
        </Title>

        <StyledTabs pathname={pathname} messageBaseKey="sessions.tabs" items={statuses} />
        <PageQuery
          query={gql`
            query SupervisedSessionStatsList($supervisorId: ID!, $filters: Json, $sort: [String]) {
              supervisedSessionStatsList(supervisorId: $supervisorId, filters: $filters, sort: $sort) {
                _meta {
                  total
                }
                items {
                  session {
                    id
                  }
                  ...SupervisedSessionStats
                }
              }
            }
            ${SupervisedSessionStats.fragment}
          `}
          variables={variables}
          render={({ supervisedSessionStatsList: { items } }) => (
            <>
              <Helmet title={`page_titles.supervisor.sessions.${status}`} />
              {items.length ? (
                items.map(supervisedSessionStats => (
                  <StyledSupervisedSessionStats
                    supervisedSessionStats={supervisedSessionStats}
                    key={supervisedSessionStats.session.id}
                  />
                ))
              ) : (
                <EmptyState status={status} />
              )}
            </>
          )}
        />
      </>
    )
  }
}

List.propTypes = {
  user: PropTypes.shape().isRequired,
  status: PropTypes.oneOf(['in_progress', 'planned', 'finished', 'all']).isRequired,
  statuses: PropTypes.arrayOf(PropTypes.string).isRequired,
  location: PropTypes.shape().isRequired,
}

export default withUser(List)
