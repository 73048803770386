import React, { useEffect } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { useLazyUpload } from '@unowmooc/files'

const FileStackUploader = ({ render, onChange }) => {
  const [uploadFile, { progress, error, file }] = useLazyUpload()

  useEffect(() => {
    if (file) {
      const {
        url,
        handle,
        _file: { type: mimeType, name: filename },
      } = file

      onChange({
        mimeType,
        filename,
        url,
        fileStackId: handle,
      })
    }
  }, [_.get(file, 'handle')])

  // Dans le cas où aucune progression ne serait retournée on se réfère à l'existence du fichier retourné par FileStack pour définir si l'upload est terminé.
  const progressRender = progress?.totalPercent || (file ? 100 : 0)

  return render({
    onChange: uploadFile,
    progress: progressRender,
    error: error && <FormattedMessage id="uploader.upload_error" />,
  })
}

FileStackUploader.defaultProps = {
  value: undefined,
  onChange: undefined,
}

FileStackUploader.propTypes = {
  render: PropTypes.func.isRequired,
  value: PropTypes.shape(),
  onChange: PropTypes.func,
}

export default FileStackUploader
