import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import gql from 'graphql-tag'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq, colors, rgba } from '@unowmooc/themes'
import { getCatalogCourseUrl } from 'business/website'

const CatalogLink = styled.a`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${colors.white};
  border-radius: 2px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  transition: box-shadow 200ms ease-in-out, transform 200ms ease-in-out;

  &:hover {
    text-decoration: none;
    box-shadow: 0 13px 46px 0 ${rgba(colors.mischka, 0.5)};
    transform: translate3d(0, -2px, 0);
  }

  ${mq.sm(css`
    box-shadow: 0 7px 23px 0 ${rgba(colors.black, 0.1)};

    &:hover {
      box-shadow: 0 7px 23px 0 ${rgba(colors.black, 0.1)};
    }
  `)};
`

const Details = styled.div`
  height: 100%;
  padding: 32px;
  border: 1px solid ${colors.whisper};
  border-top: 0;
  border-radius: 2px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  ${mq.sm(css`
    padding: 16px;
  `)};
`

const Theme = styled.strong`
  display: block;
  margin-bottom: 8px;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 16px;
  text-transform: uppercase;
  color: ${({ color }) => color};
`

const Title = styled.h4`
  margin-bottom: 24px;
  font-weight: 600;

  ${mq.sm(css`
    margin-bottom: 10px;
  `)};
`

const Tagline = styled.p`
  font-size: 18px;
  line-height: 32px;
  color: ${colors.manatee};

  ${mq.sm(css`
    font-size: 14px;
    line-height: 24px;
  `)};
`

const CourseBox = ({ course: { slug, title, tagline, mainTheme }, className }) => (
  <CatalogLink
    data-cy="catalog-course-box"
    href={getCatalogCourseUrl(slug)}
    target="_blank"
    rel="noopener noreferrer"
    className={className}
  >
    <Details>
      <Theme data-cy="catalog-course-theme-label" color={mainTheme.color}>
        {mainTheme.label}
      </Theme>
      <Title>{title}</Title>
      <Tagline>{tagline}</Tagline>
    </Details>
  </CatalogLink>
)

CourseBox.fragments = {
  course: gql`
    fragment _ on Course {
      id
      slug
      title
      tagline
      cpfCode
      mainTheme {
        id
        color
        label
      }
      hero {
        id
        url
        policy
        signature
      }
    }
  `,
}

CourseBox.defaultProps = {
  className: undefined,
}

CourseBox.propTypes = {
  course: propType(CourseBox.fragments.course).isRequired,
  className: PropTypes.string,
}

export default CourseBox
