import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field as FinalFormField } from 'react-final-form'
import { FieldLayout } from '@unowmooc/react-ui-kit'
import {
  required as requiredValidator,
  maxCharacters as maxCharactersValidator,
  mergeValidators,
} from 'business/validators'

class Field extends Component {
  get validators() {
    const { validate, required, maxCharacters } = this.props

    const validators = []

    // add required validator on required field
    if (required) {
      validators.push(requiredValidator)
    }

    // add max characters validator on field with max character helper
    if (maxCharacters) {
      validators.push(maxCharactersValidator(maxCharacters))
    }

    if (validate) {
      validators.push(validate)
    }

    return mergeValidators(validators)
  }

  format(value) {
    return value === undefined ? null : value
  }

  parse(value) {
    return value
  }

  render() {
    const { children, parse, format, layout, ...props } = this.props

    return (
      <FinalFormField
        {...props}
        validate={this.validators}
        format={format || this.format}
        parse={parse || this.parse}
        component={layout}
      >
        {children}
      </FinalFormField>
    )
  }
}

Field.defaultProps = {
  validate: undefined,
  format: undefined,
  parse: undefined,
  layout: FieldLayout,
}

Field.propTypes = {
  children: PropTypes.node.isRequired,
  validate: PropTypes.func,
  format: PropTypes.func,
  parse: PropTypes.func,
  layout: PropTypes.func,
  ...FieldLayout.propTypes,
}

export default Field
