import { isStarted } from 'utils/date'

export const isSessionStarted = ({ startAt, confirmedAt, course: { hasFlexibleStart = true } }) => {
  // La session a atteint la date officiel
  if (isStarted(startAt)) {
    return true
  }

  // On verifie si le demarrage flexible est autorise
  if (hasFlexibleStart) {
    // Si oui, on regarde si on a atteint la date de confirmation
    return isStarted(confirmedAt)
  }

  return false
}

export const getPlatformLinkLabel = ({ sessionIsStarted, participationStarted }) => {
  if (sessionIsStarted) {
    if (participationStarted) {
      return 'courses.course.platform_link.resume'
    }

    return 'courses.course.platform_link.start'
  }

  return 'courses.course.platform_link.discover'
}
