import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { filter as graphqlFilter } from 'graphql-anywhere'
import { toInlineFragment } from 'fraql'
import gql from 'graphql-tag'
import { FormattedDate } from 'react-intl'
import { Box, Flex } from 'reflexbox'
import { FilePreview } from '@unowmooc/files'
import { IntlMessage } from '@unowmooc/text'

import { mq, rgba } from '@unowmooc/themes'
import I18nProvider from '@unowmooc/i18n'
import UIComments from 'modules/comments/components/UI'
import Reactions from 'modules/comments/components/Comments/Comment/Reactions'
import { getFileWithUrls } from 'modules/comments/components/Comments/Comment/Content'
import CommentInfo from './CommentInfo'
import { getTheme } from '../../comment.utils'
import Tag from '../Tag'
import Badge from '../Badge'
import DoneTag from './DoneTag'
import CommentActions from './CommentActions'

const FilePreviewWrapper = styled.div`
  margin-top: 30px;

  svg {
    z-index: 0;
  }
`

const ProfileLink = styled(Link)`
  font-weight: inherit;
  color: inherit;
  line-height: 19px;
`

const StyledComment = styled.div`
  position: relative;
  border-radius: 4px;
  width: 100%;

  & > :first-of-type {
    border-radius: 4px 0 0 0;
  }

  &:not(:first-of-type) {
    margin-top: 28px;
  }
`

export const Separator = styled.div`
  width: calc(100% - 25px);
  height: 1px;
  background-color: ${({ theme }) => rgba(theme.colors.mischka, 0.5)};
  float: right;
  margin-top: 7px;

  ${mq.xs(css`
    width: 100%;
  `)}
`

const CommentExpert = React.memo(({ comment, user }) => {
  const fileWithUrls = getFileWithUrls(comment.file)
  const theme = getTheme(comment.authorRole)

  return (
    <StyledComment>
      <UIComments.Comment
        theme={theme}
        TopHead={
          <Flex position="relative" flexDirection="column" width="100%">
            <Box sx={{ display: ['block', 'none'] }}>
              <CommentActions mobile comment={graphqlFilter(CommentActions.fragment, comment)} />
            </Box>
            <Box sx={{ display: ['none', 'block'], position: 'absolute', right: '20px', top: '25px' }}>
              <CommentActions comment={graphqlFilter(CommentActions.fragment, comment)} />
            </Box>
            <Box mb={['15px', '20px']}>
              <DoneTag comment={graphqlFilter(DoneTag.fragment, comment)} />
            </Box>
          </Flex>
        }
        info={
          <Flex flexDirection="column">
            <CommentInfo
              theme={theme}
              author={
                <ProfileLink
                  to={I18nProvider.getLinkRoute('/profile/{slug}/{id}', {
                    slug: comment.user.slug,
                    id: comment.user.id,
                  })}
                >
                  {comment.user.fullName}
                </ProfileLink>
              }
              avatarName={comment.user.fullName}
              avatarSrc={_.get(comment.user.avatar, 'secureUrl')}
              tagLabel={<Tag comment={graphqlFilter(Tag.fragment, comment)} />}
              badgeLabel={<Badge comment={graphqlFilter(Badge.fragment, comment)} />}
              date={
                <IntlMessage
                  i18nKey="comment.date_expert"
                  values={{
                    date: <FormattedDate value={comment.createdAt} year="2-digit" month="2-digit" day="2-digit" />,
                  }}
                />
              }
            />
          </Flex>
        }
        content={
          <>
            <div dangerouslySetInnerHTML={{ __html: comment.text }} />
            {fileWithUrls && (
              <FilePreviewWrapper>
                <FilePreview key={fileWithUrls.id} file={fileWithUrls} author={comment.user} />
              </FilePreviewWrapper>
            )}
          </>
        }
        reactions={<Reactions reactions={comment.reactions} commentId={comment.id} user={user} />}
      />
    </StyledComment>
  )
})

CommentExpert.fragment = gql`
  fragment CommentExpert on Comment {
    text
    isForwarded
    createdAt
    file {
      id
      createdAt
      title
      filename
      url
      secureUrl
      mimeType
    }
    authorRole
    user {
      id
      slug
      fullName
      role
      avatar {
        id
        url
        secureUrl
        fileStackId
        policy
        signature
      }
      animations {
        id
        session {
          id
        }
      }
    }
    reactions {
      id
      shortname
      user {
        id
        fullName
      }
    }
    ${toInlineFragment(DoneTag.fragment)}
    ${toInlineFragment(Tag.fragment)}
    ${toInlineFragment(Badge.fragment)}
    ${toInlineFragment(CommentActions.fragment)}
  }
`

CommentExpert.propTypes = {
  user: PropTypes.shape().isRequired,
  comment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    authorRole: PropTypes.string.isRequired,
    file: PropTypes.shape(),
    createdAt: PropTypes.string.isRequired,
    user: PropTypes.shape({
      id: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      fullName: PropTypes.string.isRequired,
      avatar: PropTypes.shape().isRequired,
    }).isRequired,
    reactions: PropTypes.arrayOf(),
  }).isRequired,
}

export default CommentExpert
