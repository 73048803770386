import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { ButtonInput } from '@unowmooc/buttons'

const InputCopy = ({ id, value }) => {
  const handleClick = () => {
    document.getElementById(id).select()
    document.execCommand('copy')
  }

  return (
    <ButtonInput
      labelI18nKey="commons.copy"
      activeLabel={<FormattedMessage id="commons.copied" />}
      value={value}
      inputProps={{
        id,
        readOnly: true,
      }}
      onClick={handleClick}
    />
  )
}

InputCopy.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}

export default InputCopy
