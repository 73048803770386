import React, { createContext, useContext } from 'react'
import PropTypes from 'prop-types'

const DomainContext = createContext({
  domain: 'participant',
})

export const DomainContextProvider = ({ children, domain }) => (
  <DomainContext.Provider value={{ domain }}>{children}</DomainContext.Provider>
)

DomainContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  domain: PropTypes.string.isRequired,
}

export const useDomainContext = () => useContext(DomainContext)
