import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Transition } from 'react-transition-group'

const Animation = styled.div`
  overflow: hidden;
  transition: ${({ duration }) => `max-width ${duration}ms ease-in-out`};

  max-width: ${({ state, maxWidth }) => {
    switch (state) {
      case 'entering':
      case 'exiting':
        return `${maxWidth}px`
      case 'exited':
        return '48px'
      case 'entered':
      default:
        return 'none'
    }
  }};
`

const Content = styled.div`
  height: 100%;
  width: ${({ state, maxWidth }) => {
    switch (state) {
      case 'entering':
      case 'exiting':
      case 'exited':
        return `${maxWidth}px`
      case 'entered':
      default:
        return 'auto'
    }
  }};
`

const SlideTransition = ({ in: inProp, duration, children, className }) => {
  const ref = useRef()
  const [width, setWidth] = useState(0)

  useEffect(() => {
    setWidth(ref.current.scrollWidth)
  }, [inProp])

  return (
    <Transition
      in={inProp}
      timeout={{
        appear: 0,
        enter: duration,
        exit: 0,
      }}
    >
      {state => (
        <Animation state={state} maxWidth={width} duration={duration} ref={ref} className={className}>
          <Content state={state} maxWidth={width}>
            {children}
          </Content>
        </Animation>
      )}
    </Transition>
  )
}

SlideTransition.defaultProps = {
  in: false,
  duration: 300,
  className: undefined,
}

SlideTransition.propTypes = {
  children: PropTypes.node.isRequired,
  in: PropTypes.bool,
  duration: PropTypes.number,
  className: PropTypes.string,
}

export default SlideTransition
