import React from 'react'
import _ from 'lodash'
import Select from 'components/Select'
import countries from 'i18n/fr/countries.json'

const options = _.map(countries, (name, isoCode) => ({ label: name, value: isoCode }))

const SelectCountry = props => <Select {...props} options={options} />

export default SelectCountry
