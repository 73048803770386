import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useForm, FormProvider } from 'react-hook-form'
import { Field } from '@unowmooc/form'
import Checkbox from 'components/Checkbox'
import Textarea from 'components/Textarea'
import { ButtonI18n } from '@unowmooc/buttons'
import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl'
import { useUserContext } from 'store/UserProvider'
import { useMutation } from '@apollo/react-hooks'
import { useDomainContext } from 'components/DomainLayout/Context'
import { captureException } from '@sentry/browser'
import { mq } from '@unowmooc/themes'
import { css } from '@emotion/core'
import { useTracking } from 'modules/tracking'
import { REPLY_CONVERSATION_MUTATION } from '../mutations'
import * as ConversationUI from './UI'
import ResolverButton from './ResolverButton'

const LabelCheckbox = styled.span`
  color: ${({ theme: { colors } }) => colors.shipGray};
  font-size: 14px;
`

const FormFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${mq.sm(css`
    & > button:first-child {
      margin-bottom: 20px;
    }
    flex-direction: column;
  `)};
`

const ReplyForm = ({ conversationId, onSubmitSuccess, isResolved }) => {
  const { sendEvent } = useTracking()
  const [submitLoading, setSubmitLoading] = useState(false)
  const [replyConversation] = useMutation(REPLY_CONVERSATION_MUTATION)

  const { domain } = useDomainContext()

  const {
    user: { fullName, avatar },
  } = useUserContext()

  const methods = useForm({
    mode: 'onSubmit ',
    reValidateMode: 'onSubmit',
    shouldUnregister: true,
    defaultValues: {
      content: '',
      markAsResolved: true,
    },
  })

  // Permet de désactiver la soumission du formulaire si la réponse est vide.
  const contentValue = methods.watch('content')?.trim()

  const handleSubmit = methods.handleSubmit(async data => {
    try {
      setSubmitLoading(true)

      const variables = { conversationId, content: data.content }
      if (domain === 'expert') {
        variables.markAsResolved = data.markAsResolved
      }

      const {
        data: { replyConversation: conversation },
      } = await replyConversation({ variables })

      await onSubmitSuccess()

      if (domain === 'expert') {
        sendEvent(
          `conversation.send_message_with_resolved_flag_${conversation.isResolved ? 'checked' : 'unchecked'}`,
          JSON.stringify({
            courseIdentifier: conversation.session.course.identifier,
            sessionIdentifier: conversation.session.identifier,
            conversationId: conversation.id,
          }),
        )
      }

      methods.reset({
        content: '',
        markAsResolved: true,
      })
    } catch (error) {
      methods.setError('FORM_ERROR', error)
      captureException(new Error(`[replyConversation ${conversationId}]: ${JSON.stringify(error)}`))
    }

    setSubmitLoading(false)
  })

  return (
    <ConversationUI.BoxShadow>
      <ConversationUI.Sender fullName={fullName} avatarUrl={avatar?.secureUrl} />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit}>
          <Field name="content" label={<FormattedMessage id="conversation.form.answer" />}>
            <Textarea minRows={5} />
          </Field>
          {domain === 'expert' && (
            <Field name="markAsResolved">
              <Checkbox
                label={
                  <LabelCheckbox>
                    <FormattedMessage id="conversation.mark_as_done_after_answer" />
                  </LabelCheckbox>
                }
                isChecked={({ value }) => value}
                labelDirection="right"
                checked
              />
            </Field>
          )}
          <FormFooter>
            <ButtonI18n
              i18nKey="conversation.submit"
              type="submit"
              variant="primary"
              loading={submitLoading}
              disabled={submitLoading || !contentValue}
            />
            {domain === 'expert' && (
              <ResolverButton
                isResolved={isResolved}
                conversationId={conversationId}
                onSubmitSuccess={onSubmitSuccess}
                disabled={!!contentValue}
              />
            )}
          </FormFooter>
        </form>
      </FormProvider>
    </ConversationUI.BoxShadow>
  )
}

ReplyForm.defaultProps = {
  isResolved: null,
}

ReplyForm.propTypes = {
  conversationId: PropTypes.string.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  isResolved: PropTypes.bool,
}

export default ReplyForm
