import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Tag from '@unowmooc/tags'
import Tooltip from '@unowmooc/tooltip'

class TagParticipantStatus extends PureComponent {
  get theme() {
    const { status } = this.props

    switch (status) {
      case 'inactive':
        return Tag.themes.lightRed
      case 'waiting':
        return Tag.themes.lightGrey
      case 'active':
      case 'registered':
        return Tag.themes.lightGreen
      case 'finish':
        return Tag.themes.lightGrey
      default:
        // do nothing
        return undefined
    }
  }

  render() {
    const { status } = this.props
    const { theme } = this

    return (
      <Tooltip content={<FormattedMessage id={`participant.status_tooltip.${status}`} />}>
        {/* Pour l'instant le composant tag ne forward pas la ref comme tippy le demande
            https://www.npmjs.com/package/@tippy.js/react/v/2.2.3#component-children */}
        <span>
          <Tag theme={theme} small>
            <FormattedMessage id={`participant.status_values.${status}`} />
          </Tag>
        </span>
      </Tooltip>
    )
  }
}

TagParticipantStatus.propTypes = {
  status: PropTypes.string.isRequired,
}

export default TagParticipantStatus
