import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`

const ProgressionText = styled.strong`
  display: block;
  font-size: 11px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${({ theme: { colors } }) => colors.shipGray};
`

const Bar = styled.div`
  position: relative;
  flex-grow: 1;
  margin-left: 24px;
  width: 100px;
  height: 5px;
  border-radius: 12px;
  background-color: ${({ theme: { colors } }) => colors.mischka};
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: ${({ percent }) => percent}%;
    height: 5px;
    background: ${({ theme: { colors } }) => colors.unowGreen};
  }
`

const SurveyProgression = ({ label, currentQuestionNumber, numberOfQuestions, showProgressBar }) => (
  <Wrapper data-cy="survey-progression">
    <ProgressionText>
      {label} {currentQuestionNumber}/{numberOfQuestions}
    </ProgressionText>
    {showProgressBar && <Bar percent={(currentQuestionNumber / numberOfQuestions) * 100} />}
  </Wrapper>
)

SurveyProgression.defaultProps = {
  showProgressBar: false,
}

SurveyProgression.propTypes = {
  label: PropTypes.node.isRequired,
  currentQuestionNumber: PropTypes.number.isRequired,
  numberOfQuestions: PropTypes.number.isRequired,
  showProgressBar: PropTypes.bool,
}

export default SurveyProgression
