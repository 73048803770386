import React from 'react'
import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl'
import { Alert, Container, LinkButton } from '@unowmooc/react-ui-kit'
import { Col, Row } from 'react-grid-system'
import { getSkillsFinalForm } from 'modules/session/session.utils'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'
import { Link, Redirect } from 'react-router-dom'
import I18nProvider from '@unowmooc/i18n'
import useMe from 'hooks/useMe'
import FinalSkillsLinkCard from './FinalSkillsLinkCard'

const Wrapper = styled.div`
  position: relative;
  padding-bottom: 100px;
`

const Title = styled.h1`
  margin-bottom: 30px;
  margin-top: 30px;

  ${mq.sm(css`
    margin-bottom: 20px;
  `)};
`

const WrapperLink = styled.div`
  text-align: center;
  padding-top: 15px;
`

const RowPrincipal = styled(Row)`
  margin-bottom: 50px;
`
const FinalSkillsFormRedirectPage = () => {
  const {
    me: { participants: participations },
  } = useMe()

  const formsNotAnswered = participations.filter(participation => {
    const skillsFinalForm = getSkillsFinalForm(participation.session.forms, participation.formAdvancementStates)

    return skillsFinalForm.hasBfc && !skillsFinalForm.hasBfcAnswered
  })

  if (formsNotAnswered.length === 1) {
    return (
      <Redirect
        to={I18nProvider.getLinkRoute('/courses/{slugCourse}/{sessionId}/final_skills_form', {
          slugCourse: formsNotAnswered[0].session.course.slug,
          sessionId: formsNotAnswered[0].session.id,
        })}
      />
    )
  }

  return (
    <Wrapper>
      <Container>
        <RowPrincipal>
          <Col>
            <Title>
              <FormattedMessage id="domains.final_skills_form_redirect.title" />
            </Title>

            {formsNotAnswered.length === 0 && (
              <Alert theme="warning">
                <FormattedMessage id="domains.final_skills_form_redirect.error_no_final_skills_form" />
                <WrapperLink>
                  <LinkButton theme="highlight" tag={Link} to={I18nProvider.getLinkRoute('/dashboard')}>
                    <FormattedMessage id="domains.final_skills_form_redirect.back_to_dashboard" />
                  </LinkButton>
                </WrapperLink>
              </Alert>
            )}

            {formsNotAnswered.map(participation => (
              <FinalSkillsLinkCard
                title={participation.session.course.title}
                startAt={participation.session.startAt}
                endAt={participation.session.endAt}
                slugCourse={participation.session.course.slug}
                sessionId={participation.session.id}
              />
            ))}
          </Col>
        </RowPrincipal>
      </Container>
    </Wrapper>
  )
}

export default FinalSkillsFormRedirectPage
