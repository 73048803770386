import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Clickable } from '@unowmooc/buttons'
import StreamlineIcon from '@unowmooc/icons'
import { rgba } from '@unowmooc/themes'
import ToggleTransition from '../ToggleTransition'
import SubItem from './SubItem'

const Wrapper = styled.div`
  position: relative;
  color: ${({ theme: { colors } }) => colors.shipGray};

  &::before {
    content: '';
    position: absolute;
    top: 19px;
    left: -30px;
    width: 12px;
    height: 12px;
    background-color: ${({ theme: { colors } }) => colors.white};
    border-radius: 50%;
  }

  &::after {
    content: '';
    position: absolute;
    top: 22px;
    left: -25px;
    width: 2px;
    height: 100%;
    background-color: ${({ theme: { colors } }) => rgba(colors.white, 0.4)};
  }
`

const Button = styled(Clickable)`
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  width: 100%;
  outline-offset: -2px;
  color: ${({ theme: { colors } }) => colors.shipGray};
  background-color: ${({ theme: { colors } }) => colors.whiteLilac};
`

const Legend = styled.span`
  display: block;
  margin-bottom: 6px;
  font-size: 11px;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
`

const Title = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
`

const ArrowIcon = styled(StreamlineIcon, { shouldForwardProp: prop => prop !== 'rotate' })`
  padding-left: 20px;
  font-size: 26px;
  color: ${({ theme: { colors } }) => colors.manatee};
  transition: transform 300ms ease-in-out;
  transform: ${({ rotate }) => `rotate3d(1, 0, 0, ${rotate ? 180 : 0}deg)`};
`

const Item = ({ open, isMenuOpen, legend, title, children }) => {
  const [isOpen, setIsOpen] = useState(open)

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  return (
    <Wrapper>
      <Button
        tabIndex={isMenuOpen ? 0 : -1}
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        <div>
          <Legend>{legend}</Legend>
          <Title>{title}</Title>
        </div>

        <ArrowIcon icon="down" rotate={isOpen} />
      </Button>
      <ToggleTransition in={isOpen} duration={300}>
        <div style={{ visibility: isOpen && isMenuOpen ? 'visible' : 'hidden' }}>{children}</div>
      </ToggleTransition>
    </Wrapper>
  )
}

Item.defaultProps = {
  open: false,
}

Item.propTypes = {
  legend: PropTypes.node.isRequired,
  isMenuOpen: PropTypes.bool.isRequired, // Permet de connaitre l'état du menu et de désactiver les focus s'il est plié.
  open: PropTypes.bool,
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
}

export { SubItem }

export default Item
