export function enrichVirtualLabel(value, renderLabel) {
  if (Array.isArray(value)) {
    return value.map(v => enrichVirtualLabel(v, renderLabel))
  }

  return {
    ...value,
    __label: renderLabel(value),
  }
}
