import _ from 'lodash'
import s from 'underscore.string'
import { useMediaQuery } from 'react-responsive'
import Files from '@unowmooc/files'

export const isImage = mimeType => s.startsWith(mimeType, 'image/')

export const isResizable = mimeType => _.includes(['image/jpeg', 'image/jpg', 'image/png', 'image/bmp'], mimeType)

export const getThumbnailSrc = (file, size = { width: 240 }) => {
  const { mimeType, fileStackId, secureUrl, url } = file

  if (!fileStackId || !isResizable(mimeType)) {
    return secureUrl || url
  }

  return Files.transform(file, {
    resize: {
      ...size,
    },
  })
}

export const useResponsiveSrc = (file, size) => {
  const isOnHighResScreen = useMediaQuery({
    query: '(-webkit-min-device-pixel-ratio: 2), (max-width: 192dpi)',
  })

  const finalSize = {}

  if (size.width) {
    finalSize.width = isOnHighResScreen ? size.width * 2 : size.width
  }

  if (size.height) {
    finalSize.height = isOnHighResScreen ? size.height * 2 : size.height
  }

  return getThumbnailSrc(file, finalSize)
}
