import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq, colors } from 'styles-resources'
import ShadowBox from '@unowmooc/shadow-box'
import scanEffect from './scanEffect'

const Wrapper = styled(ShadowBox)`
  display: inline-block;
  background-color: ${colors.white};
`

const Content = styled.div`
  padding: 30px 46px 40px 46px;

  ${mq.xs(css`
    display: flex;
    align-items: center;
    padding: 20px;
  `)}
`

const Avatar = styled.div`
  width: 100px;
  height: 100px;
  margin: 0 auto 20px auto;
  background-color: ${colors.whisper};
  border-radius: 50%;
  ${scanEffect};

  ${mq.xs(css`
    width: 45px;
    height: 45px;
    margin: 0 10px 0 0;
    flex-shrink: 0;
  `)}
`

const LineGroup = styled.div`
  width: 100%;
`

const Line = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 15px;
  margin-bottom: 16px;
  background-color: ${colors.whisper};
  border-radius: 100px;
  ${scanEffect};

  ${mq.xs(css`
    margin-bottom: 10px;
  `)}

  &:last-child {
    margin-bottom: 0;
  }
`

const Footer = styled.div`
  padding: 20px;
  border-top: 1px solid ${colors.whisper};

  ${mq.xs(css`
    padding: 15px;
  `)}
`

const Placeholder = ({ className }) => (
  <Wrapper className={className} data-cy="trombinoscope-placeholder">
    <Content>
      <Avatar />
      <LineGroup>
        <Line />
        <Line />
      </LineGroup>
    </Content>
    <Footer>
      <Line />
    </Footer>
  </Wrapper>
)

Placeholder.defaultProps = {
  className: undefined,
}

Placeholder.propTypes = {
  className: PropTypes.string,
}

export default Placeholder
