import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { useTracking } from './tracking.hook'

const TrackingContext = React.createContext({})

const TrackingProvider = ({ children }) => {
  const tracker = useTracking()

  // Global listener for event on button
  useEffect(() => {
    const handleClick = element => {
      const trackingId = _.get(element, 'target.dataset.trackingId')
      const trackingValues = _.get(element, 'target.dataset.trackingValues')

      tracker.sendEvent(trackingId, trackingValues)
    }

    document.addEventListener('click', handleClick, true)

    return () => document.removeEventListener('click', handleClick, true)
  }, [])

  return <TrackingContext.Provider values={tracker}>{children}</TrackingContext.Provider>
}

TrackingProvider.Consumer = TrackingContext.Consumer

TrackingProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default TrackingProvider
