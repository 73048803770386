/* eslint-disable no-process-env */

export default {
  env: {
    APP_VERSION: process.env.APP_VERSION,
    AUTH_CLIENT_URL: process.env.AUTH_CLIENT_URL,
    WEBSITE_URL: process.env.WEBSITE_URL,
    ADMIN_URL: process.env.ADMIN_URL,
    ADMIN_OLD_URL: process.env.ADMIN_OLD_URL,
    GRAPHQL_API_URL: process.env.GRAPHQL_API_URL,
    FILESTACK_API_KEY: process.env.FILESTACK_API_KEY,
    ENABLE_SENTRY: JSON.parse(process.env.ENABLE_SENTRY || 'false'),
    GA_TAG: process.env.GA_TAG,
    COOKIE_DOMAIN: process.env.COOKIE_DOMAIN,
    BUILD_ENV: process.env.BUILD_ENV,
  },
  featureFlags: {
    switchUserLanguage: true,
    performanceMetrics: false,
  },
  analytics: {
    siteSpeedSampleRate: 100,
  },
  helpcenter_url: 'https://aide.unow.fr',
}
