import React from 'react'
import PropTypes from 'prop-types'
import Tooltip from '@unowmooc/tooltip'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { mq } from '@unowmooc/themes'
import CircleBackground from 'components/CircleBackground'

const InlineCounterWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 30px;
  color: ${({ theme: { colors } }) => colors.midGray};

  &:last-child {
    margin-right: 0;
  }

  ${mq.sm(css`
    display: inline-flex;
    margin-bottom: 20px;
    min-width: 170px;

    &:last-child {
      margin-right: 30px;
    }
  `)}
`

const InlineCounter = ({ background, svgSrc, tooltipContent, children }) => (
  <Tooltip content={tooltipContent}>
    <InlineCounterWrapper>
      <CircleBackground background={background}>
        <img alt="" src={svgSrc} />
      </CircleBackground>
      {children}
    </InlineCounterWrapper>
  </Tooltip>
)

InlineCounter.propTypes = {
  background: PropTypes.string.isRequired,
  svgSrc: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  tooltipContent: PropTypes.node.isRequired,
}

export default InlineCounter
