import { Promise } from 'es6-promise'

const createOnErrorDecorator = onError => form => {
  // Save original submit function
  const originalSubmit = form.submit

  // Subscribe to errors, and keep a local copy of them
  let state = {}

  const unsubscribe = form.subscribe(
    nextState => {
      state = nextState
    },
    { errors: true, submitErrors: true },
  )

  // What to do after submit
  const afterSubmit = () => {
    const { errors, submitErrors } = state

    return new Promise((resolve, reject) => {
      if (errors && Object.keys(errors).length) {
        onError(errors)
        reject(errors)
      }
      if (submitErrors && Object.keys(submitErrors).length) {
        onError(submitErrors)
        reject(submitErrors)
      }

      resolve()
    })
  }

  // Rewrite submit function
  // eslint-disable-next-line no-param-reassign
  form.submit = () => {
    const result = originalSubmit.call(form)
    if (result && typeof result.then === 'function') {
      // async
      return result.then(afterSubmit)
    }

    return afterSubmit()
  }

  return () => {
    unsubscribe()
    // eslint-disable-next-line no-param-reassign
    form.submit = originalSubmit
  }
}

export default createOnErrorDecorator
