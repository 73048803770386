import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { toInlineFragment } from 'fraql'
import { filter } from 'graphql-anywhere'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { Switch, Route, Link, Redirect } from 'react-router-dom'
import I18nProvider from '@unowmooc/i18n'
import { mockParticipation } from 'modules/participant/utils'
import PageQuery from 'components/PageQuery'
import { FormattedMessage } from 'react-intl'
import { mq } from '@unowmooc/themes'
import useIsMobile from 'hooks/useIsMobile'
import BreadcrumbRenderer from 'modules/course/components/BreadcrumbRenderer'
import { getResumeLink } from 'modules/course/course.utils'
import useMe from 'hooks/useMe'
import { ADMIN } from 'modules/user/user.constants'
import SkipLinkAnchor from 'modules/skipLink/SkipLinkAnchor'
import ProgramTimelineMenuBar from './ProgramTimelineMenuBar'
import QuizRedirect from './QuizRedirect'
import SectionPage from './section'
import QuizPage from './quiz'
import FinalExamPage from './final-exam'

const Wrapper = styled.div`
  display: flex;
  min-height: calc(100vh - 61px);
  background: ${({ theme: { colors } }) => colors.white};

  ${mq.sm(css`
    display: block;
  `)};
`

const PageWrapper = styled.div`
  max-width: 820px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 20px 20px 170px 20px;
  box-sizing: border-box;

  ${mq.sm(css`
    padding-bottom: 40px;
  `)};
`

const SectionsPage = ({ idSession, slugCourse, location: { pathname }, match: { url } }) => {
  const {
    me: { role, isLms },
  } = useMe()
  // scroll to top on route change
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  const isMobile = useIsMobile()

  return (
    <PageQuery
      force
      query={gql`
        query SectionsPage($idSession: ID!) {
          session(id: $idSession) {
            id
            identifier
            course {
              id
              slug
              title
              identifier
            }
            ${toInlineFragment(getResumeLink.fragments.session)}
            ${toInlineFragment(ProgramTimelineMenuBar.fragments.session)}
            ${toInlineFragment(SectionPage.fragments.session)}
            ${toInlineFragment(QuizPage.fragments.session)}
            ${toInlineFragment(FinalExamPage.fragments.session)}
            authenticatedParticipant {
              id
              ${toInlineFragment(ProgramTimelineMenuBar.fragments.participation)}
            }
          }
        }
      `}
      variables={{ idSession }}
      render={({ session }) => {
        const participation = session.authenticatedParticipant || mockParticipation(session)

        const mobileTitleWithLink = (
          <Link
            to={I18nProvider.getLinkRoute('/courses/{slugCourse}/{idSession}/program', {
              slugCourse,
              idSession,
            })}
            data-tracking-id="session.click_breadcrumb_session"
            data-tracking-values={JSON.stringify({
              courseIdentifier: session.course.identifier,
              sessionIdentifier: session.identifier,
            })}
          >
            {session.course.title}
          </Link>
        )

        const mobileTitleWithoutLink = <span>{session.course.title}</span>
        const mobileTitle = isLms ? mobileTitleWithoutLink : mobileTitleWithLink

        return (
          <Wrapper>
            <ProgramTimelineMenuBar
              title={isMobile ? mobileTitle : <FormattedMessage id="commons.course_menu" />}
              session={filter(ProgramTimelineMenuBar.fragments.session, session)}
              participation={filter(ProgramTimelineMenuBar.fragments.participation, participation)}
            />

            <PageWrapper id="course-section-content">
              <BreadcrumbRenderer />

              <SkipLinkAnchor
                label={<FormattedMessage id="a11y.skip_to_content" />}
                anchor="contenu-formation"
                priority={1}
              />

              <Switch>
                <Route
                  exact
                  path={url}
                  render={() => <Redirect to={getResumeLink({ session, userIsAdmin: role === ADMIN })} />}
                />
                <Route
                  path={`${url}/quiz-redirect/:slugModule`}
                  render={props => (
                    <QuizRedirect {...props} session={filter(QuizRedirect.fragments.course, session.course)} />
                  )}
                />
                <Route
                  path={`${url}/${I18nProvider.translatePath('quiz/:idModule')}`}
                  render={props => <QuizPage {...props} session={filter(QuizPage.fragments.session, session)} />}
                />
                <Route
                  path={`${url}/${I18nProvider.translatePath('final-exam')}`}
                  render={props => (
                    <FinalExamPage {...props} session={filter(FinalExamPage.fragments.session, session)} />
                  )}
                />
                <Route
                  path={`${url}/:idSection`}
                  render={props => <SectionPage {...props} session={filter(SectionPage.fragments.session, session)} />}
                />
              </Switch>
            </PageWrapper>
          </Wrapper>
        )
      }}
    />
  )
}

SectionsPage.propTypes = {
  idSession: PropTypes.string.isRequired,
  slugCourse: PropTypes.string.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default SectionsPage
