import moment from 'moment'
import _ from 'lodash'

import { getSupervisionId } from './supervisor.selectors'

export const getSupervisionTitle = ({ type, nbLicenses, displayedName }) => {
  if (displayedName) {
    return displayedName
  }
  return `${_.capitalize(type)} ${nbLicenses}`
}

export const getAvailibleSupervisions = (supervisions = []) => {
  const currentDate = moment()

  return supervisions.filter(({ project }) => {
    if (currentDate.isAfter(moment(project.expireAt).endOf('day'))) {
      return false
    }

    return project.nbAvailableLicenses > 0
  })
}

export const getUnavailibleSupervisions = (supervisions = []) => {
  const availibleSupervisions = getAvailibleSupervisions(supervisions)

  return supervisions.filter(supervision => {
    const index = availibleSupervisions.findIndex(item => getSupervisionId(item) === getSupervisionId(supervision))

    return index < 0
  })
}
