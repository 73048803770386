import React from 'react'
import PropTypes from 'prop-types'
import s from 'underscore.string'
import { withAccordion } from '@unowmooc/buttons'
import { FormattedHTMLMessage } from 'react-intl'
import { withRouter } from 'react-router-dom'
import styled from '@emotion/styled'
import { colors, transitions } from 'styles-resources'

const Icon = styled.img`
  justify-content: center;
  align-items: center;
  display: flex;
  width: 22px;
`

const StyledLink = styled.button`
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 48px;
  color: ${({ active }) => (active ? colors.black : colors.manatee)};
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: ${transitions.color};

  &:hover {
    color: ${colors.black};
    text-decoration: none;

    ${Icon} {
      opacity: 1;
    }
  }

  ${Icon} {
    width: 32px;
    height: 32px;
    filter: grayscale(${({ active }) => (active ? 0 : 100)}%);
    opacity: ${({ active }) => (active ? 1 : 0.6)};
    transition: ${transitions.opacity};
  }
`

const Label = styled.span`
  margin-left: 18px;
  white-space: nowrap;
`

const Link = ({ hidden, domain, name, icon, location: { pathname }, ...props }) => {
  if (hidden) {
    return null
  }

  const to = `/espace-superviseur/packs/`
  const active = new RegExp(`^${to}`).test(pathname)

  return (
    <StyledLink key={name} id="dropdown-button-project" active={active} {...props}>
      <Icon src={icon} alt="" />
      <Label>
        <FormattedHTMLMessage id={`menu.${domain}.${s.replaceAll(name, '-', '_')}`} />
      </Label>
    </StyledLink>
  )
}

Link.defaultProps = {
  location: undefined,
  domain: undefined,
  hidden: false,
}

Link.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  domain: PropTypes.string,
  component: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
  name: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  hidden: PropTypes.bool,
}
const Dropdown = withAccordion(withRouter(Link))

Dropdown.isDomainLayout = true

export default Dropdown
