import _ from 'lodash'
import axios from 'utils/axios'

export const importParticipants = (data, mode) => {
  if (mode === 'manuel') {
    const formData = {}
    formData.project_id = data.pack.value
    formData.session_id = data.session.value

    formData.participants = data.collaborators

    return axios.post('/import-participant/by-form', JSON.stringify(formData))
  }

  const formData = new FormData()

  formData.append('project_id', data.pack.value)
  formData.append('session_id', data.session.value)
  formData.append('file', _.first(data.file))

  return axios.post('/import-participant/by-file/validate', formData)
}

export const processParticipantsFromFile = importId => axios.post(`/import-participant/by-file/process/${importId}`)
