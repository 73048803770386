import React, { Component } from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import _ from 'lodash'
import moment from 'moment'
import SelectGraphql from 'components/SelectGraphql'
import { enrichVirtualLabel } from '../utils'

const fragment = gql`
  fragment SelectSessionFragment on Session {
    id
    identifier
    startAt
  }
`

export const renderLabel = option => `${option.identifier} - ${moment(option.startAt).format('DD/MM/YYYY')}`

class SelectSession extends Component {
  constructor(props) {
    super(props)

    this.computedProps = this.computedProps.bind(this)
  }

  computedProps(value, options) {
    const { nullableOption } = this.props

    // computing new concat label key
    const props = {
      options: _.orderBy(
        options.map(option => ({
          ...option,
          __label: renderLabel(option),
        })),
        '__label',
        'asc',
      ),
    }

    if (nullableOption) {
      props.options.unshift(nullableOption)
    }

    let computedValue = null
    if (props.options?.length === 1) {
      computedValue = enrichVirtualLabel(props.options[0], renderLabel)
    }

    if (!computedValue) {
      computedValue = options.find(option => option.id === value?.id)
    }

    if (!computedValue) {
      return props
    }

    props.value = enrichVirtualLabel(computedValue, renderLabel)

    return props
  }

  render() {
    const { nullableOption, ...props } = this.props

    return (
      <SelectGraphql
        {...props}
        computedProps={this.computedProps}
        remote
        queryName="sessions"
        labelProperty="__label"
        onSelectResetsInput={false}
        fragment={fragment}
      />
    )
  }
}

SelectSession.fragment = fragment

SelectSession.defaultProps = {
  nullableOption: null,
}

SelectSession.propTypes = {
  nullableOption: PropTypes.shape(),
}

export default SelectSession
