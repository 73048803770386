import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'
import { Row, Col } from 'react-grid-system'
import { Switch, Route, Redirect } from 'react-router-dom'
import { css } from '@emotion/core'
import I18nProvider from '@unowmooc/i18n'
import { Alert } from '@unowmooc/react-ui-kit'
import { Text } from '@unowmooc/text'
import { mq } from '@unowmooc/themes'

import { withUser } from 'store/UserProvider'
import PageQuery from 'components/PageQuery'
import Helmet from 'components/HelmetIntl'
import Tabs from 'pages/domains/courses/course/components/Tabs'
import { getPathToExpertCommentsBySessionId } from 'modules/comments/comment.utils'

import Header from './Header'
import ParticipantsPage from './participants'
import VirtualClassroomsPage from './virtualClassrooms'

const PageCol = styled(Col)`
  ${mq.sm(css`
    margin-bottom: 30px;
  `)};
`

const StyledTabs = styled(Tabs)`
  margin-bottom: 40px;
`

const StyledHeader = styled(Header)`
  margin-bottom: 50px;

  ${mq.sm(css`
    margin-top: 20px;
  `)}
`

const ViewPage = ({
  match: {
    url,
    params: { idSession },
  },
  location: { pathname },
}) => (
  <PageQuery
    query={gql`
      query SessionView($sessionId: ID!) {
        session(id: $sessionId) {
          id
          ...SessionHeader
        }
      }
      ${Header.fragment}
    `}
    variables={{ sessionId: idSession }}
    render={({ session }) => (
      <>
        <Helmet title="page_titles.expert.session.title" values={{ courseTitle: session.course.title }} />
        <StyledHeader session={session} />
        <Row>
          <Col xs={12}>
            <StyledTabs
              pathname={pathname}
              messageBaseKey="menu.expert.session_view"
              forceDesktop
              items={[
                { name: 'virtual-classrooms' },
                { name: 'participants' },
                {
                  name: 'comments',
                  label: <FormattedMessage id="menu.expert.session_view.comments" />,
                },
              ]}
            />
          </Col>
        </Row>
        <Row>
          <PageCol>
            <Switch>
              <Route
                exact
                path={`${url}/${I18nProvider.translatePath('virtual-classrooms')}`}
                render={props => <VirtualClassroomsPage sessionId={session.id} {...props} />}
              />
              <Route
                exact
                path={`${url}/${I18nProvider.translatePath('participants')}`}
                render={props => <ParticipantsPage session={session} {...props} />}
              />
              <Route
                exact
                path={`${url}/${I18nProvider.translatePath('comments')}`}
                render={() => (
                  <>
                    <Alert>
                      <Text
                        position="relative"
                        html
                        as="p"
                        i18nKey="expert.info_comment_moved"
                        values={{ href: getPathToExpertCommentsBySessionId(session.id) }}
                      />
                    </Alert>
                  </>
                )}
              />
              <Redirect to={`${url}/${I18nProvider.translatePath('virtual-classrooms')}`} />
            </Switch>
          </PageCol>
        </Row>
      </>
    )}
  />
)

ViewPage.propTypes = {
  match: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
  user: PropTypes.shape().isRequired,
}

export default withUser(ViewPage)
