import React from 'react'
import PropTypes from 'prop-types'
import { propType, filter } from 'graphql-anywhere'
import { toInlineFragment } from 'fraql'
import gql from 'graphql-tag'
import styled from '@emotion/styled'
import Bonus from './Bonus'

const Resource = styled.div`
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`

const Bonuses = ({ bonuses, className }) => (
  <div className={className}>
    {bonuses.map(bonus => (
      <Resource key={bonus.id}>
        <Bonus bonus={filter(Bonus.fragments.link, bonus)} />
      </Resource>
    ))}
  </div>
)

Bonuses.fragments = {
  link: gql`
    fragment _ on Link {
      id
      ${toInlineFragment(Bonus.fragments.link)}
    }
  `,
}

Bonuses.defaultProps = {
  className: undefined,
}

Bonuses.propTypes = {
  bonuses: PropTypes.arrayOf(propType(Bonus.fragments.link)).isRequired,
  className: PropTypes.string,
}

export default Bonuses
