import React from 'react'
import PropTypes from 'prop-types'
import ShadowBox from '@unowmooc/shadow-box'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'

const Wrapper = styled(ShadowBox)`
  align-items: center;
  padding: 29px 30px;
  text-align: center;
  background-color: ${({ theme: { colors } }) => colors.white};

  ${mq.sm(css`
    padding: 26px 30px;
  `)}
`

const Value = styled.strong`
  display: inline-block;
  margin-bottom: 10px;
  font-size: 28px;
  line-height: 32px;
  color: ${({ theme: { colors } }) => colors.shipGray};
`

const Label = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme: { colors } }) => colors.midGray};
`

const BoxCounter = ({ value, label, className }) => (
  <Wrapper className={className}>
    <Value>{value}</Value>
    <Label>{label}</Label>
  </Wrapper>
)

BoxCounter.defaultProps = {
  className: undefined,
}

BoxCounter.propTypes = {
  value: PropTypes.node.isRequired,
  label: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default BoxCounter
