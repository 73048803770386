import _ from 'lodash'
import queryString from 'query-string'

export const getModuleSelected = search => {
  const query = queryString.parse(search)

  if (!query.module) {
    return null
  }

  return _.parseInt(query.module)
}

export const flattenObject = (object, prefix = '') => {
  _.reduce(
    _.keys(object),
    (acc, key) => {
      const pre = prefix.length ? `${prefix}.` : ''

      if (_.isObject(object[key])) {
        _.assign(acc, flattenObject(object[key], pre + key))
      } else {
        acc[pre + key] = object[key]
      }

      return acc
    },
    {},
  )
}
