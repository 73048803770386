import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import svgBadge from './assets/badge.svg'

const Wrapper = styled.div`
  position: relative;
`

const Number = styled.div`
  position: absolute;
  top: 7px;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  color: ${({ theme: { colors } }) => colors.unowBluegreen};
`

const Img = styled.img``

const BadgeIllu = ({ number, className }) => (
  <Wrapper className={className}>
    <Img src={svgBadge} />
    <Number>{number}</Number>
  </Wrapper>
)

BadgeIllu.defaultProps = {
  className: undefined,
}

BadgeIllu.propTypes = {
  number: PropTypes.number.isRequired,
  className: PropTypes.string,
}

export default BadgeIllu
