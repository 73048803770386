import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import { Clickable } from '@unowmooc/buttons'
import StreamlineIcon from '@unowmooc/icons'
import { UnowAwesome } from '@unowmooc/loaders'
import { mq, rgba } from '@unowmooc/themes'
import styled from '@emotion/styled'
import { captureException } from '@sentry/browser'

const Action = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  box-sizing: border-box;
  padding: 25px 35px;
  font-size: 14px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  box-shadow: 0 2px 14px 0 ${({ theme }) => rgba(theme.colors.black, 0.12)};
`

const ActionButton = styled(Clickable)`
  margin-right: 15px;
  font-size: 24px;
  color: ${({ theme: { colors } }) => colors.mischka};

  &:hover {
    color: ${({ theme: { colors } }) => colors.manatee};
  }

  ${mq.md(css`
    margin-right: 20px;
  `)}

  &:last-child {
    margin-right: 0;
  }
`

const Feedback = styled.div`
  margin-bottom: 20px;
  text-align: center;
`

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Cancel = styled(Clickable)`
  font-size: 14px;

  &:hover {
    text-decoration: underline;
  }

  color: ${({ theme, name }) => (name === 'delete' ? theme.colors.unowGreen : theme.colors.bittersweet)};
`
const Confirm = styled(Clickable)`
  color: ${({ theme, name }) => (name === 'delete' ? theme.colors.bittersweet : theme.colors.unowGreen)};
  font-size: 14px;
  margin-left: 30px;

  &:hover {
    text-decoration: underline;
  }
`

const Loader = styled.div`
  display: flex;
  align-items: center;
`

const ActionMessage = styled.span`
  margin-right: 15px;
`

const CommentAction = ({
  icon,
  name,
  beforeAction,
  onAction,
  onCancel,
  active,
  actionLabel,
  cancelLabel,
  confirmLabel,
  messageLabel,
  executingLabel,
  className,
}) => {
  const [loading, setLoading] = useState()
  const stopLoading = () => setLoading(false)

  const toggleActive = newActive => event => {
    event.preventDefault()

    if (newActive && beforeAction) {
      beforeAction({ name })
    }

    if (!newActive && onCancel) {
      onCancel({ name })
    }
  }

  const handleConfirm = useCallback(
    async (...props) => {
      setLoading(true)

      if (onAction) {
        try {
          await onAction(...props)
        } catch (onActionError) {
          captureException(new Error(`[Comments handleConfirm: ${name}]: ${JSON.stringify(onActionError)}`))
        }
      }

      onCancel({ name })
      stopLoading()
    },
    [onAction],
  )

  return (
    <>
      {active ? (
        <Action className={className}>
          {!loading ? (
            <>
              {messageLabel && <Feedback>{messageLabel}</Feedback>}
              <ActionsWrapper>
                {onCancel && (
                  <Cancel name={name} onClick={toggleActive(false)}>
                    {cancelLabel}
                  </Cancel>
                )}
                {onAction && (
                  <Confirm name={name} onClick={handleConfirm}>
                    {confirmLabel}
                  </Confirm>
                )}
              </ActionsWrapper>
            </>
          ) : (
            <Loader>
              {executingLabel && <ActionMessage>{executingLabel}</ActionMessage>}
              <UnowAwesome size={20} />
            </Loader>
          )}
        </Action>
      ) : (
        <ActionButton className={className} onClick={toggleActive(true)} title={actionLabel}>
          <StreamlineIcon icon={icon} />
        </ActionButton>
      )}
    </>
  )
}

CommentAction.defaultProps = {
  executingLabel: undefined,
  confirmLabel: 'Are you sure ?',
  messageLabel: 'Want to ...',
  beforeAction: undefined,
  onAction: undefined,
  onCancel: undefined,
  className: undefined,
  active: false,
}

CommentAction.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  beforeAction: PropTypes.func,
  onAction: PropTypes.func,
  onCancel: PropTypes.func,
  active: PropTypes.bool,
  actionLabel: PropTypes.node.isRequired,
  cancelLabel: PropTypes.node.isRequired,
  confirmLabel: PropTypes.node,
  messageLabel: PropTypes.node,
  executingLabel: PropTypes.node,
  className: PropTypes.string,
}

export default CommentAction
