import React, { useState } from 'react'
import PropTypes from 'prop-types'
import MediaQuery from 'react-responsive'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import Avatar from '@unowmooc/avatar'
import { mq, rgba } from '@unowmooc/themes'
import { Clickable } from '@unowmooc/buttons'

const Wrapper = styled.div`
  border-radius: 4px;
  padding: 0px 25px 25px;
  margin-top: 25px;

  ${mq.xs(css`
    width: auto;
    padding-right: 20px;
    padding-left: 20px;
    border-radius: 0px 0px 4px 4px;
  `)}
`

const ButtonNotFocus = styled(Clickable)`
  width: 100%;
  padding: 15px;
  background-color: ${({ theme: { colors } }) => rgba(colors.mischka, 0.3)};
  border: ${({ theme: { colors } }) => `1px solid ${colors.mischka}`};
  border-radius: 4px;
`

const Focus = styled.div`
  position: relative;
`

const StyledAvatar = styled(Avatar)`
  position: absolute;
  top: 5px;
  left: 9px;
`

const Placeholder = styled.span`
  margin-left: 16px;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme: { colors } }) => colors.bombay};
  vertical-align: middle;

  ${mq.xs(css`
    margin-left: 0;
    text-align: center;
  `)}
`

const CommentInput = ({ avatarSrc, fullName, editor: Editor, placeholder, editorAvatarClassName }) => {
  const [focus, setFocus] = useState()

  return (
    <Wrapper>
      {!focus ? (
        <ButtonNotFocus data-cy="button-not-focus" onClick={() => setFocus(true)}>
          {fullName && (
            <MediaQuery minWidth={768}>
              <Avatar size={30} src={avatarSrc} name={fullName} />
            </MediaQuery>
          )}
          <Placeholder>{placeholder}</Placeholder>
        </ButtonNotFocus>
      ) : (
        <Focus>
          {fullName && (
            <MediaQuery minWidth={768}>
              <StyledAvatar className={editorAvatarClassName} size={30} src={avatarSrc} name={fullName} />
            </MediaQuery>
          )}
          {Editor}
        </Focus>
      )}
    </Wrapper>
  )
}

CommentInput.defaultProps = {
  avatarSrc: undefined,
  fullName: undefined,
  placeholder: 'Type your answer',
  editorAvatarClassName: undefined,
}

CommentInput.propTypes = {
  avatarSrc: PropTypes.string,
  fullName: PropTypes.string,
  editor: PropTypes.node.isRequired,
  placeholder: PropTypes.node,
  editorAvatarClassName: PropTypes.string,
}

export default CommentInput
