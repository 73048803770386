import React from 'react'
import StickyHeader from 'components/StickyHeader'
import Content from './Content'

const FormHeader = props => <StickyHeader render={({ active }) => <Content {...props} sticky={active} />} />

FormHeader.defaultProps = {
  ...Content.defaultProps,
}

FormHeader.propTypes = {
  ...Content.propTypes,
}

export default FormHeader
