import React from 'react'
import PropTypes from 'prop-types'
import { propType, filter } from 'graphql-anywhere'
import { toInlineFragment } from 'fraql'
import gql from 'graphql-tag'
import { useQuiz } from 'modules/quiz/provider'
import { QUIZ_ATTEMPT_STATUS } from 'modules/quiz/constants'
import Success from './Success'
import Failure from './Failure'

const ResultsPage = ({ participant, session, history }) => {
  const { status, remainingAttempts, getIntroductionPath } = useQuiz()

  // Si on n'a pas d'examen en cours et qu'il reste des tentatives on redirige sur la page d'intro
  if (status === null && remainingAttempts > 0 && participant.advancement.status !== 'certified') {
    history.push(getIntroductionPath())

    return null
  }

  return (
    <>
      {status === QUIZ_ATTEMPT_STATUS.SUCCESS ? (
        <Success
          participant={filter(Success.fragments.participant, participant)}
          course={filter(Success.fragments.course, session.course)}
          sessionIdentifier={session.identifier}
        />
      ) : (
        <Failure
          participant={filter(Failure.fragments.participant, participant)}
          mainAnimatorUser={
            session.mainAnimator ? filter(Failure.fragments.mainAnimatorUser, session.mainAnimator) : undefined
          }
        />
      )}
    </>
  )
}

ResultsPage.fragments = {
  participant: gql`
    fragment _ on Participant {
      id
      advancement {
        status
      }
      lastFinalExamQuizAttempt {
        id
        status
      }
      ${toInlineFragment(Failure.fragments.participant)}
      ${toInlineFragment(Success.fragments.participant)}
    }
  `,
  session: gql`
    fragment _ on Session {
      identifier
      course {
        id
        ${toInlineFragment(Success.fragments.course)}
      }
      mainAnimator {
        id
        ${toInlineFragment(Failure.fragments.mainAnimatorUser)}
      }
    }
  `,
}

ResultsPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  participant: propType(ResultsPage.fragments.participant).isRequired,
  session: propType(ResultsPage.fragments.session).isRequired,
}

export default ResultsPage
