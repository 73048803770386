import { css } from '@emotion/core'
import { colors } from 'styles-resources'
import 'quill-mention'
import Fuse from 'fuse.js'
import _ from 'lodash'

export const mentionStyle = css`
  .ql-mention-list-container {
    font-family: Ubuntu;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 13px 46px 0 rgba(0, 0, 0, 0.08);
    z-index: 9001;
  }

  .ql-mention-list {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  .ql-mention-list-item {
    cursor: pointer;
    height: 46px;
    line-height: 44px;
    font-size: 16px;
    padding: 0 15px;
    vertical-align: middle;
    white-space: nowrap;
  }

  .ql-mention-list-item.selected {
    background-color: ${colors.whisper};
    text-decoration: none;
  }

  .mention {
    border-radius: 6px;
    background-color: rgba(0, 204, 153, 0.08);
    padding: 3px 0;
    color: ${colors.unowGreen};
  }

  .mention[data-id='-1'] {
    color: #3e3e42;
  }

  .mention > span {
    margin: 0 3px;
  }
`

export const sourceMention = users => (searchTerm, renderList) => {
  const fuse = new Fuse(users, {
    keys: [
      { name: 'value', weight: 0.3 },
      { name: 'fullName', weight: 0.7 },
    ],
    shouldSort: true,
    threshold: 0.1,
  })

  if (searchTerm.length === 0) {
    renderList(_.orderBy(users, ['weight', 'fullName'], ['desc', 'asc']).splice(0, 5), searchTerm)
  } else {
    renderList(fuse.search(searchTerm).splice(0, 5), searchTerm)
  }
}
