import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { colors } from 'styles-resources'
import { useCourseContext } from 'modules/course/components/CourseContext/CourseContext'
import TagUp from './Tags/TagUp'
import TagInitial from './Tags/TagInitial'
import TagFinal from './Tags/TagFinal'
import TagEqual from './Tags/TagEqual'
import TagDown from './Tags/TagDown'
import FilterSelect from './SelectFilter'

const MATRIX_LABEL_WIDTH = 450

const RestitutionRawWrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 22px;
`

const Title = styled.h4`
  font-size: 18px;
  ${({ isQuestionMatrix }) => `margin-bottom: ${isQuestionMatrix ? 10 : 22}px;`}
`

const ColTitle = styled.th`
  width: ${MATRIX_LABEL_WIDTH}px;
  text-align: left;
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`

const Table = styled.table`
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;

  ${({ stripped }) =>
    stripped &&
    `
    tr:nth-child(odd) {
      background-color: ${colors.whiteLilac};
    }
  `}

  td {
    text-align: center;
    padding-top: 7px;
    padding-bottom: 7px;
  }

  thead ${ColTitle} {
    padding: 0;
  }
`

const ColProgression = styled.th`
  width: 44px;
  padding-left: 6px;
  text-align: left;
`

const ColChoiceLabel = styled.th`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  height: 20px;
`

const ColAnswerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  div:first-child {
    margin-bottom: 2px;
  }
`

const Radio = styled.div`
  position: relative;
  display: inline-block;
  background: #f0f0f7;
  border: 1px solid ${({ checked }) => (checked ? colors.midGray : '#dfdfe7')};
  box-sizing: border-box;
  border-radius: 11px;
  width: 22px;
  height: 22px;

  ${({ checked }) =>
    checked &&
    `&:before {
      position: absolute;
      top: 2px;
      left: 2px;
      width: 16px;
      height: 16px;
      background-color: ${colors.manatee};
      border-radius: 9px;
      content: ' ';
    }`}
`

const RestitutionRaw = ({ questionRaw, filterValue }) => {
  const { hasBfcAnswered, hasSpecificBfc } = useCourseContext()

  const questionsToDisplay = questionRaw.questions.filter(({ config }) => {
    if (filterValue === FilterSelect.values.ALL) {
      return true
    }

    if (filterValue === FilterSelect.values.UP && config.progression > 0) {
      return true
    }

    if (filterValue === FilterSelect.values.EQUAL_AND_DOWN && config.progression <= 0 && !config.notImprovable) {
      return true
    }

    return false
  })

  if (questionsToDisplay.length === 0) {
    return null
  }

  const hasProgression = questionsToDisplay.some(({ config }) => config.progression !== null)

  return (
    <RestitutionRawWrapper>
      <Title isQuestionMatrix={questionRaw.isQuestionMatrix}>{questionRaw.title}</Title>
      <Table stripped={questionRaw.isQuestionMatrix}>
        <thead>
          {hasProgression && <ColProgression />}
          {questionRaw.isQuestionMatrix && <ColTitle>&nbsp;</ColTitle>}
          {questionRaw.questions?.[0].question.choices.map(choice => (
            <ColChoiceLabel key={choice.id}>{choice.label}</ColChoiceLabel>
          ))}
        </thead>
        <tbody>
          {questionsToDisplay.map(({ question, config }) => (
            <tr key={`question_${question.id}`}>
              {hasProgression && (
                <ColProgression>
                  {config.progression > 0 && <TagUp />}
                  {config.progression === 0 && <TagEqual />}
                  {config.progression < 0 && <TagDown />}
                </ColProgression>
              )}
              {questionRaw.isQuestionMatrix && <ColTitle>{question.title}</ColTitle>}
              {question.choices.map((choice, choiceIndex) => (
                <td key={`question_${question.id}.choice_${choice.id}`}>
                  <ColAnswerContainer>
                    {choiceIndex === config.bic?.choiceIndex &&
                      (hasBfcAnswered && hasSpecificBfc ? <TagInitial /> : <Radio checked>&nbsp;</Radio>)}
                    {choiceIndex === config.bfc?.choiceIndex && <TagFinal />}
                    {![config.bic?.choiceIndex, config.bfc?.choiceIndex].includes(choiceIndex) && <Radio>&nbsp;</Radio>}
                  </ColAnswerContainer>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </RestitutionRawWrapper>
  )
}

RestitutionRaw.propTypes = {
  questionRaw: PropTypes.shape({
    title: PropTypes.string.isRequired,
    isQuestionMatrix: PropTypes.bool.isRequired,
    isQuestionSimple: PropTypes.bool.isRequired,
    questions: PropTypes.arrayOf(
      PropTypes.shape({
        question: PropTypes.shape({
          choices: PropTypes.arrayOf(
            PropTypes.shape({
              label: PropTypes.string.isRequired,
            }),
          ).isRequired,
        }).isRequired,
        config: PropTypes.shape({
          bic: PropTypes.shape({ choiceIndex: PropTypes.number.isRequired }),
          bfc: PropTypes.shape({ choiceIndex: PropTypes.number.isRequired }),
          progression: PropTypes.number,
        }).isRequired,
      }),
    ),
  }).isRequired,
  filterValue: PropTypes.string.isRequired,
}

export default RestitutionRaw
