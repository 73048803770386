import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import gql from 'graphql-tag'
import { propType } from 'graphql-anywhere'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq, colors } from '@unowmooc/themes'
import FormattedDate from 'components/FormattedDate'
import Timeline from './Timeline'
import Card from './Card'
import BadgeIllu from './BadgeIllu'
import Star from './Star'

import svgBaby from './assets/baby.svg'
import svgCertificate from './assets/certificate.svg'

const Title = styled.p`
  margin-bottom: 20px;
  font-size: 16px;
  color: ${colors.midGray};
  text-align: center;

  ${mq.xs(css`
    text-align: left;
  `)};
`

const Img = styled.img`
  height: 46px;
`

const LeftStar = styled(Star)`
  position: absolute;
  top: ${({ top }) => (top ? 22 : 0)}px;
  left: 50%;
  transform: translateX(-55px);
`

const RightStar = styled(Star)`
  position: absolute;
  top: ${({ top }) => (top ? 22 : 0)}px;
  right: 50%;
  transform: translateX(55px);
`

const getActivityBadges = participations => {
  const badges = _.flatMap(participations, 'badges')

  return _.orderBy(badges, ['createdAt'], ['desc'])
}

const Activity = ({ user, participations, className }) => (
  <div className={className}>
    <Title>
      <FormattedMessage id="profile.activity.title" />
    </Title>
    <Timeline>
      {getActivityBadges(participations).map((badge, key) => {
        const left = key % 2 === 0

        return (
          <Card
            illu={
              <>
                <LeftStar color={left ? colors.vividTangerine : colors.azalea} top={left} />
                <RightStar color={left ? colors.cornflowerBlue : colors.unowOrange} top={!left} />
                {badge.module ? <BadgeIllu number={badge.module.publicPosition + 1} /> : <Img src={svgCertificate} />}
              </>
            }
            legend={
              badge.module ? (
                <FormattedHTMLMessage
                  id="profile.activity.module_badge_won"
                  values={{ moduleNumber: badge.module.publicPosition + 1, courseTitle: badge.module.course.title }}
                />
              ) : (
                <FormattedHTMLMessage
                  id="profile.activity.certificate"
                  values={{ courseTitle: badge.session.course.title }}
                />
              )
            }
            date={<FormattedDate value={moment(badge.createdAt)} year="numeric" month="long" />}
            key={badge.id}
          />
        )
      })}
      <Card
        illu={<Img src={svgBaby} />}
        legend={<FormattedHTMLMessage id="profile.activity.creation" />}
        date={<FormattedDate value={moment(user.createdAt)} year="numeric" month="long" />}
        key="creation"
      />
    </Timeline>
  </div>
)

Activity.fragments = {
  user: gql`
    fragment _ on User {
      createdAt
    }
  `,
  participation: gql`
    fragment _ on Participant {
      id
      badges {
        id
        createdAt
        session {
          id
          course {
            id
            title
          }
        }
        module {
          id
          publicPosition
          course {
            id
            title
          }
        }
      }
    }
  `,
}

Activity.defaultProps = {
  className: undefined,
}

Activity.propTypes = {
  user: propType(Activity.fragments.user).isRequired,
  participations: PropTypes.arrayOf(propType(Activity.fragments.participation)).isRequired,
  className: PropTypes.string,
}

export default Activity
