import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { ButtonInput } from '@unowmooc/buttons'
import { FieldLayout } from '@unowmooc/react-ui-kit'

const handleClick = field => () => {
  document.getElementById(field).select()
  document.execCommand('copy')
}

const FieldInputCopy = ({ courseId, value, field }) => (
  <FieldLayout label={<FormattedMessage id={`final_exam.results.success.form.fields.${field}`} />}>
    <ButtonInput
      labelI18nKey="commons.copy"
      activeLabel={<FormattedMessage id="commons.copied" />}
      value={value}
      inputProps={{
        id: `${courseId}-${field}`,
        // Allow text selection before copied to clipboard
        readOnly: true,
      }}
      onClick={handleClick(`${courseId}-${field}`)}
    />
  </FieldLayout>
)

FieldInputCopy.propTypes = {
  value: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  courseId: PropTypes.string.isRequired,
}

export default FieldInputCopy
