import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'
import { Clickable } from '@unowmooc/react-ui-kit'
import StreamlineIcon from '@unowmooc/icons'
import FileStackUploader from 'components/FileStackUploader'
import FilePreview from 'components/FilePreview'
import { colors } from 'styles-resources'

const PaperclipIcon = styled(StreamlineIcon)`
  position: relative;
  top: -1px;
  margin-right: 4px;
  color: ${colors.midGray};
`

const AttachFileButton = styled(Clickable)`
  font-size: 14px;
  color: ${colors.unowGreen};

  &:hover {
    color: ${colors.unowBluegreen};
  }
`

const InputFile = ({ value, onChange, className }) => {
  const fileInputRef = useRef(null)

  return (
    <FileStackUploader
      onChange={onChange}
      render={({ onChange: onFileChange, progress, error }) =>
        value ? (
          <FilePreview
            file={{ ...value, url: value.preview }}
            progress={progress}
            error={error}
            onDelete={() => {
              onChange(null)
            }}
          />
        ) : (
          <>
            <input
              ref={fileInputRef}
              type="file"
              onChange={event => {
                const file = _.head(event.target.files)

                // show preview
                const filePreview = { filename: file.name, preview: URL.createObjectURL(file), mimeType: file.type }
                onChange(filePreview)

                // start uploading
                onFileChange(event.target.files)
              }}
              style={{ display: 'none' }}
            />
            <AttachFileButton
              onClick={() => {
                fileInputRef.current.click()
              }}
              className={className}
            >
              <PaperclipIcon icon="paperclip" />
              <FormattedMessage id="comment.attach_file" />
            </AttachFileButton>
          </>
        )
      }
    />
  )
}

InputFile.defaultProps = {
  value: undefined,
  className: undefined,
}

InputFile.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape(),
  className: PropTypes.string,
}

export default InputFile
