import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'
import { RadioGroup, CheckboxGroup } from '@unowmooc/inputs'
import { useQuiz } from '../../../provider'
import { QUIZ_QUESTION_ATTEMPT_STATUS } from '../../../constants'

const isError = (choice, value) => {
  if (Array.isArray(value)) {
    return !choice.isCorrect && value.some(({ id }) => id === choice.id)
  }

  return !choice.isCorrect && choice.id === value.id
}

const Label = styled.div`
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 25px;
  color: ${({ disabled, correct, error, theme: { colors } }) =>
    (correct && colors.unowGreen) || (error && colors.bittersweet) || (disabled && colors.mischka) || colors.woodsmoke};

  ${mq.sm(css`
    font-size: 16px;
    line-height: 25px;
  `)};

  > p {
    font-size: 16px;
    line-height: 25px;
    color: ${({ disabled, correct, error, theme: { colors } }) =>
      (correct && colors.unowGreen) ||
      (error && colors.bittersweet) ||
      (disabled && colors.mischka) ||
      colors.woodsmoke};

    ${mq.sm(css`
      font-size: 16px;
      line-height: 25px;
    `)};
  }
`

const Choices = ({ className }) => {
  const { currentQuestion, pendingAnswer, updatePendingAnswer } = useQuiz()
  const displayAnswer = currentQuestion.status !== QUIZ_QUESTION_ATTEMPT_STATUS.CREATED
  const multi = currentQuestion.quizQuestion.hasManyValidChoices

  const options = currentQuestion.quizQuestion.choices.map(choice => ({
    id: choice.id,
    label: (
      <Label
        dangerouslySetInnerHTML={{ __html: choice.text }}
        disabled={displayAnswer}
        correct={displayAnswer && choice.isCorrect}
        error={displayAnswer && isError(choice, pendingAnswer)}
      />
    ),
  }))

  const onChangeMulti = newValue => {
    updatePendingAnswer(newValue.length === 0 ? null : newValue)
  }

  const onChangeSingle = newValue => {
    updatePendingAnswer(newValue)
  }

  return (
    <div className={className}>
      {multi ? (
        <CheckboxGroup
          inline={false}
          disabled={displayAnswer}
          value={pendingAnswer || []}
          onChange={onChangeMulti}
          options={options}
          name="answer"
        />
      ) : (
        <RadioGroup
          inline={false}
          disabled={displayAnswer}
          value={pendingAnswer}
          name="answer"
          onChange={onChangeSingle}
          options={options}
        />
      )}
    </div>
  )
}

Choices.defaultProps = {
  className: undefined,
}

Choices.propTypes = {
  className: PropTypes.string,
}

export default Choices
