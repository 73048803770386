import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Query } from 'react-apollo'
import _ from 'lodash'

import { advancementStatus as status } from 'business/participant'
import Pagination from './components/Pagination'
import { withGridGraphqlQuery } from './gridGraphqlQuery.hoc'

export class DataGridGraphQL extends PureComponent {
  static getDerivedStateFromProps({ filters, location, limit }) {
    const searchParams = new URLSearchParams(location.search)
    let advancement

    if (searchParams.has('advancementStatus')) {
      advancement = decodeURIComponent(searchParams.get('advancementStatus'))
    }

    const graphqlFilters = _.reduce(
      {
        ...(JSON.parse(searchParams.get('filters')) || filters),
      },
      (accumulator, value, key) => {
        if (_.isObject(value)) {
          return {
            ...accumulator,
            [key]: _.has(value, 'id') ? value.id : _.omit(value, ['label']),
          }
        }

        return {
          ...accumulator,
          [key]: value,
        }
      },
      {},
    )

    return {
      filters: graphqlFilters,
      search: searchParams.get('search'),
      advancementStatus: (advancement && advancement.split('+')) || [],
      offset: parseInt(searchParams.get('pageIndex') || 0, 10) * limit,
    }
  }

  constructor(props) {
    super(props)

    this.state = {}
  }

  onPageChange = ({ selected }) => {
    const { history, location } = this.props

    const searchParams = new URLSearchParams(decodeURIComponent(location.search))

    searchParams.set('pageIndex', selected)

    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    })

    window.scrollTo(0, 0)
  }

  render() {
    const { search, filters, advancementStatus, offset } = this.state
    const { query, typeName, limit, sort, pollInterval, ...rest } = this.props

    return (
      <Query
        query={query}
        variables={{
          search,
          offset,
          limit,
          sort,
          filters: JSON.stringify(
            _.reduce(
              status,
              (acc, value) => {
                if (!advancementStatus.length) {
                  return acc
                }

                if (_.includes(advancementStatus, value)) {
                  acc.advancementStatus = { eq: value }
                }

                return acc
              },
              filters || {},
            ),
          ),
        }}
        pollInterval={pollInterval}
        fetchPolicy="no-cache"
      >
        {({ loading, data }) => (
          <Pagination
            {...rest}
            loading={loading}
            data={_.get(data, 'dataGridRows.items', [])}
            metadata={_.get(data, 'dataGridRows._meta', { total: 0 })}
            limit={limit}
            pageIndex={offset / limit}
            onPageChange={this.onPageChange}
            typeName={typeName}
          />
        )}
      </Query>
    )
  }
}

DataGridGraphQL.defaultProps = {
  limit: 15,
  filters: {},
  sort: [],
  pollInterval: undefined,
}

DataGridGraphQL.propTypes = {
  limit: PropTypes.number,
  filters: PropTypes.shape(),
  sort: PropTypes.arrayOf(PropTypes.string),
  pollInterval: PropTypes.number,
  query: PropTypes.shape().isRequired,
  typeName: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
    pathname: PropTypes.string,
  }).isRequired,
}

export default withRouter(withGridGraphqlQuery(DataGridGraphQL))
