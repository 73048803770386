import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

const Wrapper = ({ onInit, className }) => {
  const ref = useRef()

  useEffect(() => {
    onInit(ref.current)
  }, [])

  return <div ref={ref} className={className} />
}

Wrapper.defaultProps = {
  className: undefined,
}

Wrapper.propTypes = {
  onInit: PropTypes.func.isRequired,
  className: PropTypes.string,
}

export default Wrapper
