import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import StreamlineIcon from '@unowmooc/icons'
import styled from '@emotion/styled'

const StarButton = styled.button`
  cursor: pointer;
  background: none;
  border: 0;
  outline: 0;
  padding: 0 15px;
  font-size: 24px;
  color: ${({ theme: { colors } }) => colors.unowGreen};

  @media (max-width: 480px) {
    padding: 0 4px;
    font-size: 16px;
  }
`

const Rating = ({ options, value: optionValue, onChange }) => {
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [displayStarIndex, setDisplayStarIndex] = useState(-1)

  const getIndexFromValue = value => options.findIndex(option => option.value === value)

  useEffect(() => {
    setSelectedIndex(getIndexFromValue(optionValue?.value))
  }, [optionValue])

  useEffect(() => {
    setDisplayStarIndex(selectedIndex)
  }, [selectedIndex])

  const handleChange = option => {
    onChange(option)
  }

  return (
    <>
      {options.map((option, index) => (
        <StarButton
          key={option.value}
          onClick={() => handleChange(option)}
          type="button"
          onMouseEnter={() => setDisplayStarIndex(index)}
          onMouseLeave={() => setDisplayStarIndex(selectedIndex)}
        >
          <StreamlineIcon icon={`star-${index <= displayStarIndex ? 'full' : 'empty'}`} />
        </StarButton>
      ))}
    </>
  )
}

Rating.defaultProps = {
  value: null,
}

Rating.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    value: PropTypes.string.isRequired,
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
}

export default Rating
