import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'

export default styled.div`
  display: flex;
  margin-bottom: 56px;
  flex-direction: row;

  ${mq.sm(css`
    margin-top: 36px;
    flex-direction: column;
  `)};
`
