import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import I18nProvider from '@unowmooc/i18n'
import Select from 'components/Select'

const advancementStatus = ['up_to_date', 'not_started', 'late', 'finisher_not_certified', 'certified', 'canceled']

const SelectAdvancementStatus = ({ placeholder, ...props }) => (
  <Select
    options={advancementStatus.map(status => ({
      value: status,
      label: I18nProvider.formatMessage({ id: `select.advancement_status.values.${status}` }),
    }))}
    placeholder={placeholder || <FormattedMessage id="select.advancement_status.placeholder" />}
    {...props}
  />
)

SelectAdvancementStatus.defaultProps = {
  placeholder: undefined,
}

SelectAdvancementStatus.propTypes = {
  placeholder: PropTypes.node,
}

export default SelectAdvancementStatus
