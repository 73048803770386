import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { StreamlineIcon } from '@unowmooc/react-ui-kit'
import { Clickable } from '@unowmooc/buttons'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'
import { colors } from 'styles-resources'
import { animateScrollTo } from 'utils/scroll'
import useScrollPosition from 'hooks/useScrollPosition'
import { FormattedMessage } from 'react-intl'

const Label = styled.div`
  margin-top: 16px;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 1px;
  text-transform: uppercase;
`

const Button = styled(Clickable)`
  z-index: 1;
  position: fixed;
  bottom: 100px;
  right: 20px;
  width: 64px;
  height: 64px;
  background: ${colors.white};
  border: 1px solid ${colors.bombay};
  border-radius: 50%;
  text-align: center;
  opacity: ${({ isDisplay }) => (isDisplay ? 1 : 0)};
  color: ${colors.shipGray};
  transition: background-color 0.5s ease, opacity 0.2s ease;

  ${mq.sm(css`
    bottom: 20px;
  `)};

  &:hover,
  &:active {
    transition: background-color 0.2s ease;
    background: #c1eee5;
    border: none;
    color: #006149;
  }
`

const Icon = styled(StreamlineIcon)`
  position: absolute;
  top: -7px;
  left: 0px;
`

const IconContainer = styled.div`
  position: relative;
  width: 20px;
  margin: auto;
  font-size: 20px;

  ${Icon} + ${Icon} {
    top: -2px;
  }
`

function ScrollToTopButton({ dataTrackingId, dataTrackingValues }) {
  const [scrollPosition, lastScrollPosition] = useScrollPosition()
  const [isDisplay, setIsDisplay] = useState(false)

  useEffect(() => {
    const contentTop = document.querySelector('#course-section-content')?.getBoundingClientRect().top || 0

    setIsDisplay(contentTop < 0 && lastScrollPosition > scrollPosition)
  }, [scrollPosition])

  const handleClick = () => {
    animateScrollTo(document.body)
  }

  return (
    <Button
      onClick={handleClick}
      isDisplay={isDisplay}
      data-tracking-id={dataTrackingId}
      data-tracking-values={dataTrackingValues}
    >
      <IconContainer>
        <Icon icon="up" />
        <Icon icon="up" />
      </IconContainer>
      <Label>
        <FormattedMessage id="commons.scroll_to_top" />
      </Label>
    </Button>
  )
}

ScrollToTopButton.defaultProps = {
  dataTrackingId: null,
  dataTrackingValues: null,
}

ScrollToTopButton.propTypes = {
  dataTrackingId: PropTypes.string,
  dataTrackingValues: PropTypes.string,
}

export default ScrollToTopButton
