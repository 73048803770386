import React, { useState } from 'react'
import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl'
import PageLoader from 'components/PageLoader'
import { useQuiz } from 'modules/quiz/provider'
import Form from './Form'

const Title = styled.strong`
  display: block;
  margin-bottom: 15px;
  font-size: 11px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${({ theme: { colors } }) => colors.shipGray};
`

const Description = styled.h3`
  line-height: 32px;
`

const ConfirmIdentityPage = () => {
  const [loading, updateLoading] = useState(false)
  const { startQuiz } = useQuiz()

  const onFormSubmit = async () => {
    updateLoading(true)
    await startQuiz()
  }

  return loading ? (
    <PageLoader />
  ) : (
    <>
      <Title>
        <FormattedMessage id="final_exam.confirm_identity.title" />
      </Title>
      <Description>
        <FormattedMessage id="final_exam.confirm_identity.description" />
      </Description>
      <Form onSubmit={onFormSubmit} />
    </>
  )
}

export default ConfirmIdentityPage
