import { DateTimePicker } from '@unowmooc/react-ui-kit'
import { OnChange } from 'react-final-form-listeners'
import moment from 'moment/moment'
import React, { useState } from 'react'
import I18nProvider from '@unowmooc/i18n'
import { realDate as realDateValidator } from 'business/validators'
import Field from 'components/FieldIntl'

import Fieldset from 'components/FieldsetIntl'
import PropTypes from 'prop-types'
import CustomCalendarPicker from './CustomCalendarPicker'

export const dateShouldBeInFuture = value => {
  if (!value) {
    return undefined
  }

  const now = moment()

  if (moment(value) < now) {
    return `${I18nProvider.formatMessage({ id: 'vc_input.edit_sequence.errors.past' })}`
  }

  return undefined
}

const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)

const EditVirtualClassroomSequence = ({ form, item }) => {
  const [maDate, setMadate] = useState(null)

  const onChangeEndDateHandler = value => {
    setMadate(value)
  }

  return (
    <Fieldset
      legend={`${I18nProvider.formatMessage({ id: 'vc_input.edit_sequence.title' })} #${item.sequenceNumber} : ${
        item.title
      }`}
    >
      <Field name={`vc[number${item.sequenceNumber}]sequence`} initialValue={item.sequenceNumber}>
        {({ input }) => (
          <>
            <input {...input} type="hidden" />
          </>
        )}
      </Field>
      <Field
        name={`vc[number${item.sequenceNumber}]start`}
        label="vc_input.edit_sequence.start_date_and_start_time"
        validate={composeValidators(realDateValidator, dateShouldBeInFuture)}
        required
      >
        <DateTimePicker />
      </Field>
      <Field
        name={`vc[number${item.sequenceNumber}]end`}
        label="vc_input.edit_sequence.end_time"
        validate={realDateValidator}
        required
      >
        <CustomCalendarPicker
          forcedValue={maDate}
          value={maDate}
          icon="clock"
          placeholder="12:00"
          type="time"
          secondaryOnChange={onChangeEndDateHandler}
        />
      </Field>
      <OnChange name={`vc[number${item.sequenceNumber}]start`}>
        {value => {
          if (moment(value).isValid()) {
            const newDate = moment(value).add(1, 'hour')
            form.change(`vc[number${item.sequenceNumber}]end`, newDate)
            setMadate(newDate.format())
          }
        }}
      </OnChange>
    </Fieldset>
  )
}

EditVirtualClassroomSequence.propTypes = {
  form: PropTypes.shape().isRequired,
  item: PropTypes.shape().isRequired,
}

export default EditVirtualClassroomSequence
