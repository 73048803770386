import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { HamburgerButton, Clickable } from '@unowmooc/buttons'
import { screens } from '@unowmooc/themes'
import { useMediaQuery } from 'react-responsive'
import MobileToggleBar from './MobileToggleBar'
import SlideTransition from './SlideTransition'
import ToggleTransition from './ToggleTransition'
import Item, { SubItem } from './Item'
import StarItem from './StarItem'

const Timeline = styled.div`
  display: flex;
  height: 100%;
`

const StyledToggleTransition = styled(ToggleTransition)`
  height: 100%;
`

const Gradient = styled.div`
  width: 48px;
  flex-shrink: 0;
  background: ${({ theme: { colors } }) =>
    `linear-gradient(180deg, ${colors.unowGreen} 0%, ${colors.unowBluegreen} 100%)`};
`

const ClickableBar = styled(Clickable)`
  position: absolute;
  z-index: 1;
  top: 0;
  left: -48px;
  width: 48px;
  height: 100%;
`

const Items = styled.div`
  position: relative;
  width: 100%;
`

const ItemWrapper = styled.div`
  position: relative;
`

const Line = styled.div`
  position: absolute;
  top: 32px;
  left: -25px;
  width: 2px;
  background-color: ${({ theme: { colors } }) => colors.white};
`

const StyledHamburgerButton = styled(HamburgerButton)`
  position: ${({ hasTitle }) => (hasTitle ? 'absolute' : 'relative')};
  top: ${({ hasTitle }) => (hasTitle ? 14 : 0)}px;
  left: -34px;
  transform: scale(1.2);
  outline-offset: 4px;
`

const MenuText = styled.strong`
  display: block;
  position: absolute;
  top: 42px;
  left: -48px;
  width: 48px;
  text-align: center;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: ${({ theme: { colors } }) => colors.white};
  transition: opacity 0.2s ease-in-out;
  opacity: ${({ open }) => (open ? 0 : 1)};
`

const Title = styled.h4`
  padding: 10px 15px;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 14px;
  line-height: 20px;
`

const TimelineMenu = ({ onToggle, title, open, children, className }) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${screens.smMax})`,
  })

  return (
    <SlideTransition in={open || isMobile} className={className}>
      {onToggle && isMobile && (
        <MobileToggleBar
          active={open}
          title={title}
          onClick={() => {
            onToggle({ open: !open })
          }}
        />
      )}
      <StyledToggleTransition in={!onToggle || !isMobile || open}>
        <Timeline>
          <Gradient />
          <Items>
            {onToggle && !isMobile && (
              <>
                <StyledHamburgerButton
                  hasTitle={!!title}
                  color="white"
                  active={open}
                  onClick={() => {
                    onToggle({ open: !open })
                  }}
                />
                <MenuText open={open}>Menu</MenuText>
                <ClickableBar
                  // La barre gauche de la timeline est cliquable. On désactive la navigation au clavier sur cet élément.
                  // La croix 'StyledHamburgerButton' définit plus haut permet déjà de fermer le menu.
                  tabIndex={-1}
                  onClick={() => {
                    onToggle({ open: !open })
                  }}
                />
              </>
            )}
            {!isMobile && title && <Title>{title}</Title>}

            {React.Children.map(
              children,
              (child, key) =>
                child && (
                  <ItemWrapper key={child.props.id || key}>
                    {typeof child.props.progression !== 'undefined' && (
                      <Line style={{ height: `${child.props.progression}%` }} />
                    )}
                    {child}
                  </ItemWrapper>
                ),
            )}
          </Items>
        </Timeline>
      </StyledToggleTransition>
    </SlideTransition>
  )
}

TimelineMenu.defaultProps = {
  title: undefined,
  open: true,
  onToggle: undefined,
  className: undefined,
}

TimelineMenu.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  title: PropTypes.node,
  open: PropTypes.bool,
  onToggle: PropTypes.func,
  className: PropTypes.string,
}

export { Item, SubItem, StarItem }

export default TimelineMenu
