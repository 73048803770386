import { Component } from 'react'
import PropTypes from 'prop-types'
import config from 'config'

const convertOldUrlToNewUrl = ({ pathname, search, hash }, params) => {
  // Par défaut on redirige l'utilisateur sur le tableau de bord
  let newUrl = '/'
  let newSearch = search

  if (pathname.match(/^\/inscription\/sessions\/.*$/)) {
    // register to a session with direct link
    newUrl = `${config.env.AUTH_CLIENT_URL}/inscription-session/${params.token}`
  } else if (pathname.match(/^\/completer-mon-inscription\/[^/]*\/.*$/)) {
    // validate your registration to a session with a confirmation
    newUrl = `${config.env.AUTH_CLIENT_URL}/inscription-session/${params.token}`
    newSearch = newSearch ? `?userToken=${params.confirmationToken}` : `${search}&userToken=${params.confirmationToken}`
  } else if (pathname.match(/^\/valider-mon-invitation\/.*$/)) {
    // validate your new admin or expert account
    newUrl = `${config.env.AUTH_CLIENT_URL}${pathname}`
  } else if (pathname.match(/^\/changer-le-mot-de-passe\/.*$/)) {
    // reset password from link received by email
    newUrl = `${config.env.AUTH_CLIENT_URL}/mot-de-passe-oublie`
  } else if (pathname.match(/^\/connexion$/)) {
    // login link
    newUrl = config.env.AUTH_CLIENT_URL
  } else if (pathname.match(/^\/deconnexion$/)) {
    // logout link
    newUrl = `${config.env.AUTH_CLIENT_URL}/logout`
  } else if (pathname.match(/^\/inscription\/sessions\/.*$/)) {
    newUrl = `${config.env.AUTH_CLIENT_URL}/logout`
  } else if (pathname.match(/^\/admin/)) {
    newUrl = config.env.ADMIN_OLD_URL

    if (params && params.splat) {
      newUrl += `/${params.splat}`
    }
  } else {
    const matchSessionPattern = pathname.match(/^\/sessions\/(?<sessionId>\d+).*$/)
    if (matchSessionPattern) {
      newUrl = `/formations/formation/${matchSessionPattern.groups.sessionId}`
    }
  }

  // append search query and hash
  return `${newUrl}${newSearch}${hash}`
}

class OldRoutesRedirect extends Component {
  componentWillMount() {
    const {
      location,
      match: { params },
    } = this.props

    window.location.href = convertOldUrlToNewUrl(location, params)
  }

  render() {
    return null
  }
}

OldRoutesRedirect.propTypes = {
  location: PropTypes.shape().isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape().isRequired,
  }).isRequired,
}

export default OldRoutesRedirect
