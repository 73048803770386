import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import Proton from 'proton-engine'
import RAFManager from 'raf-manager'
import Wrapper from './Wrapper'

const colors = ['#8A82D6', '#FFDF7D', '#5FDCCD', '#CDA4F3', '#FF9480', '#C7E87D', '#FFB10F', '#FF6464']

const createEmitter = () => {
  const emitter = new Proton.Emitter()

  emitter.rate = new Proton.Rate(Proton.getSpan(2, 2), Proton.getSpan(0.1, 0.25))

  emitter.addInitialize(new Proton.Mass(1))
  emitter.addInitialize(new Proton.Radius(6, 8))
  emitter.addInitialize(new Proton.Life(2, 4))
  emitter.addInitialize(new Proton.Velocity(new Proton.Span(1.5, 3), new Proton.Span(100, 1360), 'polar'))

  emitter.addBehaviour(new Proton.Alpha(1, 0))
  emitter.addBehaviour(new Proton.Scale(1, 0))
  emitter.addBehaviour(new Proton.Color(colors))

  return emitter
}

const ParticlesBackground = ({ className }) => {
  let proton = null

  const renderProton = () => {
    if (proton) {
      proton.update()
    }
  }

  const onInit = useCallback(element => {
    const emitter = createEmitter()

    emitter.emit()

    proton = new Proton()

    proton.addEmitter(emitter)

    const renderer = new Proton.DomRenderer(element)
    proton.addRenderer(renderer)

    RAFManager.add(renderProton)
  }, [])

  useEffect(
    () => () => {
      try {
        RAFManager.remove(renderProton)
        proton.destroy()
      } catch (e) {} // eslint-disable-line no-empty
    },
    [],
  )

  return <Wrapper onInit={onInit} className={className} />
}

ParticlesBackground.defaultProps = {
  className: undefined,
}

ParticlesBackground.propTypes = {
  className: PropTypes.string,
}

export default ParticlesBackground
