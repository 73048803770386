import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedHTMLMessage } from 'react-intl'
import styled from '@emotion/styled'
import classnames from 'classnames'
import { Dropdown, DropdownMenu, DropdownMenuLink } from '@unowmooc/react-ui-kit'
import Target from './Target'
import SupportContactModal from './SupportContactModal'

const StyledDropdownMenu = styled(DropdownMenu)`
  right: 5px;
`

const StyledDropdownMenuLink = styled(DropdownMenuLink)`
  padding: 14px 20px;
  line-height: 16px;
  color: ${({ theme: { colors } }) => colors.manatee};
  text-align: right;

  &:hover {
    color: ${({ theme: { colors } }) => colors.unowGreen};
    text-decoration: none;
  }
`

const Content = styled.div`
  display: flex;
  align-items: center;
`

const HelpDropdown = ({ className }) => {
  const [supportContactModalActive, setSupportContactModalActive] = useState(false)

  const handleClickSupportContact = () => {
    setSupportContactModalActive(true)
  }

  const handleCloseSupportContactModal = () => {
    setSupportContactModalActive(false)
  }

  return (
    <>
      {supportContactModalActive && <SupportContactModal onClose={handleCloseSupportContactModal} />}
      <Dropdown
        render={({ active, open }) => (
          <Target
            active={active}
            onClick={open}
            data-cy="helper-dropdown"
            data-tracking-id="header_menu.click_on_help_menu_item"
          />
        )}
        className={classnames('helper-dropdown', className)}
      >
        <StyledDropdownMenu>
          <StyledDropdownMenuLink
            tag="a"
            target="help_website"
            href="https://aide.unow.fr"
            data-tracking-id="header_menu.click_on_help_center_button_link"
          >
            <Content>
              <FormattedHTMLMessage id="courses.course.menu_top.contacts.heading.help_center" />
            </Content>
          </StyledDropdownMenuLink>
          <StyledDropdownMenuLink
            onClick={handleClickSupportContact}
            data-tracking-id="header_menu.click_on_writing_to_support_button_link"
          >
            <Content>
              <FormattedHTMLMessage id="courses.course.menu_top.contacts.button.support" />
            </Content>
          </StyledDropdownMenuLink>
          <StyledDropdownMenuLink
            tag="a"
            target="procedure_rules_website"
            href="https://www.unow.fr/reglement-interieur/"
            data-tracking-id="header_menu.click_on_procedure_rules_button_link"
          >
            <Content>
              <FormattedHTMLMessage id="courses.course.menu_top.contacts.heading.procedure_rules" />
            </Content>
          </StyledDropdownMenuLink>
        </StyledDropdownMenu>
      </Dropdown>
    </>
  )
}

HelpDropdown.defaultProps = {
  className: undefined,
}

HelpDropdown.propTypes = {
  className: PropTypes.string,
}

export default HelpDropdown
