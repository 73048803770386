import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import StreamlineIcon from '@unowmooc/icons'
import { ButtonI18n } from '@unowmooc/buttons'
import Modal from 'components/Modal'
import { mq } from '@unowmooc/themes'
import { css } from '@emotion/core'
import { Text } from '@unowmooc/text'
import Tag from '@unowmooc/tags'
import { UnowAwesome } from '@unowmooc/loaders'
import I18nProvider from '@unowmooc/i18n'

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${mq.sm(css`
    flex-direction: column-reverse;
    & > button:first-child {
      margin-top: 20px;
    }
  `)};
`

const ForwardIcon = styled(StreamlineIcon)`
  font-size: 24px;
  color: #dfdfe7;
  cursor: pointer;
  &:hover {
    color: #6b6b72;
  }
`

const ForwardBox = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
`

const ForwardModal = ({ onClose, onConfirm, loading, gender }) => (
  <Modal>
    <Text
      fontSize="16px"
      lineHeight="25px"
      paddingBottom="20px"
      i18nKey="conversation.forward.explanation_modal"
      values={{ gender }}
    />
    <Text fontSize="16px" lineHeight="25px" paddingBottom="30px" i18nKey="conversation.forward.explanation_2_modal" />
    <Footer>
      <ButtonI18n i18nKey="commons.cancel" type="button" variant="secondary" onClick={onClose} disabled={loading} />
      <ButtonI18n i18nKey="commons.confirm" type="button" variant="primary" onClick={onConfirm} loading={loading} />
    </Footer>
  </Modal>
)

const ForwardButton = ({ onSubmit, loading, isForwarded, gender }) => {
  const [hasModal, setHasModal] = useState(false)

  const handleClick = () => {
    onSubmit()
    if (loading === false) {
      setHasModal(false)
    }
  }

  if (isForwarded) {
    return (
      <ForwardBox>
        <Tag theme="lightHitPink" small>
          <Text fontSize="11px" i18nKey="conversation.forward.forwarded_tag" />
        </Tag>
      </ForwardBox>
    )
  }

  return (
    <ForwardBox>
      {loading ? (
        <UnowAwesome size={20} />
      ) : (
        <ForwardIcon
          title={I18nProvider.formatMessage({ id: `comment.actions.forward` })}
          icon="forward"
          onClick={() => {
            setHasModal(true)
          }}
        />
      )}
      {hasModal && (
        <ForwardModal onClose={() => setHasModal(false)} onConfirm={handleClick} loading={loading} gender={gender} />
      )}
    </ForwardBox>
  )
}

ForwardModal.defaultProps = {
  loading: false,
}

ForwardModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  gender: PropTypes.string.isRequired,
}

ForwardButton.defaultProps = {
  loading: false,
  isForwarded: false,
}

ForwardButton.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  isForwarded: PropTypes.bool,
  gender: PropTypes.string.isRequired,
}

export default ForwardButton
