import parse from 'url-parse'

export const formatUrlWithoutHttps = urlToFormat => {
  if (!urlToFormat) {
    return urlToFormat
  }

  const urlElement = parse(urlToFormat, {}, true)

  urlElement.protocol = 'https:'
  urlElement.slashes = true

  return urlElement.toString()
}
