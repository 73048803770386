import React, { useContext } from 'react'
import PropTypes from 'prop-types'

const ThreadContext = React.createContext({
  isFormDirtyAndValid: false,
  setIsFormDirtyAndValid: () => {},
})

export const ThreadContextProvider = ({ children }) => {
  const [isFormDirtyAndValid, setIsFormDirtyAndValid] = React.useState(false)

  return (
    <ThreadContext.Provider value={{ isFormDirtyAndValid, setIsFormDirtyAndValid }}>{children}</ThreadContext.Provider>
  )
}

export const useThreadContext = () => useContext(ThreadContext)

ThreadContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ThreadContext
