import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { FormattedHTMLMessage, injectIntl } from 'react-intl'
import styled from '@emotion/styled'
import { colors } from '@unowmooc/themes'

const FontStyle = styled.span`
  color: ${colors.woodsmoke};
  line-height: 24px;
`

const DateInterval = ({ intl: { formatDate, formatTime }, startDate }) => (
  <FontStyle>
    <FormattedHTMLMessage
      id="virtual_classroom.date_interval_start"
      values={{
        date: formatDate(moment(startDate), {
          timeZone: 'Europe/Paris',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          weekday: 'long',
        }),
        startHour: formatTime(moment(startDate), { timeZone: 'Europe/Paris', hour: '2-digit', minute: '2-digit' }),
      }}
    />{' '}
    (<FormattedHTMLMessage id="commons.paris_time" />)
  </FontStyle>
)

DateInterval.propTypes = {
  intl: PropTypes.shape({
    formatDate: PropTypes.func.isRequired,
    formatTime: PropTypes.func.isRequired,
  }).isRequired,
  startDate: PropTypes.string.isRequired,
}

export default injectIntl(DateInterval)
