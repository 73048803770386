import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'

const VirtualClassroomList = styled.ul`
  margin: 0;
  padding-left: 16px;
`
const VirtualClassroomListItem = styled.li`
  list-style: disc;
`

const VirtualClassroomsBySequence = ({ virtualClassrooms }) => {
  const virtualClassroomsOrderedByStartDate = virtualClassrooms.sort((a, b) => a.startDate.localeCompare(b.startDate))

  return (
    <VirtualClassroomList>
      {virtualClassroomsOrderedByStartDate.map(virtualClassroom => (
        <VirtualClassroomListItem>
          <FormattedMessage
            id="commons.date_at_time"
            values={{
              date: moment(virtualClassroom.startDate).format('dddd LL'),
              time: moment(virtualClassroom.startDate).format('HH[:]mm'),
            }}
          />
        </VirtualClassroomListItem>
      ))}
    </VirtualClassroomList>
  )
}

VirtualClassroomsBySequence.propTypes = {
  virtualClassrooms: PropTypes.arrayOf(
    PropTypes.shape({
      startDate: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default VirtualClassroomsBySequence
