import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import gql from 'graphql-tag'
import UIComments from 'modules/comments/components/UI'
import { FormattedMessage } from 'react-intl'
import { useMutation } from '@apollo/react-hooks'

const formatReactions = reactions => {
  const reactionsCounters = reactions.reduce(
    (result, { shortname, user }) => ({
      ...result,
      [shortname]: {
        id: shortname,
        shortname,
        counter: _.get(result, `${shortname}.counter`, 0) + 1,
        user,
        people: _.compact(_.concat(_.get(result, `${shortname}.people`, []), [{ id: user.id, label: user.fullName }])),
      },
    }),
    {},
  )

  return Object.values(reactionsCounters)
}

const commentFragment = gql`
  fragment _ on Comment {
    reactions {
      id
      shortname
      user {
        id
        fullName
      }
    }
  }
`

const CREATE_REACTION = gql`
  mutation createReaction($reaction: ReactionCreateInput2!) {
    createReaction2(reaction: $reaction) {
      id
      shortname
      user {
        id
        fullName
      }
    }
  }
`
const DELETE_REACTION = gql`
  mutation deleteReaction($id: ID!) {
    deleteReaction(id: $id) {
      id
    }
  }
`

const toggleReaction = (shortname, reactions, commentId, user, createReaction, deleteReaction) => {
  const myReaction = _.find(reactions, { shortname, user: { id: user.id } })

  if (myReaction) {
    return deleteReaction({
      variables: { id: myReaction.id },
      update: (
        cache,
        {
          data: {
            deleteReaction: { id: deleteReactionId },
          },
        },
      ) => {
        const storeCommentId = `Comment:${commentId}`

        const storeComment = cache.readFragment({
          id: storeCommentId,
          fragment: commentFragment,
        })

        cache.writeFragment({
          id: storeCommentId,
          fragment: commentFragment,
          data: {
            reactions: _.reject(storeComment.reactions, { id: deleteReactionId }),
          },
        })
      },
      optimisticResponse: {
        deleteReaction: {
          id: myReaction.id,
          __typename: 'Reaction',
        },
      },
    })
  }

  const reaction = { commentId, shortname }

  return createReaction({
    variables: { reaction },
    update: (cache, { data: { createReaction2: newlyCreatedReaction } }) => {
      const storeCommentId = `Comment:${commentId}`

      const storeComment = cache.readFragment({
        id: storeCommentId,
        fragment: commentFragment,
      })

      cache.writeFragment({
        id: storeCommentId,
        fragment: commentFragment,
        data: {
          reactions: [...storeComment.reactions, newlyCreatedReaction],
        },
      })
    },
    optimisticResponse: {
      createReaction2: {
        id: `#OPTIMISTIC_ID_${shortname}_${commentId}_${user.id}`,
        shortname,
        user: {
          id: user.id,
          fullName: user.fullName,
          __typename: 'User',
        },
        __typename: 'Reaction',
      },
    },
  })
}

const Reactions = ({ reactions, commentId, user }) => {
  const [createReaction] = useMutation(CREATE_REACTION)
  const [deleteReaction] = useMutation(DELETE_REACTION)
  const reactionsFormatted = formatReactions(reactions)

  const handleToggleReaction = shortname => {
    toggleReaction(shortname, reactions, commentId, user, createReaction, deleteReaction)
  }

  return (
    <UIComments.Reactions
      reactions={reactionsFormatted}
      onNewReaction={shortname => () => {
        handleToggleReaction(shortname)
      }}
      renderReaction={({ id, counter, people, user: reactionUser }) => (
        <UIComments.Reactions.Reaction
          isHighlighted={reactionUser.id === user.id}
          shortname={id}
          onClick={handleToggleReaction}
          counter={counter}
          people={people}
          andMorePeopleFeedback={<FormattedMessage id="comment.more_people" values={{ count: people.length - 10 }} />}
        />
      )}
    />
  )
}

Reactions.fragments = {
  comment: commentFragment,
}

Reactions.propTypes = {
  reactions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      shortname: PropTypes.string.isRequired,
      user: PropTypes.shape({
        fullName: PropTypes.string,
      }).isRequired,
    }),
  ).isRequired,
  commentId: PropTypes.string.isRequired,
  user: PropTypes.shape().isRequired,
}

export default Reactions
