import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl'
import NavItem from '../NavItem'
import Arrow from '../Arrow'

const NextItem = styled(NavItem)`
  flex: 1;
  padding: 20px 10px 20px 20px;
  justify-content: flex-end;
  text-align: right;
`

const NextText = styled.strong`
  display: inline-block;
  margin-bottom: 4px;
`

const NextSectionTitle = styled.p`
  font-size: 14px;
  line-height: 18px;
`

const NextLinkContent = ({ title, disabled }) => (
  <NextItem disabled={disabled} data-cy="next-section">
    <div>
      <NextText>
        <FormattedMessage id="sections.navbar.next" />
      </NextText>
      <NextSectionTitle>{title}</NextSectionTitle>
    </div>
    <Arrow icon="right" />
  </NextItem>
)

NextLinkContent.defaultProps = {
  disabled: false,
}

NextLinkContent.propTypes = {
  title: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
}

export default NextLinkContent
