import React from 'react'
import moment from 'moment'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { Text } from '@unowmooc/text'
import { Select } from '@unowmooc/inputs'
import QueryString from 'components/QueryString'

const GET_SESSIONS = gql`
  query Sessions {
    sessionsForAuthenticatedAnimator(sortCode: "starts_at_desc", withAtLeastOneComment: true) {
      items {
        id
        identifier
        startAt
      }
    }
  }
`

const formatSession = session => ({
  value: session.id,
  label: (
    <>
      <Text fontSize="16px" variant="label">
        {session.identifier}
      </Text>
      &nbsp;
      <Text variant="span" color="midGray">
        -&nbsp;{moment(session.startAt).format('DD/MM/YYYY')}
      </Text>
    </>
  ),
})

const mapSessionsForSelector = data => data && data.sessionsForAuthenticatedAnimator.items.map(formatSession)

const ExpertSessionSelect = props => {
  const { called, loading, data } = useQuery(GET_SESSIONS)

  return (
    <QueryString name="sessionId">
      <Select
        options={mapSessionsForSelector(data)}
        isClearable
        isDisabled={!called}
        isLoading={loading}
        placeholder={<Text fontSize="16px" i18nKey="select.session.placeholder" />}
        {...props}
      />
    </QueryString>
  )
}

export default ExpertSessionSelect
