import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import { Visible } from 'react-grid-system'
import { css } from '@emotion/core'
import { mq, colors } from 'styles-resources'
import TrackVisibility from 'react-on-screen'
import { Row, LeftCol, RightCol, LastCol } from './Grid'

const Wrapper = styled.div``

const Date = styled.p`
  margin-bottom: ${({ last }) => (last ? 12 : 0)}px;
  font-size: 14px;
  line-height: 23px;
  color: ${colors.midGray};

  :first-letter {
    text-transform: uppercase;
  }

  ${mq.xs(css`
    margin-bottom: 12px;
  `)};
`

const variants = {
  initial: ({ from }) => ({
    opacity: 0,
    translateX: (from === 'left' && '-100%') || (from === 'right' && '100%') || 0,
    translateY: (from === 'bottom' && '100%') || 0,
  }),
  animate: {
    opacity: 1,
    translateX: 0,
    translateY: 0,
  },
}

const transition = {
  opacity: { ease: 'easeOut', duration: 0.5 },
  translateX: { type: 'spring', stiffness: 180, damping: 18 },
  translateY: { type: 'spring', stiffness: 180, damping: 18 },
}

const MotionContent = styled(motion.div)`
  ${mq.xs(css`
    padding-left: 26px;
  `)};
`

const Content = props => (
  <TrackVisibility once partialVisibility>
    {({ isVisible }) => (
      <MotionContent
        {...props}
        initial={false}
        animate={isVisible ? 'animate' : 'initial'}
        variants={variants}
        transition={transition}
      />
    )}
  </TrackVisibility>
)

const Timeline = ({ children, className }) => {
  const nbChildren = React.Children.count(children)

  return (
    <Wrapper className={className}>
      {React.Children.map(children, (child, index) => {
        const isLast = index === nbChildren - 1
        const isLeft = index % 2 === 0
        const { date } = child.props

        return (
          <Row style={{ display: 'flex', alignItems: 'center' }} key={child.key}>
            {isLast ? (
              <LastCol xs={12}>
                <Content custom={{ from: 'bottom' }}>
                  <Date last>{date}</Date>
                  {child}
                </Content>
              </LastCol>
            ) : (
              <>
                <LeftCol xs={12} sm={6}>
                  <Content custom={{ from: 'left' }}>
                    <Visible xs>
                      <Date>{date}</Date>
                      {child}
                    </Visible>
                    <Visible sm md lg xl>
                      {isLeft ? child : <Date>{date}</Date>}
                    </Visible>
                  </Content>
                </LeftCol>
                <Visible sm md lg xl>
                  <RightCol xs={12} sm={6}>
                    <Content custom={{ from: 'right' }}>{isLeft ? <Date>{date}</Date> : child}</Content>
                  </RightCol>
                </Visible>
              </>
            )}
          </Row>
        )
      })}
    </Wrapper>
  )
}

Timeline.defaultProps = {
  className: undefined,
}

Timeline.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  className: PropTypes.string,
}

export default Timeline
