import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/browser'
import ErrorBloc from 'components/ErrorBloc'
import styled from '@emotion/styled'
import Alert from '@unowmooc/alert'
import { FormattedMessage } from 'react-intl'

const Wrapper = styled.div`
  margin: 0 auto;
  padding-top: 120px;
  text-align: center;
  width: 600px;
`

const AlertStyled = styled(Alert)`
  margin-top: 30px;
`

class ErrorBoundary extends Component {
  state = {
    hasError: false,
    sentryEventId: null,
  }

  componentDidCatch(error, errorInfo) {
    let sentryEventId = null

    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key])
      })

      sentryEventId = Sentry.captureException(error)
    })

    this.setState({ hasError: true, sentryEventId })
  }

  render() {
    const { children } = this.props
    const { hasError, sentryEventId } = this.state

    if (hasError) {
      return (
        <Wrapper>
          <ErrorBloc i18nMessage="errors.unexpected_error" />
          <a href="/">
            <FormattedMessage id="commons.back_to_home_link" />
          </a>
          {sentryEventId && <AlertStyled>{sentryEventId}</AlertStyled>}
        </Wrapper>
      )
    }

    return children
  }
}

ErrorBoundary.defaultProps = {
  children: undefined,
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
}

export default ErrorBoundary
