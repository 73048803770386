import React from 'react'
import { FormattedHTMLMessage } from 'react-intl'

const ThanksScreen = () => (
  <div>
    <p>
      <FormattedHTMLMessage id="qff.thanks.label" />
    </p>

    <p>
      <FormattedHTMLMessage id="qff.thanks.from" />
    </p>
  </div>
)

export default ThanksScreen
