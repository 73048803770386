import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'
import { Box } from 'reflexbox'

import Alert from '@unowmooc/alert'

const getStatus = (nbMonth, nbAvailableLicenses) => {
  if (nbMonth < 0) {
    return 'no'
  }

  if (nbAvailableLicenses <= 0) {
    return 'noLicences'
  }

  return 'ok'
}

const AlertLicences = ({ expireAt, nbAvailableLicenses }) => {
  const nbMonth = moment(expireAt).diff(moment(), 'months', true)

  if (!expireAt) {
    return null
  }

  return (
    <Box mb="30px">
      <Alert theme="warning">
        <FormattedMessage
          id={`project.licence_warning.${getStatus(nbMonth, nbAvailableLicenses)}`}
          values={{
            nbMonth: Number(nbMonth).toFixed(0),
            nbLicences: nbAvailableLicenses,
            expireAt: moment(expireAt).format('DD/MM/YYYY'),
          }}
        />
      </Alert>
    </Box>
  )
}

AlertLicences.defaultProps = {
  expireAt: null,
  nbAvailableLicenses: null,
}

AlertLicences.propTypes = {
  expireAt: PropTypes.string,
  nbAvailableLicenses: PropTypes.number,
}

export default AlertLicences
