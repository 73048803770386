import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import OverviewParticipant from 'modules/conversations/components/OverviewParticipant'
import Helmet from 'components/HelmetIntl'
import I18nProvider from '@unowmooc/i18n'
import Alert from '@unowmooc/alert'
import { FormattedMessage } from 'react-intl'
import { ButtonI18n } from '@unowmooc/buttons'
import { ZeroHeightUnow } from '@unowmooc/zeroheight'
import EthicsCodePlaceholder from 'components/EthicsCode/Placeholder'
import { mq } from '@unowmooc/themes'
import { css } from '@emotion/core'
import { useUserContext } from 'store/UserProvider'
import EthicsCodeModal from 'components/EthicsCode/Modal'
import WriteMessageParticipantModalForm from 'modules/conversations/components/WriteMessageParticipantModalForm'
import useToggle from 'hooks/useToggle'
import PageQuery from 'components/PageQuery'
import { useDomainContext } from 'components/DomainLayout/Context'
import getQuery from './queries'
import WriteMessageExpertModalForm from '../WriteMessageExpertModalForm'
import OverviewExpert from '../OverviewExpert'
import Filter from './Filter'
import { injectFilterContext, useFilterContext } from './FilterContext'

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 20px;

  ${mq.sm(css`
    flex-direction: column;
  `)};
`

const Title = styled.h2`
  margin-top: 8px;
  margin-bottom: 10px;
`

const FilterStyled = styled(Filter)`
  margin-bottom: 20px;
`

const OverviewWrapper = styled.div`
  & > div {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const CongratulationAlert = styled(Alert, { shouldForwardProp: () => true })`
  display: flex;
`

const MetaInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  ${mq.sm(css`
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;

    & > button {
      margin-top: 16px;
    }
  `)};
`

const computeConversationPropsForOverviewComponent = ({ conversation, authenticatedUserId, matchUrl }) => {
  const member = conversation.members.find(m => m.user.id !== authenticatedUserId)

  return {
    fullName: member.user.fullname,
    avatarUrl: member.user.avatarUrl,
    sessionTitle: conversation.session.course.title,
    subject: conversation.subject,
    sentAt: conversation.date,
    conversationIsResolved: conversation?.isResolved,
    conversationHasForwardedMessage: conversation?.hasForwardedMessage,
    sessionIdentifier: conversation.session?.identifier,
    detailUrl:
      matchUrl +
      I18nProvider.getLinkRoute(`/{idConversation}`, {
        idConversation: conversation.id,
      }),
  }
}

const getWriteMessageModalComponent = domain => {
  switch (domain) {
    case 'participant':
      return WriteMessageParticipantModalForm
    case 'expert':
      return WriteMessageExpertModalForm
    default:
      throw new Error('The component to write a message is not yet defined for this domain')
  }
}

const getOverviewComponent = domain => {
  switch (domain) {
    case 'participant':
      return OverviewParticipant
    case 'expert':
      return OverviewExpert
    default:
      throw new Error('The component to write a message is not yet defined for this domain')
  }
}

const ListRender = ({ filters, match: { url: matchUrl } }) => {
  const { domain } = useDomainContext()
  const [ethicsCodeModal, { open: openEthicsCodeModal, close: closeEthicsCodeModal }] = useToggle()
  const [writeMessageModal, { open: openWriteMessageModal, close: closeWriteMessageModal }] = useToggle()

  const {
    user: { id: authenticatedUserId },
  } = useUserContext()

  const WriteMessageModal = getWriteMessageModalComponent(domain)
  const Overview = getOverviewComponent(domain)

  const queryVariables = { limit: 100 }
  if (filters?.session) {
    queryVariables.withSessionIds = [filters.session]
  }
  if (filters?.resolved ?? false) {
    queryVariables.withIsResolved = JSON.parse(filters.resolved)
  }

  return (
    <>
      <Helmet title="conversation.page_title" />
      <TitleWrapper>
        <Title>
          <FormattedMessage id="conversation.page_title" />
        </Title>

        <ButtonI18n variant="primary" onClick={openWriteMessageModal}>
          <FormattedMessage id={`conversation.write_message_label.${domain}`} />
        </ButtonI18n>
      </TitleWrapper>

      {domain === 'expert' && <FilterStyled />}
      <PageQuery
        key={`query-resolved-${filters.resolved}`}
        fetchPolicy="no-cache"
        query={getQuery(domain)}
        variables={queryVariables}
        render={(data, { refetch: gqlRefetchQuery }) => {
          const conversationCounter = data.conversations._meta.total
          const conversationCounterWithoutFilters = data.conversations._meta.totalWithoutFilters

          return (
            <>
              {conversationCounter > 0 && (
                <MetaInfoWrapper>
                  <FormattedMessage id="conversation.page_total_label" values={{ number: conversationCounter }} />
                  <ButtonI18n variant="link" onClick={openEthicsCodeModal}>
                    <FormattedMessage id="conversation.ethics_code.see_button_label" />
                  </ButtonI18n>
                </MetaInfoWrapper>
              )}
              {domain === 'participant' && conversationCounter === 0 && (
                <>
                  <Alert>
                    <FormattedMessage id="conversation.empty_placeholder.participant" />
                  </Alert>
                  <EthicsCodePlaceholder onButtonClick={openWriteMessageModal} />
                </>
              )}
              {domain === 'expert' &&
                conversationCounter === 0 &&
                conversationCounterWithoutFilters > 0 &&
                filters.resolved === 'false' && (
                  <>
                    <CongratulationAlert theme="success">
                      <ZeroHeightUnow height="32px" width="32px" name="IconPartyPopper" mr="12px" />
                      <FormattedMessage id="conversation.expert_all_resolved_label" />
                    </CongratulationAlert>
                  </>
                )}
              {domain === 'expert' &&
                conversationCounter === 0 &&
                (conversationCounterWithoutFilters === 0 || filters.resolved !== 'false') && (
                  <>
                    <Alert>
                      <FormattedMessage id="conversation.empty_placeholder.expert" />
                    </Alert>
                    <EthicsCodePlaceholder onButtonClick={openWriteMessageModal} />
                  </>
                )}
              {conversationCounter > 0 && (
                <>
                  <OverviewWrapper>
                    {data.conversations.items.map(conversation => (
                      <Overview
                        key={conversation.id}
                        {...computeConversationPropsForOverviewComponent({
                          conversation,
                          authenticatedUserId,
                          matchUrl,
                        })}
                      />
                    ))}
                  </OverviewWrapper>
                </>
              )}
              {ethicsCodeModal && <EthicsCodeModal onClose={closeEthicsCodeModal} />}
              {writeMessageModal && (
                <WriteMessageModal onClose={closeWriteMessageModal} onSubmitSuccess={gqlRefetchQuery} />
              )}
            </>
          )
        }}
      />
    </>
  )
}

ListRender.propTypes = {
  gqlQueryResult: PropTypes.shape().isRequired,
  gqlRefetchQuery: PropTypes.func.isRequired,
  domain: PropTypes.string.isRequired,
  filters: PropTypes.shape({
    resolved: PropTypes.string,
    session: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
}

const List = props => {
  const { filters } = useFilterContext()

  return <ListRender {...props} filters={filters} />
}

export default injectFilterContext(List, { resolved: 'false' })
