import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { Row, Col } from 'react-grid-system'
import { Container } from '@unowmooc/react-ui-kit'
import { FormattedMessage } from 'react-intl'
import { mq, colors } from 'styles-resources'
import Helmet from 'components/HelmetIntl'
import Catalog from './Catalog'
import CtaDownload from './CtaDownload'

const Wrapper = styled.div`
  padding-bottom: 100px;
`

const Title = styled.h1`
  margin-top: 70px;
  margin-bottom: 16px;
  font-size: 48px;
  line-height: 64px;
  text-align: center;

  ${mq.sm(css`
    margin-top: 30px;
    font-size: 32px;
    line-height: 38px;
  `)};
`

const SubTitle = styled.p`
  margin-bottom: 50px;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: ${colors.manatee};

  ${mq.sm(css`
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 24px;
  `)};
`

const StyledCatalog = styled(Catalog)`
  margin-bottom: 96px;

  ${mq.sm(css`
    margin-bottom: 48px;
  `)};
`

const CatalogPage = () => (
  <Wrapper>
    <Helmet title="page_titles.dashboard.title" />

    <Container>
      <Row>
        <Col>
          <Title>
            <FormattedMessage id="catalog.title" />
          </Title>
          <SubTitle>
            <FormattedMessage id="catalog.subtitle" />
          </SubTitle>
        </Col>
      </Row>
      <StyledCatalog />
      <CtaDownload />
    </Container>
  </Wrapper>
)

export default CatalogPage
