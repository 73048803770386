import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useLazyQuery } from '@apollo/react-hooks'
import { FormattedMessage } from 'react-intl'
import Tooltip from '@unowmooc/tooltip'
import { UnowAwesome } from '@unowmooc/loaders'
import styled from '@emotion/styled'
import gql from 'graphql-tag'

const StyledTooltip = styled(Tooltip, { shouldForwardProp: () => true })`
  padding: 0 !important;
`

const Loader = styled(UnowAwesome)`
  padding: 30px 70px;
`

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`

const Error = styled.div`
  padding: 20px;
  max-width: 150px;
  text-align: center;
  color: ${({ theme: { colors } }) => colors.bittersweet};
`

const LinkQuality = styled.a`
  color: ${({ theme: { colors } }) => colors.woodsmoke};
  font-size: 14px;
  line-height: 23px;
  padding: 10px 20px;
  transition: ${({ theme: { transitions } }) => transitions.backgroundColor};

  &:hover {
    text-decoration: none;
    background-color: ${({ theme: { colors } }) => colors.whiteLilac};
  }
`

const GET_VIMEO_DOWNLOAD_LINKS = gql`
  query vimeoDownloadLinks($vimeoId: Int!) {
    vimeoDownloadLinks(vimeoId: $vimeoId) {
      link
      quality
      md5
    }
  }
`

const DownloadLinks = ({ vimeoId, children }) => {
  const [getDownloadLinks, { data, error, loading, called }] = useLazyQuery(GET_VIMEO_DOWNLOAD_LINKS, {
    fetchPolicy: 'network-only',
  })

  const handleClick = useCallback(() => {
    getDownloadLinks({
      variables: {
        vimeoId,
      },
    })
  }, [vimeoId])

  return (
    <StyledTooltip
      content={
        loading || !called ? (
          <Loader size={20} />
        ) : (
          <LinksContainer>
            {error ? (
              <Error>
                <FormattedMessage id="not_found.unexpected_error" />
              </Error>
            ) : (
              data.vimeoDownloadLinks.map(vimeo => (
                <LinkQuality href={vimeo.link} key={vimeo.md5}>
                  <FormattedMessage id={`sections.content.video.quality.${vimeo.quality}`} />
                </LinkQuality>
              ))
            )}
          </LinksContainer>
        )
      }
      placement="bottom"
      flipBehavior={['bottom', 'top', 'left', 'right']}
      theme="white"
      interactive
      trigger="click"
      onShow={() => {
        handleClick()
      }}
    >
      {children}
    </StyledTooltip>
  )
}

DownloadLinks.propTypes = {
  vimeoId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default DownloadLinks
