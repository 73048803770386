import React from 'react'
import { useCourseContext } from 'modules/course/components/CourseContext/CourseContext'
import CollectorForm from 'modules/collector/components/Collector'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'

const Collector = ({ onSubmit }) => {
  const { participantId, bicOnboarding } = useCourseContext()

  return (
    <>
      <CollectorForm
        onSubmit={onSubmit}
        formId={bicOnboarding.id}
        formContext="bic"
        participantId={participantId}
        submitButtonLabel={<FormattedMessage id="guide.button_label_end_bic" />}
      />
    </>
  )
}

Collector.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default Collector
