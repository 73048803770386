import React from 'react'
import SkillsForm from 'components/SkillsForm'
import { screens } from '@unowmooc/themes'
import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl'
import SkipLinkAnchor from 'modules/skipLink/SkipLinkAnchor'

const Wrapper = styled.div`
  background-color: white;
  min-height: 100vh;
  padding-top: 60px;
`
const Container = styled.div`
  max-width: ${screens.mdMin};
  margin: auto;
  padding: 0 16px;
`

const Heading = styled.h2`
  margin-bottom: 32px;
`
const InitialSkillsFormPage = () => (
  <Wrapper>
    <Container>
      <SkipLinkAnchor label={<FormattedMessage id="a11y.skip_to_content" />} anchor="content" priority={1} />
      <Heading>
        <FormattedMessage id="survey.bic_page_title" />
      </Heading>
      <SkillsForm context={SkillsForm.contexts.BIC} />
    </Container>
  </Wrapper>
)

export default InitialSkillsFormPage
