import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { propType } from 'graphql-anywhere'
import gql from 'graphql-tag'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { Row, Col } from 'react-grid-system'
import { FormattedMessage } from 'react-intl'
import { ShadowBox } from '@unowmooc/react-ui-kit'
import { mq, colors } from 'styles-resources'
import Progression from './Progression'
import { COURSE_FORMAT } from '../../../../../business/course'

const Wrapper = styled(ShadowBox)`
  padding: 20px;
  background-color: ${colors.white};
`

const CourseTitle = styled.p`
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: bold;
`

const NbBadges = styled.p`
  font-size: 14px;
  line-height: 23px;
  color: ${colors.midGray};
`

const ColProgression = styled(Col)`
  text-align: right;

  ${mq.xs(css`
    margin-top: 20px;
  `)};
`

const getNbModuleBadges = badges => _.filter(badges, ({ module }) => !!module).length
const getNbModules = modules => _.filter(modules, { bonus: false }).length

const Participation = ({
  participation: {
    badges,
    session: { course },
    advancement,
  },
  className,
}) => (
  <Wrapper className={className}>
    <Row>
      <Col xs={12} sm={8} md={7} lg={8}>
        <CourseTitle>{course.title}</CourseTitle>
        {(course.format === COURSE_FORMAT.SPOC || course.format === COURSE_FORMAT.MOOC) && (
          <NbBadges>
            <FormattedMessage
              id="profile.participations.participation.nb_badges"
              values={{ nbBadges: getNbModuleBadges(badges), nbModules: getNbModules(course.modules) }}
            />
          </NbBadges>
        )}
      </Col>
      <ColProgression xs={12} sm={4} md={5} lg={4}>
        <Progression rate={advancement.rate} />
      </ColProgression>
    </Row>
  </Wrapper>
)

Participation.fragments = {
  participation: gql`
    fragment _ on Participant {
      badges {
        id
        module {
          id
        }
      }
      session {
        id
        course {
          id
          title
          format
          modules {
            id
            bonus
          }
        }
      }
      advancement {
        rate
      }
    }
  `,
}

Participation.defaultProps = {
  className: undefined,
}

Participation.propTypes = {
  participation: propType(Participation.fragments.participation).isRequired,
  className: PropTypes.string,
}

export default Participation
