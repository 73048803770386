import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'
import ListPage from './list'
import EditPage from './edit'

const VirtualClassroomsPage = ({ match: { url } }) => (
  <Switch>
    <Route exact path={url} component={ListPage} />
    <Route path={`${url}/:idSession`} component={EditPage} />
  </Switch>
)

VirtualClassroomsPage.propTypes = {
  match: PropTypes.shape().isRequired,
}

export default VirtualClassroomsPage
