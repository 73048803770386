import React from 'react'
import PropTypes from 'prop-types'
import SelectSession from 'components/Select/Session'
import { useUserContext } from 'store/UserProvider'
import styled from '@emotion/styled'
import { mq } from '@unowmooc/themes'
import { css } from '@emotion/core'
import I18nProvider from '@unowmooc/i18n'
import SelectResolved from 'components/Select/Resolved'
import { BoxShadow } from '../UI'
import { useFilterContext } from './FilterContext'

const FieldWrapper = styled.div`
  width: 33%;

  ${mq.sm(css`
    width: 100%;
  `)};
`

const BoxShadowStyled = styled(BoxShadow)`
  display: flex;
  flex-direction: row;

  ${mq.sm(css`
    flex-direction: column;
  `)};

  & > ${FieldWrapper}:first-child {
    margin: 0 20px 0 0;

    ${mq.sm(css`
      margin: 0 0 20px;
    `)};
  }
`

const Filter = ({ className }) => {
  const { filters, setFilters } = useFilterContext()

  const {
    user: { id: authenticatedUserId },
  } = useUserContext()

  const handleSelectResolvedChange = option => {
    setFilters({ ...filters, resolved: option.value })
  }

  const handleSelectSessionChange = option => {
    setFilters({ ...filters, session: option.id })
  }

  return (
    <BoxShadowStyled className={className}>
      <FieldWrapper>
        <SelectResolved
          onChange={handleSelectResolvedChange}
          value={filters.resolved !== null ? { value: filters.resolved } : null}
          placeholder={I18nProvider.formatMessage({ id: `select.resolved.placeholder` })}
        />
      </FieldWrapper>
      <FieldWrapper>
        <SelectSession
          value={filters.session ? { id: filters.session } : null}
          onChange={handleSelectSessionChange}
          placeholder={I18nProvider.formatMessage({ id: `select.session.placeholder` })}
          filters={{ animations_user_id: authenticatedUserId, animations_isMain: true }}
          nullableOption={{
            id: null,
            __label: I18nProvider.formatMessage({ id: `select.session.all` }),
          }}
        />
      </FieldWrapper>
    </BoxShadowStyled>
  )
}

Filter.propTypes = {
  className: PropTypes.string.isRequired,
}

export default Filter
