import React from 'react'
import PropTypes from 'prop-types'
import Avatar from '@unowmooc/avatar'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'

const Wrapper = styled.div`
  display: flex;
`

const StyledAvatar = styled(Avatar)`
  flex-shrink: 0;
`

const Info = styled.div`
  margin-left: 15px;
`

const Author = styled.div`
  display: flex;
  align-items: center;

  ${mq.xs(css`
    display: block;
  `)}
`

const Name = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.midGray};

  ${mq.xs(css`
    margin-bottom: 2px;
  `)}
`

const Role = styled.div`
  margin-left: 12px;

  ${mq.xs(css`
    margin-bottom: 5px;
    margin-left: 0;
  `)}
`

const Badge = styled(Role)`
  align-items: center;
  display: flex;
`

const Date = styled.div`
  margin-top: 5px;
  font-size: 12px;
  line-height: 13px;
  color: ${({ theme }) => theme.colors.bombay};

  ${mq.xs(css`
    margin-bottom: 6px;
  `)}
`

const CommentInfo = ({ author, avatarFile, avatarSrc, avatarName, date, tagLabel, badgeLabel }) => (
  <Wrapper>
    <StyledAvatar size={30} file={avatarFile} src={avatarSrc} name={avatarName} />
    <Info>
      <Author>
        <Name>{author}</Name>
        {tagLabel && <Role>{tagLabel}</Role>}
        {badgeLabel && <Badge>{badgeLabel}</Badge>}
      </Author>
      <Date>{date}</Date>
    </Info>
  </Wrapper>
)

CommentInfo.defaultProps = {
  avatarFile: undefined,
  avatarSrc: undefined,
  tagLabel: undefined,
  badgeLabel: undefined,
}

CommentInfo.propTypes = {
  avatarName: PropTypes.string.isRequired,
  author: PropTypes.node.isRequired,
  date: PropTypes.node.isRequired,
  avatarFile: PropTypes.shape(),
  avatarSrc: PropTypes.string,
  tagLabel: PropTypes.node,
  badgeLabel: PropTypes.node,
}

export default CommentInfo
