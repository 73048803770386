import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import gql from 'graphql-tag'
import { FormattedMessage } from 'react-intl'
import Resource from '../../Resource'

const Bonus = ({ bonus, className }) => (
  <Resource
    title={bonus.title}
    href={bonus.url}
    icon="link"
    type={<FormattedMessage id={`sections.bonus.types.${bonus.type}`} />}
    className={className}
  />
)

Bonus.fragments = {
  link: gql`
    fragment Bonus on Link {
      id
      type
      title
      url
    }
  `,
}

Bonus.defaultProps = {
  className: undefined,
}

Bonus.propTypes = {
  bonus: propType(Bonus.fragments.link).isRequired,
  className: PropTypes.string,
}

export default Bonus
