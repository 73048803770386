import React from 'react'
import { mq } from '@unowmooc/themes'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { ButtonSkin } from '@unowmooc/buttons'
import { Link } from 'react-router-dom'
import Tag from '@unowmooc/tags'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import * as ConversationUI from './UI'

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
`

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;

  ${mq.sm(css`
    flex-direction: column;
  `)};
`

const ButtonSkinStyled = styled(ButtonSkin)`
  margin-left: 40px;

  ${mq.sm(css`
    margin-left: 0;
  `)};
`

const OverviewExpert = ({
  fullName,
  avatarUrl,
  sessionIdentifier,
  subject,
  sentAt,
  detailUrl,
  conversationIsResolved,
  conversationHasForwardedMessage,
}) => (
  <ConversationUI.BoxShadow>
    <Header>
      <Tag theme={conversationIsResolved ? 'lightGreen' : 'lightRed'} small>
        <FormattedMessage id={`conversation.is_resolved.${conversationIsResolved}`} />
      </Tag>

      {conversationHasForwardedMessage && (
        <Tag theme="lightHitPink" small>
          <FormattedMessage id="conversation.forward.forwarded_tag" />
        </Tag>
      )}
      <Tag theme="grey" small style={{ marginLeft: 'auto' }}>
        {sessionIdentifier}
      </Tag>
    </Header>
    <ConversationUI.Sender fullName={fullName} avatarUrl={avatarUrl} sentAt={sentAt} />
    <FlexWrapper>
      <ConversationUI.Subject text={subject} />
      <ButtonSkinStyled tag={Link} to={`${detailUrl}${window.location.search}`} size="small">
        <FormattedHTMLMessage id="conversation.see" />
      </ButtonSkinStyled>
    </FlexWrapper>
  </ConversationUI.BoxShadow>
)

OverviewExpert.defaultProps = {
  avatarUrl: undefined,
}

OverviewExpert.propTypes = {
  fullName: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  sessionIdentifier: PropTypes.string.isRequired,
  detailUrl: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string,
  sentAt: PropTypes.string.isRequired,
  conversationIsResolved: PropTypes.bool.isRequired,
  conversationHasForwardedMessage: PropTypes.bool.isRequired,
}

export default OverviewExpert
