import React, { useState, useRef, useEffect } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'
import StreamlineIcon from '@unowmooc/icons'
import useEventListener from 'hooks/useEventListener'
import ReactPlayer from 'react-player'
import PropTypes from 'prop-types'
import useIsMobile from 'hooks/useIsMobile'
import CloseButton from './CloseButton'

const FloatingVideoWrapper = styled.div`
  ${({ floating, theme: { colors } }) =>
    floating &&
    `
      position: fixed;
      z-index: 1;
      bottom: 20px;
      right: 20px;
      width: 300px;
      box-shadow: 0 13px 46px 0 ${colors.mischka};

      &:hover {
        ${CloseButton} {
          opacity: 1;
        }
      }
    `}
`

const VideoPlaceholder = styled('div', { shouldForwardProp: () => false })`
  ${({ width, height }) =>
    `
      width: ${width}px;
      height: ${height}px;
    `}
`

const VideoIframeWrapper = styled.div`
  position: relative;
  height: 0;
  padding-bottom: 56.25%;

  ${mq.sm(css`
    margin-left: -20px;
    margin-right: -20px;
    padding-bottom: 60%;
  `)};
`

const StyledReactPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`

const Player = ({ videoLink }) => {
  const [floating, setFloating] = useState(false)
  const [disableFloating, setDisableFloating] = useState(false)
  const [playing, setPlaying] = useState(false)
  const [{ width, height }, setPlaceholderSize] = useState({ width: null, height: null })
  const floatingVideoWrapperRef = useRef()
  const videoPlaceholderRef = useRef()
  const isMobile = useIsMobile()

  useEffect(() => {
    // disable floating player on mobile and when not playing
    if (floating && (isMobile || !playing)) {
      setFloating(false)
    }
  }, [isMobile, floating, playing])

  useEventListener('scroll', () => {
    if (!isMobile && !disableFloating) {
      // we use the bottom value of the player box to know if the player is visible or not
      const ref = floating ? videoPlaceholderRef : floatingVideoWrapperRef
      const bounding = ref.current.getBoundingClientRect()

      // the player bottom is reaching
      const isOnViewport = bounding.bottom > 0

      if (playing && floating === isOnViewport) {
        setFloating(!isOnViewport)
        setPlaceholderSize({ width: bounding.width, height: bounding.height })
      }
    }
  })

  return (
    <>
      {floating && <VideoPlaceholder ref={videoPlaceholderRef} width={width} height={height} />}

      <FloatingVideoWrapper floating={floating} ref={floatingVideoWrapperRef}>
        {floating && (
          <CloseButton
            onClick={() => {
              setFloating(false)
              setDisableFloating(true)
            }}
          >
            <StreamlineIcon icon="cross" />
          </CloseButton>
        )}
        <VideoIframeWrapper>
          <StyledReactPlayer
            url={videoLink}
            controls
            width="100%"
            height="100%"
            onPlay={() => {
              setPlaying(true)
            }}
            onEnded={() => {
              setPlaying(false)
            }}
          />
        </VideoIframeWrapper>
      </FloatingVideoWrapper>
    </>
  )
}

Player.propTypes = {
  videoLink: PropTypes.string.isRequired,
}

export default Player
