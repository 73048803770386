import React from 'react'
import PropTypes from 'prop-types'
import { filter } from 'graphql-anywhere'
import gql from 'graphql-tag'
import { toInlineFragment } from 'fraql'
import _ from 'lodash'
import { withRouter } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import UIComments from 'modules/comments/components/UI'
import { Box } from 'reflexbox'
import queryString from 'query-string'
import styled from '@emotion/styled'
import { ClassNames } from '@emotion/core'
import I18nProvider from '@unowmooc/i18n'
import { withUser } from 'store/UserProvider'
import Comment from '../Comments/Comment'
import AddForm from '../Comments/AddForm'
import Answers from '../Comments/Answers'
import CommentExpert from '../CommentExpert'
import ThreadResolverButton from '../ThreadResolverButton'
import { ThreadContextProvider } from '../Context/ThreadContext'

const StyledThread = styled(UIComments.Thread, { shouldForwardProp: prop => prop !== 'user' })`
  position: relative;
  margin-bottom: 30px;
  background-color: ${({ theme }) => theme.colors.white};
  overflow: visible;

  border-radius: 4px;
  box-shadow: 0 13px 46px 0 rgba(0, 0, 0, 0.08);
  width: 100%;

  .mention {
    border-radius: 6px;
    background-color: rgba(0, 204, 153, 0.08);
    padding: 3px 0;
    color: ${({ theme }) => theme.colors.unowGreen};
  }

  .mention[data-id*='${({ user }) => user.id}'] {
    background-color: rgb(255, 230, 179, 0.4);
  }

  .mention > span {
    margin: 0 3px;
  }
`

const isCommentVisible = comment =>
  comment.deletedAt === null || comment.children.every(item => item.deletedAt === null)

const addChildCommentOnCache = (cache, comment, parent) => {
  const fragment = gql`
    fragment Thread on Comment {
      id
      children {
        id
        ${toInlineFragment(Comment.fragments.comment)}
      }
    }
  `

  const storeParentId = `Comment:${parent.id}`

  const storeParent = cache.readFragment({
    id: storeParentId,
    fragment,
  })

  const { children } = storeParent

  cache.writeFragment({
    id: storeParentId,
    fragment,
    data: {
      children: [...children, { ...comment, deletedAt: null }],
    },
  })
}

const addCommentOnCacheByComment = parent => (cache, comment) => addChildCommentOnCache(cache, comment, parent)

const ThreadExpert = ({ comments, user, location: { search, pathname }, history }) => {
  const { comment: highlightedCommentId } = queryString.parse(search)
  const avatarSrc = _.get(user.avatar, 'secureUrl')

  return (
    <ClassNames>
      {({ css }) => {
        const cssAvatar = css`
          top: 23px;
        `

        return (
          <>
            {_.filter(comments, isCommentVisible).map(comment => (
              <ThreadContextProvider>
                <StyledThread key={comment.id} user={user}>
                  <CommentExpert key={comment.id} comment={comment} user={user} />
                  {comment.children.length > 0 && (
                    <Answers
                      answers={filter(Answers.fragments.comment, comment.children)}
                      session={filter(Answers.fragments.session, comment.session)}
                      user={filter(Answers.fragments.user, user)}
                      historyReplace={history.replace}
                      pathname={pathname}
                      highlightedCommentId={highlightedCommentId}
                      isThreadExpert
                    />
                  )}
                  <UIComments.CommentInput
                    avatarSrc={avatarSrc}
                    fullName={user.fullName}
                    placeholder={<FormattedMessage id="comment.answer_placeholder" />}
                    editorAvatarClassName={cssAvatar}
                    editor={
                      <AddForm
                        autofocus
                        session={filter(AddForm.fragments.session, comment.session)}
                        section={comment.section ? filter(AddForm.fragments.section, comment.section) : undefined}
                        parent={filter(AddForm.fragments.parent, comment)}
                        fragment={Comment.fragments.comment}
                        addCommentOnCache={addCommentOnCacheByComment(comment)}
                        placeholder={I18nProvider.formatMessage({ id: 'comment.answer_placeholder' })}
                      />
                    }
                  />
                  <Box
                    sx={{
                      padding: ['20px', '0px 25px 25px'],
                    }}
                  >
                    <ThreadResolverButton comment={filter(ThreadResolverButton.fragment, comment)} />
                  </Box>
                </StyledThread>
              </ThreadContextProvider>
            ))}
          </>
        )
      }}
    </ClassNames>
  )
}

ThreadExpert.fragment = gql`
  fragment ThreadExpertFragment on Comment {
    id
    deletedAt
    ${toInlineFragment(CommentExpert.fragment)}
    ${toInlineFragment(AddForm.fragments.parent)}
    ${toInlineFragment(ThreadResolverButton.fragment)}
    children {
      id
      deletedAt
      ${toInlineFragment(Answers.fragments.comment)}
    }
    session {
      id
      course {
        id
      }
      ${toInlineFragment(AddForm.fragments.session)}
    }
    section {
      id
      ${toInlineFragment(AddForm.fragments.section)}
    }
  }
`

ThreadExpert.propTypes = {
  comments: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  user: PropTypes.shape().isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
}

export default withRouter(withUser(ThreadExpert))
