import React, { Component } from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import moment from 'moment'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'
import MaskedInput from 'react-text-mask'
import { Input, Textarea, Select } from '@unowmooc/react-ui-kit'
import Fieldset from 'components/FieldsetIntl'
import FormContainer from 'components/FormContainer'
import Field from 'components/FieldIntl'
import ImagePickerv2 from 'components/ImagePicker/indexv2'
import SelectCountry from 'components/SelectCountry'
import {
  twitter as twitterValidator,
  linkedIn as linkedInValidator,
  website as websiteValidator,
  realDate as realDateValidator,
} from 'business/validators'
import { formatUrlWithoutHttps } from 'business/parser'
import { dataToSelectOptions } from 'utils/form'

const fragment = gql`
  fragment FormDataFragment on Me {
    id
    firstName
    lastName
    __fullName: fullName
    avatar {
      id
      filename
      secureUrl
      fileStackId
      policy
      signature
    }
    presentation
    organization
    job
    fonction
    city
    country
    twitter
    linkedIn
    website
    phone
    birthday
    gender
  }
`

const StyledFirstFieldset = styled(Fieldset)`
  margin-top: 40px;

  ${mq.sm(css`
    margin-top: 20px;
  `)};
`

const birthdayFormat = 'DD/MM/YYYY'

class Form extends Component {
  shouldComponentUpdate(nextProps) {
    const { id } = this.props
    const { id: nextId } = nextProps

    return id !== nextId
  }

  render() {
    return (
      <FormContainer
        {...this.props}
        name="form-user-profile"
        omitFields={['avatar.preview', 'slug']}
        fragment={fragment}
        typeName="Me"
        mutationArgumentName="user"
        queryWithoutId
        mutationOptions={{
          update: (cache, { data }) => {
            const storeId = `User:${data.object.id}`

            const storeUser = cache.readFragment({
              id: storeId,
              fragment,
            })

            if (storeUser) {
              cache.writeFragment({
                id: storeId,
                fragment,
                data: {
                  ...data.object,
                  __typename: 'User',
                },
              })
            }
          },
        }}
      >
        <StyledFirstFieldset legend="commons.fieldset_legend.general_informations">
          <Field required name="firstName" label="settings.profile.form.first_name">
            <Input />
          </Field>
          <Field required name="lastName" label="settings.profile.form.last_name">
            <Input />
          </Field>
          <Field name="avatar" label="settings.profile.form.avatar" allowNull>
            <ImagePickerv2 rounded />
          </Field>
          <Field name="presentation" label="settings.profile.form.biography">
            <Textarea />
          </Field>
          <Field name="phone" label="settings.profile.form.phone" help="settings.profile.form.phone_help">
            <Input />
          </Field>
          <Field
            name="birthday"
            label="settings.profile.form.birthday"
            format={value => moment(value).format(birthdayFormat)}
            parse={value =>
              value && /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(value) ? moment(value, birthdayFormat) : null
            }
            validate={realDateValidator}
          >
            <MaskedInput
              placeholder="JJ/MM/AAAA"
              placeholderChar="_"
              mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
              render={(ref, props) => <Input innerRef={ref} {...props} />}
            />
          </Field>
          <Field name="gender" label="settings.profile.form.gender">
            <Select options={dataToSelectOptions(['f', 'm', 'other', 'nspp'], 'select.genders')} clearable />
          </Field>
        </StyledFirstFieldset>
        <Fieldset legend="settings.profile.form.your_company">
          <Field name="organization" label="settings.profile.form.company_name">
            <Input />
          </Field>
          <Field name="fonction" label="settings.profile.form.fonction" maxCharacters={255}>
            <Input />
          </Field>
          <Field name="job" label="settings.profile.form.job" maxCharacters={255}>
            <Input />
          </Field>
          <Field name="city" label="settings.profile.form.city">
            <Input />
          </Field>
          <Field name="country" label="settings.profile.form.country">
            <SelectCountry searchable clearable />
          </Field>
        </Fieldset>
        <Fieldset legend="settings.profile.form.social_networks">
          <Field
            name="twitter"
            label="settings.profile.form.twitter"
            formatOnBlur
            format={formatUrlWithoutHttps}
            validate={twitterValidator}
          >
            <Input />
          </Field>
          <Field
            name="linkedIn"
            label="settings.profile.form.linkedin"
            formatOnBlur
            format={formatUrlWithoutHttps}
            validate={linkedInValidator}
          >
            <Input />
          </Field>
          <Field name="website" label="settings.profile.form.website" validate={websiteValidator}>
            <Input />
          </Field>
        </Fieldset>
      </FormContainer>
    )
  }
}

Form.defaultProps = {
  id: undefined,
}

Form.propTypes = {
  id: PropTypes.string,
}

export default Form
