import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import { colors } from 'styles-resources'

const BarItemPointOuter = styled.div`
  position: absolute;
  top: -14px;
  right: -13px;
  width: 35px;
  height: 35px;
  box-sizing: border-box;
  border-style: solid;
  border-width: 3px;
  border-radius: 50%;
  border-color: ${({ borderColor }) => borderColor};
  opacity: 0.2;
`

const BarItemPoint = styled.div`
  position: absolute;
  top: -5px;
  right: -8px;
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  background: ${colors.white};
  border-style: solid;
  border-width: 5px;
  border-radius: 50%;
  border-color: ${({ borderColor }) => borderColor};
`

const BarItemViewport = styled.div`
  position: absolute;
  top: -40px;
  right: 0;
  left: 0;
  width: 100%;
  height: 60px;
  cursor: pointer;
`

const BarItemNumber = styled.span`
  position: absolute;
  right: -50px;
  width: 100px;
  color: ${colors.shipGray};
  text-align: center;
  top: ${({ position }) => (position === 'top' ? -40 : 35)}px;
`

const scale = keyframes`
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.15);
  }
`

const BarItem = styled.div`
  width: ${({ width }) => width}%;
  background-color: ${({ backgroundColor }) => backgroundColor};

  &:hover {
    ${BarItemPointOuter} {
      animation: ${scale} 0.4s ease-in-out infinite alternate;
    }
  }

  position: absolute;
  top: 40px;
  left: 0;
  height: 8px;
  border-radius: 11.5px;
`

const Item = ({ width, color, onHover, noLabel, position, label, point }) => (
  <BarItem
    width={width}
    backgroundColor={color}
    onMouseEnter={() => {
      onHover(true)
    }}
    onMouseLeave={() => {
      onHover(false)
    }}
  >
    <BarItemViewport />
    {!noLabel && <BarItemNumber position={position}>{label || point}</BarItemNumber>}
    <BarItemPoint borderColor={color}>
      <BarItemPointOuter borderColor={color} />
    </BarItemPoint>
  </BarItem>
)

Item.defaultProps = {
  width: 0,
  noLabel: false,
  label: undefined,
  position: 'top',
}

Item.propTypes = {
  width: PropTypes.number,
  point: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  onHover: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  noLabel: PropTypes.bool,
  position: PropTypes.oneOf(['top', 'bottom']),
}

export default Item
