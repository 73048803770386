import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Field } from '@unowmooc/form'
import { Select } from '@unowmooc/inputs'
import { FormattedMessage } from 'react-intl'
import { useQuery } from 'react-apollo'
import { useFormContext } from 'react-hook-form'

const SelectAutoFillField = ({ name, label, query, filters, getOptions, selectProps }) => {
  const [options, setOptions] = useState([])
  const queryResult = useQuery(query, { variables: { filters: JSON.stringify(filters) } })
  const { setValue } = useFormContext()

  useEffect(() => {
    if (!queryResult.loading) {
      setOptions(getOptions(queryResult))
    }
  }, [queryResult.loading])

  useEffect(() => {
    if (options?.length === 1) {
      setValue(name, options[0])
    }
  }, [options])

  return (
    <>
      <Field required={<FormattedMessage id="errors.isRequired" />} name={name} label={label}>
        <Select {...selectProps} options={options} />
      </Field>
    </>
  )
}

SelectAutoFillField.defaultProps = {
  filters: {},
  selectProps: {},
  label: null,
  getOptions: queryResult => queryResult.data?.select?.items,
}

SelectAutoFillField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOf([PropTypes.node, PropTypes.string]),
  query: PropTypes.string.isRequired,
  filters: PropTypes.shape(),
  selectProps: PropTypes.shape(),
  getOptions: PropTypes.func,
}

export default SelectAutoFillField
