import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import SectionBox from 'components/SectionBox'
import styled from '@emotion/styled'
import { css, keyframes } from '@emotion/core'
import { FormattedNumber } from 'react-intl'
import { mq, rgba, colors } from '@unowmooc/themes'

const frames = ({ color }) => keyframes`
  0% {
    background: ${colors.white};
  }

  20% {
    background: ${rgba(color, 0.13)};
  }

  30% {
    background: ${rgba(color, 0.07)};
  }

  40% {
    background: ${rgba(color, 0.13)};
  }

  100% {
    background: ${colors.white};
  }
`

const animationStyle = ({ color }) => css`
  animation: ${frames({ color })} 1s ease;
`

const Box = styled(SectionBox, { shouldForwardProp: prop => prop !== 'highlight' })`
  display: flex;
  flex: 1;
  margin-left: 30px;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  transition: background-color 0.2s ease;

  ${({ highlight }) => (highlight ? animationStyle : undefined)}

  &:first-of-type {
    margin-left: 0;
  }

  ${mq.sm(css`
    margin-left: 0;
    margin-bottom: 30px;
  `)};
`

const Number = styled.span`
  display: block;
  margin-bottom: 10px;
  font-size: 28px;
  font-weight: bold;
  line-height: 32px;
  color: ${({ color }) => color};
`

const Help = styled.span`
  font-size: 14px;
  color: ${colors.manatee};
`

const BoxNumber = ({
  number,
  text,
  unit,
  help,
  color,
  highlight,
  numberStyle,
  defaultValue,
  className,
  maximumFractionDigits,
}) => (
  <Box color={color} className={className} highlight={highlight}>
    <Number color={color}>
      {!_.isNil(number) ? (
        <>
          <FormattedNumber value={number} style={numberStyle} maximumFractionDigits={maximumFractionDigits} />
          {unit && ` ${unit}`}
        </>
      ) : (
        defaultValue
      )}
    </Number>
    <span>{text}</span>
    {help && <Help>{help}</Help>}
  </Box>
)

BoxNumber.defaultProps = {
  number: undefined,
  color: colors.shipGray,
  unit: undefined,
  highlight: false,
  help: undefined,
  numberStyle: undefined,
  defaultValue: '-',
  className: undefined,
  maximumFractionDigits: 0,
}

BoxNumber.propTypes = {
  number: PropTypes.node,
  highlight: PropTypes.bool,
  unit: PropTypes.string,
  text: PropTypes.node.isRequired,
  help: PropTypes.node,
  color: PropTypes.string,
  numberStyle: PropTypes.string,
  defaultValue: PropTypes.node,
  className: PropTypes.string,
  maximumFractionDigits: PropTypes.number,
}

export default BoxNumber
