import React from 'react'
import { Input, Textarea } from '@unowmooc/react-ui-kit'
import { Select } from '@unowmooc/inputs'
import RadioGroup from 'components/RadioGroup'
import CheckboxGroup from 'components/CheckboxGroup'
import { minChoices } from 'business/validators'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'
import SurveyQuestionLabel from 'components/SurveyQuestionLabel'
import { questionPropTypes, questionPropTypesDefault } from '../../proptypes'
import Field from '../Field'
import Watch from '../Watch'
import MatrixScale from './MatrixScale'
import Rating from './Rating'
import ScaleAnswer from '../ScaleAnswer'

const getAnswerConfig = question => {
  const { type, subtype } = question

  const internalType = `${type}.${subtype}`

  const answerConfig = {
    type,
    subtype,
    internalType,
    component: () => null,
    componentProps: {},
    fieldProps: {},
    explanationTriggerField: null,
  }

  // Gestion du composant de rendu
  switch (internalType) {
    case 'text.single':
      answerConfig.component = Input
      break
    case 'text.essay':
      answerConfig.component = Textarea
      break
    case 'single_choice.radio_buttons':
      answerConfig.component = RadioGroup
      answerConfig.componentProps.inline = false
      answerConfig.componentProps.options = question.choices
      break
    case 'single_choice.radio_buttons_horizontal':
      answerConfig.component = RadioGroup
      answerConfig.componentProps.inline = true
      answerConfig.componentProps.options = question.choices
      break
    case 'single_choice.scale':
      answerConfig.component = ScaleAnswer
      answerConfig.componentProps.options = question.choices
      break
    case 'single_choice.rating':
      answerConfig.component = Rating
      answerConfig.componentProps.options = question.choices
      break
    case 'single_choice.dropdown':
    case 'single_choice.birth_year':
      answerConfig.component = Select
      answerConfig.componentProps.isSearchable = false
      answerConfig.componentProps.options = question.choices
      answerConfig.componentProps.placeholder = <FormattedMessage id="select.default_placeholder" />
      answerConfig.componentProps.menuPlacement = 'auto'
      answerConfig.componentProps.innerProps = { 'data-cy': 'collector-single-choice-select' }
      break
    case 'multiple_choice.checkboxes':
      answerConfig.component = CheckboxGroup
      answerConfig.componentProps.inline = false
      answerConfig.componentProps.options = question.choices
      break
    default:
      answerConfig.component = () => null
      break
  }

  // Gestion des validations
  switch (type) {
    case 'text':
    case 'single_choice':
      answerConfig.fieldProps.required = question.min_required_answers > 0
      break
    case 'multiple_choice':
      answerConfig.fieldProps.required = question.min_required_answers > 0
      answerConfig.fieldProps.requiredMessage =
        question.min_required_answers > 1 ? (
          <FormattedMessage id="survey.required_multiple" values={{ number: question.min_required_answers || 0 }} />
        ) : null
      answerConfig.fieldProps.validate = minChoices(question.min_required_answers)
      break
    case 'matrix':
      answerConfig.fieldProps.required = question.min_required_answers > 0
      break
    default:
      break
  }

  // Gestion du explanation_field
  if (Array.isArray(question.choices)) {
    answerConfig.explanationTriggerField = question.choices.find(choice => choice.explanation_field_type)
  }

  return answerConfig
}

const RequiredTag = styled.span`
  color: ${({ theme: { colors } }) => colors.pastelRed};
`

const RequiredMessage = styled.p`
  border-left: 3px solid ${({ theme: { colors } }) => colors.manatee};
  color: ${({ theme: { colors } }) => colors.midGray};
  margin: -14px 0 24px 4px;
  padding-left: 12px;
`

const Answer = ({ question }) => {
  const formContext = useFormContext()
  const answerConfig = getAnswerConfig(question)
  const AnswerComponent = answerConfig.component

  const fieldName = `page_${question.page_number}_question_${question.id}`

  return (
    <>
      <SurveyQuestionLabel marginBottom={10} id={fieldName}>
        {question.title} {answerConfig.fieldProps.required ? <RequiredTag>*</RequiredTag> : null}
      </SurveyQuestionLabel>

      {answerConfig.fieldProps.requiredMessage && (
        <RequiredMessage>{answerConfig.fieldProps.requiredMessage}</RequiredMessage>
      )}

      {answerConfig.type !== 'matrix' && (
        <Field name={fieldName} {...answerConfig.fieldProps}>
          <AnswerComponent {...answerConfig.componentProps} />
        </Field>
      )}
      {answerConfig.type === 'matrix' && (
        <MatrixScale fieldName={fieldName} required={answerConfig.fieldProps.required} {...question} />
      )}

      {answerConfig.explanationTriggerField && (
        <Watch name={fieldName}>
          {(fieldValue = []) => {
            const explanationFieldName = `${fieldName}_choice_${answerConfig.explanationTriggerField.id}_explanation`

            // Gestion des deux cas ou values est retourné par une question à choix multiple ou simple
            const computedValues = Array.isArray(fieldValue) ? fieldValue : [fieldValue]

            const triggerField = computedValues.find(choice => choice.id === answerConfig.explanationTriggerField.id)

            if (!triggerField) {
              formContext.setValue(explanationFieldName, undefined)

              return null
            }

            return (
              <>
                <br />
                <Field name={explanationFieldName} label={<FormattedMessage id="survey.explanation_label" />}>
                  {triggerField.explanation_field_type === 'single' ? <Input /> : <Textarea />}
                </Field>
              </>
            )
          }}
        </Watch>
      )}
    </>
  )
}

Answer.defaultProps = {
  question: questionPropTypesDefault,
}

Answer.propTypes = {
  question: questionPropTypes,
}

export default Answer
