import React, { Component } from 'react'
import gql from 'graphql-tag'
import _ from 'lodash'
import SelectGraphql from 'components/SelectGraphql'
import { enrichVirtualLabel } from '../utils'

const fragment = gql`
  fragment SelectParticipantUserFragment on Participant {
    id
    user {
      id
      fullName
    }
  }
`

class SelectParticipantUser extends Component {
  constructor(props) {
    super(props)

    this.computedProps = this.computedProps.bind(this)
  }

  computedProps(value, options) {
    const renderLabel = option => `${option.user.fullName}`

    // computing new concat label key
    const props = {
      options: _.orderBy(
        options.reduce(
          (acc, option) => ({
            ...acc,
            [option.user.id]: {
              ...option,
              __label: renderLabel(option),
            },
          }),
          {},
        ),
        '__label',
        'asc',
      ),
    }

    if (!value) {
      return props
    }

    props.value = enrichVirtualLabel(value, renderLabel)

    return props
  }

  render() {
    return (
      <SelectGraphql
        {...this.props}
        computedProps={this.computedProps}
        searchable
        remote
        queryName="participants"
        labelProperty="__label"
        onSelectResetsInput={false}
        fragment={fragment}
      />
    )
  }
}

SelectParticipantUser.fragment = fragment

export default SelectParticipantUser
