import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import gql from 'graphql-tag'
import s from 'underscore.string'
import { FormattedMessage } from 'react-intl'
import { Link, withRouter } from 'react-router-dom'
import styled from '@emotion/styled'
import I18nProvider from '@unowmooc/i18n'
import { SubItem } from 'components/TimelineMenu'
import SectionIcon from '../SectionIcon'

const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`

const QuizSectionItem = ({ module, session, valid, disabled, onClick, location: { pathname } }) => {
  const to = I18nProvider.getLinkRoute('/courses/{slugCourse}/{idSession}/lessons/quiz/{idModule}', {
    slugCourse: session.course.slug,
    idSession: session.id,
    idModule: module.id,
  })

  const subItem = (
    <SubItem
      title={<FormattedMessage id="courses.course.program.quiz_section" values={{ title: module.title }} />}
      icon={<SectionIcon valid={valid} icon="quiz" />}
      duration={<FormattedMessage id="courses.course.program.section_duration" values={{ duration: 5 }} />}
      disabled={disabled}
      active={s.startsWith(pathname, to)}
    />
  )

  return disabled ? (
    subItem
  ) : (
    <StyledLink to={to} onClick={onClick}>
      {subItem}
    </StyledLink>
  )
}

QuizSectionItem.fragments = {
  module: gql`
    fragment _ on Module {
      id
      title
    }
  `,
  session: gql`
    fragment _ on Session {
      id
      course {
        id
        slug
      }
    }
  `,
}

QuizSectionItem.defaultProps = {
  disabled: false,
  valid: false,
  onClick: undefined,
}

QuizSectionItem.propTypes = {
  module: propType(QuizSectionItem.fragments.module).isRequired,
  session: propType(QuizSectionItem.fragments.session).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
  valid: PropTypes.bool,
  onClick: PropTypes.func,
}

export default withRouter(QuizSectionItem)
