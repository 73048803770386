import React from 'react'
import { Route, Switch } from 'react-router-dom'
import OldRoutesRedirect from './OldRoutesRedirect'

const OldRoutes = () => (
  <Switch>
    <Route path="/inscription/sessions/:token" component={OldRoutesRedirect} />
    <Route path="/valider-mon-invitation/:confirmationToken" component={OldRoutesRedirect} />
    <Route path="/completer-mon-inscription/:confirmationToken/:token" component={OldRoutesRedirect} />
    <Route path="/changer-le-mot-de-passe/:token" component={OldRoutesRedirect} />
    <Route path="/connexion" component={OldRoutesRedirect} />
    <Route path="/deconnexion" component={OldRoutesRedirect} />
    <Route path="/admin" component={OldRoutesRedirect} />
    <Route path="/admin/*" component={OldRoutesRedirect} />
    <Route path="/sessions" component={OldRoutesRedirect} />
    <Route path="/parametres" component={OldRoutesRedirect} />
    <Route path="/utilisateurs" component={OldRoutesRedirect} />
  </Switch>
)

export default OldRoutes
