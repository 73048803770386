import React, { useState, createContext, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useForm, FormProvider } from 'react-hook-form'
import { captureException } from '@sentry/browser'
import PageLoader from 'components/PageLoader'
import Scroll from 'react-scroll'
import { useCollectorReducer, initializeForm, goPrevPage, goNextPage, submitAnswers } from '../reducer'
import { retrieveCurrentQuestions } from './utils'
import ApiConnector from '../connector/api'
import JsonConnector from '../connector/json'

const CollectorContext = createContext({})

const COLLECTOR_FORM_ID_PREFIX = 'collector_form_'

export const CollectorProvider = ({ connector, onSubmit, onLoad, children, containerId }) => {
  const [state, dispatch] = useCollectorReducer()
  const [submitting, setSubmitting] = useState(false)
  const [collectorFormId] = useState(`${COLLECTOR_FORM_ID_PREFIX}${connector.formId}`)
  const methods = useForm({
    mode: 'onSubmit ',
    reValidateMode: 'onSubmit',
    shouldUnregister: false,
  })

  useEffect(() => {
    ;(async () => {
      dispatch(initializeForm({ questions: await connector.loadData(), participantId: connector.participantId }))
    })()
  }, [])

  useEffect(() => {
    const handleBeforeUnload = () => true

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => window.removeEventListener('beforeunload', handleBeforeUnload)
  }, [])

  const prepareAnswers = answers => {
    if (typeof answers === 'string') {
      return [{ value: answers }]
    }

    return (Array.isArray(answers) ? answers : [answers]).reduce((acc, answer) => {
      if (!answer) {
        return acc
      }

      return [
        ...acc,
        {
          value: answer.value,
          choice_id: parseInt(answer.id, 10),
        },
      ]
    }, [])
  }

  const isLastPage = state.currentPage === state.pageCounter

  const handleBackPage = () => {
    dispatch(goPrevPage())
    Scroll.scroller.scrollTo(collectorFormId, { containerId })
  }

  const handleNextPage = async () => {
    dispatch(goNextPage())
    Scroll.scroller.scrollTo(collectorFormId, { containerId })
  }

  const handleSubmit = methods.handleSubmit(
    async data => {
      if (!isLastPage) {
        await handleNextPage()

        return
      }

      const formData = {
        participant_id: parseInt(connector.participantId, 10),
        context: connector.formContext,
        questions: [],
      }

      Object.keys(data)
        .sort()
        .forEach(fieldName => {
          const fieldValue = data[fieldName]

          if (!fieldValue) {
            return
          }

          const matchSimpleQuestion = fieldName.match(/^page_(\d+)_question_(?<questionId>\d+)$/)
          if (matchSimpleQuestion) {
            const questionId = parseInt(matchSimpleQuestion.groups.questionId, 10)

            formData.questions.push({
              id: questionId,
              answers: prepareAnswers(fieldValue),
            })
          }

          const matchSubQuestion = fieldName.match(
            /^page_(\d+)_question_(?<questionId>\d+)_subquestion_(?<subQuestionId>\d+)$/,
          )
          if (matchSubQuestion) {
            const questionId = parseInt(matchSubQuestion.groups.questionId, 10)
            const subQuestionId = parseInt(matchSubQuestion.groups.subQuestionId, 10)

            let question = formData.questions.find(q => q.id === questionId)
            if (!question) {
              question = {
                id: questionId,
                subquestions: [],
              }

              formData.questions.push(question)
            }

            question.subquestions.push({
              id: subQuestionId,
              answers: prepareAnswers(fieldValue),
            })
          }

          const matchExplanationQuestion = fieldName.match(
            /^page_(\d+)_question_(?<questionId>\d+)_choice_(?<choiceId>\d+)_explanation$/,
          )
          if (matchExplanationQuestion) {
            const questionId = parseInt(matchExplanationQuestion.groups.questionId, 10)
            const choiceId = parseInt(matchExplanationQuestion.groups.choiceId, 10)

            const question = formData.questions.find(q => q.id === questionId)
            const choice = question.answers.find(c => c.choice_id === choiceId)

            choice.explanation_answer = fieldValue
          }
        })

      setSubmitting(true)

      await connector.sendData(formData).catch(error => {
        captureException(error)
        setSubmitting(false)
      })

      dispatch(submitAnswers())

      if (onSubmit) {
        onSubmit()
      }
    },
    async errors => {
      const currentPageErrors = Object.keys(errors).filter(
        fieldName => fieldName.indexOf(`page_${state.currentPage}_`) === 0,
      )

      // Permet de naviguer dans le formulaire si une erreur est située dans une autre page
      if (currentPageErrors.length === 0) {
        await handleNextPage()

        return
      }

      // Permet de remonter la page sur la première erreur rencontrée
      const match = currentPageErrors[0].match(/^page_(\d+)_question_(?<questionId>\d+)/)

      if (match) {
        Scroll.scroller.scrollTo(`page_${state.currentPage}_question_${match.groups.questionId}`, { containerId })
      }
    },
  )

  if (onLoad) {
    onLoad({ hasAnswers: state.hasAnswers })
  }

  if (!state.loaded) {
    return <PageLoader />
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} id={collectorFormId}>
        <CollectorContext.Provider
          value={{
            loaded: state.loaded,
            pageNumber: state.pageCounter,
            currentPage: state.currentPage,
            currentQuestions: retrieveCurrentQuestions({
              questions: state.questions,
              currentPage: state.currentPage,
              formValues: methods.getValues(),
              clearValue: methods.setValue,
            }),
            isFirstPage: state.currentPage === 1,
            isLastPage,
            answers: state.answers,
            handleNextPage,
            handleBackPage,
            handleSubmit,
            submitting,
            hasAnswers: state.hasAnswers,
          }}
        >
          {children}
        </CollectorContext.Provider>
      </form>
    </FormProvider>
  )
}

CollectorProvider.defaultProps = {
  onSubmit: undefined,
  onLoad: undefined,
  containerId: undefined,
}

CollectorProvider.propTypes = {
  connector: PropTypes.oneOf([PropTypes.instanceOf(ApiConnector), PropTypes.instanceOf(JsonConnector)]).isRequired,
  onSubmit: PropTypes.func,
  onLoad: PropTypes.func,
  children: PropTypes.node.isRequired,
  containerId: PropTypes.string,
}

export const useCollector = () => useContext(CollectorContext)
