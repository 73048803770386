import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { ShadowBox } from '@unowmooc/react-ui-kit'
import { css, keyframes } from '@emotion/core'
import { animateScrollTo } from 'utils/scroll'

const highlightKeyframes = keyframes`
  from, 20%, 53%, 80%, to {
    background-color: white;
  }

  40%, 43%, 70%, 90% {
    background-color: #faecd1;
  }
`

const highlightAnimation = css`
  animation: ${highlightKeyframes} 700ms ease;
`

const BoxShadowStyled = styled(ShadowBox, { shouldForwardProp: prop => prop !== 'highlight' })`
  position: relative;
  padding: 20px 25px;
  background-color: ${({ theme: { colors } }) => colors.white};

  ${({ highlight }) => (highlight ? highlightAnimation : '')}
}
`

const BoxShadow = props => {
  const { id, highlight } = props
  useEffect(() => {
    if (highlight && id) {
      const element = document.getElementById(id)
      animateScrollTo(element)
    }
  }, [highlight])

  return <BoxShadowStyled {...props} />
}

BoxShadow.defaultProps = {
  highlight: false,
}

BoxShadow.propTypes = {
  id: PropTypes.string.isRequired,
  highlight: PropTypes.bool,
}

export default BoxShadow
