import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Col, Row } from 'react-grid-system'
import { FormattedMessage } from 'react-intl'
import VirtualClassroomsBySequence from './VirtualClassroomsBySequence'
import { MAX_COLUMNS_NUMBER, ONBOARDING_INDEX } from './constants'

const StyledAlert = styled.div`
color: 
  padding: 16px 20px;
  font-size: 14px;
  line-height: 24px;
  border-radius: 4px;
`

const Title = styled.div`
  display: flex;
  font-weight: 500;
  margin-bottom: 8px;
  line-height: 24px;
`

const VirtualClassroomsInfo = memo(
  ({ selectedSessionId, sessions, maxColumnsNumber, className }) => {
    if (!selectedSessionId) {
      return null
    }

    const sessionConfig = sessions.find(session => session.id === selectedSessionId)

    if (!sessionConfig || !sessionConfig.hasVirtualClassroom) {
      return null
    }

    const virtualClassroomsBySequence = sessionConfig.virtualClassrooms.reduce((acc, virtualClassroom) => {
      const index = virtualClassroom.isOnboarding ? ONBOARDING_INDEX : virtualClassroom.sequenceNumber

      return {
        ...acc,
        [index]: [...(acc?.[index] ?? []), virtualClassroom],
      }
    }, {})

    const hasVirtualClassroomEmpty = sessionConfig.hasVirtualClassroom && sessionConfig.virtualClassrooms.length === 0

    const sortedSequenceNumber = Object.keys(virtualClassroomsBySequence).sort()

    return (
      <StyledAlert className={className}>
        <Title>
          <FormattedMessage
            id={`registered_users.virtual_classrooms.title_virtual_classroom_info${
              hasVirtualClassroomEmpty ? '_empty' : ''
            }`}
          />
        </Title>
        <Row>
          {sortedSequenceNumber.map(sequenceNumber => {
            const numberOfColumns = Math.min(maxColumnsNumber, sortedSequenceNumber.length)

            return (
              <Col sm={12 / 2} md={12 / numberOfColumns}>
                <div>
                  <FormattedMessage
                    id={`registered_users.virtual_classrooms.sequence_title${
                      ONBOARDING_INDEX === sequenceNumber ? '_onboarding' : ''
                    }`}
                    values={{
                      sequenceNumber,
                    }}
                  />
                </div>
                <VirtualClassroomsBySequence virtualClassrooms={virtualClassroomsBySequence[sequenceNumber]} />
              </Col>
            )
          })}
        </Row>
      </StyledAlert>
    )
  },
  (prevProps, nextProps) => prevProps.selectedSessionId === nextProps.selectedSessionId,
)

VirtualClassroomsInfo.defaultProps = {
  className: null,
  selectedSessionId: null,
  maxColumnsNumber: MAX_COLUMNS_NUMBER,
}

VirtualClassroomsInfo.propTypes = {
  className: PropTypes.string,
  selectedSessionId: PropTypes.string,
  sessions: PropTypes.arrayOf(
    PropTypes.shape({
      hasVirtualClassroom: PropTypes.bool.isRequired,
      virtualClassrooms: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    }),
  ).isRequired,
  maxColumnsNumber: PropTypes.number,
}

export default VirtualClassroomsInfo
