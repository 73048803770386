import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Box } from 'reflexbox'
import { Text } from '@unowmooc/text'
import FormattedDateInterval from 'components/FormattedDateInterval'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import LinkButtonIcon from 'components/LinkButtonIcon'
import I18nProvider from '@unowmooc/i18n'

const FinalSkillsLinkCard = ({ title, startAt, endAt, slugCourse, sessionId }) => (
  <Box variant="card" style={{ marginTop: '30px' }}>
    <Flex display="inline-flex" alignItems="center">
      <Text as="strong" variant="strong" display={['none', 'none', 'inline']} fontSize={['18px']} color="shipGray">
        {title}
      </Text>
    </Flex>
    <Box display={['block', 'block', 'none']} my={['9px', null, '10px']}>
      <Text as="strong" variant="strong" fontSize={['16px', null, '18px']} color="shipGray">
        {title}
      </Text>
    </Box>
    <Box mt={['0', '0', '2px']} mb={['0', '0', '8px']}>
      <Text color="manatee">
        <FormattedDateInterval id="commons.session_from_to" from={startAt} to={endAt} />
      </Text>
    </Box>
    <Flex display="inline-flex" flexDirection={[null, null, 'row-reverse']}>
      <Box
        display={['none', 'none', 'flex']}
        flexDirection={[null, null, 'row-reverse']}
        sx={{
          '> *:not(:last-child)': {
            marginRight: '20px',
          },
        }}
      >
        <LinkButtonIcon
          tag={Link}
          to={I18nProvider.getLinkRoute('/courses/{slugCourse}/{sessionId}/final_skills_form', {
            slugCourse,
            sessionId,
          })}
          icon="right"
        >
          <FormattedMessage id="domains.final_skills_form_redirect.button_redirect" />
        </LinkButtonIcon>
      </Box>
    </Flex>
    <Flex
      display={['flex', 'flex', 'none']}
      flexDirection={['column', 'column', 'row']}
      sx={{
        '> *:not(:last-child)': {
          marginBottom: '20px',
        },
      }}
    >
      <LinkButtonIcon
        tag={Link}
        to={I18nProvider.getLinkRoute('/courses/{slugCourse}/{sessionId}/final_skills_form', {
          slugCourse,
          sessionId,
        })}
        icon="right"
      >
        <FormattedMessage id="dashboard.participations.planned.show_guide" />
      </LinkButtonIcon>
    </Flex>
  </Box>
)

FinalSkillsLinkCard.propTypes = {
  title: PropTypes.string.isRequired,
  startAt: PropTypes.string.isRequired,
  endAt: PropTypes.string.isRequired,
  slugCourse: PropTypes.string.isRequired,
  sessionId: PropTypes.string.isRequired,
}

export default FinalSkillsLinkCard
