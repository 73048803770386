import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'

const Link = styled.a`
  display: inline-block;
  font-size: 14px;
  margin-bottom: 25px;
`

const NbCommentsLink = ({ to, nbComments, className, courseIdentifier, sessionIdentifier, sectionId }) => (
  <Link
    href={to}
    className={className}
    data-tracking-id={
      nbComments
        ? 'comment.click_on_link_to_comments_with_comments'
        : 'comment.click_on_link_to_comments_without_comments'
    }
    data-tracking-values={JSON.stringify({
      courseIdentifier,
      sessionIdentifier,
      sectionId,
    })}
  >
    <FormattedMessage id="sections.nb_comments_link" values={{ nbComments }} />
  </Link>
)

NbCommentsLink.defaultProps = {
  className: undefined,
}

NbCommentsLink.propTypes = {
  to: PropTypes.string.isRequired,
  nbComments: PropTypes.number.isRequired,
  className: PropTypes.string,
  courseIdentifier: PropTypes.string.isRequired,
  sessionIdentifier: PropTypes.string.isRequired,
  sectionId: PropTypes.number.isRequired,
}

export default NbCommentsLink
