import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import Tag from '@unowmooc/tags'
import { IntlMessage } from '@unowmooc/text'

const DoneTag = ({ comment }) => {
  if (comment.isResolved) {
    return (
      <Tag small theme="lightGreen">
        <IntlMessage i18nKey="expert.comments.done" />
      </Tag>
    )
  }

  return (
    <Tag small theme="lightRed">
      <IntlMessage i18nKey="expert.comments.not_done" />
    </Tag>
  )
}

DoneTag.fragment = gql`
  fragment DoneTagFragment on Comment {
    id
    isResolved
  }
`

DoneTag.propTypes = {
  comment: PropTypes.shape({
    isResolved: PropTypes.string,
  }).isRequired,
}

export default DoneTag
