import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import I18nProvider from '@unowmooc/i18n'
import { Select } from '@unowmooc/inputs'

const resolvedOptionValues = ['null', 'true', 'false']

const SelectResolved = ({ value, ...props }) => {
  const options = useMemo(
    () =>
      resolvedOptionValues.map(option => ({
        value: option,
        label: I18nProvider.formatMessage({ id: `select.resolved.${option}` }),
      })),
    [],
  )

  const computedValue = options.find(option => option.value === value?.value)

  return <Select options={options} value={computedValue} {...props} />
}

SelectResolved.propTypes = {
  value: PropTypes.shape().isRequired,
}

export default SelectResolved
