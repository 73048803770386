import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'
import { withUser } from 'store/UserProvider'
import Helmet from 'components/HelmetIntl'
import Form from './Form'

const StyledForm = styled(Form)`
  ${mq.sm(css`
    margin-top: 26px;
  `)};
`

const AccountPage = ({ user }) => (
  <>
    <Helmet title="page_titles.settings.account.title" />
    <StyledForm
      id={user.id}
      canUpdatePassword={user.canUpdatePassword}
      title={<FormattedMessage id="settings.account.page_title" />}
    />
  </>
)

AccountPage.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.shape.isRequired,
    canUpdatePassword: PropTypes.func.isRequired,
  }).isRequired,
}

export default withUser(AccountPage)
