import urlParser from 'url-parse'
import qs from 'query-string'
import I18nProvider from '@unowmooc/i18n'
import { AUTHOR_ROLES } from './comment.constants'

export const getTheme = role => {
  switch (role) {
    case AUTHOR_ROLES.ADMIN:
      return 'green'
    case AUTHOR_ROLES.EXPERT:
      return 'orange'
    case AUTHOR_ROLES.PARTICIPANT:
    default:
      return 'grey'
  }
}

/**
 * This method have to be used for notification only
 */
export const getTo = activity => {
  const { course_slug: slugCourse, session_id: idSession, section_id: idSection, comment_id: idComment } = activity

  const pathname = idSection
    ? I18nProvider.getLinkRoute('/courses/{slugCourse}/{idSession}/lessons/{idSection}', {
        slugCourse,
        idSession,
        idSection,
      })
    : I18nProvider.getLinkRoute('/courses/{slugCourse}/{idSession}/discussions', {
        slugCourse,
        idSession,
      })

  return {
    pathname,
    search: `?comment=${idComment}`,
  }
}

export const getPathToSessionComment = ({ session, section, id: commentId }) => {
  const parsed = {}
  const urlParsed = urlParser(
    I18nProvider.getLinkRoute('/courses/{slugCourse}/{idSession}', {
      slugCourse: session.course.slug,
      idSession: session.id,
    }),
  )

  if (section) {
    urlParsed.pathname = urlParsed.pathname.concat(
      I18nProvider.getLinkRoute('/lessons/{idSection}', { idSection: section.id }),
    )
  } else {
    urlParsed.pathname = urlParsed.pathname.concat(I18nProvider.getLinkRoute('/discussions'))
  }

  if (commentId) {
    parsed.comment = commentId
  }

  urlParsed.set('query', qs.stringify(parsed))

  return urlParsed.href.replace(urlParsed.origin, '')
}

export const getPathToExpertCommentsBySessionId = sessionId => {
  const parsed = {}
  const urlParsed = urlParser(I18nProvider.getLinkRoute('/expert/comments'), {}, true)

  if (sessionId) {
    parsed.sessionId = sessionId
  }

  urlParsed.set('query', qs.stringify(parsed))

  return urlParsed.href.replace(urlParsed.origin, '')
}
