import React from 'react'
import { mq } from '@unowmooc/themes'
import { css } from '@emotion/core'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Radio from 'components/Radio'
import useIsMobile from 'hooks/useIsMobile'

const Wrapper = styled('div', { shouldForwardProp: () => true })`
  display: flex;

  &[data-inline='true'] {
    flex-direction: row;
    justify-content: space-between;

    & > label {
      margin-left: 40px;
      &:first-child {
        margin-left: 0;
      }
    }

    ${mq.sm(css`
      flex-direction: column;

      & > label {
        margin-left: 0;
      }
    `)};
  }
  &[data-inline='false'] {
    flex-direction: column;
  }
`

const StyledRadio = styled(Radio)`
  margin-bottom: 10px;
`

const RadioGroup = ({ name, options, value, onChange, disabled, success, error, inline, className, ...props }) => {
  const isMobile = useIsMobile()

  return (
    <Wrapper data-inline={!isMobile ? inline : false} className={className}>
      {options.map(option => (
        <StyledRadio
          {...props}
          value={option.id}
          id={`${name}[${option.id}]`}
          checked={!!value && value.id === option.id}
          disabled={disabled || option.disabled}
          success={success || option.success}
          error={error || option.error}
          label={option.label}
          labelDirection={!isMobile && inline ? 'bottom' : 'right'}
          name={name}
          onChange={() => {
            if (!value || value.id !== option.id) {
              onChange(option)
            }
          }}
          inline={!isMobile ? inline : false}
          key={option.id}
        />
      ))}
    </Wrapper>
  )
}

RadioGroup.defaultProps = {
  inline: false,
  disabled: false,
  success: false,
  error: false,
  value: null,
  className: undefined,
}

RadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  inline: PropTypes.bool,
  disabled: PropTypes.bool,
  success: PropTypes.bool,
  error: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.node.isRequired,
      disabled: PropTypes.bool,
      error: PropTypes.bool,
    }),
  ).isRequired,
  value: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }),
  className: PropTypes.string,
}

export default RadioGroup
