import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Global, css } from '@emotion/core'
import { colors } from 'styles-resources'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { logoutUrl } from 'utils/routes'
import I18nProvider from '@unowmooc/i18n'
import { withUser } from 'store/UserProvider'
import DomainNav from 'components/Layout/DomainNav'
import UserAvatar from 'components/UserAvatar'
import SlideTransition from 'components/SlideTransition'
import Wrapper from './Wrapper'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: auto;
`

const StyledDomainNav = styled(DomainNav)`
  margin-bottom: 32px;
`

const UserNav = styled.div`
  margin-bottom: 20px;
  text-align: center;
`

const StyledUserAvatar = styled(UserAvatar)`
  margin-bottom: 26px;
  justify-content: center;
`

const ProfileLink = styled(Link)`
  color: inherit;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
`

const LogoutLink = styled.a`
  display: block;
  font-size: 14px;
  line-height: 22px;
  color: ${colors.bittersweet};
`

const MobileNav = ({ user, visible, className, ...props }) => (
  <>
    {visible && (
      <Global
        styles={css`
          body {
            overflow: hidden;
          }
        `}
      />
    )}
    <SlideTransition in={visible} from="right">
      <Wrapper>
        <Content>
          <StyledDomainNav {...props} />

          <UserNav>
            <ProfileLink
              to={I18nProvider.getLinkRoute('/profile/{slug}/{id}', { slug: user.slug, id: user.id })}
              data-tracking-id="session.click_on_profile_page"
              data-tracking-values={JSON.stringify({
                from: 'MobileNav',
              })}
            >
              <StyledUserAvatar size={24} />
            </ProfileLink>
            <LogoutLink href={logoutUrl}>
              <FormattedMessage id="user_dropdown.logout" />
            </LogoutLink>
          </UserNav>
        </Content>
      </Wrapper>
    </SlideTransition>
  </>
)

MobileNav.defaultProps = {
  visible: false,
  className: undefined,
}

MobileNav.propTypes = {
  user: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  visible: PropTypes.bool,
  className: PropTypes.string,
}

export default withUser(MobileNav)
