import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import gql from 'graphql-tag/lib/graphql-tag.umd'
import PageQuery from 'components/PageQuery'
import GoBackLink from 'components/GoBackLink'
import Timeline from 'modules/conversations/components/Timeline'
import Message from 'modules/conversations/components/Message'
import ReplyForm from 'modules/conversations/components/ReplyForm'
import { useDomainContext } from 'components/DomainLayout/Context'
import { FormattedMessage } from 'react-intl'
import Tag from '@unowmooc/tags'

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
`

const TagWrapper = styled.div`
  & > div:last-child {
    margin-left: 12px;
  }
`

const Subject = styled.h2`
  margin-bottom: 20px;
`

const CourseName = styled.p`
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme: { colors } }) => colors.midGray};
  margin-bottom: 30px;
`

const DetailRender = ({ conversationId, gqlQueryResult: data, gqlRefetchQuery, backUrl }) => {
  const { domain } = useDomainContext()
  const [highlightMessageId, setHighlightMessageId] = useState(null)

  useEffect(() => {
    const matchHash = window.location.hash.match(/^#message_(?<messageId>\d+)$/)

    if (matchHash) {
      setHighlightMessageId(matchHash.groups.messageId)
    }
  }, [])

  const conversationIsResolved = data.conversation?.isResolved
  const sessionId = data.conversation.session.id
  const sessionIdentifier = data.conversation.session.identifier

  const handleSubmitSuccess = async () => {
    await gqlRefetchQuery()
  }

  return (
    <>
      <GoBackLink to={`${backUrl}${window.location.search}`} i18nLabel="conversation.go_back_list_button_label" />
      {domain === 'expert' && (
        <Header>
          <TagWrapper>
            <Tag theme={conversationIsResolved ? 'lightGreen' : 'lightRed'} small>
              <FormattedMessage id={`conversation.is_resolved.${conversationIsResolved}`} />
            </Tag>
            {data.conversation.hasForwardedMessage && (
              <Tag theme="lightHitPink" small>
                <FormattedMessage id="conversation.forward.forwarded_tag" />
              </Tag>
            )}
            <Tag theme="grey" small>
              {sessionIdentifier}
            </Tag>
          </TagWrapper>
        </Header>
      )}
      <Subject>{data.conversation.subject}</Subject>
      {domain === 'participant' && <CourseName>{data.conversation.session.course.title}</CourseName>}
      <Timeline>
        {data.conversation.messages.map(message => (
          <Message
            key={`message_${message.id}`}
            highlight={message.id === highlightMessageId}
            messageId={message.id}
            fullName={message.sentBy.fullname}
            avatarUrl={message.sentBy.avatarUrl}
            gender={message.sentBy.gender}
            sentAt={message.sentAt}
            content={message.content}
            isForwarded={message.isForwarded}
            sessionId={sessionId}
            sentById={message.sentBy.id}
            onForwardSuccess={handleSubmitSuccess}
          />
        ))}
        <ReplyForm
          conversationId={conversationId}
          onSubmitSuccess={handleSubmitSuccess}
          isResolved={conversationIsResolved}
        />
      </Timeline>
    </>
  )
}

const query = gql`
  query DetailQuery($idConversation: ID) {
    conversation(id: $idConversation) {
      id
      isResolved
      hasForwardedMessage
      subject
      session {
        id
        identifier
        course {
          id
          title
        }
      }
      messages {
        id
        sentBy {
          id
          fullname
          avatarUrl
          gender
        }
        sentAt
        content
        isForwarded
      }
    }
  }
`

DetailRender.propTypes = {
  conversationId: PropTypes.string.isRequired,
  gqlRefetchQuery: PropTypes.func.isRequired,
  gqlQueryResult: PropTypes.shape().isRequired,
  backUrl: PropTypes.string.isRequired,
}

const Detail = props => {
  const {
    match: {
      params: { idConversation },
    },
  } = props

  return (
    <PageQuery
      variables={{ idConversation }}
      fetchPolicy="cache-and-network"
      query={query}
      render={(gqlQueryResult, { refetch }) => (
        <DetailRender
          {...props}
          conversationId={idConversation}
          gqlQueryResult={gqlQueryResult}
          gqlRefetchQuery={refetch}
        />
      )}
    />
  )
}

Detail.propTypes = {
  backUrl: PropTypes.string.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      idConversation: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export default Detail
