import React from 'react'
import styled from '@emotion/styled'
import Answer from '../Answer'
import { questionPropTypes, questionPropTypesDefault } from '../../proptypes'

const QuestionWrapper = styled.div`
  margin: 10px 0 40px;
`

const Question = ({ question }) => (
  <QuestionWrapper>
    <Answer question={question} />
  </QuestionWrapper>
)

Question.defaultProps = {
  question: questionPropTypesDefault,
}

Question.propTypes = {
  question: questionPropTypes,
}

export default Question
