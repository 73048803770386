import { useEffect, useState } from 'react'
import axios from 'axios'
import _get from 'lodash/get'
import { withRouter } from 'react-router-dom'
import * as dateUtils from 'utils/date'
import config from 'config'

// we check only every 5 minutes
const CHECK_VALIDITY_DURATION_IN_SECONDS = 5 * 60

// some urls should not be forced reloaded
// eslint-disable-next-line prettier/prettier
const EXCLUDED_PATTERN_URL = [
  /lecons\/quiz/,
  /lessons\/quiz/,
  /lecons\/examen-final/,
  /lessons\/final-exam/
]

const CheckVersion = ({ history, children }) => {
  const [href, setHref] = useState(null)
  const [forceReload, setForceReload] = useState(false)
  const [lastCompareDate, setLastCompareDate] = useState(dateUtils.now() - CHECK_VALIDITY_DURATION_IN_SECONDS)

  const compareVersion = async location => {
    const now = dateUtils.now()

    // if an update is detected we define the URL to hit
    if (forceReload) {
      setHref(`${location.pathname}${location.search}${location.hash}`)
      return
    }

    // we don't check at each navigation
    if (now - lastCompareDate < CHECK_VALIDITY_DURATION_IN_SECONDS) {
      return
    }

    // we retrieve the fresh version of the app
    const response = await axios.get('/manifest.json')
    const upToDataVersion = _get(response, 'data.version', '0.0.0')

    // if the version is not the same, an update is requested
    if (config.env.APP_VERSION !== upToDataVersion) {
      setForceReload(true)
    }

    setLastCompareDate(now)
  }

  useEffect(
    () =>
      // history.listen returns unlisten callback
      history.listen(compareVersion),
    [forceReload, lastCompareDate],
  )

  if (forceReload && href && !EXCLUDED_PATTERN_URL.some(regexp => regexp.test(href))) {
    document.location.assign(href)
    return null
  }

  return children
}

export default withRouter(CheckVersion)
