import React, { createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import withUser from './withUser'
import { ADMIN } from '../../modules/user/user.constants'

const UserContext = createContext({ user: null })

const UserProvider = ({ children, user }) => {
  const isAdmin = () => user.role === ADMIN

  const isAnimatorFromSession = sessionId =>
    user.animations.filter(animation => animation.session.id === sessionId).length > 0

  return <UserContext.Provider value={{ user, isAdmin, isAnimatorFromSession }}>{children}</UserContext.Provider>
}

UserProvider.propTypes = {
  user: PropTypes.shape().isRequired,
  children: PropTypes.node.isRequired,
}

export default UserProvider

export const useUserContext = () => useContext(UserContext)

export const UserConsumer = UserContext.Consumer
export { withUser }
