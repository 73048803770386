import styled from '@emotion/styled'

const TagDefault = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 0px 8px;
  border-radius: 100px;
  white-space: nowrap;
  height: 20px;
  font-style: normal;

  span {
    font-size: 11px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: normal;
  }
`

export default TagDefault
