import React, { useState } from 'react'
import styled from '@emotion/styled'
import { ButtonI18n } from '@unowmooc/buttons'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { Field } from '@unowmooc/form'
import Textarea from 'components/Textarea'
import { InputText } from '@unowmooc/inputs'
import { FormProvider, useForm } from 'react-hook-form'
import Modal from 'components/Modal'
import { useMutation } from '@apollo/react-hooks'
import { captureException } from '@sentry/browser'
import { colors, mq } from '@unowmooc/themes'
import Alert from '@unowmooc/alert'
import { css } from '@emotion/core'
import gql from 'graphql-tag'

const SEND_MESSAGE_TO_CUSTOM_SUPPORT = gql`
  mutation SendMessageToCustomerSupport($subject: String!, $message: String!) {
    sendMessageToCustomerSupport(subject: $subject, message: $message)
  }
`

const Title = styled.h2`
  margin-bottom: 50px;
  text-align: center;
`

const FormFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${mq.sm(css`
    flex-direction: column-reverse;
    & > button:first-child {
      margin-top: 20px;
    }
  `)};
`

const StyledAlert = styled(Alert)`
  margin-bottom: 35px;
  padding: 40px 35px;
`

const GlobalError = styled.div`
  margin-bottom: 30px;
  color: ${colors.pastelRed};
`

const RequiredFieldHelper = styled.p`
  font-size: 14px;
  color: ${colors.pastelRed};
  margin-top: 20px;
`

const SupportContactModal = ({ onClose }) => {
  const [submitLoading, setSubmitLoading] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [sendMessageToCustomerSupport] = useMutation(SEND_MESSAGE_TO_CUSTOM_SUPPORT)

  const methods = useForm({
    mode: 'onSubmit ',
    reValidateMode: 'onSubmit',
    shouldUnregister: true,
  })

  const handleSubmit = event => {
    methods.clearErrors('FORM_ERROR')

    return methods.handleSubmit(async data => {
      try {
        setSubmitLoading(true)
        await sendMessageToCustomerSupport({ variables: data })
        setSubmitSuccess(true)
      } catch (error) {
        setSubmitLoading(false)
        methods.setError('FORM_ERROR', error)
        captureException(new Error(`[sendMessageToCustomerSupport]: ${JSON.stringify(error)}`))
      }
    })(event)
  }

  if (!submitSuccess) {
    return (
      <Modal>
        <Title>
          <FormattedMessage id="support_contact.title" />
        </Title>

        {methods.errors?.FORM_ERROR && (
          <GlobalError>
            <FormattedMessage id="errors.unexpected_error" />
          </GlobalError>
        )}

        <FormProvider {...methods}>
          <form onSubmit={handleSubmit}>
            <Field
              required={<FormattedMessage id="errors.isRequired" />}
              name="subject"
              label={<FormattedMessage id="support_contact.form.subject" />}
            >
              <InputText />
            </Field>
            <Field
              required={<FormattedMessage id="errors.isRequired" />}
              name="message"
              label={<FormattedMessage id="support_contact.form.message" />}
            >
              <Textarea minRows={5} maxRows={10} />
            </Field>

            <FormFooter>
              <ButtonI18n i18nKey="commons.cancel" type="button" variant="secondary" onClick={onClose} />
              <ButtonI18n
                i18nKey="support_contact.form.submit"
                type="submit"
                variant="primary"
                loading={submitLoading}
                disabled={submitLoading}
              />
            </FormFooter>

            <RequiredFieldHelper>
              <FormattedMessage id="support_contact.form.required_helper" />
            </RequiredFieldHelper>
          </form>
        </FormProvider>
      </Modal>
    )
  }

  return (
    <Modal>
      <Title>
        <FormattedMessage id="support_contact.thanks_title" />
      </Title>
      <StyledAlert>
        <FormattedMessage id="support_contact.thanks" />
      </StyledAlert>
      <FormFooter>
        <span />
        <ButtonI18n onClick={onClose} i18nKey="support_contact.close" variant="primary" />
      </FormFooter>
    </Modal>
  )
}

SupportContactModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default SupportContactModal
