import styled from '@emotion/styled'

const SurveyQuestionLabel = styled.h3`
  margin-bottom: 20px;

  > p,
  > span p {
    display: inline;
    font-family: Nunito;
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;
    color: ${({ theme: { colors } }) => colors.woodsmoke};
  }
`

export default SurveyQuestionLabel
