import _ from 'lodash'
import Tag from '@unowmooc/tags'

/**
 * @param Object participation
 */
export const isParticipationStarted = participation => {
  const { badges, progressions } = participation

  return !_.isEmpty(badges) || !_.isEmpty(progressions)
}

export const getParticipantStatusTheme = ({ status }) => {
  switch (status) {
    case 'inactive':
      return Tag.themes.lightRed
    case 'waiting':
      return Tag.themes.lightGrey
    case 'active':
    case 'registered':
      return Tag.themes.lightGreen
    case 'finish':
      return Tag.themes.lightGrey
    default:
      // do nothing
      return undefined
  }
}

export const isModuleZeroCompleted = (course, progressions) => {
  const sections = _.get(course, 'moduleZero.sections')
  if (!sections) {
    return true
  }

  const sortedModuleZeroSections = _.orderBy(sections, ['position'], ['desc'])

  return !!_.find(progressions, ({ section: { id } }) => id === _.get(sortedModuleZeroSections, '[0].id'))
}

/**
 * @param {Object[]} progressions
 */
export const getMostAdvancedSeenSection = progressions => {
  const sortedProgressions = _.orderBy(progressions, ['section.module.position', 'section.position'], ['desc', 'desc'])

  return _.get(sortedProgressions, '[0].section')
}

export const mockParticipation = session => ({
  id: null,
  session,
  badges: [],
  progressions: [],
  advancement: {
    nbRemainedExamAttempt: 3,
  },
  advancementStatus: null,
  finalExamAttempts: [],
  lastFinalExamQuizAttempt: null,
  certificateNumber: null,
  certificatedValidatedAt: null,
  nbUnreadComments: 0,
})
