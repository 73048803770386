import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl'
import { Oval } from '@unowmooc/react-ui-kit'
import { colors } from 'styles-resources'

const Wrapper = styled.span`
  color: ${colors.midGray};
  display: inline;
`

const StyledOval = styled(Oval)`
  margin-right: 10px;
`

class NbParticipantsAdvancement extends Component {
  get color() {
    const { status } = this.props

    switch (status) {
      case 'waiting':
        return colors.manatee
      case 'certified':
      case 'finisher':
        return colors.unowOrange
      case 'up_to_date':
      case 'registered':
        return colors.unowGreen
      case 'late':
        return colors.perfume
      default:
        return colors.bombay
    }
  }

  render() {
    const { nbParticipants, status, className } = this.props
    const { color } = this

    return (
      <Wrapper className={className}>
        <StyledOval color={color} />
        <FormattedMessage id={`sessions.session.nb_${status}`} values={{ nbParticipants }} />
      </Wrapper>
    )
  }
}

NbParticipantsAdvancement.defaultProps = {
  className: undefined,
}

NbParticipantsAdvancement.propTypes = {
  nbParticipants: PropTypes.number.isRequired,
  status: PropTypes.oneOf(['waiting', 'registered', 'finisher', 'certified', 'up_to_date', 'late']).isRequired,
  className: PropTypes.string,
}

export default NbParticipantsAdvancement
