import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const Wrapper = styled.div`
  display: inline-flex;
  letter-spacing: -5px;
`

const TagEqual = ({ size }) => (
  <Wrapper>
    &nbsp;
    <svg width={size} height={size} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12.5" cy="12.5" r="12.5" fill="#7794F6" fillOpacity="0.25" />
      <path d="M7.5 13.4096H17.5V16.0691H7.5V13.4096ZM7.5 8.75H17.5V11.4096H7.5V8.75Z" fill="#7794F6" />
    </svg>
  </Wrapper>
)

TagEqual.defaultProps = {
  size: 25,
}

TagEqual.propTypes = {
  size: PropTypes.number,
}

export default TagEqual
