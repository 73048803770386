import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Flex } from 'reflexbox'
import Icon from '@unowmooc/icons'
import { Route, Switch } from 'react-router-dom'
import I18nProvider from '@unowmooc/i18n'

import Helmet from 'components/HelmetIntl'
import Title from 'components/Title'
import { getCoursesPlanning } from 'modules/course/course.utils'

import StepsLine from './stepsLine'
import Session from './session'
import Method from './method'
import Add from './add'
import Validation from './validation'
import Confirm from './confirm'

const RegistrationPage = () => (
  <>
    <Helmet title="page_titles.supervisor.registered_users.title" />
    <Flex justifyContent="space-between" alignItems="baseline">
      <Title>
        <FormattedMessage id="registered_users.page_title" />
      </Title>
      <a href={getCoursesPlanning()} target="_blank" rel="noopener noreferrer">
        <Icon icon="eye" color="unowGree" fontSize="24px" lineHeight="24px" mr="10px" />
        <FormattedMessage id="supervisor.registration.links.display_sessions_planning" />
      </a>
    </Flex>

    <StepsLine />
    <Switch>
      <Route exact path={I18nProvider.translatePath('/supervisor/registered_users/session')} component={Session} />
      <Route path={I18nProvider.translatePath('/supervisor/registered_users/method')} component={Method} />
      <Route path={I18nProvider.translatePath('/supervisor/registered_users/add/:mode')} component={Add} />
      <Route
        path={I18nProvider.translatePath('/supervisor/registered_users/validation/:mode')}
        component={Validation}
      />
      <Route path={I18nProvider.translatePath('/supervisor/registered_users/confirm/:importId?')} component={Confirm} />
    </Switch>
  </>
)

export default RegistrationPage
