import axios from 'axios'
import { getToken } from '@unowmooc/utils/auth'
import config from 'config'

let token

try {
  token = getToken()
} catch (error) {
  // eslint-disable-next-line
  console.log(error)
}

const instance = axios.create({
  baseURL: config.env.GRAPHQL_API_URL,
  timeout: 5000,
  headers: {
    authorization: token ? `Bearer ${token}` : null,
    'content-type': 'application/json',
  },
})

export default instance
