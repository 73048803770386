import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { ButtonI18n } from '@unowmooc/buttons'
import { useMutation } from '@apollo/react-hooks'
import { captureException } from '@sentry/browser'
import { useTracking } from 'modules/tracking'
import { MARK_CONVERSATION_AS_RESOLVED, MARK_CONVERSATION_AS_UNRESOLVED } from '../mutations'

const ResovlerButton = ({ conversationId, onSubmitSuccess, isResolved, disabled }) => {
  const { sendEvent } = useTracking()
  const [internalIsResolved, setInternalIsResolved] = useState(isResolved)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [markConversationAsResolved] = useMutation(MARK_CONVERSATION_AS_RESOLVED)
  const [markConversationAsUnresolved] = useMutation(MARK_CONVERSATION_AS_UNRESOLVED)

  useEffect(() => {
    setInternalIsResolved(isResolved)
  }, [isResolved])

  const handleClick = async () => {
    try {
      setSubmitLoading(true)
      const action = internalIsResolved ? markConversationAsUnresolved : markConversationAsResolved
      const { data: res } = await action({ variables: { conversationId } })
      if (res.markConversationAsResolved) {
        sendEvent(
          'conversation.click_on_resolve_conversation',
          JSON.stringify({
            courseIdentifier: res.markConversationAsResolved.session.course.identifier,
            sessionIdentifier: res.markConversationAsResolved.session.identifier,
            conversationId: res.markConversationAsResolved.id,
          }),
        )
      }
      if (res.markConversationAsUnresolved) {
        sendEvent(
          'conversation.click_on_unresolve_conversation',
          JSON.stringify({
            courseIdentifier: res.markConversationAsUnresolved.session.course.identifier,
            sessionIdentifier: res.markConversationAsUnresolved.session.identifier,
            conversationId: res.markConversationAsUnresolved.id,
          }),
        )
      }
      setInternalIsResolved(!internalIsResolved)
      await onSubmitSuccess()
    } catch (error) {
      captureException(
        new Error(
          `[${
            internalIsResolved ? 'markConversationAsUnresolved' : 'markConversationAsResolved'
          } ${conversationId}]: ${JSON.stringify(error)}`,
        ),
      )
    }

    setSubmitLoading(false)
  }

  return (
    <ButtonI18n
      onClick={handleClick}
      i18nKey={`conversation.mark_as_${internalIsResolved ? 'undone' : 'done'}`}
      disabled={disabled || submitLoading}
      loading={submitLoading}
    />
  )
}

ResovlerButton.defaultProps = {
  disabled: false,
}

ResovlerButton.propTypes = {
  conversationId: PropTypes.string.isRequired,
  isResolved: PropTypes.bool.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default ResovlerButton
