import React from 'react'
import PropTypes from 'prop-types'
import { propType, filter } from 'graphql-anywhere'
import { toInlineFragment } from 'fraql'
import gql from 'graphql-tag'
import { Redirect } from 'react-router-dom'
import I18nProvider from '@unowmooc/i18n'
import { useQuiz } from 'modules/quiz/provider'
import { QUIZ_ATTEMPT_STATUS } from 'modules/quiz/constants'
import Success from './Success'
import Failure from './Failure'

const ResultsPage = ({ hasBadge, module, session }) => {
  const { status } = useQuiz()

  if (status === QUIZ_ATTEMPT_STATUS.SUCCESS || hasBadge) {
    return (
      <Success module={filter(Success.fragments.module, module)} session={filter(Success.fragments.session, session)} />
    )
  }

  if (status === QUIZ_ATTEMPT_STATUS.FAILURE) {
    return <Failure />
  }

  return <Redirect to={I18nProvider.translatePath('introduction')} />
}

ResultsPage.fragments = {
  module: gql`
    fragment _ on Module {
      ${toInlineFragment(Success.fragments.module)}
    }
  `,
  session: gql`
   fragment _ on Session {
     ${toInlineFragment(Success.fragments.session)}
   }
 `,
}

ResultsPage.propTypes = {
  module: propType(ResultsPage.fragments.module).isRequired,
  session: propType(ResultsPage.fragments.session).isRequired,
  hasBadge: PropTypes.bool.isRequired,
}

export default ResultsPage
