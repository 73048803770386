import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import s from 'underscore.string'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import Form from './Form'

class FormContainer extends Component {
  get argumentName() {
    const { typeName } = this.props

    return s.decapitalize(typeName)
  }

  get mutationArgumentName() {
    const { mutationArgumentName } = this.props

    if (mutationArgumentName) {
      return mutationArgumentName
    }

    const { argumentName } = this

    return argumentName
  }

  get query() {
    const { fragment, queryWithoutId } = this.props
    const { argumentName } = this

    if (queryWithoutId) {
      return gql`
        query FormData {
          object: ${argumentName} {
            ...FormDataFragment
          }
        }
        ${fragment}
      `
    }

    return gql`
      query FormData($id: ID!) {
        object: ${argumentName}(id: $id) {
          ...FormDataFragment
        }
      }
      ${fragment}
    `
  }

  get createMutation() {
    const { typeName, fragment } = this.props
    const { mutationArgumentName } = this
    const mutationName = `create${typeName}`

    return gql`
      mutation create($${mutationArgumentName}: ${typeName}Input!) {
        object: ${mutationName}(${mutationArgumentName}: $${mutationArgumentName}) {
          ...FormDataFragment
        }
      }
      ${fragment}
    `
  }

  get updateMutation() {
    const { typeName, fragment } = this.props
    const { mutationArgumentName } = this
    const mutationName = `update${typeName}`

    return gql`
      mutation update($${mutationArgumentName}: ${typeName}Input!) {
        object: ${mutationName}(${mutationArgumentName}: $${mutationArgumentName}) {
          ...FormDataFragment
        }
      }
      ${fragment}
    `
  }

  get deleteMutation() {
    const { typeName } = this.props
    const mutationName = `delete${typeName}`

    return gql`
      mutation delete($id: ID!) {
        ${mutationName}(id: $id) {
          id
        }
      }
    `
  }

  get graphqlHocs() {
    const { id, withDelete, queryWithoutId, mutationOptions } = this.props

    if (id) {
      const queryHoc = queryWithoutId ? graphql(this.query) : graphql(this.query, { options: { variables: { id } } })

      const graphqlHocs = [queryHoc, graphql(this.updateMutation, { name: 'update', options: mutationOptions })]

      if (withDelete) {
        graphqlHocs.push(graphql(this.deleteMutation, { name: 'deleteMutation' }))
      }

      return _.flow(graphqlHocs)
    }

    return graphql(this.createMutation, { name: 'create', options: mutationOptions })
  }

  render() {
    const FormWithDataAndMutations = this.graphqlHocs(Form)

    return <FormWithDataAndMutations {...this.props} argumentName={this.mutationArgumentName} />
  }
}

FormContainer.defaultProps = {
  mutationArgumentName: undefined,
  id: undefined,
  withDelete: true,
  queryWithoutId: false,
  mutationOptions: undefined,
}

FormContainer.propTypes = {
  fragment: PropTypes.shape().isRequired,
  typeName: PropTypes.string.isRequired,
  mutationArgumentName: PropTypes.string,
  id: PropTypes.string,
  withDelete: PropTypes.bool,
  queryWithoutId: PropTypes.bool,
  mutationOptions: PropTypes.oneOfType([PropTypes.func, PropTypes.shape()]),
}

export default FormContainer
