import React from 'react'
import { propType } from 'graphql-anywhere'
import gql from 'graphql-tag'

import { toInlineFragment } from 'fraql'
import ChallengeAcceptationBlock from './Acceptation'
import ChallengeValidationBlock from './Validation'

const ChallengeBlock = ({ block }) => {
  const challengeBlockType = block.type

  if (challengeBlockType === 'challenge_validation') {
    return <ChallengeValidationBlock challenges={block.challenges} />
  }

  if (challengeBlockType !== 'challenge_list' && challengeBlockType !== 'challenge_list_with_buttons') {
    return {}
  }

  const withButtons = challengeBlockType === 'challenge_list_with_buttons'

  return <ChallengeAcceptationBlock challenges={block.challenges} withButtons={withButtons} />
}

ChallengeBlock.fragment = toInlineFragment(
  gql`
    fragment _ on Block {
      ... on NewChallengeBlock {
        blockType
        type
        challenges {
          id
          label
          icon
          color
          why
          who
          how
        }
      }
    }
  `,
)

ChallengeBlock.propTypes = {
  block: propType(ChallengeBlock.fragment).isRequired,
}

export default ChallengeBlock
