import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Field } from '@unowmooc/form'
import Textarea from 'components/Textarea'
import { InputText } from '@unowmooc/inputs'
import { useUserContext } from 'store/UserProvider'
import SelectParticipantUser from 'components/Select/ParticipantUser'
import { FormattedMessage } from 'react-intl'
import gql from 'graphql-tag/lib/graphql-tag.umd'
import { useFormContext } from 'react-hook-form'
import { renderLabel } from 'components/Select/Session'
import SelectAutoFillField from 'components/SelectAutoFillField'
import WriteMessageModalFormContainer from './WriteMessageModalFormContainer'

const WRITE_MESSAGE_SESSION_QUERY = gql`
  query writeMessageExpert_SessionFieldQuery($filters: Json) {
    select: sessions(filters: $filters) {
      items {
        id
        identifier
        startAt
        nbParticipants
      }
    }
  }
`

/**
 * Modale affichée à un expert pour écrire à un particpant.
 */
const WriteMessageExpertModalFormRenderer = () => {
  const { watch, setValue } = useFormContext()

  const {
    user: { id: authenticatedUserId },
  } = useUserContext()

  const watchSession = watch('session')
  useEffect(() => {
    if (watchSession) {
      setValue('participant', undefined)
    }
  })

  return (
    <>
      <SelectAutoFillField
        name="session"
        query={WRITE_MESSAGE_SESSION_QUERY}
        label={<FormattedMessage id="conversation.form.session" />}
        filters={{
          animations_user_id: authenticatedUserId,
          animations_isMain: true,
          conversationsEnabled: true,
          canceledAt: null,
        }}
        getOptions={queryResult => queryResult.data.select.items?.filter(option => option.nbParticipants > 0)}
        selectProps={{
          placeholder: <FormattedMessage id="commons.search" />,
          getOptionLabel: renderLabel,
          noOptionsMessage: () => <FormattedMessage id="select.default_no_result" />,
        }}
      />
      <Field
        required={<FormattedMessage id="errors.isRequired" />}
        name="participant"
        label={<FormattedMessage id="conversation.form.participant" />}
      >
        <SelectParticipantUser
          disabled={!watchSession}
          filters={{ session_id: watchSession?.id, type: { eq: 'project' }, canceledAt: null }}
        />
      </Field>
      <Field
        required={<FormattedMessage id="errors.isRequired" />}
        name="subject"
        label={<FormattedMessage id="conversation.form.subject" />}
      >
        <InputText />
      </Field>
      <Field
        required={<FormattedMessage id="errors.isRequired" />}
        name="content"
        label={<FormattedMessage id="conversation.form.message" />}
      >
        <Textarea minRows={5} maxRows={10} />
      </Field>
    </>
  )
}

const WriteMessageExpertModalForm = ({ onClose, onSubmitSuccess }) => {
  const prepareDataOnSubmit = data => {
    const sessionId = data.session.id
    const memberUserIds = [data.participant.user.id]
    const { subject } = data
    const { content } = data

    return { sessionId, memberUserIds, subject, content }
  }

  return (
    <WriteMessageModalFormContainer
      formTitle={<FormattedMessage id="conversation.write_message_label.expert" />}
      onClose={onClose}
      onSubmitSuccess={onSubmitSuccess}
      prepareDataOnSubmit={prepareDataOnSubmit}
      render={methods => <WriteMessageExpertModalFormRenderer formMethods={methods} />}
    />
  )
}

WriteMessageExpertModalForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
}

export default WriteMessageExpertModalForm
