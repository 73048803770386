import React from 'react'
import gql from 'graphql-tag'
import { Flex } from 'reflexbox'
import { Alert, AwesomeUnowLoader, DataGrid } from '@unowmooc/react-ui-kit'
import DataGridCell from '@unowmooc/react-ui-kit/lib/DataGrid/Cell'
import { FormattedMessage } from 'react-intl'

import { useQuery } from 'react-apollo'
import StreamlineIcon from '@unowmooc/icons'
import styled from '@emotion/styled'

const QUERY_REPORTS = gql`
  query ExpertReports {
    expertReports {
      title
      url
      type
      description
    }
  }
`

const ReportIcon = styled(StreamlineIcon)`
  font-size: 22px;
  padding-right: 20px;
`

const ReportDescription = styled('p')`
  padding-top: 15px;
  padding-bottom: 10px;
  li {
    list-style-type: square;
  }
`

const ReportsPage = () => {
  const { data, loading } = useQuery(QUERY_REPORTS)

  if (loading) {
    return (
      <Flex justifyContent="center">
        <AwesomeUnowLoader />
      </Flex>
    )
  }

  return (
    <>
      <h2>
        <FormattedMessage id="reports.title" />
      </h2>
      <Alert style={{ marginTop: '30px', marginBottom: '30px' }}>
        <FormattedMessage id="reports.alert" />
      </Alert>
      <DataGrid data={data.expertReports}>
        <DataGridCell
          label={<FormattedMessage id="reports.fields.title" />}
          render={report => (
            <>
              {report.type === 'dashboard' && <ReportIcon icon="9dots" />}
              {report.type === 'question' && <ReportIcon icon="survey" />}
              <a href={report.url} target={`report_${report.id}`}>
                {report.title}
              </a>
              <br />
              <ReportDescription dangerouslySetInnerHTML={{ __html: report.description }} />
            </>
          )}
        />
      </DataGrid>
    </>
  )
}

export default ReportsPage
