import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withRouter } from 'react-router-dom'

export class QueryStringFilter extends Component {
  constructor(props) {
    super(props)

    const searchParams = new URLSearchParams(props.location.search)

    this.updateValueDebounce = _.debounce(this.updateValue, props.debounce)
    this.state = {
      value: searchParams.get(props.name) || '',
    }
  }

  componentDidUpdate(prevProps) {
    const { debounce } = this.props

    if (debounce !== prevProps.debounce) {
      this.updateValueDebounce = _.debounce(this.updateValue, debounce)
    }
  }

  handleChange = event => {
    const values = event && event.target ? event.target.value : event
    let value = values

    if (_.isArray(value)) {
      value = values.map(object => _.get(object, 'value')).join('+')
    }

    this.setState({ value })
    this.updateValueDebounce(value)
  }

  updateValue = value => {
    const { history, location, name } = this.props

    const searchParams = new URLSearchParams(location.search)

    if (value) {
      searchParams.set(name, value)
    } else {
      searchParams.delete(name)
    }

    searchParams.delete('limit')
    searchParams.delete('pageIndex')

    const search = searchParams.toString()

    history.push({
      pathname: location.pathname,
      search: search ? `?${searchParams.toString()}` : '',
    })
  }

  render() {
    const { children } = this.props
    const { value } = this.state

    return React.cloneElement(children, {
      ...children.props,
      value,
      onChange: this.handleChange,
    })
  }
}

QueryStringFilter.defaultProps = {
  debounce: 0,
}

QueryStringFilter.propTypes = {
  debounce: PropTypes.number,
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  location: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
}

export default withRouter(QueryStringFilter)
