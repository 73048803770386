import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import gql from 'graphql-tag'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'
import { useQuiz } from 'modules/quiz/provider'
import { QUIZ_ATTEMPT_STATUS } from 'modules/quiz/constants'

const Wrapper = styled.div`
  text-align: center;
`

const YourScore = styled.p`
  ${mq.sm(css`
    font-size: 16px;
    line-height: 32px;
  `)};
`

const Total = styled.strong`
  display: block;
  font-size: 22px;
  line-height: 32px;
  color: ${({ success, theme: { colors } }) => (success ? colors.unowGreen : colors.bittersweet)};
`

const Score = ({ participant: { lastFinalExamQuizAttempt }, className }) => {
  const quizState = useQuiz()
  const score = quizState.score ?? lastFinalExamQuizAttempt?.score ?? 0
  const status = quizState.status ?? lastFinalExamQuizAttempt?.status ?? 0

  return (
    <Wrapper className={className}>
      <YourScore>
        <FormattedMessage id="final_exam.results.your_score" />
      </YourScore>
      <Total success={status === QUIZ_ATTEMPT_STATUS.SUCCESS}>
        <FormattedMessage
          id="final_exam.results.score"
          values={{ nbCorrectAnswers: score, nbQuestions: quizState.questionCounter }}
        />
      </Total>
    </Wrapper>
  )
}

Score.fragments = {
  participant: gql`
    fragment ScoreFragment on Participant {
      id
      lastFinalExamQuizAttempt {
        id
        score
        status
      }
    }
  `,
}

Score.defaultProps = {
  className: undefined,
}

Score.propTypes = {
  participant: propType(Score.fragments.participant).isRequired,
  className: PropTypes.string,
}

export default Score
