import config from 'config'

export const getIndividualCourseCertificateUrl = idParticipant =>
  `${config.env.GRAPHQL_API_URL}/documents/individual-course-certificate?participant=${idParticipant}`

export const advancementStatus = {
  UP_TO_DATE: 'up_to_date',
  NOT_STARTED: 'not_started',
  LATE: 'late',
  FINISHER_NOT_CERTIFIED: 'finisher_not_certified',
  CERTIFIED: 'certified',
  CANCELED: 'canceled',
}
