import React from 'react'
import { propType, filter } from 'graphql-anywhere'
import gql from 'graphql-tag'
import { toInlineFragment } from 'fraql'
import { Query } from 'react-apollo'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'
import { Row, Col } from 'react-grid-system'
import InfiniteScroll from 'react-infinite-scroll-component'
import { FormattedMessage } from 'react-intl'
import Card from './Card'
import Placeholders from './Placeholders'

const Title = styled.h4`
  margin-bottom: 36px;
  text-align: center;
`

const StyledInfiniteScroll = styled(InfiniteScroll)`
  overflow: unset !important;
`

const ColCard = styled(Col)`
  margin-bottom: 20px;
  padding-left: 10px !important;
  padding-right: 10px !important;

  ${mq.sm(css`
    padding-left: 15px !important;
    padding-right: 15px !important;
  `)};
`

const StyledCard = styled(Card)`
  width: 100%;
  height: 100%;
`

const limitByQuery = 8

const queryFilters = idSession =>
  JSON.stringify({
    session_id: idSession,
    canceledAt: null,
    and: [{ type: { neq: 'demo' } }, { type: { neq: 'observer' } }],
  })

const Trombinoscope = ({ session }) => {
  const queryVariables = {
    filters: queryFilters(session.id),
    limit: limitByQuery,
    sort: ['user.firstName ASC', 'user.lastName ASC'],
  }

  return (
    <Query
      query={gql`
      query Trombinoscope($limit: Int!, $offset: Int!, $filters: Json, $sort: [String]) {
        participants(limit: $limit, offset: $offset, filters: $filters, sort: $sort) {
          items {
            id
            ${toInlineFragment(Card.fragments.participant)}
          }
          _meta {
            total
          }
        }
      }
    `}
      variables={{ ...queryVariables, offset: 0 }}
    >
      {({ loading, data, fetchMore }) => {
        if (loading) {
          return <Placeholders length={limitByQuery} />
        }

        if (data.participants.items.length === 0) {
          return (
            <Title>
              <FormattedMessage id="courses.course.trombinoscope.empty_label" />
            </Title>
          )
        }

        return (
          <StyledInfiniteScroll
            dataLength={data.participants.items.length}
            hasMore={data.participants._meta.total > data.participants.items.length}
            scrollThreshold={0.7}
            next={() =>
              fetchMore({
                variables: {
                  ...queryVariables,
                  offset: data.participants.items.length,
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                  const { participants } = previousResult
                  const { participants: newParticipants } = fetchMoreResult

                  return {
                    participants: {
                      ...newParticipants,
                      items: [...participants.items, ...newParticipants.items],
                    },
                  }
                },
              })
            }
            loader={<Placeholders length={limitByQuery} />}
          >
            <Row>
              {data.participants.items.map(participant => (
                <ColCard xs={12} sm={6} md={6} lg={4} xl={3} key={participant.id}>
                  <StyledCard participant={filter(Card.fragments.participant, participant)} />
                </ColCard>
              ))}
            </Row>
          </StyledInfiniteScroll>
        )
      }}
    </Query>
  )
}

Trombinoscope.fragments = {
  session: gql`
    fragment _ on Session {
      id
    }
  `,
}

Trombinoscope.propTypes = {
  session: propType(Trombinoscope.fragments.session).isRequired,
}

export { queryFilters }

export default Trombinoscope
