import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const Wrapper = styled.div`
  display: flex;
  width: 42px;
  height: 42px;
  margin-right: 10px;
  background-color: ${({ background }) => background};
  border-radius: 50%;
  align-items: center;
  justify-content: center;
`

const CircleBackground = ({ children, background }) => <Wrapper background={background}>{children}</Wrapper>

CircleBackground.propTypes = {
  background: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default CircleBackground
