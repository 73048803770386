import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import _ from 'lodash'
import { Select, MultiSelect } from '@unowmooc/react-ui-kit'
import I18nProvider from '@unowmooc/i18n'

class SelectGraphql extends Component {
  constructor(props) {
    super(props)

    const { remote } = props

    this.handleInputChange = this.handleInputChange.bind(this)

    this.queryObserver = null
    this.querySubscription = null

    this.debouncedFetchQuery = _.debounce((fetchProps, search) => this.fetchQuery(fetchProps, search), 300)
    this.debouncedRemoteSearch = _.debounce(
      ({ filters, sort }, search) =>
        this.queryObserver.setVariables({
          search,
          filters: filters ? JSON.stringify(filters) : undefined,
          sort,
          // set limit if remote is enabled
          limit: remote ? 30 : undefined,
        }),
      300,
    )

    this.state = { options: [], loading: false, search: null }
  }

  componentDidMount() {
    this.fetchQuery(this.props)
  }

  componentWillReceiveProps(nextProps) {
    const { filters } = this.props
    const { filters: nextFilters } = nextProps

    if (!_.isEqual(filters, nextFilters)) {
      if (this.queryObserver) {
        this.queryObserver.setVariables({ filters: nextFilters ? JSON.stringify(nextFilters) : undefined })
      } else {
        this.fetchQuery(nextProps)
      }
    }
  }

  componentWillUnmount() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe()
    }
  }

  get computedProps() {
    const { computedProps } = this.props
    if (!computedProps) {
      return {}
    }

    const { options } = this.state
    const { value } = this.props

    return computedProps(value, options)
  }

  get order() {
    const { order } = this.props

    if (!order) {
      return undefined
    }

    const orderFormatted = {
      fields: [],
      orders: [],
    }

    order.forEach(value => {
      const [field, fieldOrder] = value.split(' ')

      orderFormatted.fields.push(field)
      orderFormatted.orders.push(_.lowerCase(fieldOrder))
    })

    return orderFormatted
  }

  fetchQuery({ client, queryName, fragment, sort, filters, remote }, search) {
    this.setState({ loading: true })

    const query = gql`
      query ${queryName}($offset: Int, $limit: Int, $sort: [String], $search: String, $filters: Json) {
        options: ${queryName}(offset: $offset, limit: $limit, sort: $sort, search: $search, filters: $filters) {
          items {
            ${fragment.definitions.map(definition => `...${definition.name.value}`)}
          }
        }
      }
      ${fragment}
    `

    const variables = {
      filters: filters ? JSON.stringify(filters) : undefined,
      sort,
      search,
      // set limit if remote is enabled
      limit: remote ? 30 : undefined,
    }

    this.queryObserver = client.watchQuery({ query, fetchPolicy: 'cache-and-network', variables })

    this.querySubscription = this.queryObserver.subscribe(response => {
      const options = _.get(response, 'data.options.items', [])

      const { order } = this

      this.setState({ options: order ? _.orderBy(options, order.fields, order.orders) : options, loading: false })
    })
  }

  handleInputChange(search) {
    const { remote } = this.props

    if (remote) {
      this.setState({ search })

      const { search: searchState } = this.state

      if (search !== searchState) {
        this.setState({ loading: true })

        if (this.queryObserver) {
          this.debouncedRemoteSearch(this.props, search)
        } else {
          // need to initialize the query observer
          this.debouncedFetchQuery(this.props, search)
        }
      }
    }
  }

  render() {
    const { options, loading } = this.state
    const { valueProperty, labelProperty, multi, sort, value, queryName, remote, placeholder, ...props } = this.props

    const SelectTag = multi ? MultiSelect : Select

    return (
      <SelectTag
        {..._.omit(props, ['client', 'computedProps', 'filters', 'fragment'])}
        labelKey={labelProperty}
        valueKey={valueProperty}
        options={options}
        filterOption={remote ? () => true : undefined}
        value={_.isObject(value) ? value : undefined}
        onInputChange={this.handleInputChange}
        isLoading={loading}
        placeholder={placeholder || (remote ? I18nProvider.formatMessage({ id: 'commons.search' }) : undefined)}
        {...this.computedProps}
      />
    )
  }
}

SelectGraphql.defaultProps = {
  valueProperty: 'id',
  labelProperty: 'label',
  filters: undefined,
  multi: false,
  computedProps: undefined,
  value: undefined,
  sort: undefined,
  order: undefined,
  remote: false,
  placeholder: undefined,
}

SelectGraphql.propTypes = {
  multi: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({}), PropTypes.arrayOf(PropTypes.shape({}))]),
  computedProps: PropTypes.func,
  client: PropTypes.shape({}).isRequired,
  queryName: PropTypes.string.isRequired,
  fragment: PropTypes.shape({}).isRequired,
  valueProperty: PropTypes.string,
  labelProperty: PropTypes.string,
  filters: PropTypes.shape({}),
  sort: PropTypes.arrayOf(PropTypes.string),
  order: PropTypes.arrayOf(PropTypes.string),
  remote: PropTypes.bool,
  placeholder: PropTypes.node,
}

export default withApollo(SelectGraphql)
