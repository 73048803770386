import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { Row, Col } from 'react-grid-system'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { colors, rgba, mq } from 'styles-resources'
import { LinkButton } from '@unowmooc/react-ui-kit'
import StreamlineIcon from '@unowmooc/icons'
import I18nProvider from '@unowmooc/i18n'

const Wrapper = styled.div`
  padding: 48px 56px;
  background-color: ${rgba(colors.unowOrange, 0.1)};

  ${mq.sm(css`
    padding: 24px 20px;
  `)};
`

const Title = styled.h3`
  margin-bottom: 16px;
  font-weight: 600;

  ${mq.sm(css`
    font-weight: bold;
    text-align: center;
  `)};
`

const Description = styled.p`
  font-size: 18px;
  line-height: 32px;

  ${mq.sm(css`
    font-size: 14px;
    line-height: 18px;
    text-align: center;
  `)};
`

const ColRight = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${mq.sm(css`
    margin-top: 24px;
  `)};
`

const ArrowIcon = styled(StreamlineIcon)`
  margin-right: 10px;
  font-size: 24px;
  line-height: 15px;
`

const CatalogLink = styled(LinkButton, { shouldForwardProp: () => true })`
  ${mq.sm(css`
    width: 100%;
    text-align: center;
  `)};
`

const BrowseCatalog = ({ className }) => (
  <Wrapper className={className}>
    <Row>
      <Col xs={12} md={8}>
        <Title>
          <FormattedMessage id="dashboard.browse_catalog.title" />
        </Title>
        <Description>
          <FormattedMessage id="dashboard.browse_catalog.description" />
        </Description>
      </Col>
      <ColRight xs={12} md={4}>
        <CatalogLink to={I18nProvider.getLinkRoute('/catalog')} tag={Link} theme="highlight" size="large">
          <ArrowIcon icon="arrow-right" />
          <FormattedMessage id="dashboard.browse_catalog.know_more" />
        </CatalogLink>
      </ColRight>
    </Row>
  </Wrapper>
)

BrowseCatalog.defaultProps = {
  className: undefined,
}

BrowseCatalog.propTypes = {
  className: PropTypes.string,
}

export default BrowseCatalog
