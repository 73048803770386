import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { EmptyState as UIEmptyState, LinkButton } from '@unowmooc/react-ui-kit'
import I18nProvider from '@unowmooc/i18n'

// use <span> with "display:block;" instead of <div> because UIEmptyState wrap it inside a <p>
const Message = styled.span`
  display: block;
  margin-bottom: 24px;
`

const EmptyState = ({ status }) => (
  <UIEmptyState
    message={
      <>
        <Message>
          <FormattedMessage id={`sessions.empty_state.${status}`} />
        </Message>
        {status !== 'all' && (
          <LinkButton theme="highlight" tag={Link} to={I18nProvider.getLinkRoute('/expert/sessions/all')}>
            <FormattedMessage id="sessions.empty_state.see_all" />
          </LinkButton>
        )}
      </>
    }
  />
)

EmptyState.propTypes = {
  status: PropTypes.string.isRequired,
}

export default EmptyState
