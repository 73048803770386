import React from 'react'
import PropTypes from 'prop-types'
import BasicContent from 'components/BasicContent'
import svgAtomicBomb from './assets/atomic-bomb.svg'

const ErrorBloc = ({ i18nMessage, className }) => (
  <BasicContent i18nTitle={i18nMessage} imgSrc={svgAtomicBomb} className={className} />
)

ErrorBloc.defaultProps = {
  className: undefined,
}

ErrorBloc.propTypes = {
  i18nMessage: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default ErrorBloc
