import React from 'react'
import styled from '@emotion/styled'
import { Row, Col } from 'react-grid-system'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { LinkButton } from '@unowmooc/react-ui-kit'
import BasicContent from 'components/BasicContent'
import I18nProvider from '@unowmooc/i18n'

const RowWrapper = styled(Row)`
  padding-top: 100px;
  text-align: center;
`

const StyledBasicContent = styled(BasicContent)`
  margin-bottom: 50px;
`

const SessionCanceled = () => (
  <RowWrapper>
    <Col xs={12}>
      <StyledBasicContent
        i18nTitle="courses.course.session_canceled.title"
        i18nText="courses.course.session_canceled.text"
      />
      <LinkButton theme="highlight" tag={Link} to={I18nProvider.getLinkRoute('/dashboard')}>
        <FormattedMessage id="courses.course.session_canceled.back_to_home_link" />
      </LinkButton>
    </Col>
  </RowWrapper>
)

export default SessionCanceled
