import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import gql from 'graphql-tag'
import { withApollo } from 'react-apollo'
import { compose, branch, renderComponent } from 'recompose'
import { withRouter, Redirect } from 'react-router-dom'
import { Form as FinalForm } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import arrayMutators from 'final-form-arrays'
import setFieldData from 'final-form-set-field-data'
import { jsonToGraphQLQuery } from 'json-to-graphql-query'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'
import { FieldsetAnchors } from '@unowmooc/react-ui-kit'
import { cleanParams, omitProperties } from '@unowmooc/utils/form'
import I18nProvider from '@unowmooc/i18n'
import { formatGraphQLAPIErrors } from 'utils/form'
import PageLoader from 'components/PageLoader'
import { deepPick } from 'utils/object'
import FormHeader from './Header'
import GeneralError from './GeneralError'
import AutoSave from './AutoSave'
import createScrollOnErrorDecorator from './decorators/scrollOnError'

const scrollOnError = createScrollOnErrorDecorator({ offsetTop: -80 })

const StyledContent = styled.div`
  padding-top: 30px;

  ${mq.sm(css`
    padding-top: 18px;
  `)};
`

const StyledGeneralError = styled(GeneralError)`
  margin: 30px 0;
`

class Form extends Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleAutoSaveSubmit = this.handleAutoSaveSubmit.bind(this)

    this.state = {
      redirect: null,
      initialValues: _.defaultsDeep({}, _.get(props.data, 'object'), props.initialValues),
    }

    this.submit = null
  }

  componentWillReceiveProps(nextProps) {
    const { data } = this.props
    const { data: nextData } = nextProps

    if (_.get(data, 'object') && !_.isEqual(data.object, nextData.object)) {
      this.setState({ initialValues: _.defaultsDeep({}, nextData.object, nextProps.initialValues) })
    }
  }

  get title() {
    const { data, title, renderTitle } = this.props

    return renderTitle ? renderTitle(_.get(data, 'object')) : title
  }

  getAddRedirect(data) {
    const {
      addRedirect,
      match: { path },
    } = this.props

    // redirect on edit form by default
    if (!addRedirect) {
      return path.replace(I18nProvider.getLinkRoute('add'), I18nProvider.getLinkRoute('{id}/update', { id: data.id }))
    }

    return _.isFunction(addRedirect) ? addRedirect(data) : addRedirect
  }

  handleSubmit(params, form) {
    const { create, update, argumentName, omitFields } = this.props

    const mutate = params.id ? update : create

    return new Promise(resolve =>
      mutate({
        variables: { [argumentName]: { ...omitProperties(cleanParams(params), omitFields || []) } },
      })
        .then(({ data }) => {
          if (!params.id) {
            const redirect = this.getAddRedirect(_.get(data.object))

            this.setState({ redirect })
          }

          const { onSubmitSucceeded } = this.props

          if (onSubmitSucceeded) {
            onSubmitSucceeded(data.object, form)
          }

          resolve()
        })
        .catch(error => {
          const { graphQLErrors } = error

          if (!_.isEmpty(graphQLErrors)) {
            resolve(formatGraphQLAPIErrors(graphQLErrors))
          } else {
            const { networkError, message, extraInfo } = error

            resolve({ [FORM_ERROR]: { message, networkError, extraInfo } })
          }
        }),
    )
  }

  handleAutoSaveSubmit(params, form) {
    const { dirtyFields } = form.getState()

    // create param variables with only dirty fields and ids
    const dirtyParams = _.pick(params, Object.keys(dirtyFields))
    const paramsWithIds = deepPick(params, 'id')
    const variables = _.merge({}, dirtyParams, paramsWithIds)

    // create mutation query
    const {
      argumentName,
      typeName,
      client: { mutate },
      onSubmitSucceeded,
    } = this.props

    // build query with only updated fields
    const graphqlFields = jsonToGraphQLQuery(variables, { includeFalsyKeys: true })

    const mutationName = `update${typeName}`

    const mutation = gql`
      mutation update($${argumentName}: ${typeName}Input!) {
        object: ${mutationName}(${argumentName}: $${argumentName}) {
          ${graphqlFields}
        }
      }
    `

    // build optimistic response
    const paramsWithTypeNames = deepPick(params, '__typename')
    const variablesWithTypeNames = _.merge({}, variables, paramsWithTypeNames)
    const optimisticResponse = {
      [mutationName]: { ...variablesWithTypeNames },
    }

    return new Promise(resolve =>
      mutate({
        mutation,
        variables: { [argumentName]: variables },
        optimisticResponse,
      })
        .then(({ data }) => {
          resolve()

          if (onSubmitSucceeded) {
            onSubmitSucceeded(data.object, form)
          }
        })
        .catch(error => {
          const { graphQLErrors } = error

          if (!_.isEmpty(graphQLErrors)) {
            resolve(formatGraphQLAPIErrors(graphQLErrors))
          } else {
            const { networkError, message, extraInfo } = error

            resolve({ [FORM_ERROR]: { message, networkError, extraInfo } })
          }
        }),
    )
  }

  render() {
    const { redirect } = this.state

    if (redirect) {
      return <Redirect to={redirect} push />
    }

    const {
      data,
      decorators,
      addButtonLabel,
      cancelRoute,
      autoSave,
      renderSubmitButton,
      children,
      className,
      name,
      withFieldSetAnchors,
      ...rest
    } = this.props

    const { initialValues } = this.state

    return (
      <FinalForm
        {...rest}
        subscription={{
          submitting: true,
          dirty: true,
          submitError: true,
          submitErrors: true,
          submitFailed: true,
          submitSucceeded: true,
        }}
        mutators={{ ...arrayMutators, setFieldData }}
        decorators={[scrollOnError, ...decorators]}
        onSubmit={autoSave ? this.handleAutoSaveSubmit : this.handleSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, submitting, dirty, submitError, submitFailed, submitSucceeded, form }) => {
          this.submit = handleSubmit

          const formState = {
            submitting,
            dirty,
            submitFailed,
            submitSucceeded,
            submitError,
          }

          return (
            <form onSubmit={handleSubmit} className={className} name={name}>
              {!renderSubmitButton && !autoSave && (
                <FormHeader {...formState} form={form} title={this.title} addButtonLabel={addButtonLabel} />
              )}
              {autoSave && <AutoSave save={handleSubmit} />}
              <StyledContent>
                {withFieldSetAnchors && (
                  <FieldsetAnchors anchorOffset={80} responsive={false}>
                    <StyledGeneralError submitError={submitError} key="general-error" />
                    {_.isFunction(children) ? children({ ...formState }) : children}
                  </FieldsetAnchors>
                )}

                {!withFieldSetAnchors && (
                  <>
                    <StyledGeneralError submitError={submitError} key="general-error" />
                    {_.isFunction(children) ? children({ ...formState }) : children}
                  </>
                )}
              </StyledContent>
              {renderSubmitButton && renderSubmitButton({ ...formState })}
            </form>
          )
        }}
      />
    )
  }
}

Form.defaultProps = {
  name: undefined,
  data: undefined,
  create: undefined,
  update: undefined,
  title: undefined,
  renderTitle: undefined,
  initialValues: {},
  omitFields: undefined,
  addRedirect: undefined,
  addButtonLabel: undefined,
  cancelRoute: undefined,
  decorators: [],
  onSubmitSucceeded: undefined,
  renderSubmitButton: undefined,
  autoSave: false,
  className: undefined,
  client: undefined,
  withFieldSetAnchors: true,
}

Form.propTypes = {
  name: PropTypes.string,
  argumentName: PropTypes.string.isRequired,
  typeName: PropTypes.string.isRequired,
  match: PropTypes.shape().isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  client: PropTypes.shape(),
  title: PropTypes.node,
  renderTitle: PropTypes.func,
  data: PropTypes.shape(),
  create: PropTypes.func,
  update: PropTypes.func,
  initialValues: PropTypes.shape(),
  omitFields: PropTypes.arrayOf(PropTypes.string),
  addRedirect: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  addButtonLabel: PropTypes.node,
  cancelRoute: PropTypes.string,
  decorators: PropTypes.arrayOf(PropTypes.func),
  onSubmitSucceeded: PropTypes.func,
  renderSubmitButton: PropTypes.func,
  autoSave: PropTypes.bool,
  className: PropTypes.string,
  withFieldSetAnchors: PropTypes.bool,
}

export default compose(
  withRouter,
  withApollo,
  branch(
    ({ data }) => _.get(data, 'loading') === true,
    renderComponent(() => <PageLoader />),
  ),
)(Form)
