import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import gql from 'graphql-tag'
import { useUserContext } from 'store/UserProvider'
import { useMutation } from '@apollo/react-hooks'
import { useTracking } from 'modules/tracking'
import * as ConversationUI from './UI'

const ConversationHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
`

const SEND_CONVERSATION_TO_SUPPORT = gql`
  mutation sendConversationToSupport($conversationMessageId: ID!) {
    sendConversationToSupport(conversationMessageId: $conversationMessageId) {
      id
      session {
        id
        identifier
        course {
          id
          identifier
        }
      }
    }
  }
`

const Message = ({
  messageId,
  fullName,
  avatarUrl,
  gender,
  sentAt,
  content,
  highlight,
  isForwarded,
  sessionId,
  sentById,
  onForwardSuccess,
}) => {
  const [sendConversationToSupport, { loading }] = useMutation(SEND_CONVERSATION_TO_SUPPORT)
  const { user, isAnimatorFromSession } = useUserContext()
  const { sendEvent } = useTracking()
  const userCanForwardMessage = isAnimatorFromSession(sessionId) && user.id !== sentById

  const handleSubmitForward = async () => {
    const variables = { conversationMessageId: messageId }

    const {
      data: {
        sendConversationToSupport: {
          id: conversationId,
          session: {
            identifier: sessionIdentifier,
            course: { identifier: courseIdentifier },
          },
        },
      },
    } = await sendConversationToSupport({ variables })

    await onForwardSuccess()

    sendEvent(
      'conversation.send_conversation_to_support',
      JSON.stringify({
        courseIdentifier,
        sessionIdentifier,
        conversationId,
        messageId,
      }),
    )
  }

  return (
    <ConversationUI.BoxShadow id={`message_${messageId}`} highlight={highlight}>
      <ConversationHeader>
        <ConversationUI.Sender fullName={fullName} avatarUrl={avatarUrl} sentAt={sentAt} />
        {userCanForwardMessage && (
          <ConversationUI.ForwardButton
            onSubmit={handleSubmitForward}
            loading={loading}
            isForwarded={isForwarded}
            gender={gender}
          />
        )}
      </ConversationHeader>
      <ConversationUI.Content text={content} />
    </ConversationUI.BoxShadow>
  )
}

Message.defaultProps = {
  avatarUrl: undefined,
  highlight: false,
  isForwarded: false,
}

Message.propTypes = {
  highlight: PropTypes.bool,
  messageId: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  gender: PropTypes.string.isRequired,
  sentAt: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string,
  isForwarded: PropTypes.bool,
  sessionId: PropTypes.bool.isRequired,
  sentById: PropTypes.bool.isRequired,
  onForwardSuccess: PropTypes.func.isRequired,
}

export default Message
