import PropTypes from 'prop-types'
import { useWatch } from 'react-hook-form'

const Watch = ({ name, defaultValue, children }) => {
  const fieldValue = useWatch({ name, defaultValue })

  return children(fieldValue)
}

Watch.default = {
  fields: [],
  defaultValue: undefined,
}

Watch.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.string),
  // eslint-disable-next-line
  defaultValue: PropTypes.any,
}

export default Watch
