import React from 'react'
import { propType } from 'graphql-anywhere'
import gql from 'graphql-tag'
import WysiwygView from 'components/WysiwygView'
import { toInlineFragment } from 'fraql'

const TextBlock = ({ block }) => <WysiwygView content={block.displayableRaw} />

TextBlock.fragment = toInlineFragment(
  gql`
    fragment _ on Block {
      ... on TextBlock {
        blockType
        displayableRaw
      }
    }
  `,
)

TextBlock.propTypes = {
  block: propType(TextBlock.fragment).isRequired,
}

export default TextBlock
