import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'
import { Box, Flex } from 'reflexbox'
import { Form } from '@unowmooc/form'
import { ZeroHeightUnow } from '@unowmooc/zeroheight'
import I18nProvider from '@unowmooc/i18n'

import Card from 'components/Card'
import BoxSessionInfo from '../BoxSessionInfo'

const Text = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.woodsmoke};
`

const Description = styled.span`
  font-size: 16px;
  line-height: 25px;
  color: ${({ theme }) => theme.colors.woodsmoke};
`

const StyledLink = styled(Link)`
  display: block;
  width: 100%;
`

const CardHover = {
  '&:hover': {
    boxShadow: '0 13px 46px 0 rgba(0, 0, 0, 0.21)',
  },
}

const Method = () => (
  <Form name="userRegistration" persist>
    <BoxSessionInfo />
    <Flex marginTop="20px">
      <StyledLink to={I18nProvider.getLinkRoute('/supervisor/registered_users/add/manual')}>
        <Card padding="30px 30px 40px 30px" sx={CardHover}>
          <Flex justifyContent="center" alignItems="center">
            <ZeroHeightUnow name="IconGroupAdd2" height="50px" width="50px" marginRight="23px" />
            <Text>
              <FormattedMessage id="supervisor.registration.methods.manual.title" />
            </Text>
          </Flex>
          <Flex justifyContent="center" marginTop="10px">
            <Description>
              <FormattedMessage id="supervisor.registration.methods.manual.description" />
            </Description>
          </Flex>
        </Card>
      </StyledLink>
      <Box minHeight="0" minWidth="30px" />
      <StyledLink to={I18nProvider.getLinkRoute('/supervisor/registered_users/add/auto')}>
        <Card padding="30px 30px 40px 30px" sx={CardHover}>
          <Flex justifyContent="center" alignItems="center">
            <ZeroHeightUnow name="IconDocumentChecklist" height="50px" width="50px" marginRight="23px" />
            <Text>
              <FormattedMessage id="supervisor.registration.methods.auto.title" />
            </Text>
          </Flex>
          <Flex justifyContent="center" marginTop="10px">
            <Description>
              <FormattedMessage id="supervisor.registration.methods.auto.description" />
            </Description>
          </Flex>
        </Card>
      </StyledLink>
    </Flex>
  </Form>
)
export default withRouter(Method)
