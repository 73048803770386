import React from 'react'
import PropTypes from 'prop-types'
import { FormattedHTMLMessage } from 'react-intl'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'

const StyledBasicContent = styled.div`
  text-align: center;
`

const Title = styled.h3`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;

  ${mq.sm(css`
    flex-direction: column;
  `)};
`

const Illu = styled.img`
  width: 42px;
  height: 42px;
  margin-right: 14px;
  flex-shrink: 0;

  ${mq.sm(css`
    margin-right: 0;
    margin-bottom: 20px;
  `)};
`

const Text = styled.p`
  a {
    font-size: inherit;
    font-weight: 500;
    line-height: inherit;
  }
`

const BasicContent = ({ i18nTitle, i18nText, imgSrc, className }) => (
  <StyledBasicContent className={className}>
    <Title>
      {imgSrc && <Illu src={imgSrc} />}
      <FormattedHTMLMessage id={i18nTitle} />
    </Title>

    {i18nText && (
      <Text>
        <FormattedHTMLMessage id={i18nText} />
      </Text>
    )}
  </StyledBasicContent>
)

BasicContent.defaultProps = {
  i18nText: undefined,
  imgSrc: undefined,
  className: undefined,
}

BasicContent.propTypes = {
  i18nTitle: PropTypes.string.isRequired,
  i18nText: PropTypes.string,
  imgSrc: PropTypes.string,
  className: PropTypes.string,
}

export default BasicContent
