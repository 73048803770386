import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@unowmooc/react-ui-kit'
import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl'
import IntroBoxStep from './IntroBoxStep'

const Header = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
`

const Title = styled.h2`
  color: ${({ theme: { colors } }) => colors.woodsmoke};
  font-family: ${({ theme: { fonts } }) => fonts.heading};
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
`

const List = styled.ul`
  padding: 0;
  margin-bottom: 40px;
`

const Summary = styled.div`
  p {
    margin-bottom: 40px;

    &:first-child: {
      margin-bottom: 0;
    }
  }
`

const Footer = styled.div`
  text-align: center;
`

const IntroBox = ({ children, title, showButton, buttonLabel, buttonClick }) => (
  <>
    {title && (
      <Header>
        <Title>{title}</Title>
      </Header>
    )}
    {children}
    {showButton && (
      <Footer>
        <Button onClick={buttonClick}>{buttonLabel}</Button>
      </Footer>
    )}
  </>
)

IntroBox.Steps = List

IntroBox.Step = IntroBoxStep

IntroBox.Summary = Summary

IntroBox.defaultProps = {
  buttonLabel: <FormattedMessage id="survey.intro_box.start_button_label" />,
  showButton: true,
}

IntroBox.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  buttonLabel: PropTypes.node,
  buttonClick: PropTypes.func.isRequired,
  showButton: PropTypes.bool,
}

export default IntroBox
